import * as actionTypes from '../consts/actionTypes';

export function setAuthorizationDetails(param) {
    return {
        type: actionTypes.SET_AUTHORIZATION_DETAILS,
        payload: param
    };
}

export function getAuthorizationDetails() {
    return {
        type: actionTypes.GET_AUTHORIZATION_DETAILS,
        payload: ''
    };
}

export function getAuthorizationDetailsSuccess(model) {
    return {
        type: actionTypes.GET_AUTHORIZATION_DETAIL,
        payload: model
    };
}

export function getComments(param) {
    return {
        type: actionTypes.GET_COMMENTS,
        payload: param
    };
}

export function pushComments(comment) {
    return {
        type: actionTypes.PUSH_COMMENTS,
        payload: comment
    };
}

export function getReadComments(param) {
    return {
        type: actionTypes.GET_READ_COMMENTS,
        payload: param
    };
}

export function getAuthorizationsDetials() {
    return {
        type: actionTypes.GET_AUTHORIZATIONS_DETAILS,
        payload: ''
    };
}

export function setModelAuthRequest(param) {
    return {
        type: actionTypes.SET_MODEL_AUTH_REQUEST,
        payload: param
    };
}

export function clearModelAuthRequest() {
    return {
        type: actionTypes.CLEAR_MODEL_REQUEST,
        payload: ''
    };
}

export function clearModelDetails() {
    return {
        type: actionTypes.CLEAR_MODEL_DETAILS_AUTH,
        payload: ''
    };
}

export function clearHistorialAuthRequest() {
    return {
        type: actionTypes.CLEAR_HISTORIAL_REQUEST,
        payload: ''
    };
}


export function getStepCountAuthorizationsRequest() {
    return {
        type: actionTypes.GET_STEPS_AUTH_REQUEST,
        payload: ''
    };
}

export function setActualStepAuthorizationsRequest(actualStep) {
    return {
        type: actionTypes.SET_ACTUAL_STEP_AUTH_REQUEST,
        payload: actualStep
    };
}

export function setNextStepAuthorizationsRequest(step) {
    return {
        type: actionTypes.NEXT_STEP_AUTH_REQUEST,
        payload: step
    };
}

export function setPrevStepAuthorizationsRequest(step) {
    return {
        type: actionTypes.PREV_STEP_AUTH_REQUEST,
        payload: step
    };
}
export function getTypeAttached(param) {
    return {
        type: actionTypes.GET_AUTHORIZATIONS_TYPEATTACHED,
        payload: param
    };
}

export function getParamsAuthRequest() {
    return {
        type: actionTypes.GET_PARAMS_AUTH_REQUEST,
        payload: ''
    };
}

export function getAttached(param) {
    return {
        type: actionTypes.GET_AUTHORIZATIONS_ATTACHED,
        payload: param
    };
}

export function postAttached(param) {
    return {
        type: actionTypes.POST_AUTHORIZATIONS_ATTACHED,
        payload: param
    };
}

export function postRequest(param) {
    return {
        type: actionTypes.POST_AUTHORIZATIONS_REQUEST,
        payload: param
    };
}

export function getAuthorizationAffiliate(param) {
    return {
        type: actionTypes.GET_AUTHORIZATIONS_AFFILIATE,
        payload: param
    };
}

export function downloadAttachments(param) {
    return {
      type: actionTypes.DOWNLOAD_ATTACHMENTS,
      payload: param,
    };
}

export function downloadAuthorization(param) {
    return {
      type: actionTypes.DOWNLOAD_AUTHORIZATION,
      payload: param,
    };
  }

export function cancelAuthorizationRequest(param) {
    return {
        type: actionTypes.CANCEL_AUTHORIZATION,
        payload: param
    };
}
