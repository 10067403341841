import * as actionTypes from "../consts/actionTypes";

/**acciones que se disparan del componente */
export function getTelemedicineAppointments(affiliated) {
  return {
    type: actionTypes.GET_TELEMEDICINE,
    payload: affiliated,
  };
}

export function setTelemedicineParameters(param) {
  return {
    type: actionTypes.SET_TELEMEDICINE_PARAMS,
    payload: param,
  };
}

export function getTelemedicineParameters(param) {
  return {
    type: actionTypes.GET_TELEMEDICINE_PARAMS,
    payload: param,
  };
}

export function clearState() {
  return {
      type: actionTypes.CLEAR_STATE,
      payload: ''
  };
}
