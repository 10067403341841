import { makeStyles } from "@material-ui/core/styles";
import minimalCredentialSVG from "../images/horizontal-card.svg";
import credentialSVG from "../images/front-card.svg";
import backCredentialSVG from "../images/back-card.svg";
import headerCredentialSVG from "../images/header-card.svg";

/* OSLP Colors */
const $lightblue = "#54c0e8";
const $blue = "#0075c9";
const $darkblue = "#002858";
const $darkgray = "#585858";
const $lightgray = "#e2e3e3";
const $tolightgray = "#f3f3f3";
const $mediumgray = "#a0a0a0";
const $lighterGray = '#d2d2d2';
const $lightGreen = '#40cc40';
/* OSLP Box Shadows */
const $bottomBoxShadow = `0px 15px 14px 0px ${$mediumgray}`;
const $boxShadowButtons = `0px 2px 8px 0 ${$darkgray}`;
const $boxShadowCards = `1px 12px 20px ${$darkgray}`;
const $lightBoxShadow = `0px 3px 14px 0px ${$mediumgray}`;
const $upBoxShadow = `0px -18px 16px 0px ${$lighterGray}`;
const $lateralMenuBoxShadow = `0px 0px 20px 2px ${$mediumgray}`;
const $headerBoxShadow = `0px 0px 20px 5px ${$mediumgray}`;
const $containerBoxShadow = `12px 11px 9px 0px ${$mediumgray}`;
/* OSLP Linear Gradients */
const $lightLinearGradient = `linear-gradient(90deg, ${$blue}, ${$lightblue} 100%)`;
const $darkLinearGradient = `linear-gradient(90deg, ${$blue}, ${$darkblue} 100%)`;

/* LinearGradient Reverse*/
const $lightLinearGradientReverse = `linear-gradient(90deg, ${$lightblue}, ${$blue} 100%)`;
const $darkLinearGradientReverse = `linear-gradient(90deg, ${$darkblue} , ${$blue}100%)`;
const $twoHundredEightyPx = '280px !important';
const $darkLinearGradientGray = `linear-gradient(90deg, ${$darkgray} , ${$lightgray}100%)`;

/* Drawer Width */
const $drawerWidth = "450";

const $arialRegular = "Arial, regular";
const $fontSize14 = '14px !important';
const $fontBold = "bold !important";
const $colorWhite = "white !important";
const $tenPx = "10px !important";
const $centerImportant = "center !important";
const $zeroPx = "0px !important";
const $eightPx = "8px !important";
const $fivePx = "5px !important";
const $fiftyHundredPx = "150px !important";
const $sixtyPx = "60px !important";
const $thirtyPx = "30px !important";
const $fiftyFivePx = "55px !important";
const $initialImportant = "initial !important";
const $elevenPx = "11px !important";
const $twelve = "12px !important";
const $spaceBetween = "space-between";
const $twentyFivePx = "25px !important";
const $arialSansSerif = "Arial, sans-serif";
const $fortyPx = "40px !important";
const $flexImportant = "flex !important";
const $autoImportant = "auto !important";
const $absoluteImportant = "absolute !important";
const $fitContent = "fit-content";
const $twentyThreePerCent = "23% !important";
const $ninetyFivePerCent = "95% !important";
const $hiddenImportant = "hidden !important";
const $eighteenPx = "18px !important";
const $boxxShadow = "0px 2px 8px 0 #585858";
const $fourHundredPx = "400px !important";
const $threeHundredPx = "300px !important";
const $threeHundredTenPx = "310px !important";
const $sixHundredWeight = "600 !important";
const $templateColumnsFour = "1fr 1fr 1fr 1fr";
const $templateColumnsThree = "1fr 1fr 1fr";
const $fivePercent = "5% !important";
const $seventyPx = "70px !important";
const $eightyPercent = "80% !important";
const $twoPointFivePercent = "2.5% !important";
const $zeroZeroOneHundred = "0 0 100% !important";
const $oneHundredThirtyPx = "130px !important";
const $twoHundredFiftyPx = "250px !important";
const $oneHundredFortyPx = "140px !important";
const $oneHundredPercent = "100%";
const $oneHundredPercentImportant = "100% !important";
const $oneOneOneHundredPercent = "1 1 100%";
const $whiteColor = "#ffffff !important";
const $colorTransparent = "transparent !important";
const $fiftyPx = "50px !important";
const $zeroImportant = '0 !important';
const $centerCenter = "center center";
const $threeHundredThirtyPx = "330px";
const $FourHundredEightyPx = "480px";

export default makeStyles(
  (theme) => ({
    /************* Generics *************/
    customScroll: {
      '&::-webkit-scrollbar ': {
        borderRadius: '4px',
        height: '8px',
        width: '5px'
      },
      '&::-webkit-scrollbar-track': {
        background: $lightgray,
        borderRadius: '4px'
      },
      '&::-webkit-scrollbar-thumb': {
        background: $darkgray,
        borderRadius: '4px'
      },
      '&::-webkit-scrollbar-thumb:active': {
        background: $darkblue,
        borderRadius: '4px'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: $blue,
        boxShadow: $lightBoxShadow,
        borderRadius: '4px'
      }
    },

    divLoading: {
      backgroundColor: $blue,
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: $oneHundredPercent,
      position: 'fixed',
      top: '0',
    },

    txtLogIn: {
      fontSize: '8px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      maxWidth: '38px',
    },

    mainTitle: {
      color: $darkblue,
      fontFamily: $arialSansSerif,
      fontWeight: "bold",
      fontSize: "31.33px",
      [theme.breakpoints.only("xs")]: {
        fontSize: "20.33px !important",
      },
    },
    // Txt Subtitle
    txtSubtitle: {
      fontFamily: $arialRegular,
      fontSize: "28px",
      color: $blue,
    },
    txtEllipsis: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      textDecoration: "none",
      overflow: "hidden",
    },
    txtRegular: {
      fontSize: "16px",
    },
    txtObservaciones: {
      fontSize: $fontSize14
    },
    txtDescription: {
      color: $darkgray,
      fontSize: "11px",
    },
    txtDescriptionWhite: {
      color: "#FFF",
      fontSize: "11px",
    },
    txtLinksInCard: {
      color: $blue,
      fontSize: "0.67rem",
      textDecoration: "none",
    },
    txtDarkBlue: {
      color: `${$darkblue} !important`
    },
    txtLightBlue: {
      color: $lightblue,
    },
    txtBold500: {
      fontWeight: '500 !important'
    },
    upBoxShadow: {
      boxShadow: $upBoxShadow
    },
    bottomBoxShadow: {
      boxShadow: $bottomBoxShadow,
    },
    lightBoxShadow: {
      boxShadow: $lightBoxShadow
    },
    noneBoxShadow: {
      boxShadow: "none",
    },
    ShowMoreArrowIcon: {
      color: $blue,
    },
    RemoveArrowIcon: {
      color: "red",
    },
    lightLinearGradient: {
      background: $lightLinearGradient,
    },
    darkLinearGradient: {
      background: $darkLinearGradient,
    },
    darkLinearGradientImportant: {
      background: `${$darkLinearGradient} !important`,
    },
    lightLinearGradientReverse: {
      background: $lightLinearGradientReverse,
    },
    darkLinearGradientReverse: {
      background: $darkLinearGradientReverse,
    },

    errorText: {
      [theme.breakpoints.up(901)]: {
        marginLeft: "10px",
        color: "red",
        marginBottom: "10px",
      },

      [theme.breakpoints.down(901)]: {
        color: "red",
        marginLeft: "20px",
        marginBottom: "10px",
      }
    },

    infoText: {
      [theme.breakpoints.up(901)]: {
        marginLeft: "10px",
        marginBottom: "10px",
      },

      [theme.breakpoints.down(901)]: {
        marginLeft: "20px",
        marginBottom: "10px",
      }
    },

    errorTextFormMobile: {
      [theme.breakpoints.down(901)]: {
        marginLeft: "10px"
      }
    },

    backgroundError: {
      backgroundColor: 'red !important'
    },

    borderWhite: {
      border: "2px solid white"
    },

    blueTxt: {
      color: $blue,
      textTransform: "uppercase !important",
      fontWeight: $fontBold,
      fontSize: $fontSize14,
    },

    grayTxt: {
      color: `${$darkgray} !important`,
      fontSize: $fontSize14,
    },

    darkgrayTxt: {
      color: `${$darkgray} !important`,
    },

    fontBold: {
      fontWeight: $fontBold,
    },

    darkgray: {
      background: $darkgray,
    },

    backgroundBlue: {
      backgroundColor: $blue,
      color: $colorWhite
    },

    centeredBox: {
      marginTop: $tenPx,
      display: 'flex',
      justifyContent: $centerImportant
    },

    backgroundToLightGray: {
      backgroundColor: $tolightgray
    },

    backgroundDarkBlue: {
      backgroundColor: `${$darkblue} !important`
    },

    circularProgress: {
      '&>span': {
        color: $darkblue
      }
    },
    margin1PX: {
      [theme.breakpoints.between(960, 1170)]: {
        margin: '10px 1px 1px 2px !important',
        minWidth: "54px"
      }
    },

    /**************************/
    // Header
    header: {
      paddingTop: $sixtyPx,
      height: "40px",
      alignItems: "flex-start",
      [theme.breakpoints.down("768")]: {
        alignItems: "center",
        flexWrap: 'wrap',
        height: "0",
      },
    },
    headerWeb: {
      marginBottom: '30px',
      [theme.breakpoints.down(890)]: {
        flexWrap: 'wrap'
      }
    },
    headerFix: {
      paddingTop: $zeroPx,
    },
    boxHeaderBtn: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down(1160)]: {
        flexWrap: 'wrap'
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        flexWrap: "wrap",
      },
      width: $oneHundredPercent,
    },
    customHeaderBtnFix: {
      [theme.breakpoints.down(1160)]: {
        width: "50%",
        height: "8px !important",
        fontSize: "8px !important",
      },
      [theme.breakpoints.down(950)]: {
        fontSize: "7px !important",
      },
      [theme.breakpoints.down(790)]: {
        fontSize: "6px !important",
      },
    },

    boxImageHeader: {
      borderRadius: '0px 0px 40px 40px',
      width: $oneHundredPercent,
      height: $oneHundredPercent,
    },

    homeIconWOutLog: {
      flex: '1 1 28%',
      padding: '0px 20px 0px 20px',
      [theme.breakpoints.down(713)]: {
        padding: '0px 10px 0px 10px',
      },
      marginLeft: $eightPx,
      marginRight: $eightPx,
      [theme.breakpoints.down(390)]: {
        marginLeft: $fivePx,
        marginRight: $fivePx,
        padding: $zeroPx
      }
    },
    homeWOutLogDivIcons: {
      height: '88px !important',
      width: $oneHundredPercentImportant,
      [theme.breakpoints.up(600)]: {
        width: $fiftyHundredPx
      }
    },
    homeWOutLogIcon: {
      fontSize: '40px'
    },
    homeTxtIcons: {
      fontSize: $tenPx
    },
    boxHeaderMobile: {
      height: $sixtyPx,
    },

    boxHeaderFiltersMobile: {
      flexWrap: "wrap !important",
      paddingTop: $zeroPx,
    },

    divFiltersMobile: {
      paddingBottom: $thirtyPx,
    },

    divHeaderLogo: {
      display: 'flex',
      width: '80%',
      minWidth: '140px',
      [theme.breakpoints.down("md")]: {
        justifyContent: 'center'
      },
      height: '80px',
      '&>button>span>img': {
        [theme.breakpoints.down(411)]: {
          height: $fiftyFivePx
        }
      }
    },

    divQuickAccess: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      overflow: 'auto',
      paddingTop: '6%',
      [theme.breakpoints.up(1570)]: {
        justifyContent: 'center',
        marginLeft: '8%',
        marginRight: '8%',
      },
      [theme.breakpoints.down(1200)]: {
        overflow: 'hidden',
        flexWrap: 'wrap',
        marginLeft: '0px',
        marginRight: '0px',
        paddingTop: '10%',
        justifyContent: 'center',
      }
    },

    divIconHome: {
      [theme.breakpoints.down(400)]: {
        marginLeft: '0%',
        marginRight: '0%',
      },
      '&>a': {
        [theme.breakpoints.down(361)]: {
          width: '65px !important'
        }
      }
    },

    correctionBlockIcon: {
      alignItems: 'flex-start',
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.01)"
      },
    },

    correctionSentence: {
      whiteSpace: 'normal !important',
      overflow: $initialImportant,
      letterSpacing: '0.5px'
    },

    quickAccessIcon: {
      fontSize: '40px',
      [theme.breakpoints.down('md')]: {
        fontSize: '30px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '25px'
      }
    },

    txtQuickAccess: {
      fontSize: $elevenPx,
      [theme.breakpoints.up('lg')]: {
        fontSize: $twelve
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: $fontSize14
      },
    },

    quickAccess: {
      height: "78px",
      width: '90px',
      padding: "10px",
      borderRadius: "20px",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    divItemQuickAccess: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '90px',
      justifyContent: 'center',
      marginRight: '10px',
      marginLeft: '10px',
      marginBottom: '30px',
      textDecoration: 'none',
      [theme.breakpoints.down('sm')]: {
        width: '73px'
      },
      [theme.breakpoints.down(372)]: {
        width: '53px !important'
      },
      '&>span': {
        flexWrap: 'wrap'
      }
    },

    // Menu
    appBar: {
      zIndex: 1300,
      backgroundColor: $whiteColor,
      height: "80px",
      '&>div': {
        paddingLeft: $zeroPx,
        paddingRight: $zeroPx
      },
      boxShadow: $headerBoxShadow
    },
    toolbar: {
      paddingLeft: '0px',
      display: 'flex',
      flex: '0 0 100%',
      justifyContent: $spaceBetween
    },
    rightSectionMenu: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up(768)]: {
        paddingRight: '30px',
        width: '50%',
      },
      width: '20%',
      marginRight: '2%',
      paddingRight: '6%'
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: $blue,
      marginLeft: '1%',
      '&>span>svg': {
        fontSize: '30px'
      }
    },
    drawerMenu: {
      flexShrink: 0,
      height: $oneHundredPercentImportant,
    },
    drawerPaperMenu: {
      top: "80px",
      paddingBottom: "56px",
      backgroundColor: $tolightgray,
      boxShadow: $lateralMenuBoxShadow,
      [theme.breakpoints.down("600")]: {
        width: $oneHundredPercent,
      },
      [theme.breakpoints.down(1367)]: {
        maxWidth: '265px'
      }
    },
    menuButtonRight: {
      color: $blue,
      marginLeft: "auto",
      paddingRight: '0px',
      '&>span': {
        flexWrap: 'wrap',
        '&>svg': {
          fontSize: '36px',
          [theme.breakpoints.down(411)]: {
            fontSize: $twentyFivePx
          }
        }
      },
    },
    menuList: {
      paddingTop: $zeroPx,
      color: $whiteColor,
    },
    icon: {
      color: $blue,
      minWidth: "35px",
      marginLeft: "0.8em",
    },
    listItemsStyles: {
      paddingTop: "0.4rem",
      paddingBottom: "0.4rem",
      [theme.breakpoints.up(1367)]: {
        paddingRight: "6em",
      }
    },
    activeItemMenu: {
      '&>div>span>p': {
        fontWeight: 'bold !important'
      }
    },
    paddingHeightMD: {
      paddingTop: "5%",
      paddingBottom: "5%",
    },
    paddingHeightSM: {
      paddingTop: "4%",
      paddingBottom: "4%",
    },
    paddingHeightXS: {
      paddingTop: "1%",
      paddingBottom: "1%",
    },
    divider: {
      background: $whiteColor,
      marginLeft: "2.5em",
      marginRight: "2.5em",
      height: "2px",
    },
    multiline: {
      fontFamily: "Arial",
      fontSize: "1em",
      whiteSpace: "wrap",
      textDecoration: "none",
      color: $blue
    },

    // Buttons
    customButton: {
      borderRadius: "20px",
      color: "white",
      fontSize: "10pt",
      fontWeight: "bold",
      fontFamily: $arialSansSerif,
      boxShadow: $boxShadowButtons,
      margin: "2px 2px 2px 2px",
      cursor: 'pointer !important'
    },

    customHeaderBtn: {
      height: "35px",
      alignSelf: "center",
      padding: "14px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      cursor: "pointer",
      [theme.breakpoints.down(525)]: {
        width: $oneHundredPercentImportant,
        flex: $oneOneOneHundredPercent
      },
      [theme.breakpoints.up("800")]: {
        fontSize: "0.6rem",
      },
      [theme.breakpoints.up("1000")]: {
        fontSize: "0.7rem",
      },
      [theme.breakpoints.up("1400")]: {
        height: "39px",
        fontSize: "0.8rem",
        marginLeft: "14px",
      },
    },

    whiteAndBlueBtn: {
      fontSize: "12px",
      fontWeight: "600",
      backgroundColor: $whiteColor,
      color: $blue,
      border: `2px solid ${$blue}`
    },

    btnDownload: {
      margin: "10px 0 10px 0",
      "&>span>a": {
        color: $colorWhite,
        textDecorator: "none",
      },
    },
    anchorDownload: {
      display: "flex",
      flexDirection: "row-reverse",
      marginRight: "10px",
    },
    customCarouselBtn: {
      width: "130px",
      height: $fiftyPx,
      [theme.breakpoints.down("sm")]: {
        fontSize: "7pt",
        width: "80px !important",
        height: "28px !important",
      },
    },
    customCarouselBtnMobile: {
      fontSize: "8pt",
      width: "80px !important",
      height: "28px !important",
    },
    btnFilter: {
      fontSize: "13px",
      color: $blue,
      background: "transparent",
      border: "none",
      boxShadow: "none",
      "&:hover": {
        background: "transparent",
        border: "none",
        boxShadow: "none",
      },
    },

    /************************** Filters Cartilla ****************************/
    buttonsHeadersFilter: {
      backgroundColor: `${$tolightgray} !important`,
      background: "none !important",
      borderRadius: $thirtyPx,
      height: $fortyPx,
      width: $oneHundredPercentImportant,
      display: $flexImportant,
      alignItems: $centerImportant,
      justifyContent: $centerImportant,
      boxShadow: `${$boxShadowButtons} !important`,
    },

    textBtnFilter: {
      color: `${$blue} !important`,
      textAlign: $centerImportant,
      fontFamily: `${$arialSansSerif} !important`,
      fontWeight: $fontBold,
      alignItems: $centerImportant,
      justifyContent: $centerImportant,
      borderRadius: "30px",
      backgroundColor: $tolightgray,
      boxShadow: $boxShadowButtons,
      "&>div.MuiInputBase-root": {
        paddingRight: $fiftyFivePx,
        paddingLeft: "17px !important",
        fontSize: "13px !important",
        color: $blue,
        "&>input": {
          textAlign: "center",
        },
        "&>fieldset>legend": {
          width: $zeroPx,
        },
      },
      "&>.MuiInputLabel-outlined": {
        fontSize: $twelve,
        color: `${$blue} !important`,
        textAlign: $centerImportant,
        fontFamily: `${$arialSansSerif} !important`,
        transform: "none !important",
        fontWeight: $fontBold,
        top: $autoImportant,
        overflow: "hidden",
        left: $autoImportant,
        textOverflow: "ellipsis",
        maxWidth: "86%",
        whiteSpace: "nowrap",
      },
      "&>.MuiFormHelperText-root.Mui-error": {
        top: "42px !important",
        position: $absoluteImportant,
      },
      "&>.MuiOutlinedInput-root": {
        borderRadius: $thirtyPx,
      },
      "&>.MuiInputBase-fullWidth": {
        height: $oneHundredPercentImportant,
        "&.MuiInputBase-fullWidth > svg.MuiSelect-icon.MuiSelect-iconOutlined":
        {
          display: "none !important",
        },
      },
      "&>.MuiInputLabel-outlined.MuiInputLabel-shrink": {
        display: "none",
      },
      "&>.MuiOutlinedInput-adornedEnd  > div > button": {
        color: $colorTransparent,
      },
      "&>.Mui-focused > fieldset.MuiOutlinedInput-notchedOutline": {
        border: "none !important",
        "&:focus": {
          border: "none !important",
        },
      },
    },
    sendButton: {
      height: "35px !important",
      fontSize: $twelve,
      width: "240px !important",
    },

    divFilterBtn: {
      margin: "0 40px",
      flex: "1 1 50%",
      justifyContent: "center",
      display: "flex",
      [theme.breakpoints.down(1326)]: {
        padding: `10px ${$tenPx}`,
      },
      [theme.breakpoints.down(960)]: {
        flex: "1 1 36% !important",
      },
      [theme.breakpoints.down(680)]: {
        flex: "1 1 52% !important",
      },
    },
    disableTextField: {
      pointerEvents: "none",
      opacity: "0.5",
      backgroundColor: '#ffffff!important',
      boxShadow: "0px 2px 8px 0 #cdcdcd",
    },
    enableTextField: {
      pointerEvents: "auto",
      opacity: "1",
      backgroundColor: '#ffffff!important',
      boxShadow: "0px 2px 8px 0 #cdcdcd",
    },

    /***********************************************************************************/
    // Carousel
    paddingCarousel: {
      padding: "15px 2% 0 2%",
      backgroundColor: "white",
    },
    carousel: {
      height: "350px",
      borderRadius: "40px",
      "&>div": {
        height: $oneHundredPercent,
        "&>div": {
          height: $oneHundredPercent,
        },
      },
    },
    carouselMobile: {
      height: "27vh",
      marginTop: "45px",
    },

    // Carousel Item
    carouselText: {
      fontFamily: "arial",
      fontSize: "2.5rem",
      fontWeight: "bold",
      color: "#002856",
      marginBottom: "10px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "19pt",
      },
    },
    carouselTextMobile: {
      fontSize: "9pt !important",
    },
    carouselItem: {
      width: $oneHundredPercent,
      height: $oneHundredPercent,
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      borderRadius: "20px",
      display: "flex",
      backgroundPosition: $centerCenter,
      objectFit: "cover",
    },
    carouselItemMobile: {
      height: "27vh",
    },
    carouselContainer: {
      alignSelf: "end",
      [theme.breakpoints.up("md")]: {
        paddingLeft: $zeroPx,
        marginLeft: "10px !important",
      },
    },
    carouselContainerMobile: {
      marginLeft: "8%",
    },
    displayFlex: {
      display: "flex",
    },

    marginTop: {
      marginTop: "30px",
    },
    divSecndBtn: {
      marginLeft: "35px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "5%",
      },
    },
    // Btn 'Ver Mas'
    txtBtnShowMore: {
      fontFamily: $arialRegular,
      fontSize: "14.5px",
      fontWeight: "800",
      color: "#0075C9",
    },
    // No content to show:
    noContent: {
      display: "flex",
      justifyContent: "center",
      alignSelf: "start",
      color: "#0075C9",
    },

    // News Tabs
    newsTabs: {
      flexGrow: 1,
      boxShadow: $bottomBoxShadow,
      position: "absolute",
      right: 0,
    },

    tabsHomeWeb: {
      width: "80%",
      [theme.breakpoints.down(940)]: {
        width: "70%",
      },
    },

    selected: {
      opacity: 1,
      borderRadius: "20px",
      background: "linear-gradient(90deg, #0075C9, #54C0E8 100%)",
      color: $colorWhite,
      boxShadow: $boxShadowButtons,
    },
    colorTab: {
      color: "#0075C9",
      fontWeight: 700,
      fontFamily: "sans-serif",
      minWidth: "9.5% !important",
      borderRadius: "40px",
      marginBottom: "10px",
      textTransform: "none",
      marginLeft: "40px",
      marginRight: "40px",
      [theme.breakpoints.down(970)]: {
        margin: "0 15px"
      },
    },
    navTabs: {
      padding: "10px 0 0 0",
      "&>div": {
        padding: "5px 0px",
      },
      "&>div>span.MuiTabs-indicator": {
        backgroundColor: $colorWhite,
      },
    },
    tabMobile: {
      minWidth: "42% !important",
      minHeight: "38px",
    },
    paddingTopNews: {
      paddingTop: "90px",
      maxWidth: $oneHundredPercent,
      // padding para flex container
      paddingLeft: "0px",
      paddingRight: "0px",
      [theme.breakpoints.down(769)]: {
        paddingTop: $zeroPx,
      }
    },

    // Item Tabs
    itemTab: {
      color: "#0075C9",
      fontWeight: 700,
      fontFamily: "sans-serif",
      minWidth: "25% !important",
    },

    // Grid News Items
    gridNewsItems: {
      margin: "12px",
      padding: $zeroPx,
      borderRadius: "20px",
      boxShadow: "1px 12px 20px grey",
    },
    homeCardPhoto: {
      "&>ul>li": {
        height: $autoImportant,
      },
    },
    /******************** Lista en una línea *************************/
    newsRoot: {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      overflow: "hidden",
      height: $fitContent,
    },

    gridList: {
      flexWrap: "nowrap",
      transform: "translate>(0)",
      width: $oneHundredPercent,
      height: "auto",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        height: $fitContent,
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: "20px",
      },
      "&>li": {
        height: "215px !important",
        fontWeight: "bold",
        margin: "10px",
        borderRadius: "30px",
        boxShadow: $boxShadowCards,
        flexBasis: "auto",
        flexGrow: "0",
        width: $twentyThreePerCent,
        padding: $zeroPx,
        [theme.breakpoints.only("md")]: {
          width: "30.33% !important",
        },
        [theme.breakpoints.only("lg")]: {
          // flexGrow: 0,
          width: $twentyThreePerCent,
        },
        [theme.breakpoints.down(946)]: {
          //  flexGrow: 1,
          width: "47% !important",
        },
        [theme.breakpoints.down(634)]: {
          //  flexGrow: 1,
          width: $ninetyFivePerCent,
        },
        [theme.breakpoints.down("sm")]: {
          flexGrow: 1,
        },
        "&>div": {
          borderRadius: "30px",
        },
      },
    },

    titleBar: {
      color: "white",
    },
    /**********************************************************/

    // Spacing Lg 3:
    gridLg3: {
      flexGrow: 0,
      flexBasis: "23%",
      maxWidth: "25%",
    },

    // Individual New article:
    newCard: {
      borderRadius: "20px",
    },
    media: {
      display: "flex",
      height: "210px",
      borderRadius: "20px",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      alignItems: "flex-end",
    },
    cardContent: {
      width: $oneHundredPercent,
      height: "38%",
      padding: `0px 0px 0px ${$zeroPx}`,
      alignSelf: "flex-end",
      backgroundColor: "#0075C9",
      opacity: "0.7",
    },
    cardAction: {
      position: "absolute",
      padding: "1px 0 0 8px",
    },
    cardActionTitle: {
      padding: "5px 0 30px 10px",
    },

    mediaPair: {
      backgroundColor: "#002856 !important",
      opacity: "0.7",
      height: "38%",
    },

    //Accordion Buttons Phones
    headingBPhones: {
      color: "#0075C9",
      fontWeight: "700",
      fontSize: "16px",
      [theme.breakpoints.down("600")]: {
        fontSize: "11px",
      },
      "@media only screen and (min-width:600px) and (max-width: 960px)": {
        fontSize: "12px",
      },
    },

    headingTextBPhone: {
      color: $darkgray,
      fontWeight: "600",
      fontSize: "17px",
      textAlign: "center"
    },

    telBPhone: {
      color: $blue,
      fontWeight: "600",
      fontSize: "16px",
      textDecoration: "none !important",
    },

    textBPhones: {
      color: "#002856",
      fontWeight: "600",
      paddingTop: $zeroPx,
      [theme.breakpoints.down("600")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "12px",
      },
    },

    accordionItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "20px !important",
      background: $tolightgray,
      boxShadow: "0 6px 6px -6px #585858 !important",
      borderRadius: "20px !important",
      width: "350px",
      [theme.breakpoints.down("600")]: {
        borderRadius: $fiftyPx,
        background: $tolightgray,
        marginBottom: "20px",
      },
      '&>.MuiButtonBase-root': {
        cursor: "default !important",
      }
    },

    contentRegister: {
      [theme.breakpoints.up(960)]: {
        position: 'absolute',
        left: '0',
        height: '119.5%',
        paddingLeft: '0'
      },
    },

    // Page Useful Phones
    boxUsefulPhones: {
      paddingTop: $zeroPx,
      [theme.breakpoints.down(960)]: {
        backgroundColor: $colorTransparent,
        color: $whiteColor,
      },
      [theme.breakpoints.up(960)]: {
        background: $whiteColor,
        color: $whiteColor,
        marginLeft: '50%',
        left: '0',
        width: $oneHundredPercent,
        height: $oneHundredPercent,
        zIndex: '10000',
      },
    },

    imgUsefulPhones: {
      height: $oneHundredPercent,
      width: $oneHundredPercent,
      minHeight: "800px",
      [theme.breakpoints.down(960)]: {
        background: '#fff'
      },
    },

    boxUsefulPhones2: {
      paddingLeft: "2.5em !important",
      paddingRight: "2.5em !important",
      zIndex: '10',
      left: '0',
      height: $oneHundredPercent,
      [theme.breakpoints.down(960)]: {
        paddingLeft: "0.5em !important",
        paddingRight: "0.5em !important",
        width: $oneHundredPercentImportant,
        position: "absolute",
        height: '100vh'
      },
    },
    gridButtons: {
      [theme.breakpoints.down(960)]: {
        position: "fixed",
      }
    },
    opacityImg: {
      [theme.breakpoints.down(960)]: {
        height: "100vh",
        width: $oneHundredPercent,
        position: "absolute",
        backgroundColor: "#0075C9",
        opacity: "0.6",
      },
    },

    titleUsefulPhones: {
      color: "#002856",
      fontFamily: "Arial",
      fontWeight: "600",
      fontSize: "28px",
      paddingTop: "30px",
      textAlign: "center",
      [theme.breakpoints.down(768)]: {
        textAlign: "center",
        fontSize: "36px",
        paddingTop: "30px",
        color: $whiteColor,
      },
      [theme.breakpoints.down(960)]: {
        textAlign: "center",
        fontSize: "38px",
        paddingTop: "30px",
        color: $whiteColor,
      },
    },

    titlePhoneFix: {
      color: $blue,
      fontWeight: "600",
      fontSize: "28px",
      paddingTop: "30px",
      paddingLeft: "30px",
    },

    paragraphUsefulPhones: {
      paddingTop: "10px",
      paddingBottom: "20px",
      paddingLeft: "30px",
      color: $darkgray,
      fontSize: "18px",
      [theme.breakpoints.down(768)]: {
        paddingTop: "30px",
        paddingRight: "20px",
        fontSize: "16px",
      },
      [theme.breakpoints.down(380)]: {
        paddingLeft: "20px",
        fontSize: "15px",
      },
    },

    accordionButton: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      marginLeft: "10%",
      marginRight: "10%",
      [theme.breakpoints.up(1800)]: {
        marginLeft: "15%",
        marginRight: "15%",
      },
      [theme.breakpoints.up(2010)]: {
        marginLeft: "20%",
        marginRight: "20%",
      },
      [theme.breakpoints.up(2250)]: {
        marginLeft: "25%",
        marginRight: "25%",
      },
      [theme.breakpoints.down(1255)]: {
        marginLeft: "0%",
        marginRight: "0%",
      },
    },


    /************ Card Medical Records ********************/
    LocationCityIcon: {
      color: "white",
      borderRadius: "20px",
      backgroundColor: $blue,
      float: "right",
    },
    card: {
      backgroundColor: $lightgray,
      borderRadius: "30px",
      margin: "10px",
      [theme.breakpoints.up("md")]: {
        maxWidth: "340px",
      },
      [theme.breakpoints.down(1366)]: {
        margin: '5px'
      }
    },
    iconsCards: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    addListCardMobile: {
      flexDirection: "column !important",
      marginLeft: "0px",
      marginRight: "0px",
    },
    addListCard: {
      marginLeft: $twentyFivePx,
      marginRight: $twentyFivePx,
    },
    imageListChildren: {
      '&>div': {
        width: '95%'
      },
      paddingTop: '20px',
      gridTemplateColumns: 'repeat(1, 1fr)',
      [theme.breakpoints.up(1000)]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up(1650)]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up(2500)]: {
        gridTemplateColumns: 'repeat(5, 1fr)',
      },
    },
    /*****************************************************/
    closeModal: {
      zIndex: 1,
      padding: "5px",
      position: "absolute",
      right: "0",
      top: "0",
      color: $blue,
      backgroundColor: $whiteColor,
      border: "1px solid #0075C9",
      cursor: "pointer",
    },
    /*Modal Profile */
    closeButton: {
      padding: "5px",
      position: "absolute",
      right: "0",
      top: "0",
      color: $blue,
      backgroundColor: $whiteColor,
      border: "1px solid #0075C9",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    contentDialogTitle: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: $blue,
      color: $whiteColor,
    },
    titleDialogTitle: {
      fontSize: "13px !important",
    },
    subTitleDialogTitle: {
      fontSize: "15px !important",
    },
    subParagraphDialog: {
      fontSize: "11px",
    },
    paragraphDialog: {
      fontSize: $elevenPx,
    },
    linkDialog: {
      fontSize: $elevenPx,
      color: $lightblue,
      cursor: "pointer",
    },
    titleDialogContent: {
      color: $blue,
      fontSize: "13px",
      fontWeight: "600",
    },
    paddingContent: {
      zIndex: 0,
      padding: "0 !important",
      borderRadius: "15px",
    },

    infoButtonModal: {
      whiteSpace: "nowrap",
      fontSize: "12px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
    },

    /**********************************************************/

    /**************** BANNER ************************/
    imageBanner: {
      margin: "20px",
      height: $oneHundredPercentImportant,
      borderRadius: "20px",
      overflow: $hiddenImportant,
      boxShadow: $bottomBoxShadow,
      gridTemplateColumns: "none !important",
      [theme.breakpoints.down(600)]: {
        display: "none",
      },
    },
    titleBanner: {
      "&>div>div": {
        fontSize: "22px !important",
        fontWeight: "600",
      },
    },
    /****************************************/

    /******************CARDPHOTO*********************/
    imageListCard: {
      display: $flexImportant,
      flexWrap: "nowrap",
      transform: "translate(0)",
      width: $oneHundredPercent,
      height: "262px",
      [theme.breakpoints.down(768)]: {
        flexWrap: "wrap",
        height: $fitContent,
        justifyContent: "center",
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: "20px",
      },
      "&>li": {
        zIndex: "1",
        height: "215px !important",
        fontWeight: "bold",
        margin: "0 10px 10px 10px",
        borderRadius: "30px",
        boxShadow: $boxShadowCards,
        flexBasis: "auto",
        flexGrow: "0",
        width: $twentyThreePerCent,
        padding: $zeroPx,
        flexShrink: "0",
        [theme.breakpoints.up(1000)]: {
          // flexGrow: 0,
          width: "450px !important",
        },
        [theme.breakpoints.down(1000)]: {
          //  flexGrow: 1,
          width: "450px !important",
        },
        [theme.breakpoints.down(770)]: {
          //  flexGrow: 1,
          width: $ninetyFivePerCent,
          marginLeft: $zeroPx,
          marginRight: $zeroPx,
          minWidth: "270px",
          maxWidth: "510px",
          flexGrow: $zeroPx,
        },
        "&>div": {
          borderBottomLeftRadius: "30px",
          borderBottomRightRadius: "30px",
        },
        "&>img": {
          borderRadius: "30px",
          [theme.breakpoints.down(770)]: {
            objectFit: "fill !important",
            objectPosition: "center",
          },
        },
      },
    },

    titleImageListCard: {
      backgroundColor: "#002856 !important",
      opacity: "0.7",
      height: "38%",
      "&>div>div": {
        fontSize: $eighteenPx,
        fontWeight: "600",
      },
    },
    btnTextCardPhoto: {
      paddingTop: "15px",
      color: "white",
      fontWeight: "bold",
      [theme.breakpoints.down(400)]: {
        paddingTop: "0px",
      },
    },

    /****************************************/

    /************ Open Dialog Activities *******************/
    titleDialogActivities: {
      paddingTop: "12px",
      fontSize: "20px !important",
    },
    openDialogActivities: {
      color: $whiteColor,
    },
    paragraphActivities: {
      fontSize: "18px",
      marginBottom: "15px",
    },

    cardMobile: {
      color: $darkblue,
      backgroundColor: $lightgray,
      position: "relative",
      height: "350px !important",
      overflow: "scroll",
      width: $oneHundredPercentImportant,
      paddingLeft: "20px !important",
      paddingRight: "20px !important",
      borderBottomLeftRadius: "40px",
      borderBottomRightRadius: "40px",
      bottom: "28px",
      paddingTop: $eighteenPx,
    },

    homeNewsAndActivitiesCard: {
      color: "#002858 !important",
      backgroundColor: $lightgray,
    },

    closeActivitiesButton: {
      padding: "5px",
      position: "absolute",
      right: "0",
      top: "0",
      color: $darkblue,
      backgroundColor: $lightgray,
      border: "1px solid #002858",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    /****************************************/

    /************Mapa */

    customBtnMaps: {
      alignSelf: "left",
      padding: "14px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      height: "27px",
      width: "90px",
      fontSize: "8px",
      fontWeight: "500",
      marginTop: "-15px",
      marginButton: "20px",
    },

    PhoneIcon: {
      color: "white",
      width: "70px",
      borderRadius: "20px",
      padding: "5px",
      alignSelf: "center",
      fontSize: "8px",
      marginRight: "7px",
      background: $lightLinearGradientReverse,
      height: "27px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      boxShadow: $boxShadowButtons,
      fontWeight: "600",
    },

    /*****Sedes */
    LocationCity: {
      color: "white",
      borderRadius: "20px",
      backgroundColor: $darkblue,
    },

    txtTitlebranchOffice: {
      fontFamily: $arialRegular,
      fontSize: "20px",
      color: "#0075C9",
      marginLeft: "10PX",
    },

    cardStyle: {
      maxWidth: $oneHundredPercent,
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    paddingCard: {
      paddingTop: "00px",
    },
    paddingCardMobile: {
      paddingTop: "90px",
    },

    custombtn: {
      height: "27px",
      alignSelf: "center",
      padding: "14px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },


    /*****TURNO************ */
    containerOpenAskTurn: {
      width: $oneHundredPercent,
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      paddingTop: "140px",
      marginLeft: `${$drawerWidth}px !important`,
      [theme.breakpoints.down("md")]: {
        display: "none"
      },
    },
    headerOpenAskTurn: {
      position: "fixed !important",
    },

    headerFixTurn: {
      position: "relative",
      zIndex: "1",
      width: $oneHundredPercent,
      backgroundColor: "white",
      [theme.breakpoints.down(550)]: {
        position: "fixed"
      }
    },
    paddingTurnMobile: {
      [theme.breakpoints.down(550)]: {
        paddingTop: "140px"
      }
    },

    cardAppointment: {
      backgroundColor: $lightgray,
      borderRadius: $thirtyPx,
      margin: "10px",
      width: "90%"

    },
    headerSecundary: {
      marginTop: "20px",
      justifyContent: $spaceBetween,
      display: "flex"
    },
    txtSubtitleCalendar: {
      color: $blue,
      fontSize: "12px",
      fontWeight: "bold",
      textAlign: "left",
    },

    txtFilter: {
      color: $blue,
      fontSize: "10px",
      fontWeight: "bold",
      textAlign: "left",
    },
    txtNumberCalendar: {
      color: "#FFF",
      fontSize: "30px",
      fontWeight: "bold",
      textAlign: "center",

    },
    txtCalendar: {
      color: "#FFF",
      fontSize: "10px",
      textAlign: "center",

    },
    FavoriteIcon: {
      color: "red",
    },

    iconPositionFavorite: {
      position: "absolute",
    },

    txtTitleAppointment: {
      fontFamily: $arialRegular,
      fontSize: "24px",
      color: "#0075C9",
      fontWeight: "bold",
      textAlign: "center",
      marginTop: "20px"
    },

    buttonConfirmModal: {
      marginTop: "10px",
      marginRight: "20px",
      width: "20px",
      fontSize: "12px",
      color: "#FFF",
      boxShadow: $boxxShadow,
      fontFamily: $arialSansSerif,
      fontWeight: "600",
      borderRadius: "20px",
      '&:hover': {
        color: $blue,
        background: $whiteColor,
        border: `2px solid ${$blue}`,
      }
    },

    buttonNotConfirmModal: {
      marginTop: "10px",
      width: "20px",
      fontSize: "12px",
      boxShadow: $boxxShadow,
      fontFamily: $arialSansSerif,
      borderRadius: "20px",
      fontWeight: "600",
      backgroundColor: $whiteColor,
      color: $blue,
      border: `2px solid ${$blue}`,
      '&:hover': {
        color: $whiteColor,
        background: $lightLinearGradientReverse,
      }
    },

    imageListCardTurn: {
      display: $flexImportant,
      flexWrap: "nowrap",
      transform: "translate(0)",
      width: $oneHundredPercent,
      height: $fitContent,
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        height: $fitContent,
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: "20px",
      },
      [theme.breakpoints.down(768)]: {
        alignItems: 'center'
      },
      "&>li": {
        zIndex: "1",
        height: "200px !important",
        fontWeight: "bold",
        margin: "0 10px 10px 10px",
        borderRadius: "30px",
        boxShadow: $boxShadowCards,
        flexBasis: "auto",
        flexGrow: "0",
        padding: $zeroPx,
        flexShrink: "0",

        "&>div": {
          borderBottomLeftRadius: "30px",
          borderBottomRightRadius: "30px",
        },
        "&>img": {
          borderRadius: "30px",
        },
      },
    },

    txtCardDescription: {
      fontSize: "10px",
      color: "#585858",
    },

    txtCardQuestionDesc: {
      fontSize: "12px",
      color: "#585858",
      marginLeft: "2.5%",
    },

    txtTitleQuestion: {
      fontFamily: $arialRegular,
      fontSize: "16px",
      color: "#0075C9",
      marginLeft: "10PX",
    },

    FavoriteIconRound: {
      color: "white",
      borderRadius: "20px",
      backgroundColor: $lightblue,
      float: "right",
      padding: "4px",
    },
    RecordIconRound: {
      color: "white",
      borderRadius: "20px",
      backgroundColor: $darkblue,
      float: "right",
      padding: "4px",
    },
    QuestionIconRound: {
      color: "white",
      borderRadius: "20px",
      backgroundColor: $darkgray,
      float: "right",
      padding: "4px",
    },
    NextAppointmentIconRound: {
      color: "white",
      borderRadius: "20px",
      backgroundColor: $blue,
      float: "right",
      padding: "4px",
    },

    relative: {
      position: "relative !important",
    },
    dropdown: {
      flexWrap: "wrap",
      position: "absolute",
      zIndex: 9999,
      boxShadow: $boxxShadow,
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      marginLeft: "-240px",
      borderRadius: "5px",
    },
    dividerFilter: {
      background: "#CDCDCD",
      marginLeft: "00px",
      marginRight: "00px",
      height: "1px",
      width: $oneHundredPercent,
    },
    btnFilterAppointment: {
      Size: "12px",
      fontWeight: "bold",
      textAlign: "center",
      color: $blue,
      backgroundColor: "#FFF",
      marginRight: "18px",
      height: "40px",
      fontSize: "12px",
    },

    iconFilter: {
      color: $blue,
    },
    /*****Switch form */
    formSwitch: {
      width: $oneHundredPercent,
      "&>div>label": {
        justifyContent: $spaceBetween,
      },
    },
    /*Calendar Cards*/
    calendarDetail: {
      border: `2px solid ${$blue}`,
      backgroundColor: $tolightgray,
    },

    /************ASK TURN  **************/
    drawerTurn: {
      flexGrow: "0",
    },

    drawerPaperTurn: {
      paddingTop: "180px",
      width: `${$drawerWidth}px`,
      zIndex: "0",
      boxShadow: $boxShadowCards,
      padding: "20px",
      [theme.breakpoints.down("md")]: {
        width: $oneHundredPercentImportant,
      },
      [theme.breakpoints.down(500)]: {
        paddingBottom: "40px",
      },

    },

    fixAskTurn: {
      display: "flex",
      width: $oneHundredPercent,
      [theme.breakpoints.down(1200)]: {
        flexDirection: "column"
      },
    },

    containerNewAppointment: {
      padding: "30px 50px 10px 50px",
      [theme.breakpoints.down(1200)]: {
        padding: "10px 10px 10px 10px",
      },
    },

    divBoxFirstTurn: {
      width: "50%",
      padding: "30px",
      [theme.breakpoints.down(1200)]: {
        width: $oneHundredPercent,
        padding: "10px 30px 10px 30px",
      },
      [theme.breakpoints.down(768)]: {
        width: $oneHundredPercent,
      },
      [theme.breakpoints.down(500)]: {
        padding: "10px",
      },
    },

    colorIconStepper: {
      color: `#0075c9 !important`,
    },

    modalityTurn: {
      borderRadius: "20px",
      padding: "20px",
      width: 'auto',
      margin: '15px 0',
      height: 'auto',
    },

    drawerContainerTurn: {
      overflow: "auto",
      [theme.breakpoints.down(768)]: {
        paddingTop: "15px",
      },
      '&::-webkit-scrollbar': {
        width: 0,
      },
    },

    wizardLine: {
      borderColor: $lightgray,
      borderTopWidth: "3px",
    },

    wizardLineActive: {
      borderColor: $blue,
      borderTopWidth: "3px",
    },

    firstStepperTurn: {
      paddingTop: "0px",
      marginLeft: "30%",
      marginRight: "30%",
      [theme.breakpoints.down(768)]: {
        paddingTop: "15px",
        marginLeft: "0%",
        marginRight: "0%",
        '&>.MuiStep-root': {
          paddingLeft: "0px",
          paddingRight: "0px"
        },
      },
    },

    stepperTurn: {
      paddingTop: "0px",
      marginLeft: "30%",
      marginRight: "30%",
      [theme.breakpoints.down(768)]: {
        paddingTop: "15px",
        marginLeft: "0%",
        marginRight: "0%",
        '&>.MuiStep-root': {
          paddingLeft: "0px",
          paddingRight: "0px"
        },
      },
    },

    inputAffiliate: {
      height: "40px",
      width: $oneHundredPercent,
      color: `${$blue} !important`,
      textAlign: $centerImportant,
      fontSize: "13px !important",
      fontFamily: `${$arialSansSerif} !important`,
      fontWeight: $fontBold,
      alignItems: $centerImportant,
      justifyContent: $centerImportant,
      borderRadius: "30px",
      backgroundColor: $tolightgray,
      boxShadow: "0 5px 10px -6px #585858 !important",
      "&>div.MuiInputBase-root": {
        height: $oneHundredPercent,
        width: $oneHundredPercent,
        color: $darkgray,
        fontWeight: "600",
        "&>input": {
          textAlign: "center",
          height: "10px",
        },
        "&>fieldset>legend": {
          width: $zeroPx,
        },
      },
      "&>.MuiInputLabel-outlined": {
        color: `${$blue} !important`,
        textAlign: $centerImportant,
        fontSize: $twelve,
        fontFamily: `${$arialSansSerif} !important`,
        fontWeight: $fontBold,
        transform: "none !important",
        top: $autoImportant,
        left: $autoImportant,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "86%",

      },
      "&>.MuiFormHelperText-root.Mui-error": {
        position: $absoluteImportant,
        top: "42px !important",
      },
      "&>.MuiOutlinedInput-root": {
        borderRadius: $thirtyPx,
      },
      "&>.MuiInputBase-fullWidth": {
        height: $oneHundredPercentImportant,
        "&.MuiInputBase-fullWidth > svg.MuiSelect-icon.MuiSelect-iconOutlined":
        {
          display: "none !important",
        },
      },
      "&>.MuiInputLabel-outlined.MuiInputLabel-shrink": {
        display: "none",
      },
      "&>.MuiOutlinedInput-adornedEnd  > div > button": {
        color: $colorTransparent,
      },
      "&>.Mui-focused > fieldset.MuiOutlinedInput-notchedOutline": {
        border: "none !important",
        "&:focus": {
          border: "none !important",
        },
      },
    },

    afilliateSelectTurn: {
      width: $fourHundredPx,
      [theme.breakpoints.down(901)]: {
        paddingTop: $tenPx,
        paddingBottom: $eightPx,
        margin: "8px"
      },
      [theme.breakpoints.down(768)]: {
        width: $oneHundredPercentImportant,
        margin: "0px"
      },
      '&>.MuiOutlinedInput-root': {
        borderRadius: "40px",
        [theme.breakpoints.down(901)]: {
          margin: $zeroPx
        },
        [theme.breakpoints.down(768)]: {
          width: $oneHundredPercentImportant,
        },
      }
    },

    deploymentSelectTurn: {
      maxHeight: $threeHundredPx,
      [theme.breakpoints.down(1200)]: {
        top: "360px !important",
      },
      [theme.breakpoints.down(957)]: {
        top: $threeHundredTenPx,
        left: "340px !important",

      },
      [theme.breakpoints.down(768)]: {
        top: "270px !important",
        left: "75px !important",
      },
      [theme.breakpoints.down(500)]: {
        left: "20px !important",
      },
      '&>ul': {
        color: "#000000",
        '&>li': {
          fontWeight: $sixHundredWeight,
          [theme.breakpoints.down(600)]: {
            minHeight: "auto"
          },
        }
      }
    },

    switchColor: {
      "&> .MuiSwitch-track": {
        backgroundColor: "gray",
        border: '1px solid gray',
      },
      "& .Mui-checked": {
        color: $blue,
        "& >.MuiSwitch-track": {
          backgroundColor: 'gray',
          border: `1px solid ${$blue}`,
        },
      },
      "&> .Mui-checked+.MuiSwitch-track": {
        backgroundColor: 'gray',
        border: `1px solid gray`,
      },
      "& .MuiSwitch-thumb": {
        border: '1px solid gray',
      },
      "& .Mui-checked .MuiSwitch-thumb": {
        border: `1px solid ${$blue}`,
      },
    },
    txtSwitchActive: {
      fontFamily: $arialRegular,
      fontSize: "28px",
      color: $lightblue,
    },

    listItemTurn: {
      display: "flex",
      justifyContent: $spaceBetween,
      alignItems: "center",
      padding: "0px 15px",
    },

    titleAuthorization: {
      marginLeft: "1.8% !important",
      [theme.breakpoints.down("768")]: {
        marginLeft: "4.5% !important",
      },
    },

    titleTurn: {
      fontFamily: $arialRegular,
      fontSize: "20px",
      color: $blue,
      fontWeight: 'bold',
      marginTop: '15px',
      paddingLeft: '20px',
      [theme.breakpoints.down("600")]: {
        fontSize: "16px",
        marginTop: '15px',
      },
    },
    subtitleTurn: {
      fontFamily: $arialRegular,
      fontSize: "16px",
      color: $blue,
      fontWeight: 'bold',
      marginTop: "10px",
      [theme.breakpoints.down("600")]: {
        fontSize: "12px",
      },
    },
    btnAskTurn: {
      [theme.breakpoints.down("600")]: {
        top: $fivePx,
        height: "20px !important",
        flex: '1 !important'
      },
    },
    buttonsAskTurn: {
      position: "absolute",
      display: "flex",
      right: "20px",
      bottom: "20px",
      top: "100px",
      justifyContent: $spaceBetween,
      flexDirection: "column",
      [theme.breakpoints.down("768")]: {
        right: "0",
        top: "5%",
        justifyContent: "center",
        flexDirection: "initial",
        position: "relative",
        marginBottom: "20px !important",
      }

    },

    /* Medical Center Drawer */
    dividerMedicalCenter: {
      background: "#CDCDCD",
      height: "1px",
    },
    locationIcon: {
      color: $blue,
    },
    ubicationText: {
      fontWeight: "bold",
      fontSize: "13px",
      color: $blue,
    },
    accordionDrawer: {
      borderRadius: $thirtyPx,
      backgroundColor: $tolightgray,
      "&>div>div": {
        justifyContent: "center",
        "&>p": {
          color: $blue,
          fontWeight: 'bold'
        }
      }
    },
    boxPointsDrawer: {
      display: 'flex',
      justifyContent: 'flex-start'
    },
    box15x15: {
      width: '15px !important',
      height: '15px !important',
    },
    boxPointsDrawer_AgendaSusp: {
      backgroundColor: '#ffba3a',
      height: '10px',
      borderRadius: '50px',
      width: '10px',
      alignSelf: 'center'
    },
    boxPointsDrawer_TurnoTelef: {
      backgroundColor: '#6bf36b',
      height: '10px',
      borderRadius: '50px',
      width: '10px',
      alignSelf: 'center'
    },
    boxPointsDrawer_DemandaEspon: {
      backgroundColor: '#A51C30',
      height: '10px',
      borderRadius: '50px',
      width: '10px',
      alignSelf: 'center'
    },
    boxPointsDrawer_Seleccionado: {
      backgroundColor: $blue,
      borderRadius: $zeroPx
    },
    boxPointsDrawer_Disponible: {
      backgroundColor: $lightGreen,
      borderRadius: $zeroPx,
    },
    boxPointsDrawer_SinTurno: {
      backgroundColor: $tolightgray,
      borderRadius: $zeroPx
    },
    boxPointsDrawer_Licencia: {
      backgroundColor: 'red',
      borderRadius: $zeroPx
    },
    colorsLegends: {
      [theme.breakpoints.up(900)]: {
        position: 'relative',
      },
      [theme.breakpoints.down(900)]: {
        bottom: '42px',
      },
      paddingLeft: '27px',
      [theme.breakpoints.down(500)]: {
        bottom: '45px',
      }
    },
    cancelBtn: {
      backgroundColor: 'transparent',
      color: 'white',
      width: '20px',
      height: '20px',
      fontSize: '15px',
      boxShadow: 'none',
      borderRadius: '5px',
      '&:hover': {
        backgroundColor: 'white',
        color: $blue,
      }
    },
    accordionMedicalCenter: {
      display: "flex",
      justifyContent: $spaceBetween,
      paddingLeft: "30px",
      paddingRight: "30px",
      [theme.breakpoints.down(1200)]: {
        flexDirection: "column",
      },
      [theme.breakpoints.down(500)]: {
        paddingLeft: "10px",
        paddingRight: "10px",
      }

    },

    /* Next Appointment Drawer  */
    btnNextAppointment: {
      width: $oneHundredPercent,
      borderRadius: "20px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: $spaceBetween,
      background: "#FFF",
      borderColor: "#0075c9",
      border: "solid",
      marginBottom: "5px",
      color: "#0074C9",
      '&:hover': {
        background: "#0075c9",
        '&>span>div>p': {
          color: "#FFF !important"
        },
      }
    },

    btnHoverNextAppointment: {
      width: $oneHundredPercent,
      borderRadius: "20px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: $spaceBetween,
      marginBottom: "5px",
      color: "#FFF",
      background: "#0074C9",
      '&:hover': {
        background: "#0075c9",
        '&>span>div>p': {
          color: "#FFF !important",
        },
      },
    },

    divDate: {
      borderRadius: "20px",
      width: $oneHundredPercent,
      height: "25px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignContent: "center"
    },
    dateNext: {
      fontSize: "11px",
      fontWeight: "bold",
      marginRight: "5px"
    },
    descripNext: {
      fontSize: "11px",
      fontWeight: "bold"
    },
    divHour: {
      borderRadius: "20px",
      width: $sixtyPx,
      height: "20px",
      background: "white",
      display: "flex",
      alignItems: "center"
    },
    hourNext: {
      padding: "5px",
      color: $blue,
      fontSize: "12px",
      fontWeight: "bold",
      height: $oneHundredPercent,
      width: $oneHundredPercent
    },
    hourBtn: {
      flex: '1 1 10%',
      margin: '12px',
      color: $darkgray,
      maxWidth: '10%',
      boxShadow: "none",
      '&:hover': {
        backgroundColor: $blue,
        color: 'white',
      }
    },
    hourSelected: {
      color: $colorWhite,
      backgroundColor: $blue
    },

    /* Home boxes */
    divHomeBox: {
      display: 'flex',
      borderRadius: '40px',
      paddingTop: '40px',
      marginTop: '20px',
      [theme.breakpoints.up(1200)]: {
        height: '67vh',
      },
      [theme.breakpoints.down(1200)]: {
        flexWrap: 'wrap',
        height: '65vh'
      }
    },
    homeBoxTitle: {
      justifyContent: 'center',
      flexWrap: 'wrap',
      [theme.breakpoints.down(1200)]: {
        justifyContent: $spaceBetween,
        paddingLeft: '10px',
        position: 'absolute'
      }
    },
    titleBox: {
      marginLeft: '1.35rem',
      height: '30px',
      marginRight: '20px',
    },
    greetingHome: {
      paddingTop: "20px",
      [theme.breakpoints.down(500)]: {
        paddingTop: "10px",
      },
      [theme.breakpoints.down(400)]: {
        paddingTop: "0px",
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '20px'
      },
    },
    homeBox: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: '1 1 25%',
      overflow: 'auto',
      alignItems: 'flex-start',
      [theme.breakpoints.up(1200)]: {
        minHeight: '500px',
      },
      [theme.breakpoints.down(1200)]: {
        flexWrap: 'nowrap',
        height: $autoImportant,
        flex: $oneOneOneHundredPercent,
        marginBottom: '16px'
      },
      [theme.breakpoints.up(1200)]: {
        flexDirection: 'column',
        height: $oneHundredPercent,
        flexWrap: 'nowrap',
      },
      [theme.breakpoints.down(769)]: {
        justifyContent: 'center',
      },
    },
    boxCard: {
      paddingTop: '20px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      [theme.breakpoints.down(1200)]: {
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        paddingTop: '8px'
      },
      [theme.breakpoints.up(1200)]: {
        width: $oneHundredPercent
      },
      [theme.breakpoints.up(1600)]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    boxCredential: {
      paddingTop: '50px',
      height: "250px",
    },
    cardNextAppointmentHome: {
      maxWidth: '360px',
      [theme.breakpoints.down(1200)]: {
        width: '350px'
      }
    },
    credentialCard: {
      background: $lightLinearGradient,
      height: '195px'
    },
    credentialLogoPlan: {
      [theme.breakpoints.down(501)]: {
        paddingInlineStart: '7%',
        paddingInlineEnd: '6%',
      },
      [theme.breakpoints.up(501)]: {
        marginInlineStart: '-17px',
        paddingInlineEnd: '0',
      }
    },
    credentialPlan: {
      display: 'flex',
      justifyContent: 'flex-end',
      background: 'white',
      marginRight: '40px',
      borderRadius: '0px 0px 10px 10px',
      '&>.MuiTypography-root': {
        paddingLeft: $zeroPx,
      }
    },
    txtBoxCredential: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingTop: '5%',
      paddingRight: "40px",
    },
    txtBoxCredentialBottom: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingTop: '10px',
      paddingBottom: "20px"
    },
    txtBoxCredentialName: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: '18px',
      paddingTop: '10px',
      paddingBottom: "20px"
    },
    divTxtBoxCredential: {
      flex: $oneOneOneHundredPercent,
      paddingLeft: '18px',
      paddingBottom: '5px'
    },
    divTxtCredentialFooter: {
      flex: '1 1 60%',
      paddingLeft: '4%',
      paddingBottom: '5px',
      display: 'flex',
      paddingTop: '2%'
    },
    txtCredential: {
      fontWeight: 'bold',
      color: 'white'
    },
    /*Modal emergency */
    emergencyNumbersTitle: {
      color: $colorWhite,
      textTransform: 'uppercase',
      width: $oneHundredPercentImportant,
      justifyContent: 'flex-start'
    },
    emergencyModalIcons: {
      color: $colorWhite,
      textTransform: 'uppercase',
    },
    txtPhoneModalEmergencies: {
      fontWeight: 'bold',
      fontSize: '16px !important',
      color: $lightblue,
      width: $oneHundredPercentImportant,
      justifyContent: 'flex-start'
    },
    Redirect: {
      marginTop: '50px',
      overflow: "hidden",
    },
    iframe: {
      height: "100vh",
      overflowY: "auto",
      width: "calc(100% + 10px)",
    },
    /*Confirm Turn*/
    textConfirmTurn: {
      textAlign: $centerImportant,
      fontFamily: `${$arialSansSerif} !important`,
      alignItems: $centerImportant,
      justifyContent: $centerImportant,
      backgroundColor: $lightgray,
      height: "35px",
      width: $oneHundredPercent,
      borderRadius: "20px",
      overflow: $hiddenImportant,
      "&>div.MuiInputBase-root": {
        fontSize: $fontSize14,
        width: $oneHundredPercent,
        height: $oneHundredPercent,
      },
      "&>.MuiInputLabel-outlined": {
        textAlign: $centerImportant,
        fontSize: $twelve,
        fontFamily: `${$arialSansSerif} !important`,
        fontWeight: $fontBold,
        transform: "none !important",
        top: $autoImportant,
        left: $autoImportant,
        overflow: $hiddenImportant,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "86%",
      },
    },
    turnConfirm: {
      boxShadow: "none !important",
      fontSize: "15px",
      "&:hover": {
        color: $blue,
        backgroundColor: "white",
      }
    },
    iconConfirmTurn: {
      backgroundColor: "white",
      color: $blue,
      padding: "5px 9px 5px 9px",
      borderRadius: "50%",
    },
    accordionConfirmTurn: {
      paddingLeft: $zeroPx,
      '&> .MuiAccordionSummary-content > .MuiTypography-root': {
        fontSize: $fontSize14,
        fontWeight: $sixHundredWeight,
      }
    },



    /* Datos Personales: */
    personalDataItem: {
      flex: '1 1 40%',
      paddingLeft: '20px',
      paddingRight: '20px'
    },
    personalDataDivIcons: {
      height: '122px !important',
      width: $oneHundredPercent,
      minWidth: '110px',
    },
    wayToPay: {
      [theme.breakpoints.down(768)]: {
        minWidth: '160px !important',
      }
    },
    personalDataIcon: {
      fontSize: '65px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: $fiftyFivePx
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '50px !important'
      }
    },
    personalDataTxtIcon: {
      fontSize: '16px !important'
    },
    personalDataBtnEdit: {
      fontSize: "10px",
      fontWeight: "600",
      textTransform: 'capitalize',
      padding: "14px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      height: "35px",
      width: "130px",
      marginRight: "5em",
    },
    downloadVaccinesBtn: {
      fontSize: "10px",
      fontWeight: "600",
      textTransform: 'capitalize',
      padding: "14px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      height: "35px",
      width: "168px",
      textDecoration: "none",
      marginRight: "5em",
    },
    downloadTermsAndConditionsBtn: {
      fontSize: "10px",
      fontWeight: "600",
      textTransform: 'capitalize',
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      height: "35px",
      width: "150px",
      textDecoration: "none",
    },
    titlePersonalDataForm: {
      backgroundColor: $lightgray,
      paddingTop: '70px',
      paddingBottom: '5px'
    },
    addressForm: {
      display: 'grid',
      gridTemplateColumns: $templateColumnsFour,
      [theme.breakpoints.down(1300)]: {
        gridTemplateColumns: $templateColumnsThree
      },
      [theme.breakpoints.down(1100)]: {
        gridTemplateColumns: '1fr 1fr'
      },
      [theme.breakpoints.down(900)]: {
        gridTemplateColumns: '1fr'
      },
      justifyContent: $spaceBetween,
      flexWrap: 'wrap',
      paddingTop: '10px',
      paddingLeft: '5rem',
      alignItems: 'center',
      [theme.breakpoints.down(1450)]: {
        paddingLeft: '0rem',
      },
      [theme.breakpoints.down(780)]: {
        justifyContent: 'center',
      },


    },
    divSubtitleAddresForm: {
      display: 'flex',
      flex: $oneOneOneHundredPercent,
      paddingTop: '10px'
    },
    subtitleAddressForm: {
      fontSize: $twelve,
      textTransform: 'uppercase',
      alignItems: 'center',
      display: 'flex'
    },
    divSubtitleEditBtn: {
      justifyContent: `${$spaceBetween} !important`
    },

    labelSelectMobile: {
      transformOrigin: "bottom !important",
      fontSize: "12px",
      marginLeft: "2.3%",
      position: "relative"
    },
    labelSelectWeb: {
      transformOrigin: "top left !important",
      fontSize: "12px",
      marginLeft: "2.3%",
      position: "relative"
    },
    divEditBtn: {
      width: $oneHundredPercent,
      display: $flexImportant,
      flexDirection: "row-reverse",
      marginRight: $fiftyPx,
      marginTop: "20px"
    },

    divButtonsAttachment: {
      flex: "1 1 25%",
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
    },
    divAttachment: {
      flex: "1 1 25%",
      flexDirection: "column",
      display: "flex",
      alignItems: "left",
    },

    spanError: {
      width: $oneHundredPercent,
      marginTop: "0.25rem",
      marginLeft: "25px",
      color: "rgb(240, 80, 80)",
      fontWeight: "bold",
      fontSize: "11px",
    },
    txtError: {
      color: "rgb(240, 80, 80)",
      fontSize: "12px",
      fontWeight: "bold",
      textAlign: "left",
    },
    customSelectWeb: {
      width: "250px",
      paddingTop: "10px",
      paddingBottom: "8px",
    },
    customSelectMobile: {
      marginTop: "30px",
      width: "90% ",
    },

    formControlAddress: {
      marginBottom: "10px",

      flex: '1 1 25%',
      // paddingTop: '1rem',
      [theme.breakpoints.down(1300)]: {
        flex: '1 1 33%',
        '&>div': {
          width: $ninetyFivePerCent
        }
      },
      [theme.breakpoints.down(1100)]: {
        flex: '1 1 50%',
        '&>div': {
          width: $ninetyFivePerCent
        }
      },
      [theme.breakpoints.down(900)]: {
        flex: $oneOneOneHundredPercent
      }
    },

    formBiggerSelect:{
      '&>div': {
        width: "52% !important",
      }
    },

    formControlCustomSelect: {
      marginBottom: "10px",
      top: "3px !important",
      '&>div>div>div': {
        padding: "11px 10px"
      },
      flex: '1 1 25%',
      [theme.breakpoints.up(900)]: {
        '&>div>div': {
          marginTop: "6%"
        },
      },
      [theme.breakpoints.down(1300)]: {
        flex: '1 1 33%',
        '&>div': {
          width: $ninetyFivePerCent,
          marginLeft: "10px",
        }
      },
      [theme.breakpoints.down(1100)]: {
        top: $zeroPx,

        flex: '1 1 50%',
        '&>div': {
          width: $ninetyFivePerCent
        }
      },
      [theme.breakpoints.down(900)]: {
        flex: $oneOneOneHundredPercent,
        marginTop: "15px"
      }
    },

    formControlDatePicker: {
      marginBottom: "0px",

      '&>div input': {
        padding: "11px 10px",
      },
      flex: '1 1 25%',
      [theme.breakpoints.down(1300)]: {
        top: "-1px !important",

        '&>div': {
          width: $ninetyFivePerCent,
          marginLeft: "10px",
        },
      },
      [theme.breakpoints.down(1100)]: {
        flex: '1 1 50%',
        top: "-2px !important",
        '&>div': {
          width: $ninetyFivePerCent,
          '&>div': {
            marginLeft: "0%",
            '&>label': {
              marginLeft: "5%",
            },
          },
        }
      },
      [theme.breakpoints.down(900)]: {
        flex: '1 1 95%',
        '&>div': {
          width: '90% !important',
          '&>label': {
            marginLeft: "5%",
          },
          '&>div': {
            marginLeft: "5%",
            marginRight: "5%",
          },
        }
      },
      [theme.breakpoints.up(900)]: {
        '&>div>div': {
          marginTop: "3%",
        },
      }
    },

    formDivider: {
      marginLeft: '1.4rem',
      marginRight: '1.4rem',
      [theme.breakpoints.down(769)]: {
        marginLeft: '0rem',
        marginRight: '0rem',
      }
    },
    divBtnsAddressForm: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: '5rem',
      [theme.breakpoints.down(769)]: {
        paddingRight: '2rem',
        justifyContent: 'space-around',
      }
    },
    legendSubtitleForm: {
      display: "flex",
      marginLeft: "10px",
      color: $blue,
      fontSize: "11px",
      fontStyle: "italic",
      [theme.breakpoints.down(769)]: {
        marginLeft: "5%",
        marginRight: "8%",
      },
    },
    margin0: {
      margin: $zeroPx
    },
    marginn0: {
      margin: $zeroPx,
      left: '0',
      position: 'absolute',
      width: $oneHundredPercent,
      [theme.breakpoints.up(960)]: {
        width: $oneHundredPercent,
        height: $oneHundredPercent,
        left: '0',
        zIndex: '15',
        background: '#fff',
      }
    },
    marginLeft: {
      marginLeft: '10px'
    },
    formControl: {
      width: "250px",
      marginLeft: "3px",
      [theme.breakpoints.down(900)]: {
        width: $oneHundredPercent
      }
    },
    customizedVitalParameterForm: {
      padding: '0 10px',
      width: '50%'
    },
    inputLabelForms: {
      overflow: "initial",
      [theme.breakpoints.down(900)]: {
        width: "80%",
        marginLeft: "8%",
      }
    },
    inputLabelSelectForms: {
      overflow: "initial",
      [theme.breakpoints.up(900)]: {
        width: "80%",
        marginLeft: "-3%",
      },
      [theme.breakpoints.down(900)]: {
        width: "80%",
        marginLeft: "3%",
      }
    },
    customInput: {
      [theme.breakpoints.down(900)]: {
        width: "90%",
        marginLeft: "5%",
      }
    },
    /** Form Contact Data */
    contactDataForm: {
      display: 'flex',
      [theme.breakpoints.down(768)]: {
        flexDirection: "column",
        alignContent: "center",
      }
    },
    divFormContactData: {
      
      flexWrap: "wrap !important",
      paddingTop: '10px',
      paddingLeft: "10rem",
      paddingRight: "8rem",
      width: "50% !important",
      marginBottom: "10%",
      [theme.breakpoints.down(1800)]: {
        paddingLeft: "8rem",
        paddingRight: "6rem",
      },
      [theme.breakpoints.down(1750)]: {
        paddingLeft: "6rem",
        paddingRight: "6rem",
      },
      [theme.breakpoints.down(1610)]: {
        paddingLeft: "4rem",
        paddingRight: "4rem",
      },
      [theme.breakpoints.down(1580)]: {
        paddingLeft: "3rem",
        paddingRight: "3rem",
      },
      [theme.breakpoints.down(1380)]: {
        paddingLeft: "2rem",
        paddingRight: "2rem",
      },
      [theme.breakpoints.down(281)]: {
        paddingLeft: "1rem",
      },
      [theme.breakpoints.down(768)]: {
        width: $oneHundredPercentImportant,
      },
    },
    formControlContactData: {
      paddingTop: '1.5rem',
      marginRight: "0",
      [theme.breakpoints.down(1004)]: {
        width: $ninetyFivePerCent
      },
      '&>.MuiFormControl-root': {
        [theme.breakpoints.down(1230)]: {
          width: $ninetyFivePerCent
        },
        [theme.breakpoints.down(768)]: {
          width: "240px !important",
        },
      },
      [theme.breakpoints.down(1444)]: {
        paddingRight: "0",
      },
      [theme.breakpoints.down(1000)]: {
        paddingRight: $zeroPx,
      },
      [theme.breakpoints.down(768)]: {
        '&>div': {
          width: $oneHundredPercentImportant,
          marginLeft: $zeroPx,
          '&>label': {
            marginLeft: $fivePercent,
          },
        },
      }
    },
    inputContactDataBefore: {
      [theme.breakpoints.down(768)]: {
        marginRight: "3px",
      },
      '&>.MuiFormControl-root': {
        marginLeft: "0",
        marginRight: "3px",
        width: "70px",
        '&>.MuiInputBase-root': {
          [theme.breakpoints.down(900)]: {
            width: $oneHundredPercentImportant,
            marginLeft: $zeroImportant,
          },
        }
      }
    },

    inputContactDataBeforeCountryCode: {
      [theme.breakpoints.down(768)]: {
        marginRight: "3px",
      },
      [theme.breakpoints.up(768)]: {
        marginRight: "10px",
      },
      '&>.MuiFormControl-root': {
        marginLeft: "0",
        marginRight: "3px",
        width: "55px",
        '&>.MuiInputBase-root': {
          [theme.breakpoints.down(900)]: {
            width: $oneHundredPercentImportant,
            marginLeft: $zeroImportant,
          },
        }
      }
    },

    inputContactData: {
      '&>.MuiFormControl-root': {
        marginLeft: "0%",
        width: "150px",
        [theme.breakpoints.down(1310)]: {
          width: "130px",
        },
        [theme.breakpoints.down(1240)]: {
          width: "120px",
        },
        [theme.breakpoints.down(1120)]: {
          width: "110px",
        },
        [theme.breakpoints.down(1004)]: {
          width: "110px",
        },
        [theme.breakpoints.down(900)]: {
          width: "110px",
        },
        [theme.breakpoints.down(855)]: {
          width: "80px",
        },
        [theme.breakpoints.down(800)]: {
          width: "90px",
        },
        [theme.breakpoints.down(768)]: {
          width: "150px !important",
        },
        [theme.breakpoints.down(700)]: {
          width: $oneHundredFortyPx,
        },
        [theme.breakpoints.down(555)]: {
          width: "115px !important",
        },
        '&>.MuiInputBase-root': {
          [theme.breakpoints.down(900)]: {
            width: $oneHundredPercentImportant,
            marginLeft: "0% !important",
          },
        }
      }
    },
    legendSubtitleCel: {
      marginLeft: "10px",
      color: $blue,
      fontSize: "11px",
      fontStyle: "italic",
    },
    dividerVertical: {
      marginTop: '1.4rem',
      marginBottom: '1.4rem',
    },
    /**Form Refunds */
    refundForm: {
      justifyContent: "flex-start !important"
    },
    mailFormRefund: {
      '&>.MuiFormControl-root': {
        width: $oneHundredPercentImportant,
        [theme.breakpoints.down(1000)]: {
          width: $oneHundredPercentImportant,
        },
        [theme.breakpoints.down(768)]: {
          width: "90% !important",
        },
        '&>.MuiInputBase-root': {
          width: "350px !important",
          [theme.breakpoints.down(1000)]: {
            width: $oneHundredPercentImportant,
          },
          [theme.breakpoints.down(900)]: {
            width: "90% !important",
          },
        }
      }
    },
    /**Form Hobbies */
    divSportsHobbies: {
      display: 'flex',
      flex: '1 1 50%',
      flexWrap: 'wrap',
      [theme.breakpoints.down(769)]: {
        flex: $oneOneOneHundredPercent,
      }
    },
    formControlSportsHobbies: {
      flex: '1 1 50%',
      [theme.breakpoints.down(1300)]: {
        flex: $oneOneOneHundredPercent,
      }
    },
    divCustomSelectHobbies: {
      flex: $oneOneOneHundredPercent,
      justifyContent: $spaceBetween,
      alignItems: 'center',
      display: 'flex',
      '&>div:nth-child(1)': {
        flex: $oneOneOneHundredPercent,
      }
    },
    /**Page Credentials */
    boxCardCredentials: {
      paddingTop: '20px',
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.down(801)]: {
        paddingTop: '0px',
      },
      [theme.breakpoints.up('md')]: {
        width: $oneHundredPercent,
      },
      '&>.MuiButton-root>.MuiButton-label>.MuiPaper-root': {
        maxWidth: $oneHundredPercent,
        width: "450px",
        height: $oneHundredPercent,
      },
      [theme.breakpoints.down(960)]: {
        flexDirection: "column",
        flexWrap: 'nowrap',
      },
      [theme.breakpoints.down(960) && theme.breakpoints.up(501)]: {
        alignItems: 'center',
      },
    },
    modalCredentials: {
      padding: $zeroPx,
      margin: $zeroPx,
      '&>.MuiPaper-root': {
        width: $oneHundredPercentImportant,
        margin: $zeroPx,
        borderRadius: $zeroPx,
        maxWidth: $oneHundredPercentImportant,
        height: $oneHundredPercent,
        [theme.breakpoints.down(801)]: {
          margin: $zeroPx
        }
      },
    },
    qrCode: {
      paddingRight: "30px",
      [theme.breakpoints.down(501)]: {
        '&>svg': {
          height: $fiftyHundredPx,
          width: $fiftyHundredPx
        },
      },
    },
    qrCodeCredential: {
      paddingRight: "0px",
      marginLeft: "auto",
      [theme.breakpoints.down(501)]: {
        '&>svg': {
          height: $fiftyHundredPx,
          width: $fiftyHundredPx
        },
      },
    },
    divCredentialsMobile: {
      '&>.MuiButton-root': {
        [theme.breakpoints.down(801)]: {
          paddingBottom: $zeroPx
        },
      },
      '&>.MuiButton-root>.MuiButton-label>.MuiPaper-root': {
        [theme.breakpoints.down(801)]: {
          width: "80%",
          height: $fiftyPx,
          marginTop: $fortyPx,
          marginBottom: $zeroPx,
          borderTopLeftRadius: "15px !important",
          borderTopRightRadius: "15px !important",
          borderBottomLeftRadius: $zeroPx,
          borderBottomRightRadius: $zeroPx
        },
      },
    },
    btnCredentialsMobile: {
      width: $oneHundredPercent,
      height: "40px",
      backgroundColor: $darkblue,
      color: "white",
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: "10px"
    },
    modalLandscapeMobile: {
      [theme.breakpoints.down(501)]: {
        paddingRight: $zeroPx,
        paddingLeft: "0",
        '&> div > div > p': {
          fontSize: "14px"
        }
      },
    },
    modalLandscapeMobileBottom: {
      [theme.breakpoints.up(300)]: {
        paddingTop: "60px !important",
        [theme.breakpoints.down(501)]: {
          paddingLeft: "7%",
          paddingTop: "5vw",
          [theme.breakpoints.down(351)]: {
            paddingTop: "30px",
          },
          "&> div  > p": {
            fontSize: "14px",
          },
        },
      },
    },

    /**Weight and heigth */
    notRotateIcon: {
      '&>div>div>div': {
        transform: "rotate(0deg) !important"
      }
    },

    subTitleMedicalData: {
      fontFamily: $arialRegular,
      fontSize: "15px",
      color: $darkblue,
      fontWeight: 'bold',
      [theme.breakpoints.down("600")]: {
        fontSize: "13px",
      },
    },
    subTitleMedicalPesoyTalla: {
      fontFamily: $arialRegular,
      fontSize: "15px",
      color: $darkblue,
      fontWeight: 'bold',
      [theme.breakpoints.down("600")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("780")]: {
        display: "none",
      },
    },
    width25: {
      width: "25%"
    },
    width50: {
      width: "50%"
    },

    width100: {
      width: $oneHundredPercent
    },
    txtSubtitleGray: {
      color: $darkgray,
      fontSize: "12px",
      textAlign: "left",
      marginRight: "10px"
    },
    txtSubtitleBlue: {
      color: $blue,
      fontSize: "12px",
      textAlign: "left",     
    },

    headerFiltro: {
      justifyContent: $spaceBetween,
      display: "flex"
    },

    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    fontSize15: {
      fontSize: "17px !important",
      marginBottom: "5px"
    },

    flexRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: $spaceBetween,
    },

    spaceBetweenInputs: {
      [theme.breakpoints.down(1125)]: {
        flex: '1 1 35%',
        '& > div': {
          width: $ninetyFivePerCent
        }
      },
      "& > div": {
        marginLeft: "20px"
      },
      display: "flex",
      flexDirection: "row",
      justifyContent: $spaceBetween,
    },


    AccordionWeightWeb: {
      flexBasic: "300px",
      marginLeft: "20px"
    },

    AccordionWeightMobile: {
      width: $oneHundredPercentImportant,
      marginLeft: "10px"
    },


    datePicker: {
      "& > div > div > div > fieldset, input": {
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
        borderBottom: "1px solid black",
        paddingBottom: "5px",
        paddingTop: "5px"
      }
    },
    txtLabelAddWeight: {
      color: $blue,
      fontSize: "15px",
      textAlign: "left",
    },

    formControl100: {
      flex: '1 1 25%',
      paddingTop: '1rem',
      [theme.breakpoints.down(1125)]: {
        flex: '1 1 35%',
        '&>div': {
          width: $ninetyFivePerCent
        }
      },
      [theme.breakpoints.down(768)]: {
        flex: $oneOneOneHundredPercent,
        width: $ninetyFivePerCent
      }
    },

    centerWeigthForm: {

      [theme.breakpoints.down(1125)]: {
        '& > div > div > div > label': {
          textAlign: 'left',
          marginLeft: '9px !important'
        },
      },
      [theme.breakpoints.down(768)]: {
        textAlign: 'center',
        '& > div > div > label': {
          textAlign: 'left'
        },
        '& > div > div > div > label': {
          textAlign: 'left',
          marginLeft: '0px !important'
        },
      }
    },

    customMarginForm: {
      marginLeft: '30px',
      [theme.breakpoints.down(1125)]: {
        marginLeft: '15px'
      }
    },

    formWeigth: {
      flex: '1 1 25%',
      paddingTop: '1rem',
      marginBottom: '10px',
      [theme.breakpoints.down(1125)]: {
        flex: '1 1 35%',
        '& > div': {
          width: $ninetyFivePerCent,
        },
      },
      [theme.breakpoints.down(768)]: {
        flex: $oneOneOneHundredPercent,
        width: $ninetyFivePerCent,
        marginLeft: '0px !important',
        '& > div > div > div': {
          marginLeft: '0px'
        },
        ' & > div > div': {
          width: '90% !important'
        }
      },
    },

    weigthDateTimePicker: {
      [theme.breakpoints.down(1125)]: {
        marginLeft: '0.7%',
        marginBottom: '5%',
        '& > div > div': {
          width: '82%',
        },
      },
    },

    formSelectVitalParameter: {
      flex: '1 1 35%',
      paddingTop: '1rem',
      marginLeft: '27px',
      [theme.breakpoints.down(1125)]: {
        flex: '1 1 35%',
        '&>div': {
          width: $oneHundredPercent
        }
      },
      [theme.breakpoints.down(768)]: {
        flex: $oneOneOneHundredPercent,
        width: $oneHundredPercent,
        marginLeft: '0px',
      }
    },
    formSelectLabelVitalParameter: {
      position: "relative",
      marginBottom: '-5px',
      [theme.breakpoints.down(768)]: {
        marginBottom: '-20px'
      }
    },
    formSelectInputVitalParameter: {
      height: '45px !important',
    },

    formControlWeight50: {
      flex: '1 1 50%',
      paddingTop: '1rem',
      [theme.breakpoints.down(1125)]: {
        flex: '1 1 25%',
        '&>div': {
          width: $ninetyFivePerCent
        }
      },
      [theme.breakpoints.down(768)]: {
        flex: '1 1 50%',
        paddingTop: '1rem',
      }
    },

    BtnMobileWeight: {
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'flex-end',
      paddingRight: '3rem',
      paddingTop: "30px"
    },
    BtnMobileAddWeight: {
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'flex-end',
      paddingRight: '3rem',
    },
    btnNewWeigth: {
      height: "40px",
      alignSelf: "center",
      padding: "14px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "100px",
      fontSize: $twelve

    },

    flexRowAcordion: {
      display: "flex",
      flexDirection: "row",
      margin: "auto",
      width: $oneHundredPercent,
      flexWrap: "wrap",
    },

    displayNone: {
      display: "none"
    },
    padding0: {
      padding: `0px 0px 0px 0px`,
    },

    heightDivRegisters: {
      overflowY: "scroll",
      height: $fitContent,
      maxHeight: "500px",
      '&::-webkit-scrollbar': {
        width: 0,
      },
    },

    selectDniLogin: {
      "& > div > #mui-component-select-parameterType": {
        backgroundColor: "#fff",
        borderRadius: "30px",
      }
    },

    inputDocument: {
      "& > div > .css-uyi1zz-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
        color: "#FFF !important"
      },
    },

    /* Medical Data */
    subtitleBox: {
      fontSize: $elevenPx,
      color: $darkblue
    },

    titleEmergency: {
      fontSize: '13px'
    },

    emergencyFormsIcons: {
      fontSize: $eighteenPx
    },

    divEmergencyPhonesForm: {
      padding: '0.5%',
      display: 'flex',
      flex: '0 0 23.33%',
      flexWrap: 'nowrap',
      alignItems: 'baseline',
      [theme.breakpoints.up(2000)]: {
        flex: '0 0 21.33%',
      },
      [theme.breakpoints.down(1600)]: {
        flex: '0 0 33%'
      },
      [theme.breakpoints.down(1200)]: {
        flex: '0 0 50%'
      },
      [theme.breakpoints.down(720)]: {
        flex: $oneOneOneHundredPercent
      },
    },

    divInputEmergencyPhones: {
      display: 'flex',
      '&>div': {
        display: 'flex',
        width: '60px',
        '&>div': {
          width: '60px',
        }
      }
    },

    divInputPhone: {
      width: '120px !important',
      '&>div': {
        width: '120px!important'
      }
    },

    divFlexPhones: {
      maxWidth: '30%',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '40%'
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: $oneHundredPercent
      }
    },

    phoneIcon: {
      backgroundColor: $blue,
      borderRadius: '15px',
      color: 'white',
      fontSize: '30px',
      padding: '6px',
    },

    quarterCol: {
      display: 'flex',
      flex: `1 1 ${$fivePercent}`
    },

    divFormControlsEmergency: {
      flex: '1 1 70% !important',
      display: 'flex',
      flexWrap: 'wrap'
    },

    formControlsEmergency: {
      flex: '1 1 90% !important',
      display: 'flex',
      '&>div': {
        width: $oneHundredPercent,
        marginLeft: '0px'
      }
    },

    formControlTelefonoObsevacion: {
      [theme.breakpoints.down(900)]: {
        marginLeft: '-15px',
      },
      [theme.breakpoints.down(769)]: {
        marginLeft: '-25px',
      },
      [theme.breakpoints.down(412)]: {
        marginLeft: '-2px',
      },
      [theme.breakpoints.down(281)]: {
        marginLeft: '-7px',
      },
    },

    formControlSelect: {
      '&>div': {
        width: $oneHundredPercentImportant
      }
    },

    divAllergiesForm: {
      [theme.breakpoints.up(1017)]: {
        flexGrow: '0'
      }
    },

    formControlSwitchAllergies: {
      '&>div>label': {
        margin: '0',
        paddingLeft: '1%',
        width: $oneHundredPercent,
        justifyContent: 'flex-end',
        [theme.breakpoints.down(720)]: {
          justifyContent: $spaceBetween,
          paddingLeft: '5%'
        }
      }
    },
    dateTimerPicker: {
      marginLeft: "20px !important",
      paddingTop: $tenPx,
      paddingBottom: "10px"
    },
    labelDateTimerPicker: {
      margin: "13px",
      marginLeft: "21px",
    },

    top22: {
      '& > div ': {
        top: $zeroPx
      }
    },
    //***vITAL PARAMETERS */
    marginRight: {
      marginRight: "10px"

    },
    /**Chronic Disease */
    chronicDiseaseform: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      paddingTop: '10px',
      paddingLeft: '5rem',
      alignItems: 'center',
      [theme.breakpoints.down(768)]: {
        justifyContent: 'center',
        paddingLeft: '0rem',
      }
    },

    formChronicDisease: {
      display: "flex",
      [theme.breakpoints.down(768)]: {
        width: $oneHundredPercentImportant
      }
    },

    /** Chronic Medication */
    accordionMedicationMobile: {
      width: "70% !important",
      marginLeft: "10px",
      '&>.MuiAccordion-root': {
        boxShadow: "none"
      }
    },
    titleAccordionChronic: {
      textTransform: "uppercase",
      fontSize: "14px"
    },
    detailsAccordionChronic: {
      fontSize: "12px"
    },
    menuItemSelected: {
      [theme.breakpoints.down(768)]: {
        marginLeft: "25px"
      }
    },

    formControlVirtualDoctor:{
      '&>div': {
        marginLeft: "0px !important",
        maxWidth: "250px"
      },
    },

    formControlChronicMedicationForm: {
      top: $zeroPx,
      '&>div': {
        marginLeft: "10px",
        marginBottom: "10px",
      },
      '&>div>div>div': {
        padding: "11px 10px"
      },
      '&>div>div': {
        marginTop: "6%"
      },
      [theme.breakpoints.down(1300)]: {
        flex: '1 1 33%',
        '&>div': {
          width: $ninetyFivePerCent
        }
      },
      [theme.breakpoints.down(1100)]: {
        flex: '1 1 50%',
        '&>div': {
          width: $ninetyFivePerCent
        }
      },
      [theme.breakpoints.down(900)]: {
        flex: $oneOneOneHundredPercent
      }
    },

    formContactUs: {
      '&>div>div': {
        marginTop: "3% !important"
      },
    },

    divAccordionPrograms: {
      flex: '0 0 20%',
      padding: '2%',
      [theme.breakpoints.down(1800)]: {
        flex: '0 0 25.33%',
      },
      [theme.breakpoints.down(1500)]: {
        flex: '0 0 33.33%',
      },
      [theme.breakpoints.down('md')]: {
        flex: $oneOneOneHundredPercent
      }
    },
    /**VACCINE */
    accordionListVaccine: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: '30px',
      paddingLeft: '5rem',
      paddingRight: '5rem',
      [theme.breakpoints.down(850)]: {
        marginLeft: '10px',
        paddingLeft: '0rem',
        paddingRight: '0rem',
      }
    },
    accordionVaccine: {
      width: "320px",
      '&>.MuiAccordion-root': {
        boxShadow: "none",
        '&>.MuiButtonBase-root': {
          minHeight: "0px"
        }
      },
      [theme.breakpoints.down(850)]: {
        width: $ninetyFivePerCent
      }
    },
    titleVaccine: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "20px",
      color: `${$darkblue} !important`
    },
    formControlVaccine: {
      flex: '1 1 90% !important',
      display: 'flex',
      '&>div': {
        width: '100%',
        marginLeft: '0px',
        '&>div': {
          marginTop: '0px',
          [theme.breakpoints.down(900)]: {
            width: $oneHundredPercent,
            marginLeft: "0px"
          }
        }
      }
    },

    programsDataItem: {
      flex: '0 0 20%',
      paddingLeft: '20px',
      paddingRight: '20px',
      [theme.breakpoints.down('md')]: {
        flex: '1 1 30%',
      }
    },
    /**VirtualDoctor*/
    spanVirtualDoctor: {
      display: 'flex',
      fontSize: '0.9rem',
      flex: $oneOneOneHundredPercent,
      fontWeight: '500',
      [theme.breakpoints.down(768)]: {
        flex: 'auto',
        paddingLeft: '0'
      }
    },

    /********NOTIFICATIONS */
    card50: {
      flex: $oneOneOneHundredPercent,
      margin: "10px 20px 10px 20px",
    },
    card100: {
      flex: "0 1 45%",
      margin: "0 5% 20px 5%",
      flexBasis: "40%"
    },

    AllNotifications: {
      marginTop: "20px",
      display: "flex",
      flexDirection: "row",
      justifyContent: $spaceBetween,
      flexWrap: "wrap"
    },

    dropdownNotification: {
      flexWrap: "wrap",
      position: "absolute",
      top: 28,
      right: 0,
      left: 0,
      marginRight: "250px",
      zIndex: 1,
      boxShadow: $boxxShadow,
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      width: "320px",
      marginLeft: "-260px",
    },

    notificationIcon: {
      color: $blue,
      fontSize: "1rem",
    },

    customButtomNotification: {
      color: "white",
      cursor: "pointer !important",
      margin: "2px 2px 2px 16px",
      fontSize: "5pt",
      boxshadow: "0px 1px 4px 0 #585858",
      fontFamily: $arialSansSerif,
      fontWeight: "bold",
      borderRadius: "20px",
    },
    iconReader: {
      color: $darkgray,
      fontSize: "1rem",
    },

    iconDestacate: { 
      color: "#F0AB35",
      fontSize: "1rem"
    },

    AllNotIcon: {
      color: $darkblue,
      marginRight: "5px",
      fontSize: "1rem"
    },

    /* Coverage Menu */
    coverageMenuItem: {
      display: "flex",
      paddingLeft: '20px',
      paddingRight: '20px',
      width: "270px",
      [theme.breakpoints.down(375)]: {
        width: $eightyPercent
      }
    },

    divCoverageMenu: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      overflow: 'auto',
      marginLeft: '8%',
      marginRight: '8%',
      paddingTop: '6%',
      [theme.breakpoints.up(1570)]: {
        justifyContent: 'center',
      },
      [theme.breakpoints.down("md")]: {
        alignItems: "center",
        marginLeft: '0px',
        marginRight: '0px',
        paddingTop: '10%',
      },
      [theme.breakpoints.down(820)]: {
        flexDirection: "column",
        alignItems: "center",
        marginLeft: '0px',
        marginRight: '0px',
        paddingTop: '10%',
      }
    },
    /**login */
    logoPageLogin: {
      [theme.breakpoints.down(800)]: {
        display: "none",
      },
      [theme.breakpoints.up(800)]: {
        display: "block",
      },
      marginLeft: "auto",
      marginRight: "auto",
      width: "50%",
    },

    inputLogin: {
      "& > div > div > input , fieldset ": {
        borderRadius: $thirtyPx,
        boxShadow: "0px 3px 9px 0px #a0a0a0",
        marginBottom: '0 !important'
      },
      "& > div": {
        [theme.breakpoints.down(800)]: {
          width: $oneHundredPercentImportant,
        },
        [theme.breakpoints.up(800)]: {
          width: $eightyPercent,
        }
      },
      "& > div > label": {
        [theme.breakpoints.down(960)]: {
          color: "#fff",
          fontWeight: "bold"
        },
        [theme.breakpoints.up(960)]: {
          color: $darkblue,
          fontWeight: "bold"
        }
      },
      "& > div > div ": {
        backgroundColor: "#fff",
        borderRadius: "30px",
      },
      width: $oneHundredPercentImportant,
      alignContent: "center",
      alignItems: 'center',
      flex: '1 1 25%',
      paddingTop: '1rem',
      [theme.breakpoints.down(1125)]: {
        flex: '1 1 50%',
        '&>div': {
          width: $ninetyFivePerCent
        }
      },
      [theme.breakpoints.down(800)]: {
        flex: $oneOneOneHundredPercent
      }
    },

    datePickerLogin: {
      marginTop: $zeroPx,
      [theme.breakpoints.down(800)]: {
        "& > div": {
          marginLeft: "15%"
        }
      },
      [theme.breakpoints.up(800)]: {
        "& > div > div ": {
          width: "90% !important",
          marginLeft: "5%"
        },
      },
      [theme.breakpoints.down(800)]: {
        "& > div > div ": {
          width: "90% !important",
          marginLeft: "5%"
        },
      },
      [theme.breakpoints.up(899)]: {
        "& > div > div ": {
          width: $oneHundredPercentImportant,
          marginLeft: "0"
        },
      },

    },

    btnLogin: {

      [theme.breakpoints.down(800)]: {
        width: $eightyPercent
      },
      [theme.breakpoints.up(800)]: {
        width: "240px !important",
      },
      height: $fortyPx,
      fontSize: $twelve,
      marginTop: "20px"
    },
    flexRowBtnLogin: {
      display: "flex",
      [theme.breakpoints.down(800)]: {
        flexDirection: "column",
        alignItems: "center",
      },
      [theme.breakpoints.up(800)]: {
        flexDirection: "row",
        alignItems: "center",

      },
      margin: "auto",
      width: $oneHundredPercent,
      flexWrap: "wrap",
      justifyContent: "center"
    },

    decoredText: {
      justifyContent: "center"

    },

    txtLogin: {
      marginTop: "10px",
      color: $blue,
      fontSize: "14px",
      textAlign: "center",
      [theme.breakpoints.down(800)]: {
        color: $blue,
      },
      [theme.breakpoints.up(800)]: {
        color: $darkgray,
      }

    },

    btnLoginExternal: {
      width: $fiftyHundredPx,
      height: $thirtyPx,
      fontSize: $tenPx,
      [theme.breakpoints.down(800)]: {
        marginTop: "10px"
      },
      [theme.breakpoints.up(800)]: {
        marginTop: "20px"
      }
    },


    darkGrayBtn: {
      background: $darkgray,
      '&:hover': {
        background: $darkgray,
      }
    },

    divTravelCoverageMenu: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      overflow: 'auto',
      marginLeft: '8%',
      marginRight: '8%',
      [theme.breakpoints.up(1570)]: {
        justifyContent: 'center',
      },
      [theme.breakpoints.down("md")]: {
        alignItems: "center",
        marginLeft: '0px',
        marginRight: '0px',
      },
      [theme.breakpoints.down(870)]: {
        flexDirection: "column",
        alignItems: "center",
        marginLeft: '0px',
        marginRight: '0px',
      }
    },
    titleTravelCoverage: {
      paddingTop: '2%',
      paddingBottom: '2%',
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        paddingTop: '10%',
      },
    },

    sectionFlexRefunds: {
      display: 'flex',
      flex: $oneOneOneHundredPercent,
      flexWrap: 'wrap',
      justifyContent: 'center'
    },

    divRefunds: {
      display: 'flex',
      flex: '0 0 24%',
      padding: '2rem',
      '&>div': {
        display: 'flex',
        flex: $oneOneOneHundredPercent
      },
      [theme.breakpoints.down(768)]: {
        flex: $oneOneOneHundredPercent,
      }
    },

    divAccordionRefunds: {
      display: 'flex',
      flex: '0 0 23%',
      borderRadius: '10px',
      minHeight: '60px',
      padding: '8px',
      margin: '1%',
      flexWrap: 'wrap',
      '&>div': {
        display: 'flex',
        flex: $oneOneOneHundredPercent,
        '&>div': {
          display: 'flex',
          flex: $oneOneOneHundredPercent,
        }
      },
      [theme.breakpoints.down(768)]: {
        flex: '1 1 50%',
      }
    },

    contactForm: {
      display: 'flex',
      flexDirection: "column",
      paddingTop: '10px',
      paddingLeft: '4rem',
      alignItems: 'center',
      [theme.breakpoints.down(768)]: {
        paddingLeft: '0rem',
      }
    },
    contactFormControl: {
      paddingTop: '1rem',
      '&>div': {
        width: "750px !important",
        '&>div': {
          borderRadius: "10px",
        },
      },
      [theme.breakpoints.up(768)]: {
        paddingTop: '0rem',
        '&>div': {
          width: '500px !important'
        }
      },
      [theme.breakpoints.down(720)]: {
        paddingTop: '0rem',
        '&>div': {
          width: $fourHundredPx,
        }
      },
      [theme.breakpoints.down(400)]: {
        '&>div': {
          width: $threeHundredPx
        }
      },
      [theme.breakpoints.down(330)]: {
        '&>div': {
          width: '260px !important'
        }
      }
    },

    pdfCoverage: {
      [theme.breakpoints.down(1000)]: {
        '&>div>div': {
          position: "relative !important",
          top: "1rem !important"
        }
      }
    },

    formAffiliations: {
      display: 'grid',
      gridTemplateColumns: $templateColumnsFour,
      [theme.breakpoints.down(1300)]: {
        gridTemplateColumns: $templateColumnsThree
      },
      [theme.breakpoints.down(1100)]: {
        gridTemplateColumns: '1fr 1fr'
      },
      [theme.breakpoints.down(900)]: {
        gridTemplateColumns: '1fr'
      },
      paddingTop: '10px',
      [theme.breakpoints.up(800)]: {
        width: $oneHundredPercent,
        paddingLeft: "5%"
      }
    },
    formControlsAffiliations: {
      paddingRight: "15px",
      '&>div>div>div': {
        padding: "11px 10px 11px 10px"
      },
      [theme.breakpoints.down(1004)]: {
        width: $oneHundredPercentImportant
      },
      '&>.MuiFormControl-root': {
        [theme.breakpoints.down(1230)]: {
          width: '90% !important',
          marginLeft: $twoPointFivePercent,

        },
        [theme.breakpoints.down(768)]: {
          width: '90% !important',
          marginLeft: $fivePercent,

        },
      },
      [theme.breakpoints.down(1444)]: {
        paddingRight: "5px",
      },
      [theme.breakpoints.down(1000)]: {
        paddingRight: $zeroPx,
      },
      [theme.breakpoints.down(768)]: {
        '&>div': {
          width: "90% !important",
          marginLeft: "0% !important",

          '&>label': {
            position: "absolute",
            fontSize: "1rem"
          },
        },
      }
    },

    formControlsAffiliationsObservations: {
      paddingTop: '1rem',
      paddingRight: "15px",
      width: $oneHundredPercentImportant,

      [theme.breakpoints.down(1004)]: {
        width: $oneHundredPercentImportant
      },
      '&>.MuiFormControl-root': {
        width: $ninetyFivePerCent,
      },
      [theme.breakpoints.down(1444)]: {
        paddingRight: "5px",
      },
      [theme.breakpoints.down(1000)]: {
        paddingRight: $zeroPx,
      },
      [theme.breakpoints.down(768)]: {
        '&>div': {
          width: "90% !important",
          marginLeft: "0% !important",
          '&>label': {
            marginLeft: $fivePercent,
          },
        },
      }
    },

    textChangePlan: {
      paddingTop: '1rem',
      paddingRight: "15px",
      marginLeft: "8px",
      width: "90%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      [theme.breakpoints.down(1444)]: {
        paddingRight: "5px",
      },
      [theme.breakpoints.down(1000)]: {
        paddingRight: $zeroPx,
      },
      [theme.breakpoints.down(768)]: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: $spaceBetween,
        marginLeft: "10%",
        width: "90%",
      }
    },

    titleChangePlan: {
      width: "50%"
    },

    contentTextChangePlan: {
      textTransform: 'uppercase',
      fontSize: "14px",
      marginTop: "10px",
      paddingLeft: "0px"

    },

    /**BillingAndCollections**/
    billingAndCollectionsDiv: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      '&>div': {
        '&>div': {
          marginLeft: $zeroPx,
          [theme.breakpoints.up(768)]: {
            width: $fourHundredPx,
          }
        },
        [theme.breakpoints.down(768)]: {
          width: $oneHundredPercent
        },
      }
    },

    /**Unsubscribe */

    left10: {
      [theme.breakpoints.down(768)]: {
        marginLeft: '10% !important'
      },
      [theme.breakpoints.up(768)]: {
        marginLeft: $fivePercent
      },
    },

    txtLabelTramites: {
      color: $darkgray,
      fontSize: "15px",
      textAlign: "left",
      [theme.breakpoints.down(768)]: {
        marginLeft: '10% !important'
      },
      [theme.breakpoints.up(768)]: {
        marginLeft: $fivePercent
      },

    },

    QueryProcedure: {
      display: 'grid',
      [theme.breakpoints.up(1400)]: {
        gridRow: "3",
        gridcolumn: "1/5",
        marginRigth: "5%",
      },
      justifyContent: 'center',
      alignSelf: 'center',
      width: $oneHundredPercentImportant,
      marginLeft: '20px !important',
      marginTop: '20px !important',

      '& > div': {
        [theme.breakpoints.up(768)]: {
          width: $oneHundredPercentImportant,
        },
        [theme.breakpoints.down(768)]: {
          width: '80%'
        },
      }
    },

    formControlsDateAfiliations: {
      paddingTop: '1rem',
      paddingRight: "15px",
      '&>div>div>div>input': {
        padding: "11px 10px",
      },
      [theme.breakpoints.down(900)]: {
        width: $eightyPercent,
        marginLeft: $sixtyPx,
      },
      [theme.breakpoints.down(800)]: {
        width: $eightyPercent,
        marginLeft: "10%",
      },
      [theme.breakpoints.up(900)]: {
        width: '90% !important',
        marginLeft: "20px",
      },
      [theme.breakpoints.up(1000)]: {
        width: $twoHundredEightyPx,
        margin: "5px",
      },

      [theme.breakpoints.up(1444)]: {
        paddingRight: "5px",
      },
      [theme.breakpoints.down(1000)]: {
        paddingRight: $zeroPx,
      },

    },

    archivoAdjuntoDiv: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      flexWrap: 'wrap',
      [theme.breakpoints.down(800)]: {
        width: $oneHundredPercent,
        paddingTop: "20px"

      },
      [theme.breakpoints.up(800)]: {
        width: "250px",
        paddingLeft: "20px",
        paddingTop: "20px"
      },
    },

    /*Prevention*/
    preventionRow: {
      display: 'flex',
      flex: '1 0 10%',
      justifyContent: 'center',
      borderBottom: `1px solid ${$lightgray}`,
      [theme.breakpoints.up(960)]: {
        flex: '0 0 30%'
      }
    },

    paragraphPrevention: {
      display: 'flex',
      justifyContent: 'flex-start',
      flex: '0 0 60%',
      [theme.breakpoints.down(768)]: {
        flex: '0 0 80%'
      }
    },

    /**Billings**/
    firstIcon: {
      [theme.breakpoints.down(250)]: {
        flex: $zeroZeroOneHundred
      },
      [theme.breakpoints.between(250, 410)]: {
        flex: '0 0 62% !important',
        maxWidth: '175px',
        marginRight: '5%'
      },
      [theme.breakpoints.between(411, 450)]: {
        flex: '0 0 62% !important',
        maxWidth: '184px',
        marginRight: '7%'
      },
      [theme.breakpoints.up(450)]: {
        flex: '0 0 30%',
      },
      [theme.breakpoints.up(768)]: {
        flex: '0 0 15%',
      }
    },
    midIcon: {
      [theme.breakpoints.down(250)]: {
        flex: $zeroZeroOneHundred,
        maxWidth: 'inherit !important'
      },
      [theme.breakpoints.between(280, 321)]: {
        paddingLeft: '0px',
        paddingRight: '0px'
      },
      [theme.breakpoints.down(350)]: {
        flex: '0 0 42%',
        maxWidth: '175px'
      },
      [theme.breakpoints.between(350, 450)]: {
        flex: '0 0 43%',
        maxWidth: '175px'
      },
      [theme.breakpoints.up(450)]: {
        flex: '0 0 30%',
      },
      [theme.breakpoints.up(768)]: {
        flex: '0 0 15%',
      }
    },
    billingTxtIcon: {
      fontSize: $twelve,
      color: `${$blue} !important`
    },

    /**Appointment Card**/
    appointmentCards: {
      backgroundColor: $whiteColor,
      maxWidth: '400px',
      display: 'flex',
      flex: '1 1 25%',
      boxShadow: $lightBoxShadow,
      paddingTop: '5px',
      [theme.breakpoints.between(410, 1367)]: {
        maxWidth: '335px',
        minWidth: '335px !important',
      },
      [theme.breakpoints.down(1080)]: {
        flex: '1 1 35%'
      },
      [theme.breakpoints.down(768)]: {
        flex: '0 0 100%'
      },
      [theme.breakpoints.up(1366)]: {
        minWidth: '400px',
      },
      [theme.breakpoints.between(320, 345)]: {
        marginRight: '5%',
        marginLeft: '3%',
        maxWidth: $twoHundredEightyPx,
        minWidth: '200px !important',
      },
      [theme.breakpoints.between(345, 350)]: {
        marginRight: '5%',
        maxWidth: $threeHundredPx,
        minWidth: $threeHundredPx,
      },
      [theme.breakpoints.between(350, 410)]: {
        maxWidth: $threeHundredTenPx,
        minWidth: $threeHundredTenPx,
      },
    },

    /**Movements**/
    movementsCards: {
      backgroundColor: $whiteColor,
      maxWidth: '400px',
      display: 'flex',
      flex: '1 1 25%',
      boxShadow: $lightBoxShadow,
      paddingTop: '5px',
      [theme.breakpoints.between(410, 1367)]: {
        marginRight: '5%',
        maxWidth: '325px',
        minWidth: '325px !important',
      },
      [theme.breakpoints.down(1080)]: {
        flex: '1 1 35%'
      },
      [theme.breakpoints.down(768)]: {
        flex: '0 0 100%'
      },
      [theme.breakpoints.up(1366)]: {
        minWidth: '400px',
      },
    },

    divCardContent: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: $spaceBetween,
      [theme.breakpoints.up(380)]: {
        flex: '1 1 70%'
      },
      [theme.breakpoints.down(321)]: {
        flexWrap: 'wrap',
        flex: $oneOneOneHundredPercent
      }
    },
    cardContentMuiCard: {
      display: 'flex',
      flex: $oneOneOneHundredPercent,
      [theme.breakpoints.down(321)]: {
        flexWrap: 'wrap'
      }
    },
    divSectionMovements: {
      display: 'flex',
      padding: '2%',
      flexWrap: 'wrap',
      [theme.breakpoints.down(776)]: {
        justifyContent: 'center'
      }
    },
    headerIconCard: {
      [theme.breakpoints.down(321)]: {
        flex: $zeroZeroOneHundred
      }
    },
    amountMovements: {
      textTransform: 'uppercase',
      fontSize: '60px',
      display: 'flex',
      flex: $oneOneOneHundredPercent,
      justifyContent: 'center',
      [theme.breakpoints.down(768)]: {
        fontSize: '40px'
      },
      [theme.breakpoints.down(300)]: {
        fontSize: '26px'
      },
      [theme.breakpoints.down(200)]: {
        fontSize: '16px'
      }
    },
    amountTitle: {
      textTransform: 'uppercase',
      fontSize: '20px',
      color: '#585858',
      display: 'flex',
      flex: $oneOneOneHundredPercent,
      justifyContent: 'center',
      [theme.breakpoints.down(768)]: {
        fontSize: '15px'
      },
      [theme.breakpoints.down(300)]: {
        fontSize: '12px'
      }
    },
    subTitleMovements: {
      textTransform: 'uppercase',
      alignItems: 'center',
      display: 'flex',
      fontSize: '14px',
      [theme.breakpoints.down(400)]: {
        fontSize: '12px'
      },
      [theme.breakpoints.down(300)]: {
        fontSize: '11px'
      }
    },
    actionsIcons: {
      fontSize: '25px',
      color: $blue
    },

    //WayToPay
    subtitleSelectWayToPay: {
      textTransform: 'uppercase',
      marginBottom: "10px",
      marginLeft: "22px",
      fontSize: "14px",
      [theme.breakpoints.down(768)]: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        marginBottom: "10px",
        marginLeft: "7%",
        fontFamily: $arialRegular,
        color: $blue,
        fontWeight: 'bold',
      },
    },
    wayToPayDiv: {
      display: "flex",
      marginLeft: "5%",
      marginRight: "5%",
      paddingBottom: "20px",
      justifyContent: $spaceBetween,
      [theme.breakpoints.down(1100)]: {
        flexWrap: "wrap",
      },
      [theme.breakpoints.down(888)]: {
        justifyContent: "space-around"
      },
      [theme.breakpoints.down(768)]: {
        flexDirection: "column",
        paddingBottom: "0px",
      },
    },
    wayToPayItemDiv: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down(1100)]: {
        marginBottom: "20px",
        flexWrap: "nowrap",
      },
      [theme.breakpoints.down(768)]: {
        marginBottom: "20px",
        flexWrap: "wrap",
      },
    },
    wayToPayMercadoPago: {
      marginLeft: "10px",
      width: "min-content",
      minWidth: "250px",
      [theme.breakpoints.down(768)]: {
        marginLeft: "7%",
        width: "80%",

      },
    },
    buttonImgWayToPay: {
      marginLeft: "10px",
      marginBottom: "10px",
      borderRadius: "10%",
      boxShadow: `1px 2px 10px 0px ${$mediumgray}`,
      padding: "8px",
      [theme.breakpoints.down(768)]: {
        marginLeft: $zeroPx,
      },
    },
    wayToPayButtonImage: {
      width: "93%",
      [theme.breakpoints.down(768)]: {
        marginLeft: "7%",
      },
    },
    wayToPayTitle: {
      fontSize: "13px",
      fontFamily: "Roboto,Helvetica,Arial,sans-serif",
      color: "rgba(0,0,0,0.6)",
    },
    wayToPaySubtitle: {
      fontSize: $fontSize14,
      color: `${$blue} !important`,
    },

    /* Debit Automatic*/
    directDebitDiv: {
      display: "flex",
      marginLeft: "5%",
      marginRight: "5%",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      alignItems: "center",
      [theme.breakpoints.down(1003)]: {
        justifyContent: $spaceBetween,
      },
    },
    formControlsCardNumber: {
      paddingTop: '1.5rem',
      paddingRight: "5px",
      '&>.MuiFormControl-root': {
        width: "250px",
        paddingTop: "10px",
        paddingBottom: "8px",
        margin: "8px",
        minWidth: "250px",
        '&>.MuiInputBase-root': {
          marginTop: "0px",
          '&>input': {
            height: "1.4375em",
            padding: "14.5px"
          },
        },

      },
    },
    formControlsDirectDebit: {
      paddingTop: '1.5rem',
      paddingRight: "15px",
      [theme.breakpoints.down(1003)]: {
        paddingRight: $zeroPx,
        width: '40% !important',
        marginLeft: $twoPointFivePercent,
        marginRight: "7.5% !important",
      },
      [theme.breakpoints.down(900)]: {
        width: '32% !important',
        marginLeft: "6.5% !important",
        marginRight: "11.5% !important",
      },
      [theme.breakpoints.down(768)]: {
        width: '36% !important',
        marginLeft: "7% !important",
        marginRight: "7% !important",
      },
      [theme.breakpoints.down(1444)]: {
        paddingRight: "5px",
      },
      '&>.MuiFormControl-root': {
        width: "250px",
        paddingTop: "10px",
        paddingBottom: "8px",
        margin: "8px",
        minWidth: "250px",
        [theme.breakpoints.down(1230)]: {
          width: $oneHundredPercentImportant,
          marginLeft: $twoPointFivePercent,
          minWidth: "0px",
        },
        [theme.breakpoints.down(768)]: {
          width: '90% !important',
          marginLeft: $fivePercent,
          minWidth: "0px",

        },
      },
    },

    sectionButtons: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      flex: '0 0 45%',
      [theme.breakpoints.between(767, 1215)]: {
        flex: '0 0 40%',
      },
      [theme.breakpoints.down(321)]: {
        flex: '0 0 100%',
      },
    },
    btnAuthorizationCard: {
      flexDirection: 'column',
      [theme.breakpoints.down(321)]: {
        flexDirection: 'row',
        justifyContent: $spaceBetween,
        alignItems: 'center'
      },
    },
    authorizationNotifications: {
      justifyContent: 'center',
      display: 'block !important',
      '&>span': {
        right: '3%'
      }
    },
    descriptionCard: {
      display: 'flex',
      flex: '0 0 70%',
      paddingLeft: '4%',
      [theme.breakpoints.down(321)]: {
        flex: '0 0 100%',
      },
    },
    menuSubscription: {
      margin: "13px",
      marginLeft: "30px",
    },
    authorizationTabs: {
      paddingTop: '1%',
      marginLeft: '1%',
      width: '100%',
      '&>div>div': {
        display: 'flex',
        flex: $oneOneOneHundredPercent,
        flexWrap: 'nowrap',
        justifyContent: 'space-around'
      }
    },
    authorizationHeader: {
      display: "flex"
    },
    stepLabelTab: {
      '&>span>svg.Mui-active, .MuiStepIcon-completed ': {
        color: '#0075c9 !important'
      }
    },
    boxSteps: {
      display: 'flex',
      justifyContent: 'center',
      '&>div': {
        width: '40%',
        [theme.breakpoints.down(769)]: {
          width: $oneHundredPercent
        }
      }
    },
    txtMobile: {
      [theme.breakpoints.down(400)]: {
        fontSize: $eightPx
      }
    },
    txtBoxComments: {
      display: 'flex',
      justifyContent: 'center',
      '&>textarea': {
        maxWidth: '500px',
        borderRadius: '24px !important'
      }
    },
    txtFormComments: {
      width: $oneHundredPercentImportant
    },
    txtLabelComments: {
      width: $oneHundredPercent,
      display: 'flex',
      justifyContent: 'center'
    },
    txtObservations: {
      width: '500px',
      marginLeft: "0px",
      '&>.MuiInputBase-root': {
        margin: "0px"
      },
      [theme.breakpoints.down(768)]: {
        width: $oneHundredPercentImportant,
      },
    },

    txtBoxObservation: {
      display: 'flex',
      justifyContent: 'center',
      '&>textarea': {
        maxWidth: '500px',
        borderRadius: '24px !important',
      },
      [theme.breakpoints.down(768)]: {
        width: $oneHundredPercentImportant,
        marginLeft: "0px",
        display: "block",
        '&>textarea': {
          maxWidth: 'initial',
          boxSizing: "inherit"
        }
      },
    },

    txtTitleObservation: {
      fontSize: "12px",
      color: $mediumgray,
      fontFamily: "Roboto, Helvetica ,Arial ,sans-serif",
      textTransform: "uppercase",
      marginLeft: "10px"
    },

    

    /**Customs select affilliated */
    afilliateSelect: {
      padding: $zeroPx,
      margin: "0px",
      width: $threeHundredPx,
      '&>.MuiOutlinedInput-root': {
        margin: $zeroPx,
        '&>div': {
          padding: "10px 14px",
          marginTop: '3px'
        },
      },
      '&>div>fieldset': {
        borderWidth: $zeroPx
      }
    },

    deploymentSelect: {
      top: $oneHundredFortyPx,
      [theme.breakpoints.down(957)]: {
        top: "90px !important",
        left: "410px !important",
      },
      [theme.breakpoints.down(768)]: {
        top: "210px !important",
        left: $oneHundredFortyPx,
      },
      [theme.breakpoints.down(450)]: {
        left: "115px !important",
      },
      [theme.breakpoints.down(350)]: {
        left: "100px !important",
        minWidth: "235px !important",
      },
      [theme.breakpoints.down(300)]: {
        left: "20% !important",
      },
      '&>ul': {
        color: "#000000",
        '&>li': {
          fontWeight: $sixHundredWeight,
          [theme.breakpoints.down(600)]: {
            minHeight: "auto"
          },
        }
      }
    },

    divAffilliate: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: "45px",
      paddingTop: "20px",
      [theme.breakpoints.down(768)]: {
        paddingTop: "0px",
      },
      [theme.breakpoints.down(450)]: {
        paddingLeft: "25px",
      },
      [theme.breakpoints.down(350)]: {
        paddingLeft: "15px",
      },
    },

    textTitleTurn: {
      fontSize: "20px",
      color: $darkgray,
      [theme.breakpoints.down(768)]: {
        fontSize: "18px",
      },
    },

    orderBtnNewTurn: {
      [theme.breakpoints.down(768)]: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: $thirtyPx,
      },
    },
    divMyHistoryTurn: {
      paddingLeft: "40px",
      [theme.breakpoints.down(768)]: {
        paddingLeft: "0px",
      },
    },

    divMyHistoryRecipe: {
      paddingLeft: "40px",
      [theme.breakpoints.down(768)]: {
        paddingLeft: "0px",
      },
    },

    listCardMyHistory: {
      display: "flex",
      flexWrap: "wrap",
      paddingLeft: "20px",
      [theme.breakpoints.down(768)]: {
        paddingLeft: "0px",
        justifyContent: "center",
      },
    },

    deploymentSelectMyHistory: {
      [theme.breakpoints.down(768)]: {
        top: $seventyPx,
        left: $oneHundredThirtyPx,
      },
      [theme.breakpoints.down(768)]: {
        top: $seventyPx,
        left: $oneHundredThirtyPx,
      },
      [theme.breakpoints.down(350)]: {
        left: $seventyPx,
        minWidth: "235px !important",
      },
    },

    dropDownMyHistory: {
      marginLeft: "-280px",
      [theme.breakpoints.down(380)]: {
        marginLeft: "-200px",
        width: "270px"
      },
    },

    contentNotFound: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down(768)]: {
        padding: "20px"
      },
    },

    divAffilliateMyHistory: {
      [theme.breakpoints.down(768)]: {
        paddingTop: "30px"
      },
    },

    /**ContainerBox**/
    containerBoxWeb: {
      minWidth: '380px',
      display: 'flex',
      flex: '1 1 33%',
      flexWrap: 'wrap',
      borderRadius: '20px',
      boxShadow: $containerBoxShadow,
      maxWidth: '750px',
      minHeight: '300px',
      margin: '30px',
      marginTop: '0px'
    },
    cardHomeWeb: {
      maxWidth: '440px',
      minWidth: '350px',
      height: '200px'
    },
    boxDivider: {
      display: 'flex',
      flex: $oneOneOneHundredPercent,
      maxHeight: '2px',
    },
    headerSection: {
      display: 'flex',
      flex: '0 0 100%',
      justifyContent: $spaceBetween,
      paddingRight: '25px',
      paddingLeft: '25px',
      paddingTop: '12px',
      flexWrap: 'wrap',
      maxHeight: '43px'
    },
    boxCardWeb: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '20px',
    },
    homeSection: {
      display: 'flex',
      padding: '30px',
      flexWrap: 'wrap',
      [theme.breakpoints.up(1200)]: {
        paddingTop: '0px',
      },
      [theme.breakpoints.down(890)]: {
        paddingTop: '80px',
      },
      justifyContent: 'center'
    },
    ubicationTextHome: {
      paddingRight: '18px',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      display: 'flex'
    },
    personalDataItemHome: {
      flex: '0 0 25%',
      paddingLeft: '0px',
      paddingRight: '0px',
      [theme.breakpoints.up(632)]: {
        flex: '0 0 20%',
      },
      [theme.breakpoints.up(900)]: {
        flex: '0 0 25%',
      },
    },
    divItemsHome: {
      flexWrap: 'wrap',
      marginLeft: $zeroPx,
      marginRight: $zeroPx,
      justifyContent: $centerImportant
    },
    personalDataTxtIconHome: {
      fontSize: $elevenPx
    },
    personalDataDivIconsHome: {
      height: '50px !important',
      '&>svg': {
        fontSize: $twentyFivePx
      },
      width: '40px',
    },
    appointmentCardHomeWeb: {
      margin: '20px'
    },
    notAppointment: {
      fontSize: '30px',
      color: $darkgray
    },
    labelSelectAfilliations: {
      top: "17px"
    },
    labelSelectAfilliationsCobranza: {
      top: "17px",
      [theme.breakpoints.down(900)]: {
        marginLeft: "9%"
      },
      [theme.breakpoints.up(900)]: {
        marginLeft: "2%"
      }
    },
    marginBottom: {
      marginBottom: "10px"
    },

    inline50: {
      [theme.breakpoints.down(900)]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr'
      }
    },
    nuevosFormGrid: {
      display: "grid",
      gridTemplateColumns: $templateColumnsFour,
      marginLeft: "5%",
      [theme.breakpoints.down(1400)]: {
        gridTemplateColumns: $templateColumnsThree
      },
      [theme.breakpoints.down(1100)]: {
        gridTemplateColumns: "1fr 1fr"
      },
      [theme.breakpoints.down(900)]: {
        gridTemplateColumns: "1fr 1fr"
      },
    },

    commentGrid: {
      marginRigth: "5%",
      gridColumn: "1/span 4",
      '&>div': {
        width: "90%",
        marginRigth: "5%",
      },
      [theme.breakpoints.down(1400)]: {
        gridColumn: "1/span 3",
      },
      [theme.breakpoints.down(1100)]: {
        gridColumn: "1/span 2",
      },
      [theme.breakpoints.down(900)]: {
        gridColumn: "span 2",
      },
    },

    itemGridAdjuntarArchivos: {
      marginRigth: "5%",
      gridRow: "4",
      gridColumn: "1/1",
      [theme.breakpoints.down(1400)]: {
        gridRow: "5",
        gridColumn: "1/1",
      },
      [theme.breakpoints.down(1100)]: {
        gridRow: "6",
        gridColumn: "1/1",
      },
      [theme.breakpoints.down(900)]: {
        gridRow: "9",
        gridColumn: "1/1",
      },
    },
    itemGridSpan2: {
      [theme.breakpoints.down(900)]: {
        gridColumn: "span 2",
      }
    },
    gridBtnEnviar: {
      justifySelf: "end",
      [theme.breakpoints.up(1400)]: {
        gridColumn: "span 4",
      },
      [theme.breakpoints.down(1400)]: {
        gridColumn: "span 3",
      },
      [theme.breakpoints.down(1100)]: {
        gridColumn: "span 2",
      },
      [theme.breakpoints.down(900)]: {
        gridColumn: "span 2",
      },
    },

    itemGridSpan1: {
      [theme.breakpoints.down(900)]: {
        gridColumn: "span 1",
      }
    },

    /**********************GRID */


    gridContainerCartilla: {
      flex: '0 0 100%',
      justifyContent: "center",
      display: "flex",
      [theme.breakpoints.up(1367)]: {
        flex: '0 0 78%'
      },
      [theme.breakpoints.up(768)]: {
        paddingTop: '50px'
      }
    },
    cartillaCards: {
      backgroundColor: $tolightgray
    },
    divTitleCardCartilla: {
      display: 'flex',
      flex: '0 0 80%',
      justifyContent: 'flex-start',
      minWidth: '200px',
      paddingLeft: '8px',
      [theme.breakpoints.down(1366)]: {
        flex: '0 0 75% !important',
        minWidth: '150px'
      }
    },
    sectionHeaderCartilla: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      flexWrap: 'nowrap',
      [theme.breakpoints.down(420)]: {
        flexWrap: 'wrap',
      }
    },
    divBtnHeaderCartilla: {
      display: 'flex',
      flex: '1 1 90%',
      justifyContent: 'flex-end',
      [theme.breakpoints.down(420)]: {
        justifyContent: 'center'
      }
    },

    questionQFMargin: {
      '&>div>div': {
        marginBottom: $zeroPx,
      }
    },
    sectionHeaderCard: {
      padding: "10px",
      borderRadius: '10px',
      alignSelf: "top",
      flex: '0 0 30%',
      display: 'flex',
      minWidth: "72px",
      alignItems: "center"
    },
    btnZoneAppointmentCard: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: $spaceBetween,
      flex: 1,
      [theme.breakpoints.up(321)]: {
        flexDirection: 'column'
      },
      [theme.breakpoints.down(321)]: {
        justifyContent: 'flex-end'
      }
    },
    btnDivCards: {
      [theme.breakpoints.down(321)]: {
        display: 'flex'
      }
    },
    dividerAppointment: {
      marginRight: $fiftyPx,
      [theme.breakpoints.down(768)]: {
        marginRight: $zeroPx
      }
    },
    disableButton: {
      color: $darkgray,
      background: `${$lightgray} !important`
    },

    txtTitleBlue: {
      color: $blue,
      fontWeight: "600"
    },

    divConfirmTurn: {
      padding: "10px",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: $spaceBetween,
      [theme.breakpoints.down(768)]: {
        padding: "0px",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
      }
    },

    step3Div: {
      justifyContent: $spaceBetween,
      display: "grid",
      [theme.breakpoints.up(1100)]: {
        gridTemplateColumns: "1fr 1fr",
      },
      [theme.breakpoints.down(1100)]: {
        gridTemplateColumns: "1fr",
      },
      paddingTop: $fiftyPx,
      paddingBottom: $fiftyPx,
    },

    boxMedicalDataMobile: {
      '&>div': {
        flexWrap: 'nowrap !important',
        justifyContent: 'flex-start !important',
        overflow: 'auto',
        width: "100%",
        '&>a': {
          marginRight: '20px',
          [theme.breakpoints.down(325)]: {
            marginRight: '33px'
          },
          [theme.breakpoints.down(295)]: {
            marginRight: '50px'
          }
        }
      },
    },
    formControlCustomSelectWBtn: {
      [theme.breakpoints.up(900)]: {
        '&>div>div': {
          marginTop: "4px !important"
        },
      },
    },

    authorizationsAccordion: {
      marginTop: "20px",
      marginLeft: "15%",
      marginRight: "15%",
      [theme.breakpoints.down(768)]: {
        marginLeft: "0%",
        marginRight: "0%",
      },

    },
    widthMobile: {
      [theme.breakpoints.down(768)]: {
        width: $oneHundredPercent,
      },
    },
    selectAuthorizations: {
      [theme.breakpoints.down(768)]: {
        width: $oneHundredPercentImportant,
        margin: "0px"
      },
      '&>.MuiOutlinedInput-root': {
        [theme.breakpoints.down(768)]: {
          margin: $zeroPx,
          width: $oneHundredPercentImportant,
        },
      }
    },

    btnAttached: {
      [theme.breakpoints.down(525)]: {
        width: $initialImportant,
        flex: $initialImportant
      },
    },

    divConfirmAuthorization: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "20px",
      [theme.breakpoints.down(768)]: {
        alignItems: "center",
      },
    },

    divConfirmRefund: {
      display: "flex",
      flexDirection: "row",
      marginBottom: "10%",
      paddingLeft: "20px",
      alignItems: "center",
      [theme.breakpoints.down(900)]: {
        display: "grid",
        justifyContent: "center",
        placeItems: "center",
        paddingLeft: "0",
      },
    },

    titleConfirmAuthorization: {
      color: $blue,
      fontSize: "25px",
      fontWeight: "600",
      [theme.breakpoints.down(768)]: {
        textAlign: "center",
        fontSize: "19px",
      },
    },

    subtitleConfirmAuthorization: {
      color: $darkgray,
      fontSize: "16px",
    },

    titleSecondary: {
      fontWeight: "400",
      fontSize: "15px",
    },

    formConfirmAuthorization: {
      display: "flex",
      paddingTop: "10px",
      paddingLeft: "20px",
      [theme.breakpoints.down(768)]: {
        flexDirection: "column",
      }
    },

    width70: {
      width: "70%"
    },

    /*Reintegros*/
    btnStartRequest: {
      marginLeft: "45px",
      [theme.breakpoints.down(450)]: {
        marginLeft: "25px",
      },
      [theme.breakpoints.down(350)]: {
        marginLeft: "15px",
      },
    },

    titleRefunds: {
      paddingLeft: "45px",
      color: $darkblue,
      textTransform: 'uppercase',
      fontWeight: "600",
      [theme.breakpoints.down(450)]: {
        paddingLeft: "25px",
      },
      [theme.breakpoints.down(350)]: {
        paddingLeft: "15px",
      },
    },

    deploymentSelectRefunds: {
      top: "295px !important",
      maxHeight: $threeHundredPx,
      [theme.breakpoints.down(1200)]: {
        top: "290px !important",
      },
      [theme.breakpoints.down(957)]: {
        top: "240px !important",
        left: "455px !important",
      },
      [theme.breakpoints.down(768)]: {
        top: "270px !important",
        left: $oneHundredThirtyPx,
      },
      [theme.breakpoints.down(500)]: {
        left: "110px !important",
      },
      [theme.breakpoints.down(300)]: {
        top: $twoHundredFiftyPx,
        left: $sixtyPx,
      },
      '&>ul': {
        color: "#000000",
        '&>li': {
          fontWeight: $sixHundredWeight,
          [theme.breakpoints.down(600)]: {
            minHeight: "auto"
          },
        }
      }
    },
    footerAuthRequest: {
      position: 'static !important'
    },

    divIndexRefunds: {
      [theme.breakpoints.down(768)]: {
        paddingLeft: "0px",
      },
    },

    btnFinally: {
      backgroundColor: 'transparent',
      color: 'white',
      fontSize: '15px',
      boxShadow: 'none',
      borderRadius: '15px',
      '&:hover': {
        backgroundColor: 'white',
        color: $blue,
      }
    },

    formControlStep2Refunds: {
      marginLeft: "18px",
      [theme.breakpoints.down(901)]: {
        '&>div': {
          width: "280px !important",
          '&>label': {
            marginLeft: "10px !important",
          }
        },
        '&>div>div': {
          width: $oneHundredPercentImportant,
          marginLeft: $zeroPx
        },
        
      },
    },

    formControlCustomSelectRefunds: {
      top: $zeroPx,
      '&>div': {
        marginLeft: "20px",
        marginRight: "0px",
      },
      '&>div>div>div': {
        padding: "11px 10px"
      },
      '&>div>div': {
        marginTop: "6%"
      },
      [theme.breakpoints.down(901)]: {
        marginTop: "5px",
        '&>div': {
          marginLeft: "$tenPx",
          width: "280px !important",
          '&>label': {
            position: $absoluteImportant,
            width: "80%",
            marginLeft: "0% !important",
            top: "2px !important",
          }
        },
        '&>div>div': {
          width: $oneHundredPercentImportant,
          marginLeft: "0% !important"
        },
      }
    },

    formControlDatePickerRefunds: {
      '&>div': {
        marginLeft: "20px",
        '&>label': {
          top: "10px",
          marginLeft: "-10px"
        }
      },
      '&>div input': {
        padding: "11px 10px",
      },
      '&>div>div': {
        marginTop: "23px",
      },
      [theme.breakpoints.down(901)]: {
        marginTop: "5px",
        '&>div': {
          marginLeft: "20px",
          width: "280px !important",
          '&>label': {
            position: $absoluteImportant,
            width: "80%",
            marginLeft: $fivePercent,
            top: $zeroPx,
          }
        },
        '&>div>div': {
          width: $oneHundredPercentImportant,
          marginLeft: "0% !important"
        },
      }
    },

    divStep2Refunds: {
      padding: "15px",
      [theme.breakpoints.down(901)]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexWrap: "nowrap"
      }
    },

    // ******** Authorization styles *********

    formControlCustomSelectAuthorizations: {
      top: $zeroPx,
      '&>div': {
        marginLeft: "10px",
        marginBottom: "10px",
      },
      '&>div>div>div': {
        padding: "11px 10px"
      },
      '&>div>div': {
        marginTop: "6%"
      },
      [theme.breakpoints.down(901)]: {
        marginTop: "5px",
        '&>div': {
          marginLeft: $tenPx,
          width: $twoHundredFiftyPx,
          '&>label': {
            position: $absoluteImportant,
            width: "80%",
            marginLeft: $zeroPx,
            top: "2px !important",
          }
        },
        '&>div>div': {
          width: $oneHundredPercentImportant,
          marginLeft: $zeroPx
        },
      }
    },

    formControlDatePickerAuthorizations: {
      '&>div': {
        marginLeft: "10px",
        marginBottom: "0px",
        '&>label': {
          top: $zeroPx,
          marginLeft: "0px"
        }
      },
      '&>div input': {
        padding: "11px 10px",
      },
      '&>div>div': {
        marginTop: "23px",
      },
      [theme.breakpoints.down(901)]: {
        marginTop: "5px",
        '&>div': {
          marginLeft: $tenPx,
          width: $twoHundredFiftyPx,
          '&>label': {
            position: $absoluteImportant,
            width: "80%",
            marginLeft: $zeroPx,
            top: $zeroPx,
          }
        },
        '&>div>div': {
          width: $oneHundredPercentImportant,
          marginLeft: "0% !important"
        },
      },

      [theme.breakpoints.up(901)]: {
        '&>label': {
          position: $absoluteImportant,
          width: "80%",
          marginLeft: "20px !important",
          top: $zeroPx,
        }
      },
    },

    formControlStep2Authorizations: {
      [theme.breakpoints.down(1500)]: {
        '&>div': {
          width: $twoHundredFiftyPx,
        },
        '&>label': {
          marginLeft: $zeroPx,
        },
        '&>div>div': {
          width: $oneHundredPercentImportant,
          marginLeft: $zeroPx
        },

      },
    },


    flexStartJustifyContent: {
      alignContent: `${$spaceBetween} !important`
    },

    fixFormControlSelect: {
      marginTop: "25px",
    },
    fixInputLabel: {
      top: "-35px",
    },

    formBillingAndCollection: {
      display: 'flex',
      flexDirection: "row",
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      alignItems: 'baseline',
      paddingTop: '10px',
      paddingLeft: '5rem',
      [theme.breakpoints.down(1450)]: {
        paddingLeft: '0rem',
      },
      [theme.breakpoints.down(780)]: {
        justifyContent: 'center',
      },
    },
    formControlEmail: {
      width: "350px",
      '&>div': {
        width: $oneHundredPercent,
      },
      '&>div>div': {
        [theme.breakpoints.down(768)]: {
          width: $oneHundredPercentImportant,
        },
      }
    },
    emailCheck: {
      paddingLeft: '5%',
      fontSize: '11px',
      [theme.breakpoints.down(768)]: {
        paddingLeft: '10%',
      },
    },

    buttonBlankClaims: {
      textTransform: "capitalize",
      width: "100%",
      justifyContent: "start",
      fontSize: "16px",
      fontWeight: "normal",
      '&>span>.MuiButton-iconSizeLarge': {
        marginLeft: "6px",
        marginRight: "12px !important",
        marginTop: '1px',
      },
      '&>span>.MuiButton-label': {
        marginLeft: "16px !important",
      },
    },

    datePickerAuthorizations: {
      '&>div': {
        [theme.breakpoints.up(1499)]: {
          width: '250px !important',
        },
      }
    },

    formChronicMedication: {
      display: 'grid',
      gridTemplateColumns: $templateColumnsThree,
      [theme.breakpoints.down(1100)]: {
        gridTemplateColumns: '1fr 1fr'
      },
      [theme.breakpoints.down(900)]: {
        gridTemplateColumns: '1fr'
      },
      justifyContent: $spaceBetween,
      flexWrap: 'wrap',
      paddingTop: '10px',
      paddingLeft: '5rem',
      alignItems: 'flex-start',
      [theme.breakpoints.down(1450)]: {
        paddingLeft: '0rem',
      },
      [theme.breakpoints.down(780)]: {
        justifyContent: 'center',
      },
    },

    ChronicMedicationDetails: {
      marginLeft: '25px',
      [theme.breakpoints.down(900)]: {
        marginLeft: '45px',
      },
    },

    btnCancelarPersonalData: {
      padding: '5px 20px 5px 20px',
      backgroundColor: "#fff",
      color: "#0075c9",
      border: "1px solid #0075c9",
      marginBottom: "30px",
      marginLeft: "40px",
      marginTop: "20px",
      width: "60%"
    },

    btnCancelarPersonalDataHeader: {
      padding: '5px 20px 5px 20px',
      backgroundColor: "#fff",
      color: "#0075c9",
      border: "1px solid #0075c9",
      marginBottom: "30px",
      marginLeft: "40px",
      marginTop: "5px",
      width: "60%",
      marginRight: $fiftyPx
    },

    btnCancelarSportsHobbies: {
      padding: '5px 20px 5px 20px',
      backgroundColor: "#fff",
      color: "#0075c9",
      border: "1px solid #0075c9",
      width: "60%"
    },

    btnCancelaHobbies: {
      padding: '5px 20px 5px 20px',
      backgroundColor: "#fff",
      color: "#0075c9",
      border: "1px solid #0075c9",
      marginBottom: "30px",
      marginTop: "20px",
    },

    btnGuardarPersonalData: {
      padding: '5px 20px 5px 20px',
      marginBottom: "30px",
      marginTop: "20px",
      width: "80%",
    },

    btnCancelarAddress: {
      [theme.breakpoints.down(768)]: {
        display: "none",
      },
    },

    centerFactorGroupFormMobile: {
      [theme.breakpoints.down(768)]: {
        display: "flex",
        justifyContent: "center",
      },
    },

    saveButonFactorGroupForm: {
      padding: '5px 20px 5px 20px',
      marginLeft: $fiftyPx,
      width: "117px",
      fontSize: "10px",
      height: "35px",
      marginTop: "0px",
    },

    cancelButonFormWeight: {
      padding: '5px 20px 5px 20px',
      backgroundColor: "#fff",
      color: "#0075c9",
      border: "1px solid #0075c9",
      marginLeft: "20px",
      width: "117px",
      fontSize: "10px",
      height: "35px",
      marginTop: "0px",
    },

    // Font credentials in mobile:
    fontMobileCredentials: {
      [theme.breakpoints.down(500)]: {
        fontSize: '1rem !important'
      },
      [theme.breakpoints.down(380)]: {
        fontSize: '0.8rem !important'
      },
    },

    fontMobileCredentialsPlan: {
      [theme.breakpoints.down(500)]: {
        fontSize: '1rem !important'
      },
    },

    credentialFooter: {
      [theme.breakpoints.up(769)]: {
        marginTop: "5px",
        marginBottom: "10px",
      },
      [theme.breakpoints.down(769)]: {
        marginTop: "-5px",
        marginBottom: "10px",
      },
      [theme.breakpoints.up(818)]: {
        marginTop: "-8px",
        marginBottom: "10px",
      },
      [theme.breakpoints.up(1200)]: {
        marginTop: "8px",
        marginBottom: "10px",
      },
      [theme.breakpoints.up(1000)]: {
        marginTop: "-8px",
        marginBottom: "10px",
      },
      [theme.breakpoints.down(499)]: {
        marginTop: "-65px",
        marginBottom: "10px",
        marginLeft: "15px",
        paddingRight: "5%"
      },
      [theme.breakpoints.down(380)]: {
        marginTop: "-75px !important",
      },
    },

    imgMobileCredential: {
      [theme.breakpoints.down(500)]: {
        width: '250px !important'
      },
      [theme.breakpoints.down(380)]: {
        width: '220px !important'
      }
    },

    dropdownAuthorizations: {
      boxShadow: $boxxShadow,
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      width: "320px",
      marginBottom: "20px",
      marginLeft: "50%"
    },

    dropdownAuthorizationsHistorial: {
      display: "none"
    },

    formControlContactDataParticularPhone: {
      [theme.breakpoints.down(768)]: {
        width: "220px !important",
        maxWidth: "244px !important",
        minWidth: "212px !important",
      },
    },

    popUpNewTurn: {
      textAlign: "center !important"
    },

    registerTitle: {
      color: "#002856",
      fontFamily: "Arial",
      fontWeight: "600",
      fontSize: "22px",
      paddingTop: "5px",
      textAlign: "center",
      [theme.breakpoints.down(768)]: {
        textAlign: "center",
        fontSize: "28px",
        paddingTop: "5px",
        color: $whiteColor,
      },
      [theme.breakpoints.down(960)]: {
        textAlign: "center",
        fontSize: "22px",
        paddingTop: "30px",
        color: $whiteColor,
      },
    },

    registerErrorText: {
      [theme.breakpoints.up(768)]: {
        marginBottom: "5px !important",
        marginLeft: "11%",
        color: "red",
      },

      [theme.breakpoints.down(768)]: {
        color: "red",
        marginLeft: "6%",
        marginBottom: "5px",
      },

      [theme.breakpoints.between(768, 800)]: {
        marginLeft: "7%",
      },

      [theme.breakpoints.between(800, 900)]: {
        marginLeft: "14%",
      },
    },

    registerHelperText: {
      [theme.breakpoints.up(768)]: {
        marginBottom: "5px !important",
        marginLeft: "11%",
      },

      [theme.breakpoints.down(768)]: {
        marginLeft: "6%",
        marginBottom: "5px",
      },

      [theme.breakpoints.between(768, 800)]: {
        marginLeft: "7%",
      },

      [theme.breakpoints.between(800, 901)]: {
        marginLeft: "14%",
      },
    },

    registerImg: {
      height: "100%",
      width: $oneHundredPercent,
      minHeight: "105vh",
      backgroundColor: "#0075C9 !important",
      [theme.breakpoints.down(960)]: {
        backgroundColor: "#0075C9 !important",
        width: "100%",
        height: "100vh",
        filter: "brightness(0.50)",
      },
      [theme.breakpoints.down(768)]: {
        minHeight: "125vh"
      }
    },

    homeRegisterButton: {
      marginRight: theme.spacing(2),
      color: $blue,
      marginLeft: '1%',
      '&>span>svg': {
        fontSize: '30px'
      },
      [theme.breakpoints.down(960)]: {
        color: "#FFFFFF",
      }
    },

    frequentQuestionsButton: {
      border: "none",
      margin: "15px",
      borderRadius: "50%",
      backgroundColor: "#fff",
      boxShadow: '0 2px 8px 0 #585858',
      cursor: 'pointer',
    },

    iconFrequentQuestionsButton: {
      backgroundColor: 'transparent',
      color: '#fff',
      fontSize: '25px',
      marginTop: "4px"
    },

    datePickerPersonalData: {
      marginBottom: "0",

      '&>div input': {
        padding: "11px 10px",
      },
      flex: '1 1 25%',
      [theme.breakpoints.down(1300)]: {
        top: "-1px !important",

        '&>div': {
          marginLeft: "10px",
          width: $ninetyFivePerCent,
        },
      },
      [theme.breakpoints.down(1100)]: {
        top: "-2px !important",
        flex: '1 1 50%',
        '&>div': {
          width: $ninetyFivePerCent,
          '&>div': {
            marginLeft: "0%",
            '&>label': {
              marginLeft: "5%",
            },
          },
        }
      },
      [theme.breakpoints.down(900)]: {
        flex: '1 1 95%',
        '&>div': {
          width: '90% !important',
          '&>label': {
            marginLeft: "5%",
          },
          '&>div': {
            marginLeft: "5%",
          },
        }
      },
      [theme.breakpoints.up(900)]: {
        '&>div>div': {
          marginTop: "6%",
        },
      }
    },

    // Credential
    credImage: {
      backgroundSize: "100%",
      borderRadius: "15px",
      backgroundImage: `url(${credentialSVG})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: $centerCenter,
      width: $threeHundredThirtyPx,
      height: $FourHundredEightyPx,
    },

    credBody: {
      display: "flex",
      justifyContent: "center",
      width: $threeHundredThirtyPx,
      height: $FourHundredEightyPx,
    },

    credText: {
      fontSize: "14px",
      color: "white",
    },

    credTextName: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      textDecoration: "none",
      overflow: "hidden",
    },

    credBold: {
      fontWeight: "bold",
    },

    credDivText: {
      margin: "175px 0 0 66px",
      height: "130px",
      width: "224px",
    },

    credQR: {
      margin: "5px 0 0 120px",
      padding: "0",
    },

    credFooter: {
      marginLeft: "70px",
    },

    credFooterText: {
      fontSize: "8px",
      color: "white",
      marginLeft: "115px",
      textTransform: "uppercase",
    },

    credLastUpdate: {
      margin: "8px 0 0 55px",
      fontSize: "10px",
      color: "white",
      textTransform: "uppercase",
    },

    // Back Credential

    backCredImage: {
      backgroundImage: `url(${backCredentialSVG})`,
      backgroundSize: "100%",
      borderRadius: "15px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: $centerCenter,
      width: $threeHundredThirtyPx,
      height: $FourHundredEightyPx,
    },

    backCredDivText: {
      margin: "165px 0 0 50px",
      height: "200px",
      width: "170px",
    },

    backCredText: {
      fontSize: "13px",
      color: "white",
      textTransform: "none",
      lineHeight: "16px",
    },

    backCredFooter: {
      marginTop: "10px",
    },

    // Credential Header

    headerCredLogo: {
      width: "180px",
    },

    headerCredImage: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: `url(${headerCredentialSVG})`,
      height: "50px",
      width: "300px",
    },

    headerCredPlan: {
      margin: "1.35rem",
      height: "10px",
      width: "10px",
    },

    headerCredContainerHeader: {
      display: "flex",
      justifyContent: "space-between",
      height: "50px",
    },

    // Minimal Credential

    minCredBody: {
      width: "420px",
      height: "200px",
      backgroundImage: `url(${minimalCredentialSVG})`,
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      borderRadius: "18px",
      boxShadow: "5px 5px 10px #8A8987",
      [theme.breakpoints.down(1385)]: {
        width: "350px",
        height: "155px",
      },
      [theme.breakpoints.down(365)]: {
        width: "300px",
        height: "140px",
      },
    },

    minCredName: {
      textAlign: 'initial',
      margin: '54px 0 0 50px',
      fontSize: '14px',
      fontWeight: 'bold',
      color: 'white',
      [theme.breakpoints.down(1385)]: {
        margin: '45px 0 0 45px',
        fontSize: '12px',
      },
      [theme.breakpoints.down(365)]: {
        margin: '37px 0 0 40px',
        fontSize: '10px',
      },
    },

    minCredIcon: {
      width: '60px',
      height: '60px',
      color: 'white',
      [theme.breakpoints.down(1385)]: {
        width: '45px',
        height: '45px',
      },
      [theme.breakpoints.down(365)]: {
        width: '40px',
        height: '40px',
      },
    },

    minCredIconText: {
      textAlign: 'center',
      fontSize: '15px',
      fontWeight: 'bold',
      color: 'white',
      textTransform: 'none',
      [theme.breakpoints.down(850)]: {
        fontSize: '12px',
      }
    },

    formControlRefunds: {
      paddingTop: '1.5rem',
      [theme.breakpoints.down(1004)]: {
        width: $ninetyFivePerCent
      },
      '&>.MuiFormControl-root': {
        [theme.breakpoints.down(1230)]: {
          width: $ninetyFivePerCent
        },
        [theme.breakpoints.down(768)]: {
          width: $twoHundredEightyPx,
          margin: '0 auto !important',
          '&>div': {
            width: $oneHundredPercentImportant,
            marginLeft: $zeroPx,
            '&>label': {
              marginLeft: $fivePercent,
            },
          },
        },
      },
    },

    contactDataTitles: {
      width: '100%',
      marginBottom: '20px',
    },

    cartillaMedicalCenterSection: {
      display: 'flex',
      flex: '0 0 100%',
      padding: '10px 14px',
      alignItems: 'center'
    },

    cartillaClasificacionPrestadorStyles: {
      display: 'flex',
      flex: '0 0 4%',
      justifyContent: 'flex-start'
    },

    cartillaPhonesSection: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingLeft: '46px',
      marginBottom: '10px'
    },

    cartillaAddIconStyles: {
      fontWeight: "600",
      cursor: "pointer",
      marginLeft: '10px',
      marginTop: '2px'
    },

    cartillaAddressStyles: {
      fontSize: '0.67rem',
      marginRight: '10px',
      marginTop: '10px'
    },

    cartillaRoomIconStyles: {
      fontSize: '1rem',
      color: "#0075c9",
      cursor: "pointer",
      marginTop: '9px'
    },

    cartillaMuiCardStyles: {
      borderRadius: '20px',
      alignSelf: 'center',
      minHeight: '120px'
    },

    cartillaPrestadorRazonSocialStyles: {
      display: 'flex',
      flex: '0 0 80%',
      justifyContent: 'flex-start',
      minWidth: '200px',
      paddingLeft: '8px'
    },

    cartillaButtonContainerStyles: {
      display: 'flex',
      flex: '0 0 4%',
      justifyContent: 'flex-end'
    },

    cartillaPrestadorDomicilioContainer: {
      display: 'flex',
      flex: '0 0 100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },

    cartillaObservacionStyles: {
      display: 'flex',
      flex: '0 0 100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },

    whiteCircularProgress: {
      '&>span': {
        color: '#ffffff !important'
      }
    },

    appointmentsAutocompleteContainer: {
      marginTop: "3px",
      display: "grid",
      marginBottom: '20px',
      [theme.breakpoints.up(768)]: {
        marginLeft: '22px',
      }
    },

    appointmentsAutocompleteLabels: {
      fontWeight: '400',
      fontSize: '14px',
      marginLeft: "15px",
      marginTop: "20px",
      [theme.breakpoints.up(768)]: {
        paddingBottom: '10px',
      }
    },

    maintenanceDialogContentContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap'
    },

    maintenanceMessageContentContainer: {
      display: 'flex',
      justifyContent: 'center',
      flex: '1 1 100%',
      textAlign: "center"
    },

    maintenanceButton: {
      marginTop: "30px",
      fontSize: "12px",
      padding: "9px 12px",
      background: '#fff',
      color: '#0075c9',
      boxShadow: '0px 2px 8px 0 #585858',
      "&:hover": {
        background: '#fff',
        color: '#0075c9'
      },
    },

    phoneLabelOnConfirmation: {
      fontFamily: $arialRegular,
      fontSize: "18px !important",
      color: $blue,
      fontWeight: '550 !important',
      marginBottom: "12px !important",
      lineHeight: "1em",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignContent: "stretch",
      justifyContent: "space-between",
      [theme.breakpoints.down("600")]: {
        fontSize: "18px",
      },
    },

    updatePhoneBtn: {
      height: "35px",
      alignSelf: "center",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "80px",
      fontSize: '9px',
      marginTop: "15px !important",
      padding: '3px 5px 3px 5px !important',
      display: "block",
      marginLeft: "8px"
    },

  }),
  { index: 1 }
);
