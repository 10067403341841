import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

/**agregar el llamao a las funciones que se comunican con la api */

function* asyncGetAllNotifications({ payload }) {
  try {
    var response = yield call(API.getAllNotifications, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCESS_ALL_GET_NOTIFICATION,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_ALL_GET_NOTIFICATION,
      message: error.message,
    });
  }
}

function* asyncGetNotification({ payload }) {
  try {
    var response = yield call(API.getNotification, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCESS_GET_NOTIFICATION,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_NOTIFICATION,
      message: error.message,
    });
  }
}

function* asyncModifyNotification({ payload }) {
  try {
    var res = yield call(API.modifyNotification, payload);
    if (res)
      yield put({
        type: actionTypes.SUCCESS_MODIFY_NOTIFICATION,
        response: {data: true},
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_MODIFY_NOTIFICATION,
      message: error.message,
    });
  }
}

function* asyncSetCount({ payload }) {
  try {
      yield put({
        type: actionTypes.SUCCESS_SET_COUNT_NOTIFICATION,
        response: {data: payload},
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_SET_COUNT_NOTIFICATION,
      message: error.message,
    });
  }
}

function* asyncSelectNotification({payload}){
  try {
    yield put({
      type: actionTypes.SUCCESS_SELECT_NOTIFICATION,
      response: {data: payload},
      message: ''
    });
} catch (error) {
  yield put({
    type: actionTypes.FAILURE_SELECT_NOTIFICATION,
    message: error.message,
  });
}
}


function* asyncremoveModifyNotification({payload}){
  try {
    yield put({
      type: actionTypes.SUCCESS_MODIFY_NOTIFICATION,
      response: {data: payload},
      message: ''
    });
} catch (error) {
  yield put({
    type: actionTypes.FAILURE_MODIFY_NOTIFICATION,
    message: error.message,
  });
}
}


function* asyncUpdateAllNotifications({ payload }) {
  try {
      yield put({
        type: actionTypes.SUCCESS_UPDATE_ALL_GET_NOTIFICATION,
        response : {data: payload},
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_UPDATE_ALL_GET_NOTIFICATION,
      message: error.message,
    });
  }
}

function* asyncSetHashNotification({ payload }) {
  try {
      yield put({
        type: actionTypes.SUCCESS_HASH_NOTIFICATION,
        response : {data: payload},
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_HASH_NOTIFICATION,
      message: error.message,
    });
  }
}




// Watchers
export default function* notificationsSaga() {
  yield takeLatest(actionTypes.GET_NOTIFICATION, asyncGetNotification);
  yield takeLatest(actionTypes.GET_ALL_NOTIFICATION, asyncGetAllNotifications);
  yield takeLatest(actionTypes.MODIFY_NOTIFICATION, asyncModifyNotification);
  yield takeLatest(actionTypes.SET_COUNT_NOTIFICATION, asyncSetCount);
  yield takeLatest(actionTypes.SELECT_NOTIFICATION, asyncSelectNotification);
  yield takeLatest(actionTypes.RESET_MODIFY_NOTIFICATION, asyncremoveModifyNotification);
  yield takeLatest(actionTypes.GET_UPDATE_ALL_NOTIFICATION, asyncUpdateAllNotifications);
  yield takeLatest(actionTypes.HASH_NOTIFICATION, asyncSetHashNotification);

}
