import {
    Button,
    IconButton, Grid
}
    from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../assets/styles/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useResizeScreen } from "../../hooks/useResizeScreen";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import { useState, Fragment } from "react";
import OpenModal from "./openModal";
import CustomButton from "../../controls/Button/CustomButton";

const CardPhoto = (props) => {

    const classes = useStyles();
    const screenSize = useResizeScreen();

    const [open, setOpen] = useState(false);
    const [indexItem, setTndexItem] = useState(-1);

    const handleShow = (item, i) => e => {
        setTndexItem(i);
        if (open) {
            setOpen(false);
        }
        else {
            setOpen(true);
        }
        if (props.openViewModal) {
            setOpen(false);
        }

    };

    const conditionIsNotPlan = (item) => {
        return (props.isNotPlan ? <Grid style={{ paddingTop: "00px" }}>
            <div >
                <Typography className={`${classes.txtDescriptionWhite} ${classes.txtEllipsis}`} >
                    {" "}
                    {item.location}
                </Typography>
            </div>
            <div >
                <a href={`tel:${item?.phone}`} noopener noreferrer className={`${classes.txtDescriptionWhite} ${classes.txtEllipsis}`} >
                    {"Tel: " + item.phone}
                </a>
            </div>
            <div >
                <Typography className={`${classes.txtDescriptionWhite} ${classes.txtEllipsis}`} >
                    {" "}
                    {item.hours}
                </Typography>
            </div>
            <div >
                <a href={`mailto:${item?.mail}`} className={`${classes.txtDescriptionWhite} ${classes.txtEllipsis}`} noopener noreferrer>
                    {item.mail}
                </a>
            </div>
            <div style={{
                width: "100%",
            }} >

                <CustomButton
                    title={"MAPA"}
                    style={{
                        height: "20px",
                        width: "90px !important",
                        alignSelf: "center",
                        fontSize: "8px",
                        fontWeight: "500",
                        marginBottom: "50px",
                        position: "absolute",
                        right: "5px",
                        top: "5px",
                    }}
                    customClass={`${classes.custombtn} ${classes.lightLinearGradient}`}
                    onClick={() => window.open(item.urlMapa)}
                />  </div>

        </Grid> : <Grid style={{ paddingTop: "00px" }}><>
            <div >
                <Typography className={`${classes.txtDescriptionWhite} ${classes.txtEllipsis}`} >
                    {" "}
                    {item.description}
                </Typography>
            </div>
            <div style={{ width: "100%" }} >
                <a target="_blank" style={{ textDecoration: 'none' }} href={item.URL}>
                    <CustomButton
                        title={"CONOCELO"}
                        style={{
                            height: "20px",
                            width: "90px !important",
                            alignSelf: "center",
                            fontSize: "8px",
                            fontWeight: "500",
                            marginBottom: "50px",
                            position: "absolute",
                            right: "5px",
                            top: "32%",
                        }}
                        customClass={`${classes.custombtn} ${classes.lightLinearGradient}`} />
                </a>
            </div>

        </> </Grid>);
    };


    const showDetail = (item, i) => {
        return (props.hasDetail ? (conditionIsNotPlan(item)) :
            <Button size="small" className={classes.btnTextCardPhoto} onClick={handleShow(item, i)}>
                VER MÁS
            </Button>);
    };

    const cssClasses = (i) => {
        let css = classes.mediaPair;
        if (i % 2 === 0) {
            css = classes.cardContent;
        }
        return css;
    };

    const buttonIconMobile = (item, i) => {
        if (screenSize.screen.sWidth <= 768) {
            return (<div>
                <IconButton onClick={handleShow(item, i)}
                    style={{ float: "right" }}>
                    <ExpandMoreIcon
                        style={{ color: "#FFFFFF", transform: (open && i == indexItem ? "rotate(180deg)" : "rotate(0deg)") }} />
                </IconButton>
            </div>);
        } else {
            return (
                <Button size="small" className={classes.btnTextCardPhoto} onClick={handleShow(item, i)}>
                    VER MÁS
                </Button>);
        }
    };

    return (
        <ImageList className={`${classes.imageListCard}`} cols={4}>
            {props?.listCard?.slice(0, props.countCard).map((item, i) => {
                return (
                    <Fragment key={i}>
                        <ImageListItem>
                            <img src={item?.img} alt={item?.title} style={{ height: '100%', width: "100%", objectFit: "fill" }} />
                            <ImageListItemBar
                                className={props.alternateColor ? (`${cssClasses(i)} ${classes.cardContent}`) : `${classes.cardContent} ${classes.titleImageListCard}`}
                                style={{ height: "40%" }}
                                title={item?.title}
                                subtitle={props.hasButton ?
                                    (buttonIconMobile(item, i)
                                    )
                                    : (showDetail(item, i))
                                }
                            />
                        </ImageListItem>
                        {i == indexItem ?
                            <OpenModal modal={item} open={open} setOpen={setOpen}></OpenModal> : ""}
                    </Fragment>
                );
            })}
        </ImageList>
    );
};

export default CardPhoto;