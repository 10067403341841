
import StarIcon from '@material-ui/icons/Star';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Menu from '../Menu/Menu';
import { IconButton, Typography } from "@material-ui/core";
import useStyles from "../../assets/styles/styles";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../GenericHeader/Header";
import CardNotification from '../Menu/CardNotification';
import { useResizeScreen } from "../../hooks/useResizeScreen";
import { getAllNotifications } from "../../redux/actions/notificationsActions";
import NotFound from '../HomeLogged/NotFound';

const AllNotification = () => {
    const dispatch = useDispatch();
    const notificacionReducer = useSelector((state) => state.notificationReducer.notifications);

    const [notifications, setNotifications] = useState(notificacionReducer?.notification);
    const screenSize = useResizeScreen();
    const [ firstLoad, setFirstLoad ] = useState(true);
    const classes = useStyles();

    useEffect(() => {
        if(firstLoad) {
            dispatch(getAllNotifications({email: window.localStorage.getItem('email')}));
            setNotifications(notificacionReducer?.notification);
            setFirstLoad(false);
        } else {
            clickFilter("");
        }
    }, [notificacionReducer]);

    const size = () => {
        if (screenSize.screen.sWidth < 800) {
            return (classes.card50);
        } else {
            return (classes.card100);
        }
    };

    const clickFilter = (selected) => {
        switch (selected) {
            case "LEIDOS":
                const readed = notificacionReducer?.notification?.filter((x) => x.viewed);
                setNotifications(readed);
                break;
            case "NO LEIDOS":
                const notReaded = notificacionReducer?.notification?.filter((x) => !x.viewed);
                setNotifications(notReaded);
                break;
            case "DESTACADOS":
                const favorite = notificacionReducer?.notification?.filter((x) => x.favourite);
                setNotifications(favorite);
                break;
            case "TODOS":
                setNotifications(notificacionReducer?.notification);
                break;
            default:
                setNotifications(notificacionReducer?.notification);
                break;
        }
    };

    return (<>
        <Menu />
        <Header title="Notificaciones" colorTitle="#0075C9" heightTitle="140px" />
        <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }} className={classes.marginTop}>
            <IconButton onClick={() => { clickFilter("LEIDOS"); }} >
                <NotificationsIcon className={classes.iconReader} style={{ marginRight: '5px' }} />
                <Typography className={`${classes.txtDescription}`}>
                    LEIDOS
                </Typography>
            </IconButton>

            <IconButton onClick={() => { clickFilter("NO LEIDOS"); }} >
                <NotificationsIcon className={classes.notificationIcon} style={{ marginRight: '5px' }} />
                <Typography className={`${classes.txtDescription}`}>
                    NO LEIDOS
                </Typography>
            </IconButton>

            <IconButton onClick={() => { clickFilter("DESTACADOS"); }} >
                <StarIcon className={classes.iconDestacate} style={{ marginRight: '5px' }} />
                <Typography className={`${classes.txtDescription}`}>
                    DESTACADOS
                </Typography>
            </IconButton>

            <IconButton onClick={() => { clickFilter("TODOS"); }} >
                <NotificationsIcon className={classes.AllNotIcon} />
                <Typography className={`${classes.txtDescription}`}>
                    TODOS
                </Typography>
            </IconButton>

        </div>

        {
            notifications?.length > 0
            ?
                <div className={classes.AllNotifications}>
                    {notifications?.map((item) => (
                        <div className={size()}>
                            <CardNotification notification={item} position={null} />
                        </div>))}
                </div>
            :
                <NotFound customMsg={
                    <div className={classes.contentNotFound}>
                        <span style={{ marginTop: "40px" }}>No hay notificaciones</span>
                    </div>}
                    wsItem={notifications}
                    fullItem={notifications?.length}
                />
        }
    </>);
};

export default AllNotification;