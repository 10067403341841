import * as actionTypes from '../consts/actionTypes';

const initialState = {
    refunds: [],
    refundDetails: [],
    accountRefundsAB: [],
    accountRefunds: [],
    parametersRefunds: [],
    modelRequest: [],
    postRefund: [],
    attachments: [],
    comments: [],
    detailsAttachments: [],
    refundTypeAttached: [],
    pushRefundComments: [],
    postAttachments: [],
    cancelRefund: [],
    readRefundComments: [],
    downloadRefund: [],
    refundsFilters: [],
    msgError: '',
    loading: false,
    loadingDownload: false
};

export default function refundsReducer(state = initialState, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.GET_PARAMETERS_REFUNDS]: setLoading(),
        [actionTypes.GET_ACCOUNT_REFUNDS]: setLoading(),
        [actionTypes.POST_REFUNDS]: setLoading(),
        [actionTypes.GET_REFUND_DETAILS]: setLoading(),
        [actionTypes.GET_REFUNDS]: setLoading(),
        [actionTypes.DOWNLOAD_REFUND]: setDownloadLoading(),
        [actionTypes.GET_DETAILS_ATTACHMENTS]: setDownloadLoading(),
        [actionTypes.SUCCESS_GET_REFUNDS]: responseToReturn('refunds'),
        [actionTypes.SUCCESS_AB_ACCOUNT_REFUND]: responseToReturn('accountRefundsAB'),
        [actionTypes.SUCCESS_GET_ACCOUNT_REFUNDS]: responseToReturn('accountRefunds'),
        [actionTypes.SUCCESS_GET_PARAMETERS_REFUNDS]: responseToReturn('parametersRefunds'),
        [actionTypes.SUCCESS_POST_REFUNDS]: responseToReturn('postRefund'),
        [actionTypes.FAILURE_GET_REFUNDS]: responseToReturn('refunds'),
        [actionTypes.FAILURE_AB_ACCOUNT_REFUND]: responseToReturn('accountRefundsAB'),
        [actionTypes.FAILURE_GET_ACCOUNT_REFUNDS]: responseToReturn('accountRefunds'),
        [actionTypes.FAILURE_GET_PARAMETERS_REFUNDS]: responseToReturn('parametersRefunds'),
        [actionTypes.FAILURE_POST_REFUNDS]: responseToReturn('postRefund'),
        [actionTypes.SET_MODEL_REFUNDS]: setDataOfStore('modelRequest'),
        [actionTypes.CLEAR_STATE_REFUNDS]: clearState(),
        [actionTypes.CLEAR_MODEL_REFUNDS]: clearState('modelRequest'),
        [actionTypes.CLEAR_POST_REFUNDS]: clearState('postRefund'),
        [actionTypes.CLEAR_MSG_ERROR]: clearState('msgError'),
        [actionTypes.SET_REFUNDS_FILTERS]: setDataOfStore('refundsFilters'),
        [actionTypes.CLEAR_REFUNDS_FILTERS]: clearState('refundsFilters'),

        [actionTypes.SUCCESS_GET_REFUND_DETAILS]: responseToReturn('refundDetails'),
        [actionTypes.SUCCESS_GET_REFUND_ATTACHMENTS]: responseToReturn('attachments'),
        [actionTypes.SUCCESS_GET_REFUND_COMMENTS]: responseToReturn('comments'),
        [actionTypes.SUCCESS_GET_DETAILS_ATTACHMENTS]: responseToReturn('detailsAttachments'),
        [actionTypes.SUCCESS_GET_REFUND_TYPE_ATTACHED]: responseToReturn('refundTypeAttached'),
        [actionTypes.SUCCESS_PUSH_REFUND_COMMENTS]: responseToReturn('pushRefundComments'),
        [actionTypes.SUCCESS_POST_REFUND_ATTACHED]: responseToReturn('postAttachments'),
        [actionTypes.SUCCESS_CANCEL_REFUND]: responseToReturn('cancelRefund'),
        [actionTypes.SUCCESS_POST_READ_REFUNDS_COMMENTS]: responseToReturn('readRefundComments'),
        [actionTypes.SUCCESS_DOWNLOAD_REFUND]: responseToReturn('downloadRefund'),

        [actionTypes.FAILURE_GET_REFUND_DETAILS]: responseToReturn(),
        [actionTypes.FAILURE_GET_REFUND_ATTACHMENTS]: responseToReturn(),
        [actionTypes.FAILURE_GET_REFUND_COMMENTS]: responseToReturn(),
        [actionTypes.FAILURE_GET_DETAILS_ATTACHMENTS]: responseToReturn(),
        [actionTypes.FAILURE_GET_REFUND_TYPE_ATTACHED]: responseToReturn(),
        [actionTypes.FAILURE_PUSH_REFUND_COMMENTS]: responseToReturn(),
        [actionTypes.FAILURE_POST_REFUND_ATTACHED]: responseToReturn(),
        [actionTypes.FAILURE_CANCEL_REFUND]: responseToReturn(),
        [actionTypes.FAILURE_POST_READ_REFUNDS_COMMENTS]: responseToReturn(),
        [actionTypes.FAILURE_DOWNLOAD_REFUND]: responseToReturn(),
    };

    function responseToReturn(type) {
        let res = { ...state };
        if (action.response) {
            res = { ...state, [type]: action.response.data.data, loading: false, loadingDownload: false };
        }
        if (action.message) {
            res = { ...state, msgError: action.message, loading: false };
        }
        return res;
    }

    function setLoading() {
        return {...state, loading: true};
    }

    function setDownloadLoading() {
        return {...state, loadingDownload: true};
    }

    function setDataOfStore(modelName) {
        let res = { ...state };
        if (action.payload) {
            res = { ...state, [modelName]: action.payload };
        }
        return res;
    }

    function clearState(type) {
        if(type){
            return { ...state, [type]: initialState[type]};
        }else{
            return {...initialState}
        }
    }

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}