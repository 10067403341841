import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import FlightTakeoffOutlinedIcon from '@material-ui/icons/FlightTakeoffOutlined';
import useTrackPageView from "../../utils/useTrackPageView";
import SelfImprovementIcon from '@material-ui/icons/SelfImprovement';
import { useResizeScreen } from "../../hooks/useResizeScreen";
import Menu from "../../components/Menu/Menu";
import useStyles from "../../assets/styles/styles";
import SelectTypeOfComponent from "../../components/PersonalData/SelectTypeOfComponent";
import { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import QuickAccessBox from '../../components/HomeLogged/QuickAccessBox';
import Header from '../../components/GenericHeader/Header';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFullData } from '../../redux/actions/personalDataActions';
import { getAreaParameters, getRefundParameters } from '../../redux/actions/coverageActions';
import { getLimitPlan } from "../../redux/actions/limitPlansAction";
import { notAccessToThisModule, verifyPermission } from "../../utils/utilsFunctions";
import Loading from '../../components/LoadingPage/Loading';

const userCode = parseInt(window.localStorage.getItem("numeroPersona"));
const affiliated = {
    numeroPersona: userCode
};

const Coverage = () => {
    const { screen } = useResizeScreen();
    const trackPageView = useTrackPageView("Mi Cobertura");
    const [type, setActiveType] = useState(-1);
    const classes = useStyles();
    const [mobileDevice, setMobileDevice] = useState(false);
    const dispatch = useDispatch();
    const affiliatePlan = useSelector((state) => state.personalDataReducer?.fullData?.afiliado?.planDescripcion);
    const [ hasPermission, setHasPermission ] = useState(false);

    const { search } = useLocation();
    const match = search.match(/type=(.*)/);
    const typeUrl = match?.[1];
    const personalDataSelector = useSelector(state => state.personalDataReducer);
    const limitPlanReducer = useSelector((state) => state.limitPlanReducer.allLimitPlanData);
    const loadingPermission = useSelector((state) => state.limitPlanReducer.requestInProgress);

    const list = [
        { title: 'TOPES Y COPAGO', icon: <AttachMoneyIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 0, path: '/Coverage?type=CapsAndCopay' },
        { title: 'REINTEGROS', icon: <AccountBalanceWalletOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 1, path: '/Coverage?type=RefundCoverage' },
        { title: 'COBERTURA EN VIAJE', icon: <FlightTakeoffOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 2, path: '/TravelCoverage' },
        { title: 'ACTIVIDADES', icon: <SelfImprovementIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 3, path: '/Coverage?type=Activities' }
    ];

    useLayoutEffect(() => {
        verifyPermission(limitPlanReducer, affiliatePlan, "Cobertura", setHasPermission);
    }, [limitPlanReducer, affiliatePlan, hasPermission]);

    useEffect(() => {
        dispatch(getLimitPlan());
        trackPageView();
    }, []);

    const handleBack = () => {
        setActiveType(-1);
    };

    const handleClick = (item) => (e) => {
        setActiveType(item.type);
    };

    const validateResponsive = () => {
        if (screen.sWidth <= 768)
            setMobileDevice(true);
        else
            setMobileDevice(false);
    };

    useEffect(() => {
        validateResponsive();
    }, [screen.sWidth, type]);

    useEffect(() => {
        if (typeUrl !== undefined) {
            setActiveType(0);
        }
        dispatch(getFullData(affiliated));
        dispatch(getRefundParameters());
        dispatch(getAreaParameters());
    }, []);

    function isEnabled(condition1, condition2) {
        let r;
        if(condition1 !== 0 && condition2) {
            r = (
                <div className={`${classes.divQuickAccess} ${classes.customScroll}`}>
                    {list.map((item, i) => (
                        <Fragment key={i}>
                            <QuickAccessBox component={Link} to={item.path} handleClick={handleClick} i={i} item={item} classes={classes} customClass={classes.personalDataItem} iconClass={classes.personalDataDivIcons} txtIcon={classes.personalDataTxtIcon} />
                        </Fragment>
                    ))}
                </div>
            );
        } else {
            r = (notAccessToThisModule());
        }
        return r;
    }

    useEffect(() => {
        window.onpopstate = (e) => {
            if (type !== -1)
                window.location.href = '/Coverage';
        };
    });

    useEffect(() => {
        if (typeUrl !== undefined) {
            let auxURL = `/Coverage?type=${typeUrl}`;
            let aux = list.filter(x => x.path === auxURL);
            setActiveType(aux[0].type);
        }else{
            setActiveType(-1);
        }
    }, [typeUrl]);

    return (
        <>
            <Menu />
            {((!mobileDevice || type === -1) && type !== 0) &&
                <>
                    <Header title='Mi Cobertura' />
                    <Loading
                        isLoading={loadingPermission}
                        children={isEnabled(type, hasPermission)}
                    />
                </>
            }
            <SelectTypeOfComponent handleBack={handleBack} />
        </>
    );
};

export default Coverage;

