import * as actionTypes from "../consts/actionTypes";

const initialValues = {
  specialtyList: [],
  medicalCenters: [],
  isLoading: false,
};

export default function medicalCenterReducer(state = initialValues, action) {

  const ACTIONS = {
    [actionTypes.FAILURE_GET_SPECIALTY_LIST]: responseToReturn(),
    [actionTypes.FAILURE_GET_MEDICAL_CENTERS]: responseToReturn()
  };

  function responseToReturn() {
    let res = { ...state };
    if (action.message) {
      res = { ...state, msgError: action.message };
    }
    return res;
  }

  if (ACTIONS[action.type] !== undefined) {
    return ACTIONS[action.type];
  }

  switch (action.type) {
    case actionTypes.SUCCESS_GET_SPECIALTY_LIST:
      if (action.response) {
        return { ...state, specialtyList: action.response.data, isLoading: false };
      } else {
        return { ...state };
      }
    case actionTypes.SUCCESS_GET_MEDICAL_CENTERS:
      if (action.response) {
        return { ...state, medicalCenters: action.response.data, isLoading: false };
      } else {
        return { ...state };
      }
      case actionTypes.GET_SPECIALTY_LIST:
      return { ...state, specialtyList: [], isLoading: true };

      case actionTypes.GET_MEDICAL_CENTERS:
      return { ...state, medicalCenters: [], isLoading: true };
    default:
      return { ...state };
  }
}
