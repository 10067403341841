import React, { useEffect } from "react";
import Menu from "../../components/Menu/Menu";
import Header from "../../components/GenericHeader/Header";
import RefundsIndex from "../../components/Refunds/RefundsIndex";
import { useLocation } from "react-router";
import RefundsRequest from "../../components/Refunds/RefundsRequest/RefundsRequest";
import { useDispatch } from "react-redux";
import { getParametersRefunds } from "../../redux/actions/refundsActions";
import { getDataGroupFamily } from "../../redux/actions/appointmentsActions";

const persona = {
    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona"))
}

const Refunds = () => {
    const { search } = useLocation();
    const match = search.match(/type=(.*)/);
    const typeURL = match?.[1];
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDataGroupFamily(persona));
        dispatch(getParametersRefunds());
    }, []);

    return (
        <>
            <Menu />
            {typeURL === undefined ?
                <>
                    <Header title='Reintegros' />
                    <RefundsIndex />
                </>
                :
                <RefundsRequest />
            }
        </>
    );

};

export default Refunds;