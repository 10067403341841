import { Divider, FormControl, Typography, CircularProgress } from "@material-ui/core";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import FormHeader from "../../PersonalData/FormHeader";
import useStyles from '../../../assets/styles/styles';
import { useFormHeaderText } from "../../../hooks/useFormHeaderText";
import CustomSelect from "../../../controls/Select/CustomSelect";
import { useState, useEffect } from "react";
import CustomButton from "../../../controls/Button/CustomButton";
import PdfReader from "../../PdfReader/PdfReader";
import { useSelector, useDispatch } from "react-redux";
import { getProgramRequirementAndFormByProgramAction, getProgramsAction } from "../../../redux/actions/programRequirementAndFormActions";
import { handleGoToProgramsHome } from "../../../utils/utilsFunctions";

const RequirementsAndForms = () => {
    const { screen } = useResizeScreen();
    const classes = useStyles();
    const { titleHeader, subtitleHeader } = useFormHeaderText('Requisitos y formularios', '', '', screen.sWidth);
    const [pdf, setPdf] = useState();
    const [pdfForms, setPdfForms] = useState();
    const [pdfRequirements, setPdfRequirements] = useState();
    const [pdfView, setPdfView] = useState(false);
    const [typeOfPrograms, setTypeOfPrograms] = useState([]);
    const [selected, setSelected] = useState([]);
    const enrollments = useSelector(state => state.medicalDataReducer.programs);
    const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);
    const programsReduxState = useSelector(state => state.programRequirementAndFormReducer);
    const [ disabledButtons, setDisabledButtons ] = useState(true);
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);


    const inlineStyles = {
        buttonsStyles: {
            padding: '5px 20px 5px 20px',
            fontWeight: "bold",
            fontSize: "12px",
            marginLeft: "7%",
            marginBottom: "3%"
        },
    
        buttonTitlesStyles: {
            textTransform: 'uppercase',
            textAlign: "center",
            marginBottom: '10px'
        },
    
        formTitlesStyles: {
            textTransform: 'uppercase',
            alignItems: 'center',
            display: 'flex'
        },
    
        containerStyles: {
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap'
        }
    }

    useEffect(() => {
        dispatch(getProgramsAction());
    },[]);

    useEffect(() => {
        if (programsReduxState?.medicalPrograms?.programs != undefined && programsReduxState?.medicalPrograms?.programs.length != 0) {
            let select = [];

            programsReduxState?.medicalPrograms?.programs.map(item => {
                select.push({ value: item.id, label: item.program })
            });

            setTypeOfPrograms(select);
            setLoading(false);
        }
    }, [programsReduxState?.medicalPrograms]);

useEffect(() => {
    setPdfForms(programsReduxState?.programRequirementAndForm?.formLink)
    setPdfRequirements(programsReduxState?.programRequirementAndForm?.requirementLink)

}, [programsReduxState?.programRequirementAndForm])

    const handleChangeSelect = (listToFilter) => (e) => {
        const { value } = e.target;
        let description = listToFilter?.filter(x => x.value === value);

        setPdfForms(undefined)
        setPdfRequirements(undefined)
    
        dispatch(getProgramRequirementAndFormByProgramAction(description[0].label))
        description[0].label !== '' ? setDisabledButtons(false) : setDisabledButtons(true)
    };

    const handleClickForm = () => {
        setPdf(pdfForms);
        setPdfView(true);
    };
    const handleClickRequirements = () => {
        setPdf(pdfRequirements);
        setPdfView(true);
    };

    const handleBack = () => {
        setPdfForms(undefined)
        setPdfRequirements(undefined)
        setPdfView(false);
        setDisabledButtons(prevState => !prevState);
    };

    return (
        <>
            <FormHeader title={titleHeader} subtitle={subtitleHeader} handleGoTo={handleGoToProgramsHome} />
            {
                screen.sWidth > 768 &&
                <Divider className={classes.formDivider} />
            }
            <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`} style={{ justifyContent: 'flex-start' }}>
                {screen.sWidth <= 768 ?
                    <>
                        <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency}`} style={ inlineStyles.formTitlesStyles }>
                            Requisitos y formularios
                        </Typography>
                    </>
                    : ""
                }
            </div>
            {loading ?
                <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
                :
            !pdfView ?
                <div style={ inlineStyles.containerStyles }>
                    <div className={classes.divAccordionPrograms}>
                        <FormControl className={`${classes.formControlsEmergency} ${classes.formControlSelect}`} variant="standard">
                            <CustomSelect name={`alergiaCodigo-alergiaDescripcion/0`} list={typeOfPrograms} selected={selected[0]?.enrolamientoCodigo} onChange={handleChangeSelect(typeOfPrograms)} />
                        </FormControl>
                    </div>
                    <div className={classes.divAccordionPrograms} style={{textAlign: "center"}}>
                        <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency}`} style={ inlineStyles.buttonTitlesStyles }>
                            Requisitos
                        </Typography>
                        <CustomButton
                            title={'VER REQUISITOS'}
                            onClick={handleClickRequirements}
                            customClass={`${classes.lightLinearGradient}`}
                            style={ inlineStyles.buttonsStyles }
                            disabled={disabledButtons}
                        />
                    </div>
                    <div className={classes.divAccordionPrograms} style={{textAlign: "center"}}>
                        <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency}`} style={ inlineStyles.buttonTitlesStyles }>
                            Formulario del programa
                        </Typography>
                        <CustomButton
                            title={'VER FORMULARIO'}
                            onClick={handleClickForm}
                            customClass={`${classes.lightLinearGradient}`}
                            style={ inlineStyles.buttonsStyles }
                            disabled={disabledButtons}
                        />
                    </div>
                </div>
                :
                <>
                    <CustomButton
                        customClass={classes.lightLinearGradient}
                        style={{ padding: '5px 20px 5px 20px', marginLeft: "10px" }}
                        title='Volver'
                        type='button'
                        onClick={handleBack}
                    />
                    <div style={{ padding: '20px', position: 'relative' }}>
                        <PdfReader pdf={pdf} />
                    </div>
                </>
            }
        </>
    );
};

export default RequirementsAndForms;