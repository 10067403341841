import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import UseStyle from "../../assets/styles/styles";
import { Step, StepLabel } from '@material-ui/core';

const WizardLine = (props) => {
    const { activeStep, list } = props;
    const classes = UseStyle();

    function firstStep() {
        let result = classes.firstStepperTurn
        if (activeStep !== 0) {
            result = classes.stepperTurn
        }
        return result;
    }

    return (
        <>
            <Stepper activeStep={activeStep} alternativeLabel className={firstStep()} >
                {list.map((step, index) => (
                    <Step
                        key={index}
                    >
                        <StepLabel
                            StepIconProps={{
                                classes: {
                                    completed: classes.colorIconStepper,
                                    active: classes.colorIconStepper,
                                }
                            }}></StepLabel>
                    </Step>
                ))
                }
            </Stepper >
        </>
    );
};

export default WizardLine;