import React, { useEffect, useState } from 'react'
import useStyles from '../../../assets/styles/styles';
import { FormControl, InputLabel } from '@material-ui/core';
import CustomizedInputs from '../../../controls/InputCustom/CustomizedInputs';
import { useSelector } from 'react-redux';


const UnsuscribeForm = (props) => {

    const { model, setModel } = props;
    const classes = useStyles();
    const data = useSelector(state => state.billingReducer?.getDirectDebit);
    const [debitosAutomaticos] = useState(data?.debitosAutomaticos);
    const [creditCard, setCreditCard] = useState("");
    const [debitAccount, setDebitAccount] = useState("");
    const [debitoAutomaticoInformacion, setDebitoAutomaticoInformacion] = useState({});

    useEffect(() => {
        if (debitosAutomaticos[0]?.tarjetaCreditoNumero != '') {
            setCreditCard(debitosAutomaticos[0]?.tarjetaCreditoTipoCodigo)
        }
        if (debitosAutomaticos[0]?.debitoNumeroCuenta != '') {
            setDebitAccount(debitosAutomaticos[0]?.debitoTipoCodigo)
        }
        setDebitoAutomaticoInformacion({
            ...debitoAutomaticoInformacion,
            tarjetaCreditoTipoCodigo: "",
            tarjetaCreditoNumero: debitosAutomaticos[0]?.tarjetaCreditoNumero,
            tarjetaCreditoVencimientoMes: debitosAutomaticos[0]?.tarjetaCreditoVencimientoMes,
            tarjetaCreditoVencimientoAnio: debitosAutomaticos[0]?.tarjetaCreditoVencimientoAnio,
            debitoTipoCodigo: "",
            debitoNumeroCuenta: debitosAutomaticos[0]?.debitoNumeroCuenta,
            debitoCBU: debitosAutomaticos[0]?.debitoCBU,
        })
    }, [data])

    useEffect(() => {
        switch (creditCard) {
            case 'V':
                setDebitoAutomaticoInformacion({ ...debitoAutomaticoInformacion, tarjetaCreditoTipoCodigo: "VISA" })
                break;
            case 'C':
                setDebitoAutomaticoInformacion({ ...debitoAutomaticoInformacion, tarjetaCreditoTipoCodigo: "MASTERCARD" })
                break;
            case 'W':
                setDebitoAutomaticoInformacion({ ...debitoAutomaticoInformacion, tarjetaCreditoTipoCodigo: "AMEX" })
                break;
        }
        setModel({ ...model, debitoAutomaticoTipo: "TARJETA CREDITO" })
    }, [creditCard])


    useEffect(() => {
        switch (debitAccount) {
            case 1:
                setDebitoAutomaticoInformacion({ ...debitoAutomaticoInformacion, debitoTipoCodigo: "CUENTA CORRIENTE" })
                break;
            case 2:
                setDebitoAutomaticoInformacion({ ...debitoAutomaticoInformacion, debitoTipoCodigo: "CAJA DE AHORRO" })
                break;
        }
        setModel({ ...model, debitoAutomaticoTipo: "DEBITO CUENTA" })
    }, [debitAccount])

    useEffect(() => {
        setModel({ ...model, debitoAutomaticoInformacion })
    }, [model?.debitoAutomaticoTipo])

    return (
        <>
            <FormControl className={`${classes.formControlsAffiliations} ${classes.formControlsCardNumber}`} variant="standard">
                <InputLabel className={`${classes.txtLabelTramites} `}>
                    {"MEDIO DE PAGO"}
                </InputLabel>
                <CustomizedInputs
                    label=''
                    name='debitoAutomaticoTipo'
                    value={model?.debitoAutomaticoTipo}
                    disabled={true}
                />
            </FormControl>

            {creditCard !== "" &&
                <>
                    <FormControl className={`${classes.formControlsAffiliations} ${classes.formControlsCardNumber}`} variant="standard">
                        <InputLabel className={`${classes.txtLabelTramites} `}>
                            {"TIPO DE TARJETA"}
                        </InputLabel>
                        <CustomizedInputs
                            label=''
                            name='tarjetaCreditoTipoCodigo'
                            value={model?.debitoAutomaticoInformacion?.tarjetaCreditoTipoCodigo}
                            disabled={true}
                        />
                    </FormControl>
                    <FormControl className={`${classes.formControlsAffiliations} ${classes.formControlsCardNumber}`} variant="standard">
                        <InputLabel className={`${classes.txtLabelTramites} `}>
                            {"NUMERO DE TARJETA"}
                        </InputLabel>
                        <CustomizedInputs
                            label=''
                            name='tarjetaCreditoNumero'
                            value={model?.debitoAutomaticoInformacion?.tarjetaCreditoNumero?.replace(/\d(?=\d{4})/g, "*")}
                            disabled={true}
                        />
                    </FormControl>
                    <FormControl className={`${classes.formControlsAffiliations} ${classes.formControlsCardNumber}`} variant="standard">
                        <InputLabel className={`${classes.txtLabelTramites} `}>
                            {"MES DE VENCIMIENTO"}
                        </InputLabel>
                        <CustomizedInputs
                            name='tarjetaCreditoVencimientoMes'
                            value={model?.debitoAutomaticoInformacion?.tarjetaCreditoVencimientoMes}
                            disabled={true}
                        />
                    </FormControl>
                    <FormControl className={`${classes.formControlsAffiliations} ${classes.formControlsCardNumber}`} variant="standard">
                        <InputLabel className={`${classes.txtLabelTramites} `}>
                            {"AÑO DE VENCIMIENTO"}
                        </InputLabel>
                        <CustomizedInputs
                            label=''
                            name='tarjetaCreditoVencimientoAnio'
                            value={model?.debitoAutomaticoInformacion?.tarjetaCreditoVencimientoAnio}
                            disabled={true}
                        />
                    </FormControl>
                </>
            }

            {debitAccount !== "" &&
                <>
                    <FormControl className={`${classes.formControlsAffiliations} ${classes.formControlsCardNumber}`} variant="standard">
                        <InputLabel className={`${classes.txtLabelTramites} `}>
                            {"TIPO DE CUENTA"}
                        </InputLabel>
                        <CustomizedInputs
                            label=''
                            name='debitoTipoCodigo'
                            value={model?.debitoAutomaticoInformacion?.debitoTipoCodigo}
                            disabled={true}
                        />
                    </FormControl>
                    <FormControl className={`${classes.formControlsAffiliations} ${classes.formControlsCardNumber}`} variant="standard">
                        <InputLabel className={`${classes.txtLabelTramites} `}>
                            {"NUMERO DE CUENTA"}
                        </InputLabel>
                        <CustomizedInputs
                            label=''
                            name='debitoNumeroCuenta'
                            value={model?.debitoAutomaticoInformacion?.debitoNumeroCuenta?.replace(/\d(?=\d{4})/g, "*")}
                            disabled={true}
                        />
                    </FormControl>
                    <FormControl className={`${classes.formControlsAffiliations} ${classes.formControlsCardNumber}`} variant="standard">
                        <InputLabel className={`${classes.txtLabelTramites} `}>
                            {"CBU"}
                        </InputLabel>
                        <CustomizedInputs
                            label=''
                            name='debitoCBU'
                            value={model?.debitoAutomaticoInformacion?.debitoCBU?.replace(/\d(?=\d{4})/g, "*")}
                            disabled={true}
                        />
                    </FormControl>
                </>
            }
        </>
    )
}

export default UnsuscribeForm
