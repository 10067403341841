import * as actionTypes from "../consts/actionTypes";

/**acciones que se disparan del componente */
export function getRefundParameters() {
    return {
        type: actionTypes.GET_TYPE_COVERAGE_PARAMETERS,
        payload: '',
    };
}

export function getAreaParameters(params) {
    if (params == undefined)
        params = '';

    return {
        type: actionTypes.GET_AREA_PARAMETERS,
        payload: params,
    };
}

export function postRefundCoverage(params) {
    return {
        type: actionTypes.POST_REFUND_COVERAGE,
        payload: params
    };
}

export function getAllCoveragePlans(param = '') {
    return {
        type: actionTypes.GET_ALL_COVERAGE_PLANS,
        payload: param
    };
}