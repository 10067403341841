import * as actionTypes from '../consts/actionTypes';

const initialState = {
    counterSurvey: [],
    sendSurvey: [],
    msgError: ''
};

export default function surveyReducer(state = initialState, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_GET_COUNTER_SURVEY]: responseToReturn('counterSurvey'),
        [actionTypes.SUCCESS_SEND_SURVEY]: responseToReturn('sendSurvey'),
        [actionTypes.FAILURE_GET_COUNTER_SURVEY]: responseToReturn('counterSurvey'),
        [actionTypes.FAILURE_SEND_SURVEY]: responseToReturn('sendSurvey'),
        [actionTypes.CLEAR_COUNTER_SURVEY]: clearResponse('counterSurvey'),
    };
    function responseToReturn(name) {
        let res = { ...state };
        if (action.response) {
            res = { ...state, [name]: action.response.data, msgError: action.message };
        }
        if (action.message) {
            res = { ...state, msgError: action.message };
        }
        return res;
    }

    function clearResponse(name) {
        return { ...state, [name]: [] };
    }

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}