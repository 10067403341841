import { Divider } from "@material-ui/core";
import FormHeader from "../../PersonalData/FormHeader";
import useStyles from "../../../assets/styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useLayoutEffect } from "react";
import Autocomplete from "../../../controls/Autocomplete/Autocomplete";
import CustomButton from "../../../controls/Button/CustomButton";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import NotFound from "../../HomeLogged/NotFound";
import { postRefundCoverage, getRefundParameters, getAreaParameters } from "../../../redux/actions/coverageActions";

const Refunds = () => {
    const classes = useStyles();
    const fullDataAffiliated = useSelector(state => state.personalDataReducer);
    const coverageReduxState = useSelector(state => state.coverageReducer);
    const dispatch = useDispatch();
    const [plan, setPlan] = useState({});
    const [type, setType] = useState([{
        itemDescripcion: '',
        itemCodigo: 0,
    }]);
    const [area, setArea] = useState([{
        itemDescripcion: '',
        itemCodigo: 0,
    }]);
    const [request, setRequest] = useState({
        numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
        planCodigo: '',
        tipoCodigo: '',
        rubroCodigo: ''
    });
    const [response, setResponse] = useState([]);
    const { screen } = useResizeScreen();
    const [loading, setLoading] = useState(false);

    const handleClickSend = (e) => {
        e.preventDefault();
        setLoading(true);
        dispatch(postRefundCoverage(request));
    };

    const handleAutocompleteChange = (newValue, typeAutocomplete) => {
        if (newValue?.itemCodigo !== null) {
            setRequest({ ...request, [typeAutocomplete]: newValue?.itemCodigo });
        } else {
            setRequest({ ...request, [typeAutocomplete]: '' });
        }
    };

    const handleAutocompleteTipoChange = (newValue, typeAutocomplete) => {
        if (newValue?.itemCodigo !== null) {
            setArea([]);
            dispatch(getAreaParameters(newValue?.itemCodigo));
            setRequest({ ...request, [typeAutocomplete]: newValue?.itemCodigo });
        } else {
            setArea([]);
            setRequest({ ...request, [typeAutocomplete]: '' });
        }
    };

    useLayoutEffect(() => {
        dispatch(getRefundParameters());
        dispatch(getAreaParameters());
    }, []);

    useEffect(() => {
        if (coverageReduxState?.typeCoverage?.confirmacion) {
            let auxTipo = [];
            coverageReduxState?.typeCoverage?.tipoCoberturaReintegro?.forEach(tipo => {
                let obj = {
                    itemDescripcion: tipo?.tipoDescripcion,
                    itemCodigo: tipo?.tipoCodigo,
                };
                auxTipo.push(obj);
            });
            setType(auxTipo);
        }
    }, [coverageReduxState?.typeCoverage?.tipoCoberturaReintegro]);

    useEffect(() => {
        if (coverageReduxState?.areaParameters?.confirmacion) {
            let auxArea = [];
            coverageReduxState?.areaParameters?.rubroCoberturaReintegro?.forEach(rubro => {
                let obj = {
                    itemDescripcion: rubro?.rubroDescripcion,
                    itemCodigo: rubro?.rubroCodigo,
                };
                auxArea.push(obj);
            });
            setArea(auxArea);
        }
    }, [coverageReduxState?.areaParameters?.rubroCoberturaReintegro]);

    useEffect(() => {
        if (coverageReduxState?.refundCoverageResult?.confirmacion) {
            setResponse(coverageReduxState?.refundCoverageResult?.coberturaReintegro);
            setLoading(false);
        }
    }, [coverageReduxState?.refundCoverageResult]);

    useEffect(() => {
        if (Object.values(response).length > 0) {
            setLoading(false);
        }
    }, [response]);

    useEffect(() => {
        let data = {
            itemDescription: fullDataAffiliated?.fullData?.afiliado?.planDescripcion,
            itemCodigo: fullDataAffiliated?.fullData?.afiliado?.planCodigo,
        };
        setPlan(data);
        setRequest({ ...request, planCodigo: data.itemCodigo });
    }, [fullDataAffiliated?.fullData]);

    const handleGoToHome = () => {
        window.location.href = '/Coverage';
    };

    return (
        <>
            <FormHeader title='Reintegros' handleGoTo={handleGoToHome}/>
            {
                screen.sWidth > 768 &&
                <Divider className={classes.formDivider} />
            }
            {(screen.sWidth || Object.values(response).length === 0) &&
                <>
                    <section className={classes.sectionFlexRefunds}>
                        <div className={classes.divRefunds}>
                            <Autocomplete
                                error={false}
                                label={plan?.itemDescription}
                                option={plan}
                                selected={plan?.itemCodigo}
                                disabled={true}
                            />
                        </div>
                        <div className={classes.divRefunds}>
                            <Autocomplete
                                error={false}
                                label="TIPO"
                                option={type}
                                selected={type.tipoCodigo}
                                onChange={(event, newValue) => { handleAutocompleteTipoChange(newValue, 'tipoCodigo'); }}
                            />
                        </div>
                        <div className={classes.divRefunds}>
                            <Autocomplete
                                error={false}
                                label="RUBRO"
                                option={area}
                                selected={area.rubroCodigo}
                                onChange={(event, newValue) => { handleAutocompleteChange(newValue, 'rubroCodigo'); }}
                            />
                        </div>
                    </section>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className={classes.divAccordionPrograms} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CustomButton
                                title={'ENVIAR'}
                                onClick={handleClickSend}
                                customClass={`${classes.lightLinearGradient} ${classes.fontBold}`}
                                style={{ padding: '10px 15px 10px 15px', fontWeight: "bold", fontSize: "13px", width: '120px'}}
                            />
                        </div>
                    </div>
                </>
            }
            <section className={classes.sectionFlexRefunds} style={{ justifyContent: 'center', padding: '2%' }}>
                {!loading && coverageReduxState?.refundCoverageResult?.coberturaReintegro?.length > 0 ?
                    response?.map((item, i) => (
                        <article key={i} className={`${classes.divAccordionRefunds} ${classes.lightBoxShadow}`}>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <span className={`${classes.txtDarkBlue}`} style={{ fontWeight: 'bold', fontSize: '13.5px' }}>DETALLE DE RUBRO:</span>
                                <span className={`${classes.txtLightBlue} ${classes.txtRegular}`} style={{ fontWeight: 'bold', fontSize: '13.5px', paddingLeft: '1%' }}>{item.subRubroDescripcion}</span>
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <span className={`${classes.txtDarkBlue}`} style={{ fontWeight: 'bold', fontSize: '13.5px' }}>Cobertura:</span>
                                <span className={`${classes.txtLightBlue} ${classes.txtRegular}`} style={{ fontWeight: 'bold', fontSize: '13.5px', paddingLeft: '1%' }}>{item.cobertura}</span>
                            </div>
                        </article>
                    ))
                    : coverageReduxState?.refundCoverageResult?.coberturaReintegro?.length !== undefined &&
                    <NotFound
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        fullItem={coverageReduxState?.refundCoverageResult?.coberturaReintegro}
                        wsItem={coverageReduxState?.refundCoverageResult?.coberturaReintegro}
                        customMsg={
                                <span>No se encontraron resultados</span>
                        }
                        loading={loading}
                    />
                }
            </section>
        </>
    );
};

export default Refunds;