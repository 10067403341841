import { Typography, CircularProgress, Divider } from "@material-ui/core";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import FormHeader from "../../PersonalData/FormHeader";
import { useEffect, useState } from "react";
import useStyles from '../../../assets/styles/styles';
import { useFormHeaderText } from "../../../hooks/useFormHeaderText";
import AllergiesForm from "./AllergiesForm";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliatedAllergies, getAllergiesParams } from "../../../redux/actions/medicalDataAction";
import { showMessageModal, handleGoToPersonalMedicalData } from "../../../utils/utilsFunctions";
import CustomButton from "../../../controls/Button/CustomButton";


const Allergies = () => {
    const { screen } = useResizeScreen();
    const classes = useStyles();
    const { titleHeader, subtitleHeader } = useFormHeaderText('Alergias', 'Información sin carácter sanitario conforme ley Nº 26529', 'Mis datos medicos', 'Información sin carácter sanitario conforme ley Nº 26529', screen.sWidth);
    const [addItemScreen, setAddItemScreen] = useState(false);
    const dispatch = useDispatch();
    const allergiesParams = useSelector(state => state.medicalDataReducer.affiliatedAllergies);
    const [allergy, setAllergy] = useState(allergiesParams?.alergia);
    const [loading, setLoading] = useState(true);
    const [errorMessageModal, setErrorMessageModal] = useState(false);
    const errorMessage = useSelector(state => state.medicalDataReducer?.msgError);

    const handleShowAddItemScreen = (e) => {
        e.preventDefault();
        setAddItemScreen(prevState => !prevState);
    };

    useEffect(() => {
        dispatch(getAllergiesParams());
        dispatch(getAffiliatedAllergies({
            numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
            count: 100,
            skip: 0
        }));
    }, []);

    useEffect(() => {
        if (Object.values(allergiesParams).length > 0) {
            setAllergy(allergiesParams?.alergias);
            setLoading(false);
        }
    }, [allergiesParams?.alergias]);

    useEffect(() => {
        if (errorMessage?.length > 0) {
            setErrorMessageModal(true);
        }
        setLoading(true);
    }, [errorMessage]);

    return (
        <>
            <FormHeader title={titleHeader} subtitle={subtitleHeader} handleGoTo={handleGoToPersonalMedicalData} />
            {
                screen.sWidth > 768 &&
                <Divider className={classes.formDivider} style={{ marginBottom: '20px' }} />
            }
            <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`} style={{ justifyContent: 'flex-start' }}>
                {screen.sWidth <= 768 &&
                    <>
                        <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency}`} style={{ textTransform: 'uppercase', alignItems: 'center', display: 'flex' }}>
                            Alergias
                        </Typography>
                    </>
                }
                {!addItemScreen &&
                    <CustomButton
                        onClick={handleShowAddItemScreen}
                        title={"NUEVO "}
                        style={{ position: 'absolute', right: "5%" }}
                        customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                    />
                }
            </div>
            {loading ?
                <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
                :
                <AllergiesForm allergy={allergy} setAllergy={setAllergy} addItemScreen={addItemScreen} setAddItemScreen={setAddItemScreen} />
            }
            {allergiesParams?.alergias?.length === 0 && !addItemScreen && allergiesParams !== undefined &&
                <Typography
                    className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}
                    style={{ marginLeft: "30px", marginTop: "15px" }}>
                    {"No se encontraron resultados"}
                </Typography>}

                {showMessageModal(
                    errorMessage,
                    errorMessageModal,
                    setErrorMessageModal,
                    classes.borderWhite,
                    classes.quickAccessIcon,
                    classes.contentDialogTitle,
                    handleGoToPersonalMedicalData
                )}

        </>
    );
};

export default Allergies;
