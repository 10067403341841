import { useAppInsightsContext } from "./AppInsightsContext";

export default function useTrackPageView(
    pageName
) {
    const reactPlugin = useAppInsightsContext()

    const trackPageView = () => {
        const data = {
            name: pageName ?? document.title,
            uri: location.pathname
        };

        const email = localStorage.getItem("email");
        if (email?.length > 1) {
            data.properties = { email }
        }

        reactPlugin.trackPageView(data)

        return data;

    }

    return trackPageView;
}