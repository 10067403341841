import React, { useEffect, useState, useRef } from 'react'
import Select from '../../../../controls/Select/CustomSelect';
import TopButtons from '../../../AskTurn/Web/Buttons/TopButtons';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import RemoveCircleOutlinedIcon from '@material-ui/icons/RemoveCircleOutlined';
import Input from '../../../../controls/InputCustom/CustomizedInputs';
import InputNumber from '../../../../controls/InputCustom/InputNumber';
import FooterDrawer from '../../../AskTurn/FooterDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeScreen } from '../../../../hooks/useResizeScreen';
import useStyles from "../../../../assets/styles/styles";
import { Button, FormControl, FormHelperText, IconButton, Typography } from "@material-ui/core";
import { getAccountRefunds, setModel, getRefundTypeAttached } from '../../../../redux/actions/refundsActions';
import { showErrorMessageModal, validatorForms } from '../../../../utils/utilsFunctions';
import Loading from '../../../LoadingPage/Loading';
import CustomButton from '../../../../controls/Button/CustomButton';
import FrequentQuestionsButton from '../../../Authorizations/FrequentQuestionsButtons';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Dialog } from '@mui/material';
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ConfirmModal from '../../../PersonalData/ConfirmModal';
import CancelIcon from "@material-ui/icons/Cancel";

const userCode = parseInt(window.localStorage.getItem("numeroPersona"));

const tipoCuenta = [
    { tipoCuentaCodigo: 1, tipoCuentaDescripcion: 'CUENTA CORRIENTE' },
    { tipoCuentaCodigo: 2, tipoCuentaDescripcion: 'CAJA DE AHORRO' },
];

const model = {
    CBU: '',
    CUILCUIT: '',
    tipoCuentaCodigo: '',
    numeroCuenta: '',
    bancoCodigo: 0,
    bancoNombre: '',
    comprobanteDigital:''
};

const selectAccountValidations = [
    {
        name: 'numeroCuenta',
        type: 'number',
        required: true,
    }
]

const addAccountValidations = [
    {
        name: 'CBU',
        type: 'number',
        exactLength: 22,
        required: true,
        label: 'CBU',
    },
    {
        name: 'CUILCUIT',
        type: 'number',
        subType: 'cuitcuil',
        exactLength: 11,
        required: true,
        label: 'CUIT / CUIL',
    },
    {
        name: 'bancoNombre',
        type: 'string',
        maxLength: 100,
        required: true,
        label: 'NOMBRE DEL BANCO',
    },
    {
        name: 'tipoCuentaCodigo',
        type: 'list',
        required: true,
        label: 'TIPO DE CUENTA',
        list: [],
        maxLength: 2
    },
    {
        name: 'numeroCuenta',
        type: 'number',
        maxLength: 17,
        required: true,
        label: 'NUMERO DE CUENTA',
    },
    {
        name: 'comprobanteDigital',
        type: 'file',
        required: true,
    }];

const AddRefundStep4 = ({ activeStep, handleOpenCancelModal, handleBack, setActiveStep }) => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const dispatch = useDispatch();

    const [accountsList, setAccountsList] = useState([]);
    const [error, setError] = useState(model);
    const { accountRefunds, modelRequest, loading } = useSelector(state => state.refundsReducer);
    const [modelToSend, setModelToSend] = useState({ ...modelRequest, comprobanteTipoDocumento: "CUIT" });
    const [isNewAccount, setIsNewAccount] = useState(modelToSend?.isNewAccount);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);
    const [files, setFiles] = useState([]);
    const inputFile = useRef();
    const inputPhoto = useRef();
    const [openExistFileModal, setOpenExistFileModal] = useState(false);
    const refundsTypeAttached = useSelector(state => state?.refundsReducer?.refundTypeAttached);
    const [typeAttached, setTypeAttached] = useState([{ value: 0, label: '' }]);
    const [otherType, setOtherType] = useState([{ value: 0, label: '' }]);
    const [typeSelectedAttached, setTypeSelectedAttached] = useState('');
    const [labelTypeSelectedAttached, setLabelTypeSelectedAttached] = useState('');
    const [showTypeSelect, setShowTypeSelect] = useState(false);
    const [attachedError, setAttachedError] = useState({ attached: "*El adjunto Comprobante Digital es obligatorio" });
    const errorMessage = useSelector(state => state.refundsReducer.msgError);
    const [openErrorModal, setOpenErrorModal] = useState();

    const handleNext = (e) => {
        e.preventDefault();
        if (validateModelStep4()) {
            dispatch(setModel({ ...modelRequest, ...modelToSend, isNewAccount }));
            setActiveStep(activeStep + 1);
        }
    }

    const handleChange = (e) => {
        setModelToSend({ ...modelToSend, [e.target.name]: `${e.target.value}` });
        setError({ ...error, [e.target.name]: "" });
    }

    const handleSelectAccount = (e) => {
        let aux = accountRefunds?.cuentasReintegro?.find(item => item?.numeroCuenta === e.target.value);
        setModelToSend({ ...modelToSend, ...aux, bancoNombre: aux.bancoDescripcion, comprobanteTipoDocumento: "CUIT" });
        setError({ ...error, [e.target.name]: "" });
    }

    useEffect(() => {
        if (errorMessage !== "" && errorMessage !== undefined) {
            setOpenErrorModal(true);
        }
    }, [errorMessage]);

    const handleNew = () => {
        setIsNewAccount(!isNewAccount);
        setError(model);
        setModelToSend({ ...model, comprobanteTipoDocumento: "CUIT", comprobanteDigital: '' });
        setFiles([]);
        deleteFile();
    }

    const deleteFile = () => {
        let modelStep3 = JSON.parse(modelRequest?.adjuntos); 
        let modelStep4 = [modelToSend?.comprobanteDigital]; 
        let model = modelStep3?.filter(x => x.archivoNombreCompleto !== modelStep4?.[0]?.archivoNombreCompleto);
        setModelToSend({...modelToSend, 'comprobanteDigital': ''});
        dispatch(setModel({ ...modelToSend, 'adjuntos': JSON.stringify(model), 'comprobanteDigital': '' }));
    }

    useEffect(() => {

        /*Agrego tipo de cuentas*/
        let type = tipoCuenta.map((item) => {
            return { value: item.tipoCuentaCodigo, label: item.tipoCuentaDescripcion };
        });

        addAccountValidations.forEach((item) => {
            if (item.name === 'tipoCuentaCodigo') {
                item.list = type;
            }
        })

        dispatch(getAccountRefunds(userCode));
        dispatch(getRefundTypeAttached("REINTEGRO"));

        let modelStep3 = modelRequest?.adjuntos !== undefined ? JSON.parse(modelRequest?.adjuntos) : [];
        let model = modelStep3?.filter(x => x.archivoObservacion === "Comprobante digital");
        if(model?.length > 0) {
            setFiles(model);
        } else {
            setFiles([]);
        }
    }, []);

    useEffect(() => {
        let listAccounts = [{ value: "", label: "POR FAVOR AGREGUE UNA CUENTA" }];
        listAccounts = accountRefunds?.cuentasReintegro?.map((item) => {
            return { value: item?.numeroCuenta, label: `${item?.numeroCuenta} ${item?.bancoDescripcion}` };
        });
        setAccountsList(listAccounts);
    }, [accountRefunds]);

    useEffect(() => {
      if(modelToSend?.comprobanteDigital != null && modelToSend?.comprobanteDigital !== ''){
        setAttachedError({attached: ''});
      }else{
        setAttachedError({ attached: "*El adjunto Comprobante Digital es obligatorio" });
      }
    }, [modelToSend?.comprobanteDigital])
    

    const validateModelStep4 = () => {
        let auxValidation = {};

        if (isNewAccount) {
            auxValidation = addAccountValidations;
        } else {
            auxValidation = selectAccountValidations;
        }

        const { auxErrors, valid } = validatorForms(auxValidation, modelToSend);

        setError(auxErrors);

        return valid;
    }

    const addAttachment = () => {
        if (screen.sWidth > 768) {
            inputFile?.current?.click();
        } else {
            setOpenUploadModal(true);
        }
    };

    const uploadFile = (e) => {
        e.preventDefault();
        let redModal = "";
        let uploadedFiles = e.target.files;
        for (let uploaded of uploadedFiles) {
            let size = (uploaded.size / 1024);
            if (size < parseInt(configurationReduxState?.filter(x =>
                x?.id === 'medidaArchivosAdjuntos')[0]?.valor) * 1000) {
                let auxAdj = {
                    archivoNombre: uploaded.name.substring(0, uploaded.name.lastIndexOf(".")),
                    archivoExtension: uploaded.name.substring(uploaded.name.lastIndexOf(".") + 1),
                    archivoNombreCompleto: uploaded.name,
                    archivoTamanio: parseInt(size),
                    archivoClasificacion: otherType?.[0]?.value !== 0 && otherType?.[0]?.value,
                    archivo: null,
                    archivoObservacion: 'Comprobante digital',
                };

                let joinAttached = JSON.parse(modelRequest?.adjuntos);
                let filesInModel = joinAttached?.filter(x => x?.archivoNombreCompleto === auxAdj?.archivoNombreCompleto);

                if(filesInModel?.length > 0) {
                    setOpenExistFileModal(true);
                } else {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        auxAdj.archivo = reader.result?.split(',').pop();
                        setFiles([...files, auxAdj]);
                        joinAttached?.push(auxAdj);
                        setModelToSend({...modelToSend, 'adjuntos': JSON.stringify(joinAttached),'comprobanteDigital': auxAdj});
                    };
                    reader.readAsDataURL(uploaded);
                }

            } else {
                redModal = redModal + " " + uploaded.name;
            }
        }
        if (redModal != "") {
            setModalTitle('Atención');
            setModalDescription(
                `El archivo: ${redModal} supera el tamaño máximo.
                Asegurate de que los archivos no superen los ${configurationReduxState?.filter(x => x?.id === 'medidaArchivosAdjuntos')[0]?.valor}MB`
            );
            setModalIcon(<CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
            setErrorClass(classes.backgroundError);
            setOpenSendSuccess(prevState => !prevState);
        }
        e.target.value = null;
        handleClose();
    };

    const handleDeleteFile = (setFiles, files, fileName) => e => {
        e.preventDefault();

        let auxFiles = files?.filter(item => {
            return item?.archivoNombre !== fileName;
        });

        setFiles(auxFiles);
        setAttachedError({ attached: "*El adjunto Comprobante Digital es obligatorio" });
        deleteFile();
    };

    useEffect(() => {
        let type = refundsTypeAttached?.tipoAdjunto?.map(type => {
            return { value: parseInt(type?.tipoAdjuntosCodigo), label: type?.tipoAdjuntosDescripcion };
        });
        setTypeAttached(type);

        let othersType = type?.filter(x => x?.label === 'Otros');

        if(othersType?.length > 0) {
            setOtherType([{ value: othersType?.[0]?.value, label: othersType?.[0]?.label }]);
            setShowTypeSelect(false);
        } else {
            setShowTypeSelect(true);
        }
    }, [refundsTypeAttached?.tipoAdjunto]);

    const handleChangeSelect = () => e => {
        const { value } = e.target;
        let auxSelected = typeAttached?.filter(x => x?.value === value);
        setLabelTypeSelectedAttached(auxSelected?.[0]?.label);
        setTypeSelectedAttached(value);
        setOtherType([{ value: value, label: auxSelected?.[0]?.label }]);
    };

    const disabledButton = () => {
        let disable;
        if(showTypeSelect) {
            if(labelTypeSelectedAttached === '') {
                disable = true;
            } else {
                disable = false;
            }
        } else if(files?.length > 0){
            disable = true;
        } else {
            disable = false;
        }

        return disable;
    }

    const handleClose = () => {
        setOpenUploadModal(false);
    };

    const modalUpload = () => {
        return (
            <Dialog
                onClose={handleClose}
                open={openUploadModal}
                aria-labelledby="customized-dialog-title"
                id="backDropActivities"
            >
                <div id="modalActivities" style={{ textAlign: 'center' }}>
                    <IconButton
                        className={classes.closeButton}
                        aria-label="close"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogTitle
                        className={`${classes.contentDialogTitle}`}
                        style={{ padding: '20px' }}
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        <Typography
                            className={classes.titleDialogActivities}
                            style={{ fontWeight: "600", display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                            {'Adjuntar comprobante digital'}
                            <FrequentQuestionsButton />
                        </Typography>
                    </DialogTitle>

                    <DialogContent className={`${classes.contentDialogTitle}`} style={{ paddingTop: "0px" }}>
                        <div style={{ textAlign: "center" }}>
                            <CustomButton
                                onClick={() => { inputFile?.current?.click(); }}
                                style={{ padding: '5px 15px 5px 5px', width: '200px', marginBottom: '10px' }}
                                icon={<AttachFileIcon />}
                                title={"ADJUNTAR ARCHIVO"}
                                customClass={`${classes.lightLinearGradient} ${classes.marginLeft}`}
                            />
                            <CustomButton
                                onClick={() => { inputPhoto?.current?.click(); }}
                                title={"SACAR FOTO"}
                                style={{ padding: '5px 15px 5px 5px', width: '200px', marginBottom: '10px' }}
                                icon={<AttachFileIcon />}
                                customClass={`${classes.lightLinearGradient} ${classes.marginLeft}`}
                            />
                        </div>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    const showUploadFiles = () => {
        return (
            <section
                style={{ display: 'flex', flexDirection: "column", alignItems: "center", paddingTop: '10px', marginLeft: '40px' }}
            >
                {files?.map((file, i) => (
                    <div key={i} className={`${classes.txtSubtitleCalendar}`} style={{ display: "flex", justifyContent: "start", width: "100%", color: '#666666' }}>
                        <p className={`${classes.txtEllipsis}`}>
                            Adjuntaste el archivo: <span className={`${classes.txtSubtitleCalendar}`} style={{ marginLeft: '5px' }}>{file?.archivoNombreCompleto}</span>
                        </p>
                        <IconButton
                            style={{ marginTop: '-3px' }}
                            onClick={handleDeleteFile(setFiles, files, file?.archivoNombre)}>
                            <HighlightOffIcon />
                        </IconButton>
                    </div>
                ))}
            </section>
        );
    }

    return (
        <>
            {screen.sWidth > 768 &&
                <TopButtons handleOpenCancelModalTurn={handleOpenCancelModal} handleBackTurn={handleBack} activeStepTurn={activeStep} handleNextTurn={handleNext} titleCancel={"Cancelar"} titleConfirm={"Siguiente"} />
            }
            {
                loading ?
                    (<Loading isLoading={loading} />)
                    :
                    (
                        <div style={{ padding: "30px", display: "flex", flexDirection: "column" }}>
                            <>
                                <div style={{ marginBottom: "30px" }}>
                                    <Typography className={classes.titleTurn}>
                                        DATOS BANCARIOS
                                    </Typography>
                                </div>
                                <FormControl className={`${classes.formControlCustomSelectRefunds}`} variant="standard">
                                    <Select
                                        name={'numeroCuenta'}
                                        label={'CUENTA DE ACREDITACION'}
                                        list={accountsList}
                                        selected={modelToSend?.numeroCuenta ?? ''}
                                        onChange={handleSelectAccount}
                                        disabled={isNewAccount}
                                    />
                                    <FormHelperText style={{marginLeft:"30px"}} className={`${classes.errorText} ${classes.errorTextFormMobile}`} hidden={isNewAccount}>{error?.numeroCuenta}</FormHelperText>
                                </FormControl>

                                <div>
                                    <Button style={{ marginLeft: "15px" }} onClick={handleNew}>
                                        {
                                            isNewAccount ? (
                                                <>
                                                    <RemoveCircleOutlinedIcon className={`${classes.RemoveArrowIcon}`} style={{ marginRight: '5px', marginTop: '-1px' }} />
                                                    <span style={{ color: '#0276C9', fontWeight: '600' }}>Seleccionar Una Cuenta</span>
                                                </>
                                            ) : (
                                                <>
                                                    <AddCircleOutlinedIcon className={`${classes.ShowMoreArrowIcon}`} style={{ marginRight: '5px', marginTop: '-1px' }} />
                                                    <span style={{ color: '#0276C9', fontWeight: '600' }}>Agregar Nueva Cuenta</span>
                                                </>
                                            )
                                        }
                                    </Button>
                                </div>

                                {isNewAccount &&
                                    <div style={{  marginTop: "10px" }}>
                                        {
                                            addAccountValidations.map(({ name, label, type, list }) => {
                                                switch (type) {
                                                    case 'file': return<></>
                                                    case 'number':
                                                    case 'decimal':
                                                        return <FormControl className={`${classes.formControlStep2Refunds}`} variant="standard">
                                                            <InputNumber
                                                                label={label}
                                                                name={name}
                                                                value={modelToSend[name]}
                                                                type={type}
                                                                onChange={handleChange}
                                                            />
                                                            <FormHelperText className={`${classes.errorText} ${classes.errorTextFormMobile}`}>{error[name]}</FormHelperText>
                                                        </FormControl>
                                                    case 'list':
                                                        return <FormControl className={`${classes.formControlCustomSelectRefunds}`} variant="standard">
                                                            <Select
                                                                label={label}
                                                                name={name}
                                                                selected={modelToSend[name]}
                                                                list={list}
                                                                onChange={handleChange}
                                                            />
                                                            <FormHelperText style={{marginLeft:"25px"}} className={`${classes.errorText} ${classes.errorTextFormMobile}`}>{error[name]}</FormHelperText>
                                                        </FormControl>
                                                    default:
                                                        return <FormControl variant="standard" className={`${classes.formControlStep2Refunds}`}>
                                                            <Input
                                                                name={name}
                                                                label={label}
                                                                value={modelToSend[name]}
                                                                onChange={handleChange}
                                                            />
                                                            <FormHelperText className={`${classes.errorText} ${classes.errorTextFormMobile}`}>{error[name]}</FormHelperText>
                                                        </FormControl>
                                                }
                                            })
                                        }

                                        <Typography className={`${classes.titleTurn} ${classes.titleBox}`} style={{ textTransform: 'uppercase', fontSize: "14px", margin: "10px 0" }}>
                                            ADJUNTAR COMPROBANTE DIGITAL
                                        </Typography>

                                        { showTypeSelect &&
                                            <div style={{ paddingTop: '20px', paddingBottom: '20px', display: 'flex', flexDirection: "column", alignItems: "start" }}>
                                                <FormControl className={`${classes.formControlCustomSelectRefunds}`} variant="standard">
                                                    <Select
                                                        label='TIPO DE ARCHIVO'
                                                        name={'selectAttached'}
                                                        className={`${classes.selectAuthorizations}`}
                                                        list={typeAttached}
                                                        selected={typeSelectedAttached}
                                                        onChange={handleChangeSelect()}
                                                    />
                                                </FormControl>
                                            </div>
                                        }

                                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '35px' }}>
                                            <CustomButton title={"Nuevo adjunto"}
                                                onClick={addAttachment}
                                                style={{ height: "20px", padding: "16px", fontSize: "12px" }}
                                                icon={<AddCircleIcon style={{ marginRight: "8px" }} />}
                                                customClass={`${classes.txtMobile} ${classes.lightLinearGradient}`}
                                                disabled={disabledButton()}
                                            />
                                            {
                                                screen.sWidth > 768 &&
                                                <FrequentQuestionsButton />
                                            }
                                        </div>
                                        <FormHelperText className={`${classes.errorText}`} style={{ marginTop: screen.sWidth < 768 && '15px' }}>{attachedError?.attached}</FormHelperText>

                                        { files?.length > 0 && showUploadFiles() }

                                        <FormHelperText
                                            className={`${classes.formControlStep2Refunds}`}
                                            style={{ color: '#0276C9', fontWeight: '500', marginTop: '20px' }}
                                        >Esta cuenta se verá reflejada en 48 hs hábiles</FormHelperText>

                                        {openUploadModal && modalUpload()}

                                        <input
                                            ref={inputFile}
                                            type="file"
                                            hidden
                                            id='upFile'
                                            onChange={uploadFile}
                                            name='upFileName'
                                        />

                                        <input
                                            ref={inputPhoto}
                                            type="file"
                                            hidden
                                            capture="environment"
                                            id='upPhoto'
                                            onChange={uploadFile}
                                            name='upPhotoName'
                                        />
                                    </div>
                                }
                            </>
                        </div>
                    )
            }
            {
                screen.sWidth <= 768 &&
                <FooterDrawer
                    activeStep={activeStep}
                    handleOpenCancelModal={handleOpenCancelModal}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    customClassDiv={classes.footerAuthRequest}
                    confirm={true}
                />
            }

            <ConfirmModal
                setOpen={setOpenExistFileModal}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                        </div>
                    </>
                }
                isOpen={openExistFileModal}
                description={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            El archivo seleccionado ya existe en esta solicitud
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                            Por favor verificá el paso anterior
                        </div>
                    </>
                }
            />

            {showErrorMessageModal(errorMessage, openErrorModal, setOpenErrorModal, classes.borderWhite, classes.quickAccessIcon)}
        </>
    )
}

export default AddRefundStep4