import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CustomizedInputs from "../../controls/InputCustom/CustomizedInputs";
import CustomSelect from "../../controls/Select/CustomSelect";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import useStyles from "../../assets/styles/styles";
import { Divider, FormControl, Typography, Button, IconButton, CircularProgress } from "@material-ui/core";
import CustomButton from '../../controls/Button/CustomButton';
import CreateIcon from '@material-ui/icons/Create';
import CustomDatePicker from '../../controls/DatePicker/CustomizedDatePicker';
import { getCivilStatus, getTypesDocuments, getNationalities, abmFullData } from "../../redux/actions/personalDataActions";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SaveModal from './SaveModal';
import ConfirmModal from './ConfirmModal';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { format } from 'date-fns';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { showMessageModal, handleGoToPersonalData } from "../../utils/utilsFunctions";
import FrequentQuestionsButton from '../Authorizations/FrequentQuestionsButtons';

const FormPersonalData = ({ handleBack }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { screen } = useResizeScreen();
    const [errors, setErrors] = useState({});
    const [disable, setDisable] = useState(true);
    const [nationality, setNationality] = useState(0);
    const [lastName, setLastName] = useState("");
    const [motherLastName, setmotherLastName] = useState("");
    const [name, setname] = useState("");
    const [othersName, setothersName] = useState("");
    const [cuil, setCuil] = useState(0);
    const [birthDate, setbirthDate] = useState();
    const [sex, setSex] = useState("");
    const [gender, setgender] = useState(null);
    const [civilStatus, setcivilStatus] = useState("");
    const [documents, setdocuments] = useState([]);
    const [listCivilStatus, setListCivilStatus] = useState([]);
    const [listCountries, setListCountry] = useState([]);
    const [listTypesDocument, setTypesDocument] = useState([]);
    const [openSendSuccess, setOpenSendSuccess] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [newData, setNewData] = useState({});
    const listSexo = [{ value: "F", label: "Femenino" }, { value: "M", label: "Masculino" }];
    const dataUser = useSelector((state) => state?.personalDataReducer);
    const [birthCertificate, setBirthCertificate] = useState(false);
    const [uploadDniImage, setUploadDniImage] = useState(false);
    const [filesDni, setFilesDni] = useState([]);
    const [filesCertified, setFilesCertified] = useState([]);
    const [namesCertified, setNamesCertified] = useState([]);
    const [namesDni, setNamesDni] = useState([]);
    const [modalTitle, setModalTitle] = useState("");
    const [modalDescription, setModalDescription] = useState("");
    const [modalIcon, setModalIcon] = useState(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
    const [errorClass, setErrorClass] = useState('');
    const [loading, setLoading] = useState(true);
    const abm = useSelector((state) => state?.personalDataReducer?.abmData);
    const errorType = useSelector((state) => state?.personalDataReducer?.error);
    const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);
    const [errorMessageModal, setErrorMessageModal] = useState(false);   
    const errorMessage = useSelector(state => state.personalDataReducer.msgError);

    const [somethingChange, setSomethingChange] = useState(false);

    useLayoutEffect(() => {
        setLoading(true);
        inicializeErrors();
        dispatch(getCivilStatus());
        dispatch(getTypesDocuments({ numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")) }));
        dispatch(getNationalities());
    }, []);

    useEffect(() => {
        setDataOfWS();
    }, [dataUser]);

    useEffect(() => {
        if (errorMessage !== "" && errorMessage !== undefined) {
            setErrorMessageModal(true);
        }
    }, [errorMessage]);

    function setDataOfWS() {
        let cv = [];
        let cts = [];
        let tp = [];
        if (Object?.values(dataUser?.civilStatus)?.length > 0) {
            dataUser?.civilStatus?.forEach((element) => {
                cv.push({ value: element.estadoCivilCodigo, label: element.estadoCivilDescripcion });
            });
            setListCivilStatus(cv);
        }
        if (Object?.values(dataUser?.nationalities)?.length > 0) {
            dataUser?.nationalities?.forEach((element) => {
                cts.push({ value: element.nacionalidadCodigo, label: element.nacionalidadDescripcion });
            });
            setListCountry(cts);

        }
        if (Object?.values(dataUser?.typesDocuments)?.length > 0) {
            dataUser?.typesDocuments.forEach((element) => {
                tp.push({ value: element.documentoTipoCodigo, label: element.documentoTipoDescripcion });
            });
            setTypesDocument(tp);
        }

        if (Object?.values(dataUser?.fullData)?.length > 0) {
            var dateString = dataUser?.fullData?.afiliado?.fechaNacimiento?.replace(/-/g, '/').replace('T', ' ');
            setNewData(dataUser?.fullData?.afiliado);
            setLastName(dataUser?.fullData?.afiliado?.apellido);
            setmotherLastName(dataUser?.fullData?.afiliado?.apellidoMaterno);
            setname(dataUser?.fullData?.afiliado?.nombre);
            setothersName(dataUser?.fullData?.afiliado?.otrosNombres);
            setCuil(dataUser?.fullData?.afiliado?.cuil);
            setbirthDate(new Date(dateString).toISOString());
            setSex(dataUser?.fullData?.afiliado?.sexo);
            setgender(dataUser?.fullData?.afiliado?.genero);
            setcivilStatus(dataUser?.fullData?.afiliado?.estadoCivilCodigo);
            setdocuments(dataUser?.fullData?.afiliado?.documentos);
            setNationality(dataUser?.fullData?.afiliado?.nacionalidadCodigo);
            setLoading(false);
        }
    }

    useEffect(() => {
        setUploadDniImage(validateAttachmentDNI());
        setSomethingChange(true);
    }, [lastName]);

    useEffect(() => {
        setUploadDniImage(validateAttachmentDNI());
        setSomethingChange(true);
    }, [motherLastName]);

    useEffect(() => {
        setUploadDniImage(validateAttachmentDNI());
        setSomethingChange(true);
        return (() => {
            setLoading(false);
        });
    }, [name]);

    useEffect(() => {
        setUploadDniImage(validateAttachmentDNI());
        setSomethingChange(true);
    }, [othersName]);

    useEffect(() => {
        setUploadDniImage(validateAttachmentCertified());
        setSomethingChange(true);
    }, [documents]);

    useEffect(() => {
        setUploadDniImage(validateAttachmentCertified());
        setSomethingChange(true);
    }, [cuil]);

    useEffect(() => {
        setUploadDniImage(validateAttachmentCertified());
        setSomethingChange(true);
    }, [birthDate]);

    useEffect(() => {
        setUploadDniImage(validateAttachmentCertified());
        setSomethingChange(true);
    }, [nationality]);

    useEffect(() => {
        setBirthCertificate(validateAttachmentCertified());
        setUploadDniImage(validateAttachmentCertified());
        setSomethingChange(true);
    }, [sex]);

    useEffect(() => {
        setSomethingChange(true);
    }, [gender, civilStatus]);

    const setBackgroundMobile = () => {
        let cssClass = '';
        if (screen.sWidth <= 768)
            cssClass = classes.titlePersonalDataForm;
        return cssClass;
    };

    const Attachment = (show, text, title, id, error) => {
        if (show) {
            return (<>
                <div className={classes.divAttachment}>
                    <div className={classes.divButtonsAttachment} >
                        <Typography style={{ marginLeft: "5%" }} className={errors[error] == "" ? classes.txtSubtitleCalendar : classes.txtError}>{text}</Typography>
                        {
                            screen.sWidth > 768
                                ?
                                    <CustomButton
                                        style={{ padding: '5px 15px 5px 5px', marginLeft: '10px', width: '120px' }}
                                        title={title}
                                        icon={<AttachFileIcon />}
                                        customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                                        onClick={() => {
                                            document.getElementById(id).click();
                                        }}
                                    />
                                :
                                    <IconButton onClick={() => { document.getElementById(id).click(); }}>
                                        <AttachFileIcon className={classes.NextAppointmentIconRound} />
                                    </IconButton>
                        }
                            <FrequentQuestionsButton />
                    </div>

                    {
                        id == "upfileCertified" ?
                            <>
                                {filesCertified?.map((certifiedFile, i) => {
                                    return (
                                        <Typography key={i} style={{ marginLeft: "5%" }} className={classes.txtSubtitleCalendar}>{certifiedFile?.archivoNombre}</Typography>
                                    );
                                })}
                            </>
                            :
                            <>
                                {filesDni?.map((dniFile, index) => {
                                    return (
                                        <Typography key={index} style={{ marginLeft: "5%" }} className={classes.txtSubtitleCalendar}>{dniFile?.archivoNombre}</Typography>
                                    );
                                })}
                            </>
                    }
                </div>
            </>);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        var form = newData;
        form.apellido = lastName;
        form.apellidoMaterno = motherLastName;
        form.nombre = name;
        form.otrosNombres = othersName;
        if(typeof birthDate === "string") {
            form.fechaNacimiento = birthDate.substring(0, 10);
        } else {
            form.fechaNacimiento = format(birthDate, "yyyy-MM-dd");
        }
        form.sexo = sex;
        form.genero = gender;
        form.nacionalidadCodigo = nationality;
        form.estadoCivilCodigo = civilStatus;
        form.cuil = cuil;
        form.domicilios = [];
        form.telefonos = [];
        form.mails = [];
        form.deportes = [];
        form.hobbies = [];
        let list = [];
        let documentos = [];
        if (validate(form)) {
            filesDni?.forEach((item) => {
                list.push(item);
            });
            filesCertified?.forEach((item) => {
                list.push(item);
            });
            documents?.forEach((item) => {
                delete item.documentoTipoDescripcion;
                item.documentoModo = "M";
                documentos.push(item);
            });
            form.documentos = documentos;
            form.adjuntos = list;
            delete form.cuentasReintegro;
            delete form.debitosAutomaticos;
            setNewData(form);
            setOpenConfirm(prevState => !prevState);
        }
    };

    const sendForm = () => {
        /**call to service, send newData */
        let modelToSend = {
            informacion: newData,
            numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
        };
        dispatch(abmFullData(modelToSend));
        const circularProgress = (<div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress color="inherit" /></div>);
        setModalTitle('INICIANDO GESTION');
        setModalDescription(circularProgress);
        setModalIcon('');
        setErrorClass('');
        setOpenSendSuccess(prevState => !prevState);
        setOpenConfirm(prevState => !prevState);
        setDataOfWS();
    };

    useEffect(() => {
        if (abm?.confirmacion !== undefined) {
            if (abm?.confirmacion) {
                setModalIcon(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
                setModalTitle('Enviado correctamente')
                setModalDescription('Los cambios han sido realizados, una vez validados por nuestro sector correspondiente observarás los cambios en el sistema.');
                setErrorClass("");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
            else {
                setModalIcon(<CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
                setModalTitle('Ocurrió un error')
                setModalDescription('Por favor, intente más tarde');
                setErrorClass(classes.backgroundError);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
            setOpenSendSuccess(true);
        }
    }, [abm]);

    useEffect(() => {
        if(errorType?.confirmation === false) {
            setModalIcon(<CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
            setModalTitle('Ocurrió un error')
            setModalDescription('El archivo adjunto debe ser una imagen o un archivo pdf');
            setErrorClass(classes.backgroundError);
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }
    }, [errorType]);

    const handleDisable = () => {
        setDisable(prevState => !prevState);
        setUploadDniImage(false);
        setBirthCertificate(false);
        setSomethingChange(false);
        setDataOfWS();
    };

    const inicializeErrors = () => {
        let temp = {};
        temp.lastName = "";
        temp.motherLastName = "";
        temp.name = "";
        temp.othersName = "";
        temp.documentsError = [];
        temp.nationalityCode = "";
        temp.nationalityDescription = "";
        temp.cuil = "";
        temp.birthDate = "";
        temp.sexo = "";
        temp.genero = "";
        temp.errorFileDNI = "";
        temp.errorFileCertified = "";

        setErrors(temp);
    };

    const validate = (param) => {
        let temp = validateValues(param, filesDni, filesCertified, birthCertificate, uploadDniImage);
        setErrors({ ...temp.error });
        setErrors(prevState => ({ ...prevState, documentsError: temp.error.documentsError }));
        return !(temp.obj.includes(false));
    };


    const handleChangeInput = (position) => (e) => {
        let { name, value } = e.target;
        let docUpdate = documents.map((item, i) => {
            if (position == i) {
                return { ...item, [name]: value };
            } else { return item; }
        });
        docUpdate[position].documentoTipoModo = "M";
        setdocuments(docUpdate);
    };

    const handleUpload = (input) => (e) => {
        let alertGreatSize = "";
        var filesUpload = e.target.files;
        let names = "";
        for (let file of filesUpload) {
            let size = (file.size / 1024);
            if (size < parseInt(configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor) * 1000) {
                let adj = {
                    archivoNombre: file.name.substring(0, file.name.indexOf(".")),
                    archivoExtension: file.name.substring(file.name.indexOf(".") + 1),
                    archivoNombreCompleto: file.name,
                    archivoTamanio: parseInt(size),//KB
                    archivo: null,//new Blob([file],{type: file.type}),
                    archivoClasificacion: 0
                };
                const reader = new FileReader();
                reader.onloadend = () => {
                    adj.archivo = reader.result?.split(',').pop();
                    if (input == "DNI") {
                        setFilesDni((prevState) => [...prevState, adj]);
                        names = names + " " + file.name;
                        setNamesDni(names);
                    }
                    else {
                        setFilesCertified((prevState) => [...prevState, adj]);
                        names = names + " " + file.name;
                        setNamesCertified(names);
                    }
                };
                reader.readAsDataURL(file);
            } else {
                alertGreatSize = alertGreatSize + " " + file.name;
            }
        }
        if (alertGreatSize != "") {
            setModalTitle('Atención');
            setModalDescription(`Los archivos: ${alertGreatSize} superan el tamaño máximo. Asegurate de que los archivos no superen los ${configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor}MB`);
            setModalIcon(<CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
            setErrorClass(classes.backgroundError);
            setOpenSendSuccess(prevState => !prevState);

        }
    };

    const validateAttachmentDNI = () => {
        let changeName = name != dataUser?.fullData?.afiliado?.nombre;
        let changeLastName = lastName != dataUser?.fullData?.afiliado?.apellido;
        let changeMotherLastName = motherLastName != dataUser?.fullData?.afiliado?.apellidoMaterno;
        let changeOthersNames = othersName != dataUser?.fullData?.afiliado?.otrosNombres;
        return (changeName || changeLastName || changeMotherLastName || changeOthersNames);
    };

    const validateAttachmentCertified = () => {
        let changeDocuments = validateDocument(documents, dataUser?.fullData?.afiliado?.documentos);
        let changecuil = cuil != dataUser?.fullData?.afiliado?.cuil;
        let dateStatus = birthDate;
        let date = dataUser?.fullData?.afiliado?.fechaNacimiento;
        let changebirth = date !== dateStatus;
        let changeNationality = nationality != dataUser?.fullData?.afiliado?.nacionalidadCodigo;
        let changeSex = sex != dataUser?.fullData?.afiliado?.sexo;
        let changeGender = gender != dataUser?.fullData?.afiliado?.genero;
        return (changeDocuments || changecuil || changeNationality || changeSex || changebirth || changeGender);
    };

    const handleClickEdit = () => (e) => {
        if (e.target.innerText === "EDITAR") {
            handleDisable();
            e.target.innerText = "CANCELAR";
            setDataOfWS();
        }
        else {
            e.target.innerText = "EDITAR";
            handleDisable();
            setDataOfWS();
        }
    };

    return (
        <>
            <div className={`${setBackgroundMobile()}`} style={{ paddingTop: '20px', paddingBottom: "10px" }}>
                <div style={{ display: 'flex' }}>
                    {
                        screen.sWidth <= 768 &&
                        <IconButton onClick={handleGoToPersonalData}>
                            <ArrowBackIcon className={classes.ShowMoreArrowIcon} style={{ fontSize: "25px", marginBottom: "15px" }} />
                        </IconButton>
                    }
                    <Typography className={`${classes.titleTurn} ${classes.titleBox}`} style={{ textTransform: 'uppercase' }}>
                        Datos Personales
                    </Typography>
                    <Divider className={classes.formDivider} />
                </div>
            </div>
            <Divider className={classes.formDivider} />
            {loading ?
                <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
                :
                <Fragment>
                    <div className={classes.divEditBtn}>
                        {screen.sWidth <= 768 ?
                            (disable &&
                                (<div>
                                    <Button onClick={handleDisable}>
                                        <CreateIcon className={classes.ShowMoreArrowIcon} />
                                    </Button>
                                </div>)
                            )
                            :
                            (!disable && screen.sWidth > 768 ?
                            <>
                                <CustomButton
                                    title="CANCELAR"
                                    customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                                    onClick={handleClickEdit()}
                                />
                                {
                                    Attachment(uploadDniImage, "Adjuntar documento de identidad(frente y dorso) aquí", "Adjuntar", "upfileDni", "errorFileDNI")
                                }
                                {
                                    Attachment(birthCertificate, "Adjuntar partida de nacimiento aquí", "Adjuntar", "upfileCertified", "errorFileCertified")
                                }
                            </> :
                            
                        <CustomButton
                            title="EDITAR"
                            customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                            onClick={handleClickEdit()}
                        />
                            )
                        }
                    </div>
                    <form onSubmit={handleSubmit} className={classes.addressForm} style={{margin: '30px', marginLeft: screen.sWidth < 768 ? '10px' :'28px'}}>
                        <input hidden className={classes.input} id="upfileDni" multiple type="file" onChange={handleUpload("DNI")} />
                        <input hidden className={classes.input} id="upfileCertified" multiple type="file" onChange={handleUpload("CERT")} />

                        {disable ?
                            (
                                <>
                                    <FormControl className={classes.formControlAddress} variant="standard">
                                        <CustomizedInputs customRequired={true} value={lastName + " " + motherLastName} label="APELLIDO" disabled={disable} type="text" />
                                    </FormControl>


                                    <FormControl className={classes.formControlAddress} variant="standard">
                                        <CustomizedInputs customRequired={false} value={`${name?.trimEnd()} ${othersName?.trimEnd()}`} label="NOMBRES" disabled={disable} type="text" />
                                    </FormControl>

                                </>
                            ) : (
                                <>
                                    <FormControl className={classes.formControlAddress} variant="standard">
                                        <CustomizedInputs customRequired={true} onChange={(e) => { setLastName(e.target.value); }} value={lastName?.trimEnd()} label="APELLIDO" disabled={disable} type="text" />
                                        {errors.lastaName != "" && <span className={classes.spanError}>{errors.lastName}</span>}
                                    </FormControl>

                                    <FormControl className={classes.formControlAddress} variant="standard">
                                        <CustomizedInputs customRequired={false} onChange={(e) => { setmotherLastName(e.target.value); }} value={motherLastName?.trimEnd()} label="APELLIDO MATERNO" disabled={disable} type="text" />
                                        {errors.motherLastName != "" && <span className={classes.spanError}>{errors.motherLastName}</span>}

                                    </FormControl>

                                    <FormControl className={classes.formControlAddress} variant="standard">
                                        <CustomizedInputs onChange={(e) => { setname(e.target.value); }} value={name?.trimEnd()} label="NOMBRES" disabled={disable} type="text" />
                                        {errors.name != "" && <span className={classes.spanError}>{errors.name}</span>}

                                    </FormControl>

                                    <FormControl customRequired={false} className={classes.formControlAddress} variant="standard">
                                        <CustomizedInputs customRequired={false} onChange={(e) => { setothersName(e.target.value); }} value={othersName?.trimEnd()} label="OTROS NOMBRES" disabled={disable} type="text" />
                                    </FormControl>
                                </>
                            )
                        }
                        {screen.sWidth <= 768 && !disable &&
                            Attachment(uploadDniImage, "Adjuntar documento de identidad(frente y dorso) aquí", "", "upfileDni", "errorFileDNI")
                        }
                        {documents?.map((item, i) => {
                            return (
                                disable ?
                                    <FormControl className={classes.formControlAddress} variant="standard" style={{marginTop: '8px'}}>
                                        <CustomizedInputs name='documentoNumero' customRequired={true} onChange={handleChangeInput(i)} value={item?.documentoTipoCodigo.toUpperCase() + " " + item?.documentoNumero.toUpperCase()} label={item?.documentoTipoDescripcion} disabled={disable} />
                                        {errors.documentsError[i]?.type != "" && <span className={classes.spanError}>{errors.tipoDocumento}</span>}
                                        {errors.documentsError[i]?.doc != "" && <span className={classes.spanError}>{errors.numeroDocumento}</span>}

                                    </FormControl>
                                    : <>
                                        <FormControl className={classes.formControlCustomSelect} variant="standard">
                                            <CustomSelect name='documentoTipoCodigo' customRequired={true} disable={disable} list={listTypesDocument} selected={item?.documentoTipoCodigo} placeholder={documents[i]?.documentoTipoDescripcion} position={i} onChange={handleChangeInput(i)} label={"TIPO DOCUMENTO"} />
                                            {errors.documentsError[i]?.type != "" && <span className={classes.spanError}>{errors.tipoDocumento}</span>}
                                        </FormControl>
                                        <FormControl className={classes.formControlAddress} variant="standard">
                                            <CustomizedInputs
                                                name='documentoNumero'
                                                customRequired={true}
                                                onChange={handleChangeInput(i)}
                                                value={parseInt(item?.documentoNumero)}
                                                label="NUMERO DOCUMENTO" disabled={disable}
                                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                                                inputProps={{ maxLength: 16 }}
                                            />
                                            {errors.documentsError[i]?.doc != "" && <span className={classes.spanError}>{errors.numeroDocumento}</span>}
                                        </FormControl>
                                    </>
                            );
                        })
                        }

                        <FormControl className={classes.formControlAddress} variant="standard">
                            <CustomizedInputs
                                customRequired={true}
                                onChange={(e) => { setCuil(e.target.value); }}
                                value={parseInt(cuil)}
                                label="NUMERO DE CUIL"
                                disabled={disable}
                                autocomplete={true}
                                onInput={
                                    (e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');
                                    }}
                                inputProps={{ maxLength: 16 }}
                            />

                            {errors.cuil != "" && <span className={classes.spanError}>{errors.cuil}</span>}
                        </FormControl>

                        <FormControl className={classes.datePickerPersonalData} variant="standard" style={{right: screen.sWidth < 768 ? '5px': '3px', marginBottom:'8px'}}>
                            {<CustomDatePicker onChange={setbirthDate} customRequired={true} value={birthDate} label="FECHA DE NACIMIENTO" disable={disable} />}
                            {errors.birthDate != "" && <span className={classes.spanError}>{errors.birthDate}</span>}

                        </FormControl>

                        <FormControl className={classes.formControlCustomSelect} variant="standard" style={{right: screen.sWidth < 768 ? '5px': '5px', marginBottom:'8px'}}>
                            <CustomSelect
                                customRequired={true}
                                disable={disable}
                                list={listCountries}
                                selected={nationality}
                                label="NACIONALIDAD"
                                onChange={(e) => { setNationality(e.target.value); }}
                                disabled={disable}
                            />
                            {errors.nacionalidad != "" && <span className={classes.spanError}>{errors.nacionalidad}</span>}

                        </FormControl>

                        <FormControl className={classes.formControlCustomSelect} variant="standard" style={{right: screen.sWidth < 768 ? '5px': '5px', marginBottom:'10px'}}>
                            <CustomSelect customRequired={true} disable={disable} list={listSexo} selected={sex} onChange={(e) => { setSex(e.target.value); }} label={"SEXO BIOLOGICO"} />
                            {errors.sexo != "" && <span className={classes.spanError}>{errors.sexo}</span>}
                        </FormControl>

                        {screen.sWidth <= 768 && !disable &&
                            Attachment(birthCertificate, "Adjuntar partida de nacimiento aquí", "", "upfileCertified", "errorFileDNI")
                        }

                        <FormControl className={classes.formControlAddress} variant="standard" style={{left:'2px', marginTop:'8px'}}>
                            <CustomizedInputs onChange={(e) => { setgender(e.target.value); }} value={gender} label="GENERO AUTOPERCIBIDO" disabled={disable} type="text" />
                        </FormControl>

                        <FormControl className={`${classes.formControlCustomSelect}`} variant="standard" style={{right: screen.sWidth < 768 ? '5px': '5px'}}>
                            <CustomSelect disable={disable} list={listCivilStatus} selected={civilStatus} onChange={(e) => { setcivilStatus(e.target.value); }} label={"ESTADO CIVIL"} />
                        </FormControl>
                        <Divider style={{marginBottom: "80px", color: "#fff", border: "0"}} />

                        {!disable &&
                            <div className={`${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`} style={{marginBottom: "30px"}}>
                                {
                                    screen.sWidth <= 768 &&
                                    <div>
                                        <CustomButton
                                            customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                                            title='Cancelar'
                                            type='button'
                                            onClick={handleDisable}
                                        />
                                    </div>
                                }

                                {somethingChange &&
                                    <div>
                                        <CustomButton
                                            customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                                            title='Guardar'
                                            type='submit'
                                            onClick={handleSubmit}
                                        />
                                    </div>
                                }
                            </div>
                        }
                        <SaveModal isOpen={openConfirm} setOpen={setOpenConfirm} type='submit' onClick={() => { sendForm(); }}
                            titleModal={
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    Confirmar envío de datos
                                    </div>
                                </>
                            }
                            descriptionModal="¿Deseas confirmar los cambios?"
                        />
                    </form>

                    <ConfirmModal isOpen={openSendSuccess} setOpen={setOpenSendSuccess} errorClass={errorClass}
                        titleModal={
                            <>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    {modalIcon}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    {modalTitle}
                                </div>
                            </>
                        }
                        description={modalDescription} />
                </Fragment>
            }
            {
                showMessageModal(
                    errorMessage,
                    errorMessageModal,
                    setErrorMessageModal,
                    classes.borderWhite,
                    classes.quickAccessIcon,
                    classes.contentDialogTitle,
                    handleGoToPersonalData
                )
            }
        </>
    );
};

export default React.memo(FormPersonalData);


function validateValues(param, listFileDni, listFileCertified, birthCertificate, uploadDniImage) {
    let temp = {};
    temp.lastName = param?.apellido && param?.apellido?.length > 0 ? "" : "*Este campo es obligatorio";
    temp.name = param?.nombre && param?.nombre?.length > 0 ? "" : "*Este campo es obligatorio";
    temp.nationality = param?.nacionalidadCodigo && param?.nacionalidadCodigo > 0 ? "" : "*Este campo es obligatorio";
    temp.cuil = param?.cuil && param?.cuil > 0 ? "" : "*Este campo es obligatorio";
    temp.birthDate = param?.fechaNacimiento != undefined ? "" : "*Este campo es obligatorio";
    temp.sexo = param?.sexo && param?.sexo?.length > 0 ? "" : "*Este campo es obligatorio";
    temp.errorFileCertified = validateErrorFile(birthCertificate, temp.errorFileCertified, listFileCertified);
    temp.errorFileDNI = validateErrorFile(uploadDniImage, temp.errorFileDNI, listFileDni);
    let validation = [];
    let res = Object?.values(temp).every((value) => value == "");
    validation.push(res);
    temp.documentsError = documentError(param?.documentos);
    UpdateValidation(temp, validation);
    return { error: temp, obj: validation };
}

function documentError(docs) {
    let errors = [];
    docs?.map((item, i) => {
        let docError = {};
        docError.type = item.documentoTipoCodigo != null && item.documentoTipoCodigo != "" ? "" : "*Este campo es obligatorio";
        docError.doc = item.documentoNumero.length != 0 ? "" : "*Este campo es obligatorio";
        errors.push(docError);
    });
    return errors;
}

function UpdateValidation(temp, validation) {
    temp?.documentsError?.forEach((item, i) => {
        if (Object?.values(temp.documentsError[i]).every((x) => x === "")) {
            validation.push(true);
        } else {
            validation.push(false);
        }

        Object?.values(temp).every((value) => value == "");
    });
}

function validateErrorFile(valid, data, list) {
    if (valid)
        return (list.length > 0 ? "" : "error");
    else
        return (data == undefined ? "" : data);
}

function validateDocument(listNew, listService) {
    var result = false;
    listNew?.map((item, i) => {
        let old = listService[i];
        let validType = item?.documentoTipoCodigo != old?.documentoTipoCodigo;
        let validDocNumber = item?.documentoNumero != old?.documentoNumero;
        if (validType || validDocNumber) {
            result = true;
        }
    });
    return result;
}
