import React, { useState } from "react";
import { Divider, IconButton, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useStyles from '../../assets/styles/styles';
import StarIcon from '@material-ui/icons/Star';
import { selectNotification, modifyNotification  } from "../../redux/actions/notificationsActions";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {useResizeScreen} from "../../hooks/useResizeScreen";

const NotificationDetail = () => {

    const notificationSelected = useSelector(state => state.notificationReducer.notificationSelected);
    const history = useHistory();
    const dispatch = useDispatch();
    const {screen} = useResizeScreen();
    const classes = useStyles();
    const [favorite, setFavorite] = useState(notificationSelected?.favourite);

    const handlClickFavorite = (e) => {
        e.preventDefault();
        let notificationArray = [];
        notificationArray.push(notificationSelected);
        let newNotification = notificationArray?.map(item => {
            return { ...item, favourite: !item.favourite };
        });
        dispatch(selectNotification(newNotification[0]));       
        dispatch(modifyNotification(newNotification[0]));
        setFavorite(prevState => !prevState);
    };
    const isFavorite = () => {
        let response = '';
        if (favorite) {
            response = classes.iconDestacate;
        }
        return response;
    };

    const showDate = () => {
        return (
            <Typography style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }} >{new Date(notificationSelected?.message?.createDate?.split('T')[0])?.toLocaleDateString()}</Typography> 
        );
    } 

    return (
        <>
            <div style={{ padding: '3%' }}>
                <section style={{ display: 'flex', justifyContent: 'space-between', flex: '1 1 100%', marginBottom: '20px' }}>
                    <IconButton onClick={() => history.push('/AllNotifications')}>
                        <ArrowBackIcon className={classes.iconFilter} />
                    </IconButton>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {screen.sWidth < 768 && showDate()}
                    </div>
                    <IconButton onClick={handlClickFavorite}>           
                        <Typography className={`${classes.txtSubtitle}`} style={{ fontSize: '11px', fontWeight: 'bold' }}>
                            AGREGAR DESTACADO
                        </Typography>
                        <StarIcon className={isFavorite()} style={{ margin: '0 auto', fontSize: '1rem', marginTop: "-4px", marginLeft: "5px" }} />
                    </IconButton>
                </section>
                <article style={{ margin: screen.sWidth > 768 ? '5px' : '10px' }}>
                    <div style={{ display: 'flex', flex: '1 1 100%', justifyContent: 'space-between', paddingBottom: '1%', marginLeft: screen.sWidth > 768 ? '10px' : '5px' }}>
                        <Typography className={`${classes.mainTitle} ${classes.txtSubtitle}`} style={{ fontSize: '22px' }} >{notificationSelected?.message?.title}</Typography>
                       {screen.sWidth > 768 && showDate()}
                    </div>
                    <Divider />
                    <div style={{ margin: '0 auto' }}>
                        <Typography style={{ fontSize: '14px', display: 'flex', flex: '1 1 100%', fontWeight: 'bold', padding: '1%' }} >Descripción: </Typography>
                        <div style={{ fontSize: '14px', padding: '1%', display: 'flex', flex: '1 1 100%' }} dangerouslySetInnerHTML={{ __html: notificationSelected?.message?.content }} />

                    </div>
                    <section style={{ display: 'flex', justifyContent: 'flex-start', flex: '1 1 100%' }}>
                    {notificationSelected?.message?.module !== '' && (
                        <Typography style={{ fontSize: '14px', display: 'flex', flex: '1 1 100%', fontWeight: 'bold', padding: '1%' }} >Este es un mensaje de: {notificationSelected?.message?.module} </Typography>
                    )}
                        </section>
                    {notificationSelected?.image &&
                        <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img style={{ display: 'flex', minWidth: '200px', width: '50%' }} src={notificationSelected?.message?.image} alt='imgNotificacion' />
                        </aside>
                    }
                </article>
            </div>
        </>
    );
};

export default NotificationDetail;