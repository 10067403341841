import React, { Fragment, useEffect, useState } from 'react';
import useStyles from "../../../assets/styles/styles";
import { Button, Divider, FormControl, FormHelperText } from '@material-ui/core';
import CustomButton from "../../../controls/Button/CustomButton";
import CustomizedInputs from "../../../controls/InputCustom/CustomizedInputs";
import SaveModal from '../../PersonalData/SaveModal';
import ConfirmModal from '../../PersonalData/ConfirmModal';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CustomSelect from '../../../controls/Select/CustomSelect';
import useMessageModal from '../../../hooks/useMessageModal';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch, useSelector } from 'react-redux';
import { abmChronicDiseases } from '../../../redux/actions/medicalDataAction';
import NotFound from "../../HomeLogged/NotFound";

const ChronicDiseasesForm = ({ disease, setDisease, disabled, setDisabled }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [error, setError] = useState({});
    const [open, setOpen] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const errorMessageDisease = useSelector(state => state.medicalDataReducer?.msgError);
    const defaultModalDisease = {
        defaultModalTitle: 'Enviado correctamente',
        defaultModalDescription: 'Los cambios han sido realizados.',
        defaultModalIcon: <CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />,
        defaultModalErrorClass: ''
    };
    const setMessageModalDisease = {
        setModalTitle: 'Ocurrió un error',
        setModalDescription: 'Ocurrió un error al enviar los datos.',
        setModalIcon: <CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />,
        setModalErrorClass: classes.backgroundError
    };
    const { modalTitle, modalDescription, modalIcon, modalErrorClass } = useMessageModal(defaultModalDisease, setMessageModalDisease, errorMessageDisease);
    const [auxDisease, setAuxDisease] = useState({
        enfermedadDescripcion: ''
    });
    const [auxSpecify, setAuxSpecify] = useState({
        enfermedadDescripcion: ''
    });
    const [mode, setMode] = useState(false);
    const [removeDisease, setRemoveDisease] = useState('');
    const [listEnfermedades, setEnfermedades] = useState([]);
    const chronicDiseaseParams = useSelector(state => state.medicalDataReducer?.chronicDiseasesParams);
    const abmChronicDiseasesSelector = useSelector(state => state.medicalDataReducer.abmChronicDiseases);

    useEffect(() => {
        if (abmChronicDiseasesSelector?.confirmacion) {
            setOpenConfirmModal(true);
            setOpen(false);
        }
    }, [abmChronicDiseasesSelector])

    const handleDeleteDisease = (diseaseItem) => (e) => {
        e.preventDefault();
        setRemoveDisease(diseaseItem);
        setMode(false);
        setOpen(prevState => !prevState);
    };

    const handleChangeSpecify = () => (e) => {
        const { value } = e.target;
        const newEnf = { enfermedadDescripcion: value };
        setAuxSpecify(newEnf);
    };

    const handleChangeSelect = () => (e) => {
        const { value } = e.target;
        const newEnf = { enfermedadDescripcion: value };
        setAuxDisease(newEnf);
    };

    const handleClickModal = () => {
        if (validateForm(auxDisease, auxSpecify)) {
            setMode(true);
            addDisease();
            setOpen(prevState => !prevState);
        }
    };

    useEffect(() => {
        let listParameters = [];
        chronicDiseaseParams?.enfermedades?.map((item, i) => (
            listParameters.push({ value: item.enfermedadDescripcion, label: item.enfermedadDescripcion })
        ));
        setEnfermedades(listParameters);
    }, [chronicDiseaseParams?.enfermedades]);

    function addDisease() {
        if (auxDisease.enfermedadDescripcion === 'OTRO') {
            disease.push(auxSpecify);
        }
        else {
            disease.push(auxDisease);
        }
    }

    function validateForm(dis, spec) {
        const tempDisease = dis;
        const tempSpecify = spec;
        let aux = [];
        let validation = [];
        let Obj = {};
        if (tempDisease?.enfermedadDescripcion === 'OTRO') {
            Obj.enfermedadDescripcion = tempSpecify?.enfermedadDescripcion ? '' : '*Campo obligatorio';
        }
        else {
            Obj.enfermedadDescripcion = tempDisease?.enfermedadDescripcion ? '' : '*Campo obligatorio';
        }
        aux = Obj;
        if (Object.values(aux).every((x) => x === "")) {
            validation.push(true);
        } else {
            validation.push(false);
        }
        setError(aux);
        return Object.values(validation).every((valid) => valid === true);
    }

    function addABMModel() {
        let res = auxDisease?.enfermedadDescripcion;
        if (auxSpecify != '' && auxSpecify?.enfermedadDescripcion != '' && auxSpecify?.enfermedadDescripcion !== undefined) {
            res = auxSpecify?.enfermedadDescripcion;
        }
        return res;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (mode) {
            if (validateForm(auxDisease, auxSpecify)) {
                const enfermedad = addABMModel();
                dispatch(abmChronicDiseases({
                    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                    enfermedadDescripcion: enfermedad,
                    modo: "A"
                }));
            }
            setDisabled(prevState => !prevState);
            cleanInput();
        }

        else {
            const enfermedadDesc = removeDisease?.enfermedadDescripcion;
            dispatch(abmChronicDiseases({
                numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                enfermedadDescripcion: enfermedadDesc,
                modo: "B"
            }));
        }
        setOpen(prevState => !prevState);
    };

    function cleanInput() {
        const diseaseInput = [{
            enfermedadDescripcion: '',
        }];
        setAuxDisease(diseaseInput);
        setAuxSpecify(diseaseInput);
    }

    const handleAddItem = () => {
        if (validateForm(auxDisease, auxSpecify)) {
            addDisease();
            const enfermedad = addABMModel();
            dispatch(abmChronicDiseases({
                numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                enfermedadDescripcion: enfermedad,
                modo: "A"
            }));
            setOpenConfirmModal(prevState => !prevState);
            setDisabled(prevState => !prevState);
            cleanInput();
        }
        setTimeout(() => {
            handleNew()
        }, 1000);
    };

    const errorOther = () => {
        let res = '';
        if (!auxDisease?.enfermedadDescripcion === 'OTRO') {
            res = error?.enfermedadDescripcion;
        }
        return res;
    };

    function handleNew() {
        setDisabled(prevState => !prevState);
    }

    function showChronicDiseases() {
        let componentToShow;
        if(disabled) {
            componentToShow = disease?.map((item, i) => {
                return (
                    <Fragment key={i}>
                        <div className={classes.formChronicDisease}>
                            <FormControl className={`${classes.formControlAddress}`} variant="standard">
                                <CustomizedInputs
                                    name="enfermedadDescripcion"
                                    value={item?.enfermedadDescripcion}
                                    disabled={disabled}
                                    label='ENFERMEDAD'/>
                            </FormControl>
                            <div style={{ display: "flex", alignItems: "center", marginTop: '10px' }}>
                                <Button onClick={handleDeleteDisease(item)} >
                                    <DeleteOutlineIcon className={classes.ShowMoreArrowIcon} style={{ color: '#002858' }} />
                                </Button>
                            </div>
                        </div>
                    </Fragment>
                );
            }
            )
        } else {
            componentToShow = <>
                <FormControl className={classes.formControlChronicMedicationForm} variant="standard">
                    <CustomSelect
                        name='enfermedadDescripcion'
                        selected={auxDisease?.enfermedadDescripcion}
                        disabled={disabled}
                        list={listEnfermedades}
                        onChange={handleChangeSelect()}
                        label={"ENFERMEDAD"} />
                    < FormHelperText className={classes.errorText}>{errorOther()}</FormHelperText>
                </FormControl>
                {auxDisease?.enfermedadDescripcion === 'OTRO' &&
                    <FormControl className={classes.formControlAddress} variant="standard">
                        <CustomizedInputs
                            name="enfermedadDescripcion"
                            onChange={handleChangeSpecify()}
                            disabled={disabled}
                            label='ESPECIFICACION' />
                        < FormHelperText className={classes.errorText}>{error?.enfermedadDescripcion}</FormHelperText>
                    </FormControl>
                }
            </>
        }
        return componentToShow;
    }

    return (
        chronicDiseaseParams?.enfermedades !== undefined ?
            <>
                <form onSubmit={handleSubmit} className={classes.chronicDiseaseform}>
                    { showChronicDiseases() }
                    {!disabled &&
                        <div style={{ justifyContent: 'flex-start', width: '100%' }}>
                            <Button onClick={handleAddItem} >
                                <ControlPointIcon className={`${classes.ShowMoreArrowIcon}`} />
                            </Button>
                        </div>}

                    <Divider className={classes.formDivider} />
                    <div className={`${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                        {!disabled &&
                            <div style={{marginBottom: "50px"}}>
                                <CustomButton
                                    customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                                    title='cancelar'
                                    onClick={handleNew}
                                />
                                <CustomButton
                                    customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                                    title='Guardar'
                                    onClick={handleClickModal}
                                />
                            </div>
                        }
                    </div>
                    <SaveModal isOpen={open} setOpen={setOpen} type='submit' onClick={handleSubmit}
                        titleModal={
                            <>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    Confirmar envío de datos
                                </div>
                            </>
                        }
                        descriptionModal="¿Deseas confirmar los cambios?"
                    />
                </form>
                <ConfirmModal isOpen={openConfirmModal} setOpen={setOpenConfirmModal} errorClass={modalErrorClass}
                    titleModal={
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {modalIcon}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {modalTitle}
                            </div>
                        </>
                    }
                    description={modalDescription} />
            </> :
            (
                <div>
                    <NotFound
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        customMsg={
                            <div className={classes.contentNotFound}>
                                <span className={classes.notAppointment}>
                                    No se encontraron resultados
                                </span>
                            </div>
                        }
                        wsItem={chronicDiseaseParams?.enfermedades}
                        fullItem={chronicDiseaseParams?.enfermedades}
                    />
                </div>
            )
    );
};
export default React.memo(ChronicDiseasesForm, (prevState, nextState) => {
    return prevState.disease === nextState.disease && prevState.disabled === nextState.disabled;
});

