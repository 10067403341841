import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

/**agregar el llamado a las funciones que se comunican con la api */
function* asyncGetTypeRefundCoverageParameters({ payload }) {
    try {
        var response = yield call(API.getTypeRefundCoverage, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_TYPE_COVERAGE_PARAMETERS, response, message: ''});
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_GET_TYPE_COVERAGE_PARAMETERS,
            message: error.message,
        });
    }
}

function* asyncGetAreaRefundCoverageParameters({ payload }) {
    try {
        let response = yield call(API.getAreaRefundCoverage, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_AREA_PARAMETERS, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_AREA_PARAMETERS, message: error.message });
    }
}

function* asyncPostRefundCoverage({ payload }) {
    try {
        let response = yield call(API.postRefundCoverageResult, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_POST_REFUND_COVERAGE, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_POST_REFUND_COVERAGE, message: error.message });
    }
}

function* asyncGetAllCoveragePlans({ payload }) {
    try {
        let response = '';
        if (payload !== '') {
            response = yield call(API.getAllCoveragePlansWithSecurity, payload);
        } else {
            response = yield call(API.getAllCoveragePlans);
        }
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_ALL_COVERAGE_PLANS, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_ALL_COVERAGE_PLANS, message: error.message });
    }
}

// Watchers
export default function* coverageSaga() {
    yield takeLatest(actionTypes.GET_TYPE_COVERAGE_PARAMETERS, asyncGetTypeRefundCoverageParameters);
    yield takeLatest(actionTypes.GET_AREA_PARAMETERS, asyncGetAreaRefundCoverageParameters);
    yield takeLatest(actionTypes.POST_REFUND_COVERAGE, asyncPostRefundCoverage);
    yield takeLatest(actionTypes.GET_ALL_COVERAGE_PLANS, asyncGetAllCoveragePlans);
}
