import * as actionTypes from "../consts/actionTypes";

export function getProgramRequirementAndFormByProgramAction(program) {
    return {
        type: actionTypes.GET_PROGRAMREQUIREMENTANDFORM_BY_PROGRAM,
        payload: program
    };
}

export function getProgramsAction(program) {
    return {
        type: actionTypes.GET_MEDICAL_PROGRAMS,
        payload: program
    };
}