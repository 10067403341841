import React, { useEffect, useState } from "react";
import useStyles from "../../assets/styles/styles";
import Menu from "../../components/Menu/Menu";
import { Zoom, ClickAwayListener, Divider, FormControl, IconButton, InputLabel, TextField, Typography } from "@material-ui/core";
import {
    getDataFilterAppointment,
    getAfilliatedAppointmentsRecord,
    getAfilliatedAppointments,
} from "../../redux/actions/appointmentsActions";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../controls/Select/CustomSelect";
import NotFound from "../../components/HomeLogged/NotFound";
import CustomButton from "../../controls/Button/CustomButton";
import MovementsCard from "../../components/Billing/Movements/MovementsCard";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { returnHomeAppointmentHome, setModelAppointmentToRepeatOrModify } from "../../utils/utilsFunctions";
import TuneIcon from '@material-ui/icons/Tune';
import { useHistory } from "react-router";
import { LocalizationProvider, MobileDatePicker } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { format } from "date-fns";
import ModalActivities from "../../components/DialogActivities/ModalActivities";
import HeaderCard from "../../components/Billing/Movements/HeaderCard";
import useTrackPageView from "../../utils/useTrackPageView";
import { es } from "date-fns/locale";

const userCode = parseInt(window.localStorage.getItem("numeroPersona"));

const persona = {
    numeroPersona: userCode,
    parametroLlamado: "FILTRO HISTORICO TURNO"
};

const paramAll = {
    numeroPersona: userCode,
    solicitudTurnos: "TODOS",
    count: 1000,
    skip: 0,
};

const AppointmentRecord = () => {
    const trackPageView = useTrackPageView("Historial de turnos");
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const atThisMoment = new Date();
    const [dateStart, setDateStart] = useState(atThisMoment);
    const [dateEnd, setDateEnd] = useState(new Date());
    const [openPicker, setOpenPicker] = useState(false);
    const [openModalRepeatAppointment, setOpenModalRepeatAppointment] = useState(false);
    const appointments = useSelector((state) => state.appointmentsReducer);
    const [record, setRecorder] = useState([]);
    const [groupFamily, setGroupFamily] = useState([]);
    const [selectAffilliate, setSelectAffilliate] = useState({
        value: 0,
        label: ""
    });
    const [modelAppointment, setModelAppointment] = useState();

    useEffect(() => {
        trackPageView();
        setDateStart(atThisMoment.setMonth(atThisMoment.getMonth() - 6));
        dispatch(getAfilliatedAppointmentsRecord({
            numeroPersona: userCode,
            solicitudTurnos: "HISTORICOS",
            count: 9999,
            skip: 0,
            fechaDesde: format(dateStart, "yyyy-MM-dd"),
            fechaHasta: format(dateEnd, "yyyy-MM-dd")
        }));
        dispatch(getDataFilterAppointment(persona));
        dispatch(getAfilliatedAppointments(paramAll));
    }, []);

    useEffect(() => {
        let listGroupFamily = appointments?.filtro?.map((val) => {
            return {
                value: parseInt(val?.numeroPersona), label: val?.apellidoNombre
            };
        });
        if (listGroupFamily?.length > 1) {
            listGroupFamily?.push({
                value: 0, label: "TODOS"
            });
        }
        setGroupFamily(listGroupFamily);
    }, [appointments.filtro]);

    useEffect(() => {
        groupFamily?.forEach((item) => {
            if (item.value == userCode) {
                setSelectAffilliate({ value: item.value, label: item.label });
            }
        });
    }, [groupFamily]);

    function sortFunction(a, b) {
        let dateA = new Date(a.turnoFechHora).getTime();
        let dateB = new Date(b.turnoFechHora).getTime();

        return dateA < dateB ? 1 : -1;
    }

    const filterAppointmentsByDate = list => {
        let start = format(dateStart, "yyyy-MM-dd");
        let end = format(dateEnd, "yyyy-MM-dd");
        let filterList = list?.filter(x => x.turnoFechHora?.split('T')[0] >= start && x.turnoFechHora?.split('T')[0] <= end)

        return filterList?.sort(sortFunction);
    }

    useEffect(() => {
        let list = [];

        if (selectAffilliate?.value == 0 || !selectAffilliate?.value == undefined) {
            list = appointments?.record?.map(element => {
                return element;
            });
        }
        else {
            list = appointments?.record?.filter((x) => selectAffilliate?.value == x.pacienteNumero).map((element) => {
                return element;
            });
        }

        setRecorder(filterAppointmentsByDate(list));
    }, [selectAffilliate, appointments?.record]);

    useEffect(() => {
        let list = [];
        if(selectAffilliate?.label === "TODOS") {
            list = appointments?.record?.map( element => {
                return element;
            })
        } else {
            list = appointments?.record?.filter( x => selectAffilliate?.value == x.pacienteNumero)?.map( element => {
                return element;
            });
        }
        
        setRecorder(filterAppointmentsByDate(list));
    }, [dateStart, dateEnd, selectAffilliate?.value]);

    useEffect(() => {
        dispatch(getAfilliatedAppointmentsRecord({
            numeroPersona: userCode,
            solicitudTurnos: "HISTORICO",
            count: 999,
            skip: 0,
            fechaDesde: format(dateStart, "yyyy-MM-dd"),
            fechaHasta: format(dateEnd, "yyyy-MM-dd")
        }));
    }, [selectAffilliate?.value]);

    const handleChangeWEB = () => e => {
        var nameAffilliated = groupFamily.filter((x) => x.value == e.target.value);
        setSelectAffilliate({ value: nameAffilliated[0].value, label: nameAffilliated[0].label });
    };

    const handleClickAway = () => {
        setOpenPicker(false);
    };

    const handleClick = () => {
        setOpenPicker((prev) => !prev);
    };

    const handleClickOpenRepeatModal = (item) => (e) => {
        e.preventDefault();
        setOpenModalRepeatAppointment(prevState => !prevState);
        setModelAppointment(item);
    };

    const handleClickModifyRepeatAppointment = () => {
        setModelAppointmentToRepeatOrModify(modelAppointment, dispatch, userCode, appointments, history);
    };

    return (
        <>
            <Menu />
            <div className={`${classes.divMyHistoryTurn}`}>
                <div className={`${classes.divAffilliate} ${classes.divAffilliateMyHistory}`}>
                    <Typography className={classes.textTitleTurn}>Afiliado: </Typography>
                    <CustomSelect
                        className={`${classes.afilliateSelect}`}
                        list={groupFamily}
                        selected={selectAffilliate?.value}
                        customClassList={`${classes.deploymentSelect} ${classes.deploymentSelectMyHistory}`}
                        onChange={handleChangeWEB()}
                    />
                </div>
                <>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <IconButton onClick={returnHomeAppointmentHome(history)}>
                                <ArrowBackIcon className={classes.iconFilter} />
                            </IconButton>
                            <Typography className={`${classes.textTitleTurn}`} style={{ textTransform: 'uppercase' }}>
                                Historial de turnos
                            </Typography>
                        </div>
                        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                            <div style={{ position: "relative", marginRight: "12%" }}>
                                <CustomButton
                                    onClick={handleClick}
                                    style={{ fontSize: '13px'}}
                                    customClass={`${classes.customHeaderBtn} ${classes.btnFilterAppointment}`}
                                    icon={<TuneIcon style={{ marginRight: "5px" }} className={classes.iconFilter}/>}
                                />
                                {openPicker && (
                                    <Zoom in={openPicker} >
                                        <div className={`${classes.dropDownMyHistory} ${classes.dropdown}`} style={{ zIndex: 1  }}>
                                            <Typography className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>{"PERIODO"}</Typography>
                                            <Divider classes={{ root: classes.dividerFilter }} />

                                            <div className={`${classes.headerFiltro} ${classes.marginTop}`}>
                                                <FormControl style={{ width: "150px", paddingTop: "10px" }} className={classes.datePicker}>
                                                    <InputLabel className={`${classes.txtSubtitleCalendar} ${classes.fontSize15}`} shrink  >{"DESDE"}</InputLabel>
                                                    <div className={classes.top22} style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <LocalizationProvider style={{ width: "100px", zIndex: 2 }} locale={es} dateAdapter={AdapterDateFns}  >
                                                            <MobileDatePicker
                                                                cancelText="Cancelar"
                                                                toolbarTitle=""
                                                                value={dateStart}
                                                                onChange={(newValue) => { setDateStart(newValue); }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                format="dd/MM/YYYY"
                                                            />
                                                        </LocalizationProvider>
                                                        <CalendarTodayIcon style={{ alignSelf: "center" }} />
                                                    </div>
                                                </FormControl>

                                                <FormControl style={{ width: "150px", marginLeft: "10px", paddingTop: "10px" }} className={classes.datePicker} >
                                                    <InputLabel className={`${classes.txtSubtitleCalendar} ${classes.fontSize15}`} shrink  >{"HASTA"}</InputLabel>
                                                    <div className={classes.top22} style={{ display: "flex", justifyContent: "space-between", }}>
                                                        <LocalizationProvider style={{ zIndex: 2  }} locale={es} dateAdapter={AdapterDateFns}  >
                                                            <MobileDatePicker
                                                                cancelText="Cancelar"
                                                                toolbarTitle=""
                                                                value={dateEnd}
                                                                onChange={(newValue) => { setDateEnd(newValue); }}
                                                                variant='outlined'
                                                                renderInput={(params) => <TextField {...params} />}
                                                                format="dd/MM/YYYY"
                                                            />
                                                        </LocalizationProvider>
                                                        <CalendarTodayIcon style={{ alignSelf: "center" }} />
                                                    </div>
                                                </FormControl>

                                            </div>
                                        </div>
                                    </Zoom>
                                )}
                            </div>
                        </ClickAwayListener>
                    </div>
                </>

                <Divider className={`${classes.formDivider} ${classes.dividerAppointment}`} style={{margin: "10px 0"}} />
                <div className={`${classes.listCardMyHistory}`} style={{display: "flex", justifyContent: "center"}}>
                    {record?.length > 0 ?
                        record?.map((item, i) => (
                            < MovementsCard
                                key={i}
                                customHeaderColor={classes.darkLinearGradientImportant}
                                index={i}
                                headerIcon={<HeaderCard day={item?.fechaFormateada?.nombreDia} numberDay={item?.fechaFormateada?.dia} month={parseInt(item?.turnoFechHora?.substring(5, 7))} year={parseInt(item?.turnoFechHora?.substring(0, 4))} />}
                                description={
                                    <>
                                        <Typography style={{ fontWeight: "600", color: '#0075c9' }} className={`${classes.txtCardDescription} `}>
                                            <span style={{ color: 'black' }}>{"Hora: " + item?.turnoFechHora?.split('T')[1].substring(0, 5) + " hs"}</span>
                                        </Typography>
                                        <Typography style={{ fontWeight: "600", color: '#0075c9', paddingBottom: "10px" }} className={`${classes.txtCardDescription} `} >
                                            <span style={{ color: 'black' }}>{"CENTRO MEDICO " + item?.sucursalDescripcion}</span>
                                        </Typography>
                                        <Typography className={`${classes.txtCardDescription}`}>
                                            {item?.pacienteNombreApellido}
                                        </Typography>
                                        <Typography className={`${classes.txtCardDescription}`}>
                                            {item?.especialidadDescripcion}
                                        </Typography>
                                        <Typography className={`${classes.txtCardDescription}`}>
                                            {item?.medicoDescripcion}
                                        </Typography>
                                    </>
                                }
                                buttonZone={
                                    <section style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flex: 1 }}>
                                        <div>
                                            <CustomButton
                                                title="REPETIR TURNO"
                                                customClass={classes.darkLinearGradient}
                                                style={{ whiteSpace: "nowrap", marginTop: "10px", width: "100%", fontSize: "0.5rem", fontWeight: '600' }}
                                                onClick={handleClickOpenRepeatModal(item)}
                                            />
                                        </div>
                                    </section>
                                }
                            />
                        ))
                        :
                        <NotFound customMsg={
                            <div className={classes.contentNotFound}>
                                <span>No tenés historial de turnos</span>
                            </div>}
                            wsItem={record}
                            fullItem={record} />
                    }
                </div>
                {
                    openModalRepeatAppointment &&
                    <ModalActivities
                        title={'Repetir Turno'}
                        description={'¿Estás seguro que deseas repetir el turno seleccionado?'}
                        open={openModalRepeatAppointment}
                        onClose={setOpenModalRepeatAppointment}
                        modalconfirm={true}
                        modalLogout={false}
                        buttonType={'submit'}
                        handleClick={handleClickModifyRepeatAppointment}
                    />
                }
            </div>
        </>
    );
};

export default AppointmentRecord;