import { abmMDPhones } from "../../../../redux/actions/medicalDataAction";

export const handleClickModal = (phone, addItemScreen, addInput, setError, setFlag, setOpen) => (e) => {
    e.preventDefault();
    if (validates(phone, addItemScreen, addInput, setError, setFlag)) {
        setOpen(prevState => !prevState);
    }
};

export function validate(phones, setError) {
    const campoObligatorio = '*Campo obligatorio';
    if(phones !== undefined){
        const temp = phones;
        const aux = [];
        const validation = [];
        Object.values(temp).forEach((item, i) => {
            const phoneObj = {
                telefonoPaisCodigo: item.telefonoPaisCodigo ? '' : campoObligatorio,
                telefonoAreaCodigo: item.telefonoAreaCodigo ? '' : campoObligatorio,
                telefonoNumero: item.telefonoNumero ? '' : campoObligatorio,
                telefonoObservacion: item.telefonoObservacion ? '' : campoObligatorio
            };
            aux.push(phoneObj);
            if (Object.values(aux[i]).every((x) => x === "")) {
                validation.push(true);
            } else {
                validation.push(false);
            }
        });
    if (phones === '') {
        const phoneObj = {
            telefonoPaisCodigo: campoObligatorio,
            telefonoAreaCodigo: campoObligatorio,
            telefonoNumero: campoObligatorio,
            telefonoObservacion: campoObligatorio
        };
        aux.push(phoneObj);
        validation.push(false);
    }
    setError(aux);
    return Object.values(validation).every((valid) => valid === true);
    }
    return false;
}

export function validates(phones, isAdd, phoneItem, setErrorFunction, setFlagFunction) {
    let validateItem = validate(phones, setErrorFunction);
    if (isAdd) {
        validateItem = validate(phoneItem, setErrorFunction);
        setFlagFunction(true);
    }
    return validateItem;
}

export const handleRemoveItem = (phoneItem, phone, setPhone, dispatch, tipo) => (e) => {
    e.preventDefault();
    const phonesWOItem = phone?.filter(x => x !== phoneItem);
    setPhone(phonesWOItem);
    const aux = {
        numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
        telefonoTipo: tipo,
        modo: "B",
        telefonoPaisCodigo: phoneItem?.telefonoPaisCodigo,
        telefonoAreaCodigo: phoneItem?.telefonoAreaCodigo,
        telefonoNumero: parseInt(phoneItem?.telefonoNumero),
        telefonoInterno: 0,
        telefonoObservacion: phoneItem?.telefonoObservacion,
        telefonoCodigo: phoneItem?.telefonoCodigo
    };
    dispatch(abmMDPhones(aux));
    setTimeout(() => { window.location.reload() }, 500);
};

export const handleAddItem = (props) => (e) => {
    e.preventDefault();
    const { phone, addInput, addItemScreen, setError, setFlag, setOpenConfirm, setAddInput, setPhone } = props;
    const aux = phone;
    const phones = [...aux, ...addInput];
    if (validates(phone, addItemScreen, addInput, setError, setFlag)) {
        setPhone(phones);
        setOpenConfirm(prevState => !prevState);
        setAddInput([{
            telefonoPaisCodigo: 0,
            telefonoAreaCodigo: 0,
            telefonoNumero: 0,
            telefonoObservacion: ''
        }]);
    }
};

export const handleChangeInput = (props) => (e) => {
    const { name, value } = e.target;
    const { position, i, phone, setPhone, addInput, setAddInput } = props;
    const index = position ?? i;
    changeInput(index, phone, name, value, setPhone, addInput, setAddInput);
};

export function changeInput(position, phone, name, value, setPhone, addInput, setAddInput) {
    if (position !== -1) {
        const telefonoNumero = phone?.map((item, i) => {
            if (position === i) {
                return { ...item, [name.split('-')[0]]: value };
            } else {
                return item;
            }
        });
        setPhone(telefonoNumero);
    } else {
        const addPhone = addInput.map((itemsOfPhone) => {
            return { ...itemsOfPhone, [name.split('-')[0]]: value };
        });
        setAddInput(addPhone);
    }
}

export function submit(props) {
    const { phone, setAddItemScreen, addInput, flag, setPhone, setAddInput, phoneType, dispatch } = props;
    const aux = {
        numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
        telefonoTipo: '',
        modo: "A",
        telefonoPaisCodigo: 0,
        telefonoAreaCodigo: 0,
        telefonoNumero: 0,
        telefonoInterno: 0,
        telefonoObservacion: '',
        telefonoCodigo: 0
    };
    if (flag && phone !== undefined) {
        setPhone([...phone, ...addInput]);
        aux.telefonoTipo = phoneType;
        aux.modo = "A";
        aux.telefonoPaisCodigo = parseInt(addInput[0]?.telefonoPaisCodigo);
        aux.telefonoAreaCodigo = parseInt(addInput[0]?.telefonoAreaCodigo);
        aux.telefonoNumero = parseInt(addInput[0]?.telefonoNumero);
        aux.telefonoInterno = 0;
        aux.telefonoObservacion = addInput[0]?.telefonoObservacion;
        aux.telefonoCodigo = 0;
        dispatch(abmMDPhones(aux));
        setAddInput([{
            telefonoPaisCodigo: 0,
            telefonoAreaCodigo: 0,
            telefonoNumero: 0,
            telefonoObservacion: ''
        }]);
        setAddItemScreen(prevState => !prevState);
    } else {
        Object.values(phone)?.forEach(item => modifyPhones(item, dispatch, aux, phoneType));
    }
}

function modifyPhones(phone, dispatch, model, type) {
    model.telefonoTipo = type;
    model.modo = "M";
    model.telefonoPaisCodigo = phone?.telefonoPaisCodigo;
    model.telefonoAreaCodigo = phone?.telefonoAreaCodigo;
    model.telefonoNumero = parseInt(phone?.telefonoNumero);
    model.telefonoInterno = 0;
    model.telefonoObservacion = phone?.telefonoObservacion;
    model.telefonoCodigo = phone?.telefonoCodigo;
    dispatch(abmMDPhones(model));
}

export const WS_PARAMETERS_LENGTH = {
    CodigoPais: 3,
    CodigoArea: 5,
    TelefonoNumero : 10,
    TelefonoInterno: 5
};