import React from 'react'
import useStyles from "../../assets/styles/styles";
import logo from "../../assets/images/whiteLogo.png";
import { Typography } from "@material-ui/core";

const CredentialHeader = ({affilliated}) => {

    const classes = useStyles();

  return (
    <div className= {classes.headerCredImage}>
        <div className={classes.headerCredContainerHeader}>
            <div>
              <img
                className={classes.headerCredLogo}
                alt="logo"
                src={logo}
              />
            </div>
            <div className={classes.credentialPlan}>
                <Typography className={`${classes.headerCredPlan} ${classes.credBold}`}>
                    {affilliated?.credentials?.planDescripcion}
                </Typography>
            </div>
          </div>
    </div>
  )
}

export default CredentialHeader
