import * as actionTypes from "../consts/actionTypes";

const initialValues = {
    stepCounter: 0,
    actualStep: -1,
};

export default function stepAppointmentReducer(state = initialValues, action) {
    switch (action.type) {
        case actionTypes.SET_ACTUAL_STEP:
            return { ...state, actualStep: action.payload };
        case actionTypes.GET_STEPS:
            return { ...state };
        case actionTypes.NEXT_STEP:
        case actionTypes.PREV_STEP:
            return { ...state, stepCounter: action.payload };
        default:
            return { ...state };
    }
}