import React, { useEffect, useState, Fragment, useRef } from "react";
import useStyles from "../../assets/styles/styles";
import {
  IconButton,
  Switch,
  TextField,
  Typography,
  Divider,
  InputAdornment
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import "moment/locale/es";
import FooterDrawer from "./FooterDrawer";
import ModalConfirm from "./Modals/ModalConfirm";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { ABAppointmentFavorite } from "../../redux/actions/appointmentsActions";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import TopButtons from "./Web/Buttons/TopButtons";
import WizardLine from "./WizardLine";
import { abmFullData, getFullData } from '../../redux/actions/personalDataActions';
import { WS_PARAMETERS_LENGTH } from '../MedicalData/Phones/Utils/phonesUtilsFunctions';
import PhonesInput from "../../controls/InputCustom/PhonesInput";
import { FormHelperText } from "@mui/material";
import EditIcon from '@material-ui/icons/Edit';
import CustomButton from "../../controls/Button/CustomButton";
import { getConfigurations } from "../../redux/actions/configurationAction";

const list = [
  {
    step0: "",
  },
  {
    step1: "",
  },
  {
    step2: "",
  },
  {
    step3: "",
  },
];

export default function ConfirmAppointmentDrawer(props) {
  const {
    activeStep,
    setActiveStep,
    handleOpenCancelModal,
    handleCloseDrawer,
  } = props;
  const affiliated = {
    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona"))
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const { screen } = useResizeScreen();
  const modelAppointmentReducer = useSelector(
    (state) => state.appointmentsReducer?.modelAppointment
  );
  const fullgroupfamily = useSelector(
    (state) => state.appointmentsReducer?.fullGroupFamily?.afiliados
  );
  const [modelAppointment, setModelAppointment] = useState(
    modelAppointmentReducer
  );
  const [model, setModel] = useState({
    numeroPersona: 0,
    numeroTurno: 0,
    medicoCodigo: 0,
    origen: 0,
    ip: 0,
    enviarMail: "N",
    mail: "",
    enviarNotificacionApp: "N",
  });
  const [disabled, setDisabled] = useState({
    email: false,
    whatsapp: true,
    appmobile: true,
  });
  const [emailS, setEmail] = useState("");
  const [switchState, setSwitchState] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [favoriteModel, setFavoriteModel] = useState({
    numeroPersona: 0,
    medicoCodigo: 0,
    tipo: "",
  });
  const [favorite, setFavorite] = useState(false);
  const [error, setError] = useState(false);
  const fullPersonalData = useSelector(state => state.personalDataReducer);
  const configurations = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);
  const [phones, setPhones] = useState({});
  const [personalData, setPersonalData] = useState([]);
  const [modelABM, setModelABM] = useState(personalData);
  const [disablePhone, setDisablePhone] = useState(true);
  const [phoneError, setPhoneError] = useState([]);
  const editPhoneRef = useRef(null);
  const [disablePhoneCaption, setDisablePhoneCaption] = useState('');
  const [notDisablePhoneCaption, setNotDisablePhoneCaption] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);
  const [changePhoneMode, setChangePhoneMode] = useState(false);

  const bold400 = "400";
  const weightBold = "bold";
  const sixteenPx = "16px";
  const fourteenPx = "14px";
  const spaceBetween = "space-between";
  const fivePx = "5px";
  const fiftyPercent = "50%";
  const disFlex = "flex";
  const textCapitalize = "capitalize";
  const leftMargin = screen.sWidth > 768 && '-10px';

  Moment.locale("es");

  useEffect(() => {
    const val = fullgroupfamily
      ?.filter(
        (x) =>
          x.numeroPersona === Number.parseInt(modelAppointment.afiliadoCodigo)
      )
      .find((x) => x.mail);
    setEmail(val?.mail);
    dispatch(getFullData(affiliated));
    dispatch(getConfigurations());
  }, []);

  useEffect(() => {
    if (Object.values(fullPersonalData?.fullData).length > 0) {
      setModelABM(fullPersonalData?.fullData?.afiliado);
      setPersonalData(fullPersonalData?.fullData?.afiliado);
      let auxPhones = fullPersonalData?.fullData?.afiliado?.telefonos;
      let mobilePhoneNumber = auxPhones?.filter(mobilePhone => mobilePhone?.telefonoTipoDescripcion == 'Movil')?.[0];

      let genericPhone = {
        interno: 0,
        paisCodigo: 54,
        areaCodigo: '',
        numero: '',
        telefono: '',
        telefonoModo: 'A',
        telefonoTipoCodigo: 3,
        telefonoTipoDescripcion: 'Movil'
      };

      auxPhones?.forEach(phone => {
        phone.telefonoModo = "M";
      });

      if (mobilePhoneNumber == undefined) {
        setChangePhoneMode(true);
        setPhones(genericPhone)
      } else {
        setPhones(mobilePhoneNumber);
      }
    }
  }, [fullPersonalData?.fullData?.afiliado]);

  const setPhoneCaptions = () => {
    const phoneCaption = configurations?.filter(configuration => configuration?.id == 'leyendaTelefonosConfirmacionDeTurnos');
    const phoneEditCaption = configurations?.filter(configuration => configuration?.id == 'leyendaInputTelefonoTurnos');

    phoneCaption?.[0]?.valor && setDisablePhoneCaption(phoneCaption?.[0]?.valor);
    phoneEditCaption?.[0]?.valor && setNotDisablePhoneCaption(phoneEditCaption?.[0]?.valor);
  }

  useEffect(() => {
    setPhoneCaptions();
  }, [configurations])

  const handleNext = () => {
    setModelFunction(setModel, modelAppointment, model, emailS);
    if (!error) {
      setOpenModal(prevState => !prevState);
      if (favoriteModel?.numeroPersona !== 0) {
        dispatch(ABAppointmentFavorite(favoriteModel));
      }
    }
  };

  const handleBack = () => {
    if (screen.sWidth <= 768) {
      setActiveStep((prevActiveStep) => prevActiveStep - 4);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleSwitch = () => (e) => {
    const { name } = e.target;
    setSwitchState((prevState) => ({
      ...prevState,
      [name]: e.target.checked,
    }));
    if (name === "email") {
      if (e.target.checked) {
        setModelAppointment({
          ...modelAppointment,
          enviarMail: "S",
          email: emailS,
        });
      }
    }

    if (name === "appmobile") {
      if (e.target.checked) {
        setModelAppointment({
          ...modelAppointment,
          enviarNotificacionApp: "S",
        });
      } else {
        setModelAppointment({
          ...modelAppointment,
          enviarNotificacionApp: "N",
        });
      }
    }

    setDisabled((prevState) => ({
      ...prevState,
      [name]: !e.target.checked,
    }));
  };

  useEffect(() => {
    if (
      modelAppointment?.enviarMail !== undefined ||
      modelAppointment?.enviarNotificacionApp !== undefined
    ) {
      if (
        modelAppointment?.enviarMail === "S" ||
        modelAppointment?.enviarNotificacionApp === "S"
      ) {
        setError(false);
      }
    }
  }, [modelAppointment?.enviarMail, modelAppointment?.enviarNotificacionApp]);

  useEffect(() => {
    if (favorite) {
      setFavoriteModel({
        ...favoriteModel,
        numeroPersona: Number.parseInt(modelAppointment?.afiliadoCodigo),
        medicoCodigo: modelAppointment?.medicoCodigo,
        tipo: "ALTA",
      });
    } else {
      setFavoriteModel({
        ...favoriteModel,
        numeroPersona: 0,
        medicoCodigo: 0,
        tipo: "",
      });
    }
  }, [favorite]);

  useEffect(() => {
    if (phones?.numero == '') {
      setDisablePhone(false);
    }
  }, [phones])

  const enablePhone = () => {
    setDisablePhone((prevActiveStep) => !prevActiveStep)
  }

  useEffect(() => {
    if (!disablePhone) {
      if (editPhoneRef.current) {
        editPhoneRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [disablePhone])

  const showsInputs = (require, label, item) => {
    if (disablePhone && item?.numero !== '') {
      return (
        <>
          <PhonesInput
            key={phones}
            inputLabelClass={classes.phoneLabelOnConfirmation}
            require={require}
            value={`${item?.paisCodigo}  ${item?.areaCodigo}  ${item?.numero}`}
            disabled={disablePhone}
            label={label}
            endAdornment={
              <InputAdornment position="start" sx={{ marginLeft: '10px' }}>
                <IconButton onClick={enablePhone} size='small'>
                  <EditIcon sx={{ color: "#0075c9" }}/>
                </IconButton>
              </InputAdornment>
            }
          />
        </>
      );
    }
    else {
      return (inputEdit(item));
    }
  };

  const showEditPhoneButtons = () => {
    return (
      <>
        <CustomButton
          customClass={`${classes.updatePhoneBtn} ${classes.lightLinearGradient} `}
          title='GUARDAR'
          onClick={handleSubmit}
        />
        <CustomButton
          customClass={`${classes.updatePhoneBtn} ${classes.fontBold} `}
          title='CANCELAR'
          onClick={handleCancelEditPhone}
          style={{ color: '#0075c9', border: '2px solid #0075c9' }}
        />
      </>
    );
  }

  const inputEdit = (item) => {
    return (
      <>
        <div style={{ display: "flex", alignItems: 'center', justifyContent: screen.sWidth < 768 && 'center' }}>
          <div className={classes.inputContactDataBeforeCountryCode}>
            <PhonesInput
              name="paisCodigo"
              defaultValue={item?.paisCodigo}
              onChange={handleChangeInputPhones}
              disabled={disablePhone}
              onInput={(e) => { e.target.value = e.target.value?.replace(/[^0-9]/g, '')?.replace(/(\..*?)\..*/g, '$1'); }}
              inputProps={{ maxLength: WS_PARAMETERS_LENGTH.CodigoPais }}
              label="Cód. país"
              style={{ marginBottom: "10px" }}
            />
          </div>
          <div className={classes.inputContactDataBefore}>
            <PhonesInput
              name="areaCodigo"
              defaultValue={item?.areaCodigo}
              onChange={handleChangeInputPhones}
              disabled={disablePhone}
              onInput={(e) => { e.target.value = e.target.value?.replace(/[^0-9]/g, '')?.replace(/(\..*?)\..*/g, '$1'); }}
              inputProps={{ maxLength: WS_PARAMETERS_LENGTH.CodigoArea }}
              label="Cód. área"
              style={{ marginBottom: "10px", marginRight: "10px" }}
            />
          </div>
          <div className={classes.inputContactData}>
            <PhonesInput
              name="numero"
              defaultValue={item?.numero}
              onChange={handleChangeInputPhones}
              disabled={disablePhone}
              onInput={(e) => { e.target.value = e.target.value?.replace(/[^0-9]/g, '')?.replace(/(\..*?)\..*/g, '$1'); }}
              inputProps={{ maxLength: WS_PARAMETERS_LENGTH.TelefonoNumero }}
              label="Teléfono"
              style={{ marginBottom: "10px" }}
            />
          </div>
          {
            screen.sWidth > 768 &&
            <div style={{ display: 'flex' }}>
              {showEditPhoneButtons()}
            </div>
          } 
        </div>
        {phoneError?.map((x) => (
          <>
            <FormHelperText key={x} className={classes.errorText}>
              {x?.paisCodigo !== '' && `Código de país: ${x?.paisCodigo}`}
            </FormHelperText>
            <FormHelperText key={x} className={classes.errorText}>
              {x?.areaCodigo !== '' && `Código de área: ${x?.areaCodigo}`}
            </FormHelperText>
            <FormHelperText key={x} className={classes.errorText}>
              {x?.numero !== '' && `Número: ${x?.numero}`}
            </FormHelperText>
          </>
        ))}
        {
          screen.sWidth < 768 &&
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            {showEditPhoneButtons()}
          </div>
        }
      </>
    );
  };

  const handleCancelEditPhone = () => {
    let auxPhones = fullPersonalData?.fullData?.afiliado?.telefonos;
    let mobilePhoneNumber = auxPhones?.filter(mobilePhone => mobilePhone?.telefonoTipoCodigo == 3)?.[0];

    if(mobilePhoneNumber !== undefined) {
      dispatch(getFullData(affiliated));
      enablePhone();
      setPhoneError([]);
    } else {
      return;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate(phones)) {
      let form = modelABM;
      form.documentos = [];
      form.domicilios = [];
      form.deportes = [];
      form.hobbies = [];
      form.adjuntos = [];
      let auxiliarPhones = phones;
      auxiliarPhones.telefonoModo = (initialLoad && changePhoneMode) ? 'A' : 'M';
      auxiliarPhones.numero = parseInt(auxiliarPhones.numero);
      auxiliarPhones.paisCodigo = parseInt(auxiliarPhones.paisCodigo);

      auxiliarPhones.areaCodigo = parseInt(auxiliarPhones?.areaCodigo);

      delete auxiliarPhones.telefonoTipoDescripcion;
      delete auxiliarPhones.telefono;
      form.telefonos = [auxiliarPhones];
      delete form.cuentasReintegro;

      let modelToSend = {
        informacion: form,
        numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
      };

      dispatch(abmFullData(modelToSend));
      setInitialLoad(false);
      enablePhone();
    }
  };

  const handleChangeInputPhones = (e) => {
    e.preventDefault();

    let auxPhones = phones;
    const { name, value } = e.target;
    auxPhones[name] = value;
    
    auxPhones.telefono = `${auxPhones?.paisCodigo} ${auxPhones?.areaCodigo} ${auxPhones?.numero}`;

    setPhones(auxPhones);
  };

  const validate = (object) => {
    let aux = [];
    let validation = [];
    let phoneObject = {};
    const phoneWithoutFifteen = 'El teléfono no debe anteponer el número 15';
    const obligatoryField = '*Campo obligatorio';

    phoneObject.paisCodigo = object?.paisCodigo ? '' : obligatoryField;
    phoneObject.areaCodigo = object?.areaCodigo ? '' : obligatoryField;
    phoneObject.numero = object?.numero ? '' : obligatoryField;

    if (object?.areaCodigo?.toString()?.endsWith("15")) {
      phoneObject.areaCodigo = phoneWithoutFifteen;
    }

    if (object?.numero?.toString()?.startsWith("15")) {
      phoneObject.numero = phoneWithoutFifteen;
    }

    aux?.push(phoneObject);

    if (Object.values(phoneObject)?.every((x) => x === '')) {
      validation?.push(true);
    } else {
      validation?.push(false);
    }
    setPhoneError(aux);

    return Object.values(validation)?.every((valid) => valid === true);
  }

  return (
    <>
      {screen.sWidth > 768 && (
        <>
          <TopButtons
            handleOpenCancelModalTurn={handleOpenCancelModal}
            handleBackTurn={handleBack}
            activeStepTurn={activeStep}
            handleNextTurn={handleNext}
            titleCancel={"Cancelar"}
            titleConfirm={"Confirmar"}
            disabledNext={!disablePhone}
          />
          <WizardLine list={list} activeStep={activeStep} />
          <div style={{ paddingTop: "10px" }}>
            <Typography
              className={`${classes.titleTurn}`}
              style={{ fontWeight: "600" }}
            >
              Nuevo turno
            </Typography>
          </div>
          <Divider className={classes.formDivider} />
        </>
      )}

      <div
        className={`${classes.drawerContainerTurn}`}
        style={{ padding: screen.sWidth > 768 && "20px" }}
      >
        <div style={{ display: disFlex, justifyContent: spaceBetween }}>
          <Typography
            className={classes.textTitleTurn}
            style={{ marginTop: "0px" }}
          >
            {props.titles.map((titles) => titles.title9)}
          </Typography>
          <IconButton
            onClick={() => {
              setFavorite((prevState) => !prevState);
            }}
          >
            {favorite ? (
              <FavoriteIcon
                className={`${classes.FavoriteIcon} ${classes.iconPositionFavorite} `}
              />
            ) : (
              <FavoriteBorderIcon
                className={`${classes.iconPositionFavorite} `}
              />
            )}
          </IconButton>
        </div>

        <div
          style={{
            display: disFlex,
            paddingTop: screen.sWidth > 768 && "10px",
          }}
        >
          <Typography
            paragraph
            style={{ fontWeight: weightBold, fontSize: fourteenPx }}
          >
            PARA:
            <span style={{ marginLeft: fivePx, fontWeight: bold400 }}>
              {modelAppointment?.afiliadoDescripcion}
            </span>
          </Typography>
        </div>

        <Typography
          className={screen.sWidth > 768 && classes.txtTitleBlue}
          style={{ fontSize: sixteenPx }}
        >
          Datos del turno
        </Typography>
        {screen.sWidth > 768 && (
          <Divider className={` ${classes.dividerAppointment}`} />
        )}

        <div className={classes.divConfirmTurn}>
          <div
            style={{
              display: disFlex,
              width: screen.sWidth > 768 && fiftyPercent,
            }}
          >
            <Typography
              paragraph
              className={classes.darkgrayTxt}
              style={{ fontWeight: weightBold, fontSize: sixteenPx, marginLeft: leftMargin }}
            >
              Fecha y hora:
              <span
                style={{
                  textTransform: textCapitalize,
                  marginLeft: fivePx,
                  fontWeight: bold400,
                }}
              >
                {Moment(modelAppointment?.selectedAppointment).format("dddd DD")}
              </span>
                <span style={{ fontWeight: bold400 }}>{" de "}</span>
                <span style={{ textTransform: textCapitalize, fontWeight: 'normal' }}>
                  {Moment(modelAppointment?.selectedAppointment).format("MMMM")}
                </span>
                <span style={{ fontWeight: bold400 }}>{" a las "}</span>
                <span style={{ fontWeight: bold400 }}>{`${modelAppointment?.hourSelected?.substring(0, 5)} hs.`}</span>
            </Typography>
          </div>

          <div
            style={{
              display: disFlex,
              width: screen.sWidth > 768 && fiftyPercent,
            }}
          >
            <Typography
              paragraph
              className={classes.darkgrayTxt}
              style={{ fontWeight: weightBold, fontSize: sixteenPx }}
            >
              Centro Médico:
              <span style={{ marginLeft: fivePx, fontWeight: bold400 }}>
                {modelAppointment?.nombreCentroMedico === undefined
                  ? "-"
                  : modelAppointment?.nombreCentroMedico}
                {modelAppointment?.nombreCentroMedico === undefined ? "" : ", "}
                {modelAppointment?.direccion}
              </span>
            </Typography>
          </div>

          <div
            style={{
              display: disFlex,
              width: screen.sWidth > 768 && fiftyPercent,
            }}
          >
            <Typography
              paragraph
              className={classes.darkgrayTxt}
              style={{ fontWeight: weightBold, fontSize: sixteenPx, marginLeft: leftMargin }}
            >
              Médico:
              <span style={{ marginLeft: fivePx }}>
                {modelAppointment?.medicoDescripcion}
              </span>
            </Typography>
          </div>

          <div
            style={{
              display: disFlex,
              width: screen.sWidth > 768 && fiftyPercent,
            }}
          >
            <Typography
              paragraph
              className={classes.darkgrayTxt}
              style={{ fontWeight: weightBold, fontSize: sixteenPx }}
            >
              Especialidad:
              <span
                style={{
                  textTransform: textCapitalize,
                  marginLeft: fivePx,
                  fontWeight: bold400,
                }}
              >
                {modelAppointment?.especialidadDescripcion}
              </span>
            </Typography>
          </div>

          <div
            style={{
              display: disFlex,
              width: screen.sWidth > 768 && fiftyPercent,
            }}
          >
            <Typography
              paragraph
              className={classes.darkgrayTxt}
              style={{ fontWeight: weightBold, fontSize: sixteenPx, marginLeft: leftMargin }}
            >
              Modalidad de atención:
              <span
                style={{
                  textTransform: textCapitalize,
                  marginLeft: fivePx,
                  fontWeight: bold400,
                }}
              >
                {modelAppointment?.modalidadTurno}
              </span>
            </Typography>
          </div>

          <div
            style={{
              display: disFlex,
              width: screen.sWidth > 768 && fiftyPercent,
            }}
          >
            <Typography
              paragraph
              className={classes.darkgrayTxt}
              style={{ fontWeight: weightBold, fontSize: sixteenPx }}
            >
              Observaciones:
              <span
                style={{
                  marginLeft: fivePx,
                  fontWeight: bold400,
                }}
              >
                {modelAppointment?.observaciones === "" ||
                  modelAppointment?.observaciones === undefined
                  ? "No hay observaciones"
                  : modelAppointment?.observaciones}
              </span>
            </Typography>
          </div>
        </div>

        <Typography
          className={classes.darkgrayTxt}
          style={{
            fontSize: sixteenPx,
            fontWeight: "600",
            marginBottom: "12px",
            lineHeight: "1em",
          }}
        >
          ¿Por qué medio te gustaría recibir la confirmación del turno?
        </Typography>



        {fullgroupfamily
          ?.filter(
            (x) =>
              x.numeroPersona ===
              Number.parseInt(modelAppointment.afiliadoCodigo)
          )
          .map((item, index) => (
            <Fragment key={index}>
              <div className={classes.divConfirmTurn} style={{ marginLeft: leftMargin }}>
                <div style={{ width: screen.sWidth > 768 ? "40%" : "90%", margin: screen.sWidth < 768 && '0 auto' }}>
                  <div
                    style={{
                      display: disFlex,
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      alignContent: "stretch",
                      justifyContent: spaceBetween,
                    }}
                  >
                    <Typography
                      className={classes.subtitleTurn}
                      style={{
                        fontSize: fourteenPx,
                        lineHeight: "1em",
                        marginLeft: screen.sWidth < 768 && '17px'
                      }}
                    >
                      Email
                    </Typography>
                  </div>
                  <TextField
                    name={"email"}
                    disabled={disabled["email"]}
                    className={`${classes.textConfirmTurn}`}
                    onChange={(event) => {
                      setEmail(event.target.value);
                      setModelAppointment({
                        ...modelAppointment,
                        email: event.target.value,
                      });
                    }}
                    defaultValue={item.mail}
                    style={{ marginTop: "20px" }}
                  />
                </div>

                <div style={{ width: screen.sWidth > 768 && "40%", display: 'none' }}>
                  <div
                    style={{
                      display: disFlex,
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      alignContent: "stretch",
                      justifyContent: spaceBetween,
                    }}
                  >
                    <Typography
                      className={classes.subtitleTurn}
                      style={{
                        fontSize: fourteenPx,
                        fontWeight: bold400,
                        marginBottom: "12px",
                        lineHeight: "1em",
                      }}
                    >
                      App Mobile
                    </Typography>
                    <Switch
                      classes={{ root: classes.switchColor }}
                      name={"appmobile"}
                      onChange={handleSwitch()}
                      value={"appmobile"}
                      checked={!!switchState["appmobile"]}
                    ></Switch>
                  </div>
                </div>
                <div style={{ width: screen.sWidth > 768 ? "50%" : "100%", marginTop: screen.sWidth > 768 ? '10px' : '30px' }}>
                  {showsInputs(false, 'Teléfono móvil', phones)}
                  
                  <Typography
                    style={{
                      margin: screen.sWidth < 768 ? '10px 10px' : '5px',
                      color: '#0075c9',
                      fontSize: '12px',
                    }}>
                    {disablePhoneCaption}
                  </Typography>

                  {
                    !disablePhone &&
                    <Typography
                      ref={editPhoneRef}
                      style={{
                        width: '80%',
                        margin: screen.sWidth < 768 ? '5px 10px' : '5px',
                        color: '#0075c9',
                        fontSize: '12px',
                      }}>
                      {notDisablePhoneCaption}
                    </Typography>
                  }
                </div>
              </div>
            </Fragment>
          ))}
      </div>
      {openModal && (
        <ModalConfirm
          model={model}
          openModal={openModal}
          setOpenModal={setOpenModal}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleCloseDrawer={handleCloseDrawer}
        ></ModalConfirm>
      )}
      {screen.sWidth <= 768 && (
        <FooterDrawer
          activeStep={activeStep}
          handleOpenCancelModal={handleOpenCancelModal}
          handleBack={handleBack}
          handleNext={handleNext}
          confirm={true}
          disabledNext={!disablePhone}
        />
      )}
    </>
  );
}

function setModelFunction(setterModel, modelAppointmentObj, modelObj, email) {
  setterModel({
    ...modelObj,
    enviarMail: "S",
    enviarNotificacionApp: "S",
    mail: email,
    ip: modelObj?.ip,
    medicoCodigo: modelAppointmentObj?.medicoCodigo,
    numeroPersona: Number.parseInt(modelAppointmentObj?.afiliadoCodigo),
    numeroTurno: modelAppointmentObj?.turnoNumero,
    origen: modelAppointmentObj.modalidadTurno === "Turno presencial" ? 99 : 98,
    turnoAnterior: modelAppointmentObj?.turnoAnterior,
  });
}
