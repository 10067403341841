import React, { useEffect, useState } from "react";
import useStyles from "../../assets/styles/styles";
import Menu from "../../components/Menu/Menu";
import { Divider, IconButton, Typography } from "@material-ui/core";
import { getAppointmentFavorite, getDataFilterAppointment, getAfilliatedAppointments, ABAppointmentFavorite } from "../../redux/actions/appointmentsActions";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../controls/Select/CustomSelect";
import NotFound from "../../components/HomeLogged/NotFound";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { returnHomeAppointmentHome, setModelAppointmentToRepeatOrModify, showErrorMessageModal } from "../../utils/utilsFunctions";
import { useHistory } from "react-router";
import ModalActivities from "../../components/DialogActivities/ModalActivities";
import FavoriteCard from "../../components/Appointments/Cards/FavoriteCard";
import  useTrackPageView  from "../../utils/useTrackPageView";

const userCode = parseInt(window.localStorage.getItem("numeroPersona"));

const persona = {
    numeroPersona: userCode,
    parametroLlamado: "FILTRO HISTORICO TURNO"
};

const param = {
    numeroPersona: userCode,
    solicitudTurnos: "HISTORICOS",
    count: 9999,
    skip: 0
};

const paramAll = {
    numeroPersona: userCode,
    solicitudTurnos: "TODOS",
    count: 1000,
    skip: 0,
};

const paramFav = {
    numeroPersona: userCode,
    count: 9999,
    skip: 0,
};

const AppointmentFavorite = () => {
    const trackPageView = useTrackPageView("Turnos Favoritos");
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [openModalRepeatAppointment, setOpenModalRepeatAppointment] = useState(false);
    const appointments = useSelector((state) => state.appointmentsReducer);
    const [favorite, setFavorite] = useState([]);
    const [groupFamily, setGroupFamily] = useState([]);
    const [selectAffilliate, setSelectAffilliate] = useState({
        value: 0,
        label: ""
    });
    const [modelAppointment, setModelAppointment] = useState({});
    const [openModalFavorite, setOpenModalFavorite] = useState(false);
    const [openError, setOpenError] = useState(false);
    const errorMessage = useSelector(state => state.appointmentsReducer.msgError);

    const handleChangeWEB = () => e => {
        var nameAffilliated = groupFamily.filter((x) => x.value == e.target.value);
        setSelectAffilliate({ value: nameAffilliated[0].value, label: nameAffilliated[0].label });
    };

    const handleClickOpenRepeatModal = (item) => (e) => {
        e.preventDefault();
        setOpenModalRepeatAppointment(prevState => !prevState);
        setModelAppointment(item);
    };

    const handleClickModifyRepeatAppointment = () => {
        setModelAppointmentToRepeatOrModify(modelAppointment, dispatch, userCode, appointments, history);
    };

    const handleClickOpenDeleteFavModal = (item) => (e) => {
        e.preventDefault();
        var del = {
            numeroPersona: selectAffilliate?.value,
            medicoCodigo: item?.medicoCodigo,
            tipo: "BAJA"
        };
        setModelAppointment(del);
        setOpenModalFavorite(true);
    };

    useEffect(() => {
        dispatch(getAppointmentFavorite(paramFav));
    }, [openModalFavorite])

    const handleClickDeleteFavAppointment = () => {
        dispatch(ABAppointmentFavorite(modelAppointment));
        setOpenModalFavorite(prevState => !prevState);
    };


    useEffect(() => {
        trackPageView();
        dispatch(getDataFilterAppointment(persona));
        dispatch(getAfilliatedAppointments(paramAll));
        dispatch(getAppointmentFavorite(paramFav));
    }, []);

    useEffect(() => {
        let listGroupFamily = appointments?.filtro?.map((val) => {
            return {
                value: parseInt(val?.numeroPersona), label: val?.apellidoNombre
            };
        });
        setGroupFamily(listGroupFamily);
    }, [appointments?.filtro]);

    useEffect(() => {
        groupFamily?.forEach((item) => {
            if (item.value == userCode) {
                setSelectAffilliate({ value: item.value, label: item.label });
            }
        });
    }, [groupFamily]);

    useEffect(() => {
        paramFav.numeroPersona = selectAffilliate?.value;
        dispatch(getAppointmentFavorite(paramFav));
    }, [selectAffilliate]);

    useEffect(() => {
        let listFav = [];
        if (selectAffilliate?.value !== undefined || selectAffilliate?.value !== 0) {
            listFav = appointments?.favorite?.map((element) => {
                return element;
            });
            setFavorite(listFav);
        }
    }, [appointments?.favorite]);

    useEffect(() => {
        errorMessage !== "" && setOpenError(true);
    }, [errorMessage]);

    return (
        <>
            <Menu />
            <div className={`${classes.divMyHistoryTurn}`}>
                <div className={`${classes.divAffilliate} ${classes.divAffilliateMyHistory}`}>
                    <Typography className={classes.textTitleTurn}>Afiliado: </Typography>
                    <CustomSelect
                        className={`${classes.afilliateSelect}`}
                        customRequired={true}
                        list={groupFamily}
                        selected={selectAffilliate?.value}
                        customClassList={`${classes.deploymentSelect} ${classes.deploymentSelectMyHistory}`}
                        onChange={handleChangeWEB()}
                    />
                </div>
                <>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <IconButton onClick={returnHomeAppointmentHome(history)}>
                                <ArrowBackIcon className={classes.iconFilter} />
                            </IconButton>
                            <Typography className={`${classes.textTitleTurn}`} style={{ textTransform: 'uppercase' }}>
                                Mis favoritos
                            </Typography>
                        </div>
                    </div>
                </>

                <Divider className={classes.formDivider} style={{ marginRight: "50px" }} />
                <div className={`${classes.listCardMyHistory}`} style={{display: "flex", justifyContent: "center"}}>
                    {favorite?.length > 0 ?
                        favorite?.map((item, i) => (
                            <FavoriteCard item={item} i={i} handleClickOpenRepeatModal={handleClickOpenRepeatModal} handleClickOpenDeleteFavModal={handleClickOpenDeleteFavModal} />
                        ))
                        :
                        <NotFound customMsg={
                            <div className={classes.contentNotFound} style={{ marginTop: "10%" }}>
                                <span>No tenés turnos favoritos</span>
                            </div>} 
                            wsItem={appointments?.appointmentFavoriteFullData?.favoritos?.filter((x) => x.pacienteNumero === userCode)}
                            fullItem={appointments?.appointmentFavoriteFullData} />
                    }
                </div>
                {showErrorMessageModal(errorMessage, openError, setOpenError, classes.borderWhite, classes.quickAccessIcon)}
                {
                    openModalRepeatAppointment &&
                    <ModalActivities
                        title={'Repetir Turno'}
                        description={'¿Estás seguro que deseas repetir el turno seleccionado?'}
                        open={openModalRepeatAppointment}
                        onClose={setOpenModalRepeatAppointment}
                        modalconfirm={true}
                        modalLogout={false}
                        buttonType={'submit'}
                        handleClick={handleClickModifyRepeatAppointment}
                    />
                }
                {
                    openModalFavorite &&
                    <ModalActivities
                        title={'Eliminar favorito'}
                        description={'¿Estás seguro que deseas eliminar el turno de tus favoritos?'}
                        open={openModalFavorite}
                        onClose={setOpenModalFavorite}
                        modalconfirm={true}
                        modalLogout={false}
                        buttonType={'submit'}
                        handleClick={handleClickDeleteFavAppointment}
                    />
                }
            </div>
        </>
    );
};

export default AppointmentFavorite;