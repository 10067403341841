import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetDataWheightHeight({ payload }) {
    try {
        const response = yield call(API.getDataWheightHeight, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_DATA_WHEIGHT_HEIGHT, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_DATA_WHEIGHT_HEIGHT, message: error });
    }
}

function* asyncABWheightHeight({ payload }) {
    try {
        const response = yield call(API.ABWheightHeight, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_AB_WHEIGHT_HEIGHT, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_AB_WHEIGHT_HEIGHT, message: error });
    }
}

/**vital parameters */
function* asyncGetVitalParamters({ payload }) {
    try {
        const response = yield call(API.getVitalParameters, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_VITAL_PARAMETERS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_VITAL_PARAMETERS, message: error });
    }
}

function* asyncABVitalParamters({ payload }) {
    try {
        const response = yield call(API.ABvitalParameters, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_AB_VITAL_PARAMETERS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_AB_VITAL_PARAMETERS, message: error });
    }
}

function* asyncGetPrograms({ payload }) {
    try {
        var response = yield call(API.getAffiliateEnrollments, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_PROGRAMS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_PROGRAMS, message: error.message });
    }
}

function* asyncGetAllergiesParams() {
    try {
        const response = yield call(API.getAllergies);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_ALLERGIES_PARAMS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_ALLERGIES_PARAMS, message: error });
    }
}

function* asyncGetAffiliatedAllergies({ payload }) {
    try {
        const response = yield call(API.getAffiliatedAllergies, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_AFFILIATED_ALLERGIES, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_AFFILIATED_ALLERGIES, message: error });
    }
}

function* asyncABMAllergies({ payload }) {
    try {
        const response = yield call(API.allergiesABM, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_ABM_ALLERGIES, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_ABM_ALLERGIES, message: error });
    }
}

function* asyncGetAffiliatedPhones({ payload }) {
    try {
        const response = yield call(API.getMDPhones, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_AFFILIATED_PHONES, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_AFFILIATED_PHONES, message: error });
    }
}

function* asyncABMAffiliatedPhones({ payload }) {
    try {
        const response = yield call(API.ABMMDPhones, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_ABM_MD_PHONES, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_ABM_MD_PHONES, message: error });
    }
}
/** */
function* asyncABMFactorGroup({ payload }) {
    try {
        const response = yield call(API.ABMFactorGroup, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_ABM_FACTOR_GROUP, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_ABM_FACTOR_GROUP, message: error });
    }
}

function* asyncGetFactorGroup({ payload }) {
    try {
        let response = yield call(API.getFactorGroup, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_FACTOR_GROUP, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_FACTOR_GROUP, message: error });
    }
}

function* asyncGetFactorGroupParams() {
    try {
        const response = yield call(API.getParameterFactorGroup);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_FACTOR_GROUP_PARAMS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_FACTOR_GROUP_PARAMS, message: error });
    }
}

function* asyncABMChronicDiseases({ payload }) {
    try {
        const response = yield call(API.ABMChronicDiseases, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_ABM_CHRONIC_DISEASES, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_ABM_CHRONIC_DISEASES, message: error });
    }
}

function* asyncGetChronicDiseases({ payload }) {
    try {
        const response = yield call(API.getChronicDiseases, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_CHRONIC_DISEASES, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_CHRONIC_DISEASES, message: error });
    }
}

function* asyncGetChronicDiseasesParams({ payload }) {
    try {
        const response = yield call(API.getParameterDiseases, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_CHRONIC_DISEASES_PARAMS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_CHRONIC_DISEASES_PARAMS, message: error });
    }
}

function* asyncABMChronicMedication({ payload }) {
    try {
        const response = yield call(API.ABMChronicMedication, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_ABM_CHRONIC_MEDICATION, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_ABM_CHRONIC_MEDICATION, message: error });
    }
}

function* asyncGetChronicMedication({ payload }) {
    try {
        const response = yield call(API.getChronicMedication, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_CHRONIC_MEDICATION, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_CHRONIC_MEDICATION, message: error });
    }
}

function* asyncGetVaccine({ payload }) {
    try {
        var response = yield call(API.getVaccine, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_VACCINE, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_VACCINE, message: error.message });
    }
}

function* asyncGetLaboratoryStudies({ payload }) {
    try {
        var response = yield call(API.getLaboratoryStudies, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_LABORATORY_STUDIES, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_LABORATORY_STUDIES, message: error.message });
    }
}

function* asyncGetDetailLaboratoryStudies({ payload }) {
    try {
        var response = yield call(API.getDetailLaboratoryStudies, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_DETAIL_LABORATORY, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_DETAIL_LABORATORY, message: error.message });
    }
}

function* asyncGetPharmaceuticalForm() {
    try {
        const response = yield call(API.getPharmaceuticalForm);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_PHARMACEUTICAL_FORM, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_PHARMACEUTICAL_FORM, message: error });
    }
}


function* asyncGetRecipe({ payload }) {
    try {
        const response = yield call(API.getRecipe, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_RECIPE, response });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_GET_RECIPE,
            message: error,
        });
    }
}



export default function* medicalDataSaga() {
    yield takeLatest(actionTypes.GET_DATA_WHEIGHT_HEIGHT, asyncGetDataWheightHeight);
    yield takeLatest(actionTypes.AB_DATA_WHEIGHT_HEIGHT, asyncABWheightHeight);
    yield takeLatest(actionTypes.GET_VITAL_PARAMETERS, asyncGetVitalParamters);
    yield takeLatest(actionTypes.AB_VITAL_PARAMETERS, asyncABVitalParamters);
    yield takeLatest(actionTypes.GET_CHRONIC_MEDICATION, asyncGetChronicMedication);
    yield takeLatest(actionTypes.GET_PROGRAMS, asyncGetPrograms);
    yield takeLatest(actionTypes.GET_ALLERGIES_PARAMS, asyncGetAllergiesParams);
    yield takeLatest(actionTypes.GET_AFFILIATED_ALLERGIES, asyncGetAffiliatedAllergies);
    yield takeLatest(actionTypes.ABM_ALLERGIES, asyncABMAllergies);
    yield takeLatest(actionTypes.GET_AFFILIATED_PHONES, asyncGetAffiliatedPhones);
    yield takeLatest(actionTypes.ABM_MD_PHONES, asyncABMAffiliatedPhones);
    yield takeLatest(actionTypes.GET_LABORATORY_STUDIES, asyncGetLaboratoryStudies);
    yield takeLatest(actionTypes.GET_DETAIL_LABORATORY_STUDIES, asyncGetDetailLaboratoryStudies);
    yield takeLatest(actionTypes.ABM_FACTOR_GROUP, asyncABMFactorGroup);
    yield takeLatest(actionTypes.GET_FACTOR_GROUP, asyncGetFactorGroup);
    yield takeLatest(actionTypes.GET_FACTOR_GROUP_PARAMS, asyncGetFactorGroupParams);
    yield takeLatest(actionTypes.ABM_CHRONIC_DISEASES, asyncABMChronicDiseases);
    yield takeLatest(actionTypes.GET_CHRONIC_DISEASES, asyncGetChronicDiseases);
    yield takeLatest(actionTypes.GET_CHRONIC_DISEASES_PARAMS, asyncGetChronicDiseasesParams);
    yield takeLatest(actionTypes.ABM_CHRONIC_MEDICATION, asyncABMChronicMedication);
    yield takeLatest(actionTypes.GET_CHRONIC_MEDICATION, asyncGetChronicMedication);
    yield takeLatest(actionTypes.GET_VACCINE, asyncGetVaccine);
    yield takeLatest(actionTypes.GET_PHARMACEUTICAL_FORM, asyncGetPharmaceuticalForm);


    yield takeLatest(actionTypes.GET_RECIPE, asyncGetRecipe);
    
}
