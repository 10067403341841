import * as actionTypes from '../consts/actionTypes';

const initialState = {
    preventionControl: [],
    msgError: ''
};

export default function preventionReducer(state = initialState, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_POST_PREVENTIONS]: responseToReturn('preventionControl'),
        [actionTypes.FAILURE_POST_PREVENTIONS]: responseToReturn('preventionControl'),
    };
    function responseToReturn(type) {
        let res = { ...state };
        if (action.response) {
            res = { ...state, [type]: action.response.data, msgError: action.message };
        }
        if (action.message) {
            res = { ...state, msgError: action.message };
        }
        return res;
    }

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}