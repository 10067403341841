import React, { Fragment, useEffect, useState, useRef } from "react";
import useStyles from "../../assets/styles/styles";
import { CircularProgress, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import { useDispatch, useSelector } from "react-redux";
import { setModelToSaveAppointment } from "../../redux/actions/appointmentsActions";
import CustomSelect from "../../controls/Select/CustomSelect";
import { useResizeScreen } from "../../hooks/useResizeScreen";

const userCode = parseInt(window.localStorage.getItem("numeroPersona"))

var items = [
  {
    title: "Turno presencial",
  },
  {
    title: "Telemedicina",
  },
];

const appointmentFlow = [
  {
    name: "Centro Médico",
    value: "centro",
    enabled: false
  },
 
  {
    name: "Especialidad",
    value: "especialidad",
    enabled: false
  },
  {
    name: "Médico",
    value: "medico",
    enabled: false
  } 
];

export default function AskTurnDrawer(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const groupFamilyTurn = useSelector((state) => state.appointmentsReducer?.groupFamilyTurn);
  const [groupFamily, setGroupFamily] = useState([])
  const modelAppointmentReducer = useSelector(state => state.appointmentsReducer.modelAppointment);
  const [modelAppointment, setModelAppointment] = useState(modelAppointmentReducer);
  const [switchState, setSwitchState] = useState({});
  const [personNumber, setPersonNumber] = useState({ value: 0, label: '' });
  const [flow, setFlow] = useState(appointmentFlow);
  const appointmentByRef = useRef(null);
  const { screen } = useResizeScreen();

  useEffect(() => {
    initializeSwitch();
    backSwitch();
    initializePerson();

    if (modelAppointment?.afiliadoCodigo === undefined || modelAppointment?.modalidadTurno === undefined) {
      setModelAppointment({ ...modelAppointment, afiliadoCodigo: "", afiliadoDescripcion: "", modalidadTurno: "" })
    }

    setModelAppointment({ ...modelAppointment, appointmentFlow: undefined })

    const refreshSwitch = flow?.map((item) => {
      return { ...item, enabled: false }
    });

    setFlow(refreshSwitch);
  }, []);

  useEffect(() => {
    dispatch(setModelToSaveAppointment({
      ...modelAppointment,
      especialidadCodigo: '',
      medicoCodigo: '',
      appointmentFlow: modelAppointment?.modalidadTurno == 'Telemedicina' ? undefined : modelAppointment?.appointmentFlow
    }));
  }, [flow, modelAppointment?.modalidadTurno])

  useEffect(() => {
    let listGroupFamily = groupFamilyTurn?.map((item) => {
      return { value: parseInt(item?.itemCodigo), label: item?.itemDescripcion }
    })
    setGroupFamily(listGroupFamily)
    if (modelAppointment?.afiliadoCodigo === undefined || modelAppointment?.modalidadTurno === undefined) {
      let selectGroupFamily = groupFamilyTurn?.filter(x => parseInt(x.itemCodigo) == userCode)?.map((item) => {
        return { value: parseInt(item?.itemCodigo), label: item?.itemDescripcion }
      });
      setPersonNumber(selectGroupFamily?.[0])
      setModelAppointment({ ...modelAppointment, afiliadoCodigo: selectGroupFamily?.[0]?.value, afiliadoDescripcion: selectGroupFamily?.[0]?.label });
    }
  }, [groupFamilyTurn]);

  const initializePerson = () => {
    if (modelAppointment.length > 0 || modelAppointment?.afiliadoCodigo) {
      setPersonNumber({ ...personNumber, value: modelAppointment?.afiliadoCodigo, label: modelAppointment?.afiliadoDescripcion })
    }
    else {
      setPersonNumber({ ...personNumber, value: 0, label: "" })

    }
  }

  const handleSwitch = () => (e) => {
    initializeSwitch();
    const { name } = e.target;
    setSwitchState((prevState) => ({
      ...prevState,
      [name]: e.target.checked,
    }));
    if (e.target.checked) {
      setModelAppointment({
        ...modelAppointment,
        modalidadTurno: e.target.name,
        centroMedicoCodigo: undefined,
        centroMedico: undefined,
        direccion: undefined,
        especialidadCodigo: '',
        especialidadDescripcion: '',
        hourSelected: '',
        medicoCodigo: undefined,
        medicoDescripcion: '',
        nombreCentroMedico: undefined,
        observations: undefined,
        proveedorNumero: undefined,
        selectedAppointment: ''
      });
    }
    else {
      setModelAppointment({ ...modelAppointment, modalidadTurno: undefined });
    }
  }

  useEffect(() => {
    dispatch(setModelToSaveAppointment({ ...modelAppointment }));
    if (modelAppointment?.modalidadTurno == 'Turno presencial' && screen.sWidth < 768) {
      if (appointmentByRef.current) {
        appointmentByRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [modelAppointment])

  const handleChangeSwitch = (e) => {
    let objectsProperties = flow?.map(item => {

      if (item?.value === e.target.value) {
        item['enabled'] = e.target.checked;
      } else {
        item['enabled'] = false;
      }

      return item;
    });

    setFlow(objectsProperties);

    if (e.target.checked) {
      setModelAppointment({
        ...modelAppointment,
        appointmentFlow: e.target.value,
        centroMedicoCodigo: undefined,
        centroMedico: undefined,
        direccion: undefined,
        especialidadCodigo: '',
        especialidadDescripcion: '',
        hourSelected: '',
        medicoCodigo: undefined,
        medicoDescripcion: '',
        nombreCentroMedico: undefined,
        observations: undefined,
        proveedorNumero: undefined,
        selectedAppointment: ''
      });
    } else {
      setModelAppointment({ ...modelAppointment, appointmentFlow: undefined });
    }
  }

  function initializeSwitch() {
    Object.entries(switchState).forEach(([key, value]) => {
      if (value !== "") {
        setSwitchState((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });
  }

  function backSwitch() {
    setSwitchState((prevState) => ({
      ...prevState,
      [modelAppointment.modalidadTurno]: true,
    }));
  }

  const handleChange = () => e => {
    var nameAffilliated = groupFamily.filter((x) => x.value == e.target.value);
    if (nameAffilliated[0].value !== 0 && nameAffilliated !== null) {
      setPersonNumber({ value: nameAffilliated[0].value, label: nameAffilliated[0].label });
      setModelAppointment({ ...modelAppointment, afiliadoCodigo: nameAffilliated[0].value, afiliadoDescripcion: nameAffilliated[0].label });
    }
    else {
      setPersonNumber({ value: 0, label: '' });
      setModelAppointment({ ...modelAppointment, afiliadoCodigo: 0, afiliadoDescripcion: '' });
    }
  }

  return (
    <>
      <section className={`${classes.drawerContainerTurn} ${classes.fixAskTurn}`}>
        {groupFamily?.length > 0 ?
          <>
            <div className={classes.divBoxFirstTurn}>
            <Typography style={{ marginLeft: "10px" }}>
                {props.titles.map((titles) => (
                  titles.title1
                ))}
              </Typography>
              <CustomSelect
                className={`${classes.afilliateSelectTurn}`}
                customClassList={classes.deploymentSelectTurn}
                list={groupFamily}
                selected={personNumber?.value}
                onChange={handleChange()}
              />
              <div style={{ width: "100%" }}>
                {modelAppointment.afiliadoCodigo === 0 || modelAppointment.afiliadoCodigo === undefined && <span className={classes.spanError}>*Complete el campo</span>}
              </div>
            </div>
            <div className={classes.divBoxFirstTurn}>
            <Typography style={{ marginLeft: "10px" }}>
                {props.titles.map((titles) => (
                  titles.title2
                ))}
              </Typography>
              <List
                component="nav"
                aria-label="mailbox folders"
                className={classes.modalityTurn}
                sx={{
                  '& .MuiDivider-fullWidth': {
                    heigth: '1px',
                    margin: '5px',
                    borderBottomWidth: "0px",
                  },
                }}>
                {items.map((item, i) => (
                  <Fragment key={i}>
                    <ListItem className={classes.listItemTurn}>
                      <Typography style={{ fontWeight: '400', fontSize: '16px' }}>
                        {item.title}
                      </Typography>
                      <Switch
                        name={item.title}
                        onChange={handleSwitch()}
                        checked={!!switchState[item.title]}
                        classes={{ root: classes.switchColor }}
                        value={item.title} >
                      </Switch>
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </List>
              {modelAppointment?.modalidadTurno == undefined && <span className={classes.spanError}>*Complete el campo</span>}
              {modelAppointment?.modalidadTurno === "Turno presencial" &&
                <>
                  <Typography ref={appointmentByRef} style={{ marginTop: '30px', marginLeft: "10px" }}>Turno por</Typography>
                  <List
                    component="nav"
                    aria-label="mailbox folders"
                    className={classes.modalityTurn}
                    sx={{
                      '& .MuiDivider-fullWidth': {
                        heigth: '1px',
                        margin: '5px',
                        borderBottomWidth: "0px",
                      },
                    }}>
                    {flow?.map((item, i) => (
                      <Fragment key={i}>
                        <ListItem className={classes.listItemTurn}>
                          <Typography style={{ fontWeight: '400', fontSize: '16px' }}>
                            {item?.name}
                          </Typography>
                          <Switch
                            name={item?.name}
                            onChange={handleChangeSwitch}
                            checked={item?.enabled}
                            classes={{ root: classes.switchColor }}
                            value={item?.value}
                          >
                          </Switch>
                        </ListItem>
                        <Divider />
                      </Fragment>
                    ))}
                  </List>
                </>
              }
              {(modelAppointment?.appointmentFlow == undefined && modelAppointment?.modalidadTurno === "Turno presencial") && <span className={classes.spanError}>*Complete el campo</span>}
            </div>
          </>
          :
          <div className={classes.divBoxFirstTurn}>
            <div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}>
              <CircularProgress />
            </div>
          </div>}

      </section>
    </>
  );
}