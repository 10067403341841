import Menu from "../../components/Menu/Menu";
import { useEffect, useLayoutEffect, useState } from 'react';
import Header from '../../components/GenericHeader/Header';
import ContactUs from '../../components/ContactUs/ContactUs';
import { useDispatch, useSelector } from "react-redux";
import { getHeadquaters } from "../../redux/actions/sedesActions";
import { getFullData } from "../../redux/actions/personalDataActions";
import { getLimitPlan } from "../../redux/actions/limitPlansAction";
import { notAccessToThisModule, verifyPermission } from "../../utils/utilsFunctions";
import useTrackPageView from "../../utils/useTrackPageView";

const ContactUsPage = () => {
    const dispatch = useDispatch();
    const trackPageView = useTrackPageView("Contactanos");
    const userCode = parseInt(window.localStorage.getItem("numeroPersona"));
    const affiliatePlanDescription = useSelector((state) => state.personalDataReducer?.fullData?.afiliado?.planDescripcion);
    const limitPlanReducer = useSelector((state) => state.limitPlanReducer.allLimitPlanData);
    const [ hasPermission, setHasPermission ] = useState(false);

    useEffect(() => {
        dispatch(getLimitPlan());
    }, []);

    useLayoutEffect(() => {
        verifyPermission(limitPlanReducer, affiliatePlanDescription, "Contáctanos", setHasPermission);
    }, [limitPlanReducer, affiliatePlanDescription, hasPermission]);

    useEffect(() => {
        trackPageView();
        dispatch(getHeadquaters())
        dispatch(getFullData({numeroPersona: userCode}))
    }, [])

    return (
        <>
            {
            <>
                <Menu />
                <Header title='Contactanos' />
                {
                    !hasPermission ?
                    notAccessToThisModule()
                :
                    <ContactUs />
                }
            </>
        }
        </>
    );
};

export default ContactUsPage;

