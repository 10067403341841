import { Badge } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const BadgeNotification = ({ countNotification, childrenItem, customClass, customWidth }) => {
    const [defaultProps, setDefaultProps] = useState({
        color: "secondary",
        children: ''
    });

    useEffect(() => {
        setDefaultProps({ ...defaultProps, children: childrenItem });
    }, []);

    return <>
        <div style={{ width: customWidth }}>
            <Badge style={{ display: 'flex', flexWrap: 'wrap' }} className={customClass} badgeContent={countNotification} {...defaultProps} />
        </div>
    </>;
};

export default React.memo(BadgeNotification);