import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetActivities({ payload }) {
    try {
        let response = '';
        if (payload !== '') {
            response = yield call(API.getActivitiesWithSecurity);
        } else {
            response = yield call(API.getActivities);
        }
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_ALL_ACTIVITIES, response, message: '' });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_GET_ALL_FAILURE,
            message: error.message,
        });
    }
}


// Watchers
export default function* activitiesSaga() {
    yield takeLatest(actionTypes.GET_ALL_ACTIVITIES, asyncGetActivities);
}
