import React from "react";
import { Drawer as MuiDrawer } from "@material-ui/core";
import useStyles from "../../assets/styles/styles";

const Drawer = (props) => {
  const classes = useStyles();
  return (
    <MuiDrawer
      className={classes.drawerTurn}
      variant="persistent"
      open={props.open}
      classes={{
        paper: classes.drawerPaperTurn,
      }}
    >
      {props.children}
    </MuiDrawer>
  );
};

export default React.memo(Drawer);
