import { Divider, Typography, CircularProgress } from "@material-ui/core";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import FormHeader from "../../PersonalData/FormHeader";
import { useEffect, useState } from "react";
import useStyles from '../../../assets/styles/styles';
import ChronicDiseasesForm from "./ChronicDiseasesForm";
import { getChronicDiseases, getChronicDiseasesParams } from "../../../redux/actions/medicalDataAction";
import { useDispatch, useSelector } from "react-redux";
import { handleGoToPersonalMedicalData, showMessageModal } from "../../../utils/utilsFunctions";
import CustomButton from "../../../controls/Button/CustomButton";


const param = {
    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
    count: 100,
    skip: 0
}

const ChronicDiseases = () => {
    const { screen } = useResizeScreen();

    const abmReduxState = useSelector(state => state.medicalDataReducer?.abmChronicDiseases);

    const classes = useStyles();

    const [disabled, setDisabled] = useState(true); 
    const [loading, setLoading] = useState(true);
    const [disease, setDisease] = useState([]);
    const dispatch = useDispatch();
    const chronicDisease = useSelector(state => state.medicalDataReducer?.chronicDiseases);
    const [errorMessageModal, setErrorMessageModal] = useState(false);
    const errorMessage = useSelector(state => state.medicalDataReducer?.msgError);

    useEffect(() => {
        dispatch(getChronicDiseases(param));
        dispatch(getChronicDiseasesParams(param));
    }, []);

    useEffect(() => {
        setDisease(chronicDisease?.enfermedades)
        setLoading(false);
    }, [chronicDisease?.enfermedades]);


    useEffect(() => {
        getDataOfWS();
    },[abmReduxState]);

    useEffect(() => {
        if (errorMessage?.length > 0) {
            setErrorMessageModal(true);
        }
        setLoading(true);
    }, [errorMessage]);

    function getDataOfWS() {

        dispatch(getChronicDiseases(param));
        dispatch(getChronicDiseasesParams(param));
    }


    function handleNew() {
        setDisabled(prevState => !prevState);
    }

    function formHeader() {
        let res = {
            title: 'Enfermedades Cronicas',
            subtitle: 'Información sin carácter sanitario conforme ley Nº 26529'
        };
        if (screen.sWidth < 768) {
            res.title = 'MIS DATOS MEDICOS';
            res.subtitle = 'Información sin carácter sanitario conforme ley Nº 26529';
        }
        return res;
    }

    return (
        <>
            <FormHeader title={formHeader().title} subtitle={formHeader().subtitle} handleGoTo={handleGoToPersonalMedicalData}/>
            {
                screen.sWidth > 768 &&
                <Divider className={classes.formDivider} style={{ marginBottom: '20px' }} />
            }
            {disabled &&
                <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`} style={{ justifyContent: 'flex-start' }}>
                    {screen.sWidth <= 768 ?
                        <>
                            <Typography
                                className={`${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency}`}
                                style={{ textTransform: 'uppercase', alignItems: 'center', display: 'flex', paddingLeft: screen.sWidth < 768 && '5px' }}>
                                ENFERMEDADES CRONICAS
                            </Typography>
                        </>
                        :
                        ""
                    }
                    <>
                        <CustomButton
                            onClick={handleNew}
                            title={"NUEVO "}
                            style={{ position: 'absolute', right: "5%" }}
                            customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                        />
                    </>
                </div>
            }
            {loading ?
                <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
                :
                <ChronicDiseasesForm disease={disease} setDisease={setDisease} disabled={disabled} setDisabled={setDisabled} handleNew={handleNew} />
            }
            {chronicDisease?.enfermedades?.length === 0 && disabled && chronicDisease !== undefined &&
                <Typography
                    className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}
                    style={{ marginLeft: "30px", marginTop: "15px" }}>
                    {"No se encontraron resultados"}
                </Typography>}

            {showMessageModal(errorMessage, errorMessageModal, setErrorMessageModal, classes.borderWhite, classes.quickAccessIcon, classes.contentDialogTitle, handleGoToPersonalMedicalData)}
        </>
    );
};

export default ChronicDiseases;