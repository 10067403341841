import { Divider, CircularProgress } from "@material-ui/core";
import useStyles from "../../../assets/styles/styles";
import PdfReader from "../../PdfReader/PdfReader";
import MyPlan from "../../../assets/files/Cobertura incluida en tu Plan_asistencia_viajero_P_NOVO.pdf";
import GeneralConditions from "../../../assets/files/Condiciones generales.pdf";
import FrequentQuestions from "../../../assets/files/asistencia_viajero-preguntas_frecuentes.pdf";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getPlanPdfByPlanCodeAction } from "../../../redux/actions/planPdfActions";

const datos = [
  { name: "MyPlan", pdf: MyPlan },
  {
    name: "GeneralConditions",
    pdf: GeneralConditions,
    configurationId: "coberturaViajeCondicionesGenerales",
  },
  {
    name: "FrequentQuestions",
    pdf: FrequentQuestions,
    configurationId: "coberturaViajePreguntasFrecuentes",
  },
];

const MyCoveragePDF = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const match = search.match(/type=(.*)/);
  const typeUrl = match?.[1];
  const [loading, setLoading] = useState(true);
  const [pdf, setPdf] = useState();

  const dispatch = useDispatch();

  const planPdfReduxState = useSelector((state) => state.planPdfReducer);
  const fullDataAffiliatedReduxState = useSelector(
    (state) => state.personalDataReducer?.fullData?.afiliado
  );

  const configurationReduxState = useSelector(
    (state) => state.configurationReducer?.allConfigurations?.configurations
  );

  const getPDFLink = (item) =>
    !!item.configurationId
      ? configurationReduxState?.filter((x) => x.id === item.configurationId)[0]
          ?.valor
      : item.pdf;

  useEffect(() => {
    if (
      (typeUrl == "GeneralConditions" || typeUrl == "FrequentQuestions") &&
      configurationReduxState?.length > 0
    ) {
      setLoading(false);
      const item = datos.filter((item) => item.name === typeUrl)[0];
      setPdf(getPDFLink(item));
    }
  }, [typeUrl, configurationReduxState]);

  useEffect(() => {
    if (typeUrl == "MyPlan" && fullDataAffiliatedReduxState?.planCodigo > 0) {
      dispatch(
        getPlanPdfByPlanCodeAction(fullDataAffiliatedReduxState?.planCodigo)
      );
    }
  }, [typeUrl, fullDataAffiliatedReduxState]);

  useEffect(() => {
    if (planPdfReduxState?.planPdf?.id > 0) {
      setPdf(planPdfReduxState?.planPdf.pdfLink);
      setLoading(false);
    }
  }, [planPdfReduxState]);

  return (
    <>
      <Divider className={classes.formDivider} />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flex: "0 0 100%",
            paddingTop: "10px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        pdf && (
          <div>
            <PdfReader pdf={pdf} customClass={classes.pdfCoverage} />
          </div>
        )
      )}
    </>
  );
};

export default MyCoveragePDF;
