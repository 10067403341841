import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { getAfilliatedAppointmentsNext, getAppointmentFavorite, getSpecialties, setModelToSaveAppointment, setModifyAppointment } from '../redux/actions/appointmentsActions';
import { postProcedures } from '../redux/actions/procedureActions';
import { setActualStep } from '../redux/actions/stepAppointmentsActions';
import CancelIcon from '@material-ui/icons/Cancel';
import { CircularProgress, Box } from '@material-ui/core';
import ModalProfile from "../components/DialogProfile/Modal";
import CustomButton from '../controls/Button/CustomButton';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

export const MONTHS = {
    1: 'ENE',
    2: 'FEB',
    3: 'MAR',
    4: 'ABR',
    5: 'MAY',
    6: 'JUN',
    7: 'JUL',
    8: 'AGO',
    9: 'SEP',
    10: 'OCT',
    11: 'NOV',
    12: 'DIC',
};

export const handleChangeMail = (setMail, setMailError, setError, error, setShowPermanente, mail) => (e) => {
    const { name, value } = e.target;
    let regex = /[a-z0-9!#$%&'*{1,5}/=?^_`{|}~-]{1,5}(?:\.[a-z0-9!#$%&'*{1,5}/=?^_`{|}~-]{1,5})*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.){1,5}[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(value);
    setMail(value);

    if (regex || value === '') {

        setMailError('');
        if (setShowPermanente !== null) {
            value != mail ? setShowPermanente(true) : setShowPermanente(false);
        }
    } else {
        setMailError('No cumple con el formato permitido: mail@mail.com');
    }
    validate(name, '*Campo obligatorio', value, setError, error);
};

export const toggleFullScreen = (screenSize) => {
    if (screenSize?.screen?.sWidth <= 500) {
        if (!document?.fullscreenElement) {
            document?.documentElement?.requestFullscreen();
        } else {
            if (document?.exitFullscreen) {
                document?.exitFullscreen();
            }
        }
    }
}

export const responseToReturn = (item, state, action) => {
    let res = { ...state };
    if (action.response) {
        res = { ...state, [item]: action.response.data };
    }
    if (action.message) {
        res = { ...state, msgError: action.message };
    }
    return res;
}

export const validatorForms = (schemaValidation, model) => {
    let valid = true;
    let auxErrors = {};

    schemaValidation?.map(({subType, name, required}) =>{
        if(required && subType && subType === 'cuitcuil' && !validCuitCuil(model[name])){
            auxErrors = { ...auxErrors, [name]: `*El Cuit/Cuil ingresado no es válido` };
            valid = false;
        }
    })

    schemaValidation?.map(({ name, type, exactLength, maxLength, required, maxDecimals }) => {
        if ((type === 'number' || type === 'decimal') && parseInt(model[name]) == '0') {
            auxErrors = { ...auxErrors, [name]: '*Campo no puede ser 0' };
            valid = false;
        }
        if (type === 'date' && (new Date(model[name]) > new Date() || !Date.parse(model[name]))) {
            auxErrors = { ...auxErrors, [name]: '*La fecha no puede ser posterior a hoy' };
            valid = false;
        }
        if (maxLength && model[name]?.length > maxLength) {
            auxErrors = { ...auxErrors, [name]: `*No puede poseer más de ${maxLength} carácteres` };
            valid = false;
        }
        if (required && exactLength && model[name]?.length !== exactLength) {
            auxErrors = { ...auxErrors, [name]: `*Debe poseer ${exactLength} carácteres` };
            valid = false;
        }
        if (maxDecimals && maxDecimals < lengthDecimals(model[name])) {
            auxErrors = { ...auxErrors, [name]: `*No puede poseer más de ${maxDecimals} decimales` };
            valid = false;
        }
        if (required && (model[name] == null || model[name] === '')) {
            auxErrors = { ...auxErrors, [name]: '*Campo obligatorio' };
            valid = false;
        }
    });

    return { auxErrors, valid };
}

export const validCuitCuil = (value) => {

    // Valido que posea 11 caracteres y sea un valor numerico valido
    if(value?.length !== 11 || isNaN(parseInt(value))) return false;

    // Se realiza el algoritmo basado en sumas ponderadas
    let nVerif = (value?.[0] * 5)+(value?.[1] * 4)+(value?.[2] * 3)+(value?.[3] * 2)+(value?.[4] * 7) +
                 (value?.[5] * 6)+(value?.[6] * 5)+(value?.[7] * 4)+(value?.[8] * 3)+(value?.[9] * 2);

    // Calculamos el modulo de 11 y la diferencia entre el resultado y 11
    nVerif = nVerif % 11;
    nVerif = 11 - nVerif;

    // En caso de que nos de mas que nueve, le seteamos 0
    if(nVerif > 9) nVerif = 0

    // Devolvemos la comparacion del resultado calculado y el ultimo digito que nos ingreso
    return nVerif == value?.[10];
}

export const lengthDecimals = (value) => {

    let decimalsSum= 0;

    if(value?.includes('.')){
        decimalsSum = value?.split('.')[1]?.length;
    }

    return decimalsSum;
}

export const formatPhoneNumber = (number, medicalRecords) => {
    let phone = number;
    phone = phone?.replace(/\((\w+)\)/g, "$1");

    if (phone == null) {
        return phone;
    }

    const phoneWithoutCode = phone?.split("-")[1];
    let init = "";
    let middle = "";
    let end = "";

    if (hasSpecialCode(phoneWithoutCode)) {
        init = phoneWithoutCode?.substring(0, 4);
        middle = medicalRecords ? phoneWithoutCode?.substring(5, 8) : phoneWithoutCode?.substring(4, 7);
        end = phoneWithoutCode.substr(-4);

    } else if (hasSpecialCode(phone)) {
        end = phone?.substr(-4);
        init = phone?.substring(0, 4);
        middle = medicalRecords ? phone?.substring(4, 7) : phone?.substring(5, 8);

    } else if (phone?.length === 8) {
        end = phone?.substr(-4);
        init = phone?.substring(0, 4);

    } else if (isCellPhoneNumber(phone)) {
        end = phone?.substr(-4);
        init = phone?.substring(0, 2);
        middle = phone?.substring(2, 6);

    } else if (!phone?.includes("-") && phone?.length >= 11) {
        end = phone?.substr(7);
        init = `(${phone?.substring(0, 3)})`;
        middle = phone?.substring(3, 7);

    } else {
        end = phone?.substr(-4);
        init = `(${phone?.split("-")[0]})`;
        middle = phone?.substring(4, 8);
    }

    if (includeCharacteristic(phone)) {
        return returnPhoneWithCharacteristic(phone, init, end);
    }

    return `${init} ${middle} ${end}`;
}

const hasSpecialCode = (phoneWithoutCode) => {
    return (phoneWithoutCode?.includes("0800") && phoneWithoutCode?.indexOf("0800") < 4) || (phoneWithoutCode?.includes("0810") && phoneWithoutCode?.indexOf("0810") < 4);
}

const isCellPhoneNumber = (number) => {
    return number?.indexOf("15") < 2 && number?.length == 10;
}

const includeCharacteristic = number => {
    return (number.includes("02") && (number.indexOf("02") < 1) ||
        number.includes("03") && (number.indexOf("03") < 1) ||
        number.includes("04") && (number.indexOf("04") < 1)
    ) && !number.includes("0800") && !number.includes("0810");
}

const returnPhoneWithCharacteristic = (num, init, end) => {
    let phone = num;

    if (phone?.includes("(")) {
        let phoneCharacteristic = phone?.substring(1, phone?.indexOf(")") + 2);
        init = phoneCharacteristic?.replace(')', '');
        end = phone?.substr(7);
    } else {
        end = phone?.substr(5);
        init = `${phone?.substring(0, 5)}`;
    }
    return `(${init}) ${end}`;
}

export function validate(nameElement, message, val, setError, error) {
    if (val === '') {
        setError({ ...error, [nameElement]: message });
    } else {
        setError({ ...error, [nameElement]: '' });
    }
}

export const deleteFile = (fileButtons, setFileButtons, setFiles, files, attachmentName, fileName) => (e) => {
    e.preventDefault();
    let auxFiles = files.filter(item => {
        return item.archivoNombre !== fileName;
    });
    setFiles(auxFiles);
    setFileButtons({ ...fileButtons, [attachmentName]: false });
};

export const isDisabled = (fileButtons, typeName) => {
    let response = false;
    if (fileButtons[typeName]) {
        response = true;
    }
    return response;
};

export const handleSave = (props) => {
    const { mail, setModalTitle, setModalDescription, setModalIcon, setErrorClass, setOpenSendSuccess, classes, error, dispatch, model } = props;
    if (validateData(error)) {
        setSaveModal(mail, setModalTitle, setModalDescription, setModalIcon, setErrorClass, classes);
        setOpenSendSuccess(prevState => !prevState);
        dispatch(postProcedures(model));
    }
};

export const setSaveModal = (mail, setModalTitle, setModalDescription, setModalIcon, setErrorClass, classes) => {
    let circularProgress = (<div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress color="inherit" /></div>);
    setModalTitle('INICIANDO GESTION');
    setModalDescription(circularProgress);
    setModalIcon('');
    setErrorClass('');
};

export const validateData = (error) => {
    return Object.values(error).every(item => item === '');
};

export const validateFiles = (listOfAttachmentButtons, files) => {
    let auxFiles = [];
    let response = false;
    listOfAttachmentButtons?.map(item => {
        if (item.adjuntoObligatorio === 'S') {
            auxFiles.push(item.adjuntoDescripcion);
        }
    });
    if (auxFiles.length > 0) {
        let aux = [];
        files?.forEach(item => {
            if (item?.adjuntoObligatorio === 'S') {
                aux.push(auxFiles?.some(x => x === item.btnNombre));
            }
        });
        if (aux.length > 0 && Object.values(aux)?.every(x => x === true)) {
            response = true;
        }
    } else {
        response = true;
    }
    return response;
};

export const validateDate = (date, dateLow) => {
    let today = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    today.setHours(0, 0, 0, 0);
    dateLow.setHours(0, 0, 0, 0);
    return (dateLow.getTime() >= today.getTime());
};

export const isAuthenticated = () => {
    const oidcStorage = JSON.parse(localStorage.getItem(`oidc.user:${window.env.API_IDENTITY}:${window.env.CLIENT_ID_MOBILE}`));
    return (!!oidcStorage && !!oidcStorage.access_token);
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
};

export const setModelAppointmentToRepeatOrModify = (element, dispatch, userCode, modelNextAppointment, history) => {
    /* Si se repite un turno de favoritos, el numero de afiliado y el nombre es el del usuario logueado. */
    dispatch(setModelToSaveAppointment({
        ...modelNextAppointment?.modelAppointment,
        especialidadCodigo: element?.especialidadCodigo,
        especialidadDescripcion: element?.especialidadDescripcion,
        medicoCodigo: element?.medicoCodigo,
        medicoDescripcion: element?.medicoDescripcion,
        afiliadoDescripcion: element?.pacienteNombreApellido ?? window.localStorage.getItem("userName")?.replace("_", " "),
        afiliadoCodigo: element?.pacienteNumero ?? userCode,
        centroMedico: element?.tipoAtencion == "R" ? "" : element?.sucursalCodigo,
        nombreCentroMedico: element?.tipoAtencion == "R" ? "" : element?.sucursalDescripcion,
        modalidadTurno: element?.tipoAtencion == "R" ? "Telemedicina" : "Presencial",
        preference: 2,
        turnoAnterior: element?.turnoNumero
    }));

    if (element && element.turnoNumero) {
        const appointmentDate = new Date(element.turnoFechHora).getTime();
        const actualDate = Date.now();
        const isNextAppointment = appointmentDate > actualDate;

        dispatch(setModifyAppointment(isNextAppointment ? "modify" : "repeat"));
    } else {
        dispatch(setModifyAppointment("repeat"));
    }

    const specialtiesList = {
        numeroPersona: userCode,
        centroMedico: element?.tipoAtencion == "R" ? 0 : element?.sucursalCodigo,
        tipoAtencion: element?.tipoAtencion == "R" ? "TELEMEDICINA" : "WEB",
        parametroLlamado: 0,
    };

    dispatch(getSpecialties(specialtiesList));
    dispatch(setActualStep(3));
    history.push('/AppointmentHome');
};

export const checkNewAppointments = (listToday, listNext, setTodayAppointment, setNext, nextComplet, setNextComplet, dispatch) => {
    const userCode = parseInt(window.localStorage.getItem("numeroPersona"));
    const paramFav = {
        numeroPersona: userCode,
        count: 9999,
        skip: 0,
    };
    listToday.map((item) => {
        var dateItem = new Date(item.turnoFechHora);
        var previus15 = new Date(item.turnoFechHora);
        previus15.setMinutes(dateItem.getMinutes() - 15);
        var dateToday = new Date(Date.now());
        if ((previus15.getTime() <= dateToday.getTime()) && (dateToday.getTime() < dateItem.getTime()) && item.tipoAtencion == "R") {
            const newToday = listToday?.filter((x) => x.turnoNumero != item.turnoNumero);
            setTodayAppointment(newToday);
            if (listNext.length != 0 && !listNext[listNext.indexOf(item)].proximoCercano) {
                listNext[listNext.indexOf(item)].proximoCercano = true;
                if (nextComplet) {
                    nextComplet[nextComplet.indexOf(item)].proximoCercano = true;
                    setNextComplet(nextComplet);
                }
                setNext(listNext);
            }
        }
        var next5Min = new Date(item.turnoFechHora);
        next5Min.setMinutes(20);
        if (dateToday.getTime() > next5Min.getTime()) {
            dispatch(getAppointmentFavorite(paramFav));
            dispatch(getAfilliatedAppointmentsNext({
                numeroPersona: userCode,
                solicitudTurnos: "PROXIMOS",
                count: 9999,
                skip: 0,
            }));
        }
    });
};

export const returnHomeAppointmentHome = (history) => (e) => {
    e.preventDefault();
    history.push('/AppointmentHome');
};

export function handleExpandAccordion(title, e, isExpanded, setExpanded) {
    setExpanded(isExpanded ? title : false);
}

export function messageModalFunction(
    postChangeReducer, setModalTitle,
    setModalDescription,
    setModalIcon,
    setErrorClass,
    classes,
    setOpenSendSuccess,
    mail
) {
    if (postChangeReducer?.confirmacion) {
        setModalTitle('TU GESTIÓN SE HA INICIADO');
        setModalDescription(`Será respondida al email: ${mail}`);
        setModalIcon(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
        setErrorClass('');
    } else if (!postChangeReducer?.confirmacion && postChangeReducer?.confirmacion !== undefined) {
        setOpenSendSuccess(true);
        setModalTitle('Ocurrió un error');
        setModalDescription(postChangeReducer?.mensajes[0]?.mensaje ?? 'Por favor, intente nuevamente');
        setModalIcon(<CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
        setErrorClass(classes.backgroundError);
    }
}

export const validateFilesForButttonEnviar = (listOfAttachment, file) => {
    const obligatoryFiles = listOfAttachment?.filter(attachment => attachment?.adjuntoObligatorio == "S");
    return (obligatoryFiles?.length === file?.length)
}

export function showBtn(listOfAttachment, file, err, motive) {
    const isClear = (valor) => valor === "";
    var response = true;
    if (((Object.values(err)).every(isClear)) && validateFilesForButttonEnviar(listOfAttachment, file) && motive !== "") {
        response = false;
    }
    return response;
}

export function enableEnviarBtn(listOfAttachment, file, err, motive, plan) {
    const isClear = (valor) => valor === "";
    var response = true;
    if (((Object.values(err)).every(isClear)) && validateFiles(listOfAttachment, file) && motive !== "" && plan !== "") {
        response = false;
    }
    return response;
}

export const handleChangeEmail = (setMail, setMailError) => (e) => {
    const { value } = e.target;
    const isValid = validateEmail(value)
    if (isValid) {
        setMailError('')
    }
    else {
        setMailError('No cumple con el formato permitido: mail@mail.com');
    }
    setMail(value);
};

export const redirectHrefTo = (path) => {
    window.open(path, '_blank')
};

export const handleGoToPersonalMedicalData = () => {
    window.location.href = '/PersonalMedicalData';
};

export const handleGoToPersonalData = () => {
    window.location.href = '/PersonalData';
};

export const handleGoToMedicalData = () => {
    window.location.href = '/MedicalData';
};

export const handleGoToBilling = () => {
    window.location.href = '/Billing';
};

export const validateEmail = email => {
    return /[a-z0-9!#$%&'*{1,5}/=?^_`{|}~-]{1,5}(?:\.[a-z0-9!#$%&'*{1,5}/=?^_`{|}~-]{1,5})*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.){1,5}[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email);
}

export function notAccessToThisModule() {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div style={{ color: '#0075c9', margin: '10%' }}>
                No tenés acceso al módulo
            </div>
        </div>
    );
}

// Verifica si el plan del afiliado tiene acceso al modulo
export function verifyPermission(limitReducer, plan, moduleName, setPermission) {
    const allModules = 'Todos';
    const filterByPlan = limitReducer?.filter(x => x?.plan === plan || x?.plan === 'TODOS');
    const access = filterByPlan[0]?.modulesAndStatus?.filter(y => y?.value === moduleName || y?.value === allModules);

    if (access !== null && access !== undefined) {
        if (access[0]?.type === 'Deshabilitado' || access[0]?.value === allModules) {
            setPermission(false);
        } else if (access[0]?.type === 'Habilitado') {
            setPermission(true);
        }
    }
    if (access?.length === 0 || access === undefined) {
        setPermission(true);
    }
}

export const handleGoToProgramsHome = () => {
    window.location.href = '/ProgramsHome';
}

export const showErrorMessageModal = (errorMessage, openError, setOpenError, classNameOne, classNameTwo) => {
    return (
        <div className={classNameOne} style={{ left: "20px" }}>
            <ModalProfile
                observation={
                    <>
                        <section
                            style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                            <CancelIcon
                                style={{ fontSize: "50px", marginBottom: "20px" }}
                                className={classNameTwo} />
                            <div style={{ display: 'flex', justifyContent: 'center', flex: '1 1 100%', textAlign: "center" }}>
                                <span>{errorMessage}</span>
                            </div>
                        </section>
                    </>
                }
                open={openError}
                type='cancel'
                onClose={setOpenError}
            />
        </div>
    );
}

export const showMessageModal = (errorMessage, openError, setOpenError, classNameOne, classNameTwo, classNameThree, handleClick) => {
    return (
        <div className={classNameOne} style={{ left: "20px" }}>
            <Dialog
                disableEscapeKeyDown={true}
                onBackdropClick={false}
                onClose={setOpenError}
                aria-labelledby="customized-dialog-title"
                open={openError}
                id="modalTurn">
                <Box display="flex" id="cancelModal">
                    <div style={{ alignSelf: 'center' }}>
                        <DialogContent className={classNameThree}>
                            <section
                                style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                                <CancelIcon
                                    style={{ fontSize: "50px", marginBottom: "20px" }}
                                    className={classNameTwo} />
                                <div style={{ display: 'flex', justifyContent: 'center', flex: '1 1 100%', textAlign: "center" }}>
                                    <span>{errorMessage}</span>
                                </div>
                                <CustomButton
                                    title="ACEPTAR"
                                    onClick={handleClick}
                                    style={{
                                        marginTop: "30px",
                                        fontSize: "12px",
                                        padding: "9px 12px",
                                        background: '#fff',
                                        color: '#0075c9',
                                        boxShadow: '0px 2px 8px 0 #585858'
                                    }}
                                />
                            </section>
                        </DialogContent>
                    </div>
                </Box>
            </Dialog>
        </div>
    );
}