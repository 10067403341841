import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

/**agregar el llamado a las funciones que se comunican con la api */
function* asyncGetAllCredentials({ payload }) {
    try {
        var response = yield call(API.getAllCredentials, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_ALL_CREDENTIALS, response, message: '' });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_GET_ALL_CREDENTIALS,
            message: error.message,
        });
    }
}

// Watchers
export default function* credentialSaga() {
    yield takeLatest(actionTypes.GET_ALL_CREDENTIALS, asyncGetAllCredentials);
}
