import React from "react";
import useStyles from "../../assets/styles/styles";
import CustomButton from "../../controls/Button/CustomButton";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import { useSelector } from 'react-redux';

const FooterDrawer = ({ handleOpenCancelModal, handleNext, withOutNext, confirm, customClassDiv, disabledNext = false }) => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const modelNextAppointment = useSelector(state => state.appointmentsReducer);
    const flow = modelNextAppointment?.modelAppointment?.appointmentFlow;

    function setTitle() {
        let text = "Siguiente";
        if (confirm) {
            text = "Confirmar";
        }
        return text;
    }

    const showNextButton = () => {
        return (!withOutNext && modelNextAppointment?.modelAppointment?.especialidadDescripcion !== "ATENCION CLINICA ESPONTANEA") || (flow == 'medico' || flow == 'especialidad');
    }

    return (
        <>
            <div className={`${classes.buttonsAskTurn} ${customClassDiv}`}>
                {screen.sWidth > 768 &&
                    (!withOutNext &&
                        <CustomButton
                            title={setTitle()}
                            customClass={`${classes.lightLinearGradient} ${classes.fontBold}`}
                            style={{ minWidth: "100px", fontSize: "12px", marginRight: "10px", marginBottom: "20px" }}
                            onClick={handleNext}
                        />)
                }

                <CustomButton
                    title={"Cancelar"}
                    customClass={`${classes.whiteAndBlueBtn} ${classes.fontBold}`}
                    style={{ minWidth: "100px", marginRight: "10px", marginBottom: "20px" }}
                    onClick={handleOpenCancelModal}
                />

                {screen.sWidth <= 768 &&
                    ( showNextButton() &&
                        <CustomButton
                            title={setTitle()}
                            customClass={` ${classes.lightLinearGradient} ${classes.fontBold}`}
                            style={{ minWidth: "100px", fontSize: "12px", marginRight: "10px", marginBottom: "20px" }}
                            disabled={disabledNext}
                            onClick={handleNext}
                        />)
                }

            </div>
        </>
    );
};

export default React.memo(FooterDrawer);