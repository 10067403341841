import Iframe from "react-iframe";
import Menu from "../Menu/Menu";
import useStyles from "../../assets/styles/styles";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import Header from "../GenericHeader/Header";

const HeadquartersFrequentQuestions = () => {
    const classes = useStyles();
    const size = useResizeScreen();
    return (
        <>
            <Menu />
            {
                size.screen.sWidth > 768 &&
                <Header title="Preguntas Frecuentes" />
            }
            <div className={classes.Redirect} style={{marginLeft: "-8px", marginTop: (size.screen.sWidth > 768 ? "0" : "-20px")}}>
                <Iframe sandbox='' scrolling="yes" src='https://www.oslpasteur.com.ar/PortalHTML5/accesos/PreguntasFrecuentes/index.html?centrosmedicos'
                    className={classes.iframe} />
            </div>

        </>
    );
};

export default HeadquartersFrequentQuestions;
