import React, { useEffect, useState } from "react";
import useStyles from "../../assets/styles/styles";
import { Typography } from "@material-ui/core";
import FooterDrawer from "./FooterDrawer";
import { useDispatch, useSelector } from "react-redux";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import {
  getNextAvailableAppointmentsDoctor,
  setModelToSaveAppointment,
} from "../../redux/actions/appointmentsActions";
import ButtonPhones from "../ButtonPhones/ButtonPhones";


const PhoneShiftsDrawer = (props) => {
  const { activeStep, handleOpenCancelModal, handleBack, medicoCodigo} = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const phones = useSelector((state) => state.phonesReducer.phoneShift);
  const personalDataReducer = useSelector((state) => state.personalDataReducer);

  const modelNextAppointment = useSelector(
    (state) => state.appointmentsReducer
  );
  const [modelAppointment, setModelAppointment] = useState(
    modelNextAppointment?.modelAppointment
  );
  const specialties = useSelector(
    (state) => state.medicalCenterReducer?.specialtyList
  );
  const doctorSchedule = useSelector(
    (state) => state.appointmentsReducer?.nextAvailableAppointmentsDoctor
  );

  const paramAll = {
    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
    medicoCodigo: "TODOS",
    count: 0,
    skip: 0,
    tipoAtencion: modelNextAppointment?.modelAppointment?.modalidadTurno == 'Telemedicina' ? 'TELEMEDICINA' : 'PRESENCIAL'
  };

  const { screen } = useResizeScreen();

  const [description, setDescription] = useState([""]);



  const [phonesShow, setPhoneShow] = useState([]);

  useEffect(() => {
    setPhoneShow(
      phones.filter((x) =>
        x.planCode.includes(
          personalDataReducer?.fullData?.afiliado?.planDescripcion
        )
      )
    );
  }, [personalDataReducer]);



  useEffect(() => {
    dispatch(
      getNextAvailableAppointmentsDoctor({
        ...paramAll,
        medicoCodigo: medicoCodigo?.toString(),
      })
    );

  }, [modelAppointment?.medicoCodigo, modelAppointment.preference, medicoCodigo]);

  useEffect(() => {
    validateSpecialties(
      specialties,
      setDescription,
      modelAppointment
    );
    let turnosLibres = [];
    if (modelAppointment?.modalidadTurno === 'Telemedicina') {
      turnosLibres = doctorSchedule?.turnosLibres?.filter(element => element?.tipoAtencion === "R");
    } else {
      turnosLibres = doctorSchedule?.turnosLibres?.filter(element => element?.tipoAtencion === "P");
    }

  }, [doctorSchedule, specialties, medicoCodigo]);

  useEffect(() => {
    if (description !== undefined) {
      const descriptionsAppointment = description[0]?.observaciones;
      const preparation = description[0]?.preparacion;
      setModelAppointment({
        ...modelAppointment,
        observaciones: descriptionsAppointment,
        preparacion: preparation,
      });
      dispatch(
        setModelToSaveAppointment({
          ...modelAppointment,
          observaciones: descriptionsAppointment,
          preparacion: preparation,
        })
      );
    }
  }, [description, medicoCodigo]);



  return (
    <>
      <Typography className={classes.titleTurn} style={{ marginTop: "30px" }}>
        {props.titles.map((titles) => titles.title6)}
      </Typography>
      <div style={{ marginTop: "10px" }}>
        <ButtonPhones listItems={phonesShow}/>
      </div>
      {screen.sWidth <= 768 && (
        <FooterDrawer
          activeStep={activeStep}
          handleBack={handleBack}
          withOutNext={true}
          handleOpenCancelModal={handleOpenCancelModal}
        />
      )}
    </>
  );
};

export default React.memo(PhoneShiftsDrawer, (prevState, nextState) => {
  return (
    prevState.modelNextAppointment?.modelAppointment?.medicoCodigo ===
    nextState.modelNextAppointment?.modelAppointment?.medicoCodigo
  );
});


function validateSpecialties(
  specialties,
  // setOpenModal,
  setDescription,
  modelAppointment
) {
  let aux = "";
  if (specialties) {
    aux = specialties?.agenda?.filter(
      (x) => x.medicoCodigo === modelAppointment?.medicoCodigo
    );
    if (aux?.length === 0) {
      // setOpenModal(true);
    }
    setDescription(
      specialties?.agenda?.filter(
        (x) =>
          x.medicoCodigo === modelAppointment?.medicoCodigo &&
          x.especialidadCodigo === modelAppointment?.especialidadCodigo
      )
    );
  }
}