import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetCapAndCopayLinkByPlanCode({ payload }) {
    try {
        let response = yield call(API.getCapAndCopayLinkByPlanCode, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_CAPANDCOPAYLINK_BY_PLANCODE, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_CAPANDCOPAYLINK_BY_PLANCODE, message: error.message });
    }
}

export default function* capsAndCopayLinksSaga() {
    yield takeLatest(actionTypes.GET_CAPANDCOPAYLINK_BY_PLANCODE, asyncGetCapAndCopayLinkByPlanCode);
}
