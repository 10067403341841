import { Typography, IconButton } from "@material-ui/core";
import useStyles from '../../assets/styles/styles';
import { useResizeScreen } from "../../hooks/useResizeScreen";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const FormHeader = ({ title, subtitle, handleGoTo }) => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const setBackgroundMobile = () => {
        let cssClass = '';
        if (screen.sWidth <= 768)
            cssClass = classes.titlePersonalDataForm;
        return cssClass;
    };
    return (
        <div
            className={`${setBackgroundMobile()}`}
            style={{ paddingTop: '5px', display: "flex", alignItems: "center", paddingBottom: screen.sWidth <= 768 && "5px", marginBottom: "10px" }}>
            {
                screen.sWidth <= 768 &&
                <IconButton onClick={handleGoTo}>
                    <ArrowBackIcon className={classes.ShowMoreArrowIcon} style={{ fontSize: "25px", marginBottom: "5px" }} />
                </IconButton>
            }
            <div>
                <Typography className={`${classes.titleTurn} ${classes.titleBox}`} style={{ textTransform: 'uppercase', paddingTop: '20px', paddingBottom: "10px", marginLeft: screen.sWidth < 768 ? '25px': 'auto'  }}>
                    {title}
                </Typography>
                <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.subtitleBox}`} style={{ paddingTop: '20px', paddingBottom: "10px"  }}>
                    {subtitle}
                </Typography>
            </div>
        </div>);
};

export default FormHeader;
