import React, { useEffect, useState } from "react";
import useStyles from "../../../assets/styles/styles";
import Menu from "../../Menu/Menu";
import { CircularProgress, Zoom, ClickAwayListener, Divider, FormControl, IconButton, InputLabel, TextField, Typography } from "@material-ui/core";
import {
    getDataFilterAppointment,
} from "../../../redux/actions/appointmentsActions";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../../controls/Select/CustomSelect";
import NotFound from "../../HomeLogged/NotFound";
import CustomButton from "../../../controls/Button/CustomButton";
import MovementsCard from "../../Billing/Movements/MovementsCard";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { handleGoToMedicalData } from "../../../utils/utilsFunctions";
import TuneIcon from '@material-ui/icons/Tune';
import { LocalizationProvider, MobileDatePicker } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { format } from "date-fns";
import ModalActivities from "../../DialogActivities/ModalActivities";
import HeaderCard from "../../Billing/Movements/HeaderCard";
import { es } from "date-fns/locale";
import ControlPointOutlinedIcon from '@material-ui/icons/ControlPointOutlined';
import ModalProfile from "../../DialogProfile/Modal";
import { getRecipe } from "../../../redux/actions/medicalDataAction";
import { useResizeScreen } from "../../../hooks/useResizeScreen";





const Prescriptions = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [openPicker, setOpenPicker] = useState(false);
    const [openModalRepeatAppointment, setOpenModalRepeatAppointment] = useState(false);
    const appointments = useSelector((state) => state.appointmentsReducer);//
    const [groupFamily, setGroupFamily] = useState([]);
    const recipe = useSelector((state) => state.medicalDataReducer.recipe);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState();
    const [selectedItem, setSelectedItem] = useState(null);
    const screenSize = useResizeScreen();
    const userCode = parseInt(window.localStorage.getItem("numeroPersona"));
    const [filterLayout, setFilterLayout] = useState('horizontal');
    const [selectedClassification, setSelectedClassification] = useState("RECETAS");
    const [selectStatusClassification, setSelectStatusClassification] = useState("INGRESADA");

    const persona = {
        numeroPersona: userCode,
        parametroLlamado: "FILTRO RECETAS"
    };
    const param = {
        numeroPersona: userCode,
        estadoReceta: "TODOS",
        clasificacionReceta: "TODOS",
        count: 1000,
        skip: 0,
    };




    const statusClassification = [
        { value: "INGRESADA", label: "INGRESADA" },
        { value: "CONSUMIDA", label: "CONSUMIDA" },
        { value: "TODOS", label: "TODOS" },
    ]


    const classificationOptions = [
        { value: "RECETAS", label: "Recetas" },
        { value: "PRESCRIPCIONES", label: "Prescripciones" },
        { value: "ORDENES", label: "Ordenes" },
    ];

    const [selectAffilliate, setSelectAffilliate] = useState({
        value: 0,
        label: ""
    });



    useEffect(() => {
        const updateLayout = () => {
            if (window.innerWidth <= 768) {
                setFilterLayout('vertical');
            } else {
                setFilterLayout('horizontal');
            }
        };

        window.addEventListener('resize', updateLayout);
        updateLayout();
        return () => window.removeEventListener('resize', updateLayout);
    }, []);


    useEffect(() => {
        dispatch(getDataFilterAppointment(persona));
        dispatch(getRecipe(param));
    }, []);


    useEffect(() => {
        setLoading(false);
    }, [recipe])


    useEffect(() => {
        dispatch(getRecipe({
            numeroPersona: selectAffilliate?.value,
            count: 9999,
            skip: 0,
            fechaDesde: format(dateStart, "yyyy-MM-dd"),
            fechaHasta: format(dateEnd, "yyyy-MM-dd"),
            estadoReceta: selectStatusClassification, 
            clasificacionReceta: selectedClassification
        }));
        setLoading(true);

    }, [dateStart, dateEnd, selectAffilliate?.value, selectedClassification, selectStatusClassification]);


    useEffect(() => {
        let listGroupFamily = appointments?.filtro?.map((val) => {
            return {
                value: parseInt(val?.numeroPersona), label: val?.apellidoNombre
            };
        });

        setGroupFamily(listGroupFamily);


    }, [appointments.filtro]);

    useEffect(() => {
        groupFamily?.forEach((item) => {
            if (item.value == userCode) {
                setSelectAffilliate({ value: item.value, label: item.label });
            }
        });
    }, [groupFamily]);


    function Responsive(screenSize) {
        let width = screenSize.screen.sWidth;
        if (width <= 768) return "mobile";

    }

    const searchCard = recipe?.data?.recetas?.filter((item) => {
        if (selectStatusClassification === "TODOS") {
            return true;
        } else {
            return item.recetaEstado === selectStatusClassification;
        }
    });

    const handleChangeSelect = () => e => {
        var nameAffilliated = groupFamily.filter((x) => x.value == e.target.value);
        setSelectAffilliate({ value: nameAffilliated[0].value, label: nameAffilliated[0].label });
    };

    const handleClickAway = () => {
        setOpenPicker(false);
    };

    const handleClick = () => {
        setOpenPicker((prev) => !prev);
    };

    const handleClickOpenDownloadModal = (item) => (e) => {
        e.preventDefault();
        setSelectedItem(item);
        setOpenModalRepeatAppointment(true); // Abre el mismo modal que se usaba para repetir turno
    };



    const handleClickOpenViewModal = (item) => (e) => {
        setSelectedItem(item);
        setOpenModal(true);
    };

    const handleClassificationChange = (e) => {
        setSelectedClassification(e.target.value);
    };


    const handleClassificationChangeState = (e) => {
        setSelectStatusClassification(e.target.value);
    };

    const handleClickConfirmDownload = () => {
        // setSelectedItem 

        if (selectedItem?.recetaLink) {
            window.open(selectedItem.recetaLink, '_blank');
        }
        setOpenModalRepeatAppointment(false); // Cierra el modal
    };


    const inlineRecipeStyles = {
        downloadRecipeButton: { marginTop: "10px", width: "60%", fontSize: "7px", fontWeight: '600' },

        buttonZoneSection: { display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flex: 1 },

        buttonZoneContainer: { marginLeft: '10px', alignItems: 'center', marginBottom: 'auto' },

        seeMoreButton: { fontSize: '12px', fontWeight: "600", color: '#0075c9' },

        seeMoreIcon: { marginLeft: "5px", marginTop: '-2px', fontSize: '13px' },

        localizationProvider: { width: "100px", zIndex: 2 },

        descriptionModal: { display: 'flex', justifyContent: 'center', margin: '5px' }
    }


    //modal de informacion
    const showmodal = (detail) => {
        const ordenDescripcionParts = detail?.ordenDescripcion?.split(', ');
        const recetaDetalle = detail?.recetaDetalle?.[0];
        return (
            <ModalProfile
                open={openModal}
                onClose={() => setOpenModal(false)}
                title={
                    <>
                        <Typography className={classes.titleDialogTitle}>
                            {detail?.pacienteNombreApellido}
                        </Typography>
                        <Typography className={classes.subTitleDialogTitle}>
                            {detail?.recetaClasificacion}
                        </Typography>
                    </>
                }
                description={
                    <div style={{ marginTop: '5px' }}>
                        <Typography style={{ fontSize: '14px', paddingTop: '2px', paddingBottom: '2px' }} className={`${classes.txtCardDescription}`}>
                            ESTADO: <span style={{ color: '#0075c9' }}>{detail?.recetaEstado}</span>
                        </Typography>
                        <ul style={{ paddingLeft: '17px' }}>
                            {ordenDescripcionParts?.map((part, index) => {
                                const [title, info] = part.split(': ');

                                return (
                                    <li key={index}>
                                        <Typography style={{ fontSize: '14px', paddingTop: '1px', paddingBottom: '1px', whiteSpace: 'pre-line', lineHeight: '2.0' }} className={`${classes.txtCardDescription}`}>
                                            <span style={{ fontWeight: 'bold', color: 'black' }}>{title}:</span> {info}
                                        </Typography>
                                    </li>
                                );
                            })}
                            <li>
                                <Typography style={{ fontSize: '14px', paddingTop: '1px', paddingBottom: '1px' }} className={`${classes.txtCardDescription}`}>
                                    {detail?.recetaMedicoNombre && `${detail.recetaMedicoNombre}, `}
                                    {detail?.recetaMedicoMatriculaTipo && `${detail.recetaMedicoMatriculaTipo} `}
                                    {detail?.recetaMedicoMatriculaNumero && `${detail.recetaMedicoMatriculaNumero}`}
                                </Typography>
                            </li>
                            <li>
                                <Typography style={{ fontSize: '14px', paddingTop: '1px', paddingBottom: '1px' }} className={`${classes.txtCardDescription}`}>
                                    {recetaDetalle?.recetaDetalleProductoDescripcion && `${recetaDetalle.recetaDetalleProductoDescripcion}, `}
                                    {recetaDetalle?.recetaDetalleProductoCantidad != null && `${recetaDetalle.recetaDetalleProductoCantidad}`}
                                </Typography>
                            </li>
                        </ul>
                    </div>
                }
            />
        );
    };


    //tarjetas
    return (
        <>
            <Menu />
            <div className={`${classes.divMyHistoryRecipe}`}>
                <>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {Responsive(screenSize) == "mobile" &&
                                <IconButton onClick={handleGoToMedicalData}>
                                    <ArrowBackIcon className={classes.iconFilter} />
                                </IconButton>
                            }
                            <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency}`} style={{ textTransform: 'uppercase' }}>
                                ORDENES MEDICAS
                            </Typography>
                        </div>
                        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                            <div style={{ position: "relative", margin: screen.sWidth < 768 ? '15px 13px' : '20px 30px 20px 0' }}>
                                <CustomButton
                                    onClick={handleClick}
                                    style={{ fontSize: '12px' }}
                                    customClass={`${classes.customHeaderBtn} ${classes.btnFilterAppointment}`}
                                    icon={<TuneIcon style={{ marginRight: "5px" }} className={classes.iconFilter} />}
                                />
                                {openPicker && (
                                    <Zoom in={openPicker} >
                                        <div className={`${classes.dropdown}`} >
                                            <Typography className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>{"PERIODO"}</Typography>
                                            <Divider classes={{ root: classes.dividerFilter }} />

                                            <div className={`${classes.headerFiltro} ${classes.marginTop}`}>
                                                <FormControl style={{ width: "150px", paddingTop: "10px" }} className={classes.datePicker}>
                                                    <InputLabel className={`${classes.txtSubtitleCalendar} ${classes.fontSize15}`} shrink  >{"DESDE"}</InputLabel>
                                                    <div className={classes.top22} style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <LocalizationProvider style={{ width: "100px", zIndex: 2 }} locale={es} dateAdapter={AdapterDateFns}  >
                                                            <MobileDatePicker
                                                                cancelText="Cancelar"
                                                                toolbarTitle=""
                                                                value={dateStart}
                                                                onChange={(newValue) => { setDateStart(newValue); }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                format="dd/MM/YYYY"
                                                            />
                                                        </LocalizationProvider>
                                                        <CalendarTodayIcon style={{ alignSelf: "center" }} />
                                                    </div>
                                                </FormControl>

                                                <FormControl style={{ width: "150px", marginLeft: "10px", paddingTop: "10px" }} className={classes.datePicker} >
                                                    <InputLabel className={`${classes.txtSubtitleCalendar} ${classes.fontSize15}`} shrink  >{"HASTA"}</InputLabel>
                                                    <div className={classes.top22} style={{ display: "flex", justifyContent: "space-between", }}>
                                                        <LocalizationProvider style={{ zIndex: 2 }} locale={es} dateAdapter={AdapterDateFns}  >
                                                            <MobileDatePicker
                                                                cancelText="Cancelar"
                                                                toolbarTitle=""
                                                                value={dateEnd}
                                                                onChange={(newValue) => { setDateEnd(newValue); }}
                                                                variant='outlined'
                                                                renderInput={(params) => <TextField {...params} />}
                                                                format="dd/MM/YYYY"
                                                            />
                                                        </LocalizationProvider>
                                                        <CalendarTodayIcon style={{ alignSelf: "center" }} />
                                                    </div>
                                                </FormControl>

                                            </div>
                                        </div>
                                    </Zoom>
                                )}
                            </div>
                        </ClickAwayListener>
                    </div>
                </>
                <Divider className={`${classes.formDivider} ${classes.dividerAppointment}`} style={{ margin: "20px" }} />
                <div className={`${classes.divAccordionPrograms} ${classes.divAffilliateMyHistory}`} style={{ minWidth: "240px !important" }}>
                    {filterLayout === 'horizontal' ? (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <FormControl style={{ width: "150px", paddingTop: "10px" }}>
                                <CustomSelect
                                    className={`${classes.afilliateSelect}`}
                                    list={groupFamily}
                                    selected={selectAffilliate?.value}
                                    label="AFILIADO"
                                    onChange={handleChangeSelect()}
                                    style={{ marginTop: "20px" }}
                                />
                            </FormControl>
                            <FormControl style={{ width: "150px", paddingTop: "10px", marginLeft: "5px" }}>
                                <CustomSelect
                                    className={`${classes.afilliateSelect}`}
                                    list={classificationOptions}
                                    selected={selectedClassification}
                                    label="TIPO"
                                    onChange={handleClassificationChange}
                                    style={{ marginTop: "20px" }}
                                />
                            </FormControl>
                            <FormControl style={{ width: "250px", paddingTop: "10px", marginRight: "50px" }}>
                                <CustomSelect
                                    className={`${classes.afilliateSelect}`}
                                    list={statusClassification}
                                    selected={selectStatusClassification}
                                    label="Estado"
                                    onChange={handleClassificationChangeState}
                                    style={{ marginTop: "20px" }}
                                />
                            </FormControl>
                        </div>
                    ) : (
                        <>
                            <FormControl style={{ width: "350px", paddingTop: "10px", marginTop: "-10px" }}>
                                <CustomSelect
                                    className={`${classes.afilliateSelect}`}
                                    list={groupFamily}
                                    selected={selectAffilliate?.value}
                                    label="AFILIADO"
                                    onChange={handleChangeSelect()}
                                    style={{ marginTop: "20px" }}
                                />
                            </FormControl>
                            <FormControl style={{ width: "150px", paddingTop: "10px", marginRight: "100px" }}>
                                <CustomSelect
                                    className={`${classes.afilliateSelect}`}
                                    list={classificationOptions}
                                    selected={selectedClassification}
                                    label="TIPO"
                                    onChange={handleClassificationChange}
                                    style={{ marginTop: "20px" }}
                                />
                            </FormControl>
                            <FormControl style={{ width: "150px", paddingTop: "10px" }}>
                                <CustomSelect
                                    className={`${classes.afilliateSelect}`}
                                    list={statusClassification}
                                    selected={selectStatusClassification}
                                    label="Estado"
                                    onChange={handleClassificationChangeState}
                                    style={{ marginTop: "20px" }}
                                />
                            </FormControl>
                        </>
                    )}
                </div>
                <div className={`${classes.divSectionMovements}`} style={{ display: "flex", justifyContent: "center", paddingLeft: "20px", paddingRight: "20px" }}>
                    {loading ?
                        <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
                        : searchCard?.length > 0 ?
                            searchCard?.map((item, i) => (
                                < MovementsCard
                                    key={item?.i}
                                    count={item?.count}
                                    index={i}
                                    headerIcon={<HeaderCard day={item?.fechaFormateada?.nombreDia} numberDay={item?.fechaFormateada?.dia} month={Number.parseInt(item?.recetaFechaVigenciaDesde?.split('-')[1])} year={item?.recetaFechaVigenciaDesde ? new Date(item?.recetaFechaVigenciaDesde).toLocaleDateString('es-AR', { year: 'numeric' }) : ""} />}
                                    description={
                                        <div style={{ marginTop: "5px" }}>

                                            <Typography style={{ fontWeight: "600", color: '#0075c9', paddingBottom: "10px" }} className={`${classes.txtCardDescription} `}>
                                                <span>
                                                    <span style={{ fontWeight: "bold", color: "black" }}>{item?.recetaClasificacion} </span>

                                                </span>
                                            </Typography>
                                            <Typography className={`${classes.txtCardDescription}`}>
                                                {item?.pacienteNombreApellido}
                                            </Typography>
                                            <Typography style={{ fontWeight: "600", paddingBottom: "10px" }} className={`${classes.txtCardDescription}`}>
                                                <span>
                                                    <strong style={{ color: "black" }}>VTO:</strong>{" " +
                                                        new Date(item?.recetaFechaVigenciaHasta).toLocaleDateString('es-AR', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                                                </span>
                                            </Typography>
                                            <Typography style={{ fontWeight: "600", paddingBottom: "10px" }} className={`${classes.txtCardDescription} `}>
                                                <span style={{ fontWeight: "bold", color: "black" }}>ESTADO:
                                                    <span style={{ color: '#0075c9' }} > {item?.recetaEstado} </span>
                                                </span>
                                            </Typography>
                                            {/* modificar item de vt y estado y agregar clase a csss */}
                                        </div>
                                    }
                                    buttonZone={

                                        <section style={inlineRecipeStyles.buttonZoneSection}>
                                            <section className={`${classes.sectionButtons} ${classes.btnAuthorizationCard}`} style={inlineRecipeStyles.buttonZoneContainer}>
                                                <div>
                                                    <IconButton onClick={handleClickOpenViewModal(item)} customClass={`${classes.customHeaderBtn}`}>
                                                        <Typography style={inlineRecipeStyles.seeMoreButton} className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>
                                                            VER
                                                        </Typography>
                                                        <ControlPointOutlinedIcon style={inlineRecipeStyles.seeMoreIcon} />
                                                    </IconButton>
                                                </div>
                                                <div style={{ paddingRight: '60px' }}>
                                                </div>
                                                <div>
                                                    {item.recetaEstado !== "CONSUMIDA" && (
                                                        <CustomButton
                                                            title="DESCARGAR"
                                                            customClass={classes.lightLinearGradient}
                                                            style={{ marginTop: "35px", width: "100%", fontSize: "0.5rem", fontWeight: '800' }}
                                                            onClick={handleClickOpenDownloadModal(item)}
                                                        />
                                                    )}
                                                </div>



                                            </section>
                                        </section>
                                    }

                                />
                            ))
                            :
                            <NotFound customMsg={
                                <div className={classes.contentNotFound}>
                                    <span>No tenés historial de {selectedClassification}</span>
                                </div>}
                                wsItem={searchCard}
                                fullItem={searchCard} />
                    }
                </div>
                {
                    openModalRepeatAppointment &&
                    <ModalActivities
                        title={selectedItem?.recetaClasificacion}
                        description={'¿Estás seguro que deseas descargar la prescripción seleccionado?'}
                        open={openModalRepeatAppointment}
                        onClose={setOpenModalRepeatAppointment}
                        modalconfirm={true}
                        modalLogout={false}
                        buttonType={'submit'}
                        handleClick={handleClickConfirmDownload}
                    />
                }
                {
                    <>{showmodal(selectedItem)}</>
                }
            </div>
        </>
    );
};

export default Prescriptions;