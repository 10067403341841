import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../assets/styles/styles";
import "./modalactivities.css";
import CustomButton from "../../controls/Button/CustomButton";
import { useDispatch } from "react-redux";
import { updateTag } from "../../redux/actions/personPlayerActions";
import { CircularProgress } from "@mui/material";

const ModalActivities = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { title, description, observation, info, open, onClose, modalconfirm, crossDarkColor,
    functionConfirm, itemConfirm, modalLogout, buttonType, handleClick, empty, customClass, modalRegister, customBackgroundDialog, customBackgroundDialogContent, loading, ...other } = props;

  const handleClose = () => {
    onClose(false);
  };

  const removePlayerAndLogout = () => {
    let playerId = window.localStorage.getItem('playerId');
    let user = window.localStorage.getItem('email');
    dispatch(updateTag({ user: user, playerId: playerId, action: "LOGOUT" }));
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.replace("/logout");
  };

  const confirm = () => {
    functionConfirm(itemConfirm);
    onClose(false);
  };

  const buttonNotConfirm = () => {
    return modalRegister ?
      <CustomButton
        title="NO"
        className={classes.buttonNotConfirmModal}
        onClick={handleClick}
      /> :
      <CustomButton
        title="NO"
        className={classes.buttonNotConfirmModal}
        onClick={handleClose}
      />;
  };



  const modalLogoutFunction = () => {
    let jsxBtn = (
      <CustomButton
        title="SI"
        className={`${classes.buttonConfirmModal} ${classes.lightLinearGradient}`}
        onClick={confirm}
      />);
    if (modalLogout) {
      jsxBtn = (
        <CustomButton
          title="SI"
          className={`${classes.buttonConfirmModal} ${classes.lightLinearGradient}`}
          onClick={removePlayerAndLogout}

        />
      );
    } else if (buttonType === 'submit') {
      jsxBtn = (
        <CustomButton
          title="SI"
          className={`${classes.buttonConfirmModal} ${classes.lightLinearGradient}`}
          type={buttonType}
          onClick={handleClick}
        />
      );
    }
    else if (modalRegister) {
      jsxBtn = (
        <CustomButton
          title="SI"
          className={`${classes.buttonConfirmModal} ${classes.lightLinearGradient}`}
          type={buttonType}
          onClick={handleClose}
        />
      );
    }

    return jsxBtn;
  };

  const bodyModal = () => {
    const DEFAULT = (
      <>
      </>
    );
    const BODY = {
      'modalconfirm': (
        <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
          {modalLogoutFunction()}
          {buttonNotConfirm()}
        </div>
      ),
      'empty': ''
    };
    let response = DEFAULT;
    if (modalconfirm)
      response = BODY['modalconfirm'];
    else if (empty)
      response = BODY['empty'];

    return response;
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        id="backDropActivities"
        {...other}
      >
        <div id="modalActivities">
          {!modalRegister &&
            <IconButton
              aria-label="close"
              className={crossDarkColor ? classes.closeActivitiesButton : classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          }

          <DialogTitle
            id="customized-dialog-title"
            className={`${classes.contentDialogTitle} ${customClass} ${customBackgroundDialog}`}
            style={{ textAlign: "center" }}
            onClose={handleClose}
          >
            <Typography className={classes.titleDialogActivities} style={{ fontWeight: "600" }}>
              {title}
            </Typography>
          </DialogTitle>

          <DialogContent className={`${classes.contentDialogTitle} ${customClass} ${customBackgroundDialogContent}`} style={{ paddingTop: "10px" }}>
            <Typography style={{ paddingBottom: "20px" }}>
              {
                loading ? (
                  <div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.whiteCircularProgress}`}>
                    <CircularProgress />
                  </div>
                ) : description
              }
            </Typography>
            <Typography style={{ paddingBottom: "20px" }}>
              {observation}
            </Typography>
            {bodyModal()}
          </DialogContent>

        </div>
      </Dialog>
    </>
  );
};
export default ModalActivities;
