import { Typography } from "@material-ui/core";
import ModalProfile from "../../DialogProfile/Modal";
const DoesntFreeAppointmentModal = ({ openModal, onClose }) => {
    return (
        <>
            {openModal &&
                <ModalProfile
                    type='cancel'
                    title={
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                Repetir turno
                            </div>
                        </>
                    }
                    observation={
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography>
                                El médico seleccionado no tiene turnos disponibles.
                            </Typography>
                        </div>
                    }
                    description={''}
                    open={openModal}
                    onClose={onClose}
                    close={true}
                />
            }
        </>
    );
};

export default DoesntFreeAppointmentModal;