import { IconButton, Typography } from "@material-ui/core";
import { Fragment, useCallback, useState, useEffect } from "react";
import WizardLine from "../../AskTurn/WizardLine";
import DrawerRefundsSteps from "./DrawerRefundsSteps";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useStyles from '../../../assets/styles/styles';
import Header from "../../GenericHeader/Header";
import { clearModelRefundsRequest, clearStateRefunds } from "../../../redux/actions/refundsActions";
import ModalProfile from "../../../components/DialogProfile/Modal";
import CustomButton from "../../../controls/Button/CustomButton";
import { useDispatch } from "react-redux";


const RefundsRequest = () => {
    const [openModal, setOpenModal] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const dispatch = useDispatch();
    const classes = useStyles();
    const list = [
        {
            step0: ""
        },
        {
            step1: ""
        },
        {
            step2: ""
        },
        {
            step3: ""
        },
        {
            step4: ""
        },
    ];


    const handleOpenCancelModal = useCallback(() => {
        setOpenModal(prevState => !prevState);
    }, [openModal]);

    const handleNext = () => {
        let active = activeStep + 1;
        setActiveStep(active);
    };

    const handleBack = useCallback(() => {
        let active = activeStep - 1;
        setActiveStep(active);
    }, [activeStep]);

    const handleClose = () => {
        dispatch(clearModelRefundsRequest());
        window.location.href = '/Refunds';
    };

    useEffect(() => {
      return () => {
        dispatch(clearStateRefunds());
      }
    }, []);

    return (
        <Fragment>
            <Header title={"Reintegros"} zIndexTitle={100} positionTitle={"relative"} />
            <section style={{ padding: '20px' }}>
                {activeStep !== 0 &&
                    <>
                        <div style={{ position: "absolute", zIndex: "110", top: "105px", left: "10px" }}>
                            <IconButton onClick={handleBack}>
                                <ArrowBackIcon className={classes.ShowMoreArrowIcon} style={{ fontSize: "30px" }} />
                            </IconButton>
                        </div>
                    </>
                }
                <WizardLine list={list} activeStep={activeStep} />
                <DrawerRefundsSteps isWeb={true} open={true}
                    activeStep={activeStep} setActiveStep={setActiveStep}
                    handleOpenCancelModal={handleOpenCancelModal}
                    handleCloseDrawer={handleClose}
                    handleBack={handleBack} handleNext={handleNext} />
            </section>
            {
                openModal &&
                <ModalProfile
                    type='cancel'
                    title={
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                Cancelar reintegro
                            </div>
                        </>
                    }
                    observation={
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography>
                                ¿Está seguro que desea salir?
                            </Typography>
                        </div>
                    }
                    description={
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <CustomButton
                                title={"Si"}
                                customClass={`${classes.buttonConfirmModal} ${classes.lightLinearGradient}`}
                                onClick={handleClose}
                            />
                            <CustomButton
                                title={"No"}
                                customClass={`${classes.buttonNotConfirmModal}`}
                                onClick={handleOpenCancelModal}
                            />
                        </div>
                    }
                    open={openModal}
                    onClose={setOpenModal}
                />
            }
        </Fragment>
    );
};

export default RefundsRequest;