import React from "react";
import { Button } from "@material-ui/core";
import useStyles from "../../assets/styles/styles";

const CustomButton = (props) => {
  const classes = useStyles();
  const {
    title,
    icon,
    typeButton,
    customClass,
    customStyles,
    onClick,
    ...other
  } = props;

  return (
    <Button
      className={`${customClass ? customClass : ""} ${classes.customButton}`}
      onClick={onClick}
      {...other}
    >
      {icon}
      {title}
    </Button>
  );
};

export default React.memo(CustomButton);
