import React, { useEffect, useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import jwt_decode from 'jwt-decode';
import { AppInsightsContextProvider } from "./utils/AppInsightsContext";

// App Routes
import { Routes } from "./Routes";
import { AuthProvider } from './auth/providers/authProvider';
import ConfirmModal from './components/PersonalData/ConfirmModal';

const App = ({ store }) => {
	const [modalTokenExpired, setModalTokenExpired] = useState(false);

	useEffect(() => {
		if (window.localStorage.getItem("access_token")) {
			const token = jwt_decode(window.localStorage.getItem('access_token'));
			// si el token expiró, desloguear 
			if (token.exp < Date.now() / 1000) {
				setModalTokenExpired(true);
			}
		}
	}, []);

	useEffect(() => {
		if (modalTokenExpired && window.localStorage.getItem("access_token")) {
			setTimeout(() => {
				window.localStorage.clear();
				window.sessionStorage.clear();
				window.location.replace("/logout");
			}, 4000);
		}
	}, [modalTokenExpired]);

	return (
		<AppInsightsContextProvider>
			<AuthProvider>
				<Provider store={store}>
					<StyledEngineProvider injectFirst>
						<BrowserRouter children={Routes} basename={"/"} />
						<ConfirmModal
							isOpen={modalTokenExpired}
							description='Tu sesión ha expirado. Por favor, iniciá sesión nuevamente.'
							titleModal=""
							errorClass='{descriptionModal.setModalErrorClass}'
							escapeKeyDown={true}
							backdropClick={true}
						/>
					</StyledEngineProvider>
				</Provider>
			</AuthProvider>
		</AppInsightsContextProvider>
	);

};

App.propTypes = {
	store: PropTypes.object.isRequired
};

export default App;
