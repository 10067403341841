import * as actionTypes from '../consts/actionTypes';

const initialState = {
    virtualDoctorResponse: [],
    msgError: ''
};

export default function virtualDoctorReducer(state = initialState, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_POST_CALL_DOCTOR]: responseToReturn('virtualDoctorResponse'),
        [actionTypes.FAILURE_POST_CALL_DOCTOR]: responseToReturn('virtualDoctorResponse'),
        [actionTypes.CLEAN_VIRTUAL_DOCTOR_MODEL]: cleanModel(),
    };

    function responseToReturn(typeState) {
        let res = { ...state };
        if (action.response) {
            res = { ...state, [typeState]: action.response.data.data, msgError: action.message };
        }
        if (action.message) {
            res = { ...state, msgError: action.message };
        }
        return res;
    }

    function cleanModel(){
        return { ...state, virtualDoctorResponse: [] };
    }

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}