/* MEDICAL RECORDS */
export const SET_SELECTED_FILTERS_VALUES = "SET_SELECTED_FILTERS_VALUES";
export const SET_MODEL_FILTERS_VALUES = "SET_MODEL_FILTERS_VALUES";

export const SUCCESS_GET_FILTERS_VALUES = "SUCCESS_GET_FILTERS_VALUES";
export const FAILURE_GET_FILTERS_VALUES = "FAILURE_GET_FILTERS_VALUES";
export const GET_FILTERS_VALUES = "GET_FILTERS_VALUES";

export const GET_LOCALIDADES_BARRIOS = "GET_LOCALIDADES_BARRIOS";
export const SUCCESS_GET_LOCALIDADES_BARRIOS =
  "SUCCESS_GET_LOCALIDADES_BARRIOS";
export const FAILURE_GET_LOCALIDADES_BARRIOS = "FAILURE_GET_LOCALIDADES_BARRIOS";

export const GET_ESPECIALIDADES_ESTUDIOS_COMPLEMENTARIOS =
  "GET_ESPECIALIDADES_ESTUDIOS_COMPLEMENTARIOS";
export const SUCCESS_GET_ESPECIALIDADES_ESTUDIOS_COMPLEMENTARIOS =
  "SUCCESS_GET_ESPECIALIDADES_ESTUDIOS_COMPLEMENTARIOS";
export const FAILURE_GET_ESPECIALIDADES_ESTUDIOS_COMPLEMENTARIOS = "FAILURE_GET_ESPECIALIDADES_ESTUDIOS_COMPLEMENTARIOS";

export const SUCCESS_GET_PRESTADORES_RESULT = "SUCCESS_GET_PRESTADORES_RESULT";
export const GET_PRESTADORES_CARTILLA = "GET_PRESTADORES_CARTILLA";
export const FAILURE_GET_PRESTADORES_RESULT = "FAILURE_GET_PRESTADORES_RESULT";

export const GET_PERFIL_COMPLETO = "GET_PERFIL_COMPLETO";
export const SUCCESS_GET_PERFIL_COMPLETO = "SUCCESS_GET_PERFIL_COMPLETO";
export const FAILURE_GET_PERFIL_COMPLETO = "FAILURE_GET_PERFIL_COMPLETO";

export const CLEAN_MODEL_MEDICAL_RECORDS = 'CLEAN_MODEL_MEDICAL_RECORDS';

export const SET_LOADING_MEDICAL_RECORDS = 'SET_LOADING_MEDICAL_RECORDS';

export const CLEAN_PERFIL_COMPLETO = 'CLEAN_PERFIL_COMPLETO';

/****************************************************** */

/* TURNOS */
export const GET_AFILLIATED_APPOINTMENTS = "GET_AFILLIATED_APPOINTMENTS";
export const SUCCESS_GET_AFILLIATED_APPOINTMENTS =
  "GET_AFILLIATED_APPOINTMENTS";
export const FAILURE_GET_AFILLIATED_APPOINTMENTS = "FAILURE_GET_AFILLIATED_APPOINTMENTS";

export const GET_AFILLIATED_APPOINTMENTS_NEXT =
  "GET_AFILLIATED_APPOINTMENTS_NEXT";
export const SUCCESS_GET_AFILLIATED_APPOINTMENTS_NEXT =
  "GET_AFILLIATED_APPOINTMENTS_NEXT";
export const FAILURE_GET_AFILLIATED_APPOINTMENTS_NEXT = "FAILURE_GET_AFILLIATED_APPOINTMENTS_NEXT";

export const GET_AFILLIATED_APPOINTMENTS_RECORD =
  "GET_AFILLIATED_APPOINTMENTS_RECORD";
export const SUCCESS_GET_AFILLIATED_APPOINTMENTS_RECORD =
  "GET_AFILLIATED_APPOINTMENTS_RECORD";
export const FAILURE_GET_AFILLIATED_APPOINTMENTS_RECORD = "FAILURE_GET_AFILLIATED_APPOINTMENTS_RECORD";

export const GET_APPOINTMENTS_FAVORITE = "GET_APPOINTMENTS_FAVORITE";
export const SUCCES_GET_APPOINTMENTS_FAVORITE =
  "SUCCES_GET_APPOINTMENTS_FAVORITE";
export const FAILURE_GET_APPOINTMENTS_FAVORITE = "FAILURE_GET_APPOINTMENTS_FAVORITE";

export const GET_DATA_GROUP_FAMILY = "GET_DATA_GROUP_FAMILY";
export const SUCCES_GET_DATA_GROUP_FAMILY = "SUCCES_GET_DATA_GROUP_FAMILY";
export const FAILURE_GET_DATA_GROUP_FAMILY = "FAILURE_GET_DATA_GROUP_FAMILY";

export const GET_DATA_FILTER_APPOINTMENT = "GET_DATA_FILTER_APPOINTMENT";
export const SUCCES_GET_DATA_FILTER_APPOINTMENT = "SUCCES_GET_DATA_FILTER_APPOINTMENT";
export const FAILURE_GET_DATA_FILTER_APPOINTMENT = "FAILURE_GET_DATA_FILTER_APPOINTMENT";

export const GET_APPOINTMENTS_CANCELED = "GET_APPOINTMENTS_CANCELED";
export const SUCCES_GET_APPOINTMENTS_CANCELED =
  "SUCCES_GET_APPOINTMENTS_CANCELED";
export const FAILURE_GET_APPOINTMENTS_CANCELED = "FAILURE_GET_APPOINTMENTS_CANCELED";

export const GET_APPOINTMENTS_AB = "GET_APPOINTMENTS_AB";
export const SUCCES_GET_APPOINTMENTS_AB = "SUCCES_GET_APPOINTMENTS_AB";
export const FAILURE_GET_APPOINTMENTS_AB = "FAILURE_GET_APPOINTMENTS_AB";

export const GET_ANSWER_QUESTION = "GET_ANSWER_QUESTION";
export const SUCCES_ANSWER_QUESTION = "SUCCES_GET_ANSWER_QUESTION";
export const FAILURE_ANSWER_QUESTION = "FAILURE_GET_ANSWER_QUESTION";

export const GET_SPECIALTY_LIST = "GET_SPECIALTY_LIST";
export const SUCCESS_GET_SPECIALTY_LIST = "SUCCESS_GET_SPECIALTY_LIST";
export const FAILURE_GET_SPECIALTY_LIST = "FAILURE_GET_SPECIALTY_LIST";

export const GET_MEDICAL_CENTERS = "GET_MEDICAL_CENTERS";
export const SUCCESS_GET_MEDICAL_CENTERS = "SUCCESS_GET_MEDICAL_CENTERS";
export const FAILURE_GET_MEDICAL_CENTERS = "FAILURE_GET_MEDICAL_CENTERS";

export const GET_NEXT_AVAILABLE_APPOINTMENTS = "GET_NEXT_AVAILABLE_APPOINTMENTS";
export const SUCCESS_GET_NEXT_AVAILABLE_APPOINTMENTS = "SUCCESS_GET_NEXT_AVAILABLE_APPOINTMENTS";
export const FAILURE_GET_NEXT_AVAILABLE_APPOINTMENTS = "FAILURE_GET_NEXT_AVAILABLE_APPOINTMENTS";

export const GET_NEXT_AVAILABLE_APPOINTMENTS_DOCTOR = "GET_NEXT_AVAILABLE_APPOINTMENTS_DOCTOR";
export const SUCCESS_GET_NEXT_AVAILABLE_APPOINTMENTS_DOCTOR = "SUCCESS_GET_NEXT_AVAILABLE_APPOINTMENTS_DOCTOR";
export const FAILURE_GET_NEXT_AVAILABLE_APPOINTMENTS_DOCTOR = "FAILURE_GET_NEXT_AVAILABLE_APPOINTMENTS_DOCTOR";

export const GET_STEPS = 'GET_STEPS';
export const NEXT_STEP = "NEXT_STEP";
export const PREV_STEP = "PREV_STEP";
export const SET_ACTUAL_STEP = 'SET_ACTUAL_STEP';

export const SET_MODEL_TO_SAVE_APPOINTMENT = 'SET_MODEL_TO_SAVE_APPOINTMENT';
export const GET_MODEL_TO_SAVE_APPOINTMENT = 'GET_MODEL_TO_SAVE_APPOINTMENT';

export const SAVE_APPOINTMENT = 'SAVE_APPOINTMENT';
export const SUCCESS_SAVE_APPOINTMENT = 'SUCCESS_SAVE_APPOINTMENT';
export const FAILURE_SAVE_APPOINTMENT = 'FAILURE_SAVE_APPOINTMENT';

export const GET_EMERGENCY_NUMBERS = 'GET_EMERGENCY_NUMBERS';
export const SUCCESS_GET_EMERGENCY_NUMBERS = 'SUCCESS_GET_EMERGENCY_NUMBERS';
export const FAILURE_GET_EMERGENCY_NUMBERS = 'FAILURE_GET_EMERGENCY_NUMBERS';

export const GET_APPOINTMENTS_CONFIRMED = 'GET_APPOINTMENTS_CONFIRMED';
export const SUCCESS_GET_APPOINTMENTS_CONFIRMED = 'SUCCESS_GET_APPOINTMENTS_CONFIRMED';
export const FAILURE_GET_APPOINTMENTS_CONFIRMED = 'FAILURE_GET_APPOINTMENTS_CONFIRMED';

export const GET_FULL_GROUP_FAMILY_APPOINTMENT = "GET_FULL_GROUP_FAMILY_APPOINTMENT";
export const SUCCESS_GET_FULL_GROUP_FAMILY_APPOINTMENT = "SUCCESS_GET_FULL_GROUP_FAMILY_APPOINTMENT";
export const FAILURE_GET_FULL_GROUP_FAMILY_APPOINTMENT = "FAILURE_GET_FULL_GROUP_FAMILY_APPOINTMENT";

export const CLEAR_MODEL_APPOINTMENT = "CLEAR_MODEL_APPOINTMENT";

export const CLEAR_AFFILLIATE = "CLEAR_AFFILLIATE";

export const SET_MODIFY_APPOINTMENT = "SET_MODIFY_APPOINTMENT";

export const CLEAR_APPOINTMENT_MSG_ERROR = "CLEAR_APPOINTMENT_MSG_ERROR";

export const GET_SPECIALTIES_LIST = "GET_SPECIALTIES_LIST";
export const SUCCESS_GET_SPECIALTIES_LIST = "SUCCESS_GET_SPECIALTIES_LIST";
export const FAILURE_GET_SPECIALTIES_LIST = "FAILURE_GET_SPECIALTIES_LIST";

export const GET_DOCTORS_LIST = "GET_DOCTORS_LIST";
export const SUCCESS_GET_DOCTORS_LIST = "SUCCESS_GET_DOCTORS_LIST";
export const FAILURE_GET_DOCTORS_LIST = "FAILURE_GET_DOCTORS_LIST";

export const GET_FREE_DOCTOR_APPOINTMENTS_LIST = "GET_FREE_DOCTOR_APPOINTMENTS_LIST";
export const SUCCESS_GET_FREE_DOCTOR_APPOINTMENTS_LIST = "SUCCESS_GET_FREE_DOCTOR_APPOINTMENTS_LIST";
export const FAILURE_GET_FREE_DOCTOR_APPOINTMENTS_LIST = "FAILURE_GET_FREE_DOCTOR_APPOINTMENTS_LIST";

/****************************************************** */

/*********************PERSONAL DATA******************** */

export const GET_TYPE_OF_CONTACT = 'GET_TYPE_OF_CONTACT';
export const SUCCESS_GET_TYPE_OF_CONTACT = 'SUCCESS_GET_TYPE_OF_CONTACT';
export const FAILURE_GET_TYPE_OF_CONTACT = 'FAILURE_GET_TYPE_OF_CONTACT';

export const GET_TYPE_OF_CONTACT_PHONE = 'GET_TYPE_OF_CONTACT_PHONE';
export const SUCCESS_GET_TYPE_OF_CONTACT_PHONE = 'SUCCESS_GET_TYPE_OF_CONTACT_PHONE';
export const FAILURE_GET_TYPE_OF_CONTACT_PHONE = 'FAILURE_GET_TYPE_OF_CONTACT_PHONE';

export const GET_TYPE_OF_CONTACT_MAIL = 'GET_TYPE_OF_CONTACT_MAIL';
export const SUCCESS_GET_TYPE_OF_CONTACT_MAIL = 'SUCCESS_GET_TYPE_OF_CONTACT_MAIL';
export const FAILURE_GET_TYPE_OF_CONTACT_MAIL = 'FAILURE_GET_TYPE_OF_CONTACT_MAIL';

export const GET_CONTACTDATA_OF_AFFILIATED = 'GET_CONTACTDATA_OF_AFFILIATED';
export const SUCCESS_GET_CONTACTDATA_OF_AFFILIATED = 'SUCCESS_GET_CONTACTDATA_OF_AFFILIATED';
export const FAILURE_GET_CONTACTDATA_OF_AFFILIATED = 'FAILURE_GET_CONTACTDATA_OF_AFFILIATED';

export const GET_TYPES_DOCUMENTS = "GET_TYPES_DOCUMENTS";
export const SUCCESS_GET_TYPES_DOCUMENTS = "SUCCESS_GET_TYPES_DOCUMENTS";
export const FAILURE_GET_TYPES_DOCUMENTS = "FAILURE_GET_TYPES_DOCUMENTS";

export const GET_CIVIL_STATUS = "GET_CIVIL_STATUS";
export const SUCCESS_GET_CIVIL_STATUS = "SUCCESS_GET_CIVIL_STATUS";
export const FAILURE_GET_CIVIL_STATUS = "FAILURE_GET_CIVIL_STATUS";

export const GET_NATIONALITY = "GET_NATIONALITY";
export const SUCCESS_GET_NATIONALITY = "SUCCESS_GET_NATIONALITY";
export const FAILURE_GET_NATIONALITY = "FAILURE_GET_NATIONALITY";

export const GET_FULL_DATA = 'GET_FULL_DATA';
export const SUCCESS_GET_FULL_DATA = 'SUCCESS_GET_FULL_DATA';
export const FAILURE_GET_FULL_DATA = 'FAILURE_GET_FULL_DATA';

export const ABM_FULL_DATA = 'ABM_FULL_DATA';
export const SUCCESS_ABM_FULL_DATA = 'SUCCESS_ABM_FULL_DATA';
export const FAILURE_ABM_FULL_DATA = 'FAILURE_ABM_FULL_DATA';

export const GET_CREDENTIALS = 'GET_CREDENTIALS';
export const SUCCESS_GET_CREDENTIALS = 'SUCCESS_GET_CREDENTIALS';
export const FAILURE_GET_CREDENTIALS = 'FAILURE_GET_CREDENTIALS';

export const GET_HOBBIES = 'GET_HOBBIES';
export const SUCCESS_GET_HOBBIES = 'SUCCESS_GET_HOBBIES';
export const FAILURE_GET_HOBBIES = 'FAILURE_GET_HOBBIES';

export const GET_DEPORTES = 'GET_DEPORTES';
export const SUCCESS_GET_DEPORTES = 'SUCCESS_GET_DEPORTES';
export const FAILURE_GET_DEPORTES = 'FAILURE_GET_DEPORTES';
/****************************************************** */

/* REQUEST SUCCESS */
export const SEND_REQUEST_SUCCESS = "SEND_REQUEST_SUCCESS";

/* REQUEST FAILURE */
export const SEND_REQUEST_FAILURE = "SEND_REQUEST_FAILURE";
export const RESET_SEND_REQUEST_FAILURE = "RESET_SEND_REQUEST_FAILURE";
/****************************************************** */

export const GET_DEMO_REQUEST = "GET_DEMO_REQUEST";
export const GET_DEMO_SUCCESS = "GET_DEMO_SUCCESS";

/*********************MEDICAL DATA******************** */
/* PHONES */
export const GET_PHONES = "GET_PHONES";
export const GET_SHIFT_PHONES = "GET_SHIFT_PHONES";
export const SUCCES_SHIFT_PHONES = "SUCCES_SHIFT_PHONES";
export const FAILURE_SHIFT_PHONES = "FAILURE_SHIFT_PHONES";
export const SUCCES_PHONES = "SUCCES_GET_PHONES";
export const FAILURE_PHONES = "FAILURE_GET_PHONES";

//WeightAndHeight

export const GET_DATA_WHEIGHT_HEIGHT = "GET_DATA_WHEIGHT_HEIGHT";
export const SUCCESS_GET_DATA_WHEIGHT_HEIGHT = "SUCCESS_GET_DATA_WHEIGHT_HEIGHT";
export const FAILURE_GET_DATA_WHEIGHT_HEIGHT = "FAILURE_GET_DATA_WHEIGHT_HEIGHT";

export const AB_DATA_WHEIGHT_HEIGHT = "AB_WHEIGHT_HEIGHT";
export const SUCCESS_AB_WHEIGHT_HEIGHT = "SUCCESS_AB_WHEIGHT_HEIGHT";
export const FAILURE_AB_WHEIGHT_HEIGHT = "FAILURE_AB_WHEIGHT_HEIGHT";

//VitalParameters

export const GET_VITAL_PARAMETERS = "GET_VITAL_PARAMETERS";
export const SUCCESS_GET_VITAL_PARAMETERS = "SUCCESS_GET_VITAL_PARAMETERS";
export const FAILURE_GET_VITAL_PARAMETERS = "FAILURE_GET_VITAL_PARAMETERS";

export const AB_VITAL_PARAMETERS = "AB_VITAL_PARAMETERS";
export const SUCCESS_AB_VITAL_PARAMETERS = "SUCCESS_AB_VITAL_PARAMETERS";
export const FAILURE_AB_VITAL_PARAMETERS = "FAILURE_AB_VITAL_PARAMETERS";


/*PROGRAMS*/

export const GET_PROGRAMS = "GET_PROGRAMS";
export const SUCCESS_GET_PROGRAMS = "SUCCESS_GET_PROGRAMS";
export const FAILURE_GET_PROGRAMS = "FAILURE_GET_PROGRAMS";

/*Allergies*/

export const GET_ALLERGIES_PARAMS = "GET_ALLERGIES_PARAMS";
export const SUCCESS_GET_ALLERGIES_PARAMS = "SUCCESS_GET_ALLERGIES_PARAMS";
export const FAILURE_GET_ALLERGIES_PARAMS = "FAILURE_GET_ALLERGIES_PARAMS";

export const GET_AFFILIATED_ALLERGIES = "GET_AFFILIATED_ALLERGIES";
export const SUCCESS_GET_AFFILIATED_ALLERGIES = "SUCCESS_GET_AFFILIATED_ALLERGIES";
export const FAILURE_GET_AFFILIATED_ALLERGIES = "FAILURE_GET_AFFILIATED_ALLERGIES";

export const ABM_ALLERGIES = "ABM_ALLERGIES";
export const SUCCESS_ABM_ALLERGIES = "SUCCESS_ABM_ALLERGIES";
export const FAILURE_ABM_ALLERGIES = "FAILURE_ABM_ALLERGIES";

export const GET_AFFILIATED_PHONES = "GET_AFFILIATED_PHONES";
export const SUCCESS_GET_AFFILIATED_PHONES = "SUCCESS_GET_AFFILIATED_PHONES";
export const FAILURE_GET_AFFILIATED_PHONES = "FAILURE_GET_AFFILIATED_PHONES";

export const ABM_MD_PHONES = "ABM_MD_PHONES";
export const SUCCESS_ABM_MD_PHONES = "SUCCESS_ABM_MD_PHONES";
export const FAILURE_ABM_MD_PHONES = "FAILURE_ABM_MD_PHONES";

/* FACTOR GROUP */

export const ABM_FACTOR_GROUP = 'ABM_FACTOR_GROUP';
export const SUCCESS_ABM_FACTOR_GROUP = 'SUCCESS_ABM_FACTOR_GROUP';
export const FAILURE_ABM_FACTOR_GROUP = 'FAILURE_ABM_FACTOR_GROUP';

export const GET_FACTOR_GROUP = 'GET_FACTOR_GROUP';
export const SUCCESS_GET_FACTOR_GROUP = 'SUCCESS_GET_FACTOR_GROUP';
export const FAILURE_GET_FACTOR_GROUP = 'FAILURE_GET_FACTOR_GROUP';

export const GET_FACTOR_GROUP_PARAMS = 'GET_FACTOR_GROUP_PARAMS';
export const SUCCESS_GET_FACTOR_GROUP_PARAMS = 'SUCCESS_GET_FACTOR_GROUP_PARAMS';
export const FAILURE_GET_FACTOR_GROUP_PARAMS = 'FAILURE_GET_FACTOR_GROUP_PARAMS';

/* CHRONIC DISEASES */

export const ABM_CHRONIC_DISEASES = 'ABM_CHRONIC_DISEASES';
export const SUCCESS_ABM_CHRONIC_DISEASES = 'SUCCESS_ABM_CHRONIC_DISEASES';
export const FAILURE_ABM_CHRONIC_DISEASES = 'FAILURE_ABM_CHRONIC_DISEASES';

export const GET_CHRONIC_DISEASES = 'GET_CHRONIC_DISEASES';
export const SUCCESS_GET_CHRONIC_DISEASES = 'SUCCESS_GET_CHRONIC_DISEASES';
export const FAILURE_GET_CHRONIC_DISEASES = 'FAILURE_GET_CHRONIC_DISEASES';

export const GET_CHRONIC_DISEASES_PARAMS = 'GET_CHRONIC_DISEASES_PARAMS';
export const SUCCESS_GET_CHRONIC_DISEASES_PARAMS = 'SUCCESS_GET_CHRONIC_DISEASES_PARAMS';
export const FAILURE_GET_CHRONIC_DISEASES_PARAMS = 'FAILURE_GET_CHRONIC_DISEASES_PARAMS';

/* CHRONIC MEDICATION */

export const ABM_CHRONIC_MEDICATION = 'ABM_CHRONIC_MEDICATION';
export const SUCCESS_ABM_CHRONIC_MEDICATION = 'SUCCESS_ABM_CHRONIC_MEDICATION';
export const FAILURE_ABM_CHRONIC_MEDICATION = 'FAILURE_ABM_CHRONIC_MEDICATION';

export const GET_CHRONIC_MEDICATION = 'GET_CHRONIC_MEDICATION';
export const SUCCESS_GET_CHRONIC_MEDICATION = 'SUCCESS_GET_CHRONIC_MEDICATION';
export const FAILURE_GET_CHRONIC_MEDICATION = 'FAILURE_GET_CHRONIC_MEDICATION';

/* VACCINE */

export const GET_VACCINE = 'GET_VACCINE';
export const SUCCESS_GET_VACCINE = 'SUCCESS_GET_VACCINE';
export const FAILURE_GET_VACCINE = 'FAILURE_GET_VACCINE';



/* Receta  */

export const GET_RECIPE = 'GET_RECIPE';
export const SUCCESS_GET_RECIPE = 'SUCCESS_GET_RECIPE';
export const FAILURE_GET_RECIPE = 'FAILURE_GET_RECIPE';



/****************************************************** */


/**LABORATORY STUDIES */
export const GET_LABORATORY_STUDIES = "GET_LABORATORY_STUDIES";
export const SUCCESS_GET_LABORATORY_STUDIES = "SUCCESS_GET_LABORATORY_STUDIES";
export const FAILURE_GET_LABORATORY_STUDIES = "FAILURE_GET_LABORATORY_STUDIES";

export const GET_DETAIL_LABORATORY_STUDIES = "GET_DETAIL_LABORATORY_STUDIES";
export const SUCCESS_GET_DETAIL_LABORATORY = "SUCCESS_GET_DETAIL_LABORATORY_STUDIES";
export const FAILURE_GET_DETAIL_LABORATORY = "FAILURE_GET_DETAIL_LABORATORY_STUDIES";

/**REGISTER */
export const GET_DATA_REGISTER_USER = "GET_DATA_REGISTER";
export const SUCCESS_GET_DATA_REGISTER_USER = "SUCCESS_GET_DATA_REGISTER_USER";
export const FAILURE_GET_DATA_REGISTER_USER = "FAILURE_GET_DATA_REGISTER_USER";

export const ADD_DATA_REGISTER_USER = "ADD_DATA_REGISTER_USER";
export const SUCCESS_ADD_DATA_REGISTER_USER = "SUCCESS_ADD_DATA_REGISTER_USER";
export const FAILURE_ADD_DATA_REGISTER_USER = "FAILURE_ADD_DATA_REGISTER_USER";

export const DELETE_DATA_REGISTER_USER = "DELETE_DATA_REGISTER_USER";
export const SUCCESS_DELETE_DATA_REGISTER_USER = "DELETE_DATA_REGISTER_USER";
export const FAILURE_DELETE_DATA_REGISTER_USER = "FAILURE_DELETE_DATA_REGISTER_USER";

export const MODIFY_DATA_REGISTER_USER = "MODIFY_DATA_REGISTER_USER";
export const SUCCESS_MODIFY_DATA_REGISTER_USER = "MODIFY_DATA_REGISTER_USER";
export const FAILURE_MODIFY_DATA_REGISTER_USER = "FAILURE_MODIFY_DATA_REGISTER_USER";

export const REGISTER_USER = 'REGISTER_USER';
export const SUCCESS_REGISTER_USER = 'SUCCESS_REGISTER_USER';
export const FAILURE_REGISTER_USER = 'FAILURE_REGISTER_USER';

export const VERIFY_REGISTER = 'VERIFY_REGISTER';
export const SUCCESS_VERIFY_REGISTER = 'SUCCESS_VERIFY_REGISTER';
export const FAILURE_VERIFY_REGISTER = 'FAILURE_VERIFY_REGISTER';

/*******COVERAGE*******/

export const GET_TYPE_COVERAGE_PARAMETERS = 'GET_TYPE_COVERAGE_PARAMETERS';
export const SUCCESS_GET_TYPE_COVERAGE_PARAMETERS = 'SUCCESS_GET_TYPE_COVERAGE_PARAMETERS';
export const FAILURE_GET_TYPE_COVERAGE_PARAMETERS = 'FAILURE_GET_TYPE_COVERAGE_PARAMETERS';

export const GET_AREA_PARAMETERS = 'GET_AREA_PARAMETERS';
export const SUCCESS_GET_AREA_PARAMETERS = 'SUCCESS_GET_AREA_PARAMETERS';
export const FAILURE_GET_AREA_PARAMETERS = 'FAILURE_GET_AREA_PARAMETERS';

export const POST_REFUND_COVERAGE = 'POST_REFUND_COVERAGE';
export const SUCCESS_POST_REFUND_COVERAGE = 'SUCCESS_POST_REFUND_COVERAGE';
export const FAILURE_POST_REFUND_COVERAGE = 'FAILURE_POST_REFUND_COVERAGE';

export const GET_ALL_COVERAGE_PLANS = 'GET_ALL_COVERAGE_PLANS';
export const SUCCESS_GET_ALL_COVERAGE_PLANS = 'SUCCESS_GET_ALL_COVERAGE_PLANS';
export const FAILURE_GET_ALL_COVERAGE_PLANS = 'FAILURE_GET_ALL_COVERAGE_PLANS';

/********VIRTUAL DOCTOR*********/

export const POST_CALL_DOCTOR = 'POST_CALL_DOCTOR';
export const SUCCESS_POST_CALL_DOCTOR = 'SUCCESS_POST_CALL_DOCTOR';
export const FAILURE_POST_CALL_DOCTOR = 'FAILURE_POST_CALL_DOCTOR';

/********CONTACT US*********/

export const POST_CONTACT_US = 'POST_CONTACT_US';
export const SUCCESS_POST_CONTACT_US = 'SUCCESS_POST_CONTACT_US';
export const FAILURE_POST_CONTACT_US = 'FAILURE_POST_CONTACT_US';


/*********************NOTIFICATIONS******************** */

export const GET_ALL_NOTIFICATION = 'GET_ALL_NOTIFICATION';
export const SUCCESS_ALL_GET_NOTIFICATION = 'SUCCESS_ALL_GET_NOTIFICATION';
export const FAILURE_ALL_GET_NOTIFICATION = 'FAILURE_ALL_GET_NOTIFICATION';

export const GET_UPDATE_ALL_NOTIFICATION = 'GET_UPDATE_ALL_NOTIFICATION';
export const SUCCESS_UPDATE_ALL_GET_NOTIFICATION = 'SUCCESS_UPDATE_ALL_GET_NOTIFICATION';
export const FAILURE_UPDATE_ALL_GET_NOTIFICATION = 'FAILURE_UPDATE_ALL_GET_NOTIFICATION';

export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const SUCCESS_GET_NOTIFICATION = 'SUCCESS_GET_NOTIFICATION';
export const FAILURE_GET_NOTIFICATION = 'FAILURE_GET_NOTIFICATION';

export const MODIFY_NOTIFICATION = 'MODIFY_NOTIFICATION';
export const SUCCESS_MODIFY_NOTIFICATION = 'SUCCESS_MODIFY_NOTIFICATION';
export const FAILURE_MODIFY_NOTIFICATION = 'FAILURE_MODIFY_NOTIFICATION';
export const RESET_MODIFY_NOTIFICATION = 'RESET_MODIFY_NOTIFICATION';


export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

export const SELECT_NOTIFICATION = 'SELECT_NOTIFICATION';
export const SUCCESS_SELECT_NOTIFICATION = 'SUCCESS_SELECT_NOTIFICATION';
export const FAILURE_SELECT_NOTIFICATION = 'FAILURE_SELECT_NOTIFICATION';

export const SET_COUNT_NOTIFICATION = 'SET_COUNT_NOTIFICATION';
export const SUCCESS_SET_COUNT_NOTIFICATION = 'SUCCESS_SET_COUNT_NOTIFICATION';
export const FAILURE_SET_COUNT_NOTIFICATION = 'FAILURE_SET_COUNT_NOTIFICATION';

export const HASH_NOTIFICATION = 'HASH_NOTIFICATION';
export const SUCCESS_HASH_NOTIFICATION = 'SUCCESS_HASH_NOTIFICATION';
export const FAILURE_HASH_NOTIFICATION = 'FAILURE_HASH_NOTIFICATION';

/*********************ACTIVITIES******************** */
export const GET_ALL_ACTIVITIES = 'GET_ALL_ACTIVITIES';
export const SUCCESS_GET_ALL_ACTIVITIES = 'SUCCESS_GET_ALL_ACTIVITIES';
export const FAILURE_GET_ALL_FAILURE = 'FAILURE_GET_ALL_FAILURE';

/*********************CREDENTIALS******************** */

export const GET_ALL_CREDENTIALS = 'GET_ALL_CREDENTIALS';
export const SUCCESS_GET_ALL_CREDENTIALS = 'SUCCESS_GET_ALL_CREDENTIALS';
export const FAILURE_GET_ALL_CREDENTIALS = 'FAILURE_GET_ALL_CREDENTIALS';
/*********************PROCEDURES******************** */
export const GET_PROCEDURES = 'GET_PROCEDURES';
export const SUCCESS_GET_PROCEDURES = 'SUCCESS_GET_PROCEDURES';
export const FAILURE_GET_PROCEDURES = 'FAILURE_GET_PROCEDURES';

export const POST_PROCEDURE = 'POST_PROCEDURE';
export const SUCCESS_POST_PROCEDURE = 'SUCCESS_POST_PROCEDURE';
export const FAILURE_POST_PROCEDURE = 'FAILURE_POST_PROCEDURE';

export const GET_SOLICITUD_BAJA = 'GET_SOLICITUD_BAJA';
export const SUCCESS_GET_SOLICITUD_BAJA = 'SUCCESS_GET_SOLICITUD_BAJA';
export const FAILURE_GET_SOLICITUD_BAJA = 'FAILURE_GET_SOLICITUD_BAJA';

export const GET_DATOS_RECUPERA = 'GET_DATOS_RECUPERA';
export const SUCCESS_GET_DATOS_RECUPERA = 'SUCCESS_GET_DATOS_RECUPERA';
export const FAILURE_GET_DATOS_RECUPERA = 'FAILURE_GET_DATOS_RECUPERA';

export const GET_MOTIVOS_BAJA = 'GET_MOTIVOS_BAJA';
export const SUCCESS_GET_MOTIVOS_BAJA = 'SUCCESS_GET_MOTIVOS_BAJA';
export const FAILURE_GET_MOTIVOS_BAJA = 'FAILURE_GET_MOTIVOS_BAJA';

export const POST_REQUEST_LOW = 'POST_REQUEST_LOW';
export const SUCCESS_POST_REQUEST_LOW = 'SUCCESS_POST_REQUEST_LOW';
export const FAILURE_POST_REQUEST_LOW = 'FAILURE_POST_REQUEST_LOW';
//**FILES BLOB ACTION */

export const GET_FILE = 'GET_FILE';
export const SUCCESS_GET_FILE = 'SUCCESS_GET_FILE';
export const FAILURE_GET_FILE = 'FAILURE_GET_FILE';

/**CHANGE PLAN */

export const GET_CHANGE_PLAN = 'GET_CHANGE_PLAN';
export const SUCCESS_GET_CHANGE_PLAN = 'SUCCESS_GET_CHANGE_PLAN';
export const FAILURE_GET_CHANGE_PLAN = 'FAILURE_GET_CHANGE_PLAN';

export const GET_MOTIVOS_BAJA_PLAN = 'GET_MOTIVOS_BAJA_PLAN';
export const SUCCESS_GET_MOTIVOS_BAJA_PLAN = 'SUCCESS_GET_MOTIVOS_BAJA_PLAN';
export const FAILURE_GET_MOTIVOS_BAJA_PLAN = 'FAILURE_GET_MOTIVOS_BAJA_PLAN';

export const GET_PLAN = 'GET_PLAN';
export const SUCCESS_GET_PLAN = 'SUCCESS_GET_PLAN';
export const FAILURE_GET_PLAN = 'FAILURE_GET_PLAN';

export const POST_CHANGE_PLAN = 'POST_CHANGE_PLAN';
export const SUCCESS_POST_CHANGE_PLAN = 'SUCCESS_POST_CHANGE_PLAN';
export const FAILURE_POST_CHANGE_PLAN = 'FAILURE_POST_CHANGE_PLAN';

export const PERMISSION_LOW = 'PERMISSION_LOW';
export const SUCCESS_PERMISSION_LOW = 'SUCCESS_PERMISSION_LOW';
export const FAILURE_PERMISSION_LOW = 'FAILURE_PERMISSION_LOW';

export const CLEAN_POST_PROCEDURE_SELECTOR = 'CLEAN_POST_PROCEDURE_SELECTOR';

/*Prevention Control*/

export const POST_PREVENTIONS = 'POST_PREVENTIONS';
export const SUCCESS_POST_PREVENTIONS = 'SUCCESS_POST_PREVENTIONS';
export const FAILURE_POST_PREVENTIONS = 'FAILURE_POST_PREVENTIONS';

/**personPlayer */
export const DELETE_PERSON_PLAYER = 'DELETE_PERSON_PLAYER';
export const SUCCESS_DELETE_PERSON_PLAYER = 'SUCCESS_DELETE_PERSON_PLAYER';
export const FAILURE_DELETE_PERSON_PLAYER = 'FAILURE_DELETE_PERSON_PLAYER';

/**Way To Pay */
export const GET_WAYTOPAY = 'GET_WAYTOPAY';
export const SUCCESS_GET_WAYTOPAY = 'SUCCESS_GET_WAYTOPAY';
export const FAILURE_GET_WAYTOPAY = 'FAILURE_GET_WAYTOPAY';

/**Authorizations**/
export const SET_AUTHORIZATION_DETAILS = 'SET_AUTHORIZATION_DETAILS';
export const GET_AUTHORIZATION_DETAILS = 'GET_AUTHORIZATION_DETAILS';

export const GET_AUTHORIZATIONS_TYPEATTACHED = 'GET_AUTHORIZATIONS_TYPEATTACHED';
export const SUCCESS_GET_AUTHORIZATIONS_TYPEATTACHED = 'SUCCESS_GET_AUTHORIZATIONS_TYPEATTACHED';
export const FAILURE_GET_AUTHORIZATIONS_TYPEATTACHED = 'FAILURE_GET_AUTHORIZATIONS_TYPEATTACHED';

export const GET_AUTHORIZATIONS_ATTACHED = 'GET_AUTHORIZATIONS_ATTACHED';
export const SUCCESS_GET_AUTHORIZATIONS_ATTACHED = 'SUCCESS_GET_AUTHORIZATIONS_ATTACHED';
export const FAILURE_GET_AUTHORIZATIONS_ATTACHED = 'FAILURE_GET_AUTHORIZATIONS_ATTACHED';

export const POST_AUTHORIZATIONS_ATTACHED = 'POST_AUTHORIZATIONS_ATTACHED';
export const SUCCESS_POST_AUTHORIZATIONS_ATTACHED = 'SUCCESS_POST_AUTHORIZATIONS_ATTACHED';
export const FAILURE_POST_AUTHORIZATIONS_ATTACHED = 'FAILURE_POST_AUTHORIZATIONS_ATTACHED';

export const GET_AUTHORIZATIONS_AFFILIATE = 'GET_AUTHORIZATIONS_AFFILIATE';
export const SUCCESS_GET_AUTHORIZATIONS_AFFILIATE = 'SUCCESS_GET_AUTHORIZATIONS_AFFILIATE';
export const FAILURE_GET_AUTHORIZATIONS_AFFILIATE = 'FAILURE_GET_AUTHORIZATIONS_AFFILIATE';

export const DOWNLOAD_ATTACHMENTS = 'DOWNLOAD_ATTACHMENTS';
export const SUCCESS_DOWNLOAD_ATTACHMENTS = 'SUCCESS_DOWNLOAD_ATTACHMENTS';
export const FAILURE_DOWNLOAD_ATTACHMENTS = 'FAILURE_DOWNLOAD_ATTACHMENTS';

export const DOWNLOAD_AUTHORIZATION = 'DOWNLOAD_AUTHORIZATION';
export const SUCCESS_DOWNLOAD_AUTHORIZATION = 'SUCCESS_DOWNLOAD_AUTHORIZATION';
export const FAILURE_DOWNLOAD_AUTHORIZATION = 'FAILURE_DOWNLOAD_AUTHORIZATION';

export const CANCEL_AUTHORIZATION = 'CANCEL_AUTHORIZATION';
export const SUCCESS_CANCEL_AUTHORIZATION = 'SUCCESS_CANCEL_AUTHORIZATION';
export const FAILURE_CANCEL_AUTHORIZATION = 'FAILURE_CANCEL_AUTHORIZATION';

/**Comments**/
export const GET_COMMENTS = 'GET_COMMENTS';
export const SUCCESS_GET_COMMENTS = 'SUCCESS_GET_COMMENTS';
export const FAILURE_GET_COMMENTS = 'FAILURE_GET_COMMENTS';

export const PUSH_COMMENTS = 'PUSH_COMMENTS';
export const SUCCESS_PUSH_COMMENTS = 'SUCCESS_PUSH_COMMENTS';
export const FAILURE_PUSH_COMMENTS = 'FAILURE_PUSH_COMMENTS';

export const GET_READ_COMMENTS = 'GET_READ_COMMENTS';
export const SUCCESS_GET_READ_COMMENTS = 'SUCCESS_GET_READ_COMMENTS';
export const FAILURE_GET_READ_COMMENTS = 'FAILURE_GET_READ_COMMENTS';

export const GET_AUTHORIZATIONS_DETAILS = 'GET_AUTHORIZATIONS_DETAILS';
export const SUCCESS_GET_AUTHORIZATIONS_DETAILS = 'SUCCESS_GET_AUTHORIZATIONS_DETAILS';
export const FAILURE_GET_AUTHORIZATIONS_DETAILS = 'FAILURE_GET_AUTHORIZATIONS_DETAILS';

export const GET_AUTHORIZATION_DETAIL = 'GET_AUTHORIZATION_DETAIL';
export const SUCCESS_GET_AUTHORIZATION_DETAIL = 'SUCCESS_GET_AUTHORIZATION_DETAIL';
export const FAILURE_GET_AUTHORIZATION_DETAIL = 'FAILURE_GET_AUTHORIZATION_DETAIL';

/*Direct Debit */
export const GET_ENABLED_DIRECTDEBIT = 'GET_ENABLED_DIRECTDEBIT';
export const SUCCESS_GET_ENABLED_DIRECTDEBIT = 'SUCCESS_GET_ENABLED_DIRECTDEBIT';
export const FAILURE_GET_ENABLED_DIRECTDEBIT = 'FAILURE_GET_ENABLED_DIRECTDEBIT';

export const GET_DIRECTDEBIT = 'GET_DIRECTDEBIT';
export const SUCCESS_GET_DIRECTDEBIT = 'SUCCESS_GET_DIRECTDEBIT';
export const FAILURE_GET_DIRECTDEBIT = 'FAILURE_GET_DIRECTDEBIT';

export const AB_DIRECTDEBIT = 'AB_DIRECTDEBIT';
export const SUCCESS_AB_DIRECTDEBIT = 'SUCCESS_AB_DIRECTDEBIT';
export const FAILURE_AB_DIRECTDEBIT = 'FAILURE_AB_DIRECTDEBIT';

export const SET_AB_DIRECTDEBIT = 'SET_AB_DIRECTDEBIT';

export const SUCCESS_UPDATE_TAG = 'SUCCESS_UPDATE_TAG';
export const FAILURE_UPDATE_TAG = 'FAILURE_UPDATE_TAG';
export const UPDATE_TAG = 'UPDATE_TAG';

/*Facturacion*/

export const GET_BILLINGS = 'GET_BILLINGS';
export const SUCCESS_GET_BILLINGS = 'SUCCESS_GET_BILLINGS';
export const FAILURE_GET_BILLINGS = 'FAILURE_GET_BILLINGS';

export const GET_BILLINGS_FILES = 'GET_BILLINGS_FILES';
export const SUCCESS_GET_BILLINGS_FILES = 'SUCCESS_GET_BILLINGS_FILES';
export const FAILURE_GET_BILLINGS_FILES = 'FAILURE_GET_BILLINGS_FILES';

/*Model Authorization Request*/

export const SET_MODEL_AUTH_REQUEST = 'SET_MODEL_AUTH_REQUEST';
export const CLEAR_MODEL_REQUEST = 'CLEAR_MODEL_REQUEST';
export const GET_STEPS_AUTH_REQUEST = 'GET_STEPS_AUTH_REQUEST';
export const SET_ACTUAL_STEP_AUTH_REQUEST = 'SET_ACTUAL_STEP_AUTH_REQUEST';
export const NEXT_STEP_AUTH_REQUEST = 'NEXT_STEP_AUTH_REQUEST';
export const PREV_STEP_AUTH_REQUEST = 'PREV_STEP_AUTH_REQUEST';
export const CLEAR_MODEL_DETAILS_AUTH = 'CLEAR_MODEL_DETAILS_AUTH';

export const GET_PARAMS_AUTH_REQUEST = 'GET_PARAMS_AUTH_REQUEST';
export const SUCCESS_GET_PARAMS_AUTH_REQUEST = 'SUCCESS_GET_PARAMS_AUTH_REQUEST';
export const FAILURE_GET_PARAMS_AUTH_REQUEST = 'FAILURE_GET_PARAMS_AUTH_REQUEST';

export const POST_AUTHORIZATIONS_REQUEST = 'POST_AUTHORIZATIONS_REQUEST';
export const SUCCESS_POST_AUTHORIZATIONS_REQUEST = 'SUCCESS_POST_AUTHORIZATIONS_REQUEST';
export const FAILURE_POST_AUTHORIZATIONS_REQUEST = 'FAILURE_POST_AUTHORIZATIONS_REQUEST';

export const CLEAR_HISTORIAL_REQUEST = 'CLEAR_HISTORIAL_REQUEST';

/* Reintegros */
export const GET_REFUNDS = 'GET_REFUNDS';
export const SUCCESS_GET_REFUNDS = 'SUCCESS_GET_REFUNDS';
export const FAILURE_GET_REFUNDS = 'FAILURE_GET_REFUNDS';

export const GET_REFUND_DETAILS = 'GET_REFUND_DETAILS';
export const SUCCESS_GET_REFUND_DETAILS = 'SUCCESS_GET_REFUND_DETAILS';
export const FAILURE_GET_REFUND_DETAILS = 'FAILURE_GET_REFUND_DETAILS';

export const GET_REFUND_ATTACHMENTS = 'GET_REFUND_ATTACHMENTS';
export const SUCCESS_GET_REFUND_ATTACHMENTS = 'SUCCESS_GET_REFUND_ATTACHMENTS';
export const FAILURE_GET_REFUND_ATTACHMENTS = 'FAILURE_GET_REFUND_ATTACHMENTS';

export const GET_REFUND_COMMENTS = 'GET_REFUND_COMMENTS';
export const SUCCESS_GET_REFUND_COMMENTS = 'SUCCESS_GET_REFUND_COMMENTS';
export const FAILURE_GET_REFUND_COMMENTS = 'FAILURE_GET_REFUND_COMMENTS';

export const GET_DETAILS_ATTACHMENTS = 'GET_DETAILS_ATTACHMENTS';
export const SUCCESS_GET_DETAILS_ATTACHMENTS = 'SUCCESS_DETAILS_ATTACHMENTS';
export const FAILURE_GET_DETAILS_ATTACHMENTS = 'FAILURE_DETAILS_ATTACHMENTS';

export const GET_REFUND_TYPE_ATTACHED = 'GET_REFUND_TYPE_ATTACHED';
export const SUCCESS_GET_REFUND_TYPE_ATTACHED = 'SUCCESS_GET_REFUND_TYPE_ATTACHED';
export const FAILURE_GET_REFUND_TYPE_ATTACHED = 'FAILURE_GET_REFUND_TYPE_ATTACHED';

export const PUSH_REFUND_COMMENTS = 'PUSH_REFUND_COMMENTS';
export const SUCCESS_PUSH_REFUND_COMMENTS = 'SUCCESS_PUSH_REFUND_COMMENTS';
export const FAILURE_PUSH_REFUND_COMMENTS = 'FAILURE_PUSH_REFUND_COMMENTS';

export const POST_REFUND_ATTACHED = 'POST_REFUND_ATTACHED';
export const SUCCESS_POST_REFUND_ATTACHED = 'SUCCESS_POST_REFUND_ATTACHED';
export const FAILURE_POST_REFUND_ATTACHED = 'FAILURE_POST_REFUND_ATTACHED';

/* Encuesta */
export const GET_COUNTER_SURVEY = 'GET_COUNTER_SURVEY';
export const SUCCESS_GET_COUNTER_SURVEY = 'SUCCESS_GET_COUNTER_SURVEY';
export const FAILURE_GET_COUNTER_SURVEY = 'FAILURE_GET_COUNTER_SURVEY';

export const SEND_SURVEY = 'SEND_SURVEY';
export const SUCCESS_SEND_SURVEY = 'SUCCESS_SEND_SURVEY';
export const FAILURE_SEND_SURVEY = 'FAILURE_SEND_SURVEY';

export const CLEAR_COUNTER_SURVEY = 'CLEAR_COUNTER_SURVEY';

/* Campañas */
export const GET_CAMPAIGN = "GET_CAMPAIGN";
export const SUCCESS_GET_CAMPAIGN = "SUCCESS_GET_CAMPAIGN";
export const FAILURE_GET_CAMPAIGN = "FAILURE_GET_CAMPAIGN";

/*Noticias */
export const GET_ALL_NEWS = 'GET_ALL_NEWS';
export const SUCCESS_GET_ALL_NEWS = 'SUCCESS_GET_ALL_NEWS';
export const FAILURE_GET_ALL_NEWS = 'FAILURE_GET_ALL_NEWS';


export const AB_ACCOUNT_REFUND = 'AB_ACCOUNT_REFUND';
export const SUCCESS_AB_ACCOUNT_REFUND = 'SUCCESS_AB_ACCOUNT_REFUND';
export const FAILURE_AB_ACCOUNT_REFUND = 'FAILURE_AB_ACCOUNT_REFUND';

export const GET_ACCOUNT_REFUNDS = 'GET_ACCOUNT_REFUNDS';
export const SUCCESS_GET_ACCOUNT_REFUNDS = 'SUCCESS_GET_ACCOUNT_REFUNDS';
export const FAILURE_GET_ACCOUNT_REFUNDS = 'FAILURE_GET_ACCOUNT_REFUNDS';

export const GET_PARAMETERS_REFUNDS = 'GET_PARAMETERS_REFUNDS';
export const SUCCESS_GET_PARAMETERS_REFUNDS = 'SUCCESS_GET_PARAMETERS_REFUNDS';
export const FAILURE_GET_PARAMETERS_REFUNDS = 'FAILURE_GET_PARAMETERS_REFUNDS';

export const SET_MODEL_REFUNDS = 'SET_MODEL_REFUNDS';
export const CLEAR_MODEL_REFUNDS = 'CLEAR_MODEL_REFUNDS';
export const CLEAR_STATE_REFUNDS = 'CLEAR_STATE_REFUNDS';
export const CLEAR_POST_REFUNDS = 'CLEAR_POST_REFUNDS';
export const CLEAR_MSG_ERROR = 'CLEAR_MSG_ERROR';

export const POST_REFUNDS = 'POST_REFUNDS';
export const SUCCESS_POST_REFUNDS = 'SUCCESS_POST_REFUNDS';
export const FAILURE_POST_REFUNDS = 'FAILURE_POST_REFUNDS';

export const CANCEL_REFUND = 'CANCEL_REFUND';
export const SUCCESS_CANCEL_REFUND = 'SUCCESS_CANCEL_REFUND';
export const FAILURE_CANCEL_REFUND = 'FAILURE_CANCEL_REFUND';

export const POST_READ_REFUNDS_COMMENTS = 'POST_READ_REFUNDS_COMMENTS';
export const SUCCESS_POST_READ_REFUNDS_COMMENTS = 'SUCCESS_POST_READ_REFUNDS_COMMENTS';
export const FAILURE_POST_READ_REFUNDS_COMMENTS = 'FAILURE_POST_READ_REFUNDS_COMMENTS';

export const SET_REFUNDS_FILTERS = 'SET_REFUNDS_FILTERS';
export const CLEAR_REFUNDS_FILTERS = 'CLEAR_REFUNDS_REFUNDS_FILTERS';

/* Sedes */
export const GET_ALL_HEADQUATERS = 'GET_ALL_HEADQUATERS';
export const SUCCESS_GET_ALL_HEADQUATERS = 'SUCCESS_GET_ALL_HEADQUATERS';
export const FAILURE_GET_ALL_HEADQUATERS = 'FAILURE_GET_ALL_HEADQUATERS';

export const DOWNLOAD_REFUND = 'DOWNLOAD_REFUND';
export const SUCCESS_DOWNLOAD_REFUND = 'SUCCESS_DOWNLOAD_REFUND';
export const FAILURE_DOWNLOAD_REFUND = 'FAILURE_DOWNLOAD_REFUND';

/* Configurations */
export const GET_CONFIGURATIONS = 'GET_CONFIGURATIONS';
export const SUCCESS_GET_CONFIGURATIONS = 'SUCCESS_GET_CONFIGURATIONS';
export const FAILURE_GET_CONFIGURATIONS = 'FAILURE_GET_CONFIGURATIONS';

export const GET_CONFIGURATION_BY_ID = 'GET_CONFIGURATION_BY_ID';
export const SUCCESS_GET_CONFIGURATION_BY_ID = 'SUCCESS_GET_CONFIGURATION_BY_ID';
export const FAILURE_GET_CONFIGURATION_BY_ID = 'FAILURE_GET_CONFIGURATION_BY_ID';

export const GET_CONFIGURATION_LOW_SERVICE_BY_ID = 'GET_CONFIGURATION_LOW_SERVICE_BY_ID';
export const SUCCESS_GET_CONFIGURATION_LOW_SERVICE_BY_ID = 'SUCCESS_GET_CONFIGURATION_LOW_SERVICE_BY_ID';
export const FAILURE_GET_CONFIGURATION_LOW_SERVICE_BY_ID = 'FAILURE_GET_CONFIGURATION_LOW_SERVICE_BY_ID';

export const GET_CONFIGURATION_RECLAMO_BY_ID = 'GET_CONFIGURATION_RECLAMO_BY_ID';
export const SUCCESS_GET_CONFIGURATION_RECLAMO_BY_ID = 'SUCCESS_GET_CONFIGURATION_RECLAMO_BY_ID';
export const FAILURE_GET_CONFIGURATION_RECLAMO_BY_ID = 'FAILURE_GET_CONFIGURATION_RECLAMO_BY_ID';

/** Cards */
export const GET_CARDS = 'GET_CARDS';
export const SUCCESS_GET_CARDS = 'SUCCESS_GET_CARDS';
export const FAILURE_GET_CARDS = 'FAILURE_GET_CARDS';

export const CLEAR_ABM_MODEL = 'CLEAR_ABM_MODEL';

export const CLEAN_VIRTUAL_DOCTOR_MODEL = 'CLEAN_VIRTUAL_DOCTOR_MODEL';

/** Pharmaceutical form */
export const GET_PHARMACEUTICAL_FORM = 'GET_PHARMACEUTICAL_FORM';
export const SUCCESS_GET_PHARMACEUTICAL_FORM = 'SUCCESS_GET_PHARMACEUTICAL_FORM';
export const FAILURE_GET_PHARMACEUTICAL_FORM = 'FAILURE_GET_PHARMACEUTICAL_FORM';

/** PlanPdf */

export const GET_PLANPDF_BY_PLANCODE = 'GET_PLANPDF_BY_PLANCODE';
export const SUCCESS_GET_PLANPDF_BY_PLANCODE = 'SUCCESS_GET_PLANPDF_BY_PLANCODE';
export const FAILURE_GET_PLANPDF_BY_PLANCODE = 'FAILURE_GET_PLANPDF_BY_PLANCODE';

/** Practicas */
export const GET_PRACTICAS = 'GET_PRACTICAS';
export const SUCCESS_GET_PRACTICAS = 'SUCCESS_GET_PRACTICAS';
export const FAILURE_GET_PRACTICAS = 'FAILURE_GET_PRACTICAS';

/** Telemedicine Appointments */
export const GET_TELEMEDICINE = 'GET_TELEMEDICINE';
export const SUCCESS_GET_TELEMEDICINE = 'SUCCESS_GET_TELEMEDICINE';
export const FAILURE_GET_TELEMEDICINE = 'FAILURE_GET_TELEMEDICINE';

export const SET_TELEMEDICINE_PARAMS = 'SET_TELEMEDICINE_PARAMS';
export const GET_TELEMEDICINE_PARAMS = 'GET_TELEMEDICINE_PARAMS';

export const CLEAR_STATE = 'CLEAR_STATE';

/** Cap And Copay Links */

export const GET_CAPANDCOPAYLINK_BY_PLANCODE = 'GET_CAPANDCOPAYLINK_BY_PLANCODE';
export const SUCCESS_GET_CAPANDCOPAYLINK_BY_PLANCODE = 'SUCCESS_GET_CAPANDCOPAYLINK_BY_PLANCODE';
export const FAILURE_GET_CAPANDCOPAYLINK_BY_PLANCODE = 'FAILURE_GET_CAPANDCOPAYLINK_BY_PLANCODE';


/** Program Requirements And Forms */

export const GET_PROGRAMREQUIREMENTANDFORM_BY_PROGRAM = 'GET_PROGRAMREQUIREMENTANDFORM_BY_PROGRAM';
export const SUCCESS_GET_PROGRAMREQUIREMENTANDFORM_BY_PROGRAM = 'SUCCESS_GET_PROGRAMREQUIREMENTANDFORM_BY_PROGRAM';
export const FAILURE_GET_PROGRAMREQUIREMENTANDFORM_BY_PROGRAM = 'FAILURE_GET_PROGRAMREQUIREMENTANDFORM_BY_PROGRAM';


export const GET_MEDICAL_PROGRAMS = 'GET_MEDICAL_PROGRAMS';
export const SUCCESS_GET_MEDICAL_PROGRAMS = 'SUCCESS_GET_MEDICAL_PROGRAMS';
export const FAILURE_GET_MEDICAL_PROGRAMS = 'FAILURE_GET_MEDICAL_PROGRAMS';


/** Limitar Planes **/
export const FAILURE_GET_LIMIT_PLAN = "FAILURE_GET_LIMIT_PLAN";
export const SUCCESS_GET_LIMIT_PLAN = "SUCCESS_GET_LIMIT_PLAN";
export const GET_LIMIT_PLAN = "GET_LIMIT_PLAN";

export const FAILURE_POST_LIMIT_PLAN = "FAILURE_POST_LIMIT_PLAN";
export const SUCCESS_POST_LIMIT_PLAN = "SUCCESS_POST_LIMIT_PLAN";
export const POST_LIMIT_PLAN = "POST_LIMIT_PLAN";

export const FAILURE_PUT_LIMIT_PLAN = "FAILURE_PUT_LIMIT_PLAN";
export const SUCCESS_PUT_LIMIT_PLAN = "SUCCESS_PUT_LIMIT_PLAN";
export const PUT_LIMIT_PLAN = "PUT_LIMIT_PLAN";

export const FAILURE_DELETE_LIMIT_PLAN = "FAILURE_DELETE_LIMIT_PLAN";
export const SUCCESS_DELETE_LIMIT_PLAN = "SUCCESS_DELETE_LIMIT_PLAN";
export const DELETE_LIMIT_PLAN = "DELETE_LIMIT_PLAN";

