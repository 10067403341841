import { all } from "redux-saga/effects";
import filtersSaga from "./medicalFiltersSaga";
import appointmentsSaga from "./appointmentsSaga";
import phonesSaga from "./phonesSaga";
import emergencySaga from './emergencySaga';
import personalDataSaga from "./personalDataSaga";
import medicalDataSaga from './medicalDataSaga';
import registerUsersSaga from './registerUsersSaga';
import coverageSaga from './coverageSaga';
import contactUsSaga from './contactUsSaga';
import virtualDoctorSaga from "./virtualDoctorSaga";
import notificationsSaga from "./notificationsSaga";
import credentialSaga from "./credentialSaga";
import activitiesSaga from "./activitiesSaga";
import procedureSaga from "./procedureSaga";
import preventionSaga from "./preventionSaga";
import personPlayerSaga from "./personPlayerSaga";
import billingSaga from "./billingSaga";
import authorizationsSaga from "./authorizationsSaga";
import refundsSaga from "./refundsSaga";
import surveySaga from "./surveySaga";
import newsSaga from "./newsSaga";
import sedesSaga from "./sedesSaga";
import configurationSaga from "./configurationSaga";
import cardSaga from "./cardSaga";
import planPdfSaga from "./planPdfSaga";
import practicasSaga from "./practicasSaga";
import telemedicineAppointmentsSaga from "./telemedicineAppointmentsSaga";
import capsAndCopayLinksSaga from "./capsAndCopayLinksSaga";
import programRequirementAndFormSaga from "./programRequirementAndFormSaga";
import limitPlanSaga from "./limitPlanSaga";
import campaignSaga from "./campaignSaga";

export default function* rootSaga() {
  yield all([
    filtersSaga(),
    appointmentsSaga(),
    phonesSaga(), emergencySaga(),
    personalDataSaga(),
    medicalDataSaga(),
    coverageSaga(),
    contactUsSaga(),
    virtualDoctorSaga(),
    registerUsersSaga(),
    notificationsSaga(),
    credentialSaga(),
    activitiesSaga(),
    procedureSaga(),
    preventionSaga(),
    personPlayerSaga(),
    billingSaga(),
    authorizationsSaga(),
    refundsSaga(),
    surveySaga(),
    newsSaga(),
    sedesSaga(),
    campaignSaga(),
    configurationSaga(),
    cardSaga(),
    planPdfSaga(),
    practicasSaga(),
    telemedicineAppointmentsSaga(),
    capsAndCopayLinksSaga(),
    programRequirementAndFormSaga(),
    limitPlanSaga(),
  ]);
}