import useStyles from "../../assets/styles/styles";
import { Box, Button, Typography } from '@material-ui/core';
import NotFound from '../../components/HomeLogged/NotFound';
import { useSelector } from "react-redux";
import { useState } from 'react';
import GenericModal from "../GenericModal/GenericModal";
import "./credentials.css";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import CustomButton from "../../controls/Button/CustomButton";
import CredentialCard from "../Card/CredentialCard";
import CredentialHeader from "../Card/CredentialHeader";
import BackCredential from "../Card/BackCredential";

const Credentials = () => {
    const classes = useStyles();
    const [open, setOpen] = useState();
    const [index, setIndex] = useState(-1);
    const credentials = useSelector(state => state.credentialReducer);
    const screenSize = useResizeScreen();
    const [backCredentials, setBackCredentials] = useState(false);
    const buttonTitle = !backCredentials ? "Ver dorso" : "Ver frente";

    const handleOpen = (i) => (e) => {
        setOpen(prevState => !prevState);
        setIndex(i);
    };

    const changeCredentialsSide = () => {
        setBackCredentials(prevState => !prevState);
    }

    const showBackOrFront = (item) => {
        let res = <CredentialCard affilliated={item}/>

        if(backCredentials){
            res = <BackCredential affilliated={item}/>
        }

        return res;
    }

    function showNotFound() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', flex: '0 0 100%' }}>
                <NotFound wsMessage={credentials?.allCredentials?.plansCredentials?.mensajes} wsItem={credentials?.allCredentials?.plansCredentials} fullItem={credentials?.allCredentials} />
            </div>
        );
    }

    function indexItem(i, item) {
        if (i === index) {
            return (
                <GenericModal
                    open={open}
                    setClose={setOpen}
                    style={{ padding: 0 }}
                    customClass={classes.paddingContent}>
                    {backCredentials ?
                    <BackCredential affilliated={item}/>
                    :
                    <CredentialCard affilliated={item}/>}
                </GenericModal>
            );
        } else {
            return <></>;
        }
    }

    const showNewCredentialCards = () => {
        var response;
        response = (
            <Box className={`${classes.boxCardCredentials}`} style={{display: "flex", justifyContent: "center"}}>
                {Object.values(credentials?.allCredentials)?.length > 0 ?
                    credentials?.allCredentials?.plansCredentials?.map((item, i) => (
                        <div>
                            {screenSize.screen.sWidth <= "500" ?
                                <>
                                    <div style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", alignItems: "center" }}>
                                        <div
                                            className={classes.divCredentialsMobile}
                                            style={{ zIndex: "1" }}>
                                            <Button onClick={handleOpen(i)} style={{ textAlign: 'initial' }}>
                                                <CredentialHeader affilliated={item}/>
                                            </Button>
                                        </div>
                                        <Button onClick={handleOpen(i)} className={classes.btnCredentialsMobile} >
                                            <Typography style={{ marginLeft: "20px"}}>
                                                {item?.credentials?.apellidoNombre}
                                            </Typography>
                                        </Button>

                                    </div>
                                    {indexItem(i, item)}
                                </>
                                :
                                <>
                                    <Button onClick={handleOpen(i)} style={{ textAlign: 'initial', margin: "20px 0 0 20px"}}>
                                    {showBackOrFront(item)}
                                    </Button>
                                    {indexItem(i, item)}
                                </>
                            }
                        </div>
                    ))
                    :
                    showNotFound()
                }
            </Box>
        )
        return response;
    }


    return (
        <>
            <Box
                style={{ alignItems: "center", display: "flex", justifyContent: "end", flex:"0 0 85%" }}
            >
                <CustomButton
                    customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient}`}
                    style={{ margin: "20px" }}
                    title={buttonTitle} onClick={changeCredentialsSide}
                />
            </Box>
            {showNewCredentialCards()}
        </>
    );
};

export default Credentials;