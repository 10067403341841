import useStyles from "../../assets/styles/styles";
import format from "date-fns/format";
import Card from "../Card/Card";
import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import NextAppointmentCard from "../Appointments/Cards/NextAppointmentCard";
import HistoryAppointmentCard from "../Appointments/Cards/HistoryAppointmentCard";

const CardDetail = ({ appointments, indexItem, handleEdit, setItemAppointment, setOpenModal, handleClickOpenRepeatModal }) => {
  const classes = useStyles();
  const [title, setTitle] = useState('Turnos históricos');
  const [description, setDescription] = useState('No posee turnos históricos para la fecha seleccionada');

  const checkAppointment = () => {
    let date = appointments.filter((x) => {
      let aux = new Date(x.turnoFechHora);
      return format(aux, "yyyy-MM-dd") === indexItem;
    });
    return date?.length;
  };

  useEffect(() => {
    let today = Date.now();
    let todayFormated = format(today, "yyyy-MM-dd");
    if (indexItem >= todayFormated) {
      setTitle('Mis próximos turnos');
      setDescription('No posee próximos turnos');
    } else {
      setTitle('Turnos históricos');
      setDescription('No posee turnos históricos para la fecha seleccionada');
    }
  }, [indexItem]);

  return (
    <>
      <div style={{ width: "500px" }}>
        <Typography className={`${classes.titleTurn} ${classes.txtBold500}`} style={{ marginTop: "35px " }}>
          {title}
        </Typography>
        {checkAppointment() > 0 ?
          (
            <>
              {appointments?.filter((x) => {
                let aux = new Date(x.turnoFechHora);
                return format(aux, "yyyy-MM-dd") === indexItem;
              })
                .map((item, i) => (
                  item.turnoFechHora >= format(Date.now(), "yyyy-MM-dd") ?
                    <NextAppointmentCard item={item} i={i} handleClickOpenRepeatModal={handleEdit} setCancelItem={setItemAppointment} setOpenModal={setOpenModal} />
                    :
                    <HistoryAppointmentCard item={item} i={i} handleClickOpenRepeatModal={handleClickOpenRepeatModal} />
                  // <Card
                  //   isMedicalRecord={true}
                  //   key={i}
                  //   customClass={classes.calendarDetail}
                  //   title={
                  //     <>
                  //       <div className={`${classes.blueTxt} ${classes.fontBold}`}>
                  //         HORA: {item.turnoFechHora.split("T")[1].substring(0, 5)} hs.
                  //       </div>
                  //     </>
                  //   }
                  //   description={
                  //     <>
                  //       <div className={`${classes.grayTxt} ${classes.fontBold}`}>
                  //         CENTRO MEDICO: {item.sucursalDescripcion}
                  //       </div>
                  //       <div className={classes.blueTxt}>
                  //         {item.pacienteNombreApellido}
                  //       </div>
                  //       <div className={classes.grayTxt}>
                  //         {item.especialidadDescripcion}
                  //       </div>
                  //       <div className={classes.grayTxt}>
                  //         {item.medicoDescripcion}
                  //       </div>
                  //     </>
                  //   }
                  // />
                ))}
            </>
          )
          :
          <Typography className={`${classes.titleTurn} ${classes.txtBold500}`} style={{ marginTop: "50px " }}>
            {description}
          </Typography>
        }
      </div>
    </>);
};

export default React.memo(CardDetail, (prevState, nextState) => {
  return prevState.indexItem === nextState.indexItem && prevState.appointments === nextState.appointments;
});
