import React, { useCallback, useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { useDispatch, useSelector } from "react-redux";
import "./customCalendar.css";
import { format } from "date-fns";
import CardDetail from "./CardDetail";
import SelectHour from "../AskTurn/SelectDateDrawer/SelectHour";
import useStyles from "../../assets/styles/styles";
import { setModelToSaveAppointment } from "../../redux/actions/appointmentsActions";

const CustomCalendar = ({ familyFilter, appointments, withCard, isDoctor, handleEdit, setItemAppointment, setOpenModal, handleClickOpenRepeatModal }) => {
  const [value, onChange] = useState(new Date());
  const [indexItem, setIndexItem] = useState(new Date());
  const [calendarAppointments, setCalendarAppointments] = useState([]);
  const modelAppointmentReducer = useSelector(state => state.appointmentsReducer);
  const [modelAppointment, setModelAppointment] = useState(modelAppointmentReducer?.modelAppointment);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openModalRepeatAppointment, setOpenModalRepeatAppointment] = useState(false);
  const hourRef = useRef(null);
  const [appointmentDate, setAppointmentDate] = useState("");
  const [flagAppointmentSelected, setFlagAppointmentSelected] = useState(false);
  const[selectedDayCalendar, setSelectedDayCalendar] = useState(null);

  const handleClickDay = useCallback(
    (i) => (e) => {
      let selectedDate = format(e, "yyyy-MM-dd");
      setIndexItem(selectedDate);
      var element = document.getElementsByClassName("turnoSeleccionado"); 
      element[0]?.classList?.remove("turnoSeleccionado");
      
      const dayObject = selectedDate ? new Date(selectedDate + "T00:00:00") : null;

      if (!isDoctor) {
        setSelectedDayCalendar(dayObject);

      }
    },
    [indexItem]
  );

  const isDateCalendar = (shiftday) => {
    return (selectedDayCalendar != null && selectedDayCalendar?.getDate() == shiftday?.getDate() && selectedDayCalendar?.getMonth() == shiftday?.getMonth() && selectedDayCalendar?.getFullYear() == shiftday?.getFullYear());
  }

  useEffect(() => {
    setModelAppointment({ ...modelAppointmentReducer?.modelAppointment, selectedAppointment: indexItem });
    dispatch(setModelToSaveAppointment({ ...modelAppointment, selectedAppointment: indexItem }));

  }, [indexItem]);

  const setFilteredAppointmentsFunction = useCallback(() => {
    let appointmentsFiltered = [];
    if (isDoctor) {
      Object.entries(familyFilter).forEach(([key, filterValue]) => {
        if (filterValue) {
          appointments?.filter(x => x.medicoCodigo === Number.parseInt(key)).map(item => {
            appointmentsFiltered.push(item);
          });
        }
      });
    } else {
      if (familyFilter?.value === 0) {
        appointments?.map(item => {
          appointmentsFiltered.push(item);
        });
      } else {
        appointments?.filter(x => x.pacienteNumero === Number.parseInt(familyFilter?.value)).map(item => {
          appointmentsFiltered.push(item);
        });
      }
    }
    setCalendarAppointments(appointmentsFiltered);
  }, [familyFilter, appointments]);

  const checkAppointments = useCallback(
    ({ date, view }) => {
      let classesToAdd = [];
      Object.entries(calendarAppointments)?.forEach(([key, item]) => {
        let dateAux = new Date(item.turnoFechHora);
        let calendarDate = format(date, "yyyy-MM-dd");
        let auxDateAppointment = format(dateAux, "yyyy-MM-dd");
        if (view === "month" && calendarDate === auxDateAppointment) {
          let css = setClassDate(item, isDoctor, appointmentDate, setFlagAppointmentSelected);
          classesToAdd.push(css);
        }
      });
      return classesToAdd;
    },
    [calendarAppointments, familyFilter]
  );

  useEffect(() => {
    setFilteredAppointmentsFunction();
  }, [familyFilter, appointments]);

  useEffect(() => {
    if (isDoctor && modelAppointment?.selectedAppointment !== undefined) {
      setAppointmentDate(modelAppointment?.selectedAppointment);
    }
    else {
      document.getElementsByClassName("react-calendar__tile--now")?.[0]?.click();
    }
    setFilteredAppointmentsFunction();
  }, []);

  useEffect(() => {
    if (isDoctor && modelAppointment?.selectedAppointment !== undefined && flagAppointmentSelected) {
      document.getElementsByClassName("turnoSeleccionado")?.[0]?.click();
    }
  }, [flagAppointmentSelected]);

  useEffect(() => {
    if (isDoctor) {
      hourRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  });

  return (
    <>
      {isDoctor ?
        <>
          <div id='calendarioTurnos' className={classes.centeredBox}>
            <Calendar
              onChange={onChange}
              onClickDay={handleClickDay()}
              showNeighboringMonth={false}
              locale="es-ES"
              value={value}
              tileClassName={checkAppointments}
              next2AriaLabel={null}
              next2Label={null}
              prev2AriaLabel={null}
              prev2Label={null}
            />
          </div>

          <div className={classes.HourTurnCercano} style={{ marginTop: '10px' }} ref={hourRef}>
            <SelectHour selectedDate={modelAppointment} />
          </div>

        </>
        :
        <div
          id='calendarioPrincipal'
          style={{
            justifyContent: "center",
            display: "flex",
            paddingTop: "50px",
          }}
        >
          <Calendar
            onChange={onChange}
            onClickDay={handleClickDay()}
            showNeighboringMonth={false}
            locale="es-ES"
            value={value}
            tileClassName={ ({date}) =>  isDateCalendar(date) ? "activeDay" : checkAppointments({date, view: "month"}) }
          />
          {withCard &&
            <CardDetail appointments={calendarAppointments} indexItem={indexItem} handleEdit={handleEdit} setItemAppointment={setItemAppointment} setOpenModal={setOpenModal} handleClickOpenRepeatModal={handleClickOpenRepeatModal} />
          }
        </div>
      }

      {
        openModalRepeatAppointment &&
        <ModalActivities
          title={'Modificar Turno'}
          description={'¿Estás seguro que deseas modificar el turno seleccionado?'}
          open={openModalRepeatAppointment}
          onClose={setOpenModalRepeatAppointment}
          modalconfirm={true}
          modalLogout={false}
          buttonType={'submit'}
          handleClick={handleClickModifyRepeatAppointment(modifyRepeatTitle)}
        />
      }
    </>
  );
};

export default React.memo(CustomCalendar, (prevState, nextState) => {
  return prevState.familyFilter === nextState.familyFilter && prevState.appointments === nextState.appointments;
});


function setClassDate(item, isDoctor, appointmentDate, setFlagAppointmentSelected) {
  const DEFAULT_CSS_CLASS = 'appointment';
  const CSS_CLASSES = {
    DISPONIBLE: 'disponible',
    LICENCIA: 'licencia',
    'Sin-Turno': 'sinTurno',
    SELECCIONADO: 'turnoSeleccionado',
  };
  let cssAppointmentState = DEFAULT_CSS_CLASS;
  if (isDoctor) {
    cssAppointmentState = CSS_CLASSES[item.estadoTurno];
    if (item.turnoFechHora.split('T')[0] === appointmentDate) {
      cssAppointmentState = CSS_CLASSES['SELECCIONADO'];
      setFlagAppointmentSelected(true);
    }
  }
  return cssAppointmentState;
}