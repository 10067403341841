import React, { Fragment, useEffect, useState } from 'react';
import CustomButton from '../../../controls/Button/CustomButton';
import ConfirmModal from '../../PersonalData/ConfirmModal';
import SaveModal from '../../PersonalData/SaveModal';
import { FormControl, Button, FormHelperText } from '@material-ui/core';
import PhonesInput from '../../../controls/InputCustom/PhonesInput';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import PhoneIcon from '@material-ui/icons/Phone';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import { abmMDPhones } from "../../../redux/actions/medicalDataAction";
import useStyles from '../../../assets/styles/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMessageModal from '../../../hooks/useMessageModal';
import { handleAddItem, handleChangeInput, handleClickModal, validates, WS_PARAMETERS_LENGTH } from './Utils/phonesUtilsFunctions';
import NotFound from '../../HomeLogged/NotFound';

const PhonesForm = (props) => {
    const { phone, setPhone, disabled, setDisabled, addItemScreen, setAddItemScreen, phoneType } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const postAffiliatedPhones = useSelector(state => state.medicalDataReducer);

    const defaultModal = {
        defaultModalTitle: 'Enviado correctamente',
        defaultModalDescription: 'Los cambios han sido realizados.',
        defaultModalIcon: <CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />,
        defaultModalErrorClass: ''
    };
    const setMessageModal = {
        setModalTitle: 'Ocurrió un error',
        setModalDescription: 'Ocurrio un error al enviar los datos.',
        setModalIcon: <CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />,
        setModalErrorClass: classes.backgroundError
    };

    const errorMessage = useSelector(state => state.medicalDataReducer?.msgError);

    const { modalTitle, modalDescription, modalIcon, modalErrorClass } = useMessageModal(defaultModal, setMessageModal, errorMessage);

    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [addInput, setAddInput] = useState([
        {
            telefonoPaisCodigo: 0,
            telefonoAreaCodigo: 0,
            telefonoNumero: 0,
            telefonoObservacion: ''
        }
    ]);
    const [flag, setFlag] = useState(false);

    const [error, setError] = useState(
        {
            telefonoPaisCodigo: '',
            telefonoAreaCodigo: '',
            telefonoNumero: '',
            telefonoObservacion: '',
        },
    );
    const affiliatedPhones = useSelector(state => state.medicalDataReducer.affiliatedPhones);

    const position = -1;

    const handleRemoveItem = (phoneItem, phone, setPhone, dispatch, tipo) => (e) => {
        e.preventDefault();
        const phonesWOItem = phone?.filter(x => x !== phoneItem);

        setPhone(phonesWOItem);

        const aux = {
            numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
            telefonoTipo: tipo,
            modo: "B",
            telefonoPaisCodigo: phoneItem?.telefonoPaisCodigo,
            telefonoAreaCodigo: phoneItem?.telefonoAreaCodigo,
            telefonoNumero: parseInt(phoneItem?.telefonoNumero),
            telefonoInterno: 0,
            telefonoObservacion: phoneItem?.telefonoObservacion?.trim(),
            telefonoCodigo: phoneItem?.telefonoCodigo
        };
        dispatch(abmMDPhones(aux));
    };

    const submit = (submitObject) => {
        const { phone, setAddItemScreen, addInput, flag, setPhone, setAddInput, phoneType, dispatch } = submitObject;

        const aux = {
            numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
            telefonoTipo: '',
            modo: "A",
            telefonoPaisCodigo: 0,
            telefonoAreaCodigo: 0,
            telefonoNumero: 0,
            telefonoInterno: 0,
            telefonoObservacion: '',
            telefonoCodigo: 0
        };

        if (flag && phone !== undefined) {
            setPhone([...phone, ...addInput]);
            aux.telefonoTipo = phoneType;
            aux.modo = "A";
            aux.telefonoPaisCodigo = parseInt(addInput[0]?.telefonoPaisCodigo);
            aux.telefonoAreaCodigo = parseInt(addInput[0]?.telefonoAreaCodigo);
            aux.telefonoNumero = parseInt(addInput[0]?.telefonoNumero);
            aux.telefonoInterno = 0;
            aux.telefonoObservacion = addInput[0]?.telefonoObservacion?.trim();
            aux.telefonoCodigo = 0;

            dispatch(abmMDPhones(aux));

            setAddInput([{
                telefonoPaisCodigo: 0,
                telefonoAreaCodigo: 0,
                telefonoNumero: 0,
                telefonoObservacion: ''
            }]);

            setAddItemScreen(prevState => !prevState);
        } else {
            Object.values(phone)?.forEach(item => modifyPhones(item, dispatch, aux, phoneType));
        }
    }

    const modifyPhones = (phone, dispatch, model, type) => {
        model.telefonoTipo = type;
        model.modo = "M";
        model.telefonoPaisCodigo = phone?.telefonoPaisCodigo;
        model.telefonoAreaCodigo = phone?.telefonoAreaCodigo;
        model.telefonoNumero = parseInt(phone?.telefonoNumero);
        model.telefonoInterno = 0;
        model.telefonoObservacion = phone?.telefonoObservacion?.trim();
        model.telefonoCodigo = phone?.telefonoCodigo;

        dispatch(abmMDPhones(model));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validates(phone, addItemScreen, addInput, setError, setFlag)) {
            setOpen(prevState => !prevState);
            setOpenConfirm(prevState => !prevState);
            submit({ phone, setAddItemScreen, addInput, flag, setPhone, setAddInput, phoneType, dispatch });
            setDisabled(true);
        }
    };

    useEffect(() => {
        if (postAffiliatedPhones?.abmAffiliatedPhones?.confirmacion !== undefined && postAffiliatedPhones?.abmAffiliatedPhones?.confirmacion) {
            setTimeout(() => {
                window.location.reload()
            }, 2000);
        }
    },[postAffiliatedPhones?.abmAffiliatedPhones]);

    function handleNew() {
        setDisabled(prevState => !prevState);
    }

    return (
        <>
            <form onSubmit={handleSubmit} className={classes.addressForm} style={{ paddingLeft: '0', justifyContent: 'flex-start' }}>
                {!addItemScreen ? <>
                    {affiliatedPhones?.telefonos?.filter(x => x.telefonoTipo === phoneType)?.length > 0 ?
                        phone?.map((item, i) => (
                            <Fragment key={i}>
                                <div className={`${classes.divEmergencyPhonesForm} ${classes.divCustomSelectHobbies}`} style={{marginLeft: "15px", alignItems: 'start'}} >
                                    <div className={classes.quarterCol} style={{ justifyContent: 'center' }} >
                                        <PhoneIcon className={`${classes.phoneIcon} ${classes.quickAccessIcon}`} style={{marginRight: "15px", marginTop: "30px"}} />
                                    </div>
                                    <div className={classes.divFormControlsEmergency}>
                                        {disabled ?
                                            <>
                                                <FormControl className={classes.formControlsEmergency} variant="standard">
                                                    <PhonesInput
                                                        name={`telefonoNumero-${i}`}
                                                        value={`+${item?.telefonoPaisCodigo} ${item?.telefonoAreaCodigo} ${item?.telefonoNumero}`}
                                                        label='TELEFONO'
                                                        disabled={disabled}
                                                    />
                                                    <FormHelperText className={classes.errorText}>{error[i]?.telefonoNumero}</FormHelperText>
                                                </FormControl>
                                                <FormControl className={classes.formControlsEmergency} variant="standard">
                                                    <PhonesInput name={`telefonoObservacion-${i}`} value={item?.telefonoObservacion} label='DESCRIPCION' disabled={disabled} />
                                                    <FormHelperText className={classes.errorText}>{error[i]?.telefonoObservacion}</FormHelperText>
                                                </FormControl>
                                            </>
                                            :
                                            <>
                                                <FormControl className={classes.formControlsEmergency} variant="standard">
                                                    <div className={classes.divInputEmergencyPhones} >
                                                        <div >
                                                            <PhonesInput
                                                                label='Cod. País'
                                                                name={`telefonoPaisCodigo-${i}`}
                                                                value={item?.telefonoPaisCodigo}
                                                                disabled={disabled}
                                                                onChange={handleChangeInput({ i, phone, setPhone, addInput, setAddInput })}
                                                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                                                                inputProps={{ maxLength: WS_PARAMETERS_LENGTH.CodigoPais }}
                                                            />
                                                        </div>
                                                        <div >
                                                            <PhonesInput
                                                                label='Cod. Area'
                                                                name={`telefonoAreaCodigo-${i}`}
                                                                value={item?.telefonoAreaCodigo}
                                                                disabled={disabled}
                                                                onChange={handleChangeInput({ i, phone, setPhone, addInput, setAddInput })}
                                                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                                                                inputProps={{ maxLength: WS_PARAMETERS_LENGTH.CodigoArea }}
                                                            />
                                                        </div>
                                                        <div className={classes.divInputPhone} >
                                                            <PhonesInput
                                                                label='Teléfono'
                                                                name={`telefonoNumero-${i}`}
                                                                value={item?.telefonoNumero}
                                                                disabled={disabled}
                                                                onChange={handleChangeInput({ i, phone, setPhone, addInput, setAddInput })}
                                                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                                                                inputProps={{ maxLength: WS_PARAMETERS_LENGTH.TelefonoNumero }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <FormHelperText className={classes.errorText}>{error[i]?.telefonoNumero}</FormHelperText>
                                                </FormControl>
                                                <FormControl className={classes.formControlsEmergency} variant="standard">
                                                    <PhonesInput name={`telefonoObservacion-${i}`} value={item?.telefonoObservacion} label='DESCRIPCION' onChange={handleChangeInput({ i, phone, setPhone, addInput, setAddInput })} disabled={disabled} />
                                                    <FormHelperText className={classes.errorText}>{error[i]?.telefonoObservacion}</FormHelperText>
                                                </FormControl>
                                            </>
                                        }
                                    </div>
                                    <div className={classes.quarterCol} style={{ justifyContent: 'center' }}>
                                        <Button onClick={handleRemoveItem(item, phone, setPhone, dispatch, phoneType)} >
                                            <DeleteOutlineIcon className={classes.ShowMoreArrowIcon} style={{ color: '#002858', marginTop: "30px" }} />
                                        </Button>
                                    </div>
                                </div>
                            </Fragment>
                        ))
                        :
                        <div style={{ display: 'flex', justifyContent: 'center', flex: '0 0 100%' }}>
                            <NotFound customMsg={
                                <div className={classes.contentNotFound}>
                                    <span>No se encontraron resultados</span>
                                </div>
                            }
                                wsItem={affiliatedPhones?.telefonos}
                                fullItem={affiliatedPhones?.telefonos}
                            />
                        </div>
                    }
                </> :
                    <div className={`${classes.divEmergencyPhonesForm} ${classes.divCustomSelectHobbies} ${classes.divFlexPhones}`} >
                        <div className={classes.quarterCol} style={{ justifyContent: 'center' }} >
                            <PhoneIcon className={`${classes.phoneIcon} ${classes.quickAccessIcon}`} style={{ margin: '0 15px' }} />
                        </div>
                        <div className={classes.divFormControlsEmergency}>
                            <FormControl className={classes.formControlsEmergency} variant="standard">
                                <div style={{ display: 'flex' }}>
                                    <div className={classes.inputContactDataBefore} style={{ display: 'flex' }}>
                                        <PhonesInput
                                            label='Cod. País'
                                            name={`telefonoPaisCodigo-0`}
                                            value={addInput?.telefonoPaisCodigo}
                                            onChange={handleChangeInput({ position, phone, setPhone, addInput, setAddInput })}
                                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                                            inputProps={{ maxLength: WS_PARAMETERS_LENGTH.CodigoPais }}
                                        />
                                    </div>
                                    <div className={classes.inputContactDataBefore} style={{ display: 'flex', marginRight: '0 !important', marginInlineLeft: '0 !important' }}>
                                        <PhonesInput
                                            label='Cod. Area'
                                            name={`telefonoAreaCodigo-0`}
                                            value={addInput?.telefonoAreaCodigo}
                                            onChange={handleChangeInput({ position, phone, setPhone, addInput, setAddInput })}
                                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                                            inputProps={{ maxLength: WS_PARAMETERS_LENGTH.CodigoArea }}
                                        />
                                    </div>
                                    <div className={classes.inputContactData} style={{ display: 'flex' }}>
                                        <PhonesInput
                                            label='Teléfono'
                                            name={`telefonoNumero-0`}
                                            value={addInput?.telefonoNumero}
                                            onChange={handleChangeInput({ position, phone, setPhone, addInput, setAddInput })}
                                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                                            inputProps={{ maxLength: WS_PARAMETERS_LENGTH.TelefonoNumero }}
                                        />
                                    </div>
                                </div>
                                <FormHelperText className={classes.errorText}>{error[0]?.telefonoNumero}</FormHelperText>
                            </FormControl>
                            <FormControl className={`${classes.formControlsEmergency} ${classes.formControlTelefonoObsevacion}`} variant="standard">
                                <PhonesInput name={`telefonoObservacion-0`} value={addInput?.telefonoObservacion} onChange={handleChangeInput({ position, phone, setPhone, addInput, setAddInput })} label='DESCRIPCION' />
                                <FormHelperText className={classes.errorText}>{error[0]?.telefonoObservacion}</FormHelperText>
                            </FormControl>
                        </div>
                        <Button onClick={handleAddItem({ phone, addInput, addItemScreen, setError, setFlag, setOpenConfirm, setAddInput, setPhone })}>
                            <AddCircleOutlinedIcon className={`${classes.ShowMoreArrowIcon}`} />
                        </Button>
                    </div>
                }
                <div className={`${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                    {(!disabled) &&
                        <div style={{ marginBottom: "50px" }}>
                            <CustomButton
                                customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                                style={{ marginBottom: "10px" }}
                                title='cancelar'
                                onClick={handleNew}
                            />
                            <CustomButton
                                customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                                title='Guardar'
                                onClick={handleClickModal(phone, addItemScreen, addInput, setError, setFlag, setOpen)}
                                style={{ marginBottom: "10px" }}
                            />
                        </div>
                    }

                    {(addItemScreen && disabled) &&
                        <div style={{ marginBottom: "50px" }}>
                            <CustomButton
                                customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                                style={{ marginBottom: "10px" }}
                                title='cancelar'
                                onClick={() => { setAddItemScreen(false) }}
                            />
                            <CustomButton
                                customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                                style={{ marginBottom: "10px" }}
                                title='Guardar'
                                onClick={handleClickModal(phone, addItemScreen, addInput, setError, setFlag, setOpen)}
                            />
                        </div>
                    }
                </div>
                <SaveModal isOpen={open} setOpen={setOpen} type='submit' onClick={handleSubmit}
                    titleModal={
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                Confirmar envío de datos
                            </div>
                        </>
                    }
                    descriptionModal="¿Deseas confirmar los cambios?"
                />
            </form>
            <ConfirmModal isOpen={openConfirm} setOpen={setOpenConfirm} errorClass={modalErrorClass}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalIcon}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalTitle}
                        </div>
                    </>
                }
                description={modalDescription} />
        </>
    );
};

export default React.memo(PhonesForm);
