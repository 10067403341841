import React, { useEffect, useState, Suspense } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Img from "../assets/images/img-register.png";
import Grid from "@material-ui/core/Grid";
import useStyles from "../assets/styles/styles";
import { FormControl, FormHelperText, IconButton, Typography } from "@material-ui/core";
import Logo from "../assets/images/logo.png";
import whiteLogo from "../assets/images/whiteLogo.png";
import CustomizedInputs from "../controls/InputCustom/CustomizedInputs";
import CustomButton from "../controls/Button/CustomButton";
import {
  getDataRegisterUser,
  registerUser,
  verifyRegister,
} from "../redux/actions/registerUsersActions";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../controls/Select/CustomSelect";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SaveModal from "../components/PersonalData/SaveModal";
import CustomDatePicker from "../controls/DatePicker/CustomizedDatePicker";
import ConfirmModal from "../components/PersonalData/ConfirmModal";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  openModal,
  setDescriptionError,
  validate,
} from "./UtilsFunctions";
import LoadingPage from "../components/LoadingPage/LoadingPage";
import { updateTag } from "../redux/actions/personPlayerActions";
import { useResizeScreen } from "../hooks/useResizeScreen";
import HomeIcon from "@material-ui/icons/Home";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { showMessageModal } from "../utils/utilsFunctions";

const Register = () => {
  const listType = [
    { value: "DNI", label: "DNI" },
    { value: "CIL", label: "C.U.I.L" },
    { value: "CUI", label: "C.U.I.T" },
    { value: "CI", label: "Cédula de Identidad" },
    { value: "DU", label: "Documento único" },
    { value: "E/T", label: "En trámite" },
    { value: "LC", label: "Libreta Cívica (Sólo sexo Femenino)" },
    { value: "LE", label: "Libreta Enrolamiento (Sólo sexo Masculino)" },
    { value: "PAS", label: "Pasaporte" },
    { value: "OTR", label: "Otros" },
  ];
  const classes = useStyles();
  const dispatch = useDispatch();
  const [typesDocument, setTypesDocument] = useState([]);
  const [type, setType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [birthDate, setbirthDate] = useState(new Date());
  const [dni, setDni] = useState("");
  const [credential, setCredential] = useState("");
  const { screen } = useResizeScreen();
  const userData = useSelector((state) => state.registerUsersReducer.dataUser);
  const loginValidation = useSelector((state) => state.registerUsersReducer.registerValidation);
  const campoObligatorio = "*Campo obligatorio";
  const [error, setError] = useState({
    parameterType: campoObligatorio,
    document: campoObligatorio,
    credential: campoObligatorio,
  });
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [validateLoginModal, setValidateLoginModal] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState({
    title: "",
    description: "",
    setModalIcon: (
      <CancelIcon
        className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`}
      />
    ),
    setModalErrorClass: classes.backgroundError,
  });
  const registerReduxState = useSelector((state) => state.registerUsersReducer);
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const history = useHistory();
  const [errorMessageModal, setErrorMessageModal] = useState(false);
  const errorMessage = useSelector((state) => state.registerUsersReducer.msgError);

  useEffect(() => {
    if (loginValidation?.estado === "BAJA") {
      setValidateLoginModal(true);
      let timer = setTimeout(() => RedirectLogin(), 3500);
        return () => {
          clearTimeout(timer);
        };
    }
  }, [loginValidation])

  const RedirectLogin = () => {
    window.localStorage.clear();
    const playerId = window.localStorage.getItem("playerId");
    const user = window.localStorage.getItem("email");
    dispatch(updateTag({ user: user, playerId: playerId, action: "LOGOUT" }));
    window.location.replace("/logout");
  };

  const formIsValid = () => Object.values(error).every((x) => x === "");

  const handleRegister = () => {
    if (formIsValid()) {
      let request = {
        mail: window.localStorage.getItem("email"),
        telefono: "",
        tipoDocumento: type,
        nroDocumento: dni,
        nroCredencial: credential,
        fechaNacimiento: format(birthDate, "yyyy-MM-dd"),
      };
      dispatch(registerUser(request));
      setLoading(true);
    }
  };

  const handleChangeDNIType = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setType(value);
    validate(name, "*Campo obligatorio", value, setError, error);
    setDni('');
    setCredential('');
    setError({...error, document: campoObligatorio, credential: campoObligatorio, parameterType: ''});
  };

  const validateSpecialCaracters = dniNumber => {
    if(type === "DNI") {
      return validateOnlyNumbers(dniNumber);
    } else {
      return dniNumber?.match(/^[a-zA-Z0-9]*$/);
    }
  }

  const validateOnlyNumbers = credentialNumber => {
    return credentialNumber?.match(/^\d*$/);
  }

  const handleChangeDNI = (e) => {
    e.preventDefault();
    setDni(e.target.value);
  };

  const handleOnBlurDNI = () => {
    validate('document', campoObligatorio, dni, setError, error);
    type === 'DNI' && dni?.length > 8 && setError({...error, document: '*El campo debe tener como máximo 8 caracteres'});
    type !== 'DNI' && dni?.length > 15 && setError({...error, document: '*El campo debe tener como máximo 15 caracteres'});
    !validateSpecialCaracters(dni) && setError({...error, document: 'El campo no debe tener carácteres especiales'});
  }

  const handleChangeCredential = (e) => {
    e.preventDefault();
    setCredential(e.target.value);
  };

  const handleOnBlurCredential = () => {
    validate('credential', campoObligatorio, credential, setError, error);
    credential?.length > 40 && setError({...error, credential: '*El campo debe tener como máximo 40 caracteres'});
    !validateOnlyNumbers(credential) && setError({...error, credential: '*El campo debe tener sólo números'});
  }

  useEffect(() => {
    let param = {};
    param.Email = window.localStorage.getItem("email");
    dispatch(getDataRegisterUser(param));
    setTypesDocument(listType);

    if(history.location.pathname === "/Register") {
      document.getElementById("root").className = "";
    } else {
      document.getElementById("root").className = "content";
    }
  }, []);

  useEffect(() => {
    if (openConfirm) {
      window.localStorage.setItem("registerFlag", "Registrando");
    }
  }, [openConfirm]);

  useEffect(() => {
    setDescriptionError(
      registerReduxState,
      setDescriptionModal,
      descriptionModal
    );

    if (loading && registerReduxState?.registerUser?.confirmacion) {
      let data = {
        mail: window.localStorage.getItem("email"),
      };
      dispatch(verifyRegister(data));
    }
  }, [
    registerReduxState?.registerUser,
  ]);

  useEffect(() => {
    if (loading && registerReduxState?.registerValidation?.confirmacion && registerReduxState?.registerValidation?.estado !== "BAJA") {
      window.localStorage.setItem("registerFlag", "Registrado");
      window.localStorage.setItem("expired", "false");
      window.localStorage.setItem(
        "numeroPersona",
        registerReduxState?.registerValidation?.numeroPersona
      );
      const playerId = window.localStorage.getItem("playerId");
      const user = window.localStorage.getItem("email");
      dispatch(updateTag({ user: user, playerId: playerId, action: "LOGIN" }));
      window.location.replace("/");
    }
  }, [registerReduxState?.registerValidation]);

  const handleLogout = () => {
    history.push("/logout");
  }

  useEffect(() => {
    if (errorMessage?.length > 0) {
      setErrorMessageModal(true);
    }
  }, [errorMessage]);

  useEffect(() => {
    openModal(registerReduxState, setOpenConfirmModal, setLoading);
  }, [descriptionModal]);

  useEffect(() => {
    if (openConfirmModal) {
      setTimeout(() => {
        RedirectLogin();
      }, 8000);
    }
  }, [openConfirmModal]);

  useEffect(() => {
    if (userData !== null) {
      let data = {
        mail: window.localStorage.getItem("email"),
      };
      if (userData !== "") {
        dispatch(verifyRegister(data));
      } else {
        setOpenConfirm(true);
        setLoading(false);
      }
    }
  }, [userData]);

  useEffect(()=>{
    if(!registerReduxState?.isLoading && !initialLoad){
      setTimeout(() => {
				setLoading(false);
			}, 2000);
    }
    setInitialLoad(false);
  },[registerReduxState?.isLoading])

  const logoToReturn = () => {
    let logoToShow;
    if(screen.sWidth > 920) {
      logoToShow = (
        <img
          src={Logo}
          alt="logo Luis Pasteur"
          className={classes.logoPageLogin}
          style={{ marginRight: screen.sWidth > 768 && "25%" }}
        />
      );
    } else {
      logoToShow = (
        <img
          src={whiteLogo}
          alt="logo Luis Pasteur"
          style={{
            width: screen.sWidth > 768 ? "40%" : "60%",
            position: "absolute",
            top: "0",
            right: screen.sWidth > 768 ? "30%" : "22%"
          }}
        />
      );
    }
    return logoToShow;
  }

  const showLoginError = () => {
    return (
      <Dialog
        escapeKeyDown={true}
        backdropClick={true}
        aria-labelledby="customized-dialog-title"
        open={loginValidation}
        id="modalTurn">
        <Box id="cancelModal">
          <div style={{ padding: '9px 24p !important' }}>
            <DialogTitle
              id="customized-dialog-title"
              style={{ backgroundColor: '#F65656', textAlign: 'center', color: '#FFF' }}>
              <CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
            </DialogTitle>
            <DialogContent style={{ backgroundColor: '#FFF', textAlign: 'center', margin: '15px' }}>
              <Typography variant="h5" component="div" sx={{ color: '#505152', fontWeight: 'bold', marginBottom: '10px' }}>
                Atención!
              </Typography>
              <Typography variant="subtitle1" component="div" sx={{ color: '#505152' }}>
                {loginValidation?.mensajes?.[0]}
              </Typography>
            </DialogContent>
          </div>
        </Box>
      </Dialog>
    );
  }

  return (
    <>
      {loading || loginValidation?.estado !== undefined ? (
        <div
          className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}
        >
          <TransitionGroup>
            <CSSTransition timeout={500} classNames={{ ...loading }}>
              <Suspense fallback={<LoadingPage />}></Suspense>
            </CSSTransition>
            { validateLoginModal && showLoginError() }
          </TransitionGroup>
        </div>
      ) : (
        <>
          <div className={`${classes.root}`}>
            <Grid container className={`${classes.contentRegister}`}>
              <Grid item md={6} className={`${classes.marginn0}`}>
                <img
                  src={Img}
                  className={`${classes.registerImg}`}
                  alt="Obra Social Luis Pasteur"
                />
              </Grid>
              <Grid
                item
                md={6}
                className={`${classes.boxUsefulPhones} ${classes.boxUsefulPhones2}`}
              >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <IconButton
                    color="inherit"
                    maria-label="open drawer"
                    onClick={()=>{RedirectLogin()}}
                    edge="start"
                    className={clsx(classes.homeRegisterButton, open)}
                  >
                    <HomeIcon />
                  </IconButton>
                  {
                    logoToReturn()
                  }
                </div>
                <Typography className={classes.registerTitle} style={{ marginTop: screen.sWidth >= 768 ? "7%" : ""}}>
                  Crear Cuenta
                </Typography>
                <Grid>
                  <FormControl
                    className={`${classes.inputLogin} ${classes.formControlCustomSelect} ${classes.formControlDatePicker}`}
                    variant="standard"
                  >
                    <CustomSelect
                      className={`${classes.selectDniLogin}`}
                      name="parameterType"
                      customRequired={true}
                      list={typesDocument}
                      onChange={handleChangeDNIType}
                      label="TIPO DE DOCUMENTO"
                    />
                  </FormControl>
                  <FormHelperText className={` ${classes.registerErrorText} `}>
                    {error?.parameterType}
                  </FormHelperText>
                  <FormControl
                    className={`${classes.inputLogin}`}
                    variant="standard"
                  >
                    <CustomizedInputs
                      name="document"
                      type='text'
                      label="NUMERO DE DOCUMENTO"
                      value={dni}
                      onChange={handleChangeDNI}
                      onBlur={handleOnBlurDNI}
                    />
                  </FormControl>
                  <FormHelperText
                    style={{ marginTop: '5px', color: screen.sWidth < 960 ? 'white' : '#00000099' }}
                    className={` ${classes.registerHelperText} `}
                  >
                    { type === 'DNI' || type === '' ?
                      'Son máximo 8 números sin espacios ni puntos'
                      :
                      'Son máximo 15 caracteres sin espacios ni puntos'
                    }
                  </FormHelperText>
                  <FormHelperText className={` ${classes.registerErrorText} `}>
                    {error?.document}
                  </FormHelperText>
                  <FormControl
                    className={` ${classes.inputLogin} `}
                    variant="standard"
                  >
                    <CustomizedInputs
                      name="credential"
                      label="NUMERO DE CREDENCIAL"
                      value={credential}
                      onChange={handleChangeCredential}
                      onBlur={handleOnBlurCredential}
                    />
                  </FormControl>
                  <FormHelperText
                    style={{ marginTop: '5px', color: screen.sWidth < 960 ? 'white' : '#00000099' }}
                    className={` ${classes.registerHelperText} `}
                  >
                    Son 11 números sin espacios, agregá ceros adelante si tenés menos
                  </FormHelperText>
                  <FormHelperText
                    className={` ${classes.registerErrorText} `}
                  >
                    {error?.credential}
                  </FormHelperText>

                  <FormControl
                    className={`${classes.inputLogin} ${classes.formControlDatePicker}`}
                    variant="standard"
                  >
                    <CustomDatePicker
                      onChange={setbirthDate}
                      customRequired={true}
                      value={birthDate}
                      label="FECHA DE NACIMIENTO"
                    />
                  </FormControl>
                </Grid>
                <Grid className={classes.flexRowBtnLogin}>
                  <CustomButton
                    title="CREAR CUENTA"
                    disabled={!formIsValid()}
                    customClass={`${classes.lightLinearGradient}`}
                    onClick={handleRegister}
                    style={{ marginTop: "30px", fontSize: "12px", padding: "9px 12px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>

          <SaveModal
            isOpen={openConfirm}
            setOpen={setOpenConfirm}
            onClick={RedirectLogin}
            modalRegister={true}
            escapeKeyDown={true}
            backdropClick={true}
            titleModal={
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <AccountCircleIcon
                    className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  ¿Sos Afiliado?
                </div>
              </>
            }
            descriptionModal=""
          />
          <ConfirmModal
            isOpen={openConfirmModal}
            setOpen={setOpenConfirmModal}
            description={descriptionModal.description}
            titleModal=""
            errorClass={descriptionModal.setModalErrorClass}
            escapeKeyDown={true}
            backdropClick={true}
          />
        </>
      )}

      {showMessageModal(errorMessage, errorMessageModal, setErrorMessageModal, classes.borderWhite, classes.quickAccessIcon, classes.contentDialogTitle, handleLogout)}
    </>
  );
};

export default Register;
