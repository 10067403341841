import React, { useEffect } from 'react';
import Menu from "../../components/Menu/Menu";
import Iframe from 'react-iframe';
import useStyles from "../../assets/styles/styles";
import useTrackPageView from "../../utils/useTrackPageView";

const RedirectPageContactAdvisor = () => {
  const classes = useStyles();
  const trackPageView = useTrackPageView("Contactar a un asesor");

  useEffect(() => {
    trackPageView();
  },[])

  return (
    <>
      <Menu />
      <div>
        <Iframe sandbox='' scrolling="yes" src={window.env.URL_ContactAdvisor}
          className={classes.iframe} />
      </div>
    </>
  );
};

export default RedirectPageContactAdvisor;
