import * as actionTypes from '../consts/actionTypes';
import {responseToReturn} from '../../utils/utilsFunctions.js';

const initialState = {
    areaParemeters: [],
    typeCoverage: [],
    refundCoverageResult: [],
    getCoveragePlans: [],
    msgError: ''
};

export default function coverageReducer(state = initialState, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_GET_TYPE_COVERAGE_PARAMETERS]: responseToReturn('typeCoverage', state, action),
        [actionTypes.SUCCESS_GET_AREA_PARAMETERS]: responseToReturn('areaParameters', state, action),
        [actionTypes.SUCCESS_POST_REFUND_COVERAGE]: responseToReturn('refundCoverageResult', state, action),
        [actionTypes.SUCCESS_GET_ALL_COVERAGE_PLANS]: responseToReturn('getCoveragePlans', state, action),
        [actionTypes.FAILURE_GET_TYPE_COVERAGE_PARAMETERS]: responseToReturn('', state, action),
        [actionTypes.FAILURE_GET_AREA_PARAMETERS]: responseToReturn('', state, action),
        [actionTypes.FAILURE_POST_REFUND_COVERAGE]: responseToReturn('', state, action),
        [actionTypes.FAILURE_GET_ALL_COVERAGE_PLANS]: responseToReturn('', state, action)
    };

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}