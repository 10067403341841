import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import "./accordion.css";
import useStyles from "../../assets/styles/styles";
import { formatPhoneNumber } from "../../utils/utilsFunctions";



const ButtonPhones = (props) => {
  const classes = useStyles();

  const lastItemNumber = (list, obj) => {
    let res = false;
    let lastNumber = list[list?.length - 1].value;
    if (lastNumber === obj) {
      res = true
    }
    return res;
  }

  return (
    <div
      className={classes.accordionButton}
      id="accordion">
      {props.listItems?.map((item, i) => (
        <Accordion expanded={true} key={i} className={classes.accordionItem} >
          <AccordionSummary
            key={i}
          >
            <Typography key={i} className={classes.headingTextBPhone}>
              {item.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails key={i}>
            {item.numbers.map((val, index) => (
              <a key={val} href={`tel:${formatPhoneNumber(val.value)?.replace(/[^\w]/gi, '')}`} className={classes.telBPhone}>
                {lastItemNumber(item.numbers, val.value) ?
                  <Typography paragraph className={classes.telBPhone}>
                    {formatPhoneNumber(val.value, false)}
                  </Typography>
                  :
                  <>
                    <Typography paragraph className={classes.telBPhone}>
                    {formatPhoneNumber(val.value, false)}
                    </Typography>
                  </>
                }
              </a>
            ))}
          </AccordionDetails>
        </Accordion>
  ))
}
    </div >
  );
};

export default ButtonPhones;
