import SpecialtyList from "../SpecialtyListDrawer";
import PhoneShiftsDrawer from "../PhoneShiftsDrawer";
import NextAppointmentDrawer from "../NextAppointmentDrawer";
import SelectDateDrawer from "../SelectDateDrawer/SelectDateDrawer";
import { useEffect, useState } from "react";
import useStyles from "../../../assets/styles/styles";
import { useSelector } from "react-redux";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import { CircularProgress } from "@mui/material";
import { checkSpecialistVisualizacion, getSpecialtyAndDoctorCodes } from "../Utils/CheckAppointmentVisualization";

const Step3 = (props) => {
    const { titles, activeStep, setActiveStep, handleOpenCancelModal, handleBack, handleCloseDrawer, handleNext } = props;
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const [preferenceStep3, setPrefereceStep3] = useState(0);
    const modelNextAppointment = useSelector(state => state.appointmentsReducer);
    const specialties = useSelector((state) => state.medicalCenterReducer?.specialtyList);
    const [modelAppointment, setModelAppointment] = useState(modelNextAppointment?.modelAppointment);
    const [ phoneAppointment, setPhoneAppointments ] = useState(undefined);
    const loading = useSelector((state) => state.medicalCenterReducer?.isLoading);
    const [ specialistVisualizacion, setSpecialistVisualizacion ] = useState("");

    useEffect(() => {
        setModelAppointment(modelNextAppointment?.modelAppointment);
    }, [modelNextAppointment?.modelAppointment]);

    useEffect(() => {
        if (!modelNextAppointment?.modelAppointment?.especialidadCodigo) {
            return;
        }

        const { agenda, specialtyCode, doctorCode } = getSpecialtyAndDoctorCodes(specialties, modelNextAppointment);
        const suspended = checkSpecialistVisualizacion(agenda, specialtyCode, doctorCode, setSpecialistVisualizacion);

        if (suspended !== undefined) {
            setPhoneAppointments(suspended);
        }

    }, [specialties]);

    return (<>
        <div className={classes.step3Div} style={{ padding: (screen.sWidth > 768 && "30px") }}>
            <div style={{ width: "90%" }}>
                <SpecialtyList preferenceStep3={preferenceStep3} setPrefereceStep3={setPrefereceStep3} titles={titles} activeStep={activeStep} setActiveStep={setActiveStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} />
            </div>
            {
                !loading ?
                <div style={{ width: "90%" }}>
                    {
                        ((modelNextAppointment?.modelModifyAppointment == 'modify' ||
                        modelNextAppointment?.modelModifyAppointment == 'repeat')  &&
                        modelNextAppointment?.medicoCodigo !== "" && !phoneAppointment) &&
                        <SelectDateDrawer
                            modelNextAppointment={modelNextAppointment} 
                            titles={props.titles}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            handleOpenCancelModal={handleOpenCancelModal}
                            handleNext={handleNext}
                            handleBack={handleBack}
                            handleCloseDrawer={handleCloseDrawer}
                        />
                    }
                    {
                        (preferenceStep3 == 1 || modelAppointment?.preference == 1) &&
                        <NextAppointmentDrawer
                            key={modelNextAppointment?.modelAppointment?.preference}
                            modelNextAppointment={modelNextAppointment}
                            titles={props.titles}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            handleOpenCancelModal={handleOpenCancelModal}
                            handleNext={handleNext}
                        />
                    }
                    {
                        ((preferenceStep3 == 2 || modelAppointment?.preference == 2) &&
                        (modelAppointment?.doctorVisualizacion == 'TURNO WEB' && !phoneAppointment) &&
                        modelAppointment?.medicoCodigo != '') &&
                        <SelectDateDrawer
                            modelNextAppointment={modelNextAppointment}
                            titles={props.titles}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            handleOpenCancelModal={handleOpenCancelModal}
                            handleNext={handleNext}
                            handleBack={handleBack}
                            handleCloseDrawer={handleCloseDrawer}
                        />
                    }
                    {
                        ((preferenceStep3 == 2 || modelAppointment?.preference == 2) &&
                        (modelAppointment?.doctorVisualizacion == 'TURNO TELEFONICO' ||
                        specialistVisualizacion == 'TURNO TELEFONICO') &&
                        modelAppointment?.medicoCodigo != '')  &&
                        <PhoneShiftsDrawer
                            modelNextAppointment={modelNextAppointment}
                            titles={props.titles}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            handleOpenCancelModal={handleOpenCancelModal}
                            handleNext={handleNext}
                            handleBack={handleBack}
                            handleCloseDrawer={handleCloseDrawer}
                        />
                    }
                </div>
                :
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress style={{ color: "#002858" }} />
                </div>
            }
        </div>
    </>);
};

export default Step3;