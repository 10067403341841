import { useEffect } from "react";

export default function useMessageModal(defaultModal, setMessageModal, errorMessageRedux) {
    let response = {
        modalTitle: defaultModal.defaultModalTitle,
        modalDescription: defaultModal.defaultModalDescription,
        modalIcon: defaultModal.defaultModalIcon,
        modalErrorClass: defaultModal.defaultModalErrorClass
    };
    useEffect(() => {
        if (errorMessageRedux !== '') {
            response.modalTitle = setMessageModal.setModalTitle;
            response.modalDescription = setMessageModal.setModalDescription;
            response.modalIcon = setMessageModal.setModalIcon;
            response.modalErrorClass = setMessageModal.setModalErrorClass;
        }
    }, [errorMessageRedux])
    return response;
}