import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetEmergencyNumbers() {
    try {
        const response = yield call(API.getEmergencyNumbers);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_EMERGENCY_NUMBERS, response, message: '' });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_GET_EMERGENCY_NUMBERS,
            message: error.message,
        });
    }
}


// Watchers
export default function* emergencySaga() {
    yield takeLatest(actionTypes.GET_EMERGENCY_NUMBERS, asyncGetEmergencyNumbers);
}
