import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetProcedures({ payload }) {
    try {
        const response = yield call(API.getProcedures, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_PROCEDURES, response, message: '' });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_GET_PROCEDURES,
            message: error.message,
        });
    }
}

function* asyncPostProcedure({ payload }) {
    try {
        let response = yield call(API.postProcedure, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_POST_PROCEDURE, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_POST_PROCEDURE, message: error.message });
    }
}
function* asyncGetDataAffiliatesBaja({ payload }) {
    try {
        let response = yield call(API.getDataAffiliatesBaja, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_DATOS_RECUPERA, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_DATOS_RECUPERA, message: error.message });
    }
}

function* asyncGetMotivosBaja() {
    try {
        let response = yield call(API.getMotivosBaja);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_MOTIVOS_BAJA, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_MOTIVOS_BAJA, message: error.message });
    }
}
function* asyncGetSolicitudBaja() {
    try {
        let response = yield call(API.getSolicitudBaja);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_SOLICITUD_BAJA, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_SOLICITUD_BAJA, message: error.message });
    }
}
function* asyncGetFile({ payload }) {
    try {
        let response = yield call(API.getFile, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_FILE, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_FILE, message: error.message });
    }
}

function* asyncPostRequestLowGetFile({ payload }) {
    try {
        let response = yield call(API.postRequestLow, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_POST_REQUEST_LOW, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_POST_REQUEST_LOW, message: error.message });
    }
}

function* asyncGetMotiveLowPlan() {
    try {
        let response = yield call(API.getMotiveLowPlan);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_MOTIVOS_BAJA_PLAN, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_MOTIVOS_BAJA_PLAN, message: error.message });
    }
}


function* asyncGetPlans({ payload }) {
    try {
        let response = yield call(API.getPlans, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_PLAN, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_PLAN, message: error.message });
    }
}


function* asyncGetPermisionLow({ payload }) {
    try {
        let response = yield call(API.getPermisionLow, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_PERMISSION_LOW, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_PERMISSION_LOW, message: error.message });
    }
}


function* asyncGetChangePlan() {
    try {
        const response = yield call(API.getChangePlan);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_CHANGE_PLAN, response, message: '' });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_GET_CHANGE_PLAN,
            message: error.message,
        });
    }
}

function* asyncPostChangePlan({ payload }) {
    try {
        let response = yield call(API.postChangePlan, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_POST_CHANGE_PLAN, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_POST_CHANGE_PLAN, message: error.message });
    }
}

// Watchers
export default function* procedureSaga() {
    yield takeLatest(actionTypes.GET_PROCEDURES, asyncGetProcedures);
    yield takeLatest(actionTypes.POST_PROCEDURE, asyncPostProcedure);
    yield takeLatest(actionTypes.GET_CHANGE_PLAN, asyncGetChangePlan);
    yield takeLatest(actionTypes.POST_CHANGE_PLAN, asyncPostChangePlan);
    yield takeLatest(actionTypes.GET_DATOS_RECUPERA, asyncGetDataAffiliatesBaja);
    yield takeLatest(actionTypes.GET_MOTIVOS_BAJA, asyncGetMotivosBaja);
    yield takeLatest(actionTypes.GET_SOLICITUD_BAJA, asyncGetSolicitudBaja);
    yield takeLatest(actionTypes.GET_FILE, asyncGetFile);
    yield takeLatest(actionTypes.POST_REQUEST_LOW, asyncPostRequestLowGetFile);
    yield takeLatest(actionTypes.GET_MOTIVOS_BAJA_PLAN, asyncGetMotiveLowPlan);
    yield takeLatest(actionTypes.GET_PLAN, asyncGetPlans);
    yield takeLatest(actionTypes.PERMISSION_LOW, asyncGetPermisionLow);

}
