import * as actionTypes from '../consts/actionTypes';
import {responseToReturn} from '../../utils/utilsFunctions.js'

const initialValues = {
    programs: [],
    allergiesParams: [],
    affiliatedAllergies: [],
    abmAllergies: [],
    affiliatedPhones: [],
    abmAffiliatedPhones: [],
    abmFactorGroup: [],
    factorGroup: [],
    factorGroupParams: [],
    abmChronicDiseases: [],
    chronicDiseases: [],
    chronicDiseasesParams: [],
    abmChronicMedication: [],
    chronicMedication: [],
    weightHeight: {},
    vitalParameters: {},
    abweight: {},
    ABVital: {},
    laboratoryStudies: {},
    detailLaboratory: {},
    vaccine: [],
    pharmaceuticalForm: [],
    recipe: [],

    msgError: ''
};

export default function medicalDataReducer(state = initialValues, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_GET_DATA_WHEIGHT_HEIGHT]: response('weightHeight', state, action),
        [actionTypes.SUCCESS_AB_WHEIGHT_HEIGHT]: response('abweight', state, action),
        [actionTypes.SUCCESS_GET_VITAL_PARAMETERS]: response('vitalParameters', state, action),
        [actionTypes.SUCCESS_AB_VITAL_PARAMETERS]: response('ABVital', state, action),
        [actionTypes.SUCCESS_GET_PROGRAMS]: responseToReturn('programs', state, action),
        [actionTypes.SUCCESS_GET_ALLERGIES_PARAMS]: response('allergiesParams', state, action),
        [actionTypes.SUCCESS_GET_AFFILIATED_ALLERGIES]: response('affiliatedAllergies', state, action),
        [actionTypes.SUCCESS_ABM_ALLERGIES]: response('abmAllergies', state, action),
        [actionTypes.SUCCESS_GET_AFFILIATED_PHONES]: response('affiliatedPhones', state, action),
        [actionTypes.SUCCESS_ABM_MD_PHONES]: response('abmAffiliatedPhones', state, action),
        [actionTypes.SUCCESS_GET_LABORATORY_STUDIES]: responseToReturn('laboratoryStudies', state, action),
        [actionTypes.SUCCESS_GET_DETAIL_LABORATORY]: responseToReturn('detailLaboratory', state, action),
        [actionTypes.SUCCESS_ABM_FACTOR_GROUP]: response('abmFactorGroup', state, action),
        [actionTypes.SUCCESS_GET_FACTOR_GROUP]: response('factorGroup', state, action),
        [actionTypes.SUCCESS_GET_FACTOR_GROUP_PARAMS]: response('factorGroupParams', state, action),
        [actionTypes.SUCCESS_ABM_CHRONIC_DISEASES]: response('abmChronicDiseases', state, action),
        [actionTypes.SUCCESS_GET_CHRONIC_DISEASES]: response('chronicDiseases', state, action),
        [actionTypes.SUCCESS_GET_CHRONIC_DISEASES_PARAMS]: response('chronicDiseasesParams', state, action),
        [actionTypes.SUCCESS_ABM_CHRONIC_MEDICATION]: response('abmChronicMedication', state, action),
        [actionTypes.SUCCESS_GET_CHRONIC_MEDICATION]: response('chronicMedication', state, action),
        [actionTypes.SUCCESS_GET_VACCINE]: responseToReturn('vaccine', state, action),
        [actionTypes.SUCCESS_GET_PHARMACEUTICAL_FORM]: response('pharmaceuticalForm', state, action),


        [actionTypes.GET_RECIPE]: response("recipe", state, action),
        [actionTypes.SUCCESS_GET_RECIPE]: responseToReturn('recipe', state, action),
        [actionTypes.FAILURE_GET_RECIPE]: responseToReturn('recipe', state, action),



        [actionTypes.FAILURE_GET_DATA_WHEIGHT_HEIGHT]: response('', state, action),
        [actionTypes.FAILURE_AB_WHEIGHT_HEIGHT]: response('', state, action),
        [actionTypes.FAILURE_GET_VITAL_PARAMETERS]: response('', state, action),
        [actionTypes.FAILURE_AB_VITAL_PARAMETERS]: response('', state, action),
        [actionTypes.FAILURE_GET_PROGRAMS]: responseToReturn('', state, action),
        [actionTypes.FAILURE_GET_ALLERGIES_PARAMS]: response('', state, action),
        [actionTypes.FAILURE_GET_AFFILIATED_ALLERGIES]: response('', state, action),
        [actionTypes.FAILURE_ABM_ALLERGIES]: response('', state, action),
        [actionTypes.FAILURE_GET_AFFILIATED_PHONES]: response('', state, action),
        [actionTypes.FAILURE_ABM_MD_PHONES]: response('', state, action),
        [actionTypes.FAILURE_GET_LABORATORY_STUDIES]: responseToReturn('', state, action),
        [actionTypes.FAILURE_GET_DETAIL_LABORATORY]: responseToReturn('', state, action),
        [actionTypes.FAILURE_ABM_FACTOR_GROUP]: response('', state, action),
        [actionTypes.FAILURE_GET_FACTOR_GROUP]: response('', state, action),
        [actionTypes.FAILURE_GET_FACTOR_GROUP_PARAMS]: response('', state, action),
        [actionTypes.FAILURE_ABM_CHRONIC_DISEASES]: response('', state, action),
        [actionTypes.FAILURE_GET_CHRONIC_DISEASES]: response('', state, action),
        [actionTypes.FAILURE_GET_CHRONIC_DISEASES_PARAMS]: response('', state, action),
        [actionTypes.FAILURE_ABM_CHRONIC_MEDICATION]: response('', state, action),
        [actionTypes.FAILURE_GET_CHRONIC_MEDICATION]: response('', state, action),
        [actionTypes.FAILURE_GET_VACCINE]: responseToReturn('', state, action),
        [actionTypes.FAILURE_GET_PHARMACEUTICAL_FORM]: response('', state, action),
    };


   

    

    
    function response(item, state, action) {
        let res = { ...state };
        if (action.response) {
            res = { ...state, [item]: action.response.data.data };
        }
        if (action.message) {
            res = { ...state, msgError: action.message };
        }
        return res;
    }

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}