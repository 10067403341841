import React, { useEffect, Fragment, useState } from 'react'
import { useResizeScreen } from '../../../hooks/useResizeScreen';
import useStyles from '../../../assets/styles/styles';
import FormHeader from '../../PersonalData/FormHeader';
import CustomizedInputs from '../../../controls/InputCustom/CustomizedInputs';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, FormControl, IconButton, InputAdornment, Typography } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { getWayToPay } from "../../../redux/actions/billingActions";
import NotFound from '../../HomeLogged/NotFound';

const userCode = parseInt(window.localStorage.getItem("numeroPersona"));
const affiliated = {
    numeroPersona: userCode
};

const WayToPay = () => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const dispatch = useDispatch();
    const data = useSelector(state => state.billingReducer?.wayToPay);
    const [wayToPay, setWayToPay] = useState([]);

    useEffect(() => {
        dispatch(getWayToPay(affiliated));
    }, []);

    useEffect(() => {
        setWayToPay(data)
    }, [data]);

    function formHeader() {
        let res = {
            title: 'MEDIOS DE PAGO DIGITALES',
            subtitle: ''
        };
        if (screen.sWidth < 768) {
            res.title = 'MEDIOS DE PAGO DIGITALES';
            res.subtitle = '';
        }
        return res;
    }

    const handleGoToHome = () => {
        window.location.href = '/Billing';
    };

    return (
        <>
            <FormHeader title={formHeader().title} subtitle={formHeader().subtitle} handleGoTo={handleGoToHome} />
            {
                screen.sWidth > 768 &&
                <Divider className={classes.formDivider} />
            }
            <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`} style={{ justifyContent: 'flex-start' }}>
                {(screen.sWidth <= 768) && (wayToPay !== undefined) && (wayToPay?.length > 0) ?
                    <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency} ${classes.subtitleSelectWayToPay}`}>
                        SELECCIONA EL MEDIO DE PAGO
                    </Typography>
                    :
                    ""
                }
            </div>
            {
                (wayToPay !== undefined) && (wayToPay?.length > 0) ?
                    <div className={classes.wayToPayDiv}>
                        {wayToPay?.map((item, i) => (
                            <Fragment key={i}>
                                    <div className={classes.wayToPayItemDiv}>
                                        <div className={classes.wayToPayButtonImage} style={{ margin: screen.sWidth > 768 ? "10px 0" : "10px 25px" }}>
                                            <IconButton className={classes.buttonImgWayToPay} href={item?.url} target="_blank" rel="noopener noreferrer">
                                                <img
                                                    src={item?.image}
                                                    height="40px"
                                                    width="130px"
                                                />
                                            </IconButton>
                                        </div>
                                        <div className={`${classes.wayToPayMercadoPago}`}>
                                            <>
                                                <Typography className={classes.wayToPayTitle}>
                                                    OBSERVACIONES
                                                </Typography>
                                                <Typography className={`${classes.wayToPaySubtitle}`} >
                                                    {item?.observation}
                                                </Typography>
                                            </>
                                        </div>
                                        {
                                            item?.code !== "" &&
                                            <FormControl variant="standard" style={{ margin: "10px 0" }}>
                                                <CustomizedInputs
                                                    label='CODIGO DE REFERENCIA DE PAGO'
                                                    value={item?.code}
                                                    disabled={true}
                                                    endAdornment={
                                                        <InputAdornment position="start">
                                                            <IconButton onClick={() => { navigator.clipboard.writeText(item?.code) }}>
                                                                <FileCopyOutlinedIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        }
                                    </div>
                            </Fragment>
                        ))}
                    </div>
                    :
                    <NotFound
                        customMsg={
                            <div className={classes.contentNotFound}>
                                <span style={{ color: '#0075c9', margin: '10px' }}>No tenés formas de pago</span>
                            </div>}
                        customClass={classes.flexStartJustifyContent}
                        wsItem={wayToPay}
                        fullItem={wayToPay}
                    />
            }
        </>
    )
}

export default WayToPay
