import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import { useSelector } from "react-redux";
import useStyles from "../../assets/styles/styles";
import useTrackPageView from "../../utils/useTrackPageView";

const RedirectToActivities = () => {
    const classes = useStyles();
    const trackPageView = useTrackPageView("Actividades");

    const fullDataAffiliatedReduxState = useSelector(
        (state) => state.personalDataReducer?.fullData?.afiliado
    );

    const [loading, setLoading] = useState(true);

    const circularProgressStyles = {
        circularProgress: { display: "flex", justifyContent: "center", flex: "0 0 100%", paddingTop: "30px" }
    }

    useEffect(() => {
        fullDataAffiliatedReduxState !== undefined && setLoading(false);
    }, [fullDataAffiliatedReduxState]);

    useEffect(() => {
        trackPageView();
    }, [])

    const formatAffiliateNumber = () => fullDataAffiliatedReduxState?.numeroAfiliado?.replace(/\//g, "");

    return (
        <>
            {
                loading ?
                    (
                        <div style={ circularProgressStyles.circularProgress }>
                            <CircularProgress />
                        </div>
                    )
                :
                    (
                        <Iframe
                            sandbox=""
                            scrolling="yes"
                            src={`https://www.oslpasteur.com.ar/portal/Afiliados/inscripMobile.asp?p=${formatAffiliateNumber()}`}
                            className={classes.iframe}
                        />
                    )
            }
        </>
    );
};

export default RedirectToActivities;
