export function useFormHeaderText(titleDesktop, subtitleDesktop, titleMobile, subtitleMobile, sWidth) {
    let response = {
        titleHeader: titleDesktop,
        subtitleHeader: subtitleDesktop
    };
    if (sWidth < 768) {
        response.titleHeader = titleMobile;
        response.subtitleHeader = subtitleMobile;
    }
    return response;
}