import React from 'react'
import { reactPlugin } from "./AppInsights";
import { AppInsightsContext, useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const AppInsightsContextProvider = ({children}) => {
    return (
      <AppInsightsContext.Provider value={reactPlugin}>
        {children}
      </AppInsightsContext.Provider>
    )
  }


export { AppInsightsContextProvider, useAppInsightsContext }