
import React, { useEffect, useState } from "react";
import { Button, ClickAwayListener, IconButton } from "@material-ui/core";
import BadgeNotification from "../Notification/BadgeNotification";
import useStyles from "../../assets/styles/styles";
import NotificationsIcon from '@material-ui/icons/Notifications';
import CardNotification from "./CardNotification";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setCount, setNotification,removeModifyNotification, getAllNotifications } from "../../redux/actions/notificationsActions";
import {BroadcastChannel} from 'broadcast-channel';
import { Typography } from "@mui/material";

const DropdownNotification = () => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const notificacionReducer = useSelector((state) => state.notificationReducer.notifications);
    const [openNotification, setOpenNotification] = useState(false);
    const counterNotification = useSelector(state => state.notificationReducer.countNotification);
    const modifyNotificationsReducer = useSelector(state => state.notificationReducer.modifyNotifications);
    const history = useHistory();
    const allNotificationsViewed = notificacionReducer?.notification?.every(notification => notification.viewed === true);

    /***SHOW NOTIFICATION LIST */
    const handleClickAway = () => {
        setOpenNotification(false);
    };

    const handleClick = () => {
        dispatch(setNotification(0));
        setOpenNotification((prev) => !prev);
    };
    /************END SHOW NOTIFICATION */

     //***Notification prueba */
    const channel = new BroadcastChannel('displayed-messages');
    channel.onmessage= function(event){
        setTimeout(function(){        
             dispatch(getAllNotifications({email: window.localStorage.getItem('email')}));
         }, 3000);
    }

/*** */

    useEffect(() => {
        let notReview = notificacionReducer?.notification?.filter(x=>!x.viewed);
        if(notReview != undefined){
            dispatch(setCount(notReview.length))
        }
    }, [notificacionReducer])

    useEffect(()=>{
        if(modifyNotificationsReducer){
            dispatch(removeModifyNotification(false));
            dispatch(getAllNotifications({email: window.localStorage.getItem('email')}));
        }
    },[modifyNotificationsReducer])

    return (
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
            <div style={{ position: "relative" }}>
                {counterNotification > 0 ?
                    <BadgeNotification
                        childrenItem={
                            <IconButton
                                style={{ padding: '0px' }}
                                edge="start"
                                className={classes.menuButtonRight}
                                color="inherit"
                                onClick={handleClick}
                            >
                                <NotificationsIcon />
                            </IconButton>}
                        countNotification={counterNotification}
                    />
                    :
                    <IconButton
                        style={{ padding: '0px' }}
                        edge="start"
                        className={classes.menuButtonRight}
                        color="inherit"
                        onClick={handleClick}
                    >
                        <NotificationsIcon />
                    </IconButton>
                }
                {openNotification ? (
                    <div className={classes.dropdownNotification} style={{ width: '330px' }}>

                        <div className={classes.heightDivRegisters}>
                            <div style={{ display: 'flex', justifyContent: allNotificationsViewed ? 'space-evenly' : 'flex-start', alignItems: 'center' }}>
                                <Button
                                    onClick={() => { history.push('/AllNotifications') }}
                                    className={`${classes.customButtomNotification} ${classes.lightLinearGradient}`}
                                    style={{ marginTop: "10px", boxShadow: '0px 2px 8px 0 #585858', marginBottom: "10px" }}>
                                    Ver todas
                                </Button>
                                {
                                    allNotificationsViewed &&
                                    <Typography className={`${classes.txtSubtitle}`} style={{ fontSize: '11px', fontWeight: 'bold' }}>
                                        No hay notificaciones sin leer
                                    </Typography>
                                }
                            </div>
                            { notificacionReducer?.notification?.map((item, i) => (
                                item.viewed ? "" : 
                                    <CardNotification key={i}  notification={item} position={i} />
                            ))}
                        </div>

                    </div>)
                    : ""}
            </div>
        </ClickAwayListener>);


};

export default DropdownNotification;


