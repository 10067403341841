import React from 'react';
import useStyles from "../../assets/styles/styles";
const QuickAccess = (props) => {
    const classes = useStyles();
    const { children, index, hasNotification, customClass } = props;
    const setIconColor = (i) => {
        let cssClass = classes.ShowMoreArrowIcon;
        if (i % 2 === 0) return classes.txtDarkBlue;
        return cssClass;
    };

    return <>
        <div style={{ border: hasNotification ? '3px solid' : 'none' }} className={`${classes.backgroundToLightGray} ${classes.lightBoxShadow} ${classes.quickAccess} ${setIconColor(index)} ${customClass}`}>
            {children}
        </div>
    </>;
};

export default React.memo(QuickAccess,(prevState, nextState) => {
    return prevState === nextState
});