import * as actionTypes from '../consts/actionTypes';

export function getWayToPay(param) {
    return {
        type: actionTypes.GET_WAYTOPAY,
        payload: param
    };
}

export function getEnabledDirectDebit(param) {
    return {
        type: actionTypes.GET_ENABLED_DIRECTDEBIT,
        payload: param
    };
}

export function getDirectDebit(param) {
    return {
        type: actionTypes.GET_DIRECTDEBIT,
        payload: param
    };
}

export function abDirectDebit(param) {
    return {
        type: actionTypes.AB_DIRECTDEBIT,
        payload: param
    };
}

export function getAllBills(param) {
    return {
        type: actionTypes.GET_BILLINGS,
        payload: param
    };
}

export function getAffiliateBillingFiles(param) {
    return {
        type: actionTypes.GET_BILLINGS_FILES,
        payload: param
    };
}

export function setABDirectDebit(param) {
    return {
        type: actionTypes.SET_AB_DIRECTDEBIT,
        payload: param
    };
}