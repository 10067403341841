import * as actionTypes from "../consts/actionTypes";

/**acciones que se disparan del componente */
export function getAfilliatedAppointments(afilliated) {
  return {
    type: actionTypes.GET_AFILLIATED_APPOINTMENTS,
    payload: afilliated,
  };
}


export function getAfilliatedAppointmentsNext(param) {
  return {
    type: actionTypes.GET_AFILLIATED_APPOINTMENTS_NEXT,
    payload: param,
  };
}

export function getAfilliatedAppointmentsRecord(param) {
  return {
    type: actionTypes.GET_AFILLIATED_APPOINTMENTS_RECORD,
    payload: param,
  };
}

export function getAppointmentFavorite(param) {
  return {
    type: actionTypes.GET_APPOINTMENTS_FAVORITE,
    payload: param,
  };
}

export function getAnswerQuestion() {
  return {
    type: actionTypes.GET_ANSWER_QUESTION,
    payload: "",
  };
}

export function getDataGroupFamily(param) {
  return {
    type: actionTypes.GET_DATA_GROUP_FAMILY,
    payload: param,
  };
}

export function getDataFilterAppointment(param) {
  return {
    type: actionTypes.GET_DATA_FILTER_APPOINTMENT,
    payload: param,
  };
}

export function AppointmentCanceled(param) {
  return {
    type: actionTypes.GET_APPOINTMENTS_CANCELED,
    payload: param,
  };
}

export function ABAppointmentFavorite(param) {
  return {
    type: actionTypes.GET_APPOINTMENTS_AB,
    payload: param,
  };
}

export function getSpecialties(param) {
  return {
    type: actionTypes.GET_SPECIALTY_LIST,
    payload: param,
  };
}

export function getMedicalCenters(param) {
  return {
    type: actionTypes.GET_MEDICAL_CENTERS,
    payload: param,
  };
}

export function getNextAvailableAppointments(params) {
  return {
    type: actionTypes.GET_NEXT_AVAILABLE_APPOINTMENTS,
    payload: params
  };
}


export function getNextAvailableAppointmentsDoctor(params) {
  return {
    type: actionTypes.GET_NEXT_AVAILABLE_APPOINTMENTS_DOCTOR,
    payload: params
  };
}

export function setModelToSaveAppointment(model) {
  return {
    type: actionTypes.SET_MODEL_TO_SAVE_APPOINTMENT,
    payload: model
  };
}

export function getModelToSaveAppointment() {
  return {
    type: actionTypes.GET_MODEL_TO_SAVE_APPOINTMENT,
    payload: ''
  };
}

export function saveAppointment(model) {
  return {
    type: actionTypes.SAVE_APPOINTMENT,
    payload: model
  };
}

export function AppointmentConfirm(model) {
  return {
    type: actionTypes.GET_APPOINTMENTS_CONFIRMED,
    payload: model
  };
}

export function getFullGroupFamilyAppointment(param) {
  return {
    type: actionTypes.GET_FULL_GROUP_FAMILY_APPOINTMENT,
    payload: param
  };
}

export function clearModelAppointment() {
  return {
    type: actionTypes.CLEAR_MODEL_APPOINTMENT,
    payload: ''
  };
}

export function clearAffilliate() {
  return {
    type: actionTypes.CLEAR_AFFILLIATE,
    payload: ''
  };
}

export function setModifyAppointment(param) {
  return {
    type: actionTypes.SET_MODIFY_APPOINTMENT,
    payload: param
  };
}

export function clearMsgError() {
  return {
    type: actionTypes.CLEAR_APPOINTMENT_MSG_ERROR,
    payload: ''
  };
}

export function getSpecialtiesList(param) {
  return {
    type: actionTypes.GET_SPECIALTIES_LIST,
    payload: param,
  };
}

export function getDoctorsList(param) {
  return {
    type: actionTypes.GET_DOCTORS_LIST,
    payload: param,
  };
}

export function getFreeDoctorAppointmentsList(param) {
  return {
    type: actionTypes.GET_FREE_DOCTOR_APPOINTMENTS_LIST,
    payload: param,
  };
}