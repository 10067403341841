import { useEffect, useState, Fragment } from "react";
import useStyles from "../../../assets/styles/styles";
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import CustomButton from "../../../controls/Button/CustomButton";
import CustomizedInputs from "../../../controls/InputCustom/CustomizedInputs";
import SaveModal from "../../PersonalData/SaveModal";
import ConfirmModal from "../../PersonalData/ConfirmModal";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CustomSelect from "../../../controls/Select/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { abmChronicMedication, getChronicMedication } from "../../../redux/actions/medicalDataAction";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import CancelIcon from "@material-ui/icons/Cancel";
import useMessageModal from "../../../hooks/useMessageModal";
import NotFound from "../../HomeLogged/NotFound";
import { handleGoToPersonalMedicalData, showMessageModal } from "../../../utils/utilsFunctions";

const frecuencia = [
  {
    value: "DIA",
    label: "DIA",
  },
  {
    value: "SEMANA",
    label: "SEMANA",
  },
  {
    value: "MES",
    label: "MES",
  },
];

const ChronicMedicationForm = ({ disabled, setDisabled }) => {
  const classes = useStyles();
  const { screen } = useResizeScreen();
  const dispatch = useDispatch();
  const campoObligatorio = "*Campo obligatorio";
  const [error, setError] = useState({
    medicamentoDescripcion: campoObligatorio,
      medicamentoDosis: campoObligatorio,
      medicamentoFormaFarmaceutica: campoObligatorio,
      medicamentoCantidad: campoObligatorio,
      medicamentoFrecuencia: campoObligatorio,
  });
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [auxMedication, setAuxMedication] = useState({
    medicamentoDescripcion: "",
    medicamentoDosis: "",
    medicamentoFormaFarmaceutica: "",
    medicamentoCantidad: "",
    medicamentoFrecuencia: "",
    medicamentoObservacion: "",
  });
  const [medication, setMedication] = useState([]);

  const errorMessageMedication = useSelector((state) => state.medicalDataReducer?.msgError);
  const defaultModalMedication = {
    defaultModalTitle: "Enviado correctamente",
    defaultModalDescription: "Los cambios han sido realizados.",
    defaultModalIcon: (
      <CheckCircleOutlineIcon
        className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`}
      />
    ),
    defaultModalErrorClass: "",
  };
  const setMessageModalMedication = {
    setModalTitle: "Ocurrió un error",
    setModalDescription: "Ocurrió un error al enviar los datos.",
    setModalIcon: (
      <CancelIcon
        className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`}
      />
    ),
    setModalErrorClass: classes.backgroundError,
  };
  const { modalTitle, modalDescription, modalIcon, modalErrorClass } =
    useMessageModal(
      defaultModalMedication,
      setMessageModalMedication,
      errorMessageMedication
    );
  const [mode, setMode] = useState(false);
  const [removeMedication, setRemoveMedication] = useState("");
  const chronicMedication = useSelector((state) => state.medicalDataReducer?.chronicMedication);
  const pharmaceuticalForm = useSelector((state) => state.medicalDataReducer?.pharmaceuticalForm);
  const [pharmFormList, setPharmFormList] = useState([{ value: 0, label: '' }]);
  const [errorMessageModal, setErrorMessageModal] = useState(false);

  useEffect(() => {
    if (errorMessageMedication?.length > 0) {
      setErrorMessageModal(true);
    }
  }, [errorMessageMedication]);

  useEffect(() => {
    const param = {
      numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
      count: 10,
      skip: 0
    };
    dispatch(getChronicMedication(param));
  }, [openConfirm, open])

  function addMedication() {
    if (medication !== undefined) {
      medication.push(auxMedication);
    }
  }

  const handleDelete = (medicationItem) => (e) => {
    e.preventDefault();
    const medicationWOItem = medication?.filter((x) => x !== medicationItem);
    setMedication(medicationWOItem);
    setRemoveMedication(medicationItem);
    setMode(false);
    setOpen((prevState) => !prevState);
  };

  const handleChangeInput = (pharmFormType) => (e) => {
    const { name, value } = e.target;
    if (value !== "") {
      let values = { ...auxMedication, [name]: value };
    if (pharmFormType) {
      values = { ...auxMedication, [name]: pharmFormList[value]?.label };
    }
    setAuxMedication(values);
    setError({...error, [name]: ""});
    } else {
      setError({...error, [name]: campoObligatorio});
    }
  };

  const handleChangeChronicMedicationInput = () => (e) => {
    if(e.target.value > 0 && e.target.value <= 9999999) {
      e.target.value = Number.parseFloat(e.target.value)?.toFixed(2);
      let values = { ...auxMedication, [e.target.name]: (e.target.value)?.toString()?.replace(',', '.') };
      setError({ ...error, [e.target.name]: '' });
      setAuxMedication(values);
    } else if(e.target.value > 9999999) {
      setError({ ...error, [e.target.name]: 'La dosis del medicamento excede el máximo' });
    } else if(e.target.value <= 0) {
      setError({ ...error, [e.target.name]: 'La dosis del medicamento debe ser mayor a 0' });
    }
  };

  const handleClickModal = () => {
    if (validateFormMedication(error)) {
      setMode(true);
      addMedication();
      setOpen((prevState) => !prevState);
    }
  };

  useEffect(() => {
    setMedication(chronicMedication?.medicamentos);
  }, [chronicMedication?.medicamentos]);

  useEffect(() => {
    if (Object.values(pharmaceuticalForm).length > 0) {
      if (pharmaceuticalForm?.confirmacion) {
        let auxList = [];
        Object.entries(pharmaceuticalForm?.formasFarmaceuticas).forEach((key) => {
          let item = { value: key[0], label: key[1].formaFarmaceutica?.trimEnd() };
          auxList.push(item);
        });
        setPharmFormList(auxList);
      }
    }
  }, [pharmaceuticalForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mode) {
      if (validateFormMedication(error)) {
        dispatch(
          abmChronicMedication({
            numeroPersona: parseInt(
              window.localStorage.getItem("numeroPersona")
            ),
            medicamentoDescripcion: auxMedication?.medicamentoDescripcion?.trim(),
            medicamentoDosis: Number.parseFloat(auxMedication?.medicamentoDosis),
            medicamentoFormaFarmaceutica:
              auxMedication?.medicamentoFormaFarmaceutica?.trim(),
            medicamentoCantidad: Number.parseInt(
              auxMedication?.medicamentoCantidad
            ),
            medicamentoFrecuencia: auxMedication?.medicamentoFrecuencia?.trim(),
            medicamentoObservacion: auxMedication?.medicamentoObservacion?.trim(),
            modo: "A",
          })
        );
        setDisabled((prevState) => !prevState);
      }
    } else {
      dispatch(
        abmChronicMedication({
          numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
          medicamentoDescripcion: removeMedication?.medicamentoDescripcion?.trim(),
          medicamentoDosis: Number.parseFloat(removeMedication?.medicamentoDosis),
          medicamentoFormaFarmaceutica:
            removeMedication?.medicamentoFormaFarmaceutica?.trim(),
          medicamentoCantidad: Number.parseInt(
            removeMedication?.medicamentoCantidad
          ),
          medicamentoFrecuencia: removeMedication?.medicamentoFrecuencia?.trim(),
          medicamentoObservacion: auxMedication?.medicamentoObservacion?.trim(),
          modo: "B",
        })
      );
    }
    setOpen((prevState) => !prevState);
    setOpenConfirm((prevState) => !prevState);
  };

  function validateFormMedication(object) {
    const validation = [];
    if (Object.values(object).every((x) => x === "")) {
      validation.push(true);
    } else {
      validation.push(false);
    }
    setError(object);
    return Object.values(validation).every((valid) => valid === true);
  }

  const accordionItem = (i, item) => {
    return (
      <>
        <Accordion key={i}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              {screen.sWidth <= 768 ?
                <Typography className={classes.titleAccordionChronic}>
                  {item.medicamentoDescripcion}
                </Typography> :
                <Typography className={classes.titleAccordionChronic}>
                  {"Descripcion"}
                </Typography>
              }
              <div style={{ width: "100%" }}>
                <Divider className={classes.dividerMedicalCenter} />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {
              <>
                <div className={classes.displayFlex}>
                  <Typography
                    className={classes.detailsAccordionChronic}
                  >{`FORMA FARMACEUTICA: ${item?.medicamentoFormaFarmaceutica}`}</Typography>
                </div>
                <div className={classes.displayFlex}>
                  <Typography
                    className={classes.detailsAccordionChronic}
                  >{`DOSIS: ${Number.parseFloat(item?.medicamentoDosis).toFixed(2)}`}</Typography>
                </div>
                <div className={classes.displayFlex}>
                  <Typography
                    className={classes.detailsAccordionChronic}
                  >{`CANTIDAD: ${item?.medicamentoCantidad}`}</Typography>
                </div>
                <div className={classes.displayFlex}>
                  <Typography
                    className={classes.detailsAccordionChronic}
                  >{`FRECUENCIA: ${item?.medicamentoFrecuencia}`}</Typography>
                </div>
                <div className={classes.displayFlex}>
                  <Typography
                    className={classes.detailsAccordionChronic}
                  >{`OBSERVACIONES: ${item?.medicamentoObservacion}`}</Typography>
                </div>
              </>
            }
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  const addForm = (
    <>
      <FormControl
        className={classes.formControlAddress}
        variant="standard"
      >
        <CustomizedInputs
          name="medicamentoDescripcion"
          onChange={handleChangeInput()}
          disabled={disabled}
          label="MEDICAMENTO"
        />
        <FormHelperText className={classes.errorText}>
          {error?.medicamentoDescripcion}
        </FormHelperText>
      </FormControl>
      <FormControl
        className={classes.formControlAddress}
        variant="standard"
      >
        <CustomizedInputs
          name="medicamentoDosis"
          onChange={handleChangeChronicMedicationInput()}
          disabled={disabled}
          label="DOSIS"
          type="number"
          inputMode="numeric"
        />
        <FormHelperText className={classes.errorText}>
          {error?.medicamentoDosis}
        </FormHelperText>
      </FormControl>
      <FormControl
        className={`${classes.formControlChronicMedicationForm}`}
        variant="standard"
      >
        <CustomSelect
          name="medicamentoFormaFarmaceutica"
          disabled={disabled}
          list={pharmFormList}
          onChange={handleChangeInput(true)}
          label="FORMA FARMACEUTICA"
        />
        <FormHelperText className={classes.errorText}>
          {error?.medicamentoFormaFarmaceutica}
        </FormHelperText>
      </FormControl>
      <FormControl
        className={classes.formControlAddress}
        variant="standard"
      >
        <CustomizedInputs
          name="medicamentoCantidad"
          onChange={handleChangeInput()}
          disabled={disabled}
          type="number"
          label="CANTIDAD DE VECES"
        />
        <FormHelperText className={classes.errorText}>
          {error?.medicamentoCantidad}
        </FormHelperText>
      </FormControl>
      <FormControl
        className={`${classes.formControlChronicMedicationForm}`}
        variant="standard"
      >
        <CustomSelect
          name="medicamentoFrecuencia"
          disabled={disabled}
          list={frecuencia}
          onChange={handleChangeInput()}
          label={"FRECUENCIA TEMPORAL"}
        />
        <FormHelperText className={classes.errorText}>
          {error?.medicamentoFrecuencia}
        </FormHelperText>
      </FormControl>
    </>
  );

  const editForm = () => {
    if (screen.sWidth <= 768) {
      return (
        <>
          {addForm}
          <FormControl
            className={classes.formControlAddress}
            variant="standard"
          >
            <CustomizedInputs
              name="medicamentoObservacion"
              onChange={handleChangeInput()}
              disabled={disabled}
              label="OBSERVACIONES"
              multiline
              rows={6}
              inputProps={{ maxLength: 200 }}
            />
            <FormHelperText className={classes.errorText}>
              {error?.medicamentoObservacion}
            </FormHelperText>
          </FormControl>
        </>
      );
    } else {
      return (
        <>
          <div style={{ display: "flex", flexWrap: "nowrap" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {addForm}
            </div>
            <FormControl
              className={classes.formControlAddress}
              variant="standard"
            >
              <CustomizedInputs
                name="medicamentoObservacion"
                onChange={handleChangeInput()}
                disabled={disabled}
                label="OBSERVACIONES"
                multiline
                rows={6}
                inputProps={{ maxLength: 200 }}
              />
              <FormHelperText className={classes.errorText}>
                {error?.medicamentoObservacion}
              </FormHelperText>
            </FormControl>
          </div>
        </>
      );
    }
  };

  function handleNew() {
    setDisabled(prevState => !prevState);
  }

  function showChronicMedication() {
    let show;
    if(chronicMedication?.medicamentos !== undefined) {
        show = medication?.map((item, i) =>
        screen.sWidth <= 768 ? (
          <Fragment key={i}>
            <div
              className={`${classes.divEmergencyPhonesForm} ${classes.divCustomSelectHobbies} ${classes.divAllergiesForm}`}
              style={{ alignItems: 'center' }}>
              <div className={classes.accordionMedicationMobile}>
                {accordionItem(i, item)}
              </div>

              <div className={classes.quarterCol} style={{ justifyContent: 'end' }}>
                <Button onClick={handleDelete(item)}>
                  <DeleteOutlineIcon
                    className={classes.ShowMoreArrowIcon}
                    style={{ color: "#002858" }}
                  />
                </Button>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment key={i}>
            <div className={classes.formChronicDisease}>
              <FormControl
                className={classes.formControlAddress}
                variant="standard"
              >
                <CustomizedInputs
                  name="medicamentoDescripcion"
                  defaultValue={item?.medicamentoDescripcion}
                  disabled={disabled}
                  label="MEDICAMENTO"
                  style={{ marginBottom: "10px" }}
                />
                <div className={`${classes.accordionMedicationMobile}`} style={{ width: '100%' }}>
                  {accordionItem(i, item)}
                </div>
                <FormHelperText className={classes.errorText}>
                  {error[i]?.medicamentoDescripcion}
                </FormHelperText>
              </FormControl>
              <div style={{ display: "flex", alignItems: "start", marginTop: '30px' }}>
                <Button onClick={handleDelete(item)}>
                  <DeleteOutlineIcon
                    className={classes.ShowMoreArrowIcon}
                    style={{ color: "#002858" }}
                  />
                </Button>
              </div>
            </div>
          </Fragment>
        )
      )
    } else {
        show = <div>
        <NotFound
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          customMsg={
            <div className={classes.contentNotFound}>
              <span className={classes.notAppointment}>
                No se encontraron resultados
              </span>
            </div>
          }
          wsItem={chronicMedication?.medicamentos}
          fullItem={chronicMedication?.medicamentos}
        />
      </div>
    }
    return show;
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.formChronicMedication}>
        {disabled ? (
          <>
            { showChronicMedication() }
          </>
        ) : (
          editForm()
        )}

        <div
          className={`${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}
        >
          {!disabled && (
            <div style={{ marginBottom: "50px" }}>
              <CustomButton
                customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                style={{ marginBottom: "20px", marginLeft: "20px" }}
                title='cancelar'
                onClick={handleNew}
              />
              <CustomButton
                customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                style={{ marginBottom: "20px", marginLeft: "20px" }}
                title="Guardar"
                onClick={handleClickModal}
              />
            </div>
          )}
        </div>
        <SaveModal
          isOpen={open}
          setOpen={setOpen}
          type="submit"
          onClick={handleSubmit}
          titleModal={
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CheckCircleOutlineIcon
                  className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                Confirmar envío de datos
              </div>
            </>
          }
          descriptionModal="¿Deseas confirmar los cambios?"
        />
      </form>
      <ConfirmModal
        isOpen={openConfirm}
        setOpen={setOpenConfirm}
        errorClass={modalErrorClass}
        titleModal={
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {modalIcon}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {modalTitle}
            </div>
          </>
        }
        description={modalDescription}
      />
      {showMessageModal(errorMessageMedication, errorMessageModal, setErrorMessageModal, classes.borderWhite, classes.quickAccessIcon, classes.contentDialogTitle, handleGoToPersonalMedicalData)}
    </>
  );
};
export default ChronicMedicationForm;
