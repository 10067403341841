import * as React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField'; 
import useStyles from '../../assets/styles/styles';

const LineBreakInput = (props) => { 
  const { label, formControlClass, inputLabelClass, customRequired, ...other } = props;
  const classes = useStyles();

  function renderRequired() {
    if (customRequired) {
      return <span style={{ color: '#ff0000' }}>*</span>;
    }
  }

  return (
    <FormControl className={`${classes.formControl} ${formControlClass}`} variant="standard">
      <InputLabel className={`${classes.inputLabelForms} ${inputLabelClass}`} shrink htmlFor="bootstrap-input">
        {label}
        {renderRequired()}
      </InputLabel>
      <TextField {...other} className={`${classes.customInput} ${classes.customClass}`} />
    </FormControl>
  );
};

export default LineBreakInput;
