/* /src/components/auth/logout.jsx */

import * as React from "react";
import { AuthConsumer } from "./providers/authProvider";

export const Login = () => (
    <AuthConsumer>
        {({ signinRedirect }) => {
            signinRedirect();
        }}
    </AuthConsumer>
);

export default Login;

