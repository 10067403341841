import * as actionTypes from "../consts/actionTypes";

/**acciones que se disparan del componente */
export function getTypesOfContact(affiliated) {
  return {
    type: actionTypes.GET_TYPE_OF_CONTACT,
    payload: affiliated,
  };
}

export function getTypesOfContactPhone(affiliated) {
  return {
    type: actionTypes.GET_TYPE_OF_CONTACT_PHONE,
    payload: affiliated,
  };
}

export function getTypesOfContactMail(affiliated) {
  return {
    type: actionTypes.GET_TYPE_OF_CONTACT_MAIL,
    payload: affiliated,
  };
}

export function getContactData(affiliated) {
  return {
    type: actionTypes.GET_CONTACTDATA_OF_AFFILIATED,
    payload: affiliated,
  };
}

export function getTypesDocuments(params) {
  return {
    type: actionTypes.GET_TYPES_DOCUMENTS,
    payload: params,
  };

}
export function getCivilStatus() {
  return {
    type: actionTypes.GET_CIVIL_STATUS,
    payload: "",
  };
}

export function getNationalities() {
  return {
    type: actionTypes.GET_NATIONALITY,
    payload: "",
  };
}

export function getFullData(affiliated) {
  return {
    type: actionTypes.GET_FULL_DATA,
    payload: affiliated
  };
}

export function abmFullData(params) {
  return {
    type: actionTypes.ABM_FULL_DATA,
    payload: params,
  };
}

export function getCredentials(params) {
  return {
    type: actionTypes.GET_CREDENTIALS,
    payload: params,
  };
}

export function getHobbies(params) {
  return {
    type: actionTypes.GET_HOBBIES,
    payload: params,
  };
}

export function getDeportes(params) {
  return {
    type: actionTypes.GET_DEPORTES,
    payload: params,
  };
}

export function clearAbmModel() {
  return {
    type: actionTypes.CLEAR_ABM_MODEL,
    payload: ""
  };
}