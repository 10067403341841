import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetNews({ payload }) {
    try {
        let response = yield call(API.getNews, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_ALL_NEWS, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_ALL_NEWS, message: error.message });
    }
}

// Watchers
export default function* newsSaga() {
    yield takeLatest(actionTypes.GET_ALL_NEWS, asyncGetNews);
}
