import { IconButton, Typography } from "@material-ui/core";
import Header from "../../GenericHeader/Header";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import WizardLine from "../../AskTurn/WizardLine";
import SelectTypeOfComponent from "../../PersonalData/SelectTypeOfComponent";
import useStyles from '../../../assets/styles/styles';
import { useDispatch } from "react-redux";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import DrawerRequestSteps from "./DrawerRequestSteps";
import Drawer from "../../../controls/Drawer/Drawer";
import CustomButton from "../../../controls/Button/CustomButton";
import ModalProfile from "../../../components/DialogProfile/Modal";
import {
    clearModelAuthRequest,
    getAuthorizationDetails,
    setActualStepAuthorizationsRequest,
    setPrevStepAuthorizationsRequest,
    setModelAuthRequest } from "../../../redux/actions/authorizationsActions";

const NewRequest = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { screen } = useResizeScreen();
    const [activeStep, setActiveStep] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const history = useHistory();

    const list = [
        {
            step0: ""
        },
        {
            step1: ""
        },
        {
            step2: ""
        },
    ];


    const handleOpenCancelModal = useCallback(() => {
        setOpenModal(prevState => !prevState);
    }, [openModal]);

    const handleBack = useCallback(() => {
        let active = activeStep - 1;
        setActiveStep(active);
        dispatch(setActualStepAuthorizationsRequest(activeStep));
        dispatch(setPrevStepAuthorizationsRequest(active));
    }, [activeStep]);

    const handleClose = () => {
        dispatch(clearModelAuthRequest());
        dispatch(setModelAuthRequest({}))
        history.push('/Authorizations');
    };

    useEffect(() => {
        dispatch(getAuthorizationDetails());
    }, []);

    return <>
        {
            screen.sWidth <= 768 ?
                <>
                    <Header title={"Autorizaciones"} zIndexTitle={100} positionTitle={"relative"} />
                    {activeStep !== 0 &&
                        <>
                            <div style={{ position: "absolute", zIndex: "110", top: "105px", left: "10px" }}>
                                <IconButton onClick={handleBack}>
                                    <ArrowBackIcon className={classes.ShowMoreArrowIcon} style={{ fontSize: "30px" }} />
                                </IconButton>
                            </div>
                        </>
                    }
                    <Drawer open={true}>
                        <WizardLine list={list} activeStep={activeStep} />
                        <DrawerRequestSteps isWeb={false} open={props.open} activeStep={activeStep} setActiveStep={setActiveStep} />
                    </Drawer>
                </>
                :
                (<>
                    <SelectTypeOfComponent
                        activeStepTurn={activeStep}
                        setActiveStepTurn={setActiveStep}
                        handleOpenCancelModalTurn={handleOpenCancelModal}
                        handleBackTurn={handleBack}
                    />
                </>)
        }
        {
            openModal &&
            <ModalProfile
                type='cancel'
                title={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            Cancelar autorización
                        </div>
                    </>
                }
                observation={
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography>
                            ¿Está seguro que desea salir?
                        </Typography>
                    </div>
                }
                description={
                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <CustomButton
                            title={"Si"}
                            className={`${classes.buttonConfirmModal} ${classes.lightLinearGradient}`}
                            onClick={handleClose}
                        />
                        <CustomButton
                            title={"No"}
                            customClass={`${classes.buttonNotConfirmModal}`}
                            onClick={handleOpenCancelModal}
                        />
                    </div>
                }
                open={openModal}
                onClose={setOpenModal}
            />
        }
    </>;
};

export default NewRequest;
