import ModalActivities from "../DialogActivities/ModalActivities";

const ConfirmModal = ({ isOpen, setOpen, description, titleModal, errorClass, escapeKeyDown, backdropClick }) => {
    return <>
        {isOpen ?
            <ModalActivities
                style={{textAlign: "center"}}
                title={titleModal}
                description={description}
                open={isOpen}
                onClose={setOpen}
                empty={true}
                customClass={errorClass}
                disableEscapeKeyDown={escapeKeyDown}
                onBackdropClick={backdropClick}
            />
            : ""}
    </>;
};

export default ConfirmModal;