import { FormControl, FormHelperText, Typography, CircularProgress } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyles from '../../assets/styles/styles';
import CustomButton from "../../controls/Button/CustomButton";
import CustomSelect from "../../controls/Select/CustomSelect";
import { getCredentials, getFullData } from "../../redux/actions/personalDataActions";
import CustomizedInputs from '../../controls/InputCustom/CustomizedInputs';
import Iframe from "react-iframe";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import { cleanVirtualDoctorModel, postCallDoctor } from "../../redux/actions/virtualDoctorActions";
import { showMessageModal } from "../../utils/utilsFunctions";

const VirtualDoctorCaller = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const familyGroup = useSelector(state => state.appointmentsReducer.groupFamilyTurn);
    const fullDataAffiliated = useSelector(state => state.personalDataReducer);
    const virtualDoctorReduxState = useSelector(state => state.virtualDoctorReducer);
    const [selected, setSelected] = useState({});
    const [familyGroupList, setFamilyGroupList] = useState([{}]);
    const [dataOfFamilyMember, setDataOfFamilyMember] = useState({});
    const [dataEmail, setDataEmail] = useState('');
    const [dataPhone, setDataPhone] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const { screen } = useResizeScreen();
    const btnWeb = useRef();
    const btnMobile = useRef();
    const [timer, setTimer] = useState(false);
    const [disableBtn, setDisableBtn] = useState(true);
    const errorMessage = useSelector(state => state.virtualDoctorReducer.msgError);
    const [errorMessageModal, setErrorMessageModal] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        setDisableBtn(fieldValidations(formErrors) ? false : true);
    }, [formErrors])

    useEffect(() => {
        const obligatoryField = '*Campo obligatorio';
        const emailFormatValidation = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        setFormErrors({
            affiliate: selected?.[0]?.numeroPersona ? '' : obligatoryField,
            fullName: dataOfFamilyMember?.name ? '' : obligatoryField,
            email: dataEmail ? '' : obligatoryField,
            phone: dataPhone ? '' : obligatoryField,
            birthdate: dataOfFamilyMember?.dateOfBirth ? '' : obligatoryField,
            documentNumber: dataOfFamilyMember?.dni ? '' : obligatoryField,
            plan: dataOfFamilyMember?.planDescription ? '' : obligatoryField,
            credentialNumber: dataOfFamilyMember?.credential ? '' : obligatoryField
        });

        (!emailFormatValidation.test(dataEmail) && dataEmail !== '') && setFormErrors({...formErrors, email: 'No cumple con el formato permitido: mail@mail.com'});

    }, [dataOfFamilyMember, dataPhone, dataEmail, selected?.numeroPersona]);


    const handleChangeSelect = (listToFilter) => (e) => {
        const { value } = e.target;
        let description = listToFilter?.filter(x => x.value === value);
        let programSelected = [{
            numeroPersona: value,
            descripcionPersona: description[0].label
        }];
        setSelected(programSelected);
    };

    const handleChangeEmail = (e) => {
        const { value } = e.target;
        setDataEmail(value);
    };

    const handleChangePhone = (e) => {
        const { value } = e.target;
        setDataPhone(value);
    };

    const handleClickConfirm = (e) => {
        e.preventDefault();
        let request = {
            numeroPersona: parseInt(selected?.[0]?.numeroPersona),
            email: dataEmail,
            nombreApellido: dataOfFamilyMember?.name,
            sexo: dataOfFamilyMember?.sexo,
            telefono: dataPhone,
            tipoDocumento: dataOfFamilyMember?.docType,
            nroDocumento: dataOfFamilyMember?.dni,
            fechaNacimiento: dataOfFamilyMember?.dateOfBirth,
            planCodigo: dataOfFamilyMember?.planCode
        };
        dispatch(postCallDoctor(request));
        setRedirect(true);
    };

    const fieldValidations = errors => Object.values(errors).every(x => x === '');

    const redirectWithDoctorPage = useCallback(() => {
        let response = (
            <div style={{ dispay: 'flex', flex: '0 0 100%', flexWrap: 'wrap' }}>
                <div style={{ display: "flex", justifyContent: "center", paddingTop: screen.sWidth < 768 ? '60px' : '30px' }}>
                    <CircularProgress />
                </div>
                {timer && <span style={{ display: "flex", justifyContent: "center", paddingTop: '5px' }}>
                    El servicio de Médico Virtual se encuentra demorado. Por favor esperá un momento o intentá más tarde.
                </span>}
            </div>);

        setTimeout(() => {
            setTimer(true);
        }, 10000);

        if (Object.values(virtualDoctorReduxState?.virtualDoctorResponse).length > 0) {
            if (virtualDoctorReduxState?.virtualDoctorResponse?.confirmacion) {
                response = (
                    <div>
                        <Iframe sandbox='' scrolling="no" src={virtualDoctorReduxState?.virtualDoctorResponse?.link}
                            className={classes.iframe} allow="camera; microphone; autoplay;" />
                    </div >);
            } else if (!virtualDoctorReduxState?.virtualDoctorResponse?.confirmacion) {
                response = (
                    <div className={classes.Redirect} style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}>
                        <Typography variant="h5" className={classes.Typography}>
                            Por el momento no podemos comunicarnos, intentá más tarde.
                        </Typography>
                    </div >);
            }
        }
        return response;
    }, [virtualDoctorReduxState?.virtualDoctorResponse, redirect, timer]);

    useEffect(() => {
        return () => {
            dispatch(cleanVirtualDoctorModel());
        };
    }, []);


    useEffect(() => {
        if (btnWeb?.current !== null || btnMobile?.current !== null) {
            if (screen.sWidth > 768) {
                btnWeb.current.style.display = 'flex';
                btnMobile.current.style.display = 'none';
            } else {
                btnWeb.current.style.display = 'none';
                btnMobile.current.style.display = 'flex';
            }
        }
    }, [screen.sWidth]);

    useEffect(() => {
        if (Object.values(familyGroup).length > 0) {
            let itemsToPush = familyGroup.map(item => {
                return { value: item.itemCodigo, label: item.itemDescripcion };
            });
            setFamilyGroupList(itemsToPush);
        }
    }, [familyGroup]);

    useEffect(() => {
        if (Object.values(selected).length > 0) {
            let familyMember = {
                numeroPersona: Number.parseInt(selected[0]?.numeroPersona)
            };
            dispatch(getFullData(familyMember));
            dispatch(getCredentials(familyMember));
            setDisabled(false);
        }
    }, [selected]);

    useEffect(() => {
        let completePhone = fullDataAffiliated?.fullData?.afiliado?.telefonos.find(x => x.telefonoTipoDescripcion === 'Movil');

        if(completePhone === undefined) {
            completePhone = fullDataAffiliated?.fullData?.afiliado?.telefonos.find(x => x.telefonoTipoDescripcion === 'Particular');
        }

        let credentialOfMember = fullDataAffiliated?.credentials?.credenciales?.filter(x => x.numeroPersona === Number.parseInt(selected[0]?.numeroPersona));
        let data = {
            name: fullDataAffiliated?.fullData?.afiliado?.apellidoNombre?.trim() ?? '',
            email: fullDataAffiliated?.fullData?.afiliado?.mails[0]?.mail?.trim() ?? '',
            phone: completePhone !== undefined ? `${completePhone?.areaCodigo}${completePhone?.numero}` : '',
            dateOfBirth: fullDataAffiliated?.fullData?.afiliado?.fechaNacimiento?.trim() ?? '',
            docType: fullDataAffiliated?.fullData?.afiliado?.documentos[0]?.documentoTipoCodigo?.trim() ?? '',
            dni: fullDataAffiliated?.fullData?.afiliado?.documentos[0]?.documentoNumero?.trim() ?? '',
            planDescription: fullDataAffiliated?.fullData?.afiliado?.planDescripcion?.trim() ?? '',
            planCode: fullDataAffiliated?.fullData?.afiliado?.planCodigo ?? '',
            credential: credentialOfMember ? credentialOfMember[0]?.numeroAfiliado?.trim() : '',
            sexo: fullDataAffiliated?.fullData?.afiliado?.sexo?.trim() ?? '',
        };
        setDataOfFamilyMember(data);
        setDataEmail(data?.email);
        setDataPhone(data?.phone);
    }, [fullDataAffiliated?.fullData, fullDataAffiliated?.credentials]);

    useEffect(() => {
        if (dataOfFamilyMember?.credential) {
            setDisableBtn(false);
        } else {
            setDisableBtn(true);
        }
    }, [dataOfFamilyMember?.credential]);

    const redirectToVirtualDoctor = () => {
        window.location.href = '/VirtualDoctor';
    };

    useEffect(() => {
        if (errorMessage !== "" && errorMessage !== undefined) {
            setErrorMessageModal(true);
        }
    }, [errorMessage]);

    return (
        <>
            {!redirect ?
                <>
                    <div ref={btnWeb} style={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
                        <div className={classes.divAccordionPrograms} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CustomButton
                                disabled={disableBtn}
                                title={'CONFIRMAR'}
                                onClick={handleClickConfirm}
                                customClass={`${classes.lightLinearGradient} ${classes.fontBold}`}
                                style={{ padding: '5px 20px 5px 20px', fontWeight: "bold", fontSize: "13px"}}
                            />
                        </div>
                    </div>
                    <article style={{ display: 'flex', width: '100%', flexWrap: 'wrap', paddingTop: screen.sWidth <= 768 ? '30px' : '0px' }}>
                        <div className={classes.divAccordionPrograms}>
                            <FormControl className={`${classes.formControlChronicMedicationForm} ${classes.formControlVirtualDoctor} ${classes.formControlsEmergency} ${classes.formControlSelect}`} variant="standard">
                                <CustomSelect
                                    name={`familyGroup`}
                                    list={familyGroupList}
                                    selected={selected?.numeroPersona}
                                    onChange={handleChangeSelect(familyGroupList)}
                                    label='AFILIADO'
                                    style={{ maxWidth: screen.sWidth < 300 ? "250px" : "" }}
                                />
                                <FormHelperText className={classes.errorText}>{formErrors?.affiliate}</FormHelperText>
                            </FormControl>
                        </div>
                        {!disabled &&
                            <>
                                <div className={classes.divAccordionPrograms} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Typography className={`${classes.titleBox} ${classes.titleEmergency}`} style={{ textTransform: 'uppercase', alignItems: 'center', display: 'flex', flexWrap: 'wrap', flex: '1 1 100%', fontWeight: 'bold' }}>
                                        Apellido y nombre: <span className={classes.spanVirtualDoctor}>{dataOfFamilyMember?.name}</span>
                                        <FormHelperText className={`${classes.errorText}`} style={{ textTransform: 'lowercase' }}>{formErrors?.fullName}</FormHelperText>
                                    </Typography>
                                </div>
                                <div className={classes.divAccordionPrograms}>
                                    <FormControl className={classes.formControlsEmergency} variant="standard">
                                        <CustomizedInputs id='email' name='email' value={dataEmail ?? ''} onChange={handleChangeEmail} label='EMAIL' type="email" />
                                        <FormHelperText className={`${classes.errorText}`}>{formErrors?.email}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className={classes.divAccordionPrograms}>
                                    <FormControl className={classes.formControlsEmergency} variant="standard">
                                        <CustomizedInputs id='phone' name='phone' value={dataPhone} onChange={handleChangePhone} label='TELEFONO' customRequired={true}
                                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                                            inputProps={{ maxLength: 20 }}
                                        />
                                        <FormHelperText className={`${classes.errorText}`}>{formErrors?.phone}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className={classes.divAccordionPrograms} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Typography className={`${classes.titleBox} ${classes.titleEmergency}`} style={{ textTransform: 'uppercase', alignItems: 'center', display: 'flex', flexWrap: 'wrap', flex: '1 1 100%', fontWeight: 'bold' }}>
                                        Fecha de nacimiento: <span className={classes.spanVirtualDoctor}>{dataOfFamilyMember?.dateOfBirth}</span>
                                    <FormHelperText className={classes.errorText} style={{ textTransform: 'lowercase' }}>{formErrors?.birthdate}</FormHelperText>
                                    </Typography>
                                </div>
                                <div className={classes.divAccordionPrograms} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Typography className={`${classes.titleBox} ${classes.titleEmergency}`} style={{ textTransform: 'uppercase', alignItems: 'center', display: 'flex', flexWrap: 'wrap', flex: '1 1 100%', fontWeight: 'bold' }}>
                                        Nº Documento: <span className={classes.spanVirtualDoctor}>{dataOfFamilyMember?.dni}</span>
                                        <FormHelperText className={classes.errorText} style={{ textTransform: 'lowercase' }}>{formErrors?.documentNumber}</FormHelperText>
                                    </Typography>
                                </div>
                                <div className={classes.divAccordionPrograms} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Typography className={`${classes.titleBox} ${classes.titleEmergency}`} style={{ textTransform: 'uppercase', alignItems: 'center', display: 'flex', flexWrap: 'wrap', flex: '1 1 100%', fontWeight: 'bold' }}>
                                        Plan: <span className={classes.spanVirtualDoctor}>{dataOfFamilyMember?.planDescription}</span>
                                        <FormHelperText className={classes.errorText} style={{ textTransform: 'lowercase' }}>{formErrors?.plan}</FormHelperText>
                                    </Typography>
                                </div>
                                <div className={classes.divAccordionPrograms} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Typography className={`${classes.titleBox} ${classes.titleEmergency}`} style={{ textTransform: 'uppercase', alignItems: 'center', display: 'flex', flexWrap: 'wrap', flex: '1 1 100%', fontWeight: 'bold' }}>
                                        Credencial: {dataOfFamilyMember?.credential ? <span className={classes.spanVirtualDoctor}>{dataOfFamilyMember?.credential}</span> : <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}>
                                            <CircularProgress />
                                        </div>}
                                        <FormHelperText className={classes.errorText} style={{ textTransform: 'lowercase' }}>{formErrors?.credentialNumber}</FormHelperText>
                                    </Typography>
                                </div>
                            </>
                        }
                    </article>
                    <div ref={btnMobile} style={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
                        {dataOfFamilyMember?.credential &&
                            <div className={classes.divAccordionPrograms} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CustomButton
                                    disabled={disableBtn}
                                    title={'CONFIRMAR'}
                                    onClick={handleClickConfirm}
                                    customClass={`${classes.lightLinearGradient} ${classes.fontBold}`}
                                    style={{
                                        padding: '5px 20px 5px 20px',
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                        marginLeft: "7%",
                                        marginBottom: "3%"
                                    }}
                                />
                            </div>
                        }
                    </div>
                </>
                :
                redirectWithDoctorPage()
            }
            {showMessageModal(errorMessage, errorMessageModal, setErrorMessageModal, classes.borderWhite, classes.quickAccessIcon, classes.contentDialogTitle, redirectToVirtualDoctor)}
        </>
    );
};

export default VirtualDoctorCaller;
