import React, { useEffect, useState } from 'react';
import useStyles from '../../../assets/styles/styles';
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import CustomSelect from '../../../controls/Select/CustomSelect';
import CustomizedInputs from '../../../controls/InputCustom/CustomizedInputs';
import { validate } from '../../../utils/utilsFunctions';
import { useSelector } from 'react-redux';

const listOfTypeDebit = [
    {
        value: 0, label: "TARJETA CREDITO"
    },
    {
        value: 1, label: "DEBITO CUENTA"
    }
];

const listOfTypeAccount = [
    {
        value: 0, label: "CUENTA CORRIENTE"
    },
    {
        value: 1, label: "CAJA DE AHORRO"
    },
];

const AdherenceForm = (props) => {
    const { model, setModel, error, setError } = props;
    const classes = useStyles();
    const [selectTypeDebit, setSelectTypeDebit] = useState(0);
    const [selectCard, setSelectCard] = useState(0);
    const [selectTypeAccount, setSelectTypeAccount] = useState(0);
    const [debitoAutomaticoInformacion, setDebitoAutomaticoInformacion] = useState({
        tarjetaCreditoTipoCodigo: '',
        tarjetaCreditoNumero: '',
        tarjetaCreditoVencimientoMes: 0,
        tarjetaCreditoVencimientoAnio: 0,
        debitoTipoCodigo: '',
        debitoNumeroCuenta: '',
        debitoCBU: '',
    });
    const cardsReduxState = useSelector(state => state?.cardReducer?.cards);
    const [listOfCard, setListOfCard] = useState([]);

    useEffect(() => {
        let aux = listOfCard;
        cardsReduxState?.cards?.forEach(element => {
            aux.push({
                value: element.id,
                label: element.tipoTarjeta?.toUpperCase()
            });
        });
        setListOfCard(aux);
    }, [cardsReduxState]);

    useEffect(() => {
        setModel({ ...model, debitoAutomaticoInformacion });
    }, [debitoAutomaticoInformacion]);

    useEffect(() => {
        if (model?.debitoAutomaticoInformacion === undefined) {
            setModel({ ...model });
            setSelectTypeDebit(-1);
            setSelectCard(-1);
            setSelectTypeAccount(-1);
        }
    }, [model?.debitoAutomaticoInformacion]);

    const handleChangeSelectTypeDebit = () => e => {
        const { name, value } = e.target;
        setSelectTypeDebit(value);
        let typeDebit = listOfTypeDebit?.find(x => x.value === value)?.label;
        setModel({
            ...model,
            [name]: typeDebit,
        });
        setDebitoAutomaticoInformacion({
            tarjetaCreditoTipoCodigo: '',
            tarjetaCreditoNumero: '',
            tarjetaCreditoVencimientoMes: 0,
            tarjetaCreditoVencimientoAnio: 0,
            debitoTipoCodigo: '',
            debitoNumeroCuenta: '',
            debitoCBU: '',
        });
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    const handleChangeSelectCard = () => e => {
        const { name, value } = e.target;
        setSelectCard(value);
        let typeCard = listOfCard?.find(x => x.value === value)?.label;
        setDebitoAutomaticoInformacion({ ...debitoAutomaticoInformacion, [name]: typeCard });
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    const handleChangeCardNumber = () => e => {
        const { name, value } = e.target;
        setDebitoAutomaticoInformacion({ ...debitoAutomaticoInformacion, [name]: value });
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    const handleChangeCardExpiration = () => e => {
        const { name, value } = e.target;
        setDebitoAutomaticoInformacion({ ...debitoAutomaticoInformacion, [name]: parseInt(value) });
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    const handleChangeSelectTypeAccount = () => e => {
        const { name, value } = e.target;
        setSelectTypeAccount(value);
        let typeAccount = listOfTypeAccount?.find(x => x.value === value)?.label;
        setDebitoAutomaticoInformacion({ ...debitoAutomaticoInformacion, [name]: typeAccount });
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    return (
        <>
            <FormControl className={`${classes.formControlsAffiliations} ${classes.fixFormControlSelect}`} variant="standard">
                <InputLabel className={`${classes.txtLabelTramites} ${classes.fixInputLabel} `}>
                    {"MEDIO DE PAGO"}
                </InputLabel>
                <CustomSelect
                    label=""
                    name='debitoAutomaticoTipo'
                    selected={selectTypeDebit}
                    list={listOfTypeDebit}
                    onChange={handleChangeSelectTypeDebit()}
                />
                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.debitoAutomaticoTipo}</FormHelperText>
            </FormControl>

            {selectTypeDebit === 0 &&
                <>
                    <FormControl className={`${classes.formControlsAffiliations} ${classes.fixFormControlSelect}`} variant="standard">
                        <InputLabel className={`${classes.txtLabelTramites} ${classes.fixInputLabel} `}>
                            {"TIPO DE TARJETA"}
                        </InputLabel>
                        <CustomSelect
                            label=""
                            name='tarjetaCreditoTipoCodigo'
                            selected={selectCard}
                            list={listOfCard}
                            onChange={handleChangeSelectCard()}
                        />
                        <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.tarjetaCreditoTipoCodigo}</FormHelperText>
                    </FormControl>
                    <FormControl className={`${classes.formControlsAffiliations} ${classes.formControlsCardNumber}`} variant="standard">
                        <InputLabel className={`${classes.txtLabelTramites} `}>
                            {"NUMERO DE TARJETA"}
                        </InputLabel>
                        <CustomizedInputs
                            label=''
                            name='tarjetaCreditoNumero'
                            disabled={false}
                            onChange={handleChangeCardNumber()}
                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                            inputProps={{ maxLength: 16 }}
                        />
                        <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.tarjetaCreditoNumero}</FormHelperText>
                    </FormControl>
                    <FormControl className={`${classes.formControlsAffiliations} ${classes.formControlsCardNumber}`} variant="standard">
                        <InputLabel className={`${classes.txtLabelTramites} `}>
                            {"MES DE VENCIMIENTO"}
                        </InputLabel>
                        <CustomizedInputs
                            name='tarjetaCreditoVencimientoMes'
                            disabled={false}
                            onChange={handleChangeCardExpiration()}
                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                            inputProps={{ maxLength: 2 }}
                        />
                        <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.tarjetaCreditoVencimientoMes}</FormHelperText>
                    </FormControl>
                    <FormControl className={`${classes.formControlsAffiliations} ${classes.formControlsCardNumber}`} variant="standard">
                        <InputLabel className={`${classes.txtLabelTramites} `}>
                            {"AÑO DE VENCIMIENTO"}
                        </InputLabel>
                        <CustomizedInputs
                            name='tarjetaCreditoVencimientoAnio'
                            disabled={false}
                            onChange={handleChangeCardExpiration()}
                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                            inputProps={{ maxLength: 4 }}
                        />
                        <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.tarjetaCreditoVencimientoAnio}</FormHelperText>
                    </FormControl>
                </>
            }

            {selectTypeDebit === 1 &&
                <>
                    <FormControl className={`${classes.formControlsAffiliations} ${classes.fixFormControlSelect}`} variant="standard">
                        <InputLabel className={`${classes.txtLabelTramites} ${classes.fixInputLabel} `}>
                            {"TIPO DE CUENTA"}
                        </InputLabel>
                        <CustomSelect
                            label=""
                            name='debitoTipoCodigo'
                            selected={selectTypeAccount}
                            list={listOfTypeAccount}
                            onChange={handleChangeSelectTypeAccount()}
                        />
                        <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.debitoTipoCodigo}</FormHelperText>
                    </FormControl>
                    <FormControl className={`${classes.formControlsAffiliations} ${classes.formControlsCardNumber}`} variant="standard">
                        <InputLabel className={`${classes.txtLabelTramites} `}>
                            {"NUMERO DE CUENTA"}
                        </InputLabel>
                        <CustomizedInputs
                            label=''
                            name='debitoNumeroCuenta'
                            disabled={false}
                            onChange={handleChangeCardNumber()}
                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                            inputProps={{ maxLength: 10 }}
                        />
                        <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.debitoNumeroCuenta}</FormHelperText>
                    </FormControl>
                    <FormControl className={`${classes.formControlsAffiliations} ${classes.formControlsCardNumber}`} variant="standard">
                        <InputLabel className={`${classes.txtLabelTramites} `}>
                            {"CBU"}
                        </InputLabel>
                        <CustomizedInputs
                            label=''
                            name='debitoCBU'
                            disabled={false}
                            onChange={handleChangeCardNumber()}
                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                            inputProps={{ maxLength: 22 }}
                        />
                        <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.debitoCBU}</FormHelperText>
                    </FormControl>
                </>
            }
        </>
    );
};

export default AdherenceForm;
