import React, { useEffect, useState } from 'react';
import { useResizeScreen } from '../../../hooks/useResizeScreen';
import useStyles from '../../../assets/styles/styles';
import FormHeader from '../../PersonalData/FormHeader';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Divider, FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { abDirectDebit, getDirectDebit, getEnabledDirectDebit, setABDirectDebit } from "../../../redux/actions/billingActions";
import CustomSelect from '../../../controls/Select/CustomSelect';
import AdherenceForm from './AdherenceForm';
import UnsuscribeForm from './UnsuscribeForm';
import CustomButton from '../../../controls/Button/CustomButton';
import ConfirmModal from '../../PersonalData/ConfirmModal';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SaveModal from '../../PersonalData/SaveModal';
import { validate } from '../../../utils/utilsFunctions';
import NotFound from '../../HomeLogged/NotFound';

const userCode = parseInt(window.localStorage.getItem("numeroPersona"));
const affiliated = {
  numeroPersona: userCode
};

const listOfOptions = [
  {
    value: 0, label: "ADHESION"
  },
  {
    value: 1, label: "BAJA"
  }
];

const DirectDebit = () => {
  const classes = useStyles();
  const { screen } = useResizeScreen();
  const dispatch = useDispatch();
  const date = new Date();
  const [permission, setPermission] = useState(null);
  const data = useSelector(state => state.billingReducer);
  const [selectOption, setSelectOption] = useState(0);
  const [openSave, setOpenSave] = useState(false);
  const [openSendSuccess, setOpenSendSuccess] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState("");
  const [modalIcon, setModalIcon] = useState('');
  const [error, setError] = useState({});
  const [model, setModel] = useState({
    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
    debitoAutomaticoModo: 'A',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getEnabledDirectDebit(affiliated));
    dispatch(getDirectDebit(affiliated));
    setSelectOption(0);
  }, []);

  useEffect(() => {
    if (data.enabledDirectDebit != null) {
      setPermission(data.enabledDirectDebit?.habilitado);
    }
  }, [data.enabledDirectDebit]);

  const handleChangeSelect = () => e => {
    const { name, value } = e.target;
    setSelectOption(value);
    if (value == 0) {
      setModel({
        numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
        [name]: "A"
      });
    }
    else {
      setModel({
        numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
        [name]: "B"
      });
    }
    validate(name, '*Campo obligatorio', value, setError, error);
  };

  function formHeader() {
    let res = {
      title: 'ADHESION / BAJA',
      subtitle: ''
    };
    if (screen.sWidth < 768) {
      res.title = 'ADHESION / BAJA';
      res.subtitle = '';
    }
    return res;
  }

  const handleClickModal = () => {
    if (model?.debitoAutomaticoModo == 'A' && validateForm(model)) {
      setOpenSave(prevState => !prevState);
    }
    if (model?.debitoAutomaticoModo == 'B') {
      setOpenSave(prevState => !prevState);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (model?.debitoAutomaticoModo == 'A' && validateForm(model)) {
      setModalTitle('VALIDACION');
      setModalDescription(`Se dara de alta el medio de pago elegido `);
      setModalIcon(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
      setOpenSendSuccess(prevState => !prevState);
      dispatch(abDirectDebit(model));
    }
    if (model?.debitoAutomaticoModo == 'B') {
      setModalTitle('VALIDACION');
      setModalDescription(`Se dara de baja el medio de pago elegido `);
      setModalIcon(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
      setOpenSendSuccess(prevState => !prevState);
      dispatch(abDirectDebit(model));
    }

  };

  useEffect(() => {
    if (data?.abDirectDebit?.confirmacion) {
      setLoading(false);
      setTimeout(function () {
        setOpenSave(prevState => !prevState);
        setOpenSendSuccess(prevState => !prevState);
      }, 2000);
      setModel({
        numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
        debitoAutomaticoModo: 'A',
      });
      setSelectOption(0);
      setError({});
      dispatch(setABDirectDebit('clean'));
    }
    else if (data?.abDirectDebit?.confirmacion === false) {
      setLoading(false);
      setTimeout(function () {
        setOpenSave(prevState => !prevState);
        setOpenSendSuccess(prevState => !prevState);
        window.location.reload();
      }, 2000);
      setModalTitle('Ocurrió un error');
      setModalDescription(`Ocurrió un error al intentar realizar la operación, por favor, inténte mas tarde.`);
      setModalIcon(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
      setSelectOption(0);
      setError({});
      dispatch(setABDirectDebit('clean'));
    }
  }, [data?.abDirectDebit]);

  useEffect(() => {
    if (Object.values(data?.getDirectDebit)?.length > 0) {
      if (data?.getDirectDebit?.confirmacion) {
        if ((data?.getDirectDebit?.debitosAutomaticos[0]?.tarjetaCreditoTipoCodigo !== '' && data?.getDirectDebit?.debitosAutomaticos[0]?.tarjetaCreditoVencimientoMes !== '' && data?.getDirectDebit?.debitosAutomaticos[0]?.tarjetaCreditoVencimientoAnio !== '') ||
          (data?.getDirectDebit?.debitosAutomaticos[0]?.debitoNumeroCuenta !== '' && data?.getDirectDebit?.debitosAutomaticos[0]?.debitoCBU !== '' && data?.getDirectDebit?.debitosAutomaticos[0]?.debitoTipoDescripcion !== '')) {
          setSelectOption(1);
          setModel({
            numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
            debitoAutomaticoModo: "B"
          });
        } else {
          setSelectOption(0);
          setModel({
            numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
            debitoAutomaticoModo: "A"
          });
        }
      }
    }
  }, [data?.getDirectDebit]);

  function validateForm(object) {
    let temp = object;
    let aux = [];
    let validation = [];
    let Obj = {};
    Obj.debitoAutomaticoModo = temp.debitoAutomaticoModo ? '' : '*Campo obligatorio';
    if (Obj.debitoAutomaticoModo != '*Campo obligatorio') {
      Obj.debitoAutomaticoTipo = temp.debitoAutomaticoTipo ? '' : '*Campo obligatorio';
    }
    if (temp.debitoAutomaticoTipo === 'TARJETA CREDITO') {
      Obj.tarjetaCreditoTipoCodigo = temp.debitoAutomaticoInformacion?.tarjetaCreditoTipoCodigo ? '' : '*Campo obligatorio';
      Obj.tarjetaCreditoNumero = temp.debitoAutomaticoInformacion.tarjetaCreditoNumero ? '' : '*Campo obligatorio';
      Obj.tarjetaCreditoVencimientoMes = temp.debitoAutomaticoInformacion.tarjetaCreditoVencimientoMes ? '' : '*Campo obligatorio';
      Obj.tarjetaCreditoVencimientoAnio = temp.debitoAutomaticoInformacion.tarjetaCreditoVencimientoAnio ? '' : '*Campo obligatorio';
      if (Obj.tarjetaCreditoVencimientoMes != '*Campo obligatorio' && (temp.debitoAutomaticoInformacion.tarjetaCreditoVencimientoMes < date.getMonth() + 1 && temp.debitoAutomaticoInformacion?.tarjetaCreditoVencimientoAnio == date.getFullYear())) {
        Obj.tarjetaCreditoVencimientoMes = '*Ingrese MES posterior';
      }
      if (Obj.tarjetaCreditoVencimientoAnio != '*Campo obligatorio' && (temp.debitoAutomaticoInformacion.tarjetaCreditoVencimientoAnio < date.getFullYear())) {
        Obj.tarjetaCreditoVencimientoAnio = '*Ingrese AÑO posterior';
      }
    }
    if (temp.debitoAutomaticoTipo === 'DEBITO CUENTA') {
      Obj.debitoTipoCodigo = temp.debitoAutomaticoInformacion.debitoTipoCodigo ? '' : '*Campo obligatorio';
      Obj.debitoNumeroCuenta = temp.debitoAutomaticoInformacion.debitoNumeroCuenta ? '' : '*Campo obligatorio';
      Obj.debitoCBU = temp.debitoAutomaticoInformacion.debitoCBU ? '' : '*Campo obligatorio';
    }
    aux = Obj;
    if (Object.values(aux).every((x) => x === "")) {
      validation.push(true);
    } else {
      validation.push(false);
    }
    setError(aux);
    return Object.values(validation).every((valid) => valid === true);
  }

  const handleGoToHome = () => {
    window.location.href = '/Billing';
  };

  return (
    <>
      <FormHeader title={formHeader().title} subtitle={formHeader().subtitle} handleGoTo={handleGoToHome}/>
      {
        screen.sWidth > 768 &&
        <Divider className={classes.formDivider} />
      }
      {
        permission ?
          <>
            <div className={classes.directDebitDiv}>
              {screen.sWidth >= 1000 &&
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'center', width: '100%' }}>
                  <CustomButton
                    name='save'
                    title='CONFIRMAR'
                    customClass={`${classes.lightLinearGradientReverse} ${classes.marginLeft}`}
                    onClick={handleClickModal}
                  />
                </div>
              }
              <FormControl className={`${classes.formControlsAffiliations} ${classes.fixFormControlSelect}`} variant="standard">
                <InputLabel className={`${classes.txtLabelTramites} ${classes.fixInputLabel} `}>
                  {"ADHESION / BAJA"}
                </InputLabel>
                <CustomSelect
                  label=""
                  disabled={true}
                  name='debitoAutomaticoModo'
                  selected={selectOption}
                  list={listOfOptions}
                  onChange={handleChangeSelect()}
                />
                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.debitoAutomaticoModo}</FormHelperText>
              </FormControl>
              {
                selectOption === 0 &&
                <AdherenceForm model={model} setModel={setModel} error={error} setError={setError} />
              }
              {
                selectOption === 1 &&
                <UnsuscribeForm model={model} setModel={setModel} />
              }
              {screen.sWidth <= 1000 &&
                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '100%' }}>
                  <CustomButton
                    name='save'
                    title='CONFIRMAR'
                    customClass={`${classes.lightLinearGradientReverse} ${classes.marginLeft}`}
                    onClick={handleClickModal}
                  />
                </div>
              }
              <SaveModal isOpen={openSave} setOpen={setOpenSave} type='submit' onClick={handleSubmit}
                titleModal={
                  <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      Confirmar envío de datos
                    </div>
                  </>
                }
                descriptionModal="¿Deseas confirmar los cambios?"
              />
              <ConfirmModal isOpen={openSendSuccess} setOpen={setOpenSendSuccess}
                titleModal={
                  loading ?
                    <>
                      <span>Espere un momento...</span>

                    </>
                    :
                    <>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {modalIcon}
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {modalTitle}
                      </div>
                    </>
                }
                description={
                  loading ?
                    <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}>
                      <CircularProgress color={'inherit'} />
                    </div>
                    :
                    modalDescription}
              />
            </div>
          </>
          :
          (
            permission === undefined ?
              (<NotFound
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                customMsg={
                  <div className={classes.contentNotFound}>
                    <span className={classes.notAppointment}>
                      No tiene acceso a este modulo
                    </span>
                  </div>
                }
                wsItem={permission}
                fullItem={permission}
              />) :

              (
                <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <div style={{ color: '#0075c9', margin: '20px' }}>
                    No tenés acceso al módulo
                  </div>
                </div>
              )
          )
      }
    </>
  );
};

export default DirectDebit;
