import React, { useCallback } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "../../assets/styles/styles";
import "./modal.css";
import { Box } from "@material-ui/core";

const ModalProfile = (props) => {
  const classes = useStyles();
  const { title, description, observation, specialties, open, onClose, type, customBackgroundDialog, customBackgroundDialogContent, close } = props;

  const handleClose = useCallback(() => {
    onClose(false);
  }, []);

  return (
    <div>
      {type === 'cancel' ?
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          id="modalTurn"
        >
          {close &&
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          }
          <Box display="flex" id="cancelModal">
            <div style={{ alignSelf: 'center' }}>
              <DialogTitle
                id="customized-dialog-title"
                className={`${classes.contentDialogTitle} ${classes.contentDialogTitleCancel}`}
                onClose={handleClose}
              >
                {title}
              </DialogTitle>
              <DialogContent className={classes.contentDialogTitle}>
                {observation}
              </DialogContent>
              <DialogContent className={classes.contentDialogTitle}>
                {description}
              </DialogContent>
            </div>
          </Box>
        </Dialog>
        :

        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          id="backDrop"
        >
          <div id="modalProfile">
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>

            <DialogTitle
              id="customized-dialog-title"
              className={`${classes.contentDialogTitle} ${customBackgroundDialog}`}
              onClose={handleClose}
            >
              {title}
            </DialogTitle>

            <DialogContent dividers className={`${customBackgroundDialogContent}`}>
              {description}
              {observation}
              {specialties}
            </DialogContent>
          </div>
        </Dialog>}
    </div>
  );
};
export default React.memo(ModalProfile, (prevState, nextState) => {
  return prevState.open === nextState.open && prevState.title === nextState.title && prevState.description === nextState.decription;
});
