import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

/**agregar el llamao a las funciones que se comunican con la api */

function* asyncPostPreventionControl({ payload }) {
    try {
        var response = yield call(API.postPrevention, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_POST_PREVENTIONS, response, message: '' });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_POST_PREVENTIONS,
            message: error.message,
        });
    }
}

// Watchers
export default function* preventionSaga() {
    yield takeLatest(actionTypes.POST_PREVENTIONS, asyncPostPreventionControl);
}
