import React from "react";
import { Route } from "react-router-dom";
import { AuthConsumer } from "./auth/providers/authProvider";
import { CircularProgress } from "@material-ui/core";

export const PrivateRoute = ({ component, ...rest }) => {
  const renderFn = Component => props => (
    <AuthConsumer>

      {({ isAuthenticated, signinRedirect }) => {
              

        if (!!Component && isAuthenticated()) {
          return <Component {...props} />;
        } else {
          signinRedirect();
          return <div style={{display:"flex", justifyContent:"center"}}><CircularProgress /></div>;
        }
      }}
    </AuthConsumer>
  );

  return <Route {...rest} render={renderFn(component)} />;
};
