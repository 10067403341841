import InputBase from '@material-ui/core/InputBase';
import { alpha, styled } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import useStyles from '../../assets/styles/styles';
import FormControl from '@material-ui/core/FormControl';

const Input = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        borderRadius: 4,
        border: '1px solid #ced4da',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        padding: '10px 12px',
        fontSize: 16,
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:focus': {
            borderColor: theme.palette.primary.main,
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        },
    },
}));

const PhonesInput = (props) => {
    const { ...other } = props;
    const classes = useStyles();

    function required() {
        if (props.customRequired) {
            return <span style={{ color: '#ff0000' }}>*</span>;
        }
    }

    return (
        <FormControl
            className={`${classes.formControl} ${props.formControlClass}`}
            variant="standard">
            <InputLabel
                className={`${classes.inputLabelForms} ${props.inputLabelClass}`}
                shrink
                htmlFor="bootstrap-input">
                {props.label}{required()}
            </InputLabel>
            <Input {...other}
                type={props.type}
                disabled={props.disabled}
                className={`${classes.customInput} ${props.customClass}`}
                id="bootstrap-input"
            />
        </FormControl>
    );
};

export default PhonesInput;