import * as actionTypes from "../consts/actionTypes";

/**acciones que se disparan del componente */
export function getRefunds(param) {
  return {
    type: actionTypes.GET_REFUNDS,
    payload: param,
  };
}

export function accountRefundAB(param) {
  return {
    type: actionTypes.AB_ACCOUNT_REFUND,
    payload: param,
  };
}

export function getAccountRefunds(param) {
  return {
    type: actionTypes.GET_ACCOUNT_REFUNDS,
    payload: param,
  };
}

export function getParametersRefunds() {
  return {
    type: actionTypes.GET_PARAMETERS_REFUNDS,
    payload: '',
  };
}

export function setModel(model) {
  return {
    type: actionTypes.SET_MODEL_REFUNDS,
    payload: model
  };
}

export function clearModelRefundsRequest() {
  return {
    type: actionTypes.CLEAR_MODEL_REFUNDS,
    payload: ''
  };
}
export function clearPostRefundsRequest() {
  return {
    type: actionTypes.CLEAR_POST_REFUNDS,
    payload: ''
  };
}

export function clearStateRefunds() {
  return {
    type: actionTypes.CLEAR_STATE_REFUNDS,
    payload: ''
  };
}
export function clearMsgError() {
  return {
    type: actionTypes.CLEAR_MSG_ERROR,
    payload: ''
  };
}

export function postRefundRequest(param) {
  return {
    type: actionTypes.POST_REFUNDS,
    payload: param,
  };
}

export function getRefundDetails(param) {
  return {
    type: actionTypes.GET_REFUND_DETAILS,
    payload: param,
  };
}

export function getRefundAttachments(param) {
  return {
    type: actionTypes.GET_REFUND_ATTACHMENTS,
    payload: param,
  };
}

export function getRefundComments(param) {
  return {
    type: actionTypes.GET_REFUND_COMMENTS,
    payload: param,
  };
}

export function getDetailsAttachments(param) {
  return {
    type: actionTypes.GET_DETAILS_ATTACHMENTS,
    payload: param,
  };
}

export function getRefundTypeAttached(param) {
  return {
      type: actionTypes.GET_REFUND_TYPE_ATTACHED,
      payload: param
  };
}

export function pushRefundComments(comment) {
  return {
      type: actionTypes.PUSH_REFUND_COMMENTS,
      payload: comment
  };
}

export function postRefundAttached(param) {
  return {
      type: actionTypes.POST_REFUND_ATTACHED,
      payload: param
  };
}

export function cancelRefundRequest(param) {
  return {
    type: actionTypes.CANCEL_REFUND,
    payload: param,
  };
}

export function postReadRefundComment(param) {
  return {
    type: actionTypes.POST_READ_REFUNDS_COMMENTS,
    payload: param,
  };
}

export function downloadRefund(param) {
  return {
    type: actionTypes.DOWNLOAD_REFUND,
    payload: param,
  };
}

export function setFilters(model) {
  return {
    type: actionTypes.SET_REFUNDS_FILTERS,
    payload: model
  };
}

export function clearRefundsFilters() {
  return {
    type: actionTypes.CLEAR_REFUNDS_FILTERS,
    payload: ''
  };
}