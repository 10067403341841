import * as actionTypes from "../consts/actionTypes";

const initialValues = {
  afilliatedAppointments: {
    NumeroPersona: 0,
    SolicitudTurnos: "",
    Count: 0,
    Skip: 0,
  },
  all: [],
  next: [],
  appointmentNextFullData: [],
  appointmentRecordFullData: [],
  appointmentFavoriteFullData: [],
  appointmentQuestionFullData: [],
  record: [],
  favorite: [],
  questionF: [],
  filtro: [],
  fullDataFiltro: [],
  groupFamilyTurn: [],
  nextAvailableAppointments: [],
  appointments: [],
  modelAppointment: [],
  nextAvailableAppointmentsDoctor: [],
  postAppointment: [],
  modelModifyAppointment: [],
  isLoading: false,
  msgError: '',
  specialtiesList: [],
  doctorsList: [],
  freeDoctorAppointmentsList: []
  /**crear los objetos que vienen del servicio */
};

export default function appointmentsReducer(state = initialValues, action) {
  const ACTIONS = {
    [actionTypes.FAILURE_GET_AFILLIATED_APPOINTMENTS]: responseToReturn(),
    [actionTypes.FAILURE_GET_AFILLIATED_APPOINTMENTS_NEXT]: responseToReturn(),
    [actionTypes.FAILURE_GET_AFILLIATED_APPOINTMENTS_RECORD]: responseToReturn(),
    [actionTypes.FAILURE_GET_APPOINTMENTS_FAVORITE]: responseToReturn(),
    [actionTypes.FAILURE_GET_DATA_GROUP_FAMILY]: responseToReturn(),
    [actionTypes.FAILURE_GET_DATA_FILTER_APPOINTMENT]: responseToReturn(),
    [actionTypes.FAILURE_GET_APPOINTMENTS_CANCELED]: responseToReturn(),
    [actionTypes.FAILURE_GET_APPOINTMENTS_AB]: responseToReturn(),
    [actionTypes.FAILURE_ANSWER_QUESTION]: responseToReturn(),
    [actionTypes.FAILURE_GET_NEXT_AVAILABLE_APPOINTMENTS]: responseToReturn(),
    [actionTypes.FAILURE_GET_NEXT_AVAILABLE_APPOINTMENTS_DOCTOR]: responseToReturn(),
    [actionTypes.FAILURE_GET_APPOINTMENTS_CONFIRMED]: responseToReturn(),
    [actionTypes.FAILURE_GET_FULL_GROUP_FAMILY_APPOINTMENT]: responseToReturn(),
    [actionTypes.CLEAR_APPOINTMENT_MSG_ERROR]: clearState('msgError'),
    [actionTypes.FAILURE_GET_SPECIALTIES_LIST]: responseToReturn(),
    [actionTypes.FAILURE_GET_DOCTORS_LIST]: responseToReturn(),
    [actionTypes.FAILURE_GET_FREE_DOCTOR_APPOINTMENTS_LIST]: responseToReturn(),
  };

  function responseToReturn() {
    let res = { ...state };
    if (action.message) {
        res = { ...state, msgError: action.message, isLoading: false };
    }
    return res;
  }

  function clearState(type) {
    if (type) {
      return { ...state, [type]: initialValues[type] };
    } else {
      return { ...initialValues }
    }
  }

  if (ACTIONS[action.type] !== undefined) {
    return ACTIONS[action.type];
  }

  switch (action.type) {
    case actionTypes.GET_NEXT_AVAILABLE_APPOINTMENTS:
      return { ...state, nextAvailableAppointments: [], isLoading: true };
    case actionTypes.GET_NEXT_AVAILABLE_APPOINTMENTS_DOCTOR:
      return { ...state, nextAvailableAppointments: [], isLoading: true };
    case actionTypes.GET_AFILLIATED_APPOINTMENTS:
      return { ...state, appointments: [], isLoading: true };
    case actionTypes.GET_FREE_DOCTOR_APPOINTMENTS_LIST:
      return { ...state, freeDoctorAppointmentsList: [], isLoading: true };
    case actionTypes.SUCCESS_GET_AFILLIATED_APPOINTMENTS:
      if (action.response) {
        return { ...state, appointments: action.response.data.data.turnos, msgError:'', isLoading: false };
      } else {
        return { ...state };
      }
    case actionTypes.SUCCESS_GET_AFILLIATED_APPOINTMENTS_NEXT:
      if (action.response) {
        return { ...state, next: action.response.data.data.turnos, appointmentNextFullData: action.response.data.data, msgError:'' };
      } else {
        return { ...state };
      }

    case actionTypes.SUCCESS_GET_AFILLIATED_APPOINTMENTS_RECORD:
      if (action.response) {
        return { ...state, record: action.response.data.data.turnos, appointmentRecordFullData: action.response.data.data, msgError:'' };
      } else {
        return { ...state };
      }
    case actionTypes.SUCCES_GET_APPOINTMENTS_FAVORITE:
      if (action.response) {
        return { ...state, favorite: action.response.data.data.favoritos, appointmentFavoriteFullData: action.response.data.data, msgError:'' };
      } else {
        return { ...state };
      }
    case actionTypes.SUCCES_GET_DATA_GROUP_FAMILY:
      if (action.response) {
        return {
          ...state,
          groupFamilyTurn: action.response.data.data.items,
          msgError:''
        };
      } else {
        return { ...state };
      }
    case actionTypes.SUCCES_GET_DATA_FILTER_APPOINTMENT:
      if (action.response) {
        return {
          ...state,
          filtro: action.response.data.data.afiliados,
          fullDataFiltro: action.response.data.data,
          msgError:''
        };
      } else {
        return { ...state };
      }
    case actionTypes.SUCCES_GET_APPOINTMENTS_CANCELED:
      if (action.response) {
        return { ...state, afilliatedAppointments: action.response.data.data, msgError:'' };
      } else {
        return { ...state };
      }
    case actionTypes.SUCCES_GET_APPOINTMENTS_AB:
      if (action.response) {
        return { ...state, afilliatedAppointments: action.response.data.data, msgError:'' };
      } else {
        return { ...state };
      }
    case actionTypes.SUCCES_ANSWER_QUESTION:
      if (action.response) {
        return { ...state, questionF: action.response.data.answerQuestion, appointmentQuestionFullData: action.response.data, msgError:'' };
      } else {
        return { ...state };
      }
    case actionTypes.SUCCESS_GET_NEXT_AVAILABLE_APPOINTMENTS:
      if (action.response) {
        return { ...state, nextAvailableAppointments: action.response.data, isLoading: false, msgError:'' };
      }
      else {
        return { ...state, isLoading: false };
      }
    case actionTypes.SUCCESS_GET_NEXT_AVAILABLE_APPOINTMENTS_DOCTOR:
      if (action.response) {
        return { ...state, nextAvailableAppointmentsDoctor: action.response.data, isLoading: false, msgError:'' };
      }
      else {
        return { ...state };
      }
    case actionTypes.SET_MODEL_TO_SAVE_APPOINTMENT:
      return { ...state, modelAppointment: action.payload };
    case actionTypes.GET_MODEL_TO_SAVE_APPOINTMENT:
      return { ...state };
    case actionTypes.SUCCESS_GET_APPOINTMENTS_CONFIRMED:
      if (action.response) {
        return { ...state, postAppointment: action.response.data, msgError:'' };
      }
      else {
        return { ...state };
      }
    case actionTypes.SUCCESS_GET_FULL_GROUP_FAMILY_APPOINTMENT:
      if (action.response) {
        return { ...state, fullGroupFamily: action.response.data.data, msgError:'' };
      }
      else {
        return { ...state };
      }

    case actionTypes.SUCCESS_GET_SPECIALTIES_LIST:
      if (action.response) {
        return { ...state, specialtiesList: action.response.data.data, msgError: '' };
      } else {
        return { ...state };
      }

    case actionTypes.SUCCESS_GET_DOCTORS_LIST:
      if (action.response) {
        return { ...state, doctorsList: action.response.data.data, msgError: '' };
      } else {
        return { ...state };
      }

    case actionTypes.SUCCESS_GET_FREE_DOCTOR_APPOINTMENTS_LIST:
      if (action.response) {
        return { ...state, freeDoctorAppointmentsList: action.response.data.data, msgError: '', isLoading: false };
      } else {
        return { ...state };
      }

    case actionTypes.CLEAR_MODEL_APPOINTMENT:
      return { ...state, modelAppointment: [] };
    case actionTypes.CLEAR_AFFILLIATE:
      return {
        ...state, afilliatedAppointments: {
          NumeroPersona: 0,
          SolicitudTurnos: "",
          Count: 0,
          Skip: 0,
        },
      };
    case actionTypes.SET_MODIFY_APPOINTMENT:
      return { ...state, modelModifyAppointment: action.payload };

    default:
      return { ...state };
  }
}
