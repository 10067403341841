import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

/**agregar el llamao a las funciones que se comunican con la api */

function* asyncGetPhonesUtils() {
  try {
    var response = yield call(API.getPhonesUtils);
    if (response)
      yield put({
        type: actionTypes.SUCCES_PHONES,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_PHONES,
      message: error.message,
    });
  }
}

function* asyncgetShiftPhone() {
  try {
    var response = yield call(API.GetShiftPhone);
    if (response)
      yield put({
        type: actionTypes.SUCCES_SHIFT_PHONES,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_SHIFT_PHONES,
      message: error.message,
    });
  }
}




// Watchers
export default function* phonesSaga() {
  yield takeLatest(actionTypes.GET_PHONES, asyncGetPhonesUtils);
  yield takeLatest(actionTypes.GET_SHIFT_PHONES, asyncgetShiftPhone);
}
