import * as actionTypes from '../consts/actionTypes';
import {responseToReturn} from '../../utils/utilsFunctions.js';

const initialState = {
    cards: [],
    msgError: ''
};

export default function cardReducer(state = initialState, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_GET_CARDS]: responseToReturn('cards', state, action),
        [actionTypes.FAILURE_GET_CARDS]: responseToReturn('', state, action)
    };

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}