
export function setDescriptionError(registerReduxState, setDescriptionModal, descriptionModal) {
    let msg = '';
    if (Object.values(registerReduxState?.registerUser).length > 0 && !registerReduxState?.registerUser?.confirmacion) {
        msg = registerReduxState?.registerUser?.mensajes?.map(item => { return item; });
        setDescriptionModal({ ...descriptionModal, description: msg });

    } else if (Object.values(registerReduxState?.registerValidation).length > 0 && !registerReduxState?.registerValidation?.confirmacion) {
        msg = registerReduxState?.registerValidation?.mensajes?.map(item => { return item; });
        setDescriptionModal({ ...descriptionModal, description: msg });
    }
}

export function validate(nameElement, message, val, setError, error) {
    if (val === '') {
        setError({ ...error, [nameElement]: message });
    } else {
        setError({ ...error, [nameElement]: '' });
    }
}

export function openModal(registerReduxState, setOpenConfirmModal, setLoading) {
    if ((registerReduxState?.registerUser?.confirmacion !== undefined && !registerReduxState?.registerUser?.confirmacion)
        || (registerReduxState?.registerValidation?.confirmacion !== undefined && !registerReduxState?.registerValidation?.confirmacion)) {
            setLoading(false);
            setOpenConfirmModal(true);
    }
}

export  function  redirectToHome(loading, registerReduxState) {
    if ((loading && registerReduxState?.registerUser?.confirmacion) || registerReduxState?.registerValidation?.confirmacion) {
        window.localStorage.setItem("registerFlag", 'Registrado');
        window.localStorage.setItem("numeroPersona", registerReduxState?.registerValidation?.numeroPersona);
        window.location.replace("/");
    }
}