import Menu from "../../components/Menu/Menu";
import Header from '../../components/GenericHeader/Header';
import Credentials from "../../components/Credentials/Credentials";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useLayoutEffect, useState } from "react";
import { getAllCredentialsAction } from "../../redux/actions/credentialActions";
import { getLimitPlan } from "../../redux/actions/limitPlansAction";
import { notAccessToThisModule, verifyPermission } from "../../utils/utilsFunctions";
import { CircularProgress } from "@material-ui/core";
import useTrackPageView from "../../utils/useTrackPageView";


const CredentialsHome = () => {
    const dispatch = useDispatch();
    const [hasPermission, setHasPermission] = useState(false);
    const affiliatePlan = useSelector((state) => state.personalDataReducer?.fullData?.afiliado?.planDescripcion);
    const limitPlanReducer = useSelector((state) => state.limitPlanReducer.allLimitPlanData);
    const loadingPermission = useSelector((state) => state.limitPlanReducer.requestInProgress);
    const trackPageView = useTrackPageView("Credenciales")
    
    useEffect(() => {
        verifyPermission(limitPlanReducer, affiliatePlan, "Credenciales", setHasPermission);
    }, [limitPlanReducer, affiliatePlan, hasPermission]);

    useLayoutEffect(() => {
        trackPageView();
        dispatch(getAllCredentialsAction(window.localStorage.getItem("numeroPersona")));
    }, []);

    function showCredentials(condition) {
        var r;
        if (condition) {
            r = (
                <Credentials></Credentials>
            );
        } else {
            r = (notAccessToThisModule());
        }
        return r;
    }

    useEffect(() => {
        dispatch(getLimitPlan());
    }, []);

    return (
        <>
            <Menu />
            <>
                <Header title="Credenciales" colorTitle="#0075C9" />
                {
                    loadingPermission ?
                        <div style={{ display: 'flex', justifyContent: 'center', flex: '0 0 100%', paddingTop: '10px' }}>
                            <CircularProgress />
                        </div>
                        :
                        showCredentials(hasPermission)
                }
            </>
        </>
    );
};

export default CredentialsHome;
