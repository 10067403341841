import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetProgramRequirementAndFormByProgram({ payload }) {
    try {
        let response = yield call(API.getProgramRequirementAndFormByProgram, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_PROGRAMREQUIREMENTANDFORM_BY_PROGRAM, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_PROGRAMREQUIREMENTANDFORM_BY_PROGRAM, message: error.message });
    }
}

function* asyncMedicalPrograms({ payload }) {
    try {
        let response = yield call(API.getMedicalPrograms, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_MEDICAL_PROGRAMS, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_MEDICAL_PROGRAMS, message: error.message });
    }
}

export default function* programRequirementAndFormSaga() {
    yield takeLatest(actionTypes.GET_PROGRAMREQUIREMENTANDFORM_BY_PROGRAM, asyncGetProgramRequirementAndFormByProgram);
    yield takeLatest(actionTypes.GET_MEDICAL_PROGRAMS, asyncMedicalPrograms);
}
