import Iframe from "react-iframe";
import Menu from "../Menu/Menu";
import useStyles from "../../assets/styles/styles";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import FormHeader from "../PersonalData/FormHeader";
import { handleGoToBilling } from "../../utils/utilsFunctions";

const BillingFrequentQuestions = () => {
const classes = useStyles();
const { screen } = useResizeScreen();
    return (
        <>
            <Menu />
            {
                screen.sWidth < 768 &&
                <FormHeader title={"Preguntas Frecuentes"} handleGoTo={handleGoToBilling} />
            }
            <div className={classes.Redirect} style={{marginLeft: "-8px", marginTop: "-10px"}}>
                <Iframe sandbox='' scrolling="yes" src='https://www.oslpasteur.com.ar/PortalHTML5/accesos/PreguntasFrecuentes/index.html?facturacion'
                    className={classes.iframe} />
            </div>

        </>
    );
};

export default BillingFrequentQuestions;
