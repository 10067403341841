import * as actionTypes from "../consts/actionTypes";

/**acciones que se disparan del componente */
export function getConfigurations() {
    return {
        type: actionTypes.GET_CONFIGURATIONS,
        payload: '',
    };
}

export function getConfigurationById(id) {
    return {
        type: actionTypes.GET_CONFIGURATION_BY_ID,
        payload: id,
    };
}

export function getConfigurationLowServiceById(id){
    return {
        type: actionTypes.GET_CONFIGURATION_LOW_SERVICE_BY_ID,
        payload: id,
    }
}

export function getConfigurationReclamoById(id){
    return {
        type: actionTypes.GET_CONFIGURATION_RECLAMO_BY_ID,
        payload: id,
    }
}