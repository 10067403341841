
import { CircularProgress, ClickAwayListener, Divider, FormControl, IconButton, InputLabel, TextField, Typography, Zoom } from "@material-ui/core";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import useStyles from '../../assets/styles/styles';
import { useEffect, useState } from "react";
import MovementsCard from "../Billing/Movements/MovementsCard";
import CustomButton from "../../controls/Button/CustomButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CustomSelect from "../../controls/Select/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import TuneIcon from '@material-ui/icons/Tune';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import ControlPointOutlinedIcon from '@material-ui/icons/ControlPointOutlined';
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import {
    getAuthorizationAffiliate,
    getAuthorizationDetailsSuccess,
    setAuthorizationDetails,
    clearHistorialAuthRequest,
    downloadAuthorization,
    cancelAuthorizationRequest
} from "../../redux/actions/authorizationsActions";
import ModalActivities from "../DialogActivities/ModalActivities";
import FileDownloadIcon from '@material-ui/icons/FileDownload';
import { es } from "date-fns/locale";
import HeaderCard from "../Billing/Movements/HeaderCard";
import NotFound from "../HomeLogged/NotFound";
import { showErrorMessageModal } from "../../utils/utilsFunctions";

const persona = {
    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona"))
};

const AuthorizationsIndex = () => {
    const { screen } = useResizeScreen();
    const classes = useStyles();
    const today = new Date();
    today.setMonth(-6);
    const [selected, setSelected] = useState([]);
    const familyGroup = useSelector(state => state.appointmentsReducer.groupFamilyTurn);
    const [familyGroupList, setFamilyGroupList] = useState([{}]);
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [dateStart, setDateStart] = useState(today);
    const [dateEnd, setDateEnd] = useState(new Date());
    const [affiliatedSelected, setAffiliatedSelected] = useState(persona?.numeroPersona);
    const history = useHistory();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [itemSelected, setItemSelected] = useState({});
    const { historial, loading, downloadAuth, loadingDownloadAuthorization } = useSelector(state => state.authorizationsReducer);
    const errorMessage = useSelector(state => state.authorizationsReducer.msgError);
    const [openError, setOpenError] = useState();
    const cancelAuthorizationResponse = useSelector((state) => state.authorizationsReducer.cancelAuthorization);
    const [openCancelAuthorizationModal, setOpenCancelAuthorizationModal] = useState(false);
    const [cancelAuthorization, setCancelAuthorization] = useState({});
    const [openNullifyAuthorizationModal, setNullifyAuthorizationModal] = useState(false);
    const [cancelledAuthorization, setCancelledAuthorization] = useState(false);

    const handleOpenRequest = (e) => {
        e.preventDefault();
        history.push('/Authorizations?type=NewRequest');
    };

    const handleChangeSelect = (e) => {
        const { value } = e.target;
        setAffiliatedSelected(value);
        dispatch(clearHistorialAuthRequest())
        let aux = historial?.autorizaciones?.filter(x => x.afiliado === Number.parseInt(value));
        setSelected(aux);
    };

    const handleClickAway = () => {
        setOpenDatePicker(false);
    };

    const handleClick = () => {
        setOpenDatePicker((prev) => !prev);
    };


    const handleClickVerMas = (item) => (e) => {
        e.preventDefault();
        let obj = {
            numeroPersona: parseInt(affiliatedSelected),
            gestionCodigo: item.autorizacionGestionCodigo,
            autorizacionNumero: item.autorizacionNumero,
            delegacionCodigo: item.autorizacionDelegacionCodigo
        };
        dispatch(getAuthorizationDetailsSuccess(obj));
        item.afiliado = parseInt(affiliatedSelected);
        dispatch(setAuthorizationDetails(item));
        history.push('/AuthorizationsDetails');
    };

    useEffect(() => {
        if (openError === false) {
            history.push('/');
        }
    }, [openError])

    const setItemToCancel = item => {
        setCancelAuthorization(item);
        setOpenCancelAuthorizationModal(true);
    }

    const setButtons = (item) => {
        let response = '';
        const STATE = {
            INGRESADA: 'ANULAR',
            'EN PROCESO': 'ANULAR',
            CERRADO: 'DESCARGAR'
        };
        const BTN = {
            DESCARGAR: (
                <CustomButton
                    title="DESCARGAR"
                    customClass={classes.lightLinearGradient}
                    style={{ marginTop: "10px", width: "60%", fontSize: "7px", fontWeight: '800' }}
                    onClick={handleClickModal(item)}
                />
            ),

            ANULAR: (
                <CustomButton
                    title="ANULAR"
                    customClass={`${classes.lightLinearGradient}`}
                    style={{ marginTop: "10px", width: "60%", fontSize: "7px", fontWeight: '800' }}
                    onClick={() => { setItemToCancel(item) }}
                />
            )
        };
        if (item?.autorizacionEstadoDescripcion.trim()) {
            response = BTN[STATE[item?.autorizacionEstadoDescripcion.trim().toUpperCase()]];
        }
        return response;
    };

    const handleClickCancelAuthorization = () => e => {
        e.preventDefault();
        let authorizationToCancel = {
            numeroPersona: parseInt(affiliatedSelected),
            gestionCodigo: cancelAuthorization?.autorizacionGestionCodigo
        };
        dispatch(cancelAuthorizationRequest(authorizationToCancel));
        setOpenCancelAuthorizationModal(false);
        setCancelAuthorization({})
        setNullifyAuthorizationModal(true);
    };

    useEffect(() => {
        if (cancelAuthorizationResponse?.confirmacion) {
            getData();
            setNullifyAuthorizationModal(false);
            setCancelledAuthorization(true);
        }
    }, [cancelAuthorizationResponse])

    const handleClickModal = (item) => (e) => {
        e.preventDefault();
        let obj = {
            numeroPersona: parseInt(affiliatedSelected),
            gestionCodigo: item.autorizacionGestionCodigo,
            autorizacionNumero: item.autorizacionNumero,
            delegacionCodigo: item.autorizacionDelegacionCodigo
        };
        dispatch(downloadAuthorization(obj));
        setOpenModal(true);
    };

    const getData = () => {
        if (affiliatedSelected !== undefined) {
            let obj = {
                fechaDesde: format(dateStart, 'yyyy-MM-dd'),
                fechaHasta: format(dateEnd, 'yyyy-MM-dd'),
                numeroPersona: parseInt(affiliatedSelected),
                count: 0,
                skip: 0
            };
            dispatch(getAuthorizationAffiliate(obj));
        }
    };

    useEffect(() => {
        getData();
    }, [affiliatedSelected]);

    useEffect(() => {
        if (Object.values(familyGroup).length > 0) {
            let itemsToPush = familyGroup.map(item => {
                return { value: item.itemCodigo, label: item.itemDescripcion.trim() };
            });
            setFamilyGroupList(itemsToPush);
        }
    }, [familyGroup]);

    useEffect(() => {
        if (Object.values(familyGroupList).length > 0) {
            let aux = familyGroupList[0].value;
            setAffiliatedSelected(aux);
            let authorizations = historial?.autorizaciones?.filter(x => x.afiliado === Number.parseInt(aux));
            setSelected(authorizations);
        }
    }, [familyGroupList]);

    useEffect(() => {
        getData();
    }, [dateStart, dateEnd]);

    useEffect(() => {
        setSelected(historial?.autorizaciones);
    }, [historial?.autorizaciones]);

    const goToFrequentQuestions = () => {
        history.push("/AuthorizationsFAQ");
    };

    useEffect(() => {
        if (downloadAuth?.autorizacion !== undefined) {
            setItemSelected({ archivo: downloadAuth?.autorizacion?.[0]?.archivo, archivoNombreCompleto: downloadAuth?.autorizacion?.[0]?.archivoNombreCompleto });
        }
    }, [downloadAuth]);

    useEffect(() => {
        if (errorMessage !== "" && errorMessage !== undefined) {
            setOpenError(true);
            setNullifyAuthorizationModal(false);
            setOpenCancelAuthorizationModal(false);
            setOpenModal(false);
        }
    }, [errorMessage]);

    useEffect(() => {
        setCancelledAuthorization(false);
        setNullifyAuthorizationModal(false);
        setOpenCancelAuthorizationModal(false);
    }, []);

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: screen.sWidth < 768 ? '5%' : '2%', flexWrap: 'wrap' }}>
                <CustomButton title={"Iniciar solicitud"} onClick={handleOpenRequest}
                    style={{
                        height: "40px",
                        fontSize: "12px",
                        fontWeight: "600",
                        margin: screen.sWidth < 768 ? '15px 13px' : '5px 33px'
                    }}
                    icon={<AddCircleIcon style={{ marginRight: "5px" }} />}
                    customClass={`${classes.customHeaderBtn} ${classes.lightLinearGradient}`}
                />
            </div>
            <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`} style={{ justifyContent: 'space-between' }}>
                <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.txtDarkBlue} `}>
                    Historial
                </Typography>
                <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                    <div style={{ position: "relative" }}>
                        <CustomButton
                            onClick={handleClick}
                            style={{ fontSize: '13px' }}
                            customClass={`${classes.btnFilterAppointment}`}
                            icon={<TuneIcon className={classes.iconFilter} />}
                        />
                        {openDatePicker && (
                            <Zoom in={openDatePicker} >
                                <div className={`${classes.dropdown}`}>
                                    <Typography className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>{"PERIODO"}</Typography>
                                    <Divider classes={{ root: classes.dividerFilter }} />
                                    <div className={`${classes.headerFiltro} ${classes.marginTop}`}>
                                        <FormControl style={{ width: "150px", paddingTop: "10px" }} className={classes.datePicker}>
                                            <InputLabel className={`${classes.txtSubtitleCalendar} ${classes.fontSize15}`} shrink  >{"DESDE"}</InputLabel>
                                            <div className={classes.top22} style={{ display: "flex", justifyContent: "space-between" }}>
                                                <LocalizationProvider locale={es} style={{ width: "100px" }} dateAdapter={AdapterDateFns}  >
                                                    <MobileDatePicker
                                                        cancelText="Cancelar"
                                                        toolbarTitle=""
                                                        format="dd/MM/YYYY"
                                                        value={dateStart}
                                                        onChange={(newValue) => { setDateStart(newValue); }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                                <CalendarTodayIcon style={{ alignSelf: "center" }} />
                                            </div>
                                        </FormControl>
                                        <FormControl style={{ width: "150px", marginLeft: "10px", paddingTop: "10px" }} className={classes.datePicker} >
                                            <InputLabel className={`${classes.txtSubtitleCalendar} ${classes.fontSize15}`} shrink  >{"HASTA"}</InputLabel>
                                            <div className={classes.top22} style={{ display: "flex", justifyContent: "space-between", }}>
                                                <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}  >
                                                    <MobileDatePicker
                                                        cancelText="Cancelar"
                                                        toolbarTitle=""
                                                        format="dd/MM/YYYY"
                                                        value={dateEnd}
                                                        onChange={(newValue) => { setDateEnd(newValue); }}
                                                        variant='outlined'
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                                <CalendarTodayIcon style={{ alignSelf: "center" }} />
                                            </div>
                                        </FormControl>
                                    </div>
                                </div>
                            </Zoom>
                        )}
                    </div>
                </ClickAwayListener>
            </div>
            <Divider className={classes.formDivider} style={{ marginTop: "20px" }} />
            <div style={{ display: 'flex', justifyContent: screen.sWidth > 768 ? 'space-between' : 'space-around', margin: '0 !important', alignItems: 'center', padding: '0 !important' }}>
                <div className={classes.divAccordionPrograms} style={{ minWidth: '240px !important' }}>
                    <FormControl className={`${classes.formControlChronicMedicationForm} ${classes.formControlsEmergency} ${classes.formControlSelect}`} variant="standard">
                        <CustomSelect
                            name={`familyGroup`}
                            list={familyGroupList}
                            selected={affiliatedSelected}
                            onChange={handleChangeSelect}
                            label='AFILIADO'
                            style={{ marginTop: "20px" }} />
                    </FormControl>
                </div>
                <div style={{ marginRight: '20px' }}>
                    <CustomButton title={screen.sWidth > 768 ? 'Preguntas Frecuentes' : ''} onClick={goToFrequentQuestions}
                        style={{
                            height: "40px",
                            fontSize: "12px",
                            fontWeight: "600",
                            marginTop: "15px",
                            padding: '0 16px'
                        }}
                        icon={<QuestionAnswerOutlinedIcon style={{ marginRight: "5px" }} />}
                        customClass={`${classes.lightLinearGradient}`}
                    />
                </div>
            </div>
            <div className={classes.divSectionMovements} style={{ display: "flex", justifyContent: "center" }}>
                {selected?.length > 0 ?

                    selected?.map((item, i) => (
                        <MovementsCard
                            key={item?.id}
                            count={item?.count}
                            headerIcon={<HeaderCard day={item?.fechaFormateada?.nombreDia} numberDay={item?.fechaFormateada?.dia} month={Number.parseInt(item?.autorizacionGestionFecha?.split('-')[1])} year={item?.fechaFormateada?.anio} />}
                            description={
                                <>
                                    <Typography
                                        style={{ fontSize: '14px', fontWeight: "600", color: '#0075c9', marginTop: '10px' }}
                                        className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}
                                    >
                                        {item?.autorizacionEstadoDescripcion?.trim()}
                                    </Typography>
                                    <Typography style={{ color: '#0075c9', fontWeight: "800", marginTop: '2px' }} className={`${classes.txtCardDescription} ${classes.txtEllipsis} ${classes.txtDarkBlue}`} >
                                        TIPO: <span> {item?.autorizacionTipoPrestacionDescripcion}</span>
                                    </Typography>
                                    <Typography style={{ color: '#0075c9', display: 'flex', flexWrap: 'wrap', marginTop: '2px' }} className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>
                                        AUTORIZACION Nº <span style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}> {item?.autorizacionNumero}</span>
                                    </Typography>
                                    <Typography
                                        style={{ color: '#0075c9', fontWeight: "800", marginTop: '2px' }}
                                        className={`${classes.txtCardDescription} ${classes.txtEllipsis} ${classes.txtDarkBlue}`}
                                    >SOLICITUD Nº: <span> {item?.autorizacionGestionCodigo}</span>
                                    </Typography>
                                </>
                            }
                            buttonZone={
                                <section className={`${classes.sectionButtons} ${classes.btnAuthorizationCard}`}>
                                    <div>
                                        <IconButton onClick={handleClickVerMas(item)} customClass={`${classes.customHeaderBtn}`}>
                                            <Typography style={{ fontSize: '12px', fontWeight: "600", color: '#0075c9' }} className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>
                                                VER
                                            </Typography>
                                            <ControlPointOutlinedIcon style={{ marginLeft: "5px", fontSize: '13px', marginTop: '-2px' }} className={classes.iconFilter} />
                                        </IconButton>
                                    </div>
                                    <div style={{ paddingRight: '7%' }}>
                                        {setButtons(item)}
                                    </div>
                                </section>
                            } index={i} />
                    ))
                    :
                    <NotFound
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        customMsg={
                            <div className={classes.contentNotFound}>
                                <span>No se registraron autorizaciones</span>
                            </div>}
                        wsItem={selected}
                        fullItem={selected}
                        loading={loading}
                    />
                }
            </div>
            {
                openModal &&
                    <ModalActivities
                        title={
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', width: '250px' }}>
                                <span>Descargar</span>
                            </div>
                        }
                        description={
                            <div style={{ display: 'flex', justifyContent: 'center', margin: '5px' }}>
                                <a href={`data:image/png;base64,${itemSelected?.archivo}`} download={itemSelected?.archivoNombreCompleto}>
                                    <IconButton style={{ backgroundColor: '#ffffff', borderRadius: '15px' }}>
                                        <FileDownloadIcon className={classes.actionsIcons}/>
                                    </IconButton>
                                </a>
                            </div>
                        }
                        loading ={loadingDownloadAuthorization}
                        open={openModal}
                        onClose={setOpenModal}
                        modalconfirm={false}
                        modalLogout={false}
                    />
            }
            {showErrorMessageModal(errorMessage, openError, setOpenError, classes.borderWhite, classes.quickAccessIcon)}

            {
                openCancelAuthorizationModal &&
                <ModalActivities
                    title={'Anular solicitud'}
                    description={
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                Estás por anular la solicitud de autorización seleccionada
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px'
                                }}>
                                ¿Querés continuar?
                            </div>
                        </>
                    }
                    buttonType={'submit'}
                    onClose={setOpenCancelAuthorizationModal}
                    modalconfirm={true}
                    open={openCancelAuthorizationModal}
                    modalLogout={false}
                    handleClick={handleClickCancelAuthorization()}
                    style={{ textAlign: 'center' }}
                />
            }

            {
                openNullifyAuthorizationModal &&
                <ModalActivities
                    title={
                        <div style={{ marginTop: "30px" }}>Anulando autorización ...</div>
                    }
                    description={
                        <div
                            className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.whiteCircularProgress}`}>
                            <CircularProgress />
                        </div>
                    }
                    onClose={setNullifyAuthorizationModal}
                    open={openNullifyAuthorizationModal}
                    style={{ textAlign: 'center' }}
                    modalLogout={false}
                    modalconfirm={false}
                />
            }

            {
                cancelledAuthorization &&
                <ModalActivities
                    title={"Autorización anulada"}
                    description={
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            La solicitud seleccionada ha sido anulada
                        </div>
                    }
                    onClose={setCancelledAuthorization}
                    open={cancelledAuthorization}
                    modalLogout={false}
                    style={{ textAlign: 'center' }}
                    modalconfirm={false}
                />
            }
        </>
    );
};

export default AuthorizationsIndex;