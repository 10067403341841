import { Button, Divider, Typography } from "@material-ui/core";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import FormHeader from "../../PersonalData/FormHeader";
import CustomButton from "../../../controls/Button/CustomButton";
import { useEffect, useLayoutEffect, useState } from "react";
import useStyles from '../../../assets/styles/styles';
import EditIcon from '@material-ui/icons/Edit';
import FactorGroupForm from "./FactorGroupForm";
import { getFactorGroup, getGroupFactorParams } from "../../../redux/actions/medicalDataAction";
import { useDispatch, useSelector } from "react-redux";
import { handleGoToPersonalMedicalData, showMessageModal } from "../../../utils/utilsFunctions";
import SpinnerLoading from "../../LoadingPage/SpinnerLoading";

const param = {
    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
    count: 10,
    skip: 0
}

const FactorGroup = () => {
    const { screen } = useResizeScreen();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [disabled, setDisabled] = useState(true);
    const factor = useSelector(state => state.medicalDataReducer?.factorGroup);
    const [factorGroup, setFactorGroup] = useState(factor.grupoFactor);
    const [isLoading, setIsLoading] = useState(true);
    const errorMessage = useSelector(state => state.medicalDataReducer.msgError);
    const [errorMessageModal, setErrorMessageModal] = useState(false);

    useLayoutEffect(() => {
        dispatch(getGroupFactorParams())
        dispatch(getFactorGroup(param));
    }, []);

    useEffect(() => {
        setFactorGroup(factor.grupoFactor);
        setIsLoading(false);
    }, [factor.grupoFactor]);

    useEffect(() => {
        if (errorMessage?.length > 0) {
            setErrorMessageModal(true);
        }
        setIsLoading(true);
    }, [errorMessage]);

    function formHeaderText() {
        var response = {
            titleHeader: 'Grupo y Factor',
            subtitleHeader: 'Información sin carácter sanitario conforme ley Nº 26529'
        };
        if (screen.sWidth < 768) {
            response.titleHeader = 'Mis datos medicos';
            response.subtitleHeader = 'Información sin carácter sanitario conforme ley Nº 26529';
        }
        return response;
    }

    const handleEdit = (e) => {
        e.preventDefault();
        setDisabled(prevState => !prevState);
        dispatch(getFactorGroup(param));
    };

    const customsIcons = () => {
        if (disabled) {
            return (
                <Button onClick={handleEdit}>
                    <EditIcon className={`${classes.ShowMoreArrowIcon}`} />
                </Button>
            );
        }

    };

    function createForm(){
        return (
            <>
                <FormHeader title={formHeaderText().titleHeader} subtitle={formHeaderText().subtitleHeader} handleGoTo={handleGoToPersonalMedicalData} />
            {
                screen.sWidth > 768 &&
                <Divider className={classes.formDivider} />
            }
            {disabled ?
                <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                    {screen.sWidth <= 768 ?
                        <>
                            <Typography
                                className={`${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency}`}
                                style={{ textTransform: 'uppercase', marginLeft: "10%", marginTop: "20px" }}>
                                GRUPO Y FACTOR
                            </Typography>
                            {customsIcons()}
                        </>
                        :
                        <>
                            <CustomButton
                                title="EDITAR"
                                customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                                onClick={handleEdit}
                                style={{ padding: '5px 20px 5px 20px', fontWeight: "bold", fontSize: "10px" }}
                            />
                        </>
                    }
                </div> :

                <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                    {screen.sWidth <= 768 ?
                        <> 
                            <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency}`} style={{ textTransform: 'uppercase', marginTop: "20px" }}>
                                GRUPO Y FACTOR
                            </Typography>
                            {customsIcons()}
                        </>
                        :
                        <>
                            <CustomButton
                                title="CANCELAR"
                                customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                                onClick={handleEdit}
                                style={{ marginRight: "10px", marginLeft: "45px" }}
                            />
                        </>
                    }
                </div>
            }
            <FactorGroupForm factorGroup={factorGroup} setFactorGroup={setFactorGroup} disabled={disabled} setDisabled={setDisabled} />
            {showMessageModal(errorMessage, errorMessageModal, setErrorMessageModal, classes.borderWhite, classes.quickAccessIcon, classes.contentDialogTitle, handleGoToPersonalMedicalData)}
            </>
        );
    }

    return (
        <>
            {
                isLoading ? <SpinnerLoading/> : createForm()
            }
        </>
    );
};

export default FactorGroup;