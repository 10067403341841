import React, { Fragment } from 'react';
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import useStyles from '../../../assets/styles/styles';
import TopButtons from "../../AskTurn/Web/Buttons/TopButtons";
import DrawerRequestSteps from "./DrawerRequestSteps";

const list = [
    {
        step0: ""
    },
    {
        step1: ""
    },
];

const titles = [
    {
        title1: "Iniciar solicitud",
    },
    {
        title2: "Confirmar solicitud",
    }
];

const RequestAuthorization = (props) => {
    const { activeStep, setActiveStep, handleOpenCancelModal, handleBack, handleNext, handleCloseDrawer, } = props;
    const classes = useStyles();
    const { screen } = useResizeScreen();

    const addTopButtons = () => {
        let response = (<Fragment></Fragment>);
        if (activeStep > 1) {
            response = (<TopButtons handleOpenCancelModalTurn={handleOpenCancelModal} handleBackTurn={handleBack} activeStepTurn={activeStep} handleNextTurn={handleNext} titleCancel={"Cancelar"} titleConfirm={"Siguiente"} />);
        }
        return response;
    };

    return <>
        {screen.sWidth > 768 && activeStep === 2 ?
            <div className={classes.containerNewAppointment} >
                <DrawerRequestSteps isWeb={true} open={true} titles={titles} activeStep={activeStep} setActiveStep={setActiveStep}
                    handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} handleNext={handleNext} handleCloseDrawer={handleCloseDrawer} />
            </div>
            :
            <div className={classes.containerNewAppointment} >
                {addTopButtons()}
                <DrawerRequestSteps isWeb={true} open={true}
                    activeStep={activeStep} setActiveStep={setActiveStep}
                    handleOpenCancelModal={handleOpenCancelModal}
                    handleCloseDrawer={handleCloseDrawer}
                    handleBack={handleBack} handleNext={handleNext}
                />
            </div>
        }
    </>;
};

export default RequestAuthorization;
