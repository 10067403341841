import ModalActivities from "../DialogActivities/ModalActivities";

const ModalLogOut = ({ isOpen, setOpen }) => {
    return <>
        { isOpen ?
            <ModalActivities
                title={"Cerrar sesión"}
                description={"¿Estás seguro que deseas salir?"}
                open={isOpen}
                onClose={setOpen}
                modalconfirm={true}
                modalLogout={true}
            />
            : ""}
    </>;
};

export default ModalLogOut;