import { IDENTITY_CONFIG, METADATA_OIDC } from "../../utils/authConst";
import { UserManager, WebStorageStateStore } from "oidc-client";
import jwt_decode from 'jwt-decode';

export default class AuthService {
  UserManager;
  accessToken;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    });
    this.UserManager.events.addUserLoaded((user) => {
      localStorage.setItem("access_token", user.access_token);
      localStorage.setItem("id_token", user.id_token);
      this.accessToken = user.access_token;
      this.setUserInfo({
        accessToken: this.accessToken,
        idToken: user.id_token,
      });
      if (window.location.href.indexOf("callback") !== -1) {
        /**ir a la api mobile para ver si esta hecho el registro */
        // si esta el registro:
        this.navigateToScreen();
        /**sino redirijo a */
      }
    });
    this.UserManager.events.addSilentRenewError();

    this.UserManager.events.addAccessTokenExpired(() => {
      this.signinSilent();
    });
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(() => {
      "";
    });
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = (token) => {
    if (token !== null || token !== undefined) {
      return jwt_decode(token);
    }
    return null;
  };

  setUserInfo = (authResult) => {
    const data = this.parseJwt(this.accessToken);

    this.setlocalInfo(authResult);
    this.setUser(data);
  };

  signinRedirect = () => {
    localStorage.setItem("redirectUri", window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  setUser = (data) => {
    const nombre = data.given_name ?? '';
    const apellido = data.family_name ?? '';
    localStorage.setItem("userId", data.sub);
    localStorage.setItem("userName", `${nombre}_${apellido}`);
    localStorage.setItem("role", data.role);
    localStorage.setItem("email", data.email);
  };

  navigateToScreen = async () => {
    window.location.replace("/Register");
  };

  setlocalInfo(authResult) {
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
  }

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      localStorage.getItem(
        `oidc.user:${window.env.API_IDENTITY}:${window.env.CLIENT_ID_MOBILE}`
      )
    );
    return !!oidcStorage && !!oidcStorage.access_token;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log("signed in", user);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token"),
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(window.env.URL_PUBLIC_MOBILE);
    });
    this.UserManager.clearStaleState();
  };
}
