import React, {useEffect, useState} from 'react';
import { format } from "date-fns";
import {Typography } from "@material-ui/core";
import QRCode from "react-qr-code";
import useStyles from "../../assets/styles/styles";

const CredentialCard = ({affilliated}) => {

  const [lastUpdate, setLastUpdate] = useState({ date: "", hour: "" });
  const classes = useStyles();

    const qrText = (aff) => {
        return `${window.env.URL_SERVICE}/Credential/QR/${
          aff?.credentials?.numeroPersona
        }/${aff?.credentials?.numeroAfiliado
          ?.replaceAll("-", "")
          .replaceAll("/", "")}`;
    };

    useEffect(() => {
      const dateUpdated = format(new Date(), "dd-MM-yyyy");
      const hourUpdated = `${new Date().getHours()}:${String(new Date().getMinutes()).padStart(2, '0')}`;
      setLastUpdate({ ...lastUpdate, date: dateUpdated, hour: hourUpdated });
    }, []);

  return (
    <div className={classes.credBody}>
        <div className={`${classes.credImage}`}>
            <div className={classes.credDivText}>
                <Typography className={`${classes.credText} ${classes.credBold}`}>{affilliated?.credentials?.numeroAfiliado}</Typography>
                <Typography className={`${classes.credText} ${classes.credBold} ${classes.credTextName}`}>{affilliated?.credentials?.apellidoNombre}</Typography>
                <Typography className={`${classes.credText} ${classes.credBold}`}>{affilliated?.credentials?.documento} {affilliated?.credentials?.condicionIVA}</Typography>
                <Typography className={`${classes.credText} ${classes.credBold}`}>Plan: <strong>{affilliated?.credentials?.planDescripcion}
                  {affilliated?.credentials?.pmi && ` | P.M.I VTO ${affilliated?.credentials?.fechaVigencia}`}</strong>
                </Typography>
                <Typography className={`${classes.credText} ${classes.credBold} ${classes.credTextName}`}>
                  {affilliated?.credentials?.marcaCopago ? "Con copago" : ""}
                </Typography>
            </div>
            <div className={classes.credFooter}>
                <QRCode
                    value={qrText(affilliated)}
                    size={95}
                    className={classes.credQR}
                />
                <Typography className={classes.credFooterText}>Presentar junto al DNI</Typography>
                <Typography className={classes.credLastUpdate}>{`Última act: ${lastUpdate.date} | ${lastUpdate.hour}hs.`}</Typography>

            </div>
        </div>
    </div>
  )
}

export default CredentialCard
