import { Grid, TextField } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import Autocomplete from "../../controls/Autocomplete/Autocomplete";
import CustomButton from "../../controls/Button/CustomButton";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import useStyles from "../../assets/styles/styles";

const MedicalForm = (props) => {
    const { handleSubmit, setOptionsCartilla, planes, errors,
        zonas, tipoPrestador,
        loading, userPlan, filtersCartilla,
        handleCleanSearch, modelCartilla,
        selectedValues,
        setModelCartilla,
        disableAlphabeticSearch,
        setSelectedValues, disableZone, values,
        disableCity, disableProvider, disable,
        btnDisabled, setValues } = props;
    const { screen } = useResizeScreen();
    const classes = useStyles();
    const [resetLocaltiesSelection, setResetLocaltiesSelection] = useState(0);

    function Responsive() {
        let width = screen.sWidth;
        if (width <= 1000) return `${classes.divFiltersMobile} `;
    }

    function disableFunction(propDisabled) {
        return propDisabled ? classes.disableTextField : classes.enableTextField;
    }

    function setOptionsCity(filtersCartillaValues) {
        if (filtersCartillaValues.localidadesBarrios?.localidadBarrio !== undefined) {
            var localities;
            localities = filtersCartillaValues.localidadesBarrios?.localidadBarrio.sort(function (a, b) {
                if (a.itemDescripcion > b.itemDescripcion) {
                  return 1;
                }
                if (a.itemDescripcion < b.itemDescripcion) {
                  return -1;
                }
                return 0;
              });
            return localities;
        } else {
            return [""];
        }
    }

    function setOptionsEspecialidadesEstudios(filtersCartillaEstudiosValues) {
        if (
            filtersCartillaEstudiosValues.especialidadesEstudios
                ?.especialidadesEstudios !== undefined
        ) {
            return filtersCartillaEstudiosValues.especialidadesEstudios
                ?.especialidadesEstudios;
        } else {
            return [""];
        }
    }

    function verifyZone(zoneValue) {
        if (zoneValue === "101") {
            return "BARRIO";
        } else if (zoneValue === null) {
            return "LOCALIDAD/BARRIO";
        } else {
            return "LOCALIDAD";
        }
    }

    function verifyLabelEspecialidad(providerValue) {
        if (providerValue === "140") {
            return "ESTUDIOS COMPLEMENTARIOS";
        } else {
            return "ESPECIALIDAD";
        }
    }

    useEffect(() => {
        setResetLocaltiesSelection(prev => prev + 1);
    }, [selectedValues?.zone])

    return (
        <Fragment>
            <form
                style={{ flexWrap: "wrap" }}
                className={`${classes.divFilterBtn} ${Responsive()}`}
                onSubmit={handleSubmit}
            >
                <>
                    <Grid className={classes.gridContainerCartilla} container>
                        <Grid
                            item
                            xl={8}
                            md={8}
                            xs={12}
                            sm={6}
                            style={{ padding: "10px 10px", maxWidth: '300px' }}
                        >
                            <Autocomplete
                                autocompleteProp='nope'
                                label="PLAN"
                                name="plan"
                                option={planes}
                                customClass={classes.enableTextField}
                                onChange={(event, newValue) => {
                                    setOptionsCartilla(
                                        "plan",
                                        "FiltroPlan",
                                        newValue,
                                        "string"
                                    );
                                }}
                                error={errors.plan}
                                loading={loading}
                                selected={userPlan}
                            />
                        </Grid>
                        <Grid
                            item
                            xl={8}
                            md={8}
                            xs={12}
                            sm={6}
                            style={{ padding: "10px 10px", maxWidth: '300px' }}
                        >
                            <Autocomplete
                                autocompleteProp='nope'
                                label="ZONA"
                                name="zone"
                                option={zonas}
                                onChange={(event, newValue) => {
                                    setOptionsCartilla("zone", "FiltroZona", newValue, "int");
                                }}
                                error={errors.zone}
                                loading={loading}
                                disabled={disableZone}
                                customClass={disableFunction(disableZone)}
                                selected={{ itemCodigo: modelCartilla?.FiltroZona, itemDescripcion: selectedValues?.zone }}
                            />
                        </Grid>
                        <Grid
                            item
                            xl={8}
                            md={8}
                            xs={12}
                            sm={6}
                            style={{ padding: "10px 10px", maxWidth: '300px' }}
                        >
                            <div style={{ marginTop: '5px', color: 'red', fontSize:'12px' , marginLeft:'15px'}}>
                                {selectedValues.city.length >= 5 ? "Máximo a seleccionar 5 localidades" : ""}
                            </div>
                            <Autocomplete
                                key={resetLocaltiesSelection} 
                                disableCloseOnSelect
                                autocompleteProp='nope'
                                label={verifyZone(values?.zone)}
                                name="city"
                                disabled={disableCity}
                                customClass={disableFunction(disableCity)}
                                option={setOptionsCity(filtersCartilla)}
                                onChange={(event, newValue) => {
                                    setOptionsCartilla(
                                        "city",
                                        "FiltroLocalidad",
                                        newValue,
                                        "array"
                                    );
                                }}
                                error={errors?.city}
                                loading={loading}
                                selectedValues={selectedValues?.city}
                                multiple
                                sx={{
                                    '& .MuiAutocomplete-tag': {
                                      color: '#0075C9',
                                      backgroundColor: '#f2f2f2'
                                    },
                                    '& .MuiChip-deleteIcon': {
                                        color: '#0075C9 !important'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xl={8}
                            md={8}
                            xs={12}
                            sm={6}
                            style={{ padding: "10px 10px", maxWidth: '300px' }}
                        >
                            <Autocomplete
                                autocompleteProp='nope'
                                label="TIPO DE PRESTADOR"
                                name="provider"
                                option={tipoPrestador}
                                onChange={(event, newValue) => {
                                    setOptionsCartilla(
                                        "provider",
                                        "FiltroTipoPrestador",
                                        newValue,
                                        "string"
                                    );
                                }}
                                error={errors.provider}
                                disabled={disableProvider}
                                customClass={disableFunction(disableProvider)}
                                selected={{ itemCodigo: modelCartilla?.FiltroTipoPrestador, itemDescripcion: selectedValues?.provider?.trim() }}
                            />
                        </Grid>
                        <Grid
                            item
                            xl={8}
                            md={8}
                            xs={12}
                            sm={6}
                            style={{ padding: "10px 10px", maxWidth: '300px' }}
                        >
                            <Autocomplete
                                autocompleteProp='nope'
                                label={verifyLabelEspecialidad(values.provider)}
                                name="department"
                                disabled={disable}
                                customClass={disableFunction(disable)}
                                option={setOptionsEspecialidadesEstudios(filtersCartilla)}
                                onChange={(event, newValue) => {
                                    if (values.provider === "140") {
                                        setOptionsCartilla(
                                            "department",
                                            "FiltroEstudioComplementario",
                                            newValue,
                                            "int"
                                        );
                                    } else {
                                        setOptionsCartilla(
                                            "department",
                                            "FiltroEspecialidad",
                                            newValue,
                                            "string"
                                        );
                                    }
                                }}
                                error={errors.department}
                                loading={loading}
                                selected={{ itemCodigo: modelCartilla?.FiltroEspecialidad, itemDescripcion: selectedValues?.department?.trim() }}
                            />
                        </Grid>
                        <Grid
                            item
                            xl={8}
                            md={8}
                            xs={12}
                            sm={6}
                            style={{ padding: "10px 10px", maxWidth: '300px' }}
                        >
                            <TextField
                                id="alphabeticalSearch"
                                style={{ width: "100%" }}
                                label="BUSQUEDA ALFABETICA"
                                name="alphabeticalSearch"
                                className={`${classes.buttonsHeadersFilters} ${classes.textBtnFilter} ${disableFunction(disableAlphabeticSearch)}`}
                                onChange={(event) => {
                                    setValues({
                                        ...values,
                                        alphabeticalSearch: event.target.value,
                                    });
                                    setSelectedValues({
                                        ...selectedValues,
                                        alphabeticalSearch: event.target.value,
                                    });
                                    setModelCartilla({
                                        ...modelCartilla,
                                        FiltroRazonSocial: event.target.value,
                                    });
                                }}
                                value={values.alphabeticalSearch}
                                disabled={disableAlphabeticSearch}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        xl={12}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "20px 0px",
                            flex: "0 0 100%",
                            flexWrap: 'wrap'
                        }}
                    >
                        <CustomButton
                            typeButton="header"
                            type="submit"
                            title="BUSCAR"
                            disabled={btnDisabled}
                            customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                            style={{ margin: '8px' }}
                        />
                        <CustomButton
                            typeButton="header"
                            onClick={handleCleanSearch}
                            title="LIMPIAR"
                            customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                            style={{ margin: '8px' }}
                        />
                    </Grid>
                </>
            </form>
        </Fragment>
    );
};

export default React.memo(MedicalForm);