import { useEffect, useState, Fragment } from 'react';
import CustomizedInputs from "../../../controls/InputCustom/CustomizedInputs";
import useStyles from "../../../assets/styles/styles";
import { FormControl, FormHelperText, FormControlLabel, Checkbox, IconButton, InputAdornment, InputLabel, TextField, Typography, CircularProgress } from "@material-ui/core";
import CustomButton from '../../../controls/Button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import CustomSelect from '../../../controls/Select/CustomSelect';
import EditIcon from '@material-ui/icons/Edit';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ConfirmModal from '../ConfirmModal';
import UploadModal from "../../../controls/UploadModal/UploadModal";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { validate, deleteFile, handleChangeMail, isDisabled, validateFiles, validateData, messageModalFunction, enableEnviarBtn } from '../../../utils/utilsFunctions';
import { LocalizationProvider, MobileDatePicker } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { getFile, postChangePlan } from '../../../redux/actions/procedureActions';
import { es } from "date-fns/locale";

const MotivosBaja = [
    {
    },
];

const Adjuntos = [
    {
    }
];

const ChangePlanHeadline = ({ processSelected }) => {
    const classes = useStyles();
    const data = useSelector(state => state.procedureReducer);
    const postChangeReducer = useSelector(state => state.procedureReducer.postChangePlan);
    const fullPersonalData = useSelector(state => state.personalDataReducer);
    const dispatch = useDispatch();
    const [mail, setMail] = useState('');
    const [phone, setPhone] = useState('');
    const [disabledPhone, setDisabledPhone] = useState(true);
    const [disabledEmail, setDisabledEmail] = useState(true);
    const [modelToSend, setModelToSend] = useState({
        mailModificar: "N",
        sector: "AFILIACIONES"
    });
    const [listOfAttachmentButtons, setListOfAttachmentButtons] = useState([]);
    const [files, setFiles] = useState([]);
    const [fileButtons, setFileButtons] = useState([]);
    const [nameOfItem, setItemName] = useState('');
    const [error, setError] = useState([]);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [itemRequired, setItemRequired] = useState('');
    const [openSendSuccess, setOpenSendSuccess] = useState(false);
    const [mailError, setMailError] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalDescription, setModalDescription] = useState("");
    const [modalIcon, setModalIcon] = useState('');
    const [errorClass, setErrorClass] = useState('');
    const [planes, setListPlanes] = useState([]);
    const [selectedPlanDisp, setSelectedPlanDisp] = useState('');
    const [lowMotives, setLowMotives] = useState([]);
    const date = new Date();
    const [dateLow, setDateLow] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 1));
    const [motiveSelected, setMotiveSelected] = useState('');
    const [planAffiliate, setPlanAffiliate] = useState('');
    const [showPermanente, setShowPermanente] = useState(false);
    const [emailChecked, selEmailChecked] = useState(false);
    const [query, setQuery] = useState("");
    const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);

    useEffect(() => {
        let tel = fullPersonalData?.fullData?.afiliado?.telefonos[0]?.telefono;
        setPhone(tel);
        fullPersonalData?.fullData?.afiliado?.mails?.forEach((item) => {
            setearMail(item, setMail);
        });
    }, [fullPersonalData?.fullData?.afiliado]);

    useEffect(() => {
        let newFiles = data?.procedures?.tramites?.filter((x) => x.tramiteCodigo == processSelected)[0];
        if (newFiles !== undefined) {
            setAttachment(newFiles?.subTramites?.filter((x) => x.subTramiteCodigo === processSelected)[0], setListOfAttachmentButtons, Adjuntos);
            setFiles([]);
            setFileButtons([]);
        }
    }, [processSelected, data.getPlan?.tramites]);

    useEffect(() => {
        data?.dataAffiliates?.afiliados?.forEach((item) => {
            if (item.numeroPersona === data?.dataAffiliates?.afiliados[0]?.numeroPersona) {
                setPlanAffiliate(item.planCodigo);
            }
        });
    }, [data?.dataAffiliates]);

    useEffect(() => {
        data?.getPlanDisponible?.planes?.forEach(item => {
            if (item.planCodigo != planAffiliate) {
                setListPlanes((prevState) => [...prevState, { value: item.planCodigo, label: item.planDescripcion }]);
            }
        });
    }, [data?.getPlanDisponible]);

    useEffect(() => {
        if (data.getMotivosPlan != null) {
            setearMotivosPlan(data, MotivosBaja, setLowMotives);
        }
    }, [data.getMotivosPlan]);

    const handleChangeSelectPlan = () => e => {
        const { name, value } = e.target;
        setSelectedPlanDisp(value);
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    const handleChangeSelectMotive = () => e => {
        const { name, value } = e.target;
        setMotiveSelected(value);
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    const handleEditEmail = () => {
        setDisabledEmail(prevState => !prevState);
    };

    const handleChangeText = () => e => {
        const { value, name } = e.target;
        if (name !== 'consulta') {
            validate(name, '*Campo obligatorio', value, setError, error);
            setPhone(value);
        }
        setModelToSend({ ...modelToSend, [name]: value });
    };

    const handleEditPhone = () => {
        setDisabledPhone(prevState => !prevState);
    };

    const handleOpenModal = (itemName, fileRequired) => (e) => {
        e.preventDefault();
        setOpenUploadModal(prevState => !prevState);
        setItemName(itemName);
        setItemRequired(fileRequired);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateFiles(listOfAttachmentButtons, files)) {
            let filesToSend = files.map((item) => {
                delete (item['btnNombre']);
                delete (item["adjuntoObligatorio"]);
                return item;
            });
            let parametro = {
                numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                cambioPlanCodigo: processSelected,
                numeroPersonaCambio: "",
                planCodigoNuevo: selectedPlanDisp,
                cambioPlanFecha: dateLow.toISOString(),
                motivoCambioPlanCodigo: motiveSelected,
                mail: mail,
                mailModificar: showPermanente ? "S" : "N",
                telefono: phone,
                consulta: query,
                adjuntos: JSON.stringify(filesToSend)
            };
            let newError = validationSelect(parametro, dateLow, date);
            setError(newError);
            if (validateData(newError)) {
                let circularProgress = (<div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress color="inherit" /></div>);
                setModalTitle('INICIANDO GESTION');
                setModalDescription(circularProgress);
                setModalIcon('');
                setErrorClass('');
                setOpenSendSuccess(prevState => !prevState);
                dispatch(postChangePlan(parametro));
            }
        }
    };



    const handleCheck = (e) => {
        e.preventDefault();
        if (!emailChecked) {
            setModalTitle('SE HA MODIFICADO EL MAIL CORRECTAMENTE');
            setModalDescription(`Este cambio se realizará en el e-mail particular.`);
            setModalIcon(
                <CheckCircleOutlineIcon 
                    className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`}
                />
            );
            setErrorClass('');
            setOpenSendSuccess(prevState => !prevState);
        }
        selEmailChecked(prevState => !prevState);
    };

    const dowloadFile = (codigo) => {
        dispatch(getFile(codigo));
    };

    useEffect(() => {
        if (data.file != null && data.file.result) {
            var a = document.createElement("a"); //Create <a>
            a.href = "data:image/png;base64," + data.file.base64; //Image Base64 Goes here
            a.download = "Image.png"; //File name Here
            a.click(); //Downloaded file
        }
    }, [data.file]);

    useEffect(() => {
        messageModalFunction(
            postChangeReducer, setModalTitle,
            setModalDescription,
            setModalIcon,
            setErrorClass,
            classes,
            setOpenSendSuccess,
            mail
        );

        return (() => {
            if (postChangeReducer?.confirmacion) {
                setModalTitle('');
                setModalDescription('');
                setModalIcon('');
                setErrorClass('');
            }
        });
    }, [postChangeReducer]);

    return (
        <>
            <FormControl className={`${classes.formControlCustomSelect} ${classes.itemGridSpan2}`} >
                <CustomSelect
                    name='plans'
                    label='PLANES DISPONIBLES'
                    list={planes}
                    selected={selectedPlanDisp}
                    onChange={handleChangeSelectPlan()}
                />
                <FormHelperText className={classes.errorText}>{error?.plans}</FormHelperText>
            </FormControl>
            <FormControl className={`${classes.formControlCustomSelect} ${classes.itemGridSpan1}`} >
                <CustomSelect
                    name='lowMotive'
                    label='MOTIVOS DEL CAMBIO'
                    list={lowMotives}
                    selected={motiveSelected}
                    onChange={handleChangeSelectMotive()}
                />
                <FormHelperText className={classes.errorText}>{error?.lowMotive}</FormHelperText>
            </FormControl>
            <FormControl className={`${classes.formControlDatePicker} ${classes.itemGridSpan1}`} >
                <FormControl className={classes.formControl}>
                    <InputLabel style={{ top: "15px" }} className={`${classes.inputLabelSelectForms}`} shrink htmlFor="bootstrap-input">
                        {"FECHA "}
                    </InputLabel>
                    <div style={{ marginTop: "30px" }} className={classes.top22}>
                        <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}  >
                            <MobileDatePicker
                                cancelText="Cancelar"
                                toolbarTitle=""
                                format="dd/MM/YYYY"
                                value={dateLow}
                                shouldDisableDate={(date) => date > new Date(date.getFullYear(), date.getMonth(), 1)}
                                disablePast={true}
                                onChange={(newValue) => { setDateLow(newValue); }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            {<FormHelperText className={classes.errorText}>{"El cambio se realiza el primer dia del mes"}</FormHelperText>}
                            {<FormHelperText className={classes.errorText}>{error?.date}</FormHelperText>}
                        </LocalizationProvider>
                    </div>
                </FormControl>
            </FormControl>

            <FormControl style={{ marginTop: "20px", marginBottom: "20px" }} className={`${classes.formControlsAffiliations} ${classes.divFamily} ${classes.itemGridSpan1}`} variant="standard">
                <div classsName={`${classes.iconsCards} `}>

                    <Typography className={`${classes.txtLabelTramites} ${classes.txtEllipsis}`}>TITULAR</Typography>
                    {data.dataAffiliates?.items?.map((item) => (
                        item.itemCodigo == data.dataAffiliates?.afiliados[0]?.numeroTitular &&
                        <Typography className={`${classes.txtSubtitleCalendar} ${classes.txtEllipsis} ${classes.left10}`}>{item.itemDescripcion}</Typography>
                    ))}
                </div>

            </FormControl>
            <FormControl style={{ marginTop: "20px", marginBottom: "20px" }} className={`${classes.formControlsAffiliations}  ${classes.divFamily}  ${classes.itemGridSpan1} `} variant="standard">
                <div classsName={`${classes.iconsCards} `}>
                    <Typography className={`${classes.txtLabelTramites} ${classes.txtEllipsis}`}>PLAN</Typography>

                    {data.dataAffiliates?.afiliados?.map((item) => (
                        item.numeroPersona === data.dataAffiliates?.afiliados[0]?.numeroPersona &&
                        <Typography className={`${classes.txtSubtitleCalendar} ${classes.txtEllipsis} ${classes.left10}`}>{item.planDescripcion}</Typography>
                    ))}
                </div>
            </FormControl>
            <FormControl style={{ marginTop: "20px", marginBottom: "20px" }} className={`${classes.formControlsAffiliations}  ${classes.divFamily}  ${classes.itemGridSpan2} `} variant="standard">
                <div classsName={`${classes.iconsCards} `}>
                    <Typography className={`${classes.txtLabelTramites} ${classes.txtEllipsis}`}>GRUPO FAMILIAR</Typography>

                    {data.dataAffiliates?.items?.map((item) => (
                        item.itemCodigo != data.dataAffiliates?.afiliados[0]?.numeroTitular &&
                        <Typography className={`${classes.txtSubtitleCalendar} ${classes.txtEllipsis} ${classes.left10}`}>{item.itemDescripcion}</Typography>
                    ))
                    }
                </div>
            </FormControl>

            <FormControl className={`${classes.formControlAddress} ${classes.itemGridSpan2}`}  >
                <CustomizedInputs
                    value={mail}
                    name='mail'
                    label='E-MAIL'
                    disabled={disabledEmail}
                    onChange={handleChangeMail(setMail, setMailError, setError, error, setShowPermanente, fullPersonalData?.fullData?.afiliado?.mails?.filter(z => z.mailTipoCodigo == 1)[0]?.mail)}
                    endAdornment={
                        <InputAdornment position="start">
                            <IconButton onClick={handleEditEmail} size='small'>
                                <EditIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {showPermanente ?
                    <FormControlLabel style={{ paddingLeft: '5%', fontSize: '11px' }} control={<Checkbox checked={emailChecked} onClick={handleCheck} color='primary' />} label="¿E-MAIL PERMANENTE?" /> : ""
                }
                <FormHelperText className={classes.errorText}>{error?.mail}</FormHelperText>
                <FormHelperText className={classes.errorText}>{mailError}</FormHelperText>
            </FormControl>
            <FormControl className={`${classes.formControlAddress} ${classes.itemGridSpan2}`}  >
                <CustomizedInputs
                    value={phone}
                    label='TELEFONO'
                    name='phone'
                    disabled={disabledPhone}
                    onChange={handleChangeText()}
                    endAdornment={
                        <InputAdornment position="start">
                            <IconButton onClick={handleEditPhone}>
                                <EditIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                    inputProps={{ maxLength: 20 }}
                />
                <FormHelperText className={classes.errorText}>{error?.phone}</FormHelperText>
            </FormControl>
            <FormControl className={classes.commentGrid} variant="standard">
                <CustomizedInputs
                    name="consulta"
                    label='OBSERVACIONES'
                    placeholder='Escribe tu texto aqui'
                    multiline
                    rows={5}
                    inputProps={{ maxLength: 2000 }}
                    onChange={(e) => { setQuery(e.target.value); }}
                    defaultValue={query}
                />
            </FormControl>

            <section className={classes.archivoAdjuntoDiv}>
                <div style={{ width: '250px' }}>
                    <Typography style={{ flex: '1 1 100%', justifyContent: 'center' }} className={`${classes.txtObservaciones} ${classes.txtSubtitle}`}>ADJUNTAR ARCHIVOS</Typography>
                    <span
                        style={{ flex: '1 1 100%', fontSize: '10px', display: 'flex' }}
                    >{`(TAMAÑO MAXIMO ${configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor}MB)`}
                    </span>
                    {listOfAttachmentButtons?.map((item, i) => (
                        <Fragment key={i}>
                            <CustomButton
                                icon={<AttachFileIcon />}
                                name={item.adjuntoDescripcion}
                                style={{ padding: '5px 15px 5px 5px' }}
                                title={`ADJUNTAR ${item.adjuntoDescripcion}`}
                                onClick={handleOpenModal(item.adjuntoDescripcion, item.adjuntoObligatorio)}
                                disabled={isDisabled(fileButtons, item.adjuntoDescripcion)}
                                customClass={`${classes.lightLinearGradientReverse} ${classes.marginLeft}`}
                            />
                            {item.adjuntoModelo != '' ?
                                <IconButton href={`${configurationReduxState?.filter(x => x?.archivoNombre === item?.adjuntoModelo)?.[0]?.valor}`} rel={"noopener noreferrer"} target={"_blank"}>
                                    <Typography className={`${classes.txtSubtitleBlue}`}>
                                        descargar Modelo
                                    </Typography></IconButton> : ""}
                            {item.adjuntoInstructivo != '' ?
                                <IconButton onClick={() => { dowloadFile(item.adjuntoInstructivo); }}>
                                    <Typography className={`${classes.txtSubtitleBlue}`}>
                                        descargar Instructivo
                                    </Typography></IconButton> : ""}
                            {item.adjuntoObligatorio === 'S' &&
                                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>*Archivo obligatorio</FormHelperText>
                            }
                        </Fragment>
                    ))}
                    {files?.map((item, i) => (
                        <p key={i} style={{ marginLeft: "5%" }} className={classes.txtSubtitleCalendar}>{item?.archivoNombre} <IconButton onClick={deleteFile(fileButtons, setFileButtons, setFiles, files, item?.btnNombre, item?.archivoNombre)}><HighlightOffIcon /></IconButton></p>
                    ))}
                </div>
            </section>

            <div className={`${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                {
                    <CustomButton
                        disabled={enableEnviarBtn(listOfAttachmentButtons, files, error, motiveSelected, selectedPlanDisp)}
                        customClass={classes.lightLinearGradient} style={{ padding: '5px 20px 5px 20px' }}
                        title='Enviar'
                        type='button'
                        onClick={handleSubmit} />
                }
            </div>
            <ConfirmModal isOpen={openSendSuccess} setOpen={setOpenSendSuccess} errorClass={errorClass}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalIcon}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalTitle}
                        </div>
                    </>
                }
                description={modalDescription} />
            <UploadModal
                title='Adjuntar archivo'
                description=''
                observation=''
                isRequired={itemRequired}
                open={openUploadModal}
                onClose={setOpenUploadModal}
                fileButtons={fileButtons}
                setModalTitle={setModalTitle}
                setOpenSendSuccess={setOpenSendSuccess}
                setModalDescription={setModalDescription}
                setModalIcon={setModalIcon}
                setErrorClass={setErrorClass}
                setFiles={setFiles}
                files={files}
                setFileButtons={setFileButtons}
                itemName={nameOfItem}
            />

        </>
    );
};

export default ChangePlanHeadline;

function validationSelect(param, dateLow, date) {
    let temp = {};
    temp.lowMotive = param.motivoCambioPlanCodigo != '' ? '' : '*Campo obligatorio';
    temp.subProcedure = param.cambioPlanCodigo != '' ? '' : '*Campo obligatorio';
    temp.plans = param.planCodigoNuevo != '' ? '' : '*Campo obligatorio';
    temp.date = validationDate(dateLow, date);
    temp.mail = param.mail != '' ? '' : '*Campo obligatorio';
    temp.phone = param.telefono != '' ? '' : '*Campo obligatorio';
    return temp;
}

function validationDate(dateLow, date) {
    let today = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    today.setHours(0, 0, 0, 0);
    dateLow.setHours(0, 0, 0, 0);
    if (dateLow.getTime() >= today.getTime()) {
        return '';
    } else {
        return "La fecha debe ser mayor a la fecha actual";
    }
}

function setAttachment(newFiles, setistOfAttachmentButtons, adj) {
    if (newFiles == undefined) {
        setistOfAttachmentButtons(adj);
    } else {
        setistOfAttachmentButtons(newFiles?.adjuntos);
    }
}

function setearMail(item, setMail) {
    if (item.mailTipoCodigo === 1)
        setMail(item.mail);
}

function setearMotivosPlan(data, motivosBaja, setLowMotives) {
    useSetter(data?.getMotivosPlan?.motivosCambioPlan, motivosBaja, setLowMotives, 'motivoCambioPlanCodigo', 'motivoCambioPlanDescripcion');
}

function setearPlanes(data, setListSubTramite, Tramites) {
    useSetter(data.getPlan?.tramites, Tramites, setListSubTramite, 'cambioPlanCodigo', 'cambioPlanDescripcion');
}


function useSetter(wsList, hardcodeList, setter, nameValue, nameLabel) {
    let auxReason = [];
    let auxList = [];
    if (wsList?.length === 0) {
        auxList = hardcodeList;
    } else {
        auxList = wsList;
    }
    auxList?.forEach(item => {
        let auxObj = {
            value: item[nameValue],
            label: item[nameLabel]
        };
        auxReason.push(auxObj);
    });
    setter(auxReason);
}