export function ValidateSteps(activeStep, modelAppointment, screen) {
    let returnValidate = false;
    let flow = validate(modelAppointment?.appointmentFlow);

    switch (activeStep) {
        case 0:
            let grupofamiliar = validate(modelAppointment?.afiliadoCodigo);
            let modalidad = validate(modelAppointment?.modalidadTurno);
            if (grupofamiliar && modalidad) {
                if(modelAppointment?.modalidadTurno === 'Turno presencial' && !flow) {
                    return returnValidate;
                }
                returnValidate = true;
            }
            return returnValidate;
        case 1:
            let medicalCenter = validate(modelAppointment?.centroMedico);
            let specialty = validate(modelAppointment?.especialidadDescripcion);
            if (medicalCenter) returnValidate = true;
            if (flow && specialty) returnValidate = true;
            if (modelAppointment?.appointmentFlow == 'especialidad' && modelAppointment?.especialidadCodigo == '') returnValidate = false;
            if (modelAppointment?.appointmentFlow == 'medico' && modelAppointment?.medicoCodigo == '') returnValidate = false;

            return returnValidate;
        case 2:
            let doctorCode = validate(modelAppointment?.medicoCodigo);
            let calendar = validate(modelAppointment?.hourSelected);
            if (screen.sWidth <= 768) {
                if (doctorCode) returnValidate = true;
            }
            else {
                if (doctorCode && calendar) returnValidate = true;
            }
            return returnValidate;
        case 3:
            let selectedAppointment = validate(modelAppointment?.selectedAppointment);
            let hourSelected = validate(modelAppointment?.hourSelected);
            if (hourSelected && selectedAppointment) returnValidate = true;
            return returnValidate;
        case 4:
            let date = validate(modelAppointment?.selectedAppointment)
            let hour = validate(modelAppointment?.hourSelected)
            if (date && hour) returnValidate = true;
            return returnValidate;
        case 5:
            return true;
        case 6:
            return true;
        default:
            break;
    }
}

function validate(values) {
    let temp = values ? "" : "*Campo obligatorio";
    return Object.values(temp).every((value) => value == "");
}