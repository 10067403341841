import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetCampaign() {
    try {
        var response = yield call(API.getCampaign);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_CAMPAIGN, response, message: '', });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_GET_CAMPAIGN,
            message: error.message,
        });
    }
}


// Watchers
export default function* campaignSaga() {
    yield takeLatest(actionTypes.GET_CAMPAIGN, asyncGetCampaign);
}
