import React from "react";
import FormHeader from "../FormHeader";
import FormAffiliations from "./FormAffiliations";
import { useSelector } from "react-redux";
import { handleGoToPersonalData } from "../../../utils/utilsFunctions";
import { Divider } from "@material-ui/core";
import useStyles from "../../../assets/styles/styles";
import { useResizeScreen } from "../../../hooks/useResizeScreen";

const Affiliations = ({ handleBack }) => {
    const fullPersonalData = useSelector(state => state.personalDataReducer);
    const classes = useStyles();
    const { screen } = useResizeScreen();

    return <>
        <FormHeader title='Tramites de afiliaciones' handleGoTo={handleGoToPersonalData}/>
        {
            screen.sWidth > 768 &&
            <Divider className={classes.formDivider} />
        }
        <FormAffiliations fullPersonalData={fullPersonalData} handleBack={handleBack} />
    </>;
};

export default Affiliations;