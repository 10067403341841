import { Card as MuiCard, CardContent, } from "@material-ui/core";
import { Fragment } from "react";
import useStyles from '../../../assets/styles/styles';
import BadgeNotification from "../../Notification/BadgeNotification";

const AppointmentsCard = ({
    headerIcon,
    description,
    buttonZone,
    index,
    count,
    customHeaderColor,
    buttonsInHeader,
    headerButtons,
    blueBox = true
}) => {
    const classes = useStyles();
    return <Fragment>
        <MuiCard
            key={index}
            className={`${classes.card} ${classes.appointmentCards}`}
            style={{ borderRadius: '10px', marginTop: '40px' }}>
            <CardContent
                style={{ padding: '8px' }}
                className={classes.cardContentMuiCard}>
                <div className={classes.divCardContent}>
                    {count !== undefined && count > 0 ?
                        <BadgeNotification
                            customWidth='100%'
                            customClass={classes.authorizationNotifications}
                            childrenItem={
                                <section
                                    className={`${classes.lightLinearGradientReverse}
                                                ${classes.headerIconCard}
                                                ${classes.sectionHeaderCard}
                                                ${customHeaderColor}`}>
                                    <div style={{ width: "100%" }}>{headerIcon}</div>
                                </section>
                            }
                            countNotification={count}
                        />
                        :
                        <>
                            {blueBox &&
                                <section className={`${classes.lightLinearGradientReverse} ${classes.headerIconCard} ${classes.sectionHeaderCard} ${customHeaderColor}`}>
                                    <div style={{ width: "100%" }}>{headerIcon}</div>
                                    {buttonsInHeader && headerButtons}
                                </section>
                            }
                        </>
                    }
                    <section
                        className={`${classes.descriptionCard}`}
                        style={{ flex: !blueBox && '0 0 100%' }}>
                        <div>
                            {description}
                        </div>
                        {(buttonsInHeader && !blueBox) && headerButtons}
                    </section>
                </div>
                {buttonZone}
            </CardContent>
        </MuiCard>
    </Fragment>;
};

export default AppointmentsCard;
