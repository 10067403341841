import {
  FormHelperText,
  TextField,
  Autocomplete as MuiAutocomplete,
} from "@material-ui/core";
import useStyles from "../../assets/styles/styles";
import "./autocomplete.css";

const Autocomplete = (props) => {
  const {
    label,
    name,
    value,
    option,
    customClass,
    onChange,
    error,
    selected,
    loading,
    autocompleteProp,
    ...other
  } = props;
  const classes = useStyles();

  const fun = (params) => {
    params.inputProps.autoComplete = autocompleteProp;
    return (
      <TextField
        name={name}
        label={label}
        variant="outlined"
        autoComplete='nope'
        className={`${classes.buttonsHeadersFilters} ${classes.textBtnFilter} ${customClass}`}
        id="combo"
        {...params}
      />
    );
  };
  return (
    <>
      <MuiAutocomplete
        clearOnEscape
        options={option}
        getOptionLabel={(optionVal) => optionVal?.itemDescripcion}
        autoComplete
        value={selected ? selected : option?.itemCodigo}
        onChange={onChange}
        className={`${classes.buttonsHeadersFilters}`}
        name={name}
        loading={loading} /* Verificar, si viene vacio deja de cargar */
        {...other}
        renderInput={(params) => fun(params)}
        id="combo-box-filters"
      />
      {error !== "" ? (
        <FormHelperText className={classes.errorText}>{error}</FormHelperText>
      ) : (
        ""
      )}
    </>
  );
};

export default Autocomplete;
