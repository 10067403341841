import { Box, Typography } from "@material-ui/core";
import useStyles from "../../assets/styles/styles";
import { useResizeScreen } from "../../hooks/useResizeScreen";

const Header = ({ title, subtitle, textError, colorTitle, heightTitle, zIndexTitle, positionTitle }) => {
  const classes = useStyles();
  const resizeScreen = useResizeScreen();

  const Responsive = (type) => {
    let width = resizeScreen.screen.sWidth;
    switch (type) {
      case "firstBoxHeader":
        if (width <= 1000)
          return `${classes.noneBoxShadow} ${classes.mainTitle}`;
        break;
      case "header":
        if (width <= 1000)
          return `${classes.boxHeaderMobile} ${classes.mainTitle}`;
        break;
      case "filters":
        if (width <= 1000) return `${classes.boxHeaderFiltersMobile}`;
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Box
        paddingTop="40px"
        justifyContent="center"
        style={{ height: heightTitle, zIndex: zIndexTitle, position: positionTitle, backgroundColor: 'white' }}
        className={`${classes.bottomBoxShadow} ${Responsive("firstBoxHeader")}`}
      >
        <Box
          justifyContent="center"
          display="flex"
          className={`${classes.bottomBoxShadow} ${Responsive("header")}`}
        >
          <Typography
            variant="h5"
            style={{
              paddingBottom: "23px",
              position: "absolute",
              color: colorTitle,
            }}
            className={classes.mainTitle}
          >
            {title}
          </Typography>
        </Box>
        <Box
          display="flex"
          paddingTop="50px"
          justifyContent="center"
          flex="0 0 85%"
          className={`${Responsive("filters")}`}
        >
          {subtitle !== null ? subtitle : ""}
        </Box>
      </Box>
    </>
  );
};

export default Header;
