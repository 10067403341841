
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ScienceIcon from '@material-ui/icons/Science';
import CleanHandsOutlinedIcon from '@material-ui/icons/CleanHandsOutlined';

import DifferenceIcon from '@mui/icons-material/Difference';

import { useDispatch, useSelector } from "react-redux";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import Menu from "../../components/Menu/Menu";
import useStyles from "../../assets/styles/styles";
import { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import QuickAccessBox from '../../components/HomeLogged/QuickAccessBox';
import Header from '../../components/GenericHeader/Header';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SelectTypeOfComponent from '../../components/PersonalData/SelectTypeOfComponent';
import { getLimitPlan } from "../../redux/actions/limitPlansAction";
import { notAccessToThisModule, verifyPermission } from "../../utils/utilsFunctions";
import useTrackPageView from "../../utils/useTrackPageView";
import Loading from '../../components/LoadingPage/Loading';

const MedicalData = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const affiliatePlan = useSelector((state) => state.personalDataReducer?.fullData?.afiliado?.planDescripcion);
    const limitPlanReducer = useSelector((state) => state.limitPlanReducer.allLimitPlanData);
    const { screen } = useResizeScreen();
    const [type, setActiveType] = useState(-1);
    const [mobile, setMobile] = useState(false);
    const history = useHistory();
    const { search } = useLocation();
    const trackPageView = useTrackPageView("Datos Médicos");
    const match = search.match(/type=(.*)/);
    const typeURL = match?.[1];
    const loadingPermission = useSelector((state) => state.limitPlanReducer.requestInProgress);
    const [ hasPermission, setHasPermission ] = useState(false);

    const listIconAccess = [
        { title: 'Mis datos médicos', icon: <PermContactCalendarOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 3, path: '/PersonalMedicalData' },
        { title: 'Programas', icon: <MenuBookIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 0, path: '/ProgramsHome' },
        { title: 'Vacunas', icon: <EventAvailableOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 1, path: '/MedicalData?type=Vaccine' },
        { title: 'Resultados de laboratorio', icon: <ScienceIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 2, path: '/MedicalData?type=Lab' },
        { title: 'Prevención', icon: <CleanHandsOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 3, path: '/Prevention' }
    ];

    useLayoutEffect(() => {
        verifyPermission(limitPlanReducer, affiliatePlan, "Datos Médicos", setHasPermission);
    }, [limitPlanReducer, affiliatePlan, hasPermission]);

    const handleClick = (item) => (e) => {
        setActiveType(item.type);
    };

    useEffect(() => {
        if (screen.sWidth <= 768)
            setMobile(true);
        else
            setMobile(false);
    }, [screen.sWidth, type]);


    useEffect(() => {
        /* Realiza el dispatch para obtener los datos */
    }, []);

    useEffect(() => {
        dispatch(getLimitPlan());
        trackPageView();
    }, []);

    useEffect(() => {
        window.onpopstate = (e) => {
            if (screen.sWidth <= 768 && type !== -1)
                window.location.href = '/MedicalData';
            else
                history.push('/');
        };
    });

    function isEnabled(condition) {
        var r;
        if(condition) {
            r = (
                <div className={`${classes.divQuickAccess} ${classes.customScroll}`}>
                    {listIconAccess.map((item, i) => (
                        <Fragment key={i}>
                            <QuickAccessBox component={Link} to={item.path} handleClick={handleClick(item)} i={i} item={item} classes={classes} customClass={classes.personalDataItem} iconClass={classes.personalDataDivIcons} txtIcon={classes.personalDataTxtIcon} />
                        </Fragment>

                    ))}
                </div>
            );
        } else {
            r = (notAccessToThisModule());
        }
        return r;
    }

    useEffect(() => {
        if (typeURL !== undefined && (typeURL === 'Vaccine' || typeURL === 'Lab')) {
            let auxURL = `/MedicalData?type=${typeURL}`;
            let aux = listIconAccess.filter(x => x.path === auxURL);
            setActiveType(aux[0].type);
        }
    }, [typeURL]);

    return (
        <>
            <Menu />
            {(!mobile || type === -1)  &&
                <>
                    <Header title='Datos Médicos' />
                    <Loading
                        isLoading={loadingPermission}
                        children={isEnabled(hasPermission)}
                    />
                </>
            }
            <SelectTypeOfComponent />
        </>
    );
};

export default MedicalData;