import React, { useState, Fragment, useEffect } from 'react';
import { Button, Divider, FormControl, Typography, FormHelperText, Box, Grid } from "@material-ui/core";
import useStyles from '../../../assets/styles/styles';
import CustomizedInputs from "../../../controls/InputCustom/CustomizedInputs";
import GMapsAutocomplete from "../../../controls/GMapsAutocomplete/GMapsAutocomplete";
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import CustomButton from '../../../controls/Button/CustomButton';
import SaveModal from '../SaveModal';
import ConfirmModal from '../ConfirmModal';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { abmFullData } from '../../../redux/actions/personalDataActions';
import CancelIcon from '@material-ui/icons/Cancel';

const Form = ({ affiliateAddress, setAffiliateAddress, typeOfAddress, disabled, editMode, setEditMode, handleCancel }) => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const errorMessage = useSelector(state => state.personalDataReducer?.msgError);

    const [modalTitle, setModalTitle] = useState('Enviado correctamente');
    const [modalDescription, setModalDescription] = useState("Los cambios han sido realizados, una vez validados por nuestro sector correspondiente observarás los cambios en el sistema.");
    const [modalIcon, setModalIcon] = useState(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
    const [errorClass, setErrorClass] = useState('');

    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const dispatch = useDispatch();
    const [fullAddressDisabled, setFullAddressDisabled] = useState({
        'Particular': true,
        'Laboral': true,
        'Facturacion': true
    });
    const [error, setError] = useState({
        calleDescripcion: '',
        calleNumero: '',
        provinciaDescripcion: '',
        localidadDescripcion: '',
        codigoPostal: '',
        barrioDescripcion: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate(affiliateAddress?.domicilios, setError)) {
            let aux = affiliateAddress;
            aux.documentos = [];
            aux.telefonos = [];
            aux.mails = [];
            aux.deportes = [];
            aux.hobbies = [];
            let domicilios = aux?.domicilios;
            domicilios.forEach((item, i) => {
                delete item.domicilioTipoDescripcion;
                delete item.provinciaCodigo;
                delete item.localidadCodigo;
                delete item.barrioCodigo;
                item.codigoPostal = item.calleCodigo;
                delete item.calleCodigo;
            });
            delete aux.cuentasReintegro;
            aux.domicilios = domicilios;
            let modelToSend = {
                informacion: aux,
                numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
            };
            dispatch(abmFullData(modelToSend));
            setOpen(prevState => !prevState);
            setOpenConfirm(prevState => !prevState);
            setTimeout(() => {
                window.location.reload();
            }, 5000);
        }
    };

    const handleChangeInput = (position) => (e) => {
        const { name, value } = e.target;
        let domicilios = affiliateAddress?.domicilios.map((item, i) => {
            if (position == i) {
                return { ...item, [name]: value };
            } else {
                return item;
            }
        });
        setAffiliateAddress({ ...affiliateAddress, domicilios });
        validate(affiliateAddress?.domicilios, setError);
    };

    const handleAddAddress = (codeType, description) => (e) => {
        e.preventDefault();
        let auxAddress = affiliateAddress?.domicilios ?? [];
        let addressToAdd = [{
            domicilioTipoCodigo: codeType,
            domicilioTipoDescripcion: description,
            calleDescripcion: '',
            calleNumero: '',
            provinciaDescripcion: "",
            localidadDescripcion: "",
            codigoPostal: '',
            barrioDescripcion: "",
            calleDepartamento: '',
            callePiso: '',
            domicilioModo: 'A'
        }];
        let domicilios = [...auxAddress, ...addressToAdd];
        setAffiliateAddress({ ...affiliateAddress, domicilios });
        setFullAddressDisabled(prevState => ({ ...prevState, [description]: !prevState[description] }));
    };

    const handleEditFullAddress = (type) => (e) => {
        e.preventDefault();
        setFullAddressDisabled(prevState => ({ ...prevState, [type]: !prevState[type] }));
        setEditMode(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };

    const handleClickModal = () => {
        if (validate(affiliateAddress?.domicilios, setError))
            setOpen(prevState => !prevState);
    };

    useEffect(() => {
        setMessageModal(setModalTitle, setModalDescription, setModalIcon, setErrorClass, classes, errorMessage);
    }, [errorMessage]);

    function setMessageModal(setterModalTitle, setterModalDescription, setterModalIcon, setterErrorClass, cssClasses, errorMessageRedux) {
        if (errorMessageRedux !== '') {
            setterModalTitle('Ocurrió un error');
            setterModalDescription('Ocurrió un error al enviar los datos');
            setterModalIcon(<CancelIcon className={`${cssClasses.quickAccessIcon} ${cssClasses.personalDataIcon}`} />);
            setterErrorClass(cssClasses.backgroundError);
        } else {
            setterModalTitle('Enviado correctamente');
            setterModalDescription("Los cambios han sido realizados, una vez validados por nuestro sector correspondiente observarás los cambios en el sistema.");
            setterModalIcon(<CheckCircleOutlineIcon className={`${cssClasses.quickAccessIcon} ${cssClasses.personalDataIcon}`} />);
            setterErrorClass('');
        }
    }

    return <>
        <form onSubmit={handleSubmit}>
            {affiliateAddress?.domicilios?.map((item, i) => (
                <Fragment key={i}>
                    <Box style={{ margin: '35px' }}>
                        <Grid container spacing={2} columns={12}>
                            {editMode[item.domicilioTipoDescripcion?.trimEnd()] ?
                                <>
                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <FormControl className={`${classes.formControlAddress} ${classes.width100}`} variant="standard">
                                            <CustomizedInputs name='calleDescripcion' label='DIRECCIÓN' value={item?.calleDescripcion} onChange={handleChangeInput(i)} />
                                            <FormHelperText className={classes.errorText}>{error[i]?.calleDescripcion}</FormHelperText>
                                            <Typography
                                                component={Button}
                                                onClick={handleEditFullAddress(item?.domicilioTipoDescripcion?.trimEnd())}
                                                disabled={false}
                                                style={{ justifyContent: 'flex-start' }}
                                                className={`${classes.titleTurn} ${classes.titleBox} ${classes.subtitleAddressForm}`}>
                                                Buscar con Google Maps
                                            </Typography>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <FormControl className={`${classes.formControlAddress} ${classes.width100}`} variant="standard">
                                            <CustomizedInputs name='calleNumero' value={item?.calleNumero} label='ALTURA' onChange={handleChangeInput(i)} />
                                            <FormHelperText className={classes.errorText}>{error[i]?.calleNumero}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </>
                                :
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <FormControl className={`${classes.formControlAddress} ${classes.width100}`} variant="standard">
                                        <GMapsAutocomplete
                                            handlePlaceSelected={(selected) => handlePlaceSelected(selected, item?.domicilioTipoCodigo, item?.domicilioTipoDescripcion?.trimEnd(), affiliateAddress, setAffiliateAddress, setFullAddressDisabled, setEditMode)}
                                            handleChange={handleChangeInput(i)}
                                            value={`${item?.calleDescripcion} ${item?.calleNumero}`}
                                            placeholder={`${item?.calleDescripcion} ${item?.calleNumero}`}
                                            disabled={disabled}
                                            label='DIRECCION'
                                            require={true}
                                        />
                                        <Typography
                                            component={Button}
                                            onClick={handleEditFullAddress(item?.domicilioTipoDescripcion?.trimEnd())}
                                            disabled={disabled ? true : false}
                                            style={{ justifyContent: 'flex-start' }}
                                            className={`${classes.titleTurn} ${classes.titleBox} ${classes.subtitleAddressForm}`}
                                        >Modificar direccion
                                        </Typography>
                                    </FormControl>
                                </Grid>
                            }
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <FormControl className={`${classes.formControlAddress} ${classes.width100}`} variant="standard">
                                    <CustomizedInputs
                                        name='provinciaDescripcion'
                                        value={item?.provinciaDescripcion}
                                        onChange={handleChangeInput(i)}
                                        disabled={fullAddressDisabled[item?.domicilioTipoDescripcion?.trimEnd()]}
                                        label='PROVINCIA' />
                                    <FormHelperText className={classes.errorText}>{error[i]?.provinciaDescripcion}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <FormControl className={`${classes.formControlAddress} ${classes.width100}`} variant="standard">
                                    <CustomizedInputs
                                        id='localidadDescripcion'
                                        name='localidadDescripcion'
                                        value={item?.localidadDescripcion}
                                        onChange={handleChangeInput(i)}
                                        disabled={fullAddressDisabled[item?.domicilioTipoDescripcion?.trimEnd()]}
                                        label='LOCALIDAD' />
                                    <FormHelperText className={classes.errorText}>{error[i]?.localidadDescripcion}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <FormControl className={`${classes.formControlAddress} ${classes.width100}`} variant="standard">
                                    <CustomizedInputs
                                        id=''
                                        name='codigoPostal'
                                        value={item?.codigoPostal}
                                        onChange={handleChangeInput(i)}
                                        disabled={fullAddressDisabled[item?.domicilioTipoDescripcion?.trimEnd()]}
                                        label='CODIGO POSTAL' />
                                    <FormHelperText className={classes.errorText}>{error[i]?.codigoPostal}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <FormControl className={`${classes.formControlAddress} ${classes.width100}`} variant="standard">
                                    <CustomizedInputs
                                        id=''
                                        name='barrioDescripcion'
                                        value={item?.barrioDescripcion}
                                        onChange={handleChangeInput(i)}
                                        disabled={fullAddressDisabled[item?.domicilioTipoDescripcion?.trimEnd()]}
                                        label='BARRIO' />
                                    <FormHelperText className={classes.errorText}>{error[i]?.barrioDescripcion}</FormHelperText>
                                </FormControl>
                            </Grid>
                            {editMode[item?.domicilioTipoDescripcion?.trimEnd()] ?
                                <>
                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <FormControl className={`${classes.formControlAddress} ${classes.width100}`} variant="standard">
                                            <CustomizedInputs
                                                id=''
                                                name='callePiso'
                                                value={item?.callePiso}
                                                label='PISO'
                                                onChange={handleChangeInput(i)}
                                                disabled={fullAddressDisabled[item?.domicilioTipoDescripcion?.trimEnd()]} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <FormControl className={`${classes.formControlAddress} ${classes.width100}`} variant="standard">
                                            <CustomizedInputs
                                                id=''
                                                name='calleDepartamento'
                                                value={item?.calleDepartamento}
                                                onChange={handleChangeInput(i)}
                                                disabled={fullAddressDisabled[item?.domicilioTipoDescripcion?.trimEnd()]}
                                                label='DEPARTAMENTO' />
                                        </FormControl>
                                    </Grid>
                                </>
                                :
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <FormControl className={`${classes.formControlAddress} ${classes.width100}`} variant="standard">
                                        <CustomizedInputs
                                            id=''
                                            value={`${item?.callePiso?.trimEnd()}-${item?.calleDepartamento?.trimEnd()}`}
                                            disabled={fullAddressDisabled[item?.domicilioTipoDescripcion?.trimEnd()]}
                                            label='PISO | DEPARTAMENTO' />
                                    </FormControl>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Fragment>
            ))}
            {!disabled &&
                typeOfAddress?.map((item, i) => (
                    <Fragment key={i}>
                        {!affiliateAddress?.domicilios?.some(x => x.domicilioTipoCodigo === item?.tipoContactoCodigo) &&
                            (
                                <div style={{ justifyContent: 'flex-start', width: '100%', margin: screen.sWidth < 768 ? '5px' : '35px', marginLeft: screen.sWidth < 768 ? '9px' : '30px' }}>
                                    <Button onClick={handleAddAddress(item?.tipoContactoCodigo, item?.tipoContactoDescripcion)}>
                                        Agregar domicilio {item?.tipoContactoDescripcion} <ControlPointIcon className={classes.ShowMoreArrowIcon} />
                                    </Button>
                                    <Divider className={classes.formDivider} style={{ margin: screen.sWidth < 768 ? '15px' : 'auto' }} />
                                </div>
                            )
                        }
                    </Fragment>
                ))
            }
            <div className={`${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`} style={{ margin: "35px" }}>
                {screen.sWidth <= 768 && !disabled && (
                    <div style={{ marginBottom: "18px" }} >
                        <CustomButton customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`} title='Cancelar' type='button' onClick={handleCancel} />
                    </div>
                )}
                {!disabled && (
                    <div >
                        <CustomButton style={{ marginBottom: "20px" }} customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient}`} title='Guardar' onClick={handleClickModal} />
                    </div>
                )}
            </div>

            <SaveModal isOpen={open} setOpen={setOpen} type='submit' onClick={handleSubmit}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            Confirmar envío de datos
                        </div>
                    </>
                }
                descriptionModal="Si modificaste tus datos personales en breve recibirás la confirmación del cambio, luego de validarse la misma por parte del sector de Afiliaciones."
            />
        </form>
        <ConfirmModal isOpen={openConfirm} setOpen={setOpenConfirm} errorClass={errorClass}
            titleModal={
                <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {modalIcon}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {modalTitle}
                    </div>
                </>
            }
            description={modalDescription} />
    </>;
};

export default Form;


function validate(address, setError) {
    let temp = address;
    let aux = [];
    let validation = [];
    Object.values(temp).forEach((item, i) => {
        let addressObj = {};
        addressObj.calleDescripcion = item.calleDescripcion ? '' : '*Campo obligatorio';
        addressObj.calleNumero = item.calleNumero ? '' : '*Campo obligatorio';
        addressObj.provinciaDescripcion = item.provinciaDescripcion ? '' : '*Campo obligatorio';
        addressObj.localidadDescripcion = item.localidadDescripcion ? '' : '*Campo obligatorio';
        addressObj.codigoPostal = item.codigoPostal ? '' : '*Campo obligatorio';
        addressObj.barrioDescripcion = item.barrioDescripcion ? '' : '*Campo obligatorio';
        aux.push(addressObj);
        if (Object.values(aux[i]).every((x) => x === "")) {
            validation.push(true);
        } else {
            validation.push(false);
        }
    });
    setError(aux);
    return Object.values(validation).every((valid) => valid === true);
}


function handlePlaceSelected(selected, type, description, affiliateAddress, setAffiliateAddress, setFullAddressDisabled, setEditMode) {
    let data = { street_number: '' };
    selected?.address_components?.map(item => {
        data = { ...data, [item?.types[0]]: item?.long_name };
    });
    let auxLat = selected?.geometry?.location?.lat();
    let auxLng = selected?.geometry?.location?.lng();
    let auxAddress = affiliateAddress?.domicilios?.filter(x => x.domicilioTipoCodigo === type);
    let addressWithOutType = affiliateAddress?.domicilios?.filter(x => x.domicilioTipoCodigo !== type);
    auxAddress[0].calleDescripcion = data?.route ?? '';
    auxAddress[0].calleNumero = data.street_number ?? '';
    auxAddress[0].provinciaDescripcion = data.administrative_area_level_1 ?? '';
    auxAddress[0].localidadDescripcion = data.administrative_area_level_2 ?? '';
    auxAddress[0].barrioDescripcion = data.locality ?? '';
    auxAddress[0].codigoPostal = data.postal_code ?? '';
    auxAddress[0].linkGeoreferencia = `https://www.google.com/maps/dir/${auxLat},${auxLng}`;
    let domicilios = [...auxAddress, ...addressWithOutType];
    domicilios = domicilios.sort((f, b) => { return f.domicilioTipoCodigo - b.domicilioTipoCodigo; });
    setAffiliateAddress({ ...affiliateAddress, domicilios });
    if (!Object.values(auxAddress[0]).every((x) => x === "")) {
        setFullAddressDisabled(prevState => ({ ...prevState, [description]: !prevState[description] }));
        setEditMode(prevState => ({ ...prevState, [description]: !prevState[description] }));
    }
}
