import CustomButton from "../../../controls/Button/CustomButton";
import useStyles from '../../../assets/styles/styles';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import { Accordion, AccordionSummary, AccordionDetails, FormControl, Typography, IconButton, FormHelperText } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import CustomizedInputs from "../../../controls/InputCustom/CustomizedInputs";
import { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import BadgeNotification from "../../Notification/BadgeNotification";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CustomSelect from "../../../controls/Select/CustomSelect";
import UploadModal from "../../../controls/UploadModal/UploadModal";
import ConfirmModal from "../../PersonalData/ConfirmModal";
import { deleteFile, validate } from "../../../utils/utilsFunctions";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import { useDispatch, useSelector } from "react-redux";
import { getAttached, getComments, getTypeAttached, postAttached, pushComments, getReadComments, downloadAttachments } from "../../../redux/actions/authorizationsActions";
import ModalActivities from "../../DialogActivities/ModalActivities";
import CancelIcon from '@material-ui/icons/Cancel';
import NotFound from "../../HomeLogged/NotFound";
import FrequentQuestionsButton from "../FrequentQuestionsButtons";
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import FileDownloadIcon from '@material-ui/icons/FileDownload';
import { CircularProgress } from "@mui/material";
import LineBreakInput from "../../../controls/InputCustom/LineBreakInput";

const Comments = ({ item }) => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const [openTxtComment, setOpenTxtComment] = useState(false);
    const [txtComment, setTxtComment] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [files, setFiles] = useState([]);
    const [fileButtons, setFileButtons] = useState([]);
    const [modalTitle, setModalTitle] = useState('');
    const [modalDescription, setModalDescription] = useState("");
    const [modalIcon, setModalIcon] = useState('');
    const [errorClass, setErrorClass] = useState('');
    const [error, setError] = useState([]);
    const [openSendSuccess, setOpenSendSuccess] = useState(false);
    const inputFile = useRef();
    const dispatch = useDispatch();
    const attachmentsRedux = useSelector(state => state.authorizationsReducer);
    const autorizationState = useSelector(
        state => state.authorizationsReducer?.authorizationDetail?.autorizacionDetalle[0]?.autorizacionEstadoDescripcion
    );
    const [listTypeAttached, setListTypeAttached] = useState([{ value: 0, label: '' }]);
    const [typeAttachedSelected, setTypeAttachedSelected] = useState(0);
    const [txtObservation, setTxtObservation] = useState('');
    const [listAttached, setListAttached] = useState([]);
    const [hide, setHide] = useState(false);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [openModalError, setOpenModalError] = useState(false);
    const [msgErrorAttachments, setMsgErrorAttachments] = useState('');
    const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);
    const [thisFileExists, setThisFileExists] = useState(false);
    const [closeComments, setCloseComments] = useState(false);
    const [closeAttached, setCloseAttached] = useState(false);
    const downloadAuthorizationsAttachments = useSelector(state => state.authorizationsReducer.downloadAttachments);
    const loadingAuthorizationDownload = useSelector((state) => state.authorizationsReducer.loadingDownloadAttachments);
    const [openLoading, setOpenLoading] = useState(false);
    const [initLoad, setInitLoad] = useState(true);
    const [openDownload, setOpenDownload] = useState(false);
    const [attachedToDownload, setAttachedToDownload] = useState({});
    const [cancelledDownload, setCancelledDownload] = useState(false);
    const [closedAuthorization, setClosedAuthorization] = useState(false);
    const CLOSED_STATES = ["FINALIZADO", "CERRADO", "ANULADA"];
    const [counterLength, setCounterLength] = useState(0);
    const [counterObservationLength, setCounterObservationLength] = useState(0);


    const obj = {
        numeroPersona: Number.parseInt(item?.authorizationDetails?.afiliado),
        gestionCodigo: Number.parseInt(item?.authorizationDetails?.autorizacionGestionCodigo),
        autorizacionNumero: Number.parseInt(item?.authorizationDetails?.autorizacionNumero),
        delegacionCodigo: Number.parseInt(item?.authorizationDetails?.autorizacionDelegacionCodigo)
    };

    const handleChangeText = (e) => {
        const { name, value } = e.target;
        if (name === 'observacion') {
            setTxtObservation(value);
            setFiles([{ ...files[0], archivoObservacion: value }]);
        } else {
            setTxtComment(value);
        }
        if (value.length <= 1000) {
            setCounterLength(value.length);
        }
    };

    const handleChangeObservations= (e) => {
        const { name, value } = e.target;
        if (name === 'observacion') {
            setTxtObservation(value);
            setFiles([{ ...files[0], archivoObservacion: value }]);
        } else {
            setTxtComment(value);
        }
            if (value.length <= 200) {
                setCounterObservationLength(value.length);
            }
    }

    const handleOpenTextBox = (e) => {
        e.preventDefault();

        if (!CLOSED_STATES?.includes(autorizationState?.toUpperCase()?.trim())) {
            setOpenTxtComment(true);
        }
        setCloseComments(false);
        setCloseAttached(false);
        setHide(false);
    };

    const handlePushComment = (e) => {
        e.preventDefault();
        let comentarios = {
            numeroPersona: parseInt(item?.authorizationDetails?.afiliado),
            gestionCodigo: parseInt(item?.authorizationDetails?.autorizacionGestionCodigo),
            autorizacionNumero: parseInt(item?.authorizationDetails?.autorizacionNumero),
            delegacionCodigo: parseInt(item?.authorizationDetails?.autorizacionDelegacionCodigo),
            autorizacionComentario: JSON.stringify({
                autorizacionComentarioObservacion: txtComment,
                autorizacionComentarioPerspectiva: 'A',
                autorizacionComentarioPublicar: 'S',
            })
        };
        if (!CLOSED_STATES?.includes(item?.authorizationDetails?.autorizacionEstadoDescripcion?.toUpperCase()?.trim())) {
            dispatch(pushComments(comentarios));
        } else {
            setClosedAuthorization(true);
        }
        setTxtComment('');
        setOpenTxtComment(prevState => !prevState);
    };

    useEffect(() => {
        getNewComment();
    }, [openTxtComment]);

    const handleChangeSelect = () => e => {
        const { name, value } = e.target;
        setTypeAttachedSelected(value);
        setFiles([{ ...files[0], archivoClasificacion: value }]);
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    const handleAddAttachment = () => {
        if (!CLOSED_STATES?.includes(autorizationState?.toUpperCase()?.trim())) {
            if (screen.sWidth > 768) {
                inputFile?.current?.click();
            }
            else {
                setOpenModal(prevState => !prevState);
            }
        }
        setCloseComments(false);
        setCloseAttached(false);
        setOpenTxtComment(false);
    };

    const handleCloseTxtComment = () => {
        setOpenTxtComment(prevState => !prevState);
    };

    const handleUpload = (e) => {
        let alertGreatSize = "";
        var filesUpload = e.target.files;
        for (let file of filesUpload) {
            let size = (file.size / 1024);
            if (size <
                parseInt(configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor) * 1000) {
                let adj = {
                    archivoNombre: file.name.substring(0, file.name.lastIndexOf(".")),
                    archivoExtension: file.name.substring(file.name.lastIndexOf(".") + 1),
                    archivoNombreCompleto: file.name,
                    archivoTamanio: parseInt(size),//KB
                    archivo: null,//new Blob([file],{type: file.type}),
                    archivoClasificacion: typeAttachedSelected,
                    archivoObservacion: txtComment,
                };

                let thisFileExists = listAttached?.filter(x => x?.nombre?.trim() == adj?.archivoNombre?.trim());
                if (thisFileExists?.length > 0) {
                    setThisFileExists(true);
                } else {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        adj.archivo = reader.result?.split(',').pop();
                        setFiles([adj]);
                        setHide(true);
                    };
                    reader.readAsDataURL(file);
                }

            } else {
                alertGreatSize = alertGreatSize + " " + file.name;
            }
        }
        if (alertGreatSize != "") {
            setModalTitle("Atención");
            setModalDescription(`Los archivos: ${alertGreatSize} superan el tamaño máximo.
                                Asegurese de que los archivos no superen los ${configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor}MB`);
            setModalIcon(
                <CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
            );
            setErrorClass(classes.backgroundError);
            setOpenSendSuccess(prevState => !prevState);
        }
        e.target.value = null;
    };

    const handleOpenModalConfirm = () => {
        if (validateFiles(files) && validateSelect(typeAttachedSelected)) {
            setOpenModalConfirm(true);
        }
    };

    const handleSubmit = () => {

        //If the user doesn't select any option, then archivoClasificacion be will 0 and an error will be generated.
        //If onChange event doesn't run, this line will set default/first choice of the object selection.
        if(files[0].archivoClasificacion === 0){
            files[0].archivoClasificacion = typeAttachedSelected;
        }

        let model = {
            numeroPersona: item?.authorizationDetails?.afiliado,
            gestionCodigo: item?.authorizationDetails?.autorizacionGestionCodigo,
            autorizacionNumero: item?.authorizationDetails?.autorizacionNumero,
            delegacionCodigo: item?.authorizationDetails?.autorizacionDelegacionCodigo,
            perspectiva: 'A', //afiliado
            adjuntos: JSON.stringify(files)
        };
        if (!CLOSED_STATES?.includes(item?.authorizationDetails?.autorizacionEstadoDescripcion?.toUpperCase()?.trim())) {
            dispatch(postAttached(model));
        } else {
            setClosedAuthorization(true);
        }
        cleanStates();
    };

    function cleanStates() {
        setHide(false);
        setOpenModalConfirm(false);
    }

    function validateSelect(select) {
        let aux = [];
        let validation = [];
        let Obj = {};
        if (select === 0) {
            Obj.selectAttached = '*Seleccione otro campo';
        }
        else {
            Obj.selectAttached = select.toString() ? '' : '*Campo obligatorio';
        }
        aux = Obj;
        if (Object.values(aux).every((x) => x === "")) {
            validation.push(true);
        } else {
            validation.push(false);
        }
        setError(aux);
        return Object.values(validation).every((valid) => valid === true);
    }

    function getNewComment() {
        dispatch(getComments(obj));
    }

    useEffect(() => {
        dispatch(getTypeAttached("AUTORIZACIONES"));
        if (item?.authorizationDetail?.autorizacionDetalle !== undefined) {
            dispatch(getAttached(obj));
        }
    }, []);

    useEffect(() => {
        let list = attachmentsRedux.typeAttached?.tipoAdjunto?.map((type) => {
            return { value: parseInt(type?.tipoAdjuntosCodigo), label: type?.tipoAdjuntosDescripcion };
        });
        setListTypeAttached(list);
        if (list !== undefined) {
            setTypeAttachedSelected(list[0]?.value);
        }
    }, [attachmentsRedux.typeAttached?.tipoAdjunto]);

    useEffect(() => {
        if (attachmentsRedux?.attachments?.autorizacionAdjuntos !== undefined) {
            let attachmentsList = attachmentsRedux?.attachments?.autorizacionAdjuntos?.map(att => {
                return {
                    nombre: att?.autorizacionAdjuntoNombre,
                    tipo: att?.autorizacionAdjuntoTipo,
                    observacion: txtComment,
                    codigo: att?.autorizacionAdjuntoCodigo
                };
            });
            setListAttached(attachmentsList);
        }
    }, [attachmentsRedux?.attachments?.autorizacionAdjuntos, attachmentsRedux?.postAttachments]);

    useEffect(() => {
        if (attachmentsRedux?.postAttachments?.confirmacion === false) {
            setMsgErrorAttachments(attachmentsRedux?.postAttachments?.mensajes[0]?.mensaje);
            setOpenModalError(true);
        } else if (attachmentsRedux?.postAttachments?.confirmacion) {
            let dateNow = Date.now();
            setListAttached([...listAttached,
            {
                nombre: files[0]?.archivoNombre,
                fecha: format(dateNow, "dd/MM/yyyy"),
                tipo: files[0]?.archivoClasificacion,
                observacion: txtObservation
            }]);
        }
        getNewComment();
    }, [attachmentsRedux.postAttachments]);

    function getAttachmentDescription(attachmentCode) {
        var description = "";
        if (attachmentCode !== undefined) {
            const auxDescription = attachmentsRedux?.typeAttached?.tipoAdjunto?.filter(x => x.tipoAdjuntosCodigo === attachmentCode);
            if (typeof (auxDescription) !== 'undefined') {
                if (auxDescription[0]?.tipoAdjuntosDescripcion !== undefined) {
                    description = auxDescription[0]?.tipoAdjuntosDescripcion;
                }
            }
        }
        return description;
    }

    useEffect(() => {
        const timer = setTimeout(getNewComment, 1000);
        return () => clearTimeout(timer);
    }, [attachmentsRedux?.readComments]);

    const setSeenComment = () => {
        let readCommentsParameters = obj;
        readCommentsParameters.perspectiva = "A";

        dispatch(getReadComments(readCommentsParameters));
    }

    const readCommentIcon = element => {
        const readIcon = <div style={{ float: 'right', color: "#0075c9", display: 'block' }}><DoneAllOutlinedIcon /></div>;
        const unreadIcon = <div style={{ float: 'right', color: "#585858", display: 'block' }}><DoneOutlinedIcon /></div>;

        if (element?.autorizacionComentarioPerspectiva === "O" && element?.autorizacionComentarioLeido === "S") {
            return readIcon;
        } else if (element?.autorizacionComentarioPerspectiva === "O" && element?.autorizacionComentarioLeido === "N") {
            return unreadIcon;
        }
    }

    const handleClickDownloadButton = element => e => {
        e.preventDefault();
        dispatch(downloadAttachments({
            numeroPersona: parseInt(item?.authorizationDetails?.afiliado),
            autorizacionAdjuntoCodigo: element?.codigo
        }));
    }

    useEffect(() => {
        if (downloadAuthorizationsAttachments?.adjunto !== undefined) {
            setAttachedToDownload({
                archivo: downloadAuthorizationsAttachments?.adjunto?.[0]?.adjunto,
                archivoNombreCompleto: downloadAuthorizationsAttachments?.adjunto?.[0]?.adjuntoNombreCompleto
            });
        }
    }, [downloadAuthorizationsAttachments]);

    const cancelDownload = () => {
        setCancelledDownload(true);
        setOpenLoading(false);
    }

    useEffect(() => {
        if (loadingAuthorizationDownload) {
            setCancelledDownload(false);
            setOpenLoading(true);
            setInitLoad(false);
        } else {
            if (!initLoad && !cancelledDownload) {
                setOpenDownload(true);
                setOpenLoading(false);
            }
        }
    }, [loadingAuthorizationDownload])

    const commentsAccordion = () => {
        return (
            <div style={{ width: '100%', justifyContent: 'flex-start', flexDirection: 'column-reverse', paddingTop: '10px' }} className={classes.boxSteps}>
                {attachmentsRedux?.comments?.autorizacionComentarios?.length > 0 && attachmentsRedux?.comments?.autorizacionComentarios?.length !== undefined ?
                    (attachmentsRedux?.comments?.autorizacionComentarios?.map((step, i) => (
                        step?.autorizacionComentarioObservacion !== '' ? (
                            <div key={i} style={{ paddingTop: '10px' }}>
                                <div style={{ width: '10px', height: '10px', position: 'absolute', backgroundColor: '#0075c9', padding: '12px', borderRadius: '20px', marginTop: '10px' }}></div>
                                <span style={{ paddingLeft: '30px', display: 'flex', paddingTop: '10px', paddingBottom: '10px' }} className={classes.stepLabelTab}> {step?.autorizacionComentarioFechaHora?.replace('T', ' ')}</span>
                                <section style={{ marginLeft: '12px', paddingLeft: '20px', paddingRight: '8px', borderLeft: '1px solid #bdbdbd' }}>
                                    <div style={{ borderRadius: '10px', minHeight: '150px', padding: '10px', backgroundColor: '#FEDCDC', minWidth: '180px' }}>
                                        <Typography style={{ fontSize: '16px', fontWeight: "500", color: '#0075c9' }} className={`${classes.txtCardDescription}`}>{step?.autorizacionComentarioPerspectiva === 'O' ? 'OSLP' : 'Afiliado'}</Typography>
                                        <p style={{ color: '#585858', wordWrap: 'break-word' }}>
                                            {step?.autorizacionComentarioObservacion}
                                            {readCommentIcon(step)}
                                        </p>
                                    </div>
                                </section>

                            </div>
                        ) : null
                    ))) :
                    <NotFound
                        customMsg={
                            <div className={classes.contentNotFound}>
                                <span className={classes.notAppointment}>No hay contenido para mostrar</span>
                            </div>
                        }
                        wsItem={attachmentsRedux?.comments?.autorizacionComentarios?.length}
                        fullItem={attachmentsRedux?.comments?.autorizacionComentarios?.length}
                    />
                }
            </div>
        );
    }

    return (
        <>
            {!CLOSED_STATES?.includes(item?.authorizationDetails?.autorizacionEstadoDescripcion?.toUpperCase()?.trim()) &&
                <section style={{ display: 'flex', padding: screen.sWidth > 768 && "0 35px" }}>
                    <CustomButton title={"Nuevo comentario"} onClick={handleOpenTextBox}
                        style={{ height: "25px", fontSize: "9.5px", fontWeight: "600" }}
                        icon={<AddCircleIcon style={{ marginRight: "5px" }} />}
                        customClass={`${classes.customHeaderBtn} ${classes.lightLinearGradient} ${classes.txtMobile}`}
                    />
                    <CustomButton title={"Nuevo adjunto"}
                        onClick={handleAddAttachment}
                        style={{ height: "25px", fontSize: "9.5px" }}
                        icon={<AddCircleIcon style={{ marginRight: "5px" }} />}
                        customClass={`${classes.customHeaderBtn} ${classes.txtMobile} ${classes.whiteAndBlueBtn}`}
                    />
                    {
                        screen.sWidth > 768 &&
                        <FrequentQuestionsButton />
                    }
                </section>
            }

            {openTxtComment &&
                <section style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '30px' }}>
                    <FormControl style={{ width: '100%', marginTop:'40px', marginLeft: screen.sWidth < 768 ? '20px' : '320px' }} variant="standard">
                        <LineBreakInput
                            formControlClass={classes.txtObservations}
                            inputLabelClass={classes.txtLabelComments}
                            customClass={classes.txtBoxComments}
                            name="consulta"
                            style={{
                                borderRadius: 29,
                                position: 'relative',
                                border: '1px solid #ced4da',
                                fontSize: 16,
                                minWidth: '224px !important',
                                margin: '0 0 7px 0',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: "nowrap",
                            }}
                            label={<>
                                <IconButton style={{ padding: '0px', marginTop: "-40px", left: screen.sWidth < 768 ? '-15px' : "115px" }} onClick={handleCloseTxtComment}>
                                    <CloseRoundedIcon style={{ marginRight: "5px", backgroundColor: '#ff0000', color: '#ffffff', borderRadius: '15px', fontSize: '25px' }} className={classes.iconFilter} />
                                </IconButton>
                                <span style={{ marginTop: "-30px", marginLeft: screen.sWidth < 768 ? '-10px' : "120px" }}> Cerrar </span>
                            </>}
                            placeholder='Escribí tu comentario aquí...'
                            onChange={handleChangeText}
                            value={txtComment}
                            multiline
                            rows={5}
                            inputProps={{ maxLength: 1000 }}
                        />
                        <Typography variant="caption" style={{ marginLeft: screen.sWidth < 768 ? "220px" : '450px'}}>{counterLength}/1000</Typography>
                    </FormControl>
                    <CustomButton title={"ENVIAR"} onClick={handlePushComment}
                        style={{
                            height: "25px",
                            fontSize: "12px",
                            fontWeight: "600",
                            padding: '16px'
                        }}
                        customClass={`${classes.lightLinearGradient} ${classes.txtMobile}`}
                    />
                </section>
            }

            {files.length !== 0 && hide &&
                <>
                    <section style={{ display: 'flex', flexDirection: "column", alignItems: "center", marginTop: '30px' }}>

                        {files?.map((file, i) => (
                            <div
                                key={i}
                                className={`${classes.txtSubtitleCalendar}`}
                                style={{ display: "flex", justifyContent: "center", width: "100%" }}
                            >
                                <p className={`${classes.txtEllipsis}`}>
                                    {file?.archivoNombre}
                                </p>
                                <IconButton
                                    onClick={deleteFile(
                                        fileButtons,
                                        setFileButtons,
                                        setFiles,
                                        files,
                                        file?.btnNombre,
                                        file?.archivoNombre)
                                    }
                                >
                                    <HighlightOffIcon />
                                </IconButton>
                            </div>
                        ))}

                        <FormControl variant="standard" className={classes.widthMobile}>
                            <Typography className={classes.textTitleTurn} style={{ fontSize: '13px', margin: '5px 7px' }}>Tipo de archivo </Typography>
                            <CustomSelect
                                label=''
                                name={'selectAttached'}
                                className={`${classes.selectAuthorizations}`}
                                list={listTypeAttached}
                                selected={typeAttachedSelected}
                                onChange={handleChangeSelect()}
                            />
                            <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.selectAttached}</FormHelperText>
                        </FormControl>

                        <FormControl variant="standard" className={classes.widthMobile}>
                            <Typography className={classes.textTitleTurn} style={{ fontSize: '13px', margin: '5px 7px' }}>Observaciones</Typography>
                            <LineBreakInput
                                formControlClass={`${classes.txtObservations}`}
                                inputLabelClass={classes.txtLabelComments}
                                customClass={classes.txtBoxObservation}
                                name="observacion"
                                placeholder='Escribí tu comentario aquí...'
                                onChange={handleChangeObservations}
                                style={{
                                    borderRadius: 29,
                                    position: 'relative',
                                    border: '1px solid #ced4da',
                                    fontSize: 16,
                                    minWidth: '224px !important',
                                    margin: '0 0 7px 0',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: "nowrap",
                                }}
                                multiline
                                rows={5}
                                inputProps={{ maxLength: 200 }}
                            />
                             <Typography variant="caption" style={{ marginLeft: screen.sWidth < 768 ? "220px" : '450px' }}>{counterObservationLength}/200</Typography>
                        </FormControl>

                        <CustomButton title={"ADJUNTAR"} onClick={handleOpenModalConfirm}
                            style={{
                                height: "25px",
                                fontSize: "12px",
                                fontWeight: "600",
                                padding: '16px'
                            }}
                            customClass={`${classes.customHeaderBtn} ${classes.btnAttached} ${classes.lightLinearGradient} ${classes.txtMobile}`}
                        />
                    </section>
                </>
            }

            <section style={{ padding: '3%' }}>
                <Accordion style={{ boxShadow: 'none' }} expanded={closeComments} onClick={() => { setCloseComments(prevState => !prevState) }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ borderBottom: '1px solid #e2e3e3' }}
                        onClick={setSeenComment}
                    >
                        {item?.count !== undefined && item?.count > 0 ?
                            <BadgeNotification
                                childrenItem={
                                    <QuestionAnswerOutlinedIcon style={{ marginRight: "5px", backgroundColor: '#0075c9', color: '#ffffff', borderRadius: '15px', fontSize: '30px', padding: '5px' }} className={classes.iconFilter} />
                                }
                                countNotification={item?.count}
                            />
                            :
                            <QuestionAnswerOutlinedIcon style={{ marginRight: "5px", backgroundColor: '#0075c9', color: '#ffffff', borderRadius: '15px', fontSize: '30px', padding: '5px' }} className={classes.iconFilter} />
                        }
                        <span style={{ paddingLeft: '10px' }}>Comentarios</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        {commentsAccordion()}
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ boxShadow: 'none' }} expanded={closeAttached} onChange={() => { setCloseAttached(prevState => !prevState) }}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        expandIcon={<ExpandMoreIcon />}
                        style={{ borderBottom: '1px solid #e2e3e3' }}
                        id="panel1a-header"
                    >
                        <AttachFileOutlinedIcon
                            style={{ marginRight: "5px", backgroundColor: '#0075c9', color: '#ffffff', borderRadius: '15px', fontSize: '30px', padding: '5px' }}
                            className={classes.iconFilter}
                        />
                        <span style={{ paddingLeft: '10px' }}>
                            Adjuntos
                        </span>
                    </AccordionSummary>
                    <AccordionDetails>
                        {listAttached?.length > 0 ?
                            <div className={classes.authorizationsAccordion}>
                                <Typography className={classes.txtSubtitleCalendar} style={{ fontSize: "16px" }}>
                                    {"NOMBRE"}
                                </Typography>
                                {listAttached.map((attached, i) => (
                                    <>
                                        <Accordion
                                            key={i}
                                            style={{ width: "100%" }}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                classes={{ content: classes.width100 }}
                                            >
                                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Typography className={classes.txtEllipsis}>
                                                        {attached?.nombre}
                                                    </Typography>
                                                    <Typography className={classes.txtSubtitleCalendar}>
                                                        {attached?.fecha}
                                                    </Typography>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails >
                                                {<>
                                                    <div className={classes.displayFlex}>
                                                        <Typography
                                                            className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}
                                                            style={{ marginRight: "5px" }}>
                                                            {"Tipo de archivo:  "}
                                                        </Typography>
                                                        <Typography className={`${classes.txtSubtitleGray} ${classes.txtEllipsis}`}>
                                                            {getAttachmentDescription(attached?.tipo)}
                                                        </Typography>

                                                    </div>
                                                    <div className={classes.displayFlex}>
                                                        <Typography
                                                            className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}
                                                            style={{ marginRight: "5px" }}>
                                                            {"Observaciones / comentarios:  "}
                                                        </Typography>
                                                        <Typography className={`${classes.txtSubtitleGray} ${classes.txtEllipsis}`}>
                                                            {attached?.observacion !== "" ? attached?.observacion : "No hay observaciones"}
                                                        </Typography>
                                                    </div>
                                                </>}
                                            </AccordionDetails>
                                            <CustomButton
                                                title="DESCARGAR"
                                                customClass={`${classes.lightLinearGradient}`}
                                                style={{
                                                    marginRight: "20px",
                                                    marginBottom: "20px",
                                                    width: "auto",
                                                    fontSize: "7px",
                                                    float: 'right',
                                                    fontWeight: '600'
                                                }}
                                                disabled={attached?.nombre === "" ? true : false}
                                                onClick={handleClickDownloadButton(attached)}
                                            />
                                        </Accordion>
                                    </>
                                ))}
                            </div>
                            :
                            <NotFound
                                customMsg={
                                    <div className={classes.contentNotFound}>
                                        <span className={classes.notAppointment}>No posee adjuntos</span>
                                    </div>
                                }
                                wsItem={listAttached?.length}
                                fullItem={listAttached?.length}
                            />
                        }
                    </AccordionDetails>
                </Accordion>
            </section>

            <ConfirmModal isOpen={openSendSuccess} setOpen={setOpenSendSuccess} errorClass={errorClass}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalIcon}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalTitle}
                        </div>
                    </>
                }
                description={modalDescription} />

            {<UploadModal
                title='Adjuntar archivo'
                description=''
                observation=''
                open={openModal}
                onClose={setOpenModal}
                setModalTitle={setModalTitle}
                setModalDescription={setModalDescription}
                setModalIcon={setModalIcon}
                setErrorClass={setErrorClass}
                setOpenSendSuccess={setOpenSendSuccess}
                setFiles={setFiles}
                files={files}
                fileButtons={fileButtons}
                setFileButtons={setFileButtons}
                hide={true}
                setRepeatFile={setThisFileExists}
                setHide={setHide}
                listAttached={listAttached}
            />}
            {
                openModalConfirm &&
                <ModalActivities
                    title={'¿Estás seguro que deseas adjuntar?'}
                    description={'El archivo que se va a adjuntar es: ' + files[0]?.archivoNombre.slice(0, 20) + '...'}
                    open={openModalConfirm}
                    onClose={setOpenModalConfirm}
                    modalconfirm={true}
                    modalLogout={false}
                    buttonType={'submit'}
                    handleClick={handleSubmit}
                />
            }
            <ConfirmModal isOpen={openModalError} setOpen={setOpenModalError} errorClass={errorClass}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            Ocurrió un error al adjuntar el archivo
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            Error: {msgErrorAttachments}
                        </div>
                    </>
                }
                description={modalDescription} />

            <ConfirmModal
                isOpen={thisFileExists}
                setOpen={setThisFileExists}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                        </div>
                    </>
                }
                description={"No puede adjuntar dos archivos con el mismo nombre"}
            />

            {openDownload &&
                <ModalActivities
                    description={
                        <div style={{
                            display: 'flex',
                            margin: '5px',
                            justifyContent: 'center'
                        }}>
                            <a
                                href={`data:image/png;base64,${attachedToDownload?.archivo}`}
                                download={attachedToDownload?.archivoNombreCompleto}>
                                <IconButton style={{ backgroundColor: '#ffffff', borderRadius: '15px' }}>
                                    <FileDownloadIcon className={classes.actionsIcons} />
                                </IconButton>
                            </a>
                        </div>
                    }
                    title={
                        <div style={{
                            justifyContent: 'center',
                            display: 'flex',
                            marginTop: '20px',
                            width: '250px'
                        }}>
                            <span>Descargar</span>
                        </div>
                    }
                    onClose={setOpenDownload}
                    open={openDownload}
                    modalconfirm={false}
                    modalLogout={false}
                />
            }

            {openLoading &&
                <ModalActivities
                    description={
                        <div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.whiteCircularProgress}`}>
                            <CircularProgress />
                        </div>
                    }
                    title={
                        <div style={{ marginTop: "30px" }}>Preparando archivo ...</div>
                    }
                    onClose={cancelDownload}
                    style={{ textAlign: 'center' }}
                    open={openLoading}
                    modalconfirm={false}
                    modalLogout={false}
                />
            }

            <ConfirmModal
                isOpen={closedAuthorization}
                setOpen={setClosedAuthorization}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                        </div>
                    </>
                }
                description={"No es posible enviar comentarios ni adjuntos en autorizaciones finalizadas"}
            />

            <input ref={inputFile} type="file" hidden id='upFile' onChange={handleUpload} name='upFileName' />
        </>
    );
};

export default Comments;

export const validateFiles = files => {
    let auxListFiles = [];
    let returnResponse = false;
    if (auxListFiles.length > 0) {
        let auxiliarList = [];
        files?.forEach(item => {
            if (item?.adjuntoObligatorio === 'S') {
                auxiliarList.push(auxListFiles?.some(x => x === item.btnNombre));
            }
        });
        if (auxiliarList.length > 0 && Object.values(auxiliarList)?.every(x => x === true)) {
            returnResponse = true;
        }
    } else {
        returnResponse = true;
    }
    return returnResponse;
};