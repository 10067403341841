import * as actionTypes from '../consts/actionTypes';

const initialValues = {
    countNotification: 0,
    notificationSelected: null,
    notification: [],
    notifications:{},
    modifyNotifications:false,
    hasNotification : [
        { title: 'Urgencias', countNotification: 0 },
        { title: 'Turnos', countNotification: 0 },
        { title: 'Autorizaciones', countNotification: 0 },
        { title: 'Reintegros', countNotification: 0 },
        { title: 'Médico virtual', countNotification: 0 },
        { title: 'Credencial', countNotification: 0 },
        { title: 'Datos médicos', countNotification: 0 },
        { title: 'Datos y Afiliados', countNotification: 0 },
        { title: 'Cobertura', countNotification: 0 },
        { title: 'Cartilla', countNotification: 0 },
        { title: 'Datos útiles', countNotification: 0 },
        { title: 'Facturación', countNotification: 0 }
    ],
    msgError: ''

};

export default function notificationReducer(state = initialValues, action) {
    const DEFAULT = { ...state };
  
    const ACTION_TO_RESPONSE = {
        [actionTypes.SUCCESS_ALL_GET_NOTIFICATION]: responseToReturn('notifications'),
        [actionTypes.SUCCESS_UPDATE_ALL_GET_NOTIFICATION]: responseToReturn('notifications'),
        [actionTypes.SUCCESS_GET_NOTIFICATION]: responseToReturn('notification'),
        [actionTypes.SUCCESS_MODIFY_NOTIFICATION]: responseToReturn('modifyNotifications'),
        [actionTypes.SUCCESS_SET_COUNT_NOTIFICATION]: responseToReturn('countNotification'),
        [actionTypes.SUCCESS_SELECT_NOTIFICATION]: responseToReturn('notificationSelected'),
        [actionTypes.SUCCESS_HASH_NOTIFICATION]: responseToReturn('hasNotification'),
        [actionTypes.FAILURE_ALL_GET_NOTIFICATION]: responseToReturn('notifications'),
        [actionTypes.FAILURE_UPDATE_ALL_GET_NOTIFICATION]: responseToReturn('notifications'),
        [actionTypes.FAILURE_GET_NOTIFICATION]: responseToReturn('notification'),
        [actionTypes.FAILURE_MODIFY_NOTIFICATION]: responseToReturn('modifyNotifications'),
        [actionTypes.FAILURE_SET_COUNT_NOTIFICATION]: responseToReturn('countNotification'),
        [actionTypes.FAILURE_SELECT_NOTIFICATION]: responseToReturn('notificationSelected'),
        [actionTypes.FAILURE_HASH_NOTIFICATION]: responseToReturn('hasNotification'),


        /****/
        [actionTypes.GET_NOTIFICATIONS]: responseToReturn(''),
    };

    function responseToReturn(item) {
        let res = { ...state };
        if (action.response) {
            res = { ...state, [item]: action.response.data, msgError: action.message };
        }
        if (action.message) {
            res = { ...state, msgError: action.message };
        }
        return res;
    }

     let receiveAction = DEFAULT;

    if (ACTION_TO_RESPONSE[action.type] !== undefined) {
        receiveAction = ACTION_TO_RESPONSE[action.type];
    }

    return receiveAction;
}