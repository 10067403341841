import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import useStyles from "../../assets/styles/styles";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const GenericModal = (props) => {
    const { open, setClose, customId, customClass, customWidth } = props;
    const classes = useStyles();

    function handleClose() {
        setClose(prevState => !prevState)
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                id={customId}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '15px',
                    },
                }}
                maxWidth={customWidth}
            >
                <IconButton
                    aria-label="close"
                    className={classes.closeModal}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent className={customClass}>
                    {props.children}
                </DialogContent>
            </Dialog>
        </>
    );
}

export default GenericModal;