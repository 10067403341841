import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetRefunds({ payload }) {
    try {
        const response = yield call(API.getRefunds, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_REFUNDS, response });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_GET_REFUNDS,
            message: error,
        });
    }
}

function* asyncAccountRefundAB({ payload }) {
    try {
        const response = yield call(API.ABAccountRefund, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_AB_ACCOUNT_REFUND, response });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_AB_ACCOUNT_REFUND,
            message: error,
        });
    }
}

function* asyncGetParametersRefunds() {
    try {
        const response = yield call(API.getParametersRefunds);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_PARAMETERS_REFUNDS, response });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_GET_PARAMETERS_REFUNDS,
            message: error,
        });
    }
}

function* asyncGetAccountRefunds({ payload }) {
    try {
        const response = yield call(API.getAccountRefunds, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_ACCOUNT_REFUNDS, response });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_GET_ACCOUNT_REFUNDS,
            message: error,
        });
    }
}

function* asyncPostRefunds({ payload }) {
    try {
        const response = yield call(API.postRefunds, payload);
        if (response)
            yield put({
                type: actionTypes.SUCCESS_POST_REFUNDS,
                response
            });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_POST_REFUNDS,
            message: error,
        });
    }
}

function* asyncGetRefundDetails({ payload }) {
    try {
        let response = yield call(API.getRefundDetails, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_REFUND_DETAILS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_REFUND_DETAILS, message: error });
    }
}

function* asyncGetRefundAttachments({ payload }) {
    try {
        let response = yield call(API.getRefundAttachments, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_REFUND_ATTACHMENTS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_REFUND_ATTACHMENTS, message: error });
    }
}

function* asyncGetRefundComments({ payload }) {
    try {
        let response = yield call(API.getRefundComments, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_REFUND_COMMENTS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_REFUND_COMMENTS, message: error });
    }
}

function* asyncGetDetailsAttachments({ payload }) {
    try {
        let response = yield call(API.getDetailsAttachments, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_DETAILS_ATTACHMENTS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_DETAILS_ATTACHMENTS, message: error });
    }
}

function* asyncGetRefundTypeAttached({ payload }) {
    try {
        let response = yield call(API.getRefundTypeAttached, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_REFUND_TYPE_ATTACHED, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_REFUND_TYPE_ATTACHED, message: error });
    }
}

function* asyncPushRefundComments({ payload }) {
    try {
        let response = yield call(API.postRefundAComment, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_PUSH_REFUND_COMMENTS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_PUSH_REFUND_COMMENTS, message: error });
    }
}

function* asyncPostRefundAttached({ payload }) {
    try {
        let response = yield call(API.postRefundAttached, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_POST_REFUND_ATTACHED, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_POST_REFUND_ATTACHED, message: error });
    }
}

function* asyncCancelRefund({ payload }) {
    try {
        const response = yield call(API.cancelRefund, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_CANCEL_REFUND, response });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_CANCEL_REFUND,
            message: error,
        });
    }
}

function* asyncPostReadRefundComment({ payload }) {
    try {
        let response = yield call(API.postReadRefundComments, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_POST_READ_REFUNDS_COMMENTS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_POST_READ_REFUNDS_COMMENTS, message: error });
    }
}

function* asyncDownloadRefund({ payload }) {
    try {
        let response = yield call(API.downloadRefunds, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_DOWNLOAD_REFUND, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_DOWNLOAD_REFUND, message: error });
    }
}

// Watchers
export default function* refundsSaga() {
    yield takeLatest(actionTypes.GET_REFUNDS, asyncGetRefunds);
    yield takeLatest(actionTypes.AB_ACCOUNT_REFUND, asyncAccountRefundAB);
    yield takeLatest(actionTypes.GET_ACCOUNT_REFUNDS, asyncGetAccountRefunds);
    yield takeLatest(actionTypes.GET_PARAMETERS_REFUNDS, asyncGetParametersRefunds);
    yield takeLatest(actionTypes.POST_REFUNDS, asyncPostRefunds);
    yield takeLatest(actionTypes.GET_REFUND_DETAILS, asyncGetRefundDetails);
    yield takeLatest(actionTypes.GET_REFUND_ATTACHMENTS, asyncGetRefundAttachments);
    yield takeLatest(actionTypes.GET_REFUND_COMMENTS, asyncGetRefundComments);
    yield takeLatest(actionTypes.GET_DETAILS_ATTACHMENTS, asyncGetDetailsAttachments);
    yield takeLatest(actionTypes.GET_REFUND_TYPE_ATTACHED, asyncGetRefundTypeAttached);
    yield takeLatest(actionTypes.PUSH_REFUND_COMMENTS, asyncPushRefundComments);
    yield takeLatest(actionTypes.POST_REFUND_ATTACHED, asyncPostRefundAttached);
    yield takeLatest(actionTypes.CANCEL_REFUND, asyncCancelRefund);
    yield takeLatest(actionTypes.POST_READ_REFUNDS_COMMENTS, asyncPostReadRefundComment);
    yield takeLatest(actionTypes.DOWNLOAD_REFUND, asyncDownloadRefund);
}