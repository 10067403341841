import { takeLatest, call, put } from "@redux-saga/core/effects";
import * as actionTypes from '../consts/actionTypes';
import * as API from '../api/index';

function* asyncGetTypesOfContact({ payload }) {
    try {
        let response = yield call(API.getTypeOfContact, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_TYPE_OF_CONTACT, response });
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_TYPE_OF_CONTACT, message: error });
    }
}

function* asyncGetTypesOfContactPhone({ payload }) {
    try {
        let response = yield call(API.getTypeOfContact, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_TYPE_OF_CONTACT_PHONE, response });
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_TYPE_OF_CONTACT_PHONE, message: error });
    }
}

function* asyncGetTypesOfContactMail({ payload }) {
    try {
        let response = yield call(API.getTypeOfContact, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_TYPE_OF_CONTACT_MAIL, response });
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_TYPE_OF_CONTACT_MAIL, message: error });
    }
}

function* asyncGetContactData({ payload }) {
    try {
        let response = yield call(API, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_CONTACTDATA_OF_AFFILIATED, response, message: '' });
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_CONTACTDATA_OF_AFFILIATED, message: error.message });
    }
}

function* asyncGetTypesDocuments({ payload }) {
    try {
        let response = yield call(API.getTypesDocuments, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_TYPES_DOCUMENTS, response });
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_TYPES_DOCUMENTS, message: error });
    }
}

function* asyncGetCivilStatus() {
    try {
        let response = yield call(API.getCivilStatus);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_CIVIL_STATUS, response });
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_CIVIL_STATUS, message: error });
    }
}

function* asyncGetNationalities() {
    try {
        let response = yield call(API.getNationalities);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_NATIONALITY, response });
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_NATIONALITY, message: error });
    }
}

function* asyncGetFullData({ payload }) {
    try {
        let response = yield call(API.getCompleteData, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_FULL_DATA, response });
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_FULL_DATA, message: error });
    }
}

function* asyncAbmFullData({ payload }) {
    try {
        let response = yield call(API.abmFullData, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_ABM_FULL_DATA, response, message: '' });
            yield put({ type: actionTypes.SEND_REQUEST_FAILURE, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_ABM_FULL_DATA, message: error.message });
    }
}

function* asyncGetCredentials({ payload }) {
    try {
        let response = yield call(API.getCredentials, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_CREDENTIALS, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_CREDENTIALS, message: error.message });
    }
}

function* asyncGetHobbies({ payload }) {
    try {
        let response = yield call(API.getHobbies, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_HOBBIES, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_HOBBIES, message: error });
    }
}

function* asyncGetDeportes({ payload }) {
    try {
        let response = yield call(API.getDeportes, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_DEPORTES, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_DEPORTES, message: error });
    }
}

export default function* personalDataSaga() {
    yield takeLatest(actionTypes.GET_TYPE_OF_CONTACT, asyncGetTypesOfContact);
    yield takeLatest(actionTypes.GET_TYPE_OF_CONTACT_PHONE, asyncGetTypesOfContactPhone);
    yield takeLatest(actionTypes.GET_TYPE_OF_CONTACT_MAIL, asyncGetTypesOfContactMail);
    yield takeLatest(actionTypes.GET_CONTACTDATA_OF_AFFILIATED, asyncGetContactData);
    yield takeLatest(actionTypes.GET_NATIONALITY, asyncGetNationalities);
    yield takeLatest(actionTypes.GET_CIVIL_STATUS, asyncGetCivilStatus);
    yield takeLatest(actionTypes.GET_TYPES_DOCUMENTS, asyncGetTypesDocuments);
    yield takeLatest(actionTypes.GET_FULL_DATA, asyncGetFullData);
    yield takeLatest(actionTypes.ABM_FULL_DATA, asyncAbmFullData);
    yield takeLatest(actionTypes.GET_CREDENTIALS, asyncGetCredentials);
    yield takeLatest(actionTypes.GET_HOBBIES, asyncGetHobbies);
    yield takeLatest(actionTypes.GET_DEPORTES, asyncGetDeportes);
}
