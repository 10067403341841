import { useEffect, useState } from "react";


export function useCurrentPosition() {

    const [currentPosition, setCurrentPosition] = useState({});

    const success = (position) => {
        setCurrentPosition({...currentPosition, lat: position.coords.latitude, lng: position.coords.longitude});
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success);
    }, []);

    return { currentPosition };
}
