import { FormControl, StyledEngineProvider, Typography, Grid, Box } from "@material-ui/core";
import CustomizedInputs from "../../../controls/InputCustom/CustomizedInputs";
import { useState, useEffect } from 'react';
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import CustomButton from "../../../controls/Button/CustomButton";
import useStyles from "../../../assets/styles/styles";
import CustomizedDateTimerPicker from "../../../controls/DateTimerPicker/CustomizedDateTimerPicker";
import InputLabel from '@material-ui/core/InputLabel';
import CustomSelect from './../../../controls/Select/CustomSelect'
import { format } from "date-fns";
import Loading from "../../LoadingPage/Loading";

const FormVitalParameters = (props) => {
    const [loadingForm, setLoadingForm] = useState(true);
    const classes = useStyles();
    const [type, setType] = useState("");
    const [valueParameter1, setValueParameter1] = useState({});
    const [valueParameter2, setValueParameter2] = useState({});
    const [dateRegister, setDateRegister] = useState(new Date());
    const [errors, setErrors] = useState({});
    const { screen } = useResizeScreen();
    const typesParameters = [{ value: 1, label: "TEMPERATURA" }, { value: 2, label: "TENSION ARTERIAL" }, { value: 3, label: "GLUCEMIA" }, { value: 4, label: "FRECUENCIA CARDIACA" }];

    useEffect(() => {
        inicializeErrors();
        setLoadingForm(false);
    }, [])

    const inicializeErrors = () => {
        const temp = { parameterType: "", value1: "", value2: "" };
        setErrors(temp)
    }

    const handleCancel = () => {
        setType("");
        inicializeErrors();
        setValueParameter1(null);
        setValueParameter2(null);
        props.setAdd(prevState => !prevState);
    }

    const handleSaveForm = (e) => {
        e.preventDefault();
        const param = {
            numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
            parametroVitalTipo: typesParameters.find((x) => x.value == type)?.label?.trim(),
            paramentrosVitalValores: []
        };
        if (type === 2) {
            param.paramentrosVitalValores.push({ valorDescripcion: "SISTOLICA", valor: valueParameter1 });
            param.paramentrosVitalValores.push({ valorDescripcion: "DIASTOLICA", valor: valueParameter2 });
        } else {
            param.paramentrosVitalValores.push({ valorDescripcion: "", valor: valueParameter1 });
        }
        param.parametroVitalFechaRegistracion = format(dateRegister, "yyyy-MM-dd") + " " + dateRegister.toLocaleTimeString();
        param.modo = "A";

        if (validate(param)) {
            const newForm = param;
            newForm.parametroVitalValores = JSON.stringify(param.paramentrosVitalValores);
            props.setForm(newForm);
            props.setAction("ALTA");
            props.setOpenConfirm(prevState => !prevState);
        }
    }

    const validate = (param) => {
        const temp = validateValues(param.paramentrosVitalValores, param.parametroVitalTipo);
        setErrors({
            ...temp,
        });
        return Object.values(temp).every((value) => value === "");
    };


    const validationType = () => {
        if (type === 2) {
            return (
                <>
                    <CustomizedInputs
                        name="value2"
                        onChange={(e) => { setValueParameter2(e.target.value) }}
                        value={valueParameter2}
                        label={`SISTOLICA (${units(type)})`}
                        type="number"
                        formControlClass={""}
                        inputLabelClass={""}
                        onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                        }}
                    />
                    <div className={classes.flexRow}>
                        {isNotEmpty(errors.value2) ? <span style={{ marginLeft: "0" }} className={classes.spanError}>{errors.value2}</span> : ""}
                    </div>
                </>
            );
        }
    }

    const addInput = () => {
        return (
            <>
                <CustomizedInputs
                    name="value1"
                    value={valueParameter1}
                    onChange={(e) => { setValueParameter1(e.target.value) }}
                    label={type === 2 ? `DIASTOLICA (${units(type)})` : units(type)}
                    inputLabelClass={""}
                    formControlClass={""}
                    type="number"
                    onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                    }}
                />
                <div className={classes.flexRow}>
                    {isNotEmpty(errors.value1) ? <span className={classes.spanError} style={{ marginLeft: "0" }}>{errors.value1}</span> : ""}
                </div>
            </>
        );
    }

    const handleChangeErrors = (e) => {
        e.preventDefault();
        inicializeErrors();
        setType(e.target.value);
    }

    function createForm() {
        return (
            <div>
                <form>
                    <Box>
                        <Grid container spacing={2} columns={12}>
                            <Grid item xs={12} sm={12} md={4} lg={3} spacing={1}>
                                <FormControl className={classes.formSelectVitalParameter} variant="standard">
                                    <CustomSelect
                                        label={"TIPO"}
                                        formControlClass={classes.formSelectInputVitalParameter}
                                        inputLabelClass={classes.formSelectLabelVitalParameter}
                                        name='parameterType'
                                        customRequired={true}
                                        list={typesParameters}
                                        onChange={handleChangeErrors} />
                                    {isNotEmpty(errors.parameterType) ? <span className={classes.spanError}>{errors.parameterType}</span> : ""}
                                </FormControl>
                            </Grid>

                            {isNotEmpty(type) ?
                                <>
                                    <Grid item xs={12} sm={12} md={4} lg={3} spacing={1}>
                                        <FormControl className={classes.formSelectVitalParameter} variant="standard">
                                                {addInput()}
                                                {validationType()}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3} spacing={1}>
                                        <FormControl id="DateTimePicker" className={classes.formControl100} variant="standard">
                                            <InputLabel className={classes.labelDateTimerPicker} shrink htmlFor="bootstrap-input">
                                                {"FECHA Y HORA DE REGISTRACION"}
                                            </InputLabel>
                                            <StyledEngineProvider injectFirst>
                                                < CustomizedDateTimerPicker setValue={setDateRegister} value={dateRegister} />
                                            </StyledEngineProvider>
                                        </FormControl>
                                    </Grid>
                                </> : ""}
                        </Grid>
                    </Box>

                    {screen.sWidth < 800 ? <div className={`${classes.BtnMobileWeight} `}>
                        <CustomButton
                            customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                            style={{ marginRight: "10px" }}
                            title='cancelar'
                            onClick={() => { handleCancel() }}
                        />
                        <CustomButton
                            customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                            title='Guardar'
                            type='submit'
                            onClick={handleSaveForm}
                        />
                    </div> : ""}
                </form>
            </div>
        );
    }

    return (<>
        <div className={`${setBackgroundMobile(screen.sWidth, classes)}`} style={{ paddingTop: '60px', marginBottom: "20px" }}>
            <Typography className={`${classes.titleTurn} ${classes.titleBox}`} style={{ textTransform: 'uppercase' }}>
                MIS DATOS MEDICOS
            </Typography>
            <Typography className={classes.subTitleMedicalData} style={{ marginLeft: "20px" }}>
                Información sin carácter sanitario conforme ley Nº 26529
            </Typography>
        </div>
        <div className={classes.headerSecundary} style={{ marginBottom: "20px" }}>
            <Typography className={`${classes.titleTurn} ${classes.titleBox}`} style={{ textTransform: 'uppercase', marginTop: "10px" }}>
                PARAMETROS VITALES
            </Typography>
            {screen.sWidth > 800 ?
                <div className={`${classes.BtnMobileWeight} `}>
                    <CustomButton
                        customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                        title='cancelar'
                        onClick={() => { handleCancel() }}
                    />
                    <CustomButton
                        customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                        title='Guardar'
                        type='submit'
                        onClick={handleSaveForm}
                    />
                </div> : ""}
        </div>
        <Loading isLoading={loadingForm} children={createForm()} />
    </>)
}
export default FormVitalParameters;

function validateValues(values, type) {
    const temp = { parameterType: (type !== "" && type !== undefined && type !== null) ? "" : "*Este campo es obligatorio" };

    if (valuesEmpty(temp, type, values)) {
        const mmMerc = "mm de Merc.";
        switch (type) {
            case "TEMPERATURA":
                temp.value1 = maxAndMin(44, 34, values[0].valor, "°C")
                break;
            case "TENSION ARTERIAL":
                temp.value1 = maxAndMin(400, 40, values[0].valor, mmMerc);
                temp.value2 = maxAndMin(401, 40, values[1].valor, mmMerc);
                break;
            case "GLUCEMIA":
                temp.value1 = maxAndMin(400, 25, values[0].valor, "mg/dl");
                break;
            case "FRECUENCIA CARDIACA":
                temp.value1 = maxAndMin(450, 20, values[0].valor, "lat/min");
                break;
            default:
                break;
        }
    }
    return temp;
}

function maxAndMin(max, min, value, unidad) {
    return ((value >= min && value <= max) ? "" : `*El valor debe estar entre ${min} y ${max} ${unidad}`)
}

const valuesEmpty = (temp, type, values) => {
    const campoObligatorio = "*Este campo es obligatorio";
    if (values.length === 0 && type === "TENSION ARTERIAL") {
        temp.value1 = campoObligatorio;
        temp.value2 = campoObligatorio;
        return false;
    } else if (values.length === 0 && type !== "TENSION ARTERIAL") {
        temp.value1 = campoObligatorio;
        return false;
    }
    else {
        return true;
    }
}

function setBackgroundMobile(width, classes) {
    let cssClass = '';
    if (width <= 768) {
        cssClass = classes.titlePersonalDataForm;
    }
    return cssClass;
}

function units(desc) {
    let response = "";
    switch (desc) {
        case 1:
            response = "°C";
            break;
        case 2:
            response = "mm de Merc.";
            break;
        case 3:
            response = "MG/DL";
            break;
        case 4:
            response = "LAT/MIN";
            break;
        default:
            break;
    }
    return response;
}

function isNotEmpty(value) {
    return value !== "";
}
