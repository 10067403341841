import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import useStyles from "../../assets/styles/styles";
import { Typography } from "@material-ui/core";
import CustomButton from "../../controls/Button/CustomButton";
import ModalProfile from "../DialogProfile/Modal";
import "./mapStyle.css";
import { useSelector } from "react-redux";
import { useCurrentPosition } from "../../hooks/useCurrentPosition";
import MedicalCard from "../MedicalRecord/Card/MedicalCard";
import { formatPhoneNumber } from "../../utils/utilsFunctions";

const googleApiKey = window.env.GOOGLE_API_KEY;

const MapContainer = (props) => {
  const { father, heightMap, viewList, medicalCenterUbication, profileSelected, widthMap, minHeightMap, elevation = 0 } = props;
  //click marker selected
  const [selected, setSelected] = useState({});
  // open detail selected
  const [openModal, setOpen] = useState(false);
  //geolocation
  const { currentPosition } = useCurrentPosition();
  //model
  const medicalRecord = useSelector((state) => state.cartillaReducer.cartilla);

  //style map
  const classes = useStyles();

  const mapStyles = {
    height: heightMap !== undefined ? heightMap : "100vh",
    width: widthMap !== undefined ? widthMap : "100%",
    minHeight: heightMap !== undefined ? minHeightMap : "100vh",
  };

  const handleClose = () => {
    var element = document.getElementsByClassName("gm-style")[0];
    if (element != null && element != undefined) {
      document
        .getElementsByClassName("gm-style")[0]
        .setAttribute(
          "style",
          "filter: blur(0px); position: absolute; z-index: 0; left: 0px; top: 0px; height: 100%; width: 100%; padding: 0px; border-width: 0px; margin: 0px;"
        );
    }
    setOpen(false);
  };

  const onSelect = (item) => {
    let auxItem = { ...item, seleccionado: true };
    setSelected(auxItem);
  };

  const handleClick = (e) => {
    setSelected({});
  };

  const ModalClick = (item, i) => (e) => {
    setOpen(prevState => !prevState);
    document
      .getElementsByClassName("gm-style")[0]
      .setAttribute(
        "style",
        "filter: blur(4px); position: absolute; z-index: 0; left: 0px; top: 0px; height: 100%; width: 100%; padding: 0px; border-width: 0px; margin: 0px;"
      );
  };

  useEffect(() => {
    if (props.profileSelected !== null && props.profileSelected !== undefined && props.profileSelected?.PrestadorNumero > 0) {
      let newSelected = medicalRecord?.cartilla?.filter((x) => x.prestadorNumero == profileSelected?.PrestadorNumero)[0];
      onSelect(newSelected);
    }
  }, []);

  const AddCardMaps = () => {
    var el = window.document.getElementsByClassName("gm-style-iw-c");
    el[0]?.setAttribute("style", "background-color: transparent!important;border-radius : 20px;opacity: '0.7';width: '100%';height: '38%';padding-right: 0px !important;padding-bottom: 0px !important;max-width: 100% !important;min-width: 100% !important; box-sizing: border-box;;overflow: hidden !important;top: 0;left: 0;padding: 0px !important;box-shadow: none !important;");
    var el2 = window.document.getElementsByClassName("gm-style-iw-d");
    el2[0]?.setAttribute("style", "overflow: 'hidden!important'");
    var el3 = window.document.getElementsByClassName("gm-style");
    el3[0]?.setAttribute("style", "background-color: transparent!important;border-radius : 20px;opacity: '0.7';width: '100%';height: '38%';padding-right: 0px !important;padding-bottom: 0px !important;max-width: 100% !important;min-width: 100% !important; box-sizing: border-box;;overflow: hidden !important;top: 0;left: 0;padding: 0px !important;box-shadow: none !important;");
    return (
      <MedicalCard item={selected} i={1} handleClick={ModalClick} mapContainer={true} currentPosition={currentPosition} />
    );
  };

  const AddModalProfile = () => {
    return (
      <ModalProfile
        open={true}
        setOpen={setOpen}
        onClose={handleClose}
        title={
          <>
              <Typography className={classes.titleDialogTitle}>
                {selected?.prestadorRazonSocial}
              </Typography>
              <Typography className={classes.subTitleDialogTitle}>
                {selected?.prestadorEspecialidad}
              </Typography>
          </>
      }
      description={
        <>
            <Typography paragraph className={classes.subParagraphDialog}>
                DATOS DEL PRESTADOR {selected?.prestadorRazonSocial}
            </Typography>
            <Typography className={classes.titleDialogContent}>
                TELEFONO:
            </Typography>
            { selected?.telefono?.length > 0 ?
                selected?.telefono?.map((phone, i) => (
                    <Typography
                        key={i}
                        paragraph
                        className={classes.paragraphDialog}
                    >
                        <a
                            className={classes.linkDialog}
                            href={`tel:${phone?.telefonoNumero}`}
                        >
                            {formatPhoneNumber(phone?.telefonoNumero, true)}
                        </a>
                        <br />
                    </Typography>
                ))
              :
                <Typography className={classes.paragraphDialog} style={{ marginBottom: '10px' }}>
                  NO HAY TELEFONO
                </Typography>
            }
            <Typography className={classes.titleDialogContent}>
                DIRECCION:
            </Typography>
                <Typography
                    paragraph
                    className={classes.paragraphDialog}
                >
                    {selected?.prestadorDomicilio} | {selected?.prestadorLocalidad} | {selected?.prestadorBarrio}
                    <br />
                </Typography>
            <Typography className={classes.titleDialogContent}>
                CORREO:
            </Typography>
            {
              <Typography
                paragraph
                className={classes.paragraphDialog}>
                  {selected?.mail[0]?.mailDireccion !== "" ? selected?.mail[0]?.mailDireccion :"NO HAY CORREO"}
                  <br />
              </Typography>
            }
        </>
    }
    observation={
        <>
            <Typography className={classes.titleDialogContent}>
                OBSERVACIONES:
            </Typography>
            {
              <Typography
                paragraph
                className={classes.paragraphDialog}>
                  {selected?.observacion !== "" ? selected?.observacion :"NO HAY OBSERVACIONES"}
                  <br />
              </Typography>
            }
        </>
    }
    specialties={
        <>
            <Typography className={classes.titleDialogContent}>
                ESPECIALIDAD:
            </Typography>
                <>
                    <Typography className={classes.paragraphDialog}>
                        { selected?.prestadorEspecialidad !== "" ? selected?.prestadorEspecialidad : "NO HAY ESPECIALIDAD" }
                    </Typography>
                </>
        </>
    }
      />
    );
  };

  const setMarker = () => {
    let response = (<Marker
      position={{
        lat: Number.parseFloat(medicalCenterUbication?.lat),
        lng: Number.parseFloat(medicalCenterUbication?.lng),
      }}
    />);
    if (medicalRecord?.cartilla !== undefined) {
      response = medicalRecord?.cartilla?.map((item, i) => {
        return (<Marker
          key={i}
          position={{
            lat: item.prestadorLatitud,
            lng: item.prestadorLongitud,
          }}
          onClick={() => onSelect(item)}
        />);
      });
    }
    return response;
  };

  return navigator.onLine ? (
    <LoadScript googleMapsApiKey={googleApiKey}>
      <GoogleMap
        mapContainerStyle={mapStyles}
        center={father === "medicalCenter" ? medicalCenterUbication : medicalRecord?.defaultCenter}
        onClick={handleClick}
        zoom={13}
      >
        {setMarker()}
        {selected?.seleccionado && (
          <InfoWindow
            position={{
              lat: selected?.prestadorLatitud + elevation,
              lng: selected?.prestadorLongitud,
            }}
            clickable={true}
            options={{ maxWidth: 'auto', backgroundColor: 'transparent !important' }}
            className={classes.gmStyle}
          >
            {openModal ? AddModalProfile() : AddCardMaps()}
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  ) : (
    <>
      <CustomButton
        title="NO ESTAS ONLINE"
        customClass={`${classes.sendButton} ${classes.lightLinearGradient}`}
        style={{ marginTop: "10px" }}
        id="btnNoEstasOnlineMap"
      />
      {viewList &&
        <CustomButton
          title="VER LISTADO"
          customClass={`${classes.sendButton} ${classes.darkLinearGradient}`}
          style={{ marginTop: "10px" }}
          id="btnVerListadoMap"
          onClick={handleClose}
        />
      }
    </>
  );
};
export default React.memo(MapContainer, (prevState, nextState) => {
  return prevState.profileSelected === nextState.profileSelected && prevState.medicalCenterUbication === nextState.medicalCenterUbication;
});
