import React, { useEffect, Fragment, useState } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import useStyles from "../../assets/styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { getEmergencyNumbers } from "../../redux/actions/emergencyActions";
import PhoneIcon from '@material-ui/icons/Phone';
import ModalActivities from "../DialogActivities/ModalActivities";
import { getLimitPlan } from "../../redux/actions/limitPlansAction";
import { verifyPermission, formatPhoneNumber } from "../../utils/utilsFunctions";

const EmergencyModal = ({ isOpen, setOpen }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const loadingPermission = useSelector((state) => state.limitPlanReducer.requestInProgress);
    const [ hasPermission, setHasPermission ] = useState(false);
    const limitPlanReducer = useSelector((state) => state.limitPlanReducer.allLimitPlanData);
    const affiliatePlan = useSelector((state) => state.personalDataReducer?.fullData?.afiliado?.planDescripcion);
    const emergencyNumbers = useSelector((state) => state.emergencyReducer.emergencyNumbers);

    useEffect(() => {
        dispatch(getLimitPlan());
    }, []);

    useEffect(() => {
        dispatch(getEmergencyNumbers());
    }, []);

    useEffect(() => {
        verifyPermission(limitPlanReducer, affiliatePlan, "Urgencias", setHasPermission);
    }, [limitPlanReducer, affiliatePlan, hasPermission]);

    // si el estado del módulo es "Habilitado" muestra modal de emergencia sino modal de "no tiene acceso al módulo"
    function showEmergencyModal(loading, permission) {
        var resp;
        if(loading) {
            resp = (
                <div style={{ display: 'flex', justifyContent: 'center', flex: '0 0 100%', paddingTop: '10px' }}>
                    <CircularProgress />
                </div>
            );
        } else {
            if(permission) {
                resp = (
                    <ModalActivities
                        modalRegister={false}
                        title={
                            <>
                                <div style={{ display: 'flex', justifyContent: 'center', padding: '30px' }}>
                                    Emergencias & Urgencias
                                </div>
                            </>
                        }
                        description={
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column' }}>
                                {emergencyNumbers?.emergency?.map((emergency, i) => (
                                    <Fragment key={i}>
                                        <Typography className={`${classes.divItemQuickAccess} ${classes.emergencyNumbersTitle}`} >
                                            {emergency.title}
                                        </Typography>
                                        {emergency?.numbers?.map((number, indexNumber) => (
                                            <Fragment key={indexNumber}>
                                                <div style={{ display: 'flex' }}>
                                                    <PhoneIcon className={`${classes.emergencyModalIcons}`} />
                                                    <a className={`${classes.divItemQuickAccess} ${classes.blueTxt} ${classes.txtPhoneModalEmergencies}`} href={number?.type === 'T' ? `tel:${number?.value?.replace(/[^ 0-9]/g, '')}` : `https://api.whatsapp.com/send?phone=${number?.value}`} target='_blank'>
                                                        {formatPhoneNumber(number?.value, false)}
                                                    </a>
                                                </div>
                                            </Fragment>
                                        ))}

                                    </Fragment>
                                ))}
                            </div>
                        }
                        open={isOpen}
                        onClose={setOpen}
                        customBackgroundDialog={classes.backgroundDarkBlue}
                        customBackgroundDialogContent={classes.backgroundDarkBlue}
                    />
                );
            } else {
                resp = (
                    <ModalActivities
                        modalRegister={false}
                        title={
                            <>
                                <div style={{ display: 'flex', justifyContent: 'center', padding: '30px' }}>
                                    Emergencias & Urgencias
                                </div>
                            </>
                        }
                        description={
                            <div style={{ color: "#FFFFFF !important", textAlign: "center" }}>
                                No tenés acceso al módulo
                            </div>
                        }
                        open={isOpen}
                        onClose={setOpen}
                        customBackgroundDialog={classes.backgroundDarkBlue}
                        customBackgroundDialogContent={classes.backgroundDarkBlue}
                    />
                );
            }
        }
        return resp;
    }

    return  <>
                {
                    isOpen &&
                    showEmergencyModal(loadingPermission, hasPermission)
                }
            </>;
};

export default React.memo(EmergencyModal, (prevState, nextState) => {
    return prevState.isOpen === nextState.isOpen && prevState.items === nextState.items;
});