import Iframe from "react-iframe";
import Menu from "../Menu/Menu";
import useStyles from "../../assets/styles/styles";
import Header from "../GenericHeader/Header";

const AuthorizationsFrequentQuestions = () => {
    const classes = useStyles();
    return (
        <>
            <Menu />
            <Header title="Preguntas Frecuentes" />
                <Iframe
                    sandbox='' scrolling="yes" src='https://www.oslpasteur.com.ar/PortalHTML5/accesos/PreguntasFrecuentes/index.html?autorizacion'
                    className={classes.iframe}
                />
        </>
    );
};

export default AuthorizationsFrequentQuestions;
