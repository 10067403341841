import * as actionTypes from '../consts/actionTypes';

const initialState = {
    wayToPay: [],
    enabledDirectDebit: [],
    getDirectDebit: [],
    abDirectDebit: [],
    bills: [],
    billingFiles: [],
    msgError: ''
};

export default function billingReducer(state = initialState, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SET_AB_DIRECTDEBIT]: cleanVariable('abDirectDebit'),
        [actionTypes.SUCCESS_GET_WAYTOPAY]: responseToReturn('wayToPay'),
        [actionTypes.SUCCESS_GET_ENABLED_DIRECTDEBIT]: responseToReturn('enabledDirectDebit'),
        [actionTypes.SUCCESS_GET_DIRECTDEBIT]: responseToReturn('getDirectDebit'),
        [actionTypes.SUCCESS_AB_DIRECTDEBIT]: responseToReturn('abDirectDebit'),
        [actionTypes.SUCCESS_GET_BILLINGS]: responseToReturn('bills'),
        [actionTypes.SUCCESS_GET_BILLINGS_FILES]: responseToReturn('billingFiles'),
        [actionTypes.FAILURE_GET_WAYTOPAY]: responseToReturn(),
        [actionTypes.FAILURE_GET_ENABLED_DIRECTDEBIT]: responseToReturn(),
        [actionTypes.FAILURE_GET_DIRECTDEBIT]: responseToReturn(),
        [actionTypes.FAILURE_AB_DIRECTDEBIT]: responseToReturn(),
        [actionTypes.FAILURE_GET_BILLINGS]: responseToReturn(),
        [actionTypes.FAILURE_GET_BILLINGS_FILES]: responseToReturn()
    };
    function responseToReturn(typeState) {
        let res = { ...state };
        if (action.response) {
            res = { ...state, [typeState]: action.response.data, msgError: action.message };
        }
        if (action.message) {
            res = { ...state, msgError: action.message };
        }
        return res;
    }

    function cleanVariable(typeState) {
        let res = { ...state };
        if (action.payload === 'clean') {
            res = { ...state, [typeState]: [] };
        }
        return res;
    }

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}