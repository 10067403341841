import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import ApartmentIcon from '@material-ui/icons/Apartment';
import CreateIcon from '@material-ui/icons/Create';
import useStyles from "../../../assets/styles/styles";
import { IconButton, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import CustomButton from "../../../controls/Button/CustomButton";
import AppointmentsCard from "./AppointmentsCard";
import HeaderCard from '../../Billing/Movements/HeaderCard';
import { getTelemedicineAppointments, clearState } from '../../../redux/actions/telemedicineAppointmentsActions';

const userCode = parseInt(window.localStorage.getItem("numeroPersona"));

const NextAppointmentCard = ({ item, i, handleClickOpenRepeatModal, setCancelItem, setOpenModal }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { screen } = useResizeScreen();
    const telemedicineAppointmentLink = useSelector(state => state.telemedicineAppointmentsReducer?.telemedicine?.link);
    const [ click, setClick ] = useState(false);

    const handleClickIngresar = (itemNext) => (e) => {
        e.preventDefault();

        let turnDate = new Date(itemNext?.turnoFechHora);
        let nextFifteenMinutes = new Date(itemNext?.turnoFechHora);
        let previuosFifteenMinutes = new Date(itemNext?.turnoFechHora);
        let today = new Date(Date.now());

        nextFifteenMinutes?.setMinutes(turnDate?.getMinutes() + 15);
        previuosFifteenMinutes?.setMinutes(turnDate?.getMinutes() - 15);
        
        let obj = {
            numeroPersona: itemNext?.pacienteNumero,
            turnoFechHora: itemNext?.turnoFechHora,
            turnoNumero: itemNext?.turnoNumero
        };

        if(itemNext?.proximoCercano || (today >= previuosFifteenMinutes) && (today <= nextFifteenMinutes)) {
            dispatch(getTelemedicineAppointments(obj));
            setClick(true);
        }
    }

    useEffect(() => {
        if(telemedicineAppointmentLink !== undefined && click) {
            window.open(telemedicineAppointmentLink, '_blank');
            setClick(false);
        }
        dispatch(clearState());
    }, [telemedicineAppointmentLink, click]);

    const handleClickOpenCancelModal = (itemNext) => (e) => {
        e.preventDefault();
        var itemCancel = {
            numeroPersona: itemNext?.pacienteNumero,
            numeroTurno: itemNext?.turnoNumero,
            enviarMail: "S",
            mail: window.localStorage.getItem("email")
        };
        setCancelItem(itemCancel);
        setOpenModal(prevState => !prevState);
    };

    function showIngresarBtn(itemA) {
        const condition = itemA?.tipoAtencion;
        var dateItem = new Date(item.turnoFechHora);
        var next15 = new Date(item.turnoFechHora);
        next15.setMinutes(dateItem.getMinutes() + 15);
        var dateToday = new Date(Date.now());
        if (condition === 'R') {
            var response = (
                <CustomButton
                    disabled={item?.proximoCercano || (dateToday > dateItem && dateToday <= next15) ? false : true}
                    title="INGRESAR"
                    customClass={`${classes.lightLinearGradient}`}
                    style={{ whiteSpace: "nowrap", marginTop: "10px", width: "60%", fontSize: "0.5rem", fontWeight: '600' }}
                    onClick={handleClickIngresar(itemA)}
                />
            );
        } else {
            response = ("");
        }
        return response;
    }

    return (
        <>
            <AppointmentsCard
                index={i}
                buttonsInHeader={true}
                headerButtons={
                    screen.sWidth < 321 &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end', backgroundColor: '#ffffff', borderRadius: '7px', }}>
                        <IconButton onClick={handleClickOpenRepeatModal(item)} style={{ padding: '5px' }}>
                            <CreateIcon style={{ fontSize: '1.5rem', color: '#0075c9' }} />
                        </IconButton>
                    </div>
                }
                headerIcon={<HeaderCard day={item?.fechaFormateada?.nombreDia} numberDay={item?.fechaFormateada?.dia} month={parseInt(item?.turnoFechHora?.substring(5, 7))} year={parseInt(item?.turnoFechHora?.substring(0, 4))} />}
                description={
                    <>
                        <Typography style={{ fontWeight: "600", color: '#0075c9' }} className={`${classes.txtCardDescription} `}>
                            <span style={{ fontSize: '1rem' }}>{"Hora: " + item?.turnoFechHora?.split('T')[1].substring(0, 5) + " hs"}</span>
                        </Typography>
                        {item?.tipoAtencion === 'R' ?
                            <div style={{ display: 'flex' }}>
                                <LocalPhoneIcon style={{ fontSize: '1rem', backgroundColor: '#0075c9', color: '#ffffff', borderRadius: '20px', padding: '1px', marginRight: '6px' }} />
                                <Typography style={{ fontWeight: "600", color: '#0075c9', paddingBottom: "10px" }} className={`${classes.txtCardDescription} `} >
                                    <span style={{ fontSize: '0.7rem' }}>TELEMEDICINA</span>
                                </Typography>
                            </div>
                            :
                            <div style={{ display: 'flex' }}>
                                <ApartmentIcon style={{ fontSize: '1rem', backgroundColor: '#002858', color: '#ffffff', borderRadius: '20px', padding: '1px', marginRight: '6px' }} />
                                <Typography style={{ fontWeight: "600", color: '#0075c9', paddingBottom: "10px" }} className={`${classes.txtCardDescription} `} >
                                    <span style={{ fontSize: '0.7rem', color: "#002858" }}>{"CENTRO MEDICO " + item?.sucursalDescripcion?.trim()}</span>
                                </Typography>
                            </div>
                        }
                        <Typography style={{ fontWeight: '600' }} className={`${classes.txtCardDescription}`}>
                            {item?.pacienteNombreApellido?.trim()}
                        </Typography>
                        <Typography className={`${classes.txtCardDescription}`}>
                            {item?.especialidadDescripcion?.trim()}
                        </Typography>
                        <Typography className={`${classes.txtCardDescription}`}>
                            {item?.medicoDescripcion?.trim()}
                        </Typography>
                    </>
                }
                buttonZone={
                    <section className={`${classes.btnZoneAppointmentCard}`}>
                        {screen.sWidth > 321 &&
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton onClick={handleClickOpenRepeatModal(item)} style={{ padding: '0px' }}>
                                    <CreateIcon style={{ fontSize: '1.5rem', color: '#0075c9' }} />
                                </IconButton>
                            </div>
                        }
                        <div className={classes.btnDivCards}>
                            {screen.sWidth > 321 ?
                                <>
                                    {showIngresarBtn(item)}
                                    <CustomButton
                                        title="CANCELAR"
                                        customClass={classes.lightLinearGradient}
                                        style={{ whiteSpace: "nowrap", marginTop: "10px", width: "60%", fontSize: "0.5rem", fontWeight: '600' }}
                                        onClick={handleClickOpenCancelModal(item)}
                                    />
                                </>
                                :
                                <>
                                    <CustomButton
                                        title="CANCELAR"
                                        customClass={classes.lightLinearGradient}
                                        style={{ whiteSpace: "nowrap", marginTop: "10px", width: "60%", fontSize: "0.5rem", fontWeight: '600' }}
                                        onClick={handleClickOpenRepeatModal(item)}
                                    />
                                    {showIngresarBtn(item?.tipoAtencion)}
                                </>
                            }
                        </div>
                    </section>
                }
            />
        </>
    );
};

export default React.memo(NextAppointmentCard, (prevState, nextState) => {
    return prevState.item === nextState.item && prevState.i === nextState.i
});
