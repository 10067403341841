import { Button, Divider, FormControl, Typography, FormHelperText, CircularProgress } from '@material-ui/core';
import useStyles from "../../assets/styles/styles";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import CustomButton from "../../controls/Button/CustomButton";
import PhonesInput from "../../controls/InputCustom/PhonesInput";
import InputLabel from '@material-ui/core/InputLabel';
import EditIcon from '@material-ui/icons/Edit';
import { Fragment, useState, useEffect } from 'react';
import FormHeader from './FormHeader';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import SaveModal from './SaveModal';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ConfirmModal from './ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import { abmFullData } from '../../redux/actions/personalDataActions';
import CancelIcon from '@material-ui/icons/Cancel';
import { WS_PARAMETERS_LENGTH } from '../MedicalData/Phones/Utils/phonesUtilsFunctions';
import { handleGoToPersonalData, showMessageModal } from "../../utils/utilsFunctions";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const FormContactData = ({ handleBack }) => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const [disabled, setDisabled] = useState(true);
    const fullPersonalData = useSelector(state => state.personalDataReducer);
    const [phones, setPhones] = useState([]);
    const [mails, setMails] = useState([]);
    const [error, setError] = useState([]);
    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const typeOfPhone = useSelector(state => state.personalDataReducer?.phones?.tipoContacto);
    const typeOfMail = useSelector(state => state.personalDataReducer?.mails?.tipoContacto);
    const phonesOrder = phones?.sort((a, b) => a?.telefonoTipoCodigo - b?.telefonoTipoCodigo);
    const mailsOrder = mails?.sort((a, b) => a?.mailTipoCodigo - b?.mailTipoCodigo);

    const [personalData, setPersonalData] = useState([]);
    const [modelABM, setModelABM] = useState(personalData);
    const [phonesABM, setPhonesABM] = useState({
        telefonos: [],
        mails: [],
    });

    const [modalTitle, setModalTitle] = useState('Enviado correctamente');
    const [modalDescription, setModalDescription] = useState("Los cambios han sido realizados.");
    const [modalIcon, setModalIcon] = useState(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const errorMessage = useSelector(state => state.personalDataReducer.msgError);
    const [errorMessageModal, setErrorMessageModal] = useState(false);

    useEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        if (errorMessage !== "" && errorMessage !== undefined) {
            setErrorMessageModal(true);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (fullPersonalData?.fullData?.length > 0 && !fullPersonalData?.fullData?.confirmacion) {
            setModalTitle('Ocurrió un error');
            setModalDescription(fullPersonalData?.fullData?.mensajes[0]);
            setModalIcon(<CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
        }
    }, [fullPersonalData]);

    useEffect(() => {
        if (Object.values(fullPersonalData?.fullData).length > 0) {
            setModelABM(fullPersonalData?.fullData?.afiliado);
            setPersonalData(fullPersonalData?.fullData?.afiliado);
            let auxMails = fullPersonalData?.fullData?.afiliado?.mails;
            let auxPhones = fullPersonalData?.fullData?.afiliado?.telefonos;
            auxMails?.forEach(mail => {
                mail.mailModo = "M";
            });
            auxPhones?.forEach(phone => {
                phone.telefonoModo = "M";
            });
            setPhones(auxPhones);
            setMails(auxMails);
            setLoading(false);
        }
    }, [fullPersonalData?.fullData?.afiliado]);

    const handleAddPhones = (codeType, description) => (e) => {
        e.preventDefault();
        let auxPhones = phones ?? [];
        let PhonesToAdd = [{
            telefonoTipoCodigo: codeType,
            telefonoTipoDescripcion: description,
            paisCodigo: "",
            areaCodigo: "",
            numero: "",
            interno: 0,
            telefono: "",
            telefonoModo: 'A'
        }];
        let phone = [...auxPhones, ...PhonesToAdd];
        setPhones(phone);
    };

    function AddToABMModel(codeName, modo, value, abmCollection, collectionToUse, modoValue, item) {
        var abmAux = abmCollection;
        const abmObj = {
          [codeName]: value,
          [modo]: modoValue,
          ["paisCodigo"]: item.paisCodigo,
          ["areaCodigo"]: item.areaCodigo,
          ["numero"]: item.numero,
          ["interno"]: 0,
        };
        abmAux?.push(abmObj);
        setPhonesABM({ ...phonesABM, collectionToUse: abmAux });
    }

    const handleRemovePhones = (codeType, description, item) => (e) => {
        e.preventDefault();
        const auxPhones = phones ?? [];
        const PhonesToAdd = [{
            telefonoTipoCodigo: codeType,
            telefonoTipoDescripcion: description,
            telefonoModo: 'B'
        }];
        const newPhones = auxPhones.filter(x => x.telefonoTipoCodigo !== PhonesToAdd[0].telefonoTipoCodigo);
        setPhones(newPhones);

        setModelABM({ ...personalData, telefonos: newPhones });
        AddToABMModel(
            "telefonoTipoCodigo",
            "telefonoModo",
            PhonesToAdd[0].telefonoTipoCodigo,
            phonesABM?.telefonos,
            phones,
            "B", item
        );

        if (validate(newPhones) && validate(mails)) {
            var form = modelABM;
            form.documentos = [];
            form.domicilios = [];
            form.deportes = [];
            form.hobbies = [];
            form.adjuntos = [];
            form.telefonos = phonesABM.telefonos ;
            form.mails = [];
            delete form.cuentasReintegro;
            const modelToSend = {
                informacion: form,
                numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
            };
            dispatch(abmFullData(modelToSend));
            setOpenConfirm(prevState => !prevState);
        }
    };

    const handleAddMails = (codeType, description) => (e) => {
        e.preventDefault();
        let auxMails = mails ?? [];
        let MailsToAdd = [{
            mailTipoCodigo: codeType,
            mailTipoDescripcion: description,
            mail: '',
            mailModo: 'A'
        }];
        let mail = [...auxMails, ...MailsToAdd];
        setMails(mail);
    };

    function AddMailsToABMModel(codeName, modo, value, abmCollection, collectionToUse, modoValue, item) {
        var abmAux = abmCollection;
        const abmObj = {
          [codeName]: value,
          [modo]: modoValue,
          ["mail"]: item.mail,
        };
        abmAux?.push(abmObj);
        setPhonesABM({ ...phonesABM, collectionToUse: abmAux });
    }

    const handleRemoveMails = (item) => (e) => {
        e.preventDefault();
        const auxMails = mails ?? [];
        const mailsToRemove = [{
            mailTipoCodigo: item.mailTipoCodigo,
            mail: item.mail,
            mailModo: 'B'
        }];
        const newMails = auxMails.filter(x => x.mailTipoCodigo !== mailsToRemove[0]?.mailTipoCodigo);
        setMails(newMails);

        setModelABM({ ...personalData, mails: newMails });
        AddMailsToABMModel(
            "mailTipoCodigo",
            "mailModo",
            mailsToRemove[0]?.mailTipoCodigo,
            phonesABM?.mails,
            mails,
            "B", item
        );

        if (validate(phones) && validate(newMails)) {
            var form = modelABM;
            form.documentos = [];
            form.domicilios = [];
            form.deportes = [];
            form.hobbies = [];
            form.adjuntos = [];
            form.mails = phonesABM?.mails;
            form.telefonos = [];
            delete form.cuentasReintegro;
            const modelToSend = {
                informacion: form,
                numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
            };
            dispatch(abmFullData(modelToSend));
            setOpenConfirm(prevState => !prevState);
        }
    };

    const handleChangeInputPhones = (position) => (e) => {
        let auxPhones = phones;
        const { name, value } = e.target;
        auxPhones[position][name] = value;
        setPhones(auxPhones);
    };

    const handleChangeInputMails = (position) => (e) => {
        let auxMails = mails;
        const { name, value } = e.target;
        auxMails[position][name] = value;
        setMails(auxMails);
    };

    const handleEdit = () => {
        setDisabled(prevState => !prevState);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate(phones) && validate(mails)) {
            let form = modelABM;
            form.documentos = [];
            form.domicilios = [];
            form.deportes = [];
            form.hobbies = [];
            form.adjuntos = [];
            let auxTelefonos = phones;
            let auxMails = mails;
            auxTelefonos.forEach(phone => {
                delete phone.telefonoTipoDescripcion;
                delete phone.telefono;
            });
            auxMails.forEach(mail => {
                delete mail.mailTipoDescripcion;
            });
            form.mails = auxMails;
            form.telefonos = auxTelefonos;
            delete form.cuentasReintegro;
            let modelToSend = {
                informacion: form,
                numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
            };
            dispatch(abmFullData(modelToSend));
            setOpen(prevState => !prevState);
            setOpenConfirm(prevState => !prevState);
        }
        setDisabled(prevState => !prevState);
    };

    const handleClickModal = () => {
        if (validate(phones) && validate(mails)) {
            const telefonos = phonesABM.telefonos;
            setModelABM({ ...modelABM, telefonos, mails });
            setOpen(prevState => !prevState);
        }
    };

    function validate(object) {
        let temp = object;
        let aux = [];
        let validation = [];
        Object.values(temp).forEach((item, i) => {
            let Obj = {};
            if (item.telefonoTipoCodigo === typeOfPhone[0]?.tipoContactoCodigo) {
                Obj.paisCodigo = item.paisCodigo ? '' : '*Campo obligatorio';
                Obj.areaCodigo = item.areaCodigo ? '' : '*Campo obligatorio';
                Obj.numero = item.numero ? '' : '*Campo obligatorio';
            }
            if (item.mailTipoCodigo === typeOfMail[0]?.tipoContactoCodigo) {
                Obj.mail = item.mail ? '' : '*Campo obligatorio';
            }
            aux.push(Obj);
            if (Object.values(aux[i]).every((x) => x === "")) {
                validation.push(true);
            } else {
                validation.push(false);
            }
        });
        setError(aux);
        return Object.values(validation).every((valid) => valid === true);
    }

    const customsButtons = () => {
        if (disabled) {
            return (
                <div style={{position: "absolute", right: '4%'}}>
                    <CustomButton
                        title="EDITAR"
                        customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                        onClick={handleEdit}
                        style={{ marginTop: "30px" }}
                    />
                </div>
            );
        }
        else {
            return (
                <div style={{position: "absolute", right: '4%'}}>
                    <CustomButton
                        title="CANCELAR"
                        customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                        onClick={handleEdit}
                        style={{ marginBottom: "10px", marginTop: "30px", marginLeft: "30px" }}
                    />
                    <CustomButton
                        customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                        style={{ padding: '5px 20px 5px 20px', display: "block", marginTop: "20px !important", marginLeft: "30px" }}
                        title='GUARDAR'
                        onClick={handleClickModal}
                    />
                </div>
            );
        }
    };

    const customsIcons = () => {
        if (disabled) {
            return (
                <Button onClick={handleEdit} style={{ position: 'absolute', right: '5%'}}>
                    <EditIcon className={`${classes.ShowMoreArrowIcon}`}  style={{ marginTop: screen.sWidth < 768 ? "13px" : "30px" }} />
                </Button>
            );
        }
    };

    const showsInputs = (require, label, item, telefonoTipoDescripcion, telefonoTipoCodigo, opt, i) => {
        if (disabled) {
            return (
                <PhonesInput
                    require={require}
                    defaultValue={`${item?.paisCodigo}  ${item?.areaCodigo}  ${item?.numero}`}
                    disabled={disabled}
                    label={label} />
            );
        }
        else {
            return (inputEdit(item, telefonoTipoDescripcion, telefonoTipoCodigo, opt, i));
        }
    };

    const inputEdit = (item, telefonoTipoDescripcion, telefonoTipoCodigo, number, i) => {
        return (
            <>
                <div style={{ display: "flex" }}>
                    <InputLabel style={{ marginLeft: "6px", marginTop: "0" }} shrink htmlFor="bootstrap-input">
                        <Typography style={{ textTransform: "uppercase" }} >
                            {telefonoTipoDescripcion}
                            {number ?
                                <>
                                    <span style={{ color: '#ff0000', textTransform: "none" }}>*</span>
                                    <span style={{ margin: "0px !important", fontSize: "15px", textTransform: "none" }} className={`${classes.legendSubtitleCel}`}>
                                        Ingresar número sin el 15
                                    </span>
                                </>
                                :
                                ""}
                        </Typography>
                    </InputLabel>
                    <div className={classes.inputContactDataBeforeCountryCode}>
                        <PhonesInput
                            name="paisCodigo"
                            defaultValue={item?.paisCodigo}
                            onChange={handleChangeInputPhones(i)}
                            disabled={disabled}
                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                            inputProps={{ maxLength: WS_PARAMETERS_LENGTH.CodigoPais }}
                            label="Cód. país"
                            style={{ marginBottom: "10px" }}
                        />
                    </div>
                    <div className={classes.inputContactDataBefore}>
                        <PhonesInput
                            name="areaCodigo"
                            defaultValue={item?.areaCodigo}
                            onChange={handleChangeInputPhones(i)}
                            disabled={disabled}
                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                            inputProps={{ maxLength: WS_PARAMETERS_LENGTH.CodigoArea }}
                            label="Cód. área"
                            style={{ marginBottom: "10px", marginRight: "10px" }}
                        />
                    </div>
                    <div className={classes.inputContactData}>
                        <PhonesInput
                            name="numero"
                            defaultValue={item?.numero}
                            onChange={handleChangeInputPhones(i)}
                            disabled={disabled}
                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                            inputProps={{ maxLength: WS_PARAMETERS_LENGTH.TelefonoNumero }}
                            label="Teléfono"
                            style={{ marginBottom: "10px" }}
                        />
                        {error?.map((x) => (
                            (telefonoTipoCodigo === typeOfPhone[0].tipoContactoCodigo &&
                                < FormHelperText className={classes.errorText}>{x.paisCodigo || x.areaCodigo || x.numero}</FormHelperText>
                            )))}
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <FormHeader title='Datos de contacto' handleGoTo={handleGoToPersonalData} />
            {
                screen.sWidth > 768 &&
                <Divider className={classes.formDivider} />
            }
            {loading ?
                <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
                :
                <>
                <form onSubmit={handleSubmit} className={classes.contactDataForm}>
                {
                    screen.sWidth > 768 &&
                    customsButtons()
                }
                {
                    screen.sWidth <= 768 &&
                    customsIcons()
                }
                    <div className={classes.divFormContactData}  style={{marginTop: screen.sWidth < 768 ? "-40px" : '1px'}}>
                        <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.subtitleAddressForm} ${classes.contactDataTitles}`} style={{marginTop: screen.sWidth < 768 ? "50px" : '30px'}}>
                            TELEFONOS
                        </Typography>
                        {phones?.map((item, i) => (
                            <Fragment key={i}>
                                {item?.telefonoTipoCodigo === typeOfPhone?.[0]?.tipoContactoCodigo &&
                                    <FormControl
                                        className={`${classes.formControlContactData}`}
                                        variant="standard"
                                        style={{marginBottom:'10px', marginTop: screen.sWidth < 768 ?"-20px" : "1px"}}
                                    >
                                        {showsInputs(false, 'PARTICULAR', item, item?.telefonoTipoDescripcion, item?.telefonoTipoCodigo, false, i)}
                                    </FormControl>
                                }
                                {item?.telefonoTipoCodigo === typeOfPhone?.[1]?.tipoContactoCodigo &&
                                    <div
                                        style={{ display: "flex", alignItems: "center", width: "100%" }}
                                    >
                                        <FormControl className={classes.formControlContactData} variant="standard" style={{marginTop: screen.sWidth  <768 ? "2px" : '4px'}} >
                                            {showsInputs(false, 'LABORAL', item, item?.telefonoTipoDescripcion, item?.telefonoTipoCodigo, false, i)}
                                        </FormControl>
                                        {
                                            disabled &&
                                            <div style={{ justifyContent: 'center', marginTop: screen.sWidth <768 ? "45px" : "50px", marginLeft: "1px" }}>
                                                <Button
                                                    onClick={handleRemovePhones(item?.telefonoTipoCodigo, item?.telefonoTipoDescripcion, item)}
                                                >
                                                    <DeleteOutlineIcon
                                                        className={classes.ShowMoreArrowIcon}
                                                        style={{ color: '#002858' }}
                                                    />
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                }
                                {item?.telefonoTipoCodigo === typeOfPhone?.[2]?.tipoContactoCodigo &&
                                    <div style={{ display: 'flex', alignItems: 'center', width: "100%" }}>
                                        <FormControl className={classes.formControlContactData} variant="standard" style={{marginTop:'10px'}}  >
                                            {showsInputs(false, 'MOVIL', item, item?.telefonoTipoDescripcion, item?.telefonoTipoCodigo, false, i)}
                                        </FormControl>
                                        {
                                            disabled &&
                                            <div style={{ justifyContent: "center", marginTop: screen.sWidth <768 ? "54px" : "50px", marginLeft: "1px" }}>
                                                <Button onClick={handleRemovePhones(item?.telefonoTipoCodigo, item?.telefonoTipoDescripcion, item)}>
                                                    <DeleteOutlineIcon className={classes.ShowMoreArrowIcon} style={{ color: "#002858" }} />
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    screen.sWidth <= 768 ?
                                    (item?.telefonoTipoCodigo === typeOfPhone?.[3]?.tipoContactoCodigo &&
                                        <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" ,  marginTop: screen.sWidth < 768 ? "13px" : "1px"}}>
                                            <FormControl className={`${classes.formControlContactData} 
                                                                     ${classes.formControlContactDataParticularPhone}`}
                                                        variant="standard"
                                            >
                                                {showsInputs(false, 'EMERGENCIAS', item, item?.telefonoTipoDescripcion, item?.telefonoTipoCodigo, false, i)}
                                                <Typography
                                                    className={`${classes.legendSubtitleForm}`} style={{ margin: "10px 0 20px 20px" }}
                                                >
                                                    Este número de telefono sirve, en caso de querer <br />la obra social contactar a un 3ero por una emergencia
                                                </Typography>
                                            </FormControl>
                                            {
                                                disabled &&
                                                <div style={{ justifyContent: 'center', marginTop: screen.sWidth <768 ? "-53px" : '-50px', marginLeft: '-20px' }}>
                                                    <Button
                                                        onClick={
                                                            handleRemovePhones(item?.telefonoTipoCodigo, item?.telefonoTipoDescripcion, item)
                                                        }
                                                    >
                                                        <DeleteOutlineIcon className={classes.ShowMoreArrowIcon} style={{ color: '#002858' }} />
                                                    </Button>
                                                </div>
                                            }
                                        </div>
                                    )
                                    :
                                    (item?.telefonoTipoCodigo === typeOfPhone?.[3]?.tipoContactoCodigo &&
                                        <div style={{ display: "flex", alignItems: "center", width: "100%", marginTop:"10px"}}>
                                            <FormControl className={`${classes.formControlContactData} ${classes.formControlContactDataParticularPhone}`} variant="standard">
                                                {showsInputs(false, 'EMERGENCIAS', item, item?.telefonoTipoDescripcion, item?.telefonoTipoCodigo, false, i)}
                                                <Typography className={`${classes.legendSubtitleForm}`} style={{ margin: "10px 0 20px 20px" }}>
                                                    Este número de telefono sirve, en caso de querer <br />la obra social contactar a un 3ero por una emergencia
                                                </Typography>
                                            </FormControl>
                                            {
                                                disabled &&
                                                <div style={{ justifyContent: 'center', marginTop: "-20px", marginLeft: "-30px" }}>
                                                    <Button onClick={handleRemovePhones(item?.telefonoTipoCodigo, item?.telefonoTipoDescripcion, item)}>
                                                        <DeleteOutlineIcon className={classes.ShowMoreArrowIcon} style={{ color: '#002858' }} />
                                                    </Button>
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                                {item?.telefonoTipoCodigo === typeOfPhone?.[5]?.tipoContactoCodigo &&
                                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <FormControl className={classes.formControlContactData} variant="standard" style={{marginTop:'-10px'}} >
                                            {showsInputs(false, 'CONFIRMACION DE TURNOS', item, item?.telefonoTipoDescripcion, item?.telefonoTipoCodigo, false, i)}
                                        </FormControl>
                                        {
                                            disabled &&
                                            <div style={{ marginTop: screen.sWidth <768 ? "36px" : "40px", justifyContent: 'center' }}>
                                                <Button onClick={handleRemovePhones(item?.telefonoTipoCodigo, item?.telefonoTipoDescripcion, item)}>
                                                    <DeleteOutlineIcon style={{ color: '#002858' }} className={classes.ShowMoreArrowIcon} />
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                }
                            </Fragment>
                        ))}
                        {!disabled &&
                            typeOfPhone?.filter( y => y.tipoContactoCodigo !== 5)?.map((item, i) => (
                                <Fragment key={i}>
                                    {!phones?.some(x => x.telefonoTipoCodigo === item?.tipoContactoCodigo) &&
                                        <> <div style={{ justifyContent: 'flex-start', width: '100%' }}>
                                            <Button
                                                style={{ marginTop: "10px" }}
                                                onClick={handleAddPhones(item?.tipoContactoCodigo, item?.tipoContactoDescripcion)}
                                            >
                                                Agregar {item?.tipoContactoDescripcion}
                                                <ControlPointIcon style={{ marginLeft: "10px" }} className={classes.ShowMoreArrowIcon} />
                                            </Button>
                                        </div>
                                            <Divider className={classes.formDivider} />
                                        </>
                                    }
                                </Fragment>
                            ))}

                    </div>
                    <Divider orientation="vertical" className={classes.dividerVertical} flexItem />
                    <div className={classes.divFormContactData} style={{marginTop: screen.sWidth < 768 ? "-80px" : '15px'}}>
                        <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.subtitleAddressForm} ${classes.contactDataTitles}`} >
                            CORREO ELECTRONICO
                        </Typography>
                        {mails?.map((item, i) => (
                            <Fragment key={i}>
                                {(typeOfMail?.length > 0 && item?.mailTipoCodigo === typeOfMail?.[0]?.tipoContactoCodigo) &&
                                    <FormControl className={classes.formControlContactData} variant="standard" style={{marginTop: screen.sWidth <768 ? "-20px" : '1px'}} >
                                        <PhonesInput
                                            require={true}
                                            name="mail"
                                            defaultValue={item?.mail}
                                            onChange={handleChangeInputMails(i)}
                                            disabled={disabled}
                                            label='PARTICULAR' />
                                        < FormHelperText className={classes.errorText}>{error[0]?.mail}</FormHelperText>
                                    </FormControl>
                                }
                                {(typeOfMail?.length > 0 && item?.mailTipoCodigo === typeOfMail?.[1]?.tipoContactoCodigo) &&
                                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <FormControl className={classes.formControlContactData} variant="standard" style={{marginTop: screen.sWidth <768 ? "-4px" : '1px'}} >
                                            <PhonesInput
                                                name="mail"
                                                defaultValue={item?.mail}
                                                onChange={handleChangeInputMails(i)}
                                                disabled={disabled}
                                                label='LABORAL' />
                                        </FormControl>
                                        {
                                            disabled &&
                                            <div style={{ justifyContent: 'center', marginTop: "45px", marginLeft: "1px" }}>
                                                <Button onClick={handleRemoveMails(item)} >
                                                    <DeleteOutlineIcon className={classes.ShowMoreArrowIcon} style={{ color: '#002858' }} />
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                }
                                {(typeOfMail?.length > 0 && item?.mailTipoCodigo === typeOfMail?.[2]?.tipoContactoCodigo) &&
                                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <FormControl className={classes.formControlContactData} variant="standard" style={{marginTop:'10px'}}>
                                            <PhonesInput
                                                name="mail"
                                                defaultValue={item?.mail}
                                                onChange={handleChangeInputMails(i)}
                                                disabled={disabled}
                                                label='CONFIRMACION DE TURNOS' />
                                        </FormControl>
                                        {
                                            disabled &&
                                            <div style={{ justifyContent: 'center', marginLeft: "1px" }}>
                                                <Button onClick={handleRemoveMails(item)} style={{ marginTop: "50px"}}>
                                                    <DeleteOutlineIcon className={classes.ShowMoreArrowIcon} style={{ color: '#002858' }} />
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                }
                                {(typeOfMail?.length > 0 && item?.mailTipoCodigo === typeOfMail?.[3]?.tipoContactoCodigo) &&
                                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <FormControl className={classes.formControlContactData} variant="standard" style={{marginTop:'10px'}}>
                                            <PhonesInput
                                                name="mail"
                                                defaultValue={item?.mail}
                                                onChange={handleChangeInputMails(i)}
                                                disabled={disabled}
                                                label='DE FACTURACION' />
                                        </FormControl>
                                        {
                                            disabled &&
                                            <div style={{ justifyContent: 'center', marginTop: "53px", marginLeft: "1px" }}>
                                                <Button onClick={handleRemoveMails(item)}>
                                                    <DeleteOutlineIcon className={classes.ShowMoreArrowIcon} style={{ color: '#002858' }} />
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                }
                                {(typeOfMail?.length > 0 && item?.mailTipoCodigo === typeOfMail?.[4]?.tipoContactoCodigo) &&
                                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <FormControl className={classes.formControlContactData} variant="standard">
                                            <PhonesInput
                                                name="mail"
                                                defaultValue={item?.mail}
                                                onChange={handleChangeInputMails(i)}
                                                disabled={disabled}
                                                label='COMUNICACIONES' />
                                            <Typography className={`${classes.legendSubtitleForm}`}>
                                                Este mail sirve para recibir las novedades de LUIS PASTEUR<br /> relacionadas con actividades, cursos, cierres de los centros medicos, campañas de vacunacion.
                                            </Typography>
                                        </FormControl>
                                        {
                                            disabled &&
                                            <div style={{ justifyContent: 'center', marginTop: "-30px", marginLeft: "-20px" }}>
                                                <Button onClick={handleRemoveMails(item)}>
                                                    <DeleteOutlineIcon className={classes.ShowMoreArrowIcon} style={{ color: '#002858' }} />
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                }
                            </Fragment>
                        ))}
                        {!disabled &&
                            typeOfMail?.map((item, i) => (
                                <Fragment key={i}>
                                    {!mails?.some(x => x.mailTipoCodigo === item?.tipoContactoCodigo) &&
                                        <> <div style={{ justifyContent: 'flex-start', width: '100%' }}>
                                            <Button
                                                onClick={handleAddMails(item?.tipoContactoCodigo, item?.tipoContactoDescripcion)}
                                                style={{ marginTop: "10px" }}
                                            >
                                                Agregar {item?.tipoContactoDescripcion}
                                                <ControlPointIcon
                                                 className={classes.ShowMoreArrowIcon}
                                                 style={{ marginLeft: "10px" }}
                                                />
                                            </Button>
                                        </div>
                                            <Divider className={classes.formDivider} />
                                        </>
                                    }
                                </Fragment>
                            ))}
                        <div className={`${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                            {screen.sWidth <= 768 && !disabled ?
                                <div style={{ marginTop: "10px", marginBottom: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CustomButton
                                        title="CANCELAR"
                                        customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                                        onClick={handleEdit}
                                    />
                                    <CustomButton
                                        customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                                        title='GUARDAR'
                                        onClick={handleClickModal}
                                    />
                                </div>
                                : ''
                            }
                        </div>
                        <SaveModal isOpen={open} setOpen={setOpen} type='submit' onClick={handleSubmit}
                            titleModal={
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <HelpOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: "20px" }}>
                                        Se están por modificar tus datos
                                    </div>
                                </>
                            }
                            descriptionModal="¿Estás seguro que deseas confirmar los cambios?"
                        />
                    </div>
                </form>
                </>
            }
            <ConfirmModal isOpen={openConfirm} setOpen={setOpenConfirm}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalIcon}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalTitle}
                        </div>
                    </>
                }
                description={modalDescription} />
            {showMessageModal(
                errorMessage,
                errorMessageModal,
                setErrorMessageModal,
                classes.borderWhite,
                classes.quickAccessIcon,
                classes.contentDialogTitle,
                handleGoToPersonalData
            )}
        </>
    );
};

export default FormContactData;