import * as actionTypes from '../consts/actionTypes';
import {responseToReturn} from '../../utils/utilsFunctions.js';

const initialState = {
    capsAndCopayLink: [],
    msgError: ''
};

export default function capsAndCopayLinksReducer(state = initialState, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_GET_CAPANDCOPAYLINK_BY_PLANCODE]: responseToReturn('capsAndCopayLink', state, action),
        [actionTypes.FAILURE_GET_CAPANDCOPAYLINK_BY_PLANCODE]: responseToReturn('', state, action)
    };

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}