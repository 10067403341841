import CustomButton from "../../../controls/Button/CustomButton";
import useStyles from '../../../assets/styles/styles';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import { Accordion, AccordionSummary, AccordionDetails, FormControl, Typography, IconButton, FormHelperText } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import CustomizedInputs from "../../../controls/InputCustom/CustomizedInputs";
import { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import BadgeNotification from "../../Notification/BadgeNotification";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CustomSelect from "../../../controls/Select/CustomSelect";
import UploadModal from "../../../controls/UploadModal/UploadModal";
import ConfirmModal from "../../PersonalData/ConfirmModal";
import { deleteFile, validate } from "../../../utils/utilsFunctions";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import { useDispatch, useSelector } from "react-redux";
import ModalActivities from "../../DialogActivities/ModalActivities";
import CancelIcon from '@material-ui/icons/Cancel';
import NotFound from "../../HomeLogged/NotFound";
import FrequentQuestionsButton from "../../Authorizations/FrequentQuestionsButtons";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import {
    getRefundTypeAttached,
    pushRefundComments,
    getRefundAttachments,
    getRefundComments,
    postRefundAttached,
    postReadRefundComment,
    getDetailsAttachments
} from "../../../redux/actions/refundsActions";
import { CircularProgress } from "@mui/material";
import FileDownloadIcon from '@material-ui/icons/FileDownload';
import LineBreakInput from "../../../controls/InputCustom/LineBreakInput";

const Comments = ({ item, detailsParameters }) => {
    const dispatch = useDispatch();
    const { screen } = useResizeScreen();
    const inputFile = useRef();
    const classes = useStyles();
    const [openTxtComment, setOpenTxtComment] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [txtComment, setTxtComment] = useState('');
    const [fileButtons, setFileButtons] = useState([]);
    const [files, setFiles] = useState([]);
    const [modalDescription, setModalDescription] = useState("");
    const [modalTitle, setModalTitle] = useState('');
    const [errorClass, setErrorClass] = useState('');
    const [modalIcon, setModalIcon] = useState('');
    const refundsSelector = useSelector(state => state.refundsReducer);
    const [error, setError] = useState([]);
    const [openSendSuccess, setOpenSendSuccess] = useState(false);
    const refundState = useSelector(
        state => state.refundsReducer?.refundDetail?.reintegroDetalle[0]?.refundEstadoDescripcion
    );
    const [typeAttachedSelected, setTypeAttachedSelected] = useState(0);
    const [listTypeAttached, setListTypeAttached] = useState([{ value: 0, label: '' }]);
    const [listAttached, setListAttached] = useState([]);
    const [txtObservation, setTxtObservation] = useState('');
    const [hide, setHide] = useState(false);
    const [openModalError, setOpenModalError] = useState(false);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);
    const [msgErrorAttachments, setMsgErrorAttachments] = useState('');
    const [closeCommentsAccordion, setCloseCommentsAccordion] = useState(false);
    const [closeAttachedAccordion, setCloseAttachedAccordion] = useState(false);
    const [repeatFile, setRepeatFile] = useState(false);
    const [fileToDownload, setFileToDownload] = useState({});
    const downloadAttachments = useSelector(state => state.refundsReducer.detailsAttachments);
    const loadingDownload = useSelector((state) => state.refundsReducer.loadingDownload);
    const [openDownloadModal, setOpenDownloadModal] = useState(false);
    const [openLoadingModal, setLoadingModal] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [ErrorDownloadAttached, setErrorDownloadAttached] = useState(false);
    const [cancelAttachedDownload, setCancelAttachedDownload] = useState(false);
    const [counterLength, setCounterLength] = useState(0);
    const [counterObservationLength, setCounterObservationLength] = useState(0);



    const obj = {
        numeroPersona: detailsParameters,
        gestionCodigo: item?.refundDetails?.reintegroDetalle?.[0]?.reintegroGestionCodigo,
        reintegroNumero: item?.refundDetails?.reintegroDetalle?.[0]?.reintegroNumero,
        delegacionCodigo: item?.refundDetails?.reintegroDetalle?.[0]?.reintegroDelegacionCodigo
    };

    const commentsAttachmentsStyles = {
        downloadRefundsButton: {
            marginRight: "20px",
            marginBottom: screen.sWidth < 768 ? "20px" : "5px",
            width: "auto",
            fontSize: "7px",
            float: 'right',
            fontWeight: '600'
        },

        titleModal: {
            justifyContent: 'center',
            display: 'flex',
            marginTop: '20px',
            width: '250px'
        },

        descriptionModal: {
            display: 'flex',
            margin: '5px',
            justifyContent: 'center'
        },

        commentsAccordionIcon: {
            marginRight: "5px",
            backgroundColor: '#0075c9',
            color: '#ffffff',
            borderRadius: '15px',
            fontSize: '30px',
            padding: '5px'
        },

        blueCircle: {
            width: '10px',
            height: '10px',
            position: 'absolute',
            backgroundColor: '#0075c9',
            padding: '12px',
            borderRadius: '20px',
            marginTop: '10px'
        },

        commentsDate: {
            paddingLeft: '30px',
            display: 'flex',
            paddingTop: '10px',
            paddingBottom: '10px'
        },

        commentContainer: {
            marginLeft: '12px',
            paddingLeft: '20px',
            paddingRight: '8px',
            borderLeft: '1px solid #bdbdbd'
        },

        commentAndPerspective: {
            borderRadius: '10px',
            minHeight: '150px',
            padding: '10px',
            backgroundColor: '#FEDCDC',
            minWidth: '180px'
        },

        perspective: {
            fontSize: '16px',
            fontWeight: "500",
            color: '#0075c9'
        },

        commentsAccordionContainer: {
            width: '100%',
            justifyContent: 'flex-start',
            flexDirection: 'column-reverse',
            paddingTop: '10px'
        },

        attachmentsSumaryContainer: { width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" },
    };

    const handleChangeText = (e) => {
        const { name, value } = e.target;
        if (name === 'observacion') {
            setTxtObservation(value);
            setFiles([{ ...files[0], archivoObservacion: value }]);
        } else {
            setTxtComment(value);
        }
        if (value.length <= 1000) {
            setCounterLength(value.length);
        }
    };

    const handleChangeObservations= (e) => {
        const { name, value } = e.target;
        if (name === 'observacion') {
            setTxtObservation(value);
            setFiles([{ ...files[0], archivoObservacion: value }]);
        } else {
            setTxtComment(value);
        }
            if (value.length <= 200) {
                setCounterObservationLength(value.length);
            }
    }

    const getAttachmentTypeDescription = attachmentCode => {
        let description = "";
        if (attachmentCode !== undefined) {
            const auxDescription = refundsSelector?.refundTypeAttached?.tipoAdjunto?.filter(x => x.tipoAdjuntosCodigo === attachmentCode);
            if (typeof (auxDescription) !== 'undefined') {
                if (auxDescription[0]?.tipoAdjuntosDescripcion !== undefined) {
                    description = auxDescription[0]?.tipoAdjuntosDescripcion;
                } else {
                    description = "Sin observaciones";
                }
            }
        }
        return description;
    }

    const handleOpenTextBox = (e) => {
        e.preventDefault();
        if (refundState !== "Finalizado") {
            setOpenTxtComment(true);
        }
        setCloseCommentsAccordion(false);
        setCloseAttachedAccordion(false);
        setHide(false);
    };

    const handlePushComment = (e) => {
        e.preventDefault();
        let comentarios = {
            numeroPersona: parseInt(detailsParameters),
            gestionCodigo: parseInt(item?.refundDetails?.reintegroDetalle?.[0]?.reintegroGestionCodigo),
            reintegroNumero: parseInt(item?.refundDetails?.reintegroDetalle?.[0]?.reintegroNumero),
            delegacionCodigo: parseInt(item?.refundDetails?.reintegroDetalle?.[0]?.reintegroDelegacionCodigo),
            reintegroComentario: JSON.stringify({
                reintegroComentarioObservacion: txtComment,
                reintegroComentarioPerspectiva: 'A',
                reintegroComentarioPublicar: 'S',
            })
        };
        dispatch(pushRefundComments(comentarios));
        setTxtComment('');
        setOpenTxtComment(prevState => !prevState);
    };

    useEffect(() => {
        getNewComment();
    }, [refundsSelector?.pushRefundComments?.confirmacion])

    const setSeenComments = () => {
        let readComments = obj;
        readComments.perspectiva = "A";
        dispatch(postReadRefundComment(readComments));
    }
    const readCommentIcons = element => {
        const readIcon = <div style={{ float: 'right', color: "#0075c9", display: 'block' }}><DoneAllOutlinedIcon /></div>;
        const unreadIcon = <div style={{ float: 'right', color: "#585858", display: 'block' }}><DoneOutlinedIcon /></div>;
        if (element?.reintegroComentarioPerspectiva === "O" && element?.reintegroComentarioLeido === "S") {
            return readIcon;
        } else if (element?.reintegroComentarioPerspectiva === "O" && element?.reintegroComentarioLeido === "N") {
            return unreadIcon;
        }
    }

    useEffect(() => {
        const timerFunction = setTimeout(getNewComment, 1000);

        return () => clearTimeout(timerFunction);
    }, [refundsSelector?.readRefundComments]);

    const handleAddAttachment = () => {
        if (refundState !== "Finalizado") {
            if (screen.sWidth > 768) {
                inputFile?.current?.click();
            }
            else {
                setOpenModal(prevState => !prevState);
            }
        }
        setCloseCommentsAccordion(false);
        setCloseAttachedAccordion(false);
        setOpenTxtComment(false);
    };

    const handleChangeSelect = () => e => {
        const { name, value } = e.target;
        setTypeAttachedSelected(value);
        setFiles([{ ...files[0], archivoClasificacion: value }]);
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    const handleCloseTxtComment = () => {
        setOpenTxtComment(prevState => !prevState);
    };

    const handleUpload = (e) => {
        let alertGreatSize = "";
        let uploadFiles = e.target.files;
        for (let archive of uploadFiles) {
            let size = (archive.size / 1024);
            if (size <
                parseInt(configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor) * 1000) {
                let adj = {
                    archivoNombre: archive.name.substring(0, archive.name.lastIndexOf(".")),
                    archivoExtension: archive.name.substring(archive.name.lastIndexOf(".") + 1),
                    archivoNombreCompleto: archive.name,
                    archivoTamanio: parseInt(size),//KB
                    archivo: null,//new Blob([file],{type: file.type}),
                    archivoClasificacion: typeAttachedSelected,
                    archivoObservacion: txtComment,
                };

                let repeatFile = listAttached?.filter(x => x?.nombre?.trim() == adj?.archivoNombre?.trim());

                if (repeatFile?.length > 0) {
                    setRepeatFile(true);
                } else {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        adj.archivo = reader.result?.split(',').pop();
                        setFiles([adj]);
                        setHide(true);
                    };
                    reader.readAsDataURL(archive);
                }
            } else {
                alertGreatSize = alertGreatSize + " " + archive.name;
            }
        }
        if (alertGreatSize !== "") {
            setModalTitle("Atención");
            setModalDescription(`Los archivos: ${alertGreatSize} superan el tamaño máximo.
                                Asegurate de que los archivos no superen los ${configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor}MB`);
            setModalIcon(
                <CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
            );
            setErrorClass(classes.backgroundError);
            setOpenSendSuccess(prevState => !prevState);
        }
        e.target.value = null;
    };

    function cleanStates() {
        setHide(false);
        setOpenModalConfirm(false);
    }

    const handleOpenModalConfirm = () => {
        if (validateFiles(files) && validateSelect(typeAttachedSelected)) {
            setOpenModalConfirm(true);
        }
    };

    const handleSubmit = () => {

        
        //If the user doesn't select any option, then archivoClasificacion be will 0 and an error will be generated.
        //If onChange event doesn't run, this line will set default/first choice of the object selection.
        if(files[0].archivoClasificacion === 0){
            files[0].archivoClasificacion = typeAttachedSelected;
        }

        let model = {
            numeroPersona: detailsParameters,
            gestionCodigo: item?.refundDetails?.reintegroDetalle?.[0]?.reintegroGestionCodigo,
            reintegroNumero: item?.refundDetails?.reintegroDetalle?.[0]?.reintegroNumero,
            delegacionCodigo: item?.refundDetails?.reintegroDetalle?.[0]?.reintegroDelegacionCodigo,
            perspectiva: 'A', //afiliado
            adjuntos: JSON.stringify(files)
        };
        dispatch(postRefundAttached(model));
        cleanStates();
    };

    function validateSelect(select) {
        let auxList = [];
        let validationList = [];
        let auxObj = {};
        if (select === 0) {
            auxObj.selectAttached = '*Seleccione otro campo';
        }
        else {
            auxObj.selectAttached = select.toString() ? '' : '*Campo obligatorio';
        }
        auxList = auxObj;
        if (Object.values(auxList).every((x) => x === "")) {
            validationList.push(true);
        } else {
            validationList.push(false);
        }
        setError(auxList);
        return Object.values(validationList).every((valid) => valid === true);
    }

    function getNewComment() {
        dispatch(getRefundComments(obj));
    }

    useEffect(() => {
        dispatch(getRefundTypeAttached("REINTEGRO"));
        if (item?.refundDetails?.reintegroDetalle !== undefined) {
            dispatch(getRefundAttachments(obj));
        }
    }, []);

    useEffect(() => {
        let list = refundsSelector?.refundTypeAttached?.tipoAdjunto?.map((type) => {
            return {
                value: parseInt(type?.tipoAdjuntosCodigo),
                label: type?.tipoAdjuntosDescripcion
            };
        });
        setListTypeAttached(list);
        if (list !== undefined) {
            setTypeAttachedSelected(list[0]?.value);
        }
    }, [refundsSelector.refundTypeAttached]);

    const fillAttachedList = () => {
        if (refundsSelector?.attachments?.reintegroAdjuntos !== undefined) {
            let attachmentsList = refundsSelector?.attachments?.reintegroAdjuntos?.map(attachedFromWs => {
                return {
                    nombre: attachedFromWs?.reintegroAdjuntoNombre !== "" ? attachedFromWs?.reintegroAdjuntoNombre : "Archivo no disponible",
                    tipo: attachedFromWs?.reintegroAdjuntoTipo,
                    extension: attachedFromWs?.reintegroAdjuntoExtension,
                    observacion: attachedFromWs?.observacion ? attachedFromWs?.observacion : undefined,
                    reintegroAdjuntoCodigo: attachedFromWs?.reintegroAdjuntoCodigo
                };
            });
            setListAttached(attachmentsList);
        }
    }

    useEffect(() => {
        fillAttachedList();
    }, [refundsSelector?.attachments?.reintegroAdjuntos, refundsSelector?.postAttachments]);

    useEffect(() => {
        fillAttachedList();
    }, []);

    useEffect(() => {
        if (refundsSelector?.postAttachments?.confirmacion === false) {
            setMsgErrorAttachments(refundsSelector?.postAttachments?.mensajes[0]?.mensaje);
            setOpenModalError(true);
        } else if (refundsSelector?.postAttachments?.confirmacion) {
            let dateNow = Date.now();
            setListAttached([...listAttached,
            {
                nombre: files[0]?.archivoNombre,
                fecha: format(dateNow, "dd/MM/yyyy"),
                tipo: files[0]?.archivoClasificacion,
                observacion: txtObservation
            }]);
        }
    }, [refundsSelector.postAttachments]);

    useEffect(() => {
        if (downloadAttachments?.adjunto !== undefined) {
            setFileToDownload({
                archivo: downloadAttachments?.adjunto?.[0]?.adjunto,
                archivoNombreCompleto: downloadAttachments?.adjunto?.[0]?.adjuntoNombreCompleto
            });
        }
    }, [downloadAttachments]);

    const handleClickModal = item => e => {
        e.preventDefault();
        dispatch(getDetailsAttachments({
            numeroPersona: detailsParameters,
            reintegroAdjuntoCodigo: item?.reintegroAdjuntoCodigo
        }));
    }

    useEffect(() => {
        if (loadingDownload) {
            setLoadingModal(true);
            setInitialLoad(false);
            setCancelAttachedDownload(false);
        } else {
            if (!initialLoad && !cancelAttachedDownload) {
                setOpenDownloadModal(true);
                setLoadingModal(false);
            }
        }

        if((!downloadAttachments?.confirmacion && downloadAttachments?.confirmacion !== undefined) ||
        (downloadAttachments?.adjunto?.[0]?.adjunto === "")) {
            setLoadingModal(false);
            setOpenDownloadModal(false);
            setFileToDownload({});
            setErrorDownloadAttached(true);
        }
    }, [loadingDownload])

    const handleCancelDownload = () => {
        setCancelAttachedDownload(true);
        setLoadingModal(false);
    }

    // Botones "Nuevo comentario" y "Nuevo adjunto"
    const buttonsSection = () => {
        return (
            <section
                style={{ display: 'flex' }}
            >
                <CustomButton
                    title={"Nuevo comentario"}
                    onClick={handleOpenTextBox}
                    icon={<AddCircleIcon style={{ marginRight: "5px" }} />}
                    customClass={`${classes.customHeaderBtn} ${classes.lightLinearGradient} ${classes.txtMobile}`}
                    style={{ height: "25px", fontSize: "9.5px", fontWeight: "600" }}
                />
                <CustomButton
                    customClass={`${classes.customHeaderBtn} ${classes.txtMobile} ${classes.whiteAndBlueBtn}`}
                    title={"Nuevo adjunto"}
                    style={{ height: "25px", fontSize: "9.5px" }}
                    onClick={handleAddAttachment}
                    icon={<AddCircleIcon style={{ marginRight: "5px" }} />}
                />
                {screen.sWidth > 768 && <FrequentQuestionsButton />}
            </section>
        );
    }

    const commentsAccordion = element => {
        return (
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ borderBottom: '1px solid #e2e3e3' }}
                onClick={setSeenComments}
            >
                {element?.count !== undefined && element?.count > 0 ?
                    <BadgeNotification
                        childrenItem={
                            <QuestionAnswerOutlinedIcon
                                style={commentsAttachmentsStyles.commentsAccordionIcon}
                                className={classes.iconFilter}
                            />
                        }
                        countNotification={element?.count}
                    />
                    :
                    <QuestionAnswerOutlinedIcon
                        style={commentsAttachmentsStyles.commentsAccordionIcon}
                        className={classes.iconFilter}
                    />
                }
                <span style={{ paddingLeft: '10px' }}>Comentarios</span>
            </AccordionSummary>
        );
    }

    // Lista de comentarios
    const allComments = () => {
        return (
            <AccordionDetails>
                <div style={commentsAttachmentsStyles.commentsAccordionContainer} className={classes.boxSteps}>
                    {refundsSelector?.comments?.reintegroComentarios !== undefined && refundsSelector?.comments?.reintegroComentarios?.length !== undefined ?
                        (refundsSelector?.comments?.reintegroComentarios?.map((step, i) => (
                            step?.autorizacionComentarioObservacion !== '' ? (
                                <div key={i} style={{ paddingTop: '10px' }}>
                                    <div style={commentsAttachmentsStyles.blueCircle}></div>
                                    <span
                                        style={commentsAttachmentsStyles.commentsDate}
                                        className={classes.stepLabelTab}>
                                        {step?.reintegroComentarioFechaHora?.replace('T', ' ')}
                                    </span>
                                    <section style={commentsAttachmentsStyles.commentContainer}>
                                        <div style={commentsAttachmentsStyles.commentAndPerspective}>
                                            <Typography
                                                style={commentsAttachmentsStyles.perspective}
                                                className={`${classes.txtCardDescription}`}>
                                                {step?.reintegroComentarioPerspectiva === 'O' ? 'OSLP' : 'Afiliado'}
                                            </Typography>
                                            <p style={{ color: '#585858', wordWrap: 'break-word' }}>
                                                {step?.reintegroComentarioObservacion}
                                                {readCommentIcons(step)}
                                            </p>
                                        </div>
                                    </section>
                                </div>
                            ): null
                        ))) :
                        <NotFound
                            customMsg={
                                <div className={classes.contentNotFound}>
                                    <span>No hay contenido para mostrar</span>
                                </div>
                            }
                            wsItem={refundsSelector?.comments?.reintegroComentarios?.length}
                            fullItem={refundsSelector?.comments?.reintegroComentarios?.length}
                        />
                    }
                </div>
            </AccordionDetails>
        );
    }

    const attachmentsAccordion = () => {
        return (
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ borderBottom: '1px solid #e2e3e3' }}
            >
                <AttachFileOutlinedIcon
                    style={commentsAttachmentsStyles.commentsAccordionIcon}
                    className={classes.iconFilter}
                />
                <span style={{ paddingLeft: '10px' }}>Adjuntos</span>
            </AccordionSummary>
        );
    }

    const downloadButton = element => {
        return (
            <CustomButton
                title="DESCARGAR"
                customClass={`${classes.lightLinearGradient}`}
                style={commentsAttachmentsStyles.downloadRefundsButton}
                onClick={handleClickModal(element)}
                disabled={element?.nombre === "Archivo no disponible" ? true : false}
            />
        );
    }

    // Lista de adjuntos
    const allAttachments = () => {
        return (
            <AccordionDetails>
                {listAttached?.length > 0 ?
                    <div className={classes.authorizationsAccordion}>
                        <Typography
                            className={classes.txtSubtitleCalendar}
                            style={{ fontSize: "16px" }}
                        >
                            {"NOMBRE"}
                        </Typography>
                        {listAttached?.map((attached, i) => (
                            <>
                                <Accordion
                                    style={{ width: "100%" }}
                                    key={i}
                                >
                                    <AccordionSummary
                                        id="panel1a-header"
                                        expandIcon={<ExpandMoreIcon />}
                                        classes={{ content: classes.width100 }}
                                        aria-controls="panel1a-content"
                                    >
                                        <div style={commentsAttachmentsStyles.attachmentsSumaryContainer}>
                                            <Typography className={classes.txtEllipsis}>
                                                {attached?.nombre}
                                            </Typography>
                                            <Typography className={classes.txtSubtitleCalendar} style={{ marginRight: '20px' }}>
                                                {attached?.extension}
                                            </Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails >
                                        {
                                            <>
                                                <div className={classes.displayFlex}>
                                                    <Typography
                                                        className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}
                                                        style={{ marginRight: "5px" }}>
                                                        {"Tipo de adjunto:  "}
                                                    </Typography>
                                                    <Typography className={`${classes.txtSubtitleGray} ${classes.txtEllipsis}`}>
                                                        {getAttachmentTypeDescription(attached?.tipo)}
                                                    </Typography>

                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography
                                                        className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}
                                                        style={{ marginRight: "5px" }}>
                                                        { "Observaciones / comentarios: " }
                                                        <span style={{ color: '#666666' }}>
                                                            {attached?.observacion !== undefined ? attached?.observacion : "Sin observaciones"}
                                                        </span>
                                                    </Typography>
                                                    { screen.sWidth > 768 && downloadButton(attached) }
                                                </div>
                                            </>
                                        }
                                    </AccordionDetails>
                                    { screen.sWidth < 768 && downloadButton(attached) }
                                </Accordion>
                            </>
                        ))}
                    </div>
                    :
                    <NotFound
                        wsItem={listAttached?.length}
                        fullItem={listAttached?.length}
                        customMsg={
                            <div className={classes.contentNotFound}>
                                <span>No posee adjuntos</span>
                            </div>
                        }
                    />
                }
            </AccordionDetails>
        );
    }

    // Box agregar nuevo comentario
    const newCommentBox = () => {
        return (
            <section style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: "50px" }}>
                <FormControl style={{ width: '100%', marginTop:'40px', marginLeft: screen.sWidth < 768 ? '20px' : '320px' }} variant="standard">
                    <LineBreakInput
                        formControlClass={classes.txtObservations}
                        inputLabelClass={classes.txtLabelComments}
                        customClass={classes.txtBoxComments}
                        name="consulta"
                        style={{
                            borderRadius: 29,
                            position: 'relative',
                            border: '1px solid #ced4da',
                            fontSize: 16,
                            minWidth: '224px !important',
                            margin: '0 0 7px 0',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: "nowrap",
                        }}
                        label={
                            <>
                                <IconButton style={{ padding: '0', marginTop: "-40px", left: screen.sWidth < 768 ? '-15px' : "115px"  }} onClick={handleCloseTxtComment}>
                                    <CloseRoundedIcon
                                        style={{
                                            marginRight: "5px",
                                            backgroundColor: '#ff0000',
                                            color: '#ffffff',
                                            borderRadius: '15px',
                                            fontSize: '25px'
                                        }}
                                        className={classes.iconFilter}
                                    />
                                </IconButton>
                                <span style={{ marginTop: "-30px", marginLeft: screen.sWidth < 768 ? '-10px' : "120px" }}> Cerrar </span>
                            </>
                        }
                        placeholder='Escribí tu comentario aquí...'
                        onChange={handleChangeText}
                        value={txtComment}
                        multiline
                        rows={5}
                        inputProps={{ maxLength: 1000 }}
                    />
                    <Typography variant="caption" style={{ marginLeft: screen.sWidth < 768 ? "220px" : '450px' }}>{counterLength}/1000</Typography>
                </FormControl>
                <CustomButton title={"ENVIAR"} onClick={handlePushComment}
                    style={{
                        height: "25px",
                        fontSize: "12px",
                        fontWeight: "600",
                        padding: '16px',
                        marginBottom: '20px'
                    }}
                    customClass={`${classes.lightLinearGradient} ${classes.txtMobile}`}
                />
            </section>
        );
    }

    return (
        <>
            {(item?.refundDetails?.reintegroDetalle?.[0]?.reintegroEstadoCodigo == 1 ||
                item?.refundDetails?.reintegroDetalle?.[0]?.reintegroEstadoCodigo == 2) &&
                <div style={{ padding: screen.sWidth > 768 && "0 35px" }}>
                    {buttonsSection()}
                </div>
            }

            {openTxtComment && newCommentBox()}

            {files.length !== 0 && hide &&
                <>
                    <section style={{ display: 'flex', flexDirection: "column", alignItems: "center", marginTop: '40px' }}>

                        {files?.map((file, i) => (
                            <div
                                key={i}
                                style={{ display: "flex", justifyContent: "center", width: "100%" }}
                                className={`${classes.txtSubtitleCalendar}`}
                            >
                                <p className={`${classes.txtEllipsis}`}>
                                    <span style={{ color: '#666666', marginRight: '7px' }}>Nombre:</span>{file?.archivoNombre}
                                </p>
                                <IconButton
                                    onClick={
                                        deleteFile(
                                            fileButtons,
                                            setFileButtons,
                                            setFiles,
                                            files,
                                            file?.btnNombre,
                                            file?.archivoNombre
                                        )
                                    }
                                    style={{ marginTop: '-3px' }}
                                >
                                    <HighlightOffIcon />
                                </IconButton>
                            </div>
                        ))}

                        <FormControl
                            variant="standard"
                            className={classes.widthMobile}
                        >
                            <Typography
                                style={{ fontSize: '13px', margin: '5px 7px' }}
                            >
                                Tipo de archivo
                            </Typography>
                            <CustomSelect
                                name={'selectAttached'}
                                label=''
                                list={listTypeAttached}
                                className={`${classes.selectAuthorizations}`}
                                onChange={handleChangeSelect()}
                                selected={typeAttachedSelected}
                            />
                            <FormHelperText
                                style={{ paddingLeft: '5%' }}
                                className={`${classes.errorText}`}
                            >
                                {error?.selectAttached}
                            </FormHelperText>
                        </FormControl>

                        <FormControl
                            variant="standard"
                            className={classes.widthMobile}
                        >
                            <Typography style={{ fontSize: '13px', margin: '5px 7px' }}>Comentarios</Typography>
                            <LineBreakInput
                                inputLabelClass={classes.txtLabelComments}
                                formControlClass={`${classes.txtObservations}`}
                                customClass={classes.txtBoxObservation}
                                placeholder='Escribí tu comentario aquí...'
                                name="observacion"
                                inputProps={{ maxLength: 200 }}
                                onChange={handleChangeObservations}
                                style={{
                                    borderRadius: 29,
                                    position: 'relative',
                                    border: '1px solid #ced4da',
                                    fontSize: 16,
                                    minWidth: '224px !important',
                                    margin: '0 0 7px 0',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: "nowrap",
                                }}
                                rows={5}
                                multiline
                            />
                             <Typography variant="caption" style={{ marginLeft: screen.sWidth < 768 ? "220px" : '450px' }}>{counterObservationLength}/200</Typography>
                        </FormControl>

                        <CustomButton
                            title={"ADJUNTAR"}
                            onClick={handleOpenModalConfirm}
                            customClass={`${classes.customHeaderBtn} ${classes.btnAttached} ${classes.lightLinearGradient} ${classes.txtMobile}`}
                            style={{
                                height: "25px",
                                fontSize: "12px",
                                fontWeight: "600",
                                padding: '16px'
                            }}
                        />
                    </section>
                </>
            }

            <section style={{ padding: '3%' }}>
                <Accordion style={{ boxShadow: 'none' }} expanded={closeCommentsAccordion} onClick={() => { setCloseCommentsAccordion(prevState => !prevState) }}>
                    {commentsAccordion(item)}
                    {allComments()}
                </Accordion>
                <Accordion style={{ boxShadow: 'none' }} expanded={closeAttachedAccordion} onChange={() => { setCloseAttachedAccordion(prevState => !prevState) }}>
                    {attachmentsAccordion()}
                    <div>{allAttachments()}</div>
                </Accordion>
            </section>

            <ConfirmModal
                isOpen={openSendSuccess}
                setOpen={setOpenSendSuccess}
                errorClass={errorClass}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalIcon}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalTitle}
                        </div>
                    </>
                }
                description={modalDescription}
            />

            {<UploadModal
                description=''
                title='Adjuntar archivo'
                observation=''
                onClose={setOpenModal}
                open={openModal}
                setModalDescription={setModalDescription}
                setModalTitle={setModalTitle}
                setModalIcon={setModalIcon}
                setOpenSendSuccess={setOpenSendSuccess}
                setErrorClass={setErrorClass}
                setFiles={setFiles}
                fileButtons={fileButtons}
                setFileButtons={setFileButtons}
                files={files}
                setHide={setHide}
                hide={true}
                listAttached={listAttached}
                setRepeatFile={setRepeatFile}
            />}

            {
                openModalConfirm &&
                <ModalActivities
                    open={openModalConfirm}
                    title={'¿Estás seguro que deseas adjuntar?'}
                    description={'El archivo que se va a adjuntar es: ' + files[0]?.archivoNombre.slice(0, 20) + '...'}
                    handleClick={handleSubmit}
                    onClose={setOpenModalConfirm}
                    modalconfirm={true}
                    buttonType={'submit'}
                    modalLogout={false}
                />
            }
            <ConfirmModal
                isOpen={openModalError}
                setOpen={setOpenModalError}
                errorClass={errorClass}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            Ocurrió un error al adjuntar el archivo
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            Error: {msgErrorAttachments}
                        </div>
                    </>
                }
                description={modalDescription}
            />

            <ConfirmModal
                isOpen={repeatFile}
                setOpen={setRepeatFile}
                description={"No puede adjuntar dos archivos con el mismo nombre"}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                        </div>
                    </>
                }
            />

            {openDownloadModal &&
                <ModalActivities
                    description={
                        <div style={commentsAttachmentsStyles.descriptionModal}>
                            <a href={`data:image/png;base64,${fileToDownload?.archivo}`} download={fileToDownload?.archivoNombreCompleto}>
                                <IconButton style={{ backgroundColor: '#ffffff', borderRadius: '15px' }}>
                                    <FileDownloadIcon className={classes.actionsIcons} />
                                </IconButton>
                            </a>
                        </div>
                    }
                    title={
                        <div style={commentsAttachmentsStyles.titleModal}>
                            <span>Descargar</span>
                        </div>
                    }
                    onClose={setOpenDownloadModal}
                    open={openDownloadModal}
                    modalLogout={false}
                    modalconfirm={false}
                />
            }

            {openLoadingModal &&
                <ModalActivities
                    description={
                        <div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.whiteCircularProgress}`}>
                            <CircularProgress />
                        </div>
                    }
                    title={
                        <div style={{ marginTop: "30px" }}>Preparando archivo ...</div>
                    }
                    onClose={handleCancelDownload}
                    style={{ textAlign: 'center' }}
                    open={openLoadingModal}
                    modalLogout={false}
                    modalconfirm={false}
                />
            }

            {
                ErrorDownloadAttached &&
                <ModalActivities
                    description={
                        <>
                            <div>Ocurrió un error al intentar obtener el archivo</div>
                            <div>Por favor, intentá de nuevo más tarde</div>
                        </>
                    }
                    style={{ textAlign: 'center' }}
                    modalLogout={false}
                    open={ErrorDownloadAttached}
                    modalconfirm={false}
                    onClose={setErrorDownloadAttached}
                    title={<CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />}
                />
            }

            <input
                ref={inputFile}
                type="file"
                hidden
                id='upFile'
                onChange={handleUpload}
                name='upFileName'
            />
        </>
    );
};

export default Comments;

export const validateFiles = files => {
    let auxArchives = [];
    let validation = false;
    if (auxArchives?.length > 0) {
        let auxList = [];
        files?.forEach(item => {
            if (item?.adjuntoObligatorio === 'S') {
                auxList.push(auxArchives?.some(x => x === item.btnNombre));
            }
        });
        if (auxList.length > 0 && Object.values(auxList)?.every(x => x === true)) {
            validation = true;
        }
    } else {
        validation = true;
    }
    return validation;
};