import FormVitalParameters from "./FormVitalParameters";
import VitalParameters from "./VitalParameters";
import { useState, useEffect } from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import useStyles from "../../../assets/styles/styles";
import ConfirmModal from "../../PersonalData/ConfirmModal";
import SaveModal from "../../PersonalData/SaveModal";
import { ABvitalParameters } from './../../../redux/actions/medicalDataAction';
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from '@material-ui/icons/Cancel';
import { handleGoToPersonalMedicalData, showMessageModal } from '../../../utils/utilsFunctions';

const HomeVitalParameters = () => {
    const classes = useStyles();
    const [add, setAdd] = useState(false);
    const [openSendSuccess, setOpenSendSuccess] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(null);
    const [modalTitle, setModalTitle] = useState('Enviado correctamente');
    const [modalDescription, setModalDescription] =
        useState("Los cambios han sido realizados, una vez validados por nuestro sector correspondiente observarás los cambios en el sistema.");
    const [modalIcon, setModalIcon] = useState(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
    const [errorClass, setErrorClass] = useState('');
    const [form, setForm] = useState({});
    const [action, setAction] = useState("");
    const medicalData = useSelector(state => state.medicalDataReducer);
    const errorMessage = useSelector(state => state.medicalDataReducer?.msgError);
    const [errorMessageModal, setErrorMessageModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (medicalData.ABVital.confirmacion !== undefined) {
            if (medicalData.ABVital.confirmacion) {
                setOpenConfirm(prevState => !prevState);
                const titleModal = action === "ALTA" ? 'Enviado correctamente ' : 'Eliminado correctamente ';
                setModalTitle(titleModal);
                setModalDescription("Los cambios han sido efectuados correctamente.");
                setModalIcon(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
                setErrorClass("");
                setOpenSendSuccess(prevState => !prevState);
                if (action === "ALTA") {
                    setAdd(prevState => !prevState);
                }
                window.location.reload();
            } else {
                setOpenConfirm(prevState => !prevState);
                setModalTitle('Ocurrió un error');
                setModalDescription('Ocurrió un error al enviar los datos.');
                setModalIcon(<CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
                setErrorClass(classes.backgroundError);
                setOpenSendSuccess(prevState => !prevState);
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }
        }
    }, [medicalData.ABVital]);

    useEffect(() => {
        if (errorMessage?.length > 0) {
            setErrorMessageModal(true);
        }
        setMessageModal(setModalTitle, setModalDescription, setModalIcon, setErrorClass, classes, errorMessage);
    }, [errorMessage]);

    const setMessageModal = (setterModalTitle, setterModalDescription, setterModalIcon, setterErrorClass, cssClasses, errorMessageRedux) => {
        if (errorMessageRedux !== '') {
            setterModalTitle('Ocurrió un error');
            setterModalDescription('Ocurrio un error al enviar los datos.');
            setterModalIcon(<CancelIcon className={`${cssClasses.quickAccessIcon} ${cssClasses.personalDataIcon}`} />);
            setterErrorClass(cssClasses.backgroundError);
        }
    };

    const sendForm = () => {
        dispatch(ABvitalParameters(form));
    };

    return (<> {add ? <FormVitalParameters
        add={add}
        setAdd={setAdd}
        setModalTitle={setModalTitle}
        modalDescription={setModalDescription}
        modalIcon={setModalIcon}
        errorClass={errorClass}
        setErrorClass={setErrorClass}
        setOpenConfirm={setOpenConfirm}
        setForm={setForm}
        setAction={setAction}
    />
        :
        <VitalParameters
            add={add}
            setAdd={setAdd}
            setModalTitle={setModalTitle}
            modalDescription={setModalDescription}
            modalIcon={setModalIcon}
            errorClass={errorClass}
            setErrorClass={setErrorClass}
            setOpenConfirm={setOpenConfirm}
            setForm={setForm}
            setAction={setAction}

        />}

        <SaveModal isOpen={openConfirm} setOpen={setOpenConfirm} type='submit' onClick={() => { sendForm(); }}
            titleModal={
                <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        ¿ Esta seguro que desea continuar?
                    </div>
                </>
            }
            descriptionModal=""
        />

        <ConfirmModal isOpen={openSendSuccess} setOpen={setOpenSendSuccess} errorClass={errorClass}
            titleModal={
                <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {modalIcon}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {modalTitle}
                    </div>
                </>
            }
            description={modalDescription} />
        {showMessageModal(errorMessage, errorMessageModal, setErrorMessageModal, classes.borderWhite, classes.quickAccessIcon, classes.contentDialogTitle, handleGoToPersonalMedicalData)}
    </>
    );
};
export default HomeVitalParameters;
