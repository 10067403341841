import { FormControl, StyledEngineProvider, Typography } from "@material-ui/core";
import CustomizedInputs from "../../../controls/InputCustom/CustomizedInputs";
import { useState, useEffect } from 'react';
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import CustomButton from "../../../controls/Button/CustomButton";
import useStyles from "../../../assets/styles/styles";
import CustomizedDateTimerPicker from "../../../controls/DateTimerPicker/CustomizedDateTimerPicker";
import InputLabel from '@material-ui/core/InputLabel';
import { format } from "date-fns";
import { es } from "date-fns/locale";

const FormWeight = (props) => {
    const classes = useStyles();
    const [weight, setWeight] = useState(null);
    const [meters, setMeters] = useState(null);
    const [centimeters, setCentimeters] = useState(null);
    const [dateRegister, setDateRegister] = useState(new Date());
    const { screen } = useResizeScreen();
    const [errors, setErrors] = useState({});


    useEffect(() => {
        inicializeErrors();
    }, [])

    const setBackgroundMobile = () => {
        let cssClass = '';
        if (screen.sWidth <= 768)
            cssClass = classes.titlePersonalDataForm;
        return cssClass;
    };

    const handleCancel = () => {
        setWeight(null);
        setMeters(null);
        setCentimeters(null);
        props.setAdd(prevState => !prevState);
    }

    const inicializeErrors = () => {
        let temp = {};
        temp.weight = "";
        temp.meters = "";
        temp.centimeters = "";
        setErrors(temp)
    }

    const handleSaveForm = (e) => {
        e.preventDefault();
        let param = {};
        param.numeroPersona = parseInt(window.localStorage.getItem("numeroPersona"));
        param.peso = weight;
        param.tallaMetros = meters;
        param.tallaCentimetros = centimeters;
        param.registracionFechaHora = format(dateRegister, "yyyy-MM-dd") + " " + dateRegister.toLocaleTimeString();
        param.modo = "A";
        if (validate(param)) {
            props.setForm(param);
            props.setAction("ALTA");
            props.setOpenConfirm(prevState => !prevState);
        }
    }

    const validate = (param) => {
        let temp = validateValues(param);
        setErrors({
            ...temp,
        });
        return Object.values(temp).every((value) => value == "");
    };

    return (<>
        <div className={`${setBackgroundMobile()}`} style={{ paddingTop: '60px', marginBottom: "20px" }}>
            <Typography className={`${classes.titleTurn} ${classes.titleBox}`} style={{ textTransform: 'uppercase' }}>
                MIS DATOS MEDICOS
            </Typography>
            <Typography className={classes.subtitleBox} style={{ marginLeft: "20px" }}>
                Información sin carácter sanitario conforme ley Nº 26529
            </Typography>
        </div>
        <div className={classes.headerSecundary} style={{ marginBottom: "20px" }}>
            <Typography className={`${classes.titleTurn} ${classes.titleBox}`} style={{ textTransform: 'uppercase', marginTop: "10px" }}>
                PESO Y TALLA
            </Typography>
            {screen.sWidth > 800 ?
                <div className={`${classes.BtnMobileWeight} `}>
                    <CustomButton
                        customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                        style={{ marginRight: "15px" }}
                        title='cancelar'
                        onClick={() => { handleCancel() }}
                    />
                    <CustomButton
                        customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                        style={{ padding: '5px 20px 5px 20px', fontWeight: "bold", fontSize: "10px" }}
                        title='Guardar'
                        type='submit'
                        onClick={handleSaveForm}
                    />
                </div> : ""}
        </div>
        <form className={classes.centerWeigthForm}>
            <FormControl className={[classes.formWeigth, classes.customMarginForm]} variant="standard">
                <CustomizedInputs onChange={(e) => { setWeight(e.target.value) }} value={weight} label="PESO" type="number"
                    onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                    }}
                />
                {errors.weight != "" ? <span className={classes.spanError}>{errors.weight}</span> : ""}
            </FormControl>

            <FormControl className={classes.formWeigth} variant="standard">
                <div className={classes.spaceBetweenInputs}>

                    <CustomizedInputs onChange={(e) => { setMeters(e.target.value) }} value={meters} label="TALLA METROS" type="number"
                        onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1)
                        }}
                    />
                    <Typography style={{ textTransform: 'uppercase', marginTop: "50px" }}>
                    </Typography>

                    <CustomizedInputs onChange={(e) => { setCentimeters(e.target.value) }} value={centimeters} label="CENTIMETROS" type="number"
                        onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                        }}
                    />
                </div>

                <div className={classes.flexRow}>
                    {errors.meters != "" ? <span className={classes.spanError}>{errors.meters}</span> : ""}
                    {errors.centimeters != "" ? <span className={classes.spanError}>{errors.centimeters}</span> : ""}
                </div>

            </FormControl>

            <FormControl id="DateTimePicker" className={[classes.formWeigth, classes.weigthDateTimePicker]} variant="standard">
                <InputLabel className={classes.labelDateTimerPicker} shrink htmlFor="bootstrap-input">
                    {"FECHA Y HORA DE REGISTRACION"}
                </InputLabel>
                <StyledEngineProvider locale={es} injectFirst >
                    < CustomizedDateTimerPicker id="DateTimePicker" setValue={setDateRegister} value={dateRegister} />
                </StyledEngineProvider>
            </FormControl>
            {screen.sWidth < 800 ?
                <div className={`${classes.BtnMobileWeight} `}>
                    <CustomButton
                        customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                        style={{ marginRight: "15px" }}
                        title='cancelar'
                        onClick={() => { handleCancel() }}
                    />
                    <CustomButton
                        customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                        style={{ padding: '5px 20px 5px 20px', fontWeight: "bold", fontSize: "10px" }}
                        title='Guardar' type='submit' onClick={handleSaveForm}
                    />

                </div> : ""}
        </form>


    </>)
}
export default FormWeight

function validateValues(param) {
    let temp = {};
    const campoObligatorio = "*Este campo es obligatorio";

    if (param.peso == "" || param.peso == undefined) {
        temp.weight = campoObligatorio;
    } else if (param.peso < 3 || param.peso > 350) {
        temp.weight = "*El valor debe ser entre 3 y 350";
    }
    if (param.tallaMetros == "" || param.tallaMetros == undefined) {
        temp.meters = campoObligatorio;
    } else if (param.tallaMetros < 0 || param.tallaMetros > 2) {
        temp.meters = "*El valor debe ser entre 0 y 2";
    }
    if (param.tallaCentimetros == "" || param.tallaCentimetros == undefined) {
        temp.centimeters = campoObligatorio;
    } else if (param.tallaCentimetros < 0 || param.tallaCentimetros > 99) {
        temp.centimeters = "*El valor debe ser entre 0 y 99";
    }
    return temp;
}

