import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncFetchZoneFilters({ payload }) {
  try {
    const response = yield call(API.getFilterCartilla, payload);
    yield put({ type: actionTypes.SUCCESS_GET_FILTERS_VALUES, response, message: '' });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_FILTERS_VALUES,
      message: error.message,
    });
  }
}

function* asyncGetLocalidadesBarrios({ payload }) {
  try {
    const response = yield call(API.getLocalidadesBarrio, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCESS_GET_LOCALIDADES_BARRIOS,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_LOCALIDADES_BARRIOS,
      message: error.message,
    });
  }
}

function* asyncGetEspecialidadesEstudios({ payload }) {
  try {
    const response = yield call(
      API.getEspecialidadesEstudiosComplementarios,
      payload
    );
    if (response)
      yield put({
        type: actionTypes.SUCCESS_GET_ESPECIALIDADES_ESTUDIOS_COMPLEMENTARIOS,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_ESPECIALIDADES_ESTUDIOS_COMPLEMENTARIOS,
      message: error.message,
    });
  }
}

function* asyncGetPrestadoresCartilla({ payload }) {
  try {
    const response = yield call(API.getPrestadoresResult, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCESS_GET_PRESTADORES_RESULT,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_PRESTADORES_RESULT,
      message: error.message,
    });
  }
}

function* asyncGetCompleteProfile({ payload }) {
  try {
    const response = yield call(API.getCompleteProfile, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCESS_GET_PERFIL_COMPLETO,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_PERFIL_COMPLETO,
      message: error.message,
    });
  }
}

// Watchers
export default function* filtersSaga() {
  yield takeEvery(actionTypes.GET_FILTERS_VALUES, asyncFetchZoneFilters);
  yield takeLatest(
    actionTypes.GET_LOCALIDADES_BARRIOS,
    asyncGetLocalidadesBarrios
  );
  yield takeLatest(
    actionTypes.GET_ESPECIALIDADES_ESTUDIOS_COMPLEMENTARIOS,
    asyncGetEspecialidadesEstudios
  );
  yield takeLatest(
    actionTypes.GET_PRESTADORES_CARTILLA,
    asyncGetPrestadoresCartilla
  );
  yield takeLatest(actionTypes.GET_PERFIL_COMPLETO, asyncGetCompleteProfile);
}
