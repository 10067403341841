import React from 'react'
import {Typography } from "@material-ui/core";
import useStyles from "../../assets/styles/styles";
import { useSelector } from 'react-redux';

const BackCredential = () => {

    const classes = useStyles();
    const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);
    const defaultValue = 'Credencial instransferible, válida únicamente para su titular. Debe acompañarse con documento de identidad. Su uso indebido está penado por la ley.';
    const backCredentialConfig = configurationReduxState?.find(x => x.id === 'dorsoCredencial')?.Valor || defaultValue;

    return (
        <div className={classes.credBody}>
            <div className={classes.backCredImage}>
                <div className={classes.backCredDivText}>
                    <div>
                        <Typography className={classes.backCredText}>
                            {backCredentialConfig}
                        </Typography>
                    </div>
                    <div className={classes.backCredFooter}>
                        <Typography className={classes.backCredText}>
                            RNOS N° 4-0200-4
                        </Typography>
                        <Typography className={classes.backCredText}>
                            RNMEMP N° 1013
                        </Typography>
                        <Typography className={classes.backCredText}>
                            SSSalud:
                        </Typography>
                        <Typography className={classes.backCredText}>
                            0800 222 SALUD (72583) www.sssalud.gob.ar
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
      )
}

export default BackCredential
