import * as actionTypes from '../consts/actionTypes';
import {responseToReturn} from '../../utils/utilsFunctions.js';

const initialState = {
    allConfigurations: [],
    configurationById: [],
    configLowService:[],
    configReclamo: [],
    msgError: ''
};

export default function configurationReducer(state = initialState, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_GET_CONFIGURATIONS]: responseToReturn('allConfigurations', state, action),
        [actionTypes.SUCCESS_GET_CONFIGURATION_BY_ID]: responseToReturn('configurationById', state, action),
        [actionTypes.SUCCESS_GET_CONFIGURATION_LOW_SERVICE_BY_ID]: responseToReturn('configLowService', state, action),
        [actionTypes.SUCCESS_GET_CONFIGURATION_RECLAMO_BY_ID]: responseToReturn('configReclamo', state, action),
        [actionTypes.FAILURE_GET_CONFIGURATIONS]: responseToReturn('', state, action),
        [actionTypes.FAILURE_GET_CONFIGURATION_BY_ID]: responseToReturn('', state, action),
        [actionTypes.FAILURE_GET_CONFIGURATION_LOW_SERVICE_BY_ID]: responseToReturn('', state, action),
        [actionTypes.FAILURE_GET_CONFIGURATION_RECLAMO_BY_ID]: responseToReturn('', state, action),
    };

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}