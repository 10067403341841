import Menu from "../../../components/Menu/Menu";
import {
    Divider,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
} from "@material-ui/core";
import NotFound from "../../../components/HomeLogged/NotFound";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../../../assets/styles/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { handleExpandAccordion } from "../../../utils/utilsFunctions";
import { useEffect, useState } from "react";
import { getAnswerQuestion } from "../../../redux/actions/appointmentsActions";
import useTrackPageView from "../../../utils/useTrackPageView";


const Questions = () => {
    const appointments = useSelector((state) => state.appointmentsReducer);
    const classes = useStyles();
    const history = useHistory();
    const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();
    const trackPageView = useTrackPageView("Preguntas Frecuentes - Turnos");

    const returnHome = () => {
        history.push('/AppointmentHome');
    };

    useEffect(() => {
        dispatch(getAnswerQuestion());
        trackPageView();
    }, [])

    return (<>
        <Menu />
        <div className={`${classes.divMyHistoryTurn}`}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "30px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <IconButton onClick={returnHome}>
                        <ArrowBackIcon className={classes.iconFilter} />
                    </IconButton>
                    <Typography className={`${classes.textTitleTurn}`} style={{ textTransform: 'uppercase' }}>
                        Preguntas frecuentes
                    </Typography>
                </div>
            </div>
            <Divider className={`${classes.formDivider} ${classes.dividerAppointment}`} />
            {appointments.questionF?.length > 0 ?
                appointments.questionF.map((item, i) => (
                    <Accordion
                        key={i}
                        className={classes.questionQFMargin}
                        style={{ width: "90%", margin: "0px 5%" }}
                        expanded={expanded === item.question}
                        onChange={(e, isExpanded) => { handleExpandAccordion(item.question, e, isExpanded, setExpanded); }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.txtTitleQuestion}>
                                {item.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails key={i}>
                            <Typography className={classes.txtCardQuestionDesc} style={{marginLeft: "1%"}}>
                                {item.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>))
                :
                <NotFound customMsg={
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <span className={classes.notAppointment}>No existen actualmente preguntas frecuentes</span>
                    </div>}
                    wsItem={appointments?.appointmentQuestionFullData?.answerQuestion}
                    fullItem={appointments?.appointmentQuestionFullData?.answerQuestion} />
            }
        </div>
    </>);
};

export default Questions;


