import * as actionTypes from '../consts/actionTypes';
import {responseToReturn} from '../../utils/utilsFunctions.js';

const initialValues = {
    dataUser: null,
    addDataUser: {},
    deleteDataUser: {},
    modifyDataUser: {},
    registerUser: [],
    registerValidation: [],
    isLoading: false,
    msgError: ''
};

export default function registerUsersReducer(state = initialValues, action) {
    switch(action.type){
        case actionTypes.VERIFY_REGISTER:
            state = { ...state, isLoading: true};
            break;
        case actionTypes.SUCCESS_VERIFY_REGISTER:
            state = { ...state, isLoading: false};
            break;
        default:
            break;
    }

    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_GET_DATA_REGISTER_USER]: responseToReturn('dataUser', state, action),
        [actionTypes.SUCCESS_ADD_DATA_REGISTER_USER]: responseToReturn('addDataUser', state, action),
        [actionTypes.SUCCESS_DELETE_DATA_REGISTER_USER]: responseToReturn('deleteDataUser', state, action),
        [actionTypes.SUCCESS_MODIFY_DATA_REGISTER_USER]: responseToReturn('modifyDataUser', state, action),
        [actionTypes.SUCCESS_REGISTER_USER]: responseToReturn('registerUser', state, action),
        [actionTypes.SUCCESS_VERIFY_REGISTER]: response('registerValidation', state, action),
        [actionTypes.FAILURE_GET_DATA_REGISTER_USER]: responseToReturn('dataUser', state, action),
        [actionTypes.FAILURE_ADD_DATA_REGISTER_USER]: responseToReturn('addDataUser', state, action),
        [actionTypes.FAILURE_DELETE_DATA_REGISTER_USER]: responseToReturn('deleteDataUser', state, action),
        [actionTypes.FAILURE_MODIFY_DATA_REGISTER_USER]: responseToReturn('modifyDataUser', state, action),
        [actionTypes.FAILURE_REGISTER_USER]: responseToReturn('registerUser', state, action),
        [actionTypes.FAILURE_VERIFY_REGISTER]: response('registerValidation', state, action)

    };

    function response(item, state, action) {
        let res = { ...state };
        if (action.response) {
            res = { ...state, [item]: action.response.data.data };
        }
        if (action.message) {
            res = { ...state, msgError: action.message };
        }
        return res;
    }

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}