import React, { useEffect, useState } from "react";
import useStyles from "../../assets/styles/styles";
import { ClickAwayListener, Divider, FormControl, IconButton, InputLabel, TextField, Typography } from "@material-ui/core";
import { getDataFilterAppointment } from "../../redux/actions/appointmentsActions";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../controls/Select/CustomSelect";
import NotFound from "../../components/HomeLogged/NotFound";
import CustomButton from "../../controls/Button/CustomButton";
import MovementsCard from "../../components/Billing/Movements/MovementsCard";
import TuneIcon from '@material-ui/icons/Tune';
import { LocalizationProvider, MobileDatePicker } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import HeaderCard from "../../components/Billing/Movements/HeaderCard";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ModalActivities from "../DialogActivities/ModalActivities";
import FileDownloadIcon from '@material-ui/icons/FileDownload';
import {
    getRefunds,
    downloadRefund,
    cancelRefundRequest,
    getParametersRefunds,
    setFilters
} from "../../redux/actions/refundsActions";
import { useHistory } from "react-router";
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import { es } from "date-fns/locale";
import ControlPointOutlinedIcon from '@material-ui/icons/ControlPointOutlined';
import { format } from "date-fns";
import { CircularProgress } from "@mui/material";
import CancelIcon from '@material-ui/icons/Cancel';
import { showErrorMessageModal } from "../../utils/utilsFunctions";

const personNumber = parseInt(window.localStorage.getItem("numeroPersona"));

const person = {
    numeroPersona: personNumber,
    parametroLlamado: ""
};

const previousSixMonths = 6;

const RefundsIndex = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const date = new Date();
    const { screen } = useResizeScreen();
    const classes = useStyles();
    const [groupFamily, setGroupFamily] = useState([]);
    const [listCard, setListCard] = useState([]);
    const [openPicker, setOpenPicker] = useState(false);
    const refundsRedux = useSelector((state) => state.refundsReducer);
    const appointments = useSelector((state) => state.appointmentsReducer);
    const [openModal, setOpenModal] = useState(false);
    const [itemSelected, setItemSelected] = useState({});
    const downloadRefundAffiliate = useSelector((state) => state.refundsReducer.downloadRefund);
    const [openCancelRefundModal, setOpenCancelRefundModal] = useState(false);
    const [cancelRefund, setCancelRefund] = useState({});
    const [openLoadingModal, setLoadingModal] = useState(false);
    const loadingDownload = useSelector((state) => state.refundsReducer.loadingDownload);
    const [cancelRefundDownload, setCancelRefundDownload] = useState(false);
    const nullifyRefund = useSelector((state) => state.refundsReducer.cancelRefund);
    const [openNullifyRefundModal, setNullifyRefundModal] = useState(false);
    const [ErrorModal, setErrorModal] = useState(false);
    const [ErrorDownloadModal, setErrorDownloadModal] = useState(false);
    const { refundsFilters } = useSelector(state => state?.refundsReducer);
    const errorMessage = useSelector(state => state.refundsReducer.msgError);
    const [openErrorModal, setOpenErrorModal] = useState();

    const inlineRefundsStyles = {
        downloadRefundsButton: {
            marginTop: "10px",
            width: "60%",
            fontSize: "7px",
            fontWeight: '600'
        },

        newRequestRefundsContainerButton: {
            display: 'flex',
            justifyContent: 'flex-start',
            paddingTop: screen.sWidth < 768 ? '5%' : '2%', flexWrap: 'wrap'
        },

        newRequestRefundsButton: {
            height: "40px",
            fontSize: "12px",
            fontWeight: "600",
            margin: screen.sWidth < 768 ? '15px 13px' : '5px 40px'
        },

        frequentQuestionsButton: {
            height: "40px",
            fontSize: "12px",
            fontWeight: "600",
            marginTop: screen.sWidth < 768 ? "5px" : "15px",
            padding: '0 16px'
        },

        movementsCardDescription: { color: '#0075c9', fontWeight: 600 },

        titleModal: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            width: '250px'
        },

        selectAffiliateContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '15px 5px',
            alignItems: 'center'
        },

        historyAndFilterButtonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },

        refundsCardsContainer: {
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'center'
        },

        buttonZoneSection: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flex: 1
        },

        buttonZoneContainer: {
            marginLeft: '20px',
            alignItems: 'center',
            marginBottom: 'auto'
        },

        seeMoreButton: { fontSize: '12px', fontWeight: "600", color: '#0075c9' },

        seeMoreIcon: { marginLeft: "5px", marginTop: '-2px', fontSize: '13px' },

        localizationProvider: { width: "100px", zIndex: 2 },

        descriptionModal: {
            display: 'flex',
            justifyContent: 'center',
            margin: '5px'
        }
    }

    const handleChange = () => e => {
        setListCard([]);
        let nameAffilliated = groupFamily?.filter((x) => x.value == e.target.value);
        dispatch(setFilters({ ...refundsFilters, afiliado: { value: nameAffilliated?.[0]?.value, label: nameAffilliated?.[0]?.label } }));
    };

    const handleClick = () => {
        setOpenPicker((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpenPicker(false);
    };

    const handleStartRequest = (e) => {
        e.preventDefault();
        history.push('/Refunds?type=RefundsRequest');
    };

    const handleClickModal = (item) => e => {
        e.preventDefault();
        let obj = {
            numeroPersona: refundsFilters?.afiliado?.value,
            gestionCodigo: item?.reintegroGestionCodigo,
            reintegroNumero: item?.reintegroNumero,
            reintegroDelegacionCodigo: item?.reintegroDelegacionCodigo
        };
        dispatch(downloadRefund(obj));
    };

    function handleCloseDownload() {
        setCancelRefundDownload(true);
        setLoadingModal(false)
    }

    useEffect(() => {
        if (loadingDownload) {
            setLoadingModal(true);
            setCancelRefundDownload(false);
        } else {
            if (!cancelRefundDownload && downloadRefundAffiliate?.confirmacion) {
                setOpenModal(true);
                setLoadingModal(false);
            }
        }

        if (!downloadRefundAffiliate?.confirmacion && downloadRefundAffiliate?.confirmacion !== undefined) {
            setLoadingModal(false);
            setOpenModal(false);
            setErrorDownloadModal(true);
        }
    }, [loadingDownload])

    const setItemToCancel = item => {
        setCancelRefund(item);
        setOpenCancelRefundModal(true);
    }

    const handleClickCancelRefund = () => e => {
        e.preventDefault();
        let cancelActualItem = {
            numeroPersona: refundsFilters?.afiliado?.value,
            gestionCodigo: cancelRefund?.reintegroGestionCodigo
        };
        dispatch(cancelRefundRequest(cancelActualItem));
        setOpenCancelRefundModal(false);
        dispatch(getParametersRefunds());
        setCancelRefund({})
        setNullifyRefundModal(true);
    };

    useEffect(() => {
        if (nullifyRefund?.confirmacion) {
            setListCard([]);
            getAllRefunds();
        } else {
            if (!nullifyRefund?.confirmacion && nullifyRefund?.confirmacion !== undefined) {
                setNullifyRefundModal(false);
                setErrorModal(true);
            }
        }
    }, [nullifyRefund])

    useEffect(() => {
        dispatch(getDataFilterAppointment(person));
        setItemSelected({});
        setErrorDownloadModal(false);
        setErrorModal(false);
        setCancelRefundDownload(false);
        setLoadingModal(false);
        setOpenModal(false);
        setCancelRefund(false);
        validateInitialValues();
    }, []);

    useEffect(() => {
        if (downloadRefundAffiliate?.reintegro !== undefined) {
            setItemSelected({ archivo: downloadRefundAffiliate?.reintegro?.[0]?.archivo, archivoNombreCompleto: downloadRefundAffiliate?.reintegro?.[0]?.archivoNombreCompleto });
        }
    }, [downloadRefundAffiliate]);

    useEffect(() => {
        let listGroupFamily = appointments?.filtro?.map((val) => {
            return {
                value: parseInt(val?.numeroPersona), label: val?.apellidoNombre
            };
        });
        setGroupFamily(listGroupFamily);
    }, [appointments?.filtro]);

    useEffect(() => {
        groupFamily?.forEach((item) => {
            if (item?.value == personNumber && !refundsFilters?.afiliado) {
                dispatch(setFilters({ ...refundsFilters, afiliado: { value: item?.value, label: item?.label } }));
            }
        });
    }, [groupFamily]);

    const getAllRefunds = () => {
        if(refundsFilters?.dateStart && refundsFilters?.dateEnd && refundsFilters?.afiliado?.value) {
            let start = format(refundsFilters?.dateStart != undefined ? refundsFilters?.dateStart : date.setMonth(date.getMonth() - previousSixMonths), 'yyyy-MM-dd');
            let end = format(refundsFilters?.dateEnd != undefined ? refundsFilters?.dateEnd : new Date(), 'yyyy-MM-dd');
            let refundsRequest = {
                fechaDesde: start,
                fechaHasta: end,
                numeroPersona: refundsFilters?.afiliado?.value,
                count: 0,
                skip: 0
            };
            dispatch(getRefunds(refundsRequest));
        }
    }

    useEffect(() => {
        getAllRefunds();
    }, [refundsFilters?.dateStart, refundsFilters?.dateEnd, refundsFilters?.afiliado?.value]);

    useEffect(() => {
        let refundsCards = [];
        if (refundsRedux?.refunds?.reintegros?.length > 0) {
            refundsCards = refundsRedux?.refunds?.reintegros?.map(element => {
                return element;
            });
        }
        setListCard(refundsCards);
        setNullifyRefundModal(false);
    }, [refundsRedux?.refunds]);

    useEffect(() => {
        if (errorMessage !== "" && errorMessage !== undefined) {
            setOpenErrorModal(true);
            setNullifyRefundModal(false);
            setOpenCancelRefundModal(false);
            setErrorDownloadModal(false);
            setOpenModal(false);
            setLoadingModal(false);
            setErrorModal(false);
        }
    }, [errorMessage]);

    const goToFrequentQuestions = () => {
        history.push("/RefundsFAQ");
    };

    const setButtons = item => {
        let response = '';

        const STATE = {
            Ingresada: 'ANULAR',
            'En Proceso': '',
            Finalizado: '',
            Anulada: '',
            Autorizado: 'DESCARGAR',
            Denegado: '',
            Pagado: 'DESCARGAR',
        };

        const BTN = {
            DESCARGAR: (
                <CustomButton
                    title="DESCARGAR"
                    customClass={`${classes.lightLinearGradient}`}
                    style={inlineRefundsStyles.downloadRefundsButton}
                    onClick={handleClickModal(item)}
                />
            ),

            ANULAR: (
                <CustomButton
                    title="ANULAR"
                    customClass={`${classes.lightLinearGradient}`}
                    style={inlineRefundsStyles.downloadRefundsButton}
                    onClick={() => { setItemToCancel(item) }}
                />
            )
        };

        if (item?.reintegroEstadoDescripcion?.trim()) {
            response = BTN[STATE[item?.reintegroEstadoDescripcion?.trim()]];
        }

        return response;
    };

    // Botón iniciar solicitud
    const startRequestButton = () => {
        return (
            <div style={inlineRefundsStyles.newRequestRefundsContainerButton}>
                <CustomButton
                    title={"Iniciar solicitud"}
                    onClick={handleStartRequest}
                    style={inlineRefundsStyles.newRequestRefundsButton}
                    icon={<AddCircleIcon style={{ marginRight: "5px" }} />}
                    customClass={`${classes.customHeaderBtn} ${classes.lightLinearGradient}`}
                />
            </div>
        );
    }

    // Select para afiliado
    const affiliateDropDown = () => {
        return (
            <div style={{ minWidth: '240px' }}>
                <FormControl
                    className={`${classes.formControlChronicMedicationForm} ${classes.formControlsEmergency} ${classes.formControlSelect}`}
                    variant="standard"
                >
                    <CustomSelect
                        customRequired={true}
                        list={groupFamily}
                        selected={parseInt(refundsFilters?.afiliado?.value)}
                        onChange={handleChange()}
                    />
                </FormControl>
            </div>
        );
    }

    // Botón Preguntas Frecuentes
    const frequentQuestionsButton = () => {
        return (
            <div>
                <CustomButton
                    title={screen.sWidth > 768 ? 'Preguntas Frecuentes' : ''}
                    onClick={goToFrequentQuestions}
                    icon={<QuestionAnswerOutlinedIcon style={{ marginRight: screen.sWidth > 768 ? "8px" : "5px" }} />}
                    customClass={`${classes.lightLinearGradient}`}
                    style={inlineRefundsStyles.frequentQuestionsButton}
                />
            </div>
        );
    }

    const setFirstDate = (value) => {
        dispatch(setFilters({ ...refundsFilters, dateStart: value }));
    }

    const setEndDate = (value) => {
        dispatch(setFilters({ ...refundsFilters, dateEnd: value }));
    }

    const validateInitialValues = () => {
        let aux ={};
        if (refundsFilters) {
            aux = {...refundsFilters};
            
            if(refundsFilters?.dateStart == null){
                aux = {...aux, dateStart: date.setMonth(date.getMonth() - previousSixMonths)};
            }

            if(refundsFilters?.dateEnd == null){
                aux = {...aux, dateEnd: new Date()};
            }
        }
        dispatch(setFilters(aux));
    }

    // Desplegable para seleccionar rangos de fechas
    const dateDropDown = () => {
        return (
            <div className={`${classes.headerFiltro} ${classes.marginTop}`}>

                <FormControl style={{ width: "150px", paddingTop: "10px" }} className={classes.datePicker}>
                    <InputLabel className={`${classes.txtSubtitleCalendar} ${classes.fontSize15}`} shrink  >{"DESDE"}</InputLabel>
                    <div className={classes.top22} style={{ display: "flex", justifyContent: "space-between" }}>
                        <LocalizationProvider locale={es} style={inlineRefundsStyles.localizationProvider} dateAdapter={AdapterDateFns}  >
                            <MobileDatePicker
                                cancelText="Cancelar"
                                toolbarTitle=""
                                inputFormat="dd/MM/yyyy"
                                name={'dateStart'}
                                value={refundsFilters['dateStart']}
                                onChange={setFirstDate}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <CalendarTodayIcon style={{ alignSelf: "center" }} />
                    </div>
                </FormControl>

                <FormControl style={{ width: "150px", marginLeft: "10px", paddingTop: "10px" }} className={classes.datePicker} >
                    <InputLabel className={`${classes.txtSubtitleCalendar} ${classes.fontSize15}`} shrink  >{"HASTA"}</InputLabel>
                    <div className={classes.top22} style={{ display: "flex", justifyContent: "space-between", }}>
                        <LocalizationProvider locale={es} style={inlineRefundsStyles.localizationProvider} dateAdapter={AdapterDateFns}  >
                            <MobileDatePicker
                                cancelText="Cancelar"
                                toolbarTitle=""
                                inputFormat="dd/MM/yyyy"
                                name={'dateEnd'}
                                value={refundsFilters['dateEnd']}
                                onChange={setEndDate}
                                variant='outlined'
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <CalendarTodayIcon style={{ alignSelf: "center" }} />
                    </div>
                </FormControl>

            </div>
        );
    }

    // Botón filtro de fechas
    const dateFilterButton = () => {
        return (
            <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                <div style={{ position: "relative" }}>
                    <CustomButton
                        onClick={handleClick}
                        style={{ fontSize: '12px', margin: screen.sWidth < 768 ? '15px 13px' : '20px 30px 20px 0' }}
                        customClass={`${classes.btnFilterAppointment}`}
                        icon={<TuneIcon className={classes.iconFilter} />}
                    />
                    {
                        openPicker &&
                        (
                            <div className={`${classes.dropDownMyHistory} ${classes.dropdown}`} style={{ zIndex: 1 }}>
                                <Typography className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>{"PERIODO"}</Typography>
                                <Divider classes={{ root: classes.dividerFilter }} />
                                {dateDropDown()}
                            </div>
                        )
                    }
                </div>
            </ClickAwayListener>
        );
    }

    // Tarjeta con detalle de cada reintegro
    const refundsCard = (element, index) => {
        return (
            <MovementsCard
                index={index}
                headerIcon={
                    <HeaderCard
                        day={element?.fechaFormateada?.nombreDia}
                        numberDay={element?.fechaFormateada?.dia}
                        month={parseInt(element?.reintegroGestionFecha?.substring(5, 7))}
                        year={parseInt(element?.reintegroGestionFecha?.substring(0, 4))} />
                }
                description={
                    <div style={{ marginTop: '5px' }}>
                        <Typography style={{ fontWeight: "600" }} className={`${classes.txtCardDescription} `}>
                            <span style={inlineRefundsStyles.movementsCardDescription}>{`SOLICITUD N°: ${element?.reintegroGestionCodigo}`}</span>
                        </Typography>
                        <Typography className={`${classes.txtCardDescription}`} style={{ width: screen.sWidth < 768 && '150px' }}>
                            {`PRESTACION: ${element?.reintegroTipoDescripcion}`}
                        </Typography>
                        <Typography style={{ color: '#0075c9', display: 'flex', flexWrap: 'wrap' }} className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>
                            REINTEGRO Nº <span style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}> {element?.reintegroNumero !== 0 ? element?.reintegroNumero : "-"}</span>
                        </Typography>
                        <Typography className={`${classes.txtCardDescription}`}>
                            {`IMPORTE: $ ${element?.reintegroImporte}`}
                        </Typography>
                        <Typography className={`${classes.txtCardDescription}`}>
                            {`ESTADO:`} <span style={inlineRefundsStyles.movementsCardDescription}>{element?.reintegroEstadoDescripcion}</span>
                        </Typography>
                    </div>
                }
                buttonZone={
                    <section style={inlineRefundsStyles.buttonZoneSection}>
                        <section className={`${classes.sectionButtons} ${classes.btnAuthorizationCard}`} style={inlineRefundsStyles.buttonZoneContainer}>
                            <div>
                                <IconButton onClick={handleClickVerMas(element)} customClass={`${classes.customHeaderBtn}`}>
                                    <Typography style={inlineRefundsStyles.seeMoreButton} className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>
                                        VER
                                    </Typography>
                                    <ControlPointOutlinedIcon style={inlineRefundsStyles.seeMoreIcon} className={classes.iconFilter} />
                                </IconButton>
                            </div>
                            <div style={{ paddingRight: '7%' }}>
                                {setButtons(element)}
                            </div>
                        </section>
                    </section>
                }
            />
        );
    }

    // PopUp para descarga de reintegros
    const downloadPopUp = () => {
        return (
            <ModalActivities
                title={
                    <div style={inlineRefundsStyles.titleModal}>
                        <span>Descargar</span>
                    </div>
                }
                description={
                    <div style={inlineRefundsStyles.descriptionModal}>
                        <a href={`data:image/png;base64,${itemSelected?.archivo}`} download={itemSelected?.archivoNombreCompleto}>
                            <IconButton style={{ backgroundColor: '#ffffff', borderRadius: '15px' }}>
                                <FileDownloadIcon className={classes.actionsIcons} />
                            </IconButton>
                        </a>
                    </div>
                }
                open={openModal}
                onClose={setOpenModal}
                modalconfirm={false}
                modalLogout={false}
            />
        );
    }

    const handleClickVerMas = (item) => (e) => {
        e.preventDefault();
        let obj = {
            numeroPersona: refundsFilters?.afiliado?.value,
            gestionCodigo: item.reintegroGestionCodigo,
            reintegroNumero: item.reintegroNumero,
            delegacionCodigo: item.reintegroDelegacionCodigo
        };
        let afiliado = refundsFilters?.afiliado?.value;
        history.push('/RefundsDetails', { params: [obj, afiliado] });
    };

    useEffect(() => {
        setOpenModal(false);
    }, [])

    return (
        <>
            <div className={`${classes.divIndexRefunds}`}>
                {startRequestButton()}

                <div
                    className={classes.divAccordionPrograms}
                    style={inlineRefundsStyles.selectAffiliateContainer}
                >
                    {affiliateDropDown()}
                    {frequentQuestionsButton()}
                </div>

                <div style={inlineRefundsStyles.historyAndFilterButtonContainer}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography className={`${classes.textTitleTurn} ${classes.titleRefunds}`}>
                            Historial
                        </Typography>
                    </div>
                    {dateFilterButton()}
                </div>

                <Divider className={`${classes.formDivider} ${classes.dividerAppointment}`} />

                <div
                    className={`${classes.listCardMyHistory}`}
                    style={inlineRefundsStyles.refundsCardsContainer}
                >
                    {
                        (refundsFilters?.afiliado?.value !== undefined && listCard?.length > 0) ?
                            listCard?.map((item, i) => (
                                <div key={item?.id} style={{ margin: '5px' }}>
                                    {refundsCard(item, i)}
                                </div>
                            ))
                        :
                        <NotFound
                            loading={refundsRedux?.loading}
                            customMsg={
                                <div className={classes.contentNotFound}>
                                    <span >No tenés historial de reintegros</span>
                                </div>
                            }
                            wsItem={refundsRedux?.refunds?.reintegros}
                            fullItem={refundsRedux?.refunds?.reintegros}
                        />
                    }
                </div>
            </div>

            {openModal && downloadPopUp()}

            {
                openCancelRefundModal &&
                <ModalActivities
                    title={'Anular solicitud'}
                    description={
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                Estás por anular la solicitud de reintegro seleccionada
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                ¿Querés continuar?
                            </div>
                        </>
                    }
                    onClose={setOpenCancelRefundModal}
                    open={openCancelRefundModal}
                    modalconfirm={true}
                    buttonType={'submit'}
                    handleClick={handleClickCancelRefund()}
                    modalLogout={false}
                    style={{ textAlign: 'center' }}
                />
            }

            {
                openLoadingModal &&
                <ModalActivities
                    title={
                        <div style={{ marginTop: "30px" }}>Preparando archivo ...</div>
                    }
                    description={
                        <div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.whiteCircularProgress}`}>
                            <CircularProgress />
                        </div>
                    }
                    onClose={handleCloseDownload}
                    open={openLoadingModal}
                    modalconfirm={false}
                    modalLogout={false}
                    style={{ textAlign: 'center' }}
                />
            }

            {
                openNullifyRefundModal &&
                <ModalActivities
                    title={
                        <div style={{ marginTop: "30px" }}>Anulando solicitud ...</div>
                    }
                    description={
                        <div
                            className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.whiteCircularProgress}`}>
                            <CircularProgress />
                        </div>
                    }
                    onClose={setNullifyRefundModal}
                    open={openNullifyRefundModal}
                    style={{ textAlign: 'center' }}
                    modalconfirm={false}
                    modalLogout={false}
                />
            }

            {
                ErrorDownloadModal &&
                <ModalActivities
                    description={
                        <>
                            <div>Ocurrió un error al intentar obtener el archivo</div>
                            <div>Por favor, intentá de nuevo más tarde</div>
                        </>
                    }
                    title={<CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />}
                    modalLogout={false}
                    open={ErrorDownloadModal}
                    style={{ textAlign: 'center' }}
                    onClose={setErrorDownloadModal}
                    modalconfirm={false}
                />
            }

            {
                ErrorModal &&
                <ModalActivities
                    title={
                        <CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                    }
                    description={
                        <div>
                            <div>Ocurrió un error al intentar anular la solicitud</div>
                            <div>Por favor, intentá de nuevo más tarde</div>
                        </div>
                    }
                    onClose={setErrorModal}
                    open={ErrorModal}
                    style={{ textAlign: 'center' }}
                    modalconfirm={false}
                    modalLogout={false}
                />
            }

            {showErrorMessageModal(errorMessage, openErrorModal, setOpenErrorModal, classes.borderWhite, classes.quickAccessIcon)}
        </>
    );
};

export default RefundsIndex;
