import { IconButton, Typography } from "@material-ui/core";
import CustomButton from "../../../controls/Button/CustomButton";
import AppointmentsCard from "./AppointmentsCard";
import FavoriteIcon from "@material-ui/icons/Favorite";
import useStyles from '../../../assets/styles/styles';
import { useResizeScreen } from "../../../hooks/useResizeScreen";

const FavoriteCard = ({ item, i, handleClickOpenDeleteFavModal, handleClickOpenRepeatModal }) => {
    const classes = useStyles();
    const { screen } = useResizeScreen();

    return (
        <>
            <AppointmentsCard
                index={i}
                blueBox={false}
                buttonsInHeader={true}
                headerButtons={
                    screen.sWidth < 321 &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end', backgroundColor: '#ffffff', borderRadius: '7px', alignItems: 'flex-start' }}>
                        <IconButton onClick={handleClickOpenDeleteFavModal(item)}>
                            <FavoriteIcon className={`${classes.FavoriteIcon} ${classes.iconPositionFavorite} `} />
                        </IconButton>
                    </div>
                }
                description={
                    <>
                        <Typography style={{ fontWeight: "600", color: '#0075c9', paddingBottom: "10px", fontSize: '1rem' }} className={`${classes.txtCardDescription}`}>
                            {item?.medicoDescripcion}
                        </Typography>
                        <Typography style={{ fontWeight: "600", color: '#0075c9', paddingBottom: "10px" }} className={`${classes.txtCardDescription} `} >
                            <span style={{ color: 'black' }}>{"CENTRO MEDICO " + item?.sucursalDescripcion}</span>
                        </Typography>
                        <Typography className={`${classes.txtCardDescription}`}>
                            {item?.especialidadDescripcion}
                        </Typography>
                    </>
                }
                buttonZone={
                    <section className={`${classes.btnZoneAppointmentCard}`}>
                        {screen.sWidth > 321 &&
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton onClick={handleClickOpenDeleteFavModal(item)}>
                                    <FavoriteIcon className={`${classes.FavoriteIcon} ${classes.iconPositionFavorite} `} />
                                </IconButton>
                            </div>
                        }
                        <div className={classes.btnDivCards}>
                            <CustomButton
                                title="REPETIR TURNO"
                                customClass={classes.lightLinearGradient}
                                style={{ whiteSpace: "nowrap", marginTop: "10px", width: "100%", fontSize: "0.5rem", fontWeight: '600' }}
                                onClick={handleClickOpenRepeatModal(item)}
                            />
                        </div>
                    </section>
                }
            />
        </>
    );
};

export default FavoriteCard;