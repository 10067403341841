import * as actionTypes from '../consts/actionTypes';

export function getNotifications() {
    return {
        type: actionTypes.GET_NOTIFICATIONS,
        payload: ''
    };
}

export function setNotification(param) {
    return {
        type: actionTypes.SET_NOTIFICATIONS,
        payload: param
    };
}

export function selectNotification(notificationSelected) {
    return {
        type: actionTypes.SELECT_NOTIFICATION,
        payload: notificationSelected
    };
}

export function setCount(COUNT) {
    return {
        type: actionTypes.SET_COUNT_NOTIFICATION,
        payload: COUNT
    };
}


/**/ 

export function getAllNotifications(id) {
    return {
        type: actionTypes.GET_ALL_NOTIFICATION,
        payload: id
    };
}

export function updateAllNotifications(notifications) {
    return {
        type: actionTypes.GET_UPDATE_ALL_NOTIFICATION,
        payload: notifications
    };
}

export function modifyNotification(param) {
    return {
        type: actionTypes.MODIFY_NOTIFICATION,
        payload: param
    };
}

export function removeModifyNotification(param) {
    return {
        type: actionTypes.RESET_MODIFY_NOTIFICATION,
        payload: param
    };
}

export function getNotification(id) {
    return {
        type: actionTypes.GET_NOTIFICATION,
        payload: id
    };
}

export function setHashNotification(hash) {
    return {
        type: actionTypes.HASH_NOTIFICATION,
        payload: hash
    };
}