import React from "react";
import ModalActivities from "../DialogActivities/ModalActivities";

const NotAccessModal = ({ isOpen, setOpen }) => {
    
    return  <>
                {
                    isOpen &&
                    <ModalActivities
                        modalRegister={false}
                        title={
                            <>
                                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '30px' }}>
                                    Reintegros
                                </div>
                            </>
                        }
                        description={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    flexDirection: 'column',
                                    textAlign: "center",
                                    padding: '30px'
                                }}>
                                No tenés acceso al módulo
                            </div>
                        }
                        open={isOpen}
                        onClose={setOpen}
                    />
                }
            </>;
};

export default React.memo(NotAccessModal, (prevState, nextState) => {
    return prevState.isOpen === nextState.isOpen && prevState.items === nextState.items;
});