import { Divider, Typography, FormControl, FormHelperText, Checkbox, FormControlLabel, IconButton, InputAdornment, InputLabel, CircularProgress } from "@material-ui/core";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import FormHeader from "../PersonalData/FormHeader";
import { useEffect, useState, Fragment } from "react";
import useStyles from '../../assets/styles/styles';
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../controls/Select/CustomSelect";
import CustomizedInputs from "../../controls/InputCustom/CustomizedInputs";
import ConfirmModal from "../PersonalData/ConfirmModal";
import CustomButton from "../../controls/Button/CustomButton";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import UploadModal from "../../controls/UploadModal/UploadModal";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import { deleteFile, handleChangeEmail, handleSave, isDisabled, validate, validateFiles } from "../../utils/utilsFunctions";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { getFile, getProcedures } from "../../redux/actions/procedureActions";
import { getFullData } from "../../redux/actions/personalDataActions";
import CancelIcon from '@material-ui/icons/Cancel';
import LineBreakInput from "../../controls/InputCustom/LineBreakInput";

const userCode = parseInt(window.localStorage.getItem("numeroPersona"));
const affiliated = {
    numeroPersona: userCode
};

const BillingAndCollection = () => {
    const { screen } = useResizeScreen();
    const classes = useStyles();
    const dispatch = useDispatch();
    const fullPersonalData = useSelector(state => state?.personalDataReducer);
    const [procedureSelected, setProcedure] = useState(
        {
            procedure: "",
            subProcedure: "",
            consulta: ""
        });
    const [error, setError] = useState(
        {
            procedure: '*Campo obligatorio',
            subProcedure: '*Campo obligatorio',
            email: '',
            phone: ""
        });
    const [mail, setMail] = useState('');
    const [files, setFiles] = useState([]);
    const [modalTitle, setModalTitle] = useState('');
    const [modalDescription, setModalDescription] = useState("");
    const [modalIcon, setModalIcon] = useState('');
    const [errorClass, setErrorClass] = useState('');
    const [openSendSuccess, setOpenSendSuccess] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [fileButtons, setFileButtons] = useState([]);
    const [listOfProcess, setListOfProcess] = useState([]);
    const [listOfSubProcess, setListOfSubProcess] = useState([]);
    const [listOfAttachmentButtons, setListOfAttachmentButtons] = useState([]);
    const [nameOfItem, setItemName] = useState('');
    const [mailError, setMailError] = useState('');
    const [disabledEmail, setDisabledEmail] = useState(true);
    const [emailChecked, selEmailChecked] = useState(false);
    const [itemRequired, setItemRequired] = useState('');
    const data = useSelector(state => state?.procedureReducer);
    const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);
    const [counterLength, setCounterLength] = useState(0);

    const handleChangeSelect = (e) => {
        const { name, value } = e.target;
        setProcedure({ ...procedureSelected, [name]: value });
        if (name !== 'consulta') {
                validate(name, '*Campo obligatorio', value, setError, error);
        }
        if (value.length <= 1000) {
            setCounterLength(value.length);
        }
    };

    const handleOpenModal = (itemName, fileRequired) => (e) => {
        e.preventDefault();
        setOpenUploadModal(prevState => !prevState);
        setItemName(itemName);
        setItemRequired(fileRequired);
    };

    const handleEditEmail = () => {
        setDisabledEmail(prevState => !prevState);
    };

    const handleCheck = (e) => {
        e.preventDefault();
        if (!emailChecked) {
            setModalTitle('SE HA MODIFICADO EL MAIL CORRECTAMENTE');
            setModalDescription(`Este cambio se realizará en el e-mail de facturación y cobranza.`);
            setModalIcon(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
            setErrorClass('');
            setOpenSendSuccess(prevState => !prevState);
        }
        selEmailChecked(prevState => !prevState);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const phone = fullPersonalData?.fullData?.afiliado?.telefonos[0]?.telefono;
        let auxAttachments = listOfSubProcess?.filter(x => x.adjuntos?.length > 0);
        if (auxAttachments.length > 0 && (procedureSelected?.subProcedure === "SELECCIONE UNA OPCION" || procedureSelected?.subProcedure === "")) {
            setError({...error, subProcedure: '*Campo obligatorio'})
            return;
        } else {
            setError({...error, subProcedure: ''})
        }
        if (validateFiles(listOfAttachmentButtons, files)) {
            let filesToSend = files?.map((item) => {
                delete (item['btnNombre']);
                delete (item["adjuntoObligatorio"]);
                return item;
            });
            let isDisabledMail = isModifiedMailFunction(disabledEmail);
            let model = {
                numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                tramiteCodigo: procedureSelected?.procedure,
                subTramiteCodigo: procedureSelected?.subProcedure,
                mail: mail,
                mailModificar: isDisabledMail,
                telefono: phone !== undefined ? phone : "",
                consulta: procedureSelected?.consulta,
                sector: 'FACTURACION',
                adjuntos: JSON.stringify(filesToSend)
            };
            if(mailError === '') {
                handleSave({ mail, setModalTitle, setModalDescription, setModalIcon, setErrorClass, setOpenSendSuccess, classes, error, dispatch, model });
            }
        }
    };

    const dowloadFile = (codigo) => {
        dispatch(getFile(codigo));
    };

    useEffect(() => {
        dispatch(getProcedures('FACTURACION'));
        dispatch(getFullData(affiliated));
    }, []);

    useEffect(() => {
        let mailUser = fullPersonalData?.fullData?.afiliado?.mails?.filter(z => z.mailTipoCodigo == 12)[0]?.mail;
        if (mailUser !== undefined && mailUser !== null) {
            setMail(mailUser?.trimEnd());
        }
    }, [fullPersonalData?.fullData]);

    useEffect(() => {
        if (procedureSelected?.procedure !== '') {
            let subProcess = data?.procedures?.tramites?.filter(x => x.tramiteCodigo == procedureSelected?.procedure)[0]?.subTramites?.map(item => {
                return { ...item, value: item.subTramiteCodigo, label: item.subTramiteDescripcion };
            });
            subProcess?.unshift({ value: "", label: "SELECCIONE UNA OPCION" })
            setListOfSubProcess(subProcess);
            setFiles([]);
            setFileButtons([]);
        }
    }, [procedureSelected?.procedure]);

    useEffect(() => {
        if (procedureSelected?.subProcedure !== '') {
            let auxAttachments = listOfSubProcess?.filter(x => x.subTramiteCodigo == procedureSelected?.subProcedure)[0]?.adjuntos;
            setListOfAttachmentButtons(auxAttachments);
            setFiles([]);
            setFileButtons([]);
        } else {
            setListOfAttachmentButtons([]);
            setError({...error, subProcedure: ''})
        }
    }, [procedureSelected?.subProcedure]);

    useEffect(() => {
        if (data.file !== null && data.file.result) {
            var a = document.createElement("a"); //Create <a>
            a.href = "data:image/png;base64," + data.file.base64; //Image Base64 Goes here
            a.download = "Image.png"; //File name Here
            a.click(); //Downloaded file
        }
    }, [data.file]);

    useEffect(() => {
        let process = data?.procedures?.tramites?.map(item => {
            return { value: item?.tramiteCodigo, label: item?.tramiteDescripcion };
        });
        if (process !== undefined) {
            process.unshift({ value: "", label: "SELECCIONE UNA OPCION" })
            setError({...error, procedure: '*Campo obligatorio'})
        }
        setListOfProcess(process);
    }, [data?.procedures])

    useEffect(() => {
        if (data?.postProcedure?.confirmacion != undefined) {
            if (data?.postProcedure?.confirmacion) {
                setModalIcon(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
                setModalTitle('SE HA REALIZADO LA GESTION CON EXITO!')
                setModalDescription('');
                setTimeout(() => { window.location.replace('/Billing') }, 2000)
            }
            else {
                setModalIcon(<CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
                setModalTitle('NO SE PUDO REALIZAR LA GESTION')
                setErrorClass(classes.backgroundError);
                setModalDescription('');
                setTimeout(() => { window.location.replace('/Billing') }, 2000)
            }
            setOpenSendSuccess(true);
        }
    }, [data?.postProcedure])

    const handleGoToHome = () => {
        window.location.href = '/Billing';
    };

    const mailLabel = ({ sWidth }) => (
        <span style={{ marginLeft: sWidth < 768 ? "-10px" : "10px" }}>E-MAIL</span>
    );

    const observationsLabel = ({ sWidth }) => (
        <span style={{ marginLeft: sWidth < 768 ? "-10px" : "1px" }}>OBSERVACIONES</span>
    );

    return (
        <>
            <FormHeader title={formHeader(screen).title} subtitle={formHeader(screen).subtitle} handleGoTo={handleGoToHome} />
            {
                screen.sWidth > 768 &&
                <Divider className={classes.formDivider} />
            }
            <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`} style={{ justifyContent: 'flex-start' }}>
                {screen.sWidth <= 768 &&
                    <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency}`} style={{ textTransform: 'uppercase', alignItems: 'center', display: 'flex' }}>
                        FACTURACIÓN Y COBRANZA
                    </Typography>
                }
            </div>
          
            <form className={`${classes.formBillingAndCollection}`}>
                {
                    listOfProcess?.length > 0 ?

                        <FormControl className={`${classes.formControlsAffiliations} ${classes.fixFormControlSelect}`} variant="standard">
                            <InputLabel className={`${classes.txtLabelTramites} ${classes.fixInputLabel} `} style={{ fontSize: "13px" }}>
                                {"TRAMITE"}
                            </InputLabel>
                            <CustomSelect
                                name='procedure'
                                selected={procedureSelected?.procedure}
                                list={listOfProcess}
                                onChange={handleChangeSelect}
                                label="" />
                            <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.procedure}</FormHelperText>
                        </FormControl>
                        :
                        <div style={{ width: "25%" }} className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}>
                            <CircularProgress />
                        </div>
                }
                {
                    listOfSubProcess?.length > 1 &&
                    <FormControl className={`${classes.formControlsAffiliations} ${classes.fixFormControlSelect}`} variant="standard">
                        <InputLabel className={`${classes.txtLabelTramites} ${classes.fixInputLabel} `}>
                            {"SUBTRAMITE"}
                        </InputLabel>
                        <CustomSelect
                            name='subProcedure'
                            selected={procedureSelected?.subProcedure}
                            list={listOfSubProcess}
                            onChange={handleChangeSelect}
                            label="" />
                        <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.subProcedure}</FormHelperText>
                    </FormControl>
                }
                <FormControl className={`${classes.formControlsAffiliations} ${classes.formControlEmail}`} variant="standard" style={{top:"1.5px"}}>
                    <CustomizedInputs
                        style={{ marginTop: '21px' }} 
                        name="email"
                        value={mail}
                        label={mailLabel(screen)}
                        onChange={handleChangeEmail(setMail, setMailError)}
                        disabled={disabledEmail}
                        endAdornment={
                            <InputAdornment position="start">
                                <IconButton onClick={handleEditEmail}>
                                    <EditIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormControlLabel className={`${classes.emailCheck}`} control={<Checkbox checked={emailChecked} onClick={handleCheck} color='primary' />} label="¿E-MAIL PERMANENTE?" />
                    <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.email}</FormHelperText>
                    <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{mailError}</FormHelperText>
                </FormControl>
                <FormControl className={`${classes.formControlsAffiliations}`} variant="standard">
                    <LineBreakInput
                        style={{ marginTop: '21px' }}
                        name="consulta"
                        label={observationsLabel(screen)}
                        placeholder='Escribe tu texto aqui'
                        multiline
                        rows={8}
                        inputProps={{ maxLength: 1000 }}
                        onChange={handleChangeSelect}
                        value={procedureSelected?.consulta}
                    />
                    <Typography variant="caption" style={{ marginLeft: screen.sWidth < 768 ? '290px' : "210px"}}>{counterLength}/1000</Typography>
                </FormControl>

                <div style={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'center', width: '90%', marginBottom: "15px" }}>
                <CustomButton
                    name='save'
                    style={{position: 'absolute', marginTop:"50px", right: screen.sWidth < 768 ? "36px" :"214px"}}
                    title='Enviar'
                    customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                    onClick={handleSubmit}
                />
                </div>

                <section style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', flexWrap: 'wrap' }}>
                    <div style={{ width: '250px' }}>

                        {listOfAttachmentButtons?.map((item, i) => (
                            <Fragment key={i}>
                                <CustomButton
                                    name={item?.adjuntoDescripcion}
                                    style={{ padding: '5px 15px 5px 5px' }}
                                    title={`ADJUNTAR ${item.adjuntoDescripcion}`}
                                    icon={<AttachFileIcon />}
                                    customClass={`${classes.lightLinearGradientReverse} ${classes.marginLeft}`}
                                    onClick={handleOpenModal(item?.adjuntoDescripcion, item?.adjuntoObligatorio)}
                                    disabled={isDisabled(fileButtons, item?.adjuntoDescripcion)}
                                />
                                {item?.adjuntoObligatorio === 'S' &&
                                    <>
                                        <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>*Archivo obligatorio</FormHelperText>
                                        <Typography style={{ flex: '1 1 100%', justifyContent: 'center' }} className={`${classes.txtObservaciones} ${classes.txtSubtitle}`}>ADJUNTAR ARCHIVOS</Typography>
                                        <span style={{ flex: '1 1 100%', fontSize: '10px', display: 'flex' }}>{`(TAMAÑO MAXIMO ${configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor}MB)`}</span>
                                    </>
                                }
                                {item?.adjuntoModelo !== '' &&
                                    <IconButton onClick={() => { dowloadFile(item?.adjuntoModelo); }}>
                                        <Typography className={`${classes.txtSubtitleBlue}`}>
                                            descargar Modelo
                                        </Typography>
                                    </IconButton>
                                }
                                {item?.adjuntoInstructivo !== '' &&
                                    <IconButton onClick={() => { dowloadFile(item?.adjuntoInstructivo); }}>
                                        <Typography className={`${classes.txtSubtitleBlue}`}>
                                            descargar Instructivo
                                        </Typography>
                                    </IconButton>
                                }
                            </Fragment>
                        ))}
                        {files?.map((item, i) => (
                            <p key={i} style={{ marginLeft: "5%" }} className={classes.txtSubtitleCalendar}>{item?.archivoNombre} <IconButton onClick={deleteFile(fileButtons, setFileButtons, setFiles, files, item?.btnNombre, item?.archivoNombre)}><HighlightOffIcon /></IconButton></p>
                        ))}
                    </div>
                </section>
            </form>
            <ConfirmModal isOpen={openSendSuccess} setOpen={setOpenSendSuccess} errorClass={errorClass}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalIcon}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalTitle}
                        </div>
                    </>
                }
                description={modalDescription} />
            <UploadModal
                title='Adjuntar archivo'
                description=''
                observation=''
                open={openUploadModal}
                onClose={setOpenUploadModal}
                setModalTitle={setModalTitle}
                setModalDescription={setModalDescription}
                setModalIcon={setModalIcon}
                setErrorClass={setErrorClass}
                setOpenSendSuccess={setOpenSendSuccess}
                setFiles={setFiles}
                files={files}
                fileButtons={fileButtons}
                setFileButtons={setFileButtons}
                itemName={nameOfItem}
                isRequired={itemRequired}
            />
        </>
    );
};

export default BillingAndCollection;

function isModifiedMailFunction(disabledEmail) {
    let response = 'N';
    if (!disabledEmail) {
        response = 'S';
    }
    return response;
}

function formHeader(screen) {
    let res = {
        title: 'FACTURACION Y COBRANZA',
        subtitle: ''
    };
    if (screen.sWidth < 768) {
        res.title = 'FACTURACION Y COBRANZA';
        res.subtitle = '';
    }
    return res;
}
