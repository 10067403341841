import { CardContent, Typography, Button, Card as MuiCard, IconButton } from "@material-ui/core";
import useStyles from "../../../assets/styles/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import RoomIcon from "@material-ui/icons/Room";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import FaceIcon from "@material-ui/icons/Face";
import AddIcon from "@material-ui/icons/Add";
import CustomButton from "../../../controls/Button/CustomButton";
import { formatPhoneNumber } from "../../../utils/utilsFunctions";

const MedicalCard = ({ item, i, handleClick, showMaps, mapContainer, currentPosition }) => {
    const classes = useStyles();

    return (
        <>
            <MuiCard key={i} className={`${classes.card} ${classes.movementsCards} ${classes.cartillaCards} ${classes.cartillaMuiCardStyles}`}>
                <CardContent style={{ padding: '8px' }} className={classes.cardContentMuiCard}>
                    <div className={classes.divCardContent} style={{ flexWrap: 'wrap' }}>
                        <section className={ classes.cartillaMedicalCenterSection }>
                            <div className={ classes.cartillaClasificacionPrestadorStyles }>
                                {
                                    item?.clasificacionPrestador === "CENTRO MEDICO" ? (
                                        <LocationCityIcon className={classes.LocationCityIcon} />
                                    ) : (
                                        <FaceIcon className={classes.LocationCityIcon} />
                                    )
                                }
                            </div>
                            <div className={`${classes.divTitleCardCartilla} ${classes.cartillaPrestadorRazonSocialStyles}`}>
                                <Typography style={{ fontSize: '0.8rem' }}>{item?.prestadorRazonSocial}</Typography>
                            </div>
                            <div className={ classes.cartillaButtonContainerStyles }>
                                <Typography style={{ padding: '0px', alignSelf: 'flex-start' }} component={Button} onClick={handleClick(item, i)}>
                                    <ChevronRightIcon className={classes.ShowMoreArrowIcon} />
                                </Typography>
                            </div>
                        </section>
                        <section className={ classes.cartillaPhonesSection }>
                            <div style={{ display: 'flex', flex: '0 0 100%' }}>
                                <a href={`tel:${item?.telefono[0]?.telefonoNumero}`} className={`${classes.txtLinksInCard} ${classes.txtDescription} ${classes.txtEllipsis}`}>
                                    {formatPhoneNumber(item?.telefono[0]?.telefonoNumero?.trim(), true)}
                                </a>
                                {
                                    item?.telefono?.length > 1 &&
                                    (
                                        <AddIcon className={`${classes.txtLinksInCard} ${classes.cartillaAddIconStyles}`} onClick={handleClick(item, i)}></AddIcon>
                                    )
                                }
                            </div>
                            <div className={ classes.cartillaPrestadorDomicilioContainer } style={{ alignItems: 'center' }}>
                                <div>
                                    <Typography className={`${classes.txtDescription} ${classes.cartillaAddressStyles}`}>
                                        {item?.prestadorDomicilio} | {item?.prestadorProvincia} | {item?.prestadorBarrio} | {item?.prestadorLocalidad}
                                    </Typography>
                                </div>
                                <div>
                                    {mapContainer ?
                                        <a
                                            target="_blank"
                                            style={{ textDecoration: "none" }}
                                            href={
                                                "https://www.google.com/maps/dir/?api=1&origin=" +
                                                currentPosition?.lat +
                                                "%2C" +
                                                currentPosition?.lng +
                                                "&destination=" +
                                                item?.prestadorLatitud +
                                                "%2C" +
                                                item?.prestadorLongitud +
                                                "&travelmode=driving"
                                            }
                                        >
                                            <CustomButton
                                                title={"como llegar"}
                                                customClass={`${classes.customBtnMaps} ${classes.darkLinearGradient}`}
                                            />
                                        </a>
                                        :
                                        <IconButton onClick={showMaps(item, i)} style={{ marginRight: '10px' }}>
                                            <RoomIcon className={ classes.cartillaRoomIconStyles } />
                                        </IconButton>
                                    }
                                </div>
                            </div>
                            {item?.mail[0]?.mailDireccion?.trimEnd() !== '' &&
                                <div style={{ display: 'flex', flex: '0 0 100%' }}>
                                    <a href={`mailto:${item?.mail[0]?.mailDireccion}`} className={`${classes.txtLinksInCard} ${classes.txtDescription} ${classes.txtEllipsis}`}>
                                        {item?.mail[0]?.mailDireccion?.trimEnd()}
                                    </a>
                                    <AddIcon style={{ fontWeight: "600", cursor: "pointer" }} className={`${classes.txtLinksInCard}`} onClick={handleClick(item, i)}></AddIcon>
                                </div>
                            }
                            <div className={ classes.cartillaObservacionStyles }>
                                <Typography className={`${classes.txtDescription}`} style={{ fontSize: '0.67rem' }}>
                                    {item?.observacion}
                                </Typography>
                            </div>
                        </section>
                    </div>
                </CardContent>
            </MuiCard>
        </>
    );
};

export default MedicalCard;