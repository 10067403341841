import { CircularProgress, Divider, Typography, Accordion, AccordionSummary, AccordionDetails, FormControl } from "@material-ui/core";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import FormHeader from "../../PersonalData/FormHeader";
import CustomButton from "../../../controls/Button/CustomButton";
import useStyles from '../../../assets/styles/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomizedInputs from "../../../controls/InputCustom/CustomizedInputs";
import { useDispatch, useSelector } from 'react-redux';
import { getVaccine } from "../../../redux/actions/medicalDataAction";
import { useEffect, useState } from "react";
import NotFound from "../../HomeLogged/NotFound";
import { handleGoToMedicalData } from "../../../utils/utilsFunctions";
import CustomSelect from "../../../controls/Select/CustomSelect";
import { getDataGroupFamily } from "../../../redux/actions/appointmentsActions";


const persona = {
    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
    parametroLlamado: "FILTRO VACUNAS"
};

const Vaccine = () => {
    const { screen } = useResizeScreen();
    const classes = useStyles();
    const dispatch = useDispatch();
    const vaccines = useSelector(state => state.medicalDataReducer?.vaccine);
    const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations);
    const [setOpenPdf] = useState(false);
    const familyGroup = useSelector(state => state.appointmentsReducer?.groupFamilyTurn);
    const [familyGroupList, setFamilyGroupList] = useState([{}]);
    const [affiliatedSelected, setAffiliatedSelected] = useState(persona.numeroPersona);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(getDataGroupFamily(persona));
    }, [affiliatedSelected]);

    const param = {
        numeroPersona: parseInt(affiliatedSelected),
        count: 0,
        skip: 0
    };

    useEffect(() => {
        dispatch(getVaccine(param));
          setLoading(false)
    }, [affiliatedSelected]);

    const handleCalender = e => {
        e.preventDefault();
        setOpenPdf(prevState => !prevState);
    };

    const handleChangeSelect = (e) => {
        const { value } = e.target;
        setAffiliatedSelected(parseInt(value));
    };

    function formHeader() {
        let res = {
            title: 'CALENDARIO DE VACUNAS',
            subtitle: ''
        };
        if (screen.sWidth < 768) {
            res.title = 'VACUNAS';
        }
        return res;
    }

    useEffect(() => {
        if (Object.values(familyGroup).length > 0) {
            const itemsToPush = familyGroup.map(item => {
                return { value: item.itemCodigo, label: item.itemDescripcion.trim() };
            });
            setFamilyGroupList(itemsToPush);
        }
    }, [familyGroup]);

    useEffect(() => {
        if (Object.values(familyGroupList).length > 0) {
            var aux = affiliatedSelected;
            setAffiliatedSelected(aux);
        }
    }, [familyGroupList]);

    const accordionVaccine = (item, i) => {
        return (
            <Accordion key={i}  >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
                        <Typography className={classes.txtSubtitleCalendar} >
                            {`Vacuna ${item.vacunaTipo}`}
                        </Typography>
                        <div style={{ width: "100%" }}>
                            <Divider className={classes.dividerMedicalCenter} />
                        </div>
                    </div>

                </AccordionSummary>
                <AccordionDetails >
                    {<>
                        <div className={classes.displayFlex}>
                            <Typography className={classes.detailsAccordionChronic}>{item.vacunaDescripcion}</Typography>
                        </div>
                        <div className={classes.displayFlex}>
                            <Typography className={classes.detailsAccordionChronic}>{"Lote: " + item.vacunaLote}</Typography>
                        </div>
                        <div className={classes.displayFlex}>
                            <Typography className={classes.detailsAccordionChronic}>{"Dosis: " + item.vacunaDosis}</Typography>
                        </div>
                        <div className={classes.displayFlex}>
                            <Typography className={classes.detailsAccordionChronic}>{"Enfermero: " + item.vacunaEnfermero}</Typography>
                        </div>
                        <div className={classes.displayFlex}>
                            <Typography className={classes.detailsAccordionChronic}>{"Matricula: " + item.vacunaMatricula}</Typography>
                        </div>
                        <div className={classes.displayFlex}>
                            <Typography className={classes.detailsAccordionChronic}>{"FECHA DE APLICACION"}</Typography>
                        </div>
                        <FormControl className={`${classes.formControlVaccine}`} variant="standard">
                            <CustomizedInputs value={(new Date(item.vacunaFecha).toLocaleDateString().split('T')[0])} disabled={true} />
                        </FormControl>
                    </>}
                </AccordionDetails>

            </Accordion>);
    };

    useEffect(() => {
        if (Object.values(configurationReduxState).length > 0) {
           // setLoadingPdf(false);
        }
    }, [configurationReduxState]);

    return (
        <>
            <FormHeader title={formHeader().title} handleGoTo={handleGoToMedicalData} />
            <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                {screen.sWidth <= 768 &&
                    <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency}`} style={{ textTransform: 'uppercase' }}>
                        CALENDARIO DE VACUNAS
                    </Typography>
                }
            </div>
            <div style={{ width: '230px', paddingLeft: screen.sWidth > 768 ? "2%" : "0" }}>
                <FormControl className={`${classes.formControlChronicMedicationForm} ${classes.formControlSelect}`} variant="standard">
                    <CustomSelect
                        name={`familyGroup`}
                        list={familyGroupList}
                        selected={affiliatedSelected}
                        onChange={handleChangeSelect}
                        label='AFILIADO'
                        style={{marginTop: screen.sWidth < 768 ? "20px" : "0"}}
                    />
                </FormControl>
            </div>
            <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
            <a
                    href={configurationReduxState?.configurations?.filter(x => x.id === 'calendarioVacunas')[0]?.valor}
                    download="calendario.pdf"
                    style={{textDecoration: "none"}}
                    target="_blank"
                    >
                    
                <CustomButton
                    customStyles={{ width: '168px'}}
                    title={'DESCARGAR CALENDARIO'}
                    customClass={`${classes.downloadVaccinesBtn} ${classes.lightLinearGradient} ${classes.margin0}`}
                    // onClick={handleCalender}
                />
                    </a>
            </div>
            {loading ?
                <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
                :
                vaccines?.vacunas?.length === 0 || vaccines?.vacunas?.length === undefined || loading
                    ?
                        <div style={{ display: 'flex', justifyContent: 'center', flex: '0 0 100%', paddingTop: '10px', marginTop: "20px" }}>
                            <NotFound customMsg={
                                <div className={classes.contentNotFound}>
                                    <span>No tenés vacunas aplicadas en Luis Pasteur</span>
                                </div>
                            }
                                wsItem={vaccines?.vacunas}
                                fullItem={vaccines}
                            />
                        </div>
                    :
                        <>
                            <Typography
                                className={`${classes.titleVaccine} ${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency}`}
                                style={{ textTransform: 'uppercase', marginTop: "20px" }}
                            >
                                VACUNAS APLICADAS EN CENTROS MEDICOS
                            </Typography>
                            <div className={classes.accordionListVaccine}>
                                {vaccines?.vacunas?.map((item, i) => (
                                    <div key={i} className={classes.accordionVaccine}>
                                        {accordionVaccine(item, i)}
                                    </div>
                                ))}
                            </div>
                        </>
            }
        </>
    );
};

export default Vaccine;
