
import { CircularProgress } from "@material-ui/core";
import React from "react";

import { AuthConsumer } from "./providers/authProvider";

export const SilentRenew = () => (
    <AuthConsumer>
        {({ signinSilentCallback }) => {
           signinSilentCallback();
            return <div style={{display:"flex", justifyContent:"center"}}><CircularProgress /></div>;
        }}
    </AuthConsumer>
);

export default SilentRenew;