import { Box, Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from '../../../assets/styles/styles';

const Details = ({ item }) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        '',
        'Iniciada',
        'En proceso',
        'Autorizado',
        'Finalizado',
    ];

    useEffect(() => {
        if (item?.authorizationDetail?.autorizacionDetalle !== undefined) {
            setActiveStep(item?.authorizationDetail?.autorizacionDetalle[0]?.autorizacionEstadoCodigo);
        }
    }, [item]);

    return (<>
        <Box sx={{ width: '100%' }} className={classes.boxSteps}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps?.map(step => (
                    <Step key={step}>
                        <StepLabel className={classes.stepLabelTab}></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
        {item?.authorizationDetail?.autorizacionDetalle?.length > 0 &&
            <section style={{ padding: '5%' }}>
                <item>
                    <Typography style={{ fontSize: '14px', fontWeight: "600", color: '#0075c9' }} className={`${classes.txtCardDescription}`}>
                        RESUMEN DE LA INFORMACION
                    </Typography>
                    <Typography style={{ fontSize: '14px', paddingTop: '8px', paddingBottom: '8px' }} className={`${classes.txtCardDescription}`}>
                        {item?.authorizationDetail?.autorizacionDetalle[0]?.autorizacionAfiliadoSolicitante}
                    </Typography>
                    <Typography style={{ fontSize: '14px', paddingBottom: '8px' }} className={`${classes.txtCardDescription}`}>
                        ESTADO: <span style={{ color: '#0075c9' }}>{item?.authorizationDetail?.autorizacionDetalle[0]?.autorizacionEstadoDescripcion}</span>
                    </Typography>
                    <Typography style={{ fontSize: '12px', paddingBottom: '5px', fontWeight: '800' }} className={`${classes.txtCardDescription}`}>
                        FECHA DE SOLICITUD:
                            <span style={{ color: '#000000', fontWeight: '500', marginLeft: '5px' }}>
                                {new Date(item?.authorizationDetail?.autorizacionDetalle[0]?.autorizacionGestionFecha?.replace(/\s/, 'T'))?.toLocaleDateString('es-ES')}
                            </span>
                    </Typography>
                    <Typography style={{ fontSize: '12px', paddingTop: '5px', paddingBottom: '5px', fontWeight: '800' }} className={`${classes.txtCardDescription}`}>
                        TIPO: <span style={{ color: '#000000', fontWeight: '500' }}>{item?.authorizationDetails?.autorizacionTipoPrestacionDescripcion}</span>
                    </Typography>
                    <Typography style={{ fontSize: '12px', paddingTop: '5px', paddingBottom: '5px', fontWeight: '800' }} className={`${classes.txtCardDescription}`}>
                        DETALLE DE PRESCRIPCION:
                        <span
                            style={{ color: '#000000', fontWeight: '500' }}>
                            {item?.authorizationDetail?.autorizacionDetalle[0]?.autorizacionTipoPrestacionDescripcion !== ''
                            ? item?.authorizationDetail?.autorizacionDetalle[0]?.autorizacionTipoPrestacionDescripcion
                            : " No hay detalles" }
                        </span>
                    </Typography>
                    <Typography style={{ fontSize: '12px', paddingTop: '5px', paddingBottom: '5px', fontWeight: '800' }} className={`${classes.txtCardDescription}`}>
                        SOLICITANTE: <span style={{ color: '#000000', fontWeight: '500' }}>{item?.authorizationDetail?.autorizacionDetalle[0]?.autorizacionAfiliadoSolicitante}</span>
                    </Typography>
                    <Typography style={{ fontSize: '12px', paddingTop: '5px', paddingBottom: '5px', fontWeight: '800' }} className={`${classes.txtCardDescription}`}>
                        FECHA DE REALIZACION:
                            <span style={{ color: '#000000', fontWeight: '500', marginLeft: '5px' }}>
                                {new Date(item?.authorizationDetail?.autorizacionDetalle[0]?.autorizacionFechaRealizacion?.replace(/\s/, 'T'))?.toLocaleDateString('es-ES')}
                            </span>
                    </Typography>
                    <Typography style={{ fontSize: '12px', paddingTop: '5px', paddingBottom: '5px', fontWeight: '800' }} className={`${classes.txtCardDescription}`}>
                        TELEFONO DE CONTACTO: <span style={{ color: '#000000', fontWeight: '500' }}>{item?.authorizationDetail?.autorizacionDetalle[0]?.autorizacionTelefono}</span>
                    </Typography>
                    <Typography style={{ fontSize: '12px', paddingTop: '5px', paddingBottom: '5px', fontWeight: '800' }} className={`${classes.txtCardDescription}`}>
                        E-MAIL DE CONTACTO: <span style={{ color: '#000000', fontWeight: '500' }}>{item?.authorizationDetail?.autorizacionDetalle[0]?.autorizacionEmail}</span>
                    </Typography>
                </item>
            </section>
        }
    </>);
};

export default React.memo(Details, (prevState, nextState) => {
    return prevState.item === nextState.item;
});