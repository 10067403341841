import React, { Fragment, useEffect, useState } from "react";
import useStyles from "../../assets/styles/styles";
import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from '@material-ui/core/Button';
import Moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { getNextAvailableAppointments, setModelToSaveAppointment } from '../../redux/actions/appointmentsActions';
import ModalObservations from "./Modals/ModalObservations";
import FooterDrawer from "./FooterDrawer";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import NotFound from "../../components/HomeLogged/NotFound";
import { getFullData } from '../../redux/actions/personalDataActions';

const NextAppointmentDrawer = (props) => {
  const { activeStep, setActiveStep, handleOpenCancelModal } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const appointments = useSelector((state) => state.appointmentsReducer);
  const [openModal, setOpenModal] = useState(false);
  const [medico, setMedico] = useState();
  const modelNextAppointment = useSelector(state => state.appointmentsReducer);
  const [modelAppointment, setModelAppointment] = useState(modelNextAppointment?.modelAppointment);
  const countItems = 10;
  const [skipItems, setSkipItems] = useState(0);
  const { screen } = useResizeScreen();
  const [disableNextButton, setDisableNextButton] = useState(true);
  const [selectedAppointment, setSelectedAppointment] = useState();

  const persona = {
    especialidadCodigo: modelNextAppointment?.modelAppointment?.especialidadCodigo?.toString(),
    centroMedico: modelNextAppointment?.modelAppointment?.centroMedicoCodigo == undefined ? 0 : modelNextAppointment?.modelAppointment?.centroMedicoCodigo,
    count: countItems,
    skip: skipItems,
    tipoAtencion: modelNextAppointment?.modelAppointment?.modalidadTurno == 'Telemedicina' ? 'TELEMEDICINA' : 'PRESENCIAL'
  };

  useEffect(() => {
    dispatch(getNextAvailableAppointments(persona));
  }, [skipItems]);

  useEffect(() => {
    dispatch(getNextAvailableAppointments(persona));
    setModelAppointment(modelNextAppointment?.modelAppointment);
    dispatch(getFullData({ numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")) }));
  }, []);

  useEffect(() => {
      if (modelAppointment?.turnoNumero) {
      setDisableNextButton(false);
    }
  }, [modelAppointment?.turnoNumero]);

  const handleClick = (item) => {
    setMedico(item);
    setSelectedAppointment(item);
    setModelAppointment({
      ...modelAppointment,
      medicoCodigo: item.medicoCodigo,
      medicoDescripcion: item.medicoDescripcion,
      selectedAppointment: item.turnoFechHora,
      hourSelected: Moment(item.turnoFechHora).format('HH:mm'),
      turnoNumero: item.turnoNumero
    });
    dispatch(setModelToSaveAppointment({
      ...modelAppointment,
      medicoCodigo: item.medicoCodigo,
      medicoDescripcion: item.medicoDescripcion,
      selectedAppointment: item.turnoFechHora,
      hourSelected: Moment(item.turnoFechHora).format('HH:mm'),
      turnoNumero: item.turnoNumero
    }));
    setOpenModal(prevState => !prevState);
  };

  const handleClickSeeLess = () => {
    setSkipItems(skipItems - 10);
  };

  const handleClickSeeMore = () => {
    setSkipItems(skipItems + 10);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 2);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 2);
  };


  const listItems = (count) => {
    let list = '';
    list =
      <List
        component="nav"
        className={classes.root}
        aria-label="mailbox folders"
        style={{ marginBottom: "5px", maxHeight: "496px", overflow: "auto" }}
      >
        {appointments?.nextAvailableAppointments?.turnosLibresPorEspecialidad?.slice(0, count).filter(x => x.especialidadCodigo === modelAppointment?.especialidadCodigo).map((item, i) => (
          <Fragment key={i}>
            <ListItem className={classes.listItemTurn}>
              <Button
                onClick={() => {handleClick(item)}}
                className={selectedAppointment === item || modelAppointment?.turnoNumero == item.turnoNumero ? classes.btnHoverNextAppointment : classes.btnNextAppointment}>
                <div className={classes.divDate}>
                  <Typography className={classes.dateNext}>
                    {Moment(item.turnoFechHora).format('DD.MM.YYYY')}
                  </Typography>
                  <Typography className={classes.descripNext} style={{ margin: '0 auto' }}>
                    {item.medicoDescripcion}
                  </Typography>
                </div>
                <div className={classes.divHour}>
                  <div>
                    <Typography className={classes.hourNext} >
                      {Moment(item.turnoFechHora).format('HH:mm')}
                    </Typography>
                  </div>
                </div>
              </Button>
            </ListItem>
          </Fragment>
        ))}
      </List>;
    return list;
  };

  const showButtons = () => {
    if (skipItems === 0) {
      return (
        <Button size="small" style={{ float: "right" }} onClick={handleClickSeeMore}>
          SIGUIENTES
        </Button>
      );
    } else if (countItems > appointments?.nextAvailableAppointments?.turnosLibresPorEspecialidad?.length) {
      return (
        <Button size="small" style={{ float: "right" }} onClick={handleClickSeeLess}>
          ANTERIORES
        </Button>
      );
    }
    else {
      return (
        <>
          <Button size="small" style={{ float: "right", margin: "10px" }} onClick={handleClickSeeMore}>
            SIGUIENTES
          </Button>
          <Button size="small" style={{ float: "right", margin: "10px" }} onClick={handleClickSeeLess}>
            ANTERIORES
          </Button>
        </>
      );
    }
  };

  return (
    <>
      <div className={classes.drawerContainerTurn} style={{ backdropFilter: "blur(8px) !important" }}>
        {screen.sWidth < 768 && <> <Typography
          className={classes.titleTurn}
          style={{ marginTop: "0px" }}
        >
          {props.titles.map((titles) => titles.title7)}
        </Typography>

          <Typography
            style={{ color: "#002858", fontWeight: "bold", fontSize: "17px" }}
          >
            {modelAppointment?.especialidadDescripcion}
          </Typography>

          <Typography
            style={{ color: "#54c0e8", fontWeight: "400", fontSize: "15px" }}
          >
            {modelAppointment?.centroMedico}
          </Typography></>
        }
        {((appointments?.nextAvailableAppointments?.turnosLibresPorEspecialidad?.length > 0) && (modelNextAppointment?.modelAppointment?.preference !== "")) ?
          <>
            {(listItems(countItems))}
            {showButtons()}
          </>
          :
          (
            modelNextAppointment?.modelAppointment?.preference !== "" &&
            <NotFound
              customMsg={
                <div className={classes.contentNotFound}>
                  <span style={{ marginTop: "20%" }}>No hay turnos disponibles</span>
                </div>}
                wsItem={appointments?.nextAvailableAppointments?.turnosLibresPorEspecialidad}
                fullItem={appointments?.nextAvailableAppointments?.turnosLibresPorEspecialidad}
                loading= {appointments?.isLoading}
            />
          )
        }
        {openModal &&
          <ModalObservations
            medico={medico}
            openModal={openModal}
            setOpenModal={setOpenModal}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          ></ModalObservations>
        }
      </div>
      {screen.sWidth < 768 && modelNextAppointment?.modelAppointment?.doctorVisualizacion !== 'AGENDA SUSPENDIDA' &&
        <FooterDrawer
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          handleOpenCancelModal={handleOpenCancelModal}
          disabledNext={disableNextButton}
        />}
    </>
  );
};

export default NextAppointmentDrawer;