import React, { useEffect, useState } from "react";
import useFilters from "../../hooks/useFilters";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import "./medicalFilters.css";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanPerfilCompleto,
  getEspecialidadesEstudios,
  getLocalidadesBarrios,
  getPrestadoresCartilla,
  setLoadingSearchMedicalRecord,
  setModelFiltersCartilla,
  setSelectedFiltersCartilla
} from "../../redux/actions/medicalRecords";
import { useHistory } from "react-router";
import { isAuthenticated } from "../../utils/utilsFunctions";
import MedicalForm from "./MedicalForm";

const modelFiltersCartilla = {
  Titular: null,
  Miembro: window.localStorage.getItem('numeroPersona'),
  FiltroTipoPrestador: "",
  FiltroEspecialidad: 0,
  FiltroEstudioComplementario: 0,
  FiltroPlan: 0,
  FiltroZona: 0,
  FiltroLocalidad: [],
  FiltroRazonSocial: "",
  Count: 0,
  Skip: 0,
};

const filtersSelected = {
  plan: "",
  zone: "",
  city: "",
  provider: "",
  department: "",
  alphabeticalSearch: "",
};

const MedicalFilters = ({ isMobile, setNotFound, loadingSearch, setLoadingSearch }) => {
  const dispatch = useDispatch();
  const filtersCartilla = useSelector((state) => state.cartillaReducer);
  const resizeScreen = useResizeScreen();
  const { values, setValues, errors, setErrors } = useFilters(filtersCartilla.filters);
  const [modelCartilla, setModelCartilla] = useState(modelFiltersCartilla);
  const [selectedValues, setSelectedValues] = useState(filtersSelected);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disableCity, setDisableCity] = useState(true);
  const personalDataReducer = useSelector(state => state.personalDataReducer);
  const [userPlan, setUserPlan] = useState({ value: "", label: "" });
  const [disableZone, setDisableZone] = useState(true);
  const [count, setCount] = useState(0);
  const [disableProvider, setDisableProvider] = useState(true);
  const [disableAlphabeticSearch, setDisableAlphabeticSearch] = useState(true);
  const history = useHistory();
  const [planes, setPlanes] = useState([{ itemCodigo: 0, itemDescripcion: '' }]);
  const [zonas, setZonas] = useState([{ itemCodigo: 0, itemDescripcion: '' }]);
  const [tipoPrestador, setTipoPrestador] = useState([{ itemCodigo: 0, itemDescripcion: '' }]);

  const handleSubmit = (e) => {
    e.preventDefault();
    validateSubmit({ dispatch, setNotFound, setBtnDisabled, values, setErrors, modelCartilla, selectedValues, history, setModelCartilla });
  };

  const resetLocalidadBarrio = () => {
    setValues({ ...values, city: "" });
    setModelCartilla({
      ...modelCartilla,
      FiltroLocalidad: [],
    });
    setSelectedValues({
      ...selectedValues,
      city: "",
    });
  };

  const setOptionsCartilla = (name, nameModel, newValueItem, typeValue) => {
    setValueFunction({
      name, nameModel, newValueItem, typeValue, dispatch, setValues,
      values, setModelCartilla, modelCartilla, setSelectedValues, selectedValues, setUserPlan, count
    });
  };

  const handleCleanSearch = () => {
    setSelectedValues(filtersSelected);
    setValues({
      alphabeticalSearch: "",
      city: "",
      department: "",
      plan: "",
      provider: "",
      zone: 0
    });
    dispatch(setSelectedFiltersCartilla({
      alphabeticalSearch: "",
      city: "",
      department: "",
      plan: "",
      provider: "",
      zone: "",
    }));
    dispatch(setModelFiltersCartilla({
      Count: 0,
      FiltroEspecialidad: 0,
      FiltroEstudioComplementario: 0,
      FiltroLocalidad: [],
      FiltroPlan: "",
      FiltroRazonSocial: "",
      FiltroTipoPrestador: "",
      FiltroZona: 0,
      Miembro: "",
    }));
    setUserPlan({ itemCodigo: '', itemDescripcion: '' });
    dispatch(cleanPerfilCompleto());
  };

  const validateLocalStorage = () => {
    if (window.localStorage.getItem("modelCartilla") && window.localStorage.getItem("selectedValues") && window.localStorage.getItem("values")) {
      let auxSelected = JSON.parse(window.localStorage.getItem("selectedValues"));
      let auxModelCartilla = JSON.parse(window.localStorage.getItem("modelCartilla"));
      setSelectedValues(auxSelected);
      setModelCartilla(auxModelCartilla);
      setValues(JSON.parse(window.localStorage.getItem("values")));
      setUserPlan({ itemCodigo: auxModelCartilla?.FiltroPlan, itemDescripcion: auxSelected?.plan });
    } else {
      setUserPlan(null);
    }
  };

  const resetCity = () => {
    if (!validateLocalStorageBool()) {
      setValues({ ...values, city: '' });
      setModelCartilla({
        ...modelCartilla,
        FiltroLocalidad: []
      });
      setSelectedValues({
        ...selectedValues,
        city: ''
      });
    }
  };

  useEffect(() => {
    validateLocalStorage();
  }, []);

  useEffect(() => {
    setBtnDisabled(false);
    setNotFoundFunction(resizeScreen, setNotFound);
  }, [selectedValues]);

  useEffect(() => {
    setLoadingFunction(filtersCartilla, setLoading);
  }, [filtersCartilla.filters]);

  useEffect(() => {
    if (values?.plan > 0) {
      setDisableZone(false);
    } else {
      setDisableZone(true);
      setDisableProvider(true);
      setDisableCity(true);
      setDisableAlphabeticSearch(true);
      setDisable(true);
      if (!validateLocalStorageBool()) {
        resetLocalidadBarrio();
        resetEstudiosEspecialidadValues(setValues, values, setSelectedValues, selectedValues, setModelCartilla, modelCartilla);
      }
    }
  }, [values?.plan]);

  useEffect(() => {
    if (values?.zone > 0) {
      setDisableCity(false);
    } else {
      setDisableProvider(true);
      setDisableCity(true);
      setDisableAlphabeticSearch(true);
      setDisable(true);
      if (!validateLocalStorageBool()) {
        resetLocalidadBarrio();
        resetEstudiosEspecialidadValues(setValues, values, setSelectedValues, selectedValues, setModelCartilla, modelCartilla);
      }
    }
    resetCity();
  }, [values?.zone]);

  useEffect(() => {
    if (values?.city !== null && values?.city?.length > 0) {
      setDisableProvider(false);
    } else {
      setDisableProvider(true);
      setDisableAlphabeticSearch(true);
      setDisable(true);
      if (!validateLocalStorageBool()) {
        resetLocalidadBarrio();
        resetEstudiosEspecialidadValues(setValues, values, setSelectedValues, selectedValues, setModelCartilla, modelCartilla);
      }
    }
  }, [values?.city]);

  useEffect(() => {
    if (values.provider > 0) {
      setDisable(false);
      setDisableAlphabeticSearch(false);
    } else {
      setDisable(true);
      setDisableAlphabeticSearch(true);
      if (!validateLocalStorageBool()) {
        resetEstudiosEspecialidadValues(setValues, values, setSelectedValues, selectedValues, setModelCartilla, modelCartilla);
      }
    }
    validatePharmacyAndOptics(setDisable, values, modelCartilla, setModelCartilla, selectedValues, setSelectedValues, setValues);
  }, [values?.provider]);

  useEffect(() => {
    if (window.localStorage.getItem("numeroPersona") && Object.values(personalDataReducer?.fullData).length > 0) {
      let userPlanAux = {
        itemCodigo: personalDataReducer?.fullData?.afiliado?.planCodigo.toString(),
        itemDescripcion: personalDataReducer?.fullData?.afiliado?.planDescripcion?.trim()
      };

      setUserPlan(userPlanAux);
      setOptionsCartilla(
        "plan",
        "FiltroPlan",
        userPlanAux,
        "string"
      );
    }
  }, [personalDataReducer?.fullData]);

  useEffect(() => {
    if (userPlan?.itemCodigo !== '') {
      setDisableZone(false);
      setCount(prevState => prevState + 1);
    }
  }, [userPlan]);

  useEffect(() => {
    if (filtersCartilla?.filters?.plan?.length > 0) {
      let aux = [];
      if (isAuthenticated()) {
        filtersCartilla.filters.plan.map(item => {
          let plan = { itemCodigo: item.planCodigo, itemDescripcion: item.planDescripcion.trim() };
          aux.push(plan);
        });
      } else {
        filtersCartilla.filters.plan.filter(x => x.planVisibleSinLogueo === true).map(item => {
          let plan = { itemCodigo: item.planCodigo, itemDescripcion: item.planDescripcion.trim() };
          aux.push(plan);
        });
      }
      let aux2 = [];
      filtersCartilla.filters.zona.map(item => {
        let zona = { itemCodigo: item.zonaCodigo, itemDescripcion: item.zonaDescripcion.trim() };
        aux2.push(zona);
      });
      let aux3 = [];
      filtersCartilla.filters.tipoPrestador.map(item => {
        let prestador = { itemCodigo: item.tipoPrestadorCodigo, itemDescripcion: item.tipoPrestadorDescripcion.trim() };
        aux3.push(prestador);
      });
      setPlanes(aux);
      setZonas(aux2);
      setTipoPrestador(aux3);
    }
  }, [filtersCartilla.filters]);

  return (
    <>
      {!isMobile && filtersCartilla.filters !== undefined &&
        (
          <MedicalForm
            handleSubmit={handleSubmit}
            setOptionsCartilla={setOptionsCartilla}
            planes={planes} errors={errors}
            setPlanes={setPlanes}
            zonas={zonas} tipoPrestador={tipoPrestador}
            setZonas={setZonas}
            setTipoPrestador={setTipoPrestador}
            loading={loading}
            userPlan={userPlan}
            filtersCartilla={filtersCartilla}
            handleCleanSearch={handleCleanSearch}
            modelCartilla={modelCartilla}
            selectedValues={selectedValues}
            setModelCartilla={setModelCartilla}
            disableAlphabeticSearch={disableAlphabeticSearch}
            setSelectedValues={setSelectedValues}
            disableZone={disableZone}
            values={values}
            disableCity={disableCity}
            disableProvider={disableProvider}
            disable={disable}
            btnDisabled={btnDisabled}
            setValues={setValues}
          />
        )
      }
    </>
  );
};
export default React.memo(MedicalFilters);

function validateValues(filterValues) {
  let temp = {};
  temp.plan = filterValues.plan > 0 ? "" : "*Campo obligatorio";
  temp.zone = filterValues.zone > 0 ? "" : "*Campo obligatorio";
  temp.city = filterValues.city && filterValues.city.length > 0 ? "" : '*Campo obligatorio';
  temp.provider = filterValues.provider && filterValues.provider.length > 0 ? "" : "*Campo obligatorio";
  if (filterValues.provider === '070' || filterValues.provider === '160' || filterValues.provider === '210') {
    temp.department = '';
  } else {
    temp.department = filterValues.department && filterValues.department.length > 0 ? "" : "*Campo obligatorio";
  }
  return temp;
}

function validatePharmacyAndOptics(setDisable, values, modelCartilla, setModelCartilla, selectedValues, setSelectedValues, setValues) {
  if (values.provider === '070' || values.provider === '160' || values.provider === '210') {
    setDisable(true);
  }
  if (!validateLocalStorageBool()) {
    setModelCartilla({ ...modelCartilla, FiltroEspecialidad: null });
    setValues({ ...values, department: '' });
    setSelectedValues({
      ...selectedValues,
      department: '',
    });
  }
}

function validateSubmit({ dispatch, setNotFound, setBtnDisabled, values, setErrors, modelCartilla, selectedValues, history, setModelCartilla }) {
  if (validate(values, setErrors)) {
    dispatch(cleanPerfilCompleto());
    dispatch(setLoadingSearchMedicalRecord(true));
    setNotFound(true);
    setBtnDisabled(true);
    dispatch(getPrestadoresCartilla(modelCartilla));
    dispatch(setSelectedFiltersCartilla(selectedValues));
    dispatch(setModelFiltersCartilla(modelCartilla));
    let aux = {};
    aux.city = values.city;
    aux.department = values.department;
    aux.plan = values.plan;
    aux.provider = values.provider;
    aux.zone = values.zone;
    window.localStorage.setItem("modelCartilla", JSON.stringify(modelCartilla));
    window.localStorage.setItem("selectedValues", JSON.stringify(selectedValues));
    window.localStorage.setItem("values", JSON.stringify(aux));
    history.push('/MedicalRecords?type=Records');
  }
}

function setLoadingFunction(filtersCartilla, setLoading) {
  if (filtersCartilla.filters != undefined) {
    setLoading(true);
  }
}

function validateLocalStorageBool() {
  let response = false;
  if (window.localStorage.getItem("modelCartilla") && window.localStorage.getItem("selectedValues") && window.localStorage.getItem("values")) {
    response = true;
  }
  return response;
}

const changeLocalties = props => {
  const {
    values, name, newValueItem, modelCartilla,
    nameModel, selectedValues, setModelCartilla,
    setSelectedValues, setValues
  } = props;

  if (newValueItem?.length > 5) {
    newValueItem?.pop();
  }

  setValues({ ...values, [name]: newValueItem?.map(item => item?.itemCodigo) });

  setModelCartilla({
    ...modelCartilla,
    [nameModel]: newValueItem?.map(item => Number.parseInt(item?.itemCodigo)),
  });

  setSelectedValues({
    ...selectedValues,
    [name]: newValueItem?.map(item => item?.itemDescripcion)?.join(', '),
  });
}

const changeIntInputs = props => {
  const {
    values, name, newValueItem, modelCartilla,
    nameModel, selectedValues, setModelCartilla,
    setSelectedValues, setValues, dispatch
  } = props;

  setValues({ ...values, [name]: newValueItem?.itemCodigo });

  setModelCartilla({
    ...modelCartilla,
    [nameModel]: Number.parseInt(newValueItem?.itemCodigo),
  });

  setSelectedValues({
    ...selectedValues,
    [name]: newValueItem?.itemDescripcion,
  });

  if (name === "zone") {
    dispatch(getLocalidadesBarrios(newValueItem?.itemCodigo));
  }
}

const changeStringInputs = props => {
  const {
    values, name, newValueItem, modelCartilla,
    nameModel, selectedValues, setModelCartilla,
    setSelectedValues, setValues, dispatch, setUserPlan
  } = props;

  setValues({ ...values, [name]: newValueItem?.itemCodigo });

  setModelCartilla({
    ...modelCartilla,
    [nameModel]: newValueItem?.itemCodigo,
  });

  setSelectedValues({
    ...selectedValues,
    [name]: newValueItem?.itemDescripcion,
  });

  if (name === "provider") {
    dispatch(getEspecialidadesEstudios(newValueItem?.itemCodigo));
  } else if (name === "plan") {
    setUserPlan({ itemCodigo: newValueItem?.itemCodigo, itemDescripcion: newValueItem?.itemDescripcion });
  }
}

const resetValues = props => {
  const {
    setValues, values, setSelectedValues,
    selectedValues, setModelCartilla, modelCartilla, name,
    nameModel, newValueItem, count, setUserPlan
  } = props;

  if (name === "department") {
    resetEstudiosEspecialidadValues(setValues, values, setSelectedValues, selectedValues, setModelCartilla, modelCartilla);
  } else {
    if (name === "plan" && count > 0) {
      setUserPlan({ itemCodigo: '', itemDescripcion: '' });
    }
    let aux = newValueItem;

    if (aux == null) {
      aux = '';
    }

    if (name === "zone") {
      setValues({ ...values, [name]: aux, city: "", FiltroLocalidad: [] });

      setModelCartilla({
        ...modelCartilla,
        [nameModel]: aux,
        FiltroLocalidad: [],
        city: ""
      });

      setSelectedValues({
        ...selectedValues,
        [name]: aux,
        city: "",
        FiltroLocalidad: [],
      });
    } else if (name === "provider") {
      setValues({ ...values, department: '', [name]: aux });

      setModelCartilla({
        ...modelCartilla,
        FiltroEstudioComplementario: 0,
        FiltroEspecialidad: null,
        [nameModel]: aux,
      });

      setSelectedValues({
        ...selectedValues,
        provider: '',
        [name]: aux,
      });
    }
    else {
      setValues({ ...values, [name]: aux, FiltroLocalidad: [], city : "", zone: [] });

      setModelCartilla({
        ...modelCartilla,
        [nameModel]: aux,
        FiltroLocalidad: [],
        city : "",
      });

      setSelectedValues({
        ...selectedValues,
        [name]: aux,
        FiltroLocalidad: [],
        city : "",
      });
    }
  }
}

function setValueFunction(props) {
  const { newValueItem, typeValue } = props;

  if (newValueItem !== null) {
    switch (typeValue) {
      case "array":
        changeLocalties(props);
        break;

      case "int":
        changeIntInputs(props);
        break;

      case "string":
        changeStringInputs(props);
        break;

      default:
        break;
    }
  } else {
    resetValues(props);
  }
  resetLocalStorage();
}

function resetLocalStorage() {
  window.localStorage.removeItem("modelCartilla");
  window.localStorage.removeItem("selectedValues");
  window.localStorage.removeItem("values");
}

function validate(values, setErrors) {
  let temp = validateValues(values);
  setErrors({
    ...temp,
  });
  return Object.values(temp).every((value) => value == "");
}

function setNotFoundFunction(resizeScreen, setNotFound) {
  if (resizeScreen.screen.sWidth > 768) {
    setNotFound(false);
  }
}

function resetEstudiosEspecialidadValues(setValues, values, setSelectedValues, selectedValues, setModelCartilla, modelCartilla) {
  setValues({ ...values, department: null });
  setSelectedValues({
    ...selectedValues,
    department: "",
  });

  setModelCartilla({
    ...modelCartilla,
    FiltroEstudioComplementario: 0,
  });

  setModelCartilla({
    ...modelCartilla,
    FiltroEspecialidad: 0,
  });
}