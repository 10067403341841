import AskTurnDrawer from "./AskTurnDrawer";
import React, { useEffect, useState } from "react";
import MedicalCenterDrawer from "./MedicalCenterDrawer";
import PhoneShiftsDrawer from "./PhoneShiftsDrawer";
import SpecialtyList from "./SpecialtyListDrawer";
import NextAppointmentDrawer from "./NextAppointmentDrawer";
import SelectDateDrawer from "./SelectDateDrawer/SelectDateDrawer";
import { getMedicalCenters } from '../../redux/actions/appointmentsActions';
import { getShiftPhone } from "../../redux/actions/phonesUtilsActions";
import { useDispatch, useSelector } from "react-redux";
import ConfirmAppointmentDrawer from "./ConfirmAppointmentDrawer";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import Step3 from "./Web/Step3";
import { Typography } from "@material-ui/core";
import AppointmentsList from "./AppointmentsList";
import FlowSpecialtyListDrawer from "./FlowSpecialtYListDrawer";

const DrawerAppointmentsSteps = (props) => {
    const { activeStep, setActiveStep, handleOpenCancelModal, handleNext, handleBack, handleCloseDrawer, isWeb, specialistVisualizacion, setSpecialistVisualizacion } = props;
    const dispatch = useDispatch();
    const { screen } = useResizeScreen();
    const [resize, setResize] = useState('web');
    const { modelAppointment } = useSelector(state => state.appointmentsReducer);
    const medicalCenters = useSelector(state => state.medicalCenterReducer.medicalCenters.centrosMedicos);
    const flow = modelAppointment?.appointmentFlow;

    useEffect(() => {
        if (screen.sWidth <= 768) {
            setResize('mobile');
        }
    }, [screen.sWidth]);


    const step3ComponentBySpecialistVisualizacion = () => {
        if(specialistVisualizacion == "TURNO WEB"){
            return <SelectDateDrawer titles={props.titles} activeStep={activeStep} setActiveStep={setActiveStep} handleOpenCancelModal={handleOpenCancelModal} handleNext={handleNext} handleBack={handleBack} handleCloseDrawer={handleCloseDrawer} />
        }
        if(specialistVisualizacion == "TURNO TELEFONICO"){
            return <PhoneShiftsDrawer titles={props.titles} activeStep={activeStep} setActiveStep={setActiveStep} handleOpenCancelModal={handleOpenCancelModal} handleNext={handleNext} handleBack={handleBack} handleCloseDrawer={handleCloseDrawer} />
        }
        if(specialistVisualizacion === "AGENDA SUSPENDIDA"){
            return (
                <div style={{ display: "flex ", justifyContent: "center", marginTop: "40px" }}>
                    <Typography
                        style={{ margin: "10px", color: "#0075C9" }}
                    >
                        No hay turnos disponibles
                    </Typography>
                </div>
            );
        }
        if (specialistVisualizacion === "DEMANDA ESPONTANEA") {
            return <SpecialtyList titles={props.titles} activeStep={activeStep} setActiveStep={setActiveStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} setSpecialistVisualizacion={setSpecialistVisualizacion}/>
        }
    }

    const ShowStepsFunction = () => {
        const DEFAULT_STEP = <AskTurnDrawer titles={props.titles} />;
        const ACTIVE_STEPS = {
            0: <AskTurnDrawer titles={props.titles} />,
            1: <MedicalCenterDrawer titles={props.titles} />,
            2: <SpecialtyList titles={props.titles} activeStep={activeStep} setActiveStep={setActiveStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} setSpecialistVisualizacion={setSpecialistVisualizacion}/>,
            3: step3ComponentBySpecialistVisualizacion(),
            4: <NextAppointmentDrawer titles={props.titles} activeStep={activeStep} setActiveStep={setActiveStep} handleOpenCancelModal={handleOpenCancelModal} handleNext={handleNext} />,
            5: <PhoneShiftsDrawer titles={props.titles} activeStep={activeStep} setActiveStep={setActiveStep}  handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} handleCloseDrawer={handleCloseDrawer}/>,
            6: <ConfirmAppointmentDrawer titles={props.titles} activeStep={activeStep} setActiveStep={setActiveStep} handleOpenCancelModal={handleOpenCancelModal} handleCloseDrawer={handleCloseDrawer} />
        };

        const ACTIVEWEB_STEPS = {
            0: ACTIVE_STEPS[activeStep],
            1: ACTIVE_STEPS[activeStep],
            2: <Step3 titles={props.titles} activeStep={activeStep} setActiveStep={setActiveStep} handleOpenCancelModal={handleOpenCancelModal} handleNext={handleNext} handleBack={handleBack} handleCloseDrawer={handleCloseDrawer} />,
            3: <ConfirmAppointmentDrawer titles={props.titles} activeStep={activeStep} setActiveStep={setActiveStep} handleOpenCancelModal={handleOpenCancelModal} handleCloseDrawer={handleCloseDrawer} />
        };

        const ACTIVE_DOCTOR_AND_SPECIALTY_STEPS = {
            0: <AskTurnDrawer titles={props.titles} />,
            1: <FlowSpecialtyListDrawer titles={props.titles} activeStep={activeStep} setActiveStep={setActiveStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} />,
            2: <AppointmentsList titles={props.titles} activeStep={activeStep} setActiveStep={setActiveStep} handleOpenCancelModal={handleOpenCancelModal} handleNext={handleNext} medicalCenters={medicalCenters} />,
            3: <ConfirmAppointmentDrawer titles={props.titles} activeStep={activeStep} setActiveStep={setActiveStep} handleOpenCancelModal={handleOpenCancelModal} handleCloseDrawer={handleCloseDrawer} />
        };

        let returnActiveStep = DEFAULT_STEP;

        if (activeStep) {
            if (isWeb) {
                if (activeStep === 6) {
                    setActiveStep(3);
                }
            } else {
                if (activeStep === 2 && resize === 'web') {
                    setActiveStep(2);
                }
                if (activeStep === 3 && resize === 'web') {
                    setActiveStep(6);
                }
            }
            switch (flow) {
                case 'centro':
                    returnActiveStep = isWeb ? ACTIVEWEB_STEPS[activeStep] : ACTIVE_STEPS[activeStep];
                    break;
                case 'especialidad':
                    returnActiveStep = ACTIVE_DOCTOR_AND_SPECIALTY_STEPS[activeStep];
                    break;
                case 'medico':
                    returnActiveStep = ACTIVE_DOCTOR_AND_SPECIALTY_STEPS[activeStep];
                    break;
                default:
                    returnActiveStep = isWeb ? ACTIVEWEB_STEPS[activeStep] : ACTIVE_STEPS[activeStep];
                    break;
            }
        }
        return returnActiveStep;
    };

    useEffect(() => {
        dispatch(getMedicalCenters({ numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")) }));
        dispatch(getShiftPhone());
    }, []);

    return <>
        {ShowStepsFunction()}
    </>;
};

export default DrawerAppointmentsSteps;
