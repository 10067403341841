import * as actionTypes from '../consts/actionTypes';

const initialState = {
    authorizationDetails: [],
    comments: [],
    pushComment: [],
    readComments: [],
    authorizationsDetails: [],
    authorizationDetail: [],
    modelRequest: [],
    stepCounter: 0,
    actualStep: -1,
    typeAttached: [],
    params: [],
    attachments: [],
    postAttachments: [],
    cancelAuthorization: [],
    postRequest: [],
    historial: [],
    msgError: '',
    downloadAttachments: [],
    downloadAuth: [],
    loadingDownloadAttachments: false,
    loading: false,
    loadingDownloadAuthorization: false
};

export default function authorizationsReducer(state = initialState, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SET_AUTHORIZATION_DETAILS]: setDataOfStore('authorizationDetails'),
        [actionTypes.GET_AUTHORIZATION_DETAILS]: getDataOfStore(),
        [actionTypes.SET_MODEL_AUTH_REQUEST]: setDataOfStore('modelRequest'),
        [actionTypes.CLEAR_MODEL_REQUEST]: clearModel(),
        [actionTypes.SET_ACTUAL_STEP_AUTH_REQUEST]: setDataOfStore('actualStep'),
        [actionTypes.GET_STEPS_AUTH_REQUEST]: getDataOfStore(),
        [actionTypes.PREV_STEP_AUTH_REQUEST]: setDataOfStore('stepCounter'),
        [actionTypes.NEXT_STEP_AUTH_REQUEST]: setDataOfStore('stepCounter'),
        [actionTypes.CLEAR_HISTORIAL_REQUEST]: clearHistorial(),
        [actionTypes.CLEAR_MODEL_DETAILS_AUTH]: clearDetails(),
        [actionTypes.GET_AUTHORIZATIONS_AFFILIATE]: setLoading(),
        [actionTypes.DOWNLOAD_AUTHORIZATION]: setLoading('loadingDownloadAuthorization'),
        [actionTypes.DOWNLOAD_ATTACHMENTS]: setLoading('loadingDownloadAttachments'),
        [actionTypes.SUCCESS_GET_COMMENTS]: responseToReturn('comments'),
        [actionTypes.SUCCESS_PUSH_COMMENTS]: responseToReturn('pushComment'),
        [actionTypes.SUCCESS_GET_READ_COMMENTS]: responseToReturn('readComments'),
        [actionTypes.SUCCESS_GET_AUTHORIZATIONS_DETAILS]: responseToReturn('authorizationsDetails'),
        [actionTypes.SUCCESS_GET_AUTHORIZATION_DETAIL]: responseToReturn('authorizationDetail'),
        [actionTypes.SUCCESS_GET_AUTHORIZATIONS_TYPEATTACHED]: responseToReturn('typeAttached'),
        [actionTypes.SUCCESS_GET_PARAMS_AUTH_REQUEST]: responseToReturn('params'),
        [actionTypes.SUCCESS_GET_AUTHORIZATIONS_ATTACHED]: responseToReturn('attachments'),
        [actionTypes.SUCCESS_POST_AUTHORIZATIONS_ATTACHED]: responseToReturn('postAttachments'),
        [actionTypes.SUCCESS_POST_AUTHORIZATIONS_REQUEST]: responseToReturn('postRequest'),
        [actionTypes.SUCCESS_GET_AUTHORIZATIONS_AFFILIATE]: responseToReturn('historial'),
        [actionTypes.SUCCESS_DOWNLOAD_ATTACHMENTS]: responseToReturn('downloadAttachments'),
        [actionTypes.SUCCESS_DOWNLOAD_AUTHORIZATION]: responseToReturn('downloadAuth'),
        [actionTypes.SUCCESS_CANCEL_AUTHORIZATION]: responseToReturn('cancelAuthorization'),
        [actionTypes.FAILURE_GET_COMMENTS]: responseToReturn(),
        [actionTypes.FAILURE_PUSH_COMMENTS]: responseToReturn(),
        [actionTypes.FAILURE_GET_READ_COMMENTS]: responseToReturn(),
        [actionTypes.FAILURE_GET_AUTHORIZATIONS_DETAILS]: responseToReturn(),
        [actionTypes.FAILURE_GET_AUTHORIZATION_DETAIL]: responseToReturn(),
        [actionTypes.FAILURE_GET_AUTHORIZATIONS_TYPEATTACHED]: responseToReturn(),
        [actionTypes.FAILURE_GET_PARAMS_AUTH_REQUEST]: responseToReturn(),
        [actionTypes.FAILURE_GET_AUTHORIZATIONS_ATTACHED]: responseToReturn(),
        [actionTypes.FAILURE_POST_AUTHORIZATIONS_ATTACHED]: responseToReturn(),
        [actionTypes.FAILURE_POST_AUTHORIZATIONS_REQUEST]: responseToReturn(),
        [actionTypes.FAILURE_GET_AUTHORIZATIONS_AFFILIATE]: responseToReturn(),
        [actionTypes.FAILURE_DOWNLOAD_ATTACHMENTS]: responseToReturn(),
        [actionTypes.FAILURE_CANCEL_AUTHORIZATION]: responseToReturn(),
        [actionTypes.FAILURE_DOWNLOAD_AUTHORIZATION]: responseToReturn(),
    };

    function clearDetails() {
        const res = { ...state,
                        authorizationDetail: [],
                        authorizationDetails: []
                    }

        return res;
    }
    
    function clearModel() {
        let res = { ...state };
        if (action.payload) {
            res = { ...state, modelRequest: [] };
        }
        return res;
    }

    function setDataOfStore(modelName) {
        let res = { ...state };
        if (action.payload) {
            res = { ...state, [modelName]: action.payload };
        }
        return res;
    }

    function getDataOfStore() {
        return { ...state };
    }

    function responseToReturn(typeState) {
        let res = { ...state };
        if (action.response) {
            res = { ...state, [typeState]: action.response.data.data, msgError: action.message, loading: false, loadingDownloadAttachments: false, loadingDownloadAuthorization : false };
        }
        if (action.message) {
            res = { ...state, msgError: action.message };
        }
        return res;
    }

    function clearHistorial() {
        let res = { ...state };
        if (action.payload) {
            res = { ...state, historial: [] };
        }
        return res;
    }

    function setLoading(loadingType) {
        if (loadingType){
            return { ...state, [loadingType] : true}
        }else{
            return { ...state, loading: true}
        }
    }
    
    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}