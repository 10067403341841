import React, { useCallback, useState } from "react";
import useStyles from "../../assets/styles/styles";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router";

const ItemMenu = (props) => {
  const { Ref, Redirect, Icon, Link, Titulo, emergency, setOpenModalEmergency, notAccess, setOpenNotAccessModal } = props;
  const classes = useStyles();
  const [active, setActive] = useState(-1);
  const history = useHistory();

  const handleClick = (e, arg1) => {
    e.preventDefault();
    window.open(arg1);
    setActive(arg1);
    setColorActive(arg1);
  };

  const setColorActive = useCallback((arg1) => {
    let response = '';
    if (arg1 === history?.location?.pathname) {
      response = classes.activeItemMenu;
    }
    return response;
  }, [active]);

  const handleModalEmergency = () => {
    if (emergency) {
      setOpenModalEmergency(prevState => !prevState);
    } else if (notAccess) {
      setOpenNotAccessModal(prevState => !prevState);
    }
    else {
      (!history.location.pathname.includes(Redirect) || Redirect == "/") && history.push(Redirect);
    }
  };

  return (
    <>
      {Ref ?
        <ListItem button onClick={e => handleClick(e, Redirect)} className={`${classes.listItemsStyles} ${setColorActive()}`} >
          <ListItemIcon className={classes.icon}>
            {Icon}
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                <Typography className={classes.multiline}>
                  {" "}
                  {Titulo}{" "}
                </Typography>
              </>
            }
          />
        </ListItem>
        :
        <ListItem button onClick={handleModalEmergency} className={` ${classes.listItemsStyles}  ${setColorActive(Redirect)}`}>
          <ListItemIcon className={classes.icon}>
            {Icon}
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                <Typography className={classes.multiline}>
                  {" "}
                  {Titulo}{" "}
                </Typography>
              </>
            }
          />
        </ListItem>
      }
    </>
  );
};
export default React.memo(ItemMenu);
