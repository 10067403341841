import { Fragment, useEffect, useState } from 'react';
import CustomizedInputs from "../../controls/InputCustom/CustomizedInputs";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import useStyles from "../../assets/styles/styles";
import { Divider, FormControl, Typography } from "@material-ui/core";
import FormHeader from './FormHeader';
import { useSelector } from 'react-redux';
import NotFound from '../HomeLogged/NotFound';
import { handleGoToPersonalData } from "../../utils/utilsFunctions";

const FormRefund = ({ handleBack }) => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const [disabled, setDisabled] = useState(true);
    const data = useSelector(state => state.personalDataReducer?.fullData);
    const [affiliateData, setAffiliateData] = useState([]);

    useEffect(() => {
        if (data?.afiliado !== undefined) {
            let aux = [{
                apellidoNombre: data?.afiliado?.apellidoNombre?.trim(),
                documentos: data?.afiliado?.documentos,
                cuentasReintegro: data?.afiliado?.cuentasReintegro,
                debitoAutomatico: data?.afiliado?.debitosAutomaticos,
                mails: data?.afiliado?.mails,
                telefonos: data?.afiliado?.telefonos,
            }];
            setAffiliateData(aux);
        }
    }, [data]);

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <>
            <FormHeader title='Datos para reintegros' handleGoTo={handleGoToPersonalData} />
            {
                screen.sWidth > 768 &&
                <Divider className={classes.formDivider} />
            }
            <form onSubmit={handleSubmit} className={`${classes.addressForm} ${classes.refundForm}`} >
                {data?.afiliado !== undefined ?
                    <>
                        {affiliateData?.map((item, i) => (
                            <Fragment key={i}>
                                <FormControl className={classes.formControlRefunds} variant="standard">
                                    <CustomizedInputs value={`${item?.apellidoNombre?.trim()}`} disabled={disabled} label='APELLIDO Y NOMBRE' />
                                </FormControl>
                                <FormControl className={classes.formControlRefunds} variant="standard">
                                    <CustomizedInputs value={`${item?.documentos[0]?.documentoTipoCodigo?.trim()} ${item?.documentos[0]?.documentoNumero?.trim()}`} disabled={disabled} label='TIPO Y NUMERO DE DOCUMENTO' />
                                </FormControl>
                                <FormControl className={`${classes.formControlRefunds} `} variant="standard">
                                    <CustomizedInputs value={`${item.mails[0]?.mail?.trim()}`} disabled={disabled} label='E-MAIL' />
                                </FormControl>
                                <FormControl className={`${classes.formControlRefunds} `} variant="standard">
                                    <CustomizedInputs
                                        value={`${item?.telefonos[0]?.paisCodigo} ${item?.telefonos[0]?.areaCodigo} ${item?.telefonos[0]?.numero}`}
                                        disabled={disabled}
                                        label='TELEFONO'
                                    />
                                </FormControl>
                                {item.cuentasReintegro.map((aux, index) => (
                                    <Fragment key={index}>
                                        <Typography
                                            style={{
                                                justifyContent: 'flex-start',
                                                marginTop: '15px',
                                                paddingTop: '15px',
                                                marginLeft: screen.sWidth < 768 && '35px'
                                            }}
                                            className={`${classes.titleTurn} ${classes.titleBox} ${classes.subtitleAddressForm}`}>
                                            {`Cuenta Numero ${index + 1}`}
                                        </Typography>
                                        <FormControl className={classes.formControlRefunds} variant="standard">
                                            <CustomizedInputs value={aux.tipoCuentaDescripcion?.trim()} disabled={disabled} label='TIPO DE CUENTA BANCARIA' />
                                        </FormControl>
                                        <FormControl className={classes.formControlRefunds} variant="standard">
                                            <CustomizedInputs value={aux.bancoDescripcion?.trim()} disabled={disabled} label='BANCO DE RADICACION DE LA CUENTA' />
                                        </FormControl>
                                        <FormControl className={classes.formControlRefunds} variant="standard">
                                            <CustomizedInputs value={aux.numeroCuenta?.trim()} disabled={disabled} label='NUMERO DE SUCURSAL Y CUENTA' />
                                        </FormControl>
                                    </Fragment>
                                ))}
                            </Fragment>
                        ))}
                        <Divider style={{marginBottom: "80px", color: "#fff", border: "0"}} />
                    </>
                    :
                    (
                        <NotFound customMsg={
                            <div className={classes.contentNotFound}>
                                <span>No hay contenido para mostrar</span>
                            </div>
                        }
                            wsItem={data?.afiliado}
                            fullItem={data?.afiliado} />
                    )
                }
            </form>
        </>
    );
};
export default FormRefund;
