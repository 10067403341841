import React, { Fragment, useEffect, useState } from "react";
import CustomSelect from "../../../../controls/Select/CustomSelect";
import Autocomplete from "../../../../controls/Autocomplete/Autocomplete";
import useStyles from '../../../../assets/styles/styles.js';
import { FormControl, FormHelperText, InputLabel, TextField, Typography } from "@material-ui/core";
import CustomizedInputs from "../../../../controls/InputCustom/CustomizedInputs";
import { LocalizationProvider, MobileDatePicker } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { getAuthorizationDetails, setActualStepAuthorizationsRequest, setModelAuthRequest, setNextStepAuthorizationsRequest } from "../../../../redux/actions/authorizationsActions";
import { getDataGroupFamily } from "../../../../redux/actions/appointmentsActions";
import Step2 from "./Step2";
import TopButtons from "../../../AskTurn/Web/Buttons/TopButtons";
import ModalActivities from "../../../DialogActivities/ModalActivities";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useResizeScreen } from "../../../../hooks/useResizeScreen";
import { es } from "date-fns/locale";
import { getPracticas } from "../../../../redux/actions/practicasActions";
import MobileAuthorizationsButtons from "./MobileAuthorizationsButtons";
import LineBreakInput from "../../../../controls/InputCustom/LineBreakInput.jsx";

const campoItemList = [
    { value: 1, label: 'Fecha Realización/Entrega' },
    { value: 2, label: 'Lugar de Realización/Entrega' },
    { value: 3, label: 'Teléfono de Afiliado' },
    { value: 4, label: 'E-mail de Afiliado' },
    { value: 5, label: 'Fecha Prescripción' },
    { value: 6, label: 'Matrícula de Prescriptor' },
    { value: 7, label: 'Nombre de Prescriptor' },
    { value: 8, label: 'Teléfono de Prescriptor' },
    { value: 9, label: 'E-mail de Prescriptor' },
    { value: 10, label: 'Detalle de Prescripción' },

];

const datosWS = [];

const Step1 = ({ activeStep, handleOpenCancelModal, handleBack, setActiveStep }) => {
    const classes = useStyles();
    const [listPrestaciones, setListPrestaciones] = useState([{ value: 0, label: '' }]);
    const [prestacion, setPrestacion] = useState('');
    const [listPracticas, setListPracticas] = useState([]);
    const [camposValue, setCamposValue] = useState([{
        campoItem: 0,
        campoCaption: '',
        campoValorDefault: '',
        campoObligatorio: true,
        campoAdjuntoObligatorio: true,
        campoAdjuntoDetalle: ''
    }]);
    const dispatch = useDispatch();
    const groupFamilyState = useSelector((state) => state?.appointmentsReducer?.groupFamilyTurn);
    const authSelector = useSelector((state) => state?.authorizationsReducer?.modelRequest);
    const paramsAuthorizations = useSelector((state) => state?.authorizationsReducer?.params);
    const [groupFamily, setGroupFamily] = useState([{}]);
    const [affiliate, setAffiliate] = useState('');
    const [modelRequest, setModelRequest] = useState(authSelector);
    const [error, setError] = useState({ TipoAutorizacionCodigo: '*Campo obligatorio', numeroPersona: '*Campo obligatorio' });
    const [practica, setPractica] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [txtModal, setTxtModal] = useState({ title: '', description: '' });
    const { screen } = useResizeScreen();
    const configurationReduxState = useSelector(state => state?.configurationReducer?.allConfigurations?.configurations);
    const practicas = useSelector((state) => state?.practicasReducer?.practices);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [counterLength, setCounterLength] = useState(0);

    useEffect(() => {
        dispatch(getPracticas());
    }, [activeStep]);

    const getPracticasByPrestacion = (prestacionValue) => {
        let prestacionesFiltered = listPrestaciones?.filter(x => x.value === prestacionValue)[0]?.label;
        let practicasFiltered = practicas?.filter(y => y.tipoPrestacion === prestacionesFiltered);
        let auxPracticas = [];
        practicasFiltered[0]?.practices?.forEach((item, key) => {
            let aux = { itemCodigo: key, itemDescripcion: item.value};
            auxPracticas.push(aux);
        })
        setListPracticas(auxPracticas);
    }

    const handleChangeSelect = (e) => {
        const { name, value } = e.target;
        if (name === 'tipoPrestacion') {
            getPracticasByPrestacion(value);
            setPrestacion(value);
            let err = error;
            err[name] = '';
            setError(err);
        }
    };

    const handleChangeSelectPractica = (newValue) => {
        setPractica(newValue.itemDescripcion);
      };

    const handleChangeInput = (item) => (e) => {
        const { name, value } = e.target;
        setValueInput(item, value, name);
    };

    const handleChangeObservations = () => (e) => {
        const { name, value } = e.target;
        if (value.length <= 200) {
            setModelRequest(prevState => ({ ...prevState, [name]: value }));
            setCounterLength(value.length);
        };
    };

    const setValueInput = (item, value, name) => {
        let aux = camposValue;
        let err = error;
        aux.filter(x => x.campoItem === item)[0].campoValorDefault = value;
        if(value?.length < 1) {
            err[item] = "*Campo Obligatorio"
            setError(err);
        } else {
            err[item] = '';
            setError(err);
        }
        setCamposValue(aux);
        setModelRequest(prevState => ({ ...prevState, [name]: value }));


        if(item == 5){ //Fecha de Orden Medica / Prescripcion
            const dateValue = new Date(value);
            const dateToday = new Date()

            const diffTime = dateToday - dateValue; //segundos
            const diffDays = diffTime / (1000 * 60 * 60 * 24);  //dias

            if(diffDays < 0){
                let err = error;
                err[item] = '*No puede ser una fecha futura';
                setError(err)
            }

            if(prestacion == 'M' && diffDays > 30){

                let err = error;
                err[item] = '*No puede ser una fecha con más de 30 días de anterioridad';
                setError(err)
            }
            else if (diffDays > 60){
                let err = error;
                err[item] = '*No puede ser una fecha con más de 60 días de anterioridad';
                setError(err)
            }
        }
    };

    const setName = (item) => {
        const tipoAutorizacion = paramsAuthorizations?.tipoAutorizaciones?.filter(x => x.tipoAutorizacionCodigo == prestacion)[0]

        const name = tipoAutorizacion.tipoAutorizacionCampos.filter(c => c.campoItem == item.campoItem)[0]?.campoCaption
        
        return name;
    };

    const handleChangeSelectAffiliate = (e) => {
        const { value } = e.target;
        setAffiliate(value);
        setModelRequest(prevState => ({ ...prevState, numeroPersona: value }));
        let err = error;
        err.numeroPersona = '';
        setError(err);
    };

    const handleNext = () => {
        if (validateNextStep(modelRequest, error)) {
            let active = activeStep + 1;
            setActiveStep(active);
            dispatch(setActualStepAuthorizationsRequest(activeStep));
            dispatch(setNextStepAuthorizationsRequest(active));
        }
    };

    function validateNextStep(model, errorModel) {
        let responseFunction = false;
        if (model?.TipoAutorizacionCodigo !== undefined) {
            let auxSetError = {};
            Object.entries(errorModel)?.forEach((item) => {
                let name = item[0];
                let value = item[1];
                let response = {};
                if (value.includes('*')) {
                    response[name] = value;
                } else {
                    response[name] = '';
                }
                auxSetError[name] = response[name];
            });
            setError(auxSetError);

            if (Object.values(auxSetError)?.every(x => x === '')) {
                responseFunction = true;
            }
        } else {
            setError({ ...errorModel, TipoAutorizacionCodigo: '*Campo Obligatorio' });
        }
        return responseFunction;
    }

    useEffect(() => {
        dispatch(setModelAuthRequest(modelRequest));
    }, [modelRequest]);

    useEffect(() => {
        setModelRequest(prevState => ({ ...prevState, 'practica': practica }));
    }, [practica]);

    useEffect(() => {
        dispatch(getAuthorizationDetails());
        dispatch(getDataGroupFamily({ numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")) }));
        if (Object.values(authSelector).length > 0) {
            let err = error;
            setPrestacion(authSelector?.TipoAutorizacionCodigo);
            setPractica(authSelector?.practica);
            setAffiliate(authSelector?.numeroPersona);
            err.numeroPersona = '';
            setError(err);
        }
    }, []);

    useEffect(() => {
        let aux = [];
        let list = isFromService(paramsAuthorizations, datosWS);
        list?.forEach(item => {
            let auxItem = { value: item?.tipoAutorizacionCodigo, label: item?.tipoAutorizacionDescripcion };
            aux.push(auxItem);
        });
        setListPrestaciones(aux);
    }, [paramsAuthorizations]);

    useEffect(() => {
        let aux = [];
        let params = isFromService(paramsAuthorizations, datosWS);
        let prestacionModal = {
            title: '',
            description: ''
        };
        params?.filter(x => x.tipoAutorizacionCodigo === prestacion)?.forEach(item => {
            prestacionModal.title = item?.tipoAutorizacionDescripcion;
            prestacionModal.description = item?.tipoAutorizacionTextoDetalle;
            item?.tipoAutorizacionCampos?.forEach(campo => {
                let auxCampo = {
                    campoItem: campo["campoItem"],
                    campoCaption: campo["campoCaption"],
                    campoValorDefault: authSelector[campo.campoCaption] ?? campo["campoValorDefault"],
                    campoObligatorio: campo["campoObligatorio"],
                    campoAdjuntoObligatorio: campo["campoAdjuntoObligatorio"],
                    campoAdjuntoDetalle: campo["campoAdjuntoDetalle"],
                };
                aux.push(auxCampo);
            });
        });
        let auxError = new Object();
        let attachmentRequired = [];
        aux?.forEach(item => {
            if (authSelector[item.campoCaption]) {
                let caption = item.campoCaption;
                auxError[caption] = '';
            } else {
                if (item.campoObligatorio) {
                    let caption = item.campoItem;
                    auxError[caption] = '*Campo obligatorio';
                }
            }
            if (item.campoAdjuntoObligatorio) {
                attachmentRequired.push(item.campoAdjuntoDetalle);
            }
        });
        let errPrestacion = '*Campo Obligatorio';
        if (prestacion !== '') {
            errPrestacion = '';
        }
        auxError.TipoAutorizacionCodigo = errPrestacion;
        auxError.numeroPersona = error.numeroPersona ? '*Campo obligatorio' : '';
        setError(auxError);
        setCamposValue(aux);
        setTxtModal(prestacionModal);
        setModelRequest(prevState => ({ ...prevState, 'TipoAutorizacionCodigo': prestacion, 'attachmentRequired': attachmentRequired }));
    }, [prestacion, listPrestaciones]);

    useEffect(() => {
        let listGroupFamily = groupFamilyState.map((item) => {
            return { value: parseInt(item?.itemCodigo), label: item?.itemDescripcion };
        });
        setGroupFamily(listGroupFamily);
    }, [groupFamilyState]);

    function showPracticesInformation(condition) {
        var response;
        if(condition > 768) {
            response = (
                <FormControl className={classes.formControlCustomSelectAuthorizations} variant="standard">
                    <Autocomplete
                        autocompleteProp='nope'
                        label="PRACTICA"
                        name="practica"
                        option={listPracticas}
                        onChange={(event, newValue) => handleChangeSelectPractica(newValue)}
                        customClass={classes.enableTextField}
                    />
                    <div style={{ width: "250px", marginBottom: "20px" }}>
                        <span style={{ fontSize: '0.7rem', color: '#0075c9', marginLeft: "0px" }}>
                            Las siguientes prácticas generalmente no requieren autorización,
                            verificar con el prestador. Si la práctica buscada no se encuentra en este listado,
                            continuar con la solicitud.
                        </span>
                    </div>
                </FormControl>
            );
        } else {
            response = (
                <FormControl className={classes.formControlCustomSelectAuthorizations} variant="standard">
                    <div style={{ width: "250px", marginBottom: "20px" }}>
                        <span style={{ fontSize: '0.7rem', color: '#0075c9', marginLeft: "0px" }}>
                            Las siguientes prácticas generalmente no requieren autorización,
                            verificar con el prestador. Si la práctica buscada no se encuentra en este listado,
                            continuar con la solicitud.
                        </span>
                    </div>
                    <Autocomplete
                        autocompleteProp='nope'
                        label="PRACTICA"
                        name="practica"
                        option={listPracticas}
                        onChange={(event, newValue) => handleChangeSelectPractica(newValue)}
                        customClass={classes.enableTextField}
                    />
                </FormControl>
            );
        }
        return response;
    }

    
    const observationsLabel = ({ sWidth }) => (
        <span style={{ marginTop: "-30px", marginRight: sWidth < 768 ? "155px" :  "360px" }}>OBSERVACIONES</span>
    );

    const typeOfBenefitLabel = ({ sWidth }) => (
        <span style={{ marginRight: sWidth < 768 ? "0px" :  "0px"}}>TIPO DE PRESTACION</span>
    );


    return (
        <Fragment>
            {screen.sWidth > 768 &&
                <TopButtons handleOpenCancelModalTurn={handleOpenCancelModal} handleBackTurn={handleBack} activeStepTurn={activeStep} handleNextTurn={handleNext} titleCancel={"Cancelar"} titleConfirm={"Siguiente"} />
            }
            <div style={{ padding: "20px" }} >
                <div className={classes.divStep2Refunds}>
                    <FormControl className={`${classes.formControlCustomSelectAuthorizations} `} variant="standard">
                        <CustomSelect
                            name='tipoPrestacion'
                            required={true}
                            label={
                                typeOfBenefitLabel(screen)
                            }
                            list={listPrestaciones}
                            onChange={handleChangeSelect}
                            selected={prestacion}
                        />
                        <FormHelperText className={classes.errorText}>{error['TipoAutorizacionCodigo']}</FormHelperText>
                    </FormControl>
                    {(prestacion === "A" || prestacion === "R") &&
                        showPracticesInformation(screen.sWidth)
                    }
                    <FormControl className={classes.formControlCustomSelectAuthorizations} variant="standard">
                        <CustomSelect
                            name='afiliado'
                            required={true}
                            label='AFILIADO'
                            list={groupFamily}
                            onChange={handleChangeSelectAffiliate}
                            selected={affiliate}
                        />
                        <FormHelperText className={classes.errorText}>{error['numeroPersona']}</FormHelperText>
                    </FormControl>
                    {(prestacion && paramsAuthorizations) && camposValue?.map((item, i) => {
                        return (
                            <Fragment key={i}>
                                {item?.campoItem === 1 || item?.campoItem === 5 ?
                                    <FormControl className={`${classes.formControlDatePickerAuthorizations}`} >
                                        <FormControl className={classes.formControlStep2Authorizations}>
                                            <InputLabel className={classes.inputLabelForms} style={{ top: "15px !important ", marginLeft: "-10px", paddingBottom: '30px', paddingTop: '10px' }}  shrink htmlFor="bootstrap-input">
                                                {setName(item)}
                                            </InputLabel>
                                            <div style={{ marginTop: "23px", marginRight: '5px', marginLeft: '5px'}} className={`${classes.top22} ${classes.datePickerAuthorizations}`}>
                                                <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}  >
                                                    <MobileDatePicker
                                                        cancelText="Cancelar"
                                                        required={false}
                                                        toolbarTitle=""
                                                        format="dd/MM/YYYY"
                                                        value={item.campoValorDefault}
                                                        onChange={(newValue) => { setValueInput(item.campoItem, newValue?.toISOString(), item?.campoCaption); }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </FormControl>
                                        <FormHelperText className={classes.errorText}>{error[item?.campoItem]}</FormHelperText>
                                    </FormControl>
                                    :
                                    <FormControl className={classes.formControlStep2Authorizations} style={{marginTop:"-1px", marginLeft:"15px"}} variant="standard">
                                        <CustomizedInputs
                                            id=''
                                            name={setName(item)}
                                            value={item?.campoValorDefault}
                                            onChange={handleChangeInput(item?.campoItem)}
                                            style={{right:"8px"}}
                                            label={setName(item)}
                                        />
                                        <FormHelperText className={classes.errorText}>{error[item?.campoItem]}</FormHelperText>
                                    </FormControl>
                                }
                            </Fragment>
                        );
                    })}
                    <FormControl variant="standard" className={classes.widthMobile} style={{ width: "100%", marginTop:"40px", marginLeft: screen.sWidth < 768 ? "25px" :"10px" }}>
                        <LineBreakInput
                            formControlClass={`${classes.txtObservations}`}
                            inputLabelClass={classes.txtLabelComments}
                            customClass={classes.txtBoxObservation}
                            name="observacion"
                            label={observationsLabel(screen)}
                            placeholder='Escribe tu texto aqui'
                            onChange={handleChangeObservations()}
                            multiline
                            style={{
                                borderRadius: 29,
                                position: 'relative',
                                border: '1px solid #ced4da', 
                                fontSize: 16,
                                minWidth: '224px !important',
                                margin: '0 0 7px 0',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: "nowrap",
                            }}
                            rows={5}
                            inputProps={{ maxLength: 200 }}
                        />
                        <Typography variant="caption" style={{ marginLeft: screen.sWidth < 768 ? "190px" : '450px', }}>{counterLength}/200</Typography>
                    </FormControl>
                </div>
            </div>

            {(screen.sWidth > 768 && activeStep === 1) &&
                <Step2 activeStep={activeStep} />
            }
            {openModal &&
                <ModalActivities
                    title={
                        <section style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                            <CheckCircleIcon style={{ fontSize: "50px" }} className={classes.quickAccessIcon} />
                            <div style={{ display: 'flex', justifyContent: 'center', flex: '1 1 100%' }}>
                                <span>{txtModal?.title}</span>
                            </div>
                        </section>
                    }
                    description={
                        <section style={{ display: 'flex', justifyContent: 'center', flexWrap: 'nowrap' }}>
                            {configurationReduxState?.filter(x => x.id === 'popUpAutorizacionesPracticas')[0]?.valor}
                        </section>}
                    open={openModal}
                    onClose={setOpenModal}
                    modalconfirm={false}
                    modalLogout={false}
                />
            }
            {screen.sWidth < 768 &&
                <MobileAuthorizationsButtons
                    openCancelModal={openCancelModal}
                    setOpenCancelModal={setOpenCancelModal}
                    handleNext={handleNext}
                    activeStep={activeStep}
                />
            }
        </Fragment>
    );
};

export default Step1;


function isFromService(paramsAuthorizations, datosWs) {
    let list = [];
    if (Object.values(paramsAuthorizations)?.length > 0 && Object.values(paramsAuthorizations?.tipoAutorizaciones)?.length > 0) {
        list = paramsAuthorizations?.tipoAutorizaciones;
    } else {
        list = datosWs[0]?.tipoAutorizaciones;
    }
    return list;
}
