import React, { useState, useEffect, Fragment, useCallback } from 'react';
import useStyles from "../../../assets/styles/styles";
import { CircularProgress, FormControl, FormHelperText } from '@material-ui/core';
import CustomButton from "../../../controls/Button/CustomButton";
import SaveModal from '../../PersonalData/SaveModal';
import ConfirmModal from '../../PersonalData/ConfirmModal';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch, useSelector } from 'react-redux';
import { abmFactorGroup, getFactorGroup, getGroupFactorParams } from '../../../redux/actions/medicalDataAction';
import CustomSelect from '../../../controls/Select/CustomSelect';
import NotFound from "../../HomeLogged/NotFound";
import { useResizeScreen } from "../../../hooks/useResizeScreen";

const FactorGroupForm = ({ disabled, setDisabled }) => {
    const { screen } = useResizeScreen();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [error, setError] = useState({});
    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const factor = useSelector(state => state.medicalDataReducer?.factorGroup);
    const factorParams = useSelector(state => state.medicalDataReducer?.factorGroupParams);
    const postGroupFactor = useSelector(state => state.medicalDataReducer?.abmFactorGroup);
    const [listGroup, setListGroup] = useState([]);
    const [groupSelected, setGroupSelected] = useState('');
    const [listFactor, setListFactor] = useState([]);
    const [factorSelected, setFactorSelected] = useState('');
    const [modelABM, setModelABM] = useState();
    const [loading, setLoading] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalDescription, setModalDescription] = useState("");
    const [modalIcon, setModalIcon] = useState('');
    const [errorClass, setErrorClass] = useState('');

    const param = {
        numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
        count: 10,
        skip: 0
    };

    const handleChangeGroup = () => e => {
        const { value } = e.target;
        setGroupSelected(value);
    };

    const handleChangeFactor = () => e => {
        const { value } = e.target;
        setFactorSelected(value);
    };

    const handleClickModal = () => {
        var modelGroup = listGroup?.filter(x => x.value === groupSelected);
        var modelFactor = listFactor?.filter(x => x.value === factorSelected);
        var factorGroup = {
            grupo: modelGroup[0]?.label,
            factor: modelFactor[0]?.label
        };
        if (validate(factorGroup)) {
            setModelABM({ ...modelABM, factorGroup });
            setOpen(prevState => !prevState);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        var modelGroup = listGroup?.filter(x => x.value === groupSelected);
        var modelFactor = listFactor?.filter(x => x.value === factorSelected);
        var factorGroup = {
            grupo: modelGroup[0]?.label,
            factor: modelFactor[0]?.label
        };
        setLoading(true);
        if (validate(factorGroup)) {
            dispatch(abmFactorGroup({
                numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                grupo: factorGroup?.grupo,
                factor: factorGroup?.factor,
                modo: "A"
            }));
            setOpen(prevState => !prevState);
            setOpenConfirm(prevState => !prevState);
        }
        setDisabled(prevState => !prevState);
    };


    function validate(object) {
        var temp = object;
        let aux = [];
        var validation = [];
        var Obj = {};
        Obj.grupo = temp.grupo ? '' : '*Campo obligatorio';
        Obj.factor = temp.factor ? '' : '*Campo obligatorio';
        aux = Obj;
        if (Object.values(aux).every((x) => x === "")) {
            validation.push(true);
        } else {
            validation.push(false);
        }
        setError(aux);
        return Object.values(validation).every((valid) => valid === true);
    }

    useEffect(() => {
        var group = factorParams?.grupoFactor?.map((item, i) => {
            return ({
                value: i,
                label: item.grupoDescripcion.trim()
            });
        });
        setListGroup(group);
        if (Object.values(factor).length > 0) {
            var auxGroup = group?.filter(x => x.label === factor?.grupoFactor[0]?.grupo);
            if (auxGroup !== undefined && auxGroup?.length !== 0) {
                setGroupSelected(auxGroup[0]?.value);
            }
        }
    }, [factorParams, factor]);

    useEffect(() => {
        var aux = listGroup?.filter(x => x.value === groupSelected);
        var factorAux = factorParams?.grupoFactor?.filter(x => x.grupoDescripcion.trim() === aux[0]?.label).map((item) => {
            return {
                factor: item.factor
            };
        });
        if (factorAux !== undefined) {
            var factores = factorAux[0]?.factor?.map((item, i) => {
                return ({
                    value: i,
                    label: item.factorDescripcion.trim()
                });
            });
            setListFactor(factores);
        }
    }, [groupSelected]);

    useEffect(() => {
        if (factor?.length !== 0) {
            var auxGroup = listGroup?.filter(x => x.label === factor?.grupoFactor[0]?.grupo);
            if (auxGroup !== undefined && auxGroup?.length !== 0) {
                setGroupSelected(auxGroup[0]?.value);
            }
        }
    }, [listGroup]);

    useEffect(() => {
        if (factor?.length !== 0) {
            var auxFactor = listFactor?.filter(x => x.label === factor?.grupoFactor[0]?.factor);
            if (auxFactor !== undefined && auxFactor?.length !== 0) {
                setFactorSelected(auxFactor[0]?.value);
            }
        }
    }, [listFactor]);

    useEffect(() => {
        if (postGroupFactor?.confirmacion) {
            setModalTitle('Enviado correctamente');
            setModalDescription('Los cambios han sido realizados.');
            setModalIcon(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
            setErrorClass('');
            setLoading(false);
            dispatch(getGroupFactorParams());
            dispatch(getFactorGroup(param));
        }
        else if (!postGroupFactor?.confirmacion && postGroupFactor?.confirmacion !== undefined) {
            setModalTitle('Ocurrió un error');
            setModalDescription('Ocurrió un error al enviar los datos.');
            setModalIcon(<CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
            setErrorClass(classes.backgroundError);
            setLoading(false);
        }
    }, [postGroupFactor]);

    const handleBack = () => {
        setDisabled(prevState => !prevState);
        dispatch(getFactorGroup(param));
    };

    const formFactorGroup = useCallback(() => {
        return (
            <Fragment>
                <FormControl className={`${classes.formControlCustomSelectRefunds}`} variant="standard">
                    <CustomSelect
                        name={'grupo'}
                        label={'GRUPO'}
                        list={listGroup}
                        selected={groupSelected}
                        onChange={handleChangeGroup()}
                        disabled={disabled}
                    />
                    <FormHelperText className={`${classes.errorText} ${classes.errorTextFormMobile}`}>{error?.grupo}</FormHelperText>
                </FormControl>

                <FormControl className={`${classes.formControlCustomSelectRefunds}`} variant="standard">
                    <CustomSelect
                        name={'factor'}
                        label={'FACTOR'}
                        list={listFactor}
                        selected={factorSelected}
                        onChange={handleChangeFactor()}
                        disabled={disabled}
                    />
                    <FormHelperText className={`${classes.errorText} ${classes.errorTextFormMobile}`}>{error?.factor}</FormHelperText>
                </FormControl>
            </Fragment>
        );
    }, [groupSelected, listGroup, listFactor, factorSelected, disabled]);

    return (
        factorParams?.grupoFactor !== undefined ?
            (<>
                <form onSubmit={handleSubmit} className={`${classes.addressForm} ${classes.centerFactorGroupFormMobile}`}>
                    {formFactorGroup()}
                    <div className={`${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                        {!disabled && screen.sWidth <= 768 ?
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <>
                                    <CustomButton
                                        title="CANCELAR"
                                        customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                                        onClick={handleBack}
                                        style={{ marginRight: "10px", marginLeft: "45px" }}
                                    />
                                </>
                                <CustomButton
                                    customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                                    title='Guardar'
                                    onClick={handleClickModal}
                                    style={{ padding: '5px 20px 5px 20px', fontWeight: "bold", fontSize: "10px" }}
                                />
                            </div>
                            :
                            (!disabled &&
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <CustomButton
                                        customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                                        title='Guardar'
                                        onClick={handleClickModal}
                                        style={{ padding: '5px 20px 5px 20px', fontWeight: "bold", fontSize: "10px" }}
                                    />
                                </div>
                            )
                        }
                    </div>
                </form>
                <SaveModal isOpen={open} setOpen={setOpen} type='submit' onClick={handleSubmit}
                    titleModal={
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                Confirmar envío de datos
                            </div>
                        </>
                    }
                    descriptionModal="¿Deseas confirmar los cambios?" />
                <ConfirmModal isOpen={openConfirm} setOpen={setOpenConfirm} errorClass={errorClass}
                    titleModal={loading ?
                        (<>
                            <span>Espere un momento...</span>
                        </>)
                        :
                        (<>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {modalIcon}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {modalTitle}
                            </div>
                        </>)
                    }
                    description={loading ?
                        (<div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}>
                            <CircularProgress color={'inherit'} />
                        </div>)
                        :
                        (modalDescription)
                    }
                />
            </>) : (
                <NotFound
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    customMsg={
                        <div className={classes.contentNotFound}>
                            <span className={classes.notAppointment}>
                                No tiene acceso a este modulo
                            </span>
                        </div>
                    }
                    wsItem={factorParams?.grupoFactor}
                    fullItem={factorParams}
                />
            )

    );
};

export default React.memo(FactorGroupForm);
