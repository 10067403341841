export const setearMail=(item, setMail) => {
    if(item.mailTipoCodigo == 1 ){
        setMail(item.mail)
    }
}

export const existFile=(data)=>{
    return (data.file != null && data.file.result)
 }

 
 export const verifyMail=(showPermanente)=>{
     return showPermanente ? "S" : "N"
 }


 export const setAttachment=(newFiles, setistOfAttachmentButtons, Adjuntos )=>{
    if(newFiles == undefined){
        setistOfAttachmentButtons(Adjuntos);
    }else{
        setistOfAttachmentButtons(newFiles?.adjuntos);
    }
}

export const validationDate=(dateLow, date)=>{
    let today= new Date(date.getFullYear(), date.getMonth() + 1, 0);
        today.setHours(0,0,0,0);
        dateLow.setHours(0,0,0,0);
        if (dateLow.getTime() >= today.getTime()) {
           return '';
        } else {
            return "La fecha debe ser mayor a la fecha actual";
        }
}