import { Accordion, AccordionDetails, Typography, Button } from "@material-ui/core";
import useStyles from "../../assets/styles/styles";
import NotificationsIcon from '@material-ui/icons/Notifications';
import StarIcon from '@material-ui/icons/Star';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectNotification, modifyNotification, updateAllNotifications} from "../../redux/actions/notificationsActions";

const CardNotification = (props) => {
   
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const notificacionReducer = useSelector((state) => state.notificationReducer.notifications);

    const viewDetalle = (position) => {
        const newNotifications = notificacionReducer?.notification?.map((item, i) => {
            if (position === i) {
                return { ...item, ["viewed"]: true };
            } else {
                return item;
            }
        });
        let notificationSelected = props.notification;
        notificationSelected.viewed = true;
        dispatch(selectNotification(notificationSelected));
        dispatch(modifyNotification(notificationSelected));
        dispatch(updateAllNotifications({notification: newNotifications}));
        history.push('/Notification');

    };

    const AddIcon = () => {
        let response = <NotificationsIcon className={classes.notificationIcon} style={{ marginRight: '15px' }} />;
        if (props?.notification?.favourite)
            response = <StarIcon className={classes.iconDestacate} style={{ marginRight: '15px' }} />;
        else {
            if (props?.notification?.viewed) {
                response = <NotificationsIcon className={classes.iconReader} style={{ marginRight: '15px' }} />;
            }
        }
        return response;
    };

    return (
        <Accordion className="notification" key={props?.position} expanded={true} >
            <AccordionDetails className="notification" key={props?.position} >
                <div className={classes.flexRow} style={{ alignItems: 'center', margin: '5px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {AddIcon()}
                        <div style={{ width:'130px' }}>
                            <Typography className={classes.txtDescription}>{props?.notification?.message?.title}</Typography>
                            <Typography className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`} > {props?.notification?.message?.module}</Typography>
                        </div>
                    </div>
                    <div>
                        <Typography className={classes.txtDescription}>{new Date(props?.notification?.message?.createDate)?.toLocaleDateString()}</Typography>
                    </div>
                    <div>
                        <Button
                            onClick={(e) => { viewDetalle(props?.position); }}
                            className={` ${classes.lightLinearGradient} ${classes.customButtomNotification} `}
                            style={{ boxShadow: '0px 2px 8px 0 #585858' }}>
                            {'ver detalle'}
                        </Button>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default CardNotification;
