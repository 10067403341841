import * as actionTypes from '../consts/actionTypes';

const initialValues = {
    telemedicine: [],
    parameters: [],
    msgError: ''
};

export default function telemedicineAppointments(state = initialValues, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_GET_TELEMEDICINE]: responseToReturn('telemedicine'),
        [actionTypes.FAILURE_GET_TELEMEDICINE]: responseToReturn('telemedicine'),
        [actionTypes.GET_TELEMEDICINE_PARAMS]: getDataOfStore(),
        [actionTypes.SET_TELEMEDICINE_PARAMS]: setDataOfStore('parameters'),
        [actionTypes.CLEAR_STATE]: clearState(),
    };
    function responseToReturn(typeState) {
        let res = { ...state };
        if (action.response) {
            res = { ...state, [typeState]: action.response.data, msgError: action.message };
        }
        if (action.message) {
            res = { ...state, msgError: action.message };
        }
        return res;
    }

    function setDataOfStore(modelName) {
        let res = { ...state };
        if (action.payload) {
            res = { ...state, [modelName]: action.payload };
        }
        return res;
    }
    
    function getDataOfStore() {
        return { ...state };
    }

    function clearState(type) {
        if(type){
            return { ...state, [type]: initialValues[type]};
        }else{
            return {...initialValues}
        }
    }

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}
