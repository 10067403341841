import React, { useEffect, useState } from 'react';
import Menu from "../../components/Menu/Menu";
import Header from "../../components/GenericHeader/Header";
import Banner from "../../components/Banner/Banner";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import CardPhoto from '../../components/CardPhoto/CardPhoto';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCoveragePlans } from '../../redux/actions/coverageActions';
import { isAuthenticated, notAccessToThisModule, verifyPermission } from '../../utils/utilsFunctions';
import { CircularProgress } from '@material-ui/core';
import { getLimitPlan } from "../../redux/actions/limitPlansAction";
import useTrackPageView from "../../utils/useTrackPageView";


const CoveragePlans = () => {
  const { screen } = useResizeScreen();
  const dispatch = useDispatch();
  const coveragePlansReduxState = useSelector(state => state.coverageReducer.getCoveragePlans);
  const [bannerItem, setBannerItem] = useState([]);
  const [item, setItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const limitPlanReducer = useSelector((state) => state.limitPlanReducer.allLimitPlanData);
  const affiliatePlan = useSelector((state) => state.personalDataReducer?.fullData?.afiliado?.planDescripcion);
  const [hasPermission, setHasPermission] = useState(false);
  const loadingPermission = useSelector((state) => state.limitPlanReducer.requestInProgress);

  const trackPageView = useTrackPageView("Planes de Cobertura")

  useEffect(() => {
    verifyPermission(limitPlanReducer, affiliatePlan, "Planes de Cobertura", setHasPermission);
  }, [limitPlanReducer, affiliatePlan, hasPermission]);

  useEffect(() => {
    trackPageView();

    setLoading(true);
    if (isAuthenticated()) {
      let params = {
        type: 'Authorize',
        numeroPersona: window.localStorage.getItem('numeroPersona')
      };
      return dispatch(getAllCoveragePlans(params));
    } else {
      return dispatch(getAllCoveragePlans());
    }
  }, []);

  useEffect(() => {
    if (Object.values(coveragePlansReduxState).length > 0) {
      let auxBanner = coveragePlansReduxState?.coveragePlan?.filter(x => x?.coverage?.position).map(itemBanner => {
        return {
          ...itemBanner?.coverage,
          img: itemBanner?.coverage?.image64,
          title: `Plan ${itemBanner?.coverage?.planCode}`,
          URL: itemBanner?.coverage?.link
        };
      });
      let auxItem = coveragePlansReduxState?.coveragePlan?.filter(x => !x?.coverage?.position).map(itemFiltered => {
        return {
          img: itemFiltered?.coverage?.image64,
          URL: itemFiltered?.coverage?.link,
          title: `Plan ${itemFiltered?.coverage?.planCode}`
        };
      });
      setItem(auxBanner);
      setBannerItem(auxItem);
      setLoading(false);
    }
  }, [coveragePlansReduxState]);

  useEffect(() => {
    dispatch(getLimitPlan());
  }, []);

  function componentToShow(condition) {
    var response;
    if (condition) {
      response = (
        <>
          {bannerItem !== undefined &&
            <Banner items={bannerItem} hasButton={false} ></Banner>
          }
          <CardPhoto
            listCard={item}
            countCard={item?.length}
            hasButton={false}
            hasDetail={true}
            isNotPlan={false}
            alternateColor={true}
          />
        </>
      );
    } else {
      response = (notAccessToThisModule());
    }
    return response;
  }

  return (
    <div>
      <Menu />
      <Header title="Planes de Cobertura" colorTitle="#0075C9" heightTitle="140px" />
      {loading || loadingPermission && affiliatePlan ?
        <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
        :
        (componentToShow(hasPermission))
      }
    </div>
  );
};

export default CoveragePlans;
