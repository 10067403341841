import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormHelperText, IconButton, Typography } from "@material-ui/core";
import React, { Fragment, useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CancelIcon from "@material-ui/icons/Cancel";
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import useStyles from '../../../../assets/styles/styles.js';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useResizeScreen } from "../../../../hooks/useResizeScreen";
import CustomButton from "../../../../controls/Button/CustomButton.jsx";
import ModalActivities from "../../../DialogActivities/ModalActivities.jsx";
import UploadModal from "../../../../controls/UploadModal/UploadModal.jsx";
import ConfirmModal from "../../../PersonalData/ConfirmModal.jsx";
import { deleteFile, validate } from "../../../../utils/utilsFunctions.js";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CustomSelect from "../../../../controls/Select/CustomSelect.jsx";
import { format } from "date-fns";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useDispatch, useSelector } from "react-redux";
import { setActualStepAuthorizationsRequest, setModelAuthRequest, setNextStepAuthorizationsRequest } from "../../../../redux/actions/authorizationsActions.js";
import TopButtons from "../../../AskTurn/Web/Buttons/TopButtons.jsx";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FrequentQuestionsButton from "../../FrequentQuestionsButtons.jsx";
import MobileAuthorizationsButtons from "./MobileAuthorizationsButtons.jsx";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LineBreakInput from "../../../../controls/InputCustom/LineBreakInput.jsx";


const listSelect = [
    { value: 0, label: "Orden" },
    { value: 1, label: "Prescripcion" },
];

const Step2 = ({ activeStep, handleOpenCancelModal, handleBack, setActiveStep }) => {
    const [listTypeAttached, setListTypeAttached] = useState([{ value: 0, label: '' }]);
    const [typeAttachedSelected, setTypeAttachedSelected] = useState('');
    const [listAttached, setListAttached] = useState([]);
    const [files, setFiles] = useState([]);
    const [openModalRemove, setOpenModalRemove] = useState(false);
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const inputFile = useRef();
    const [hide, setHide] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalDescription, setModalDescription] = useState("");
    const [modalIcon, setModalIcon] = useState('');
    const [errorClass, setErrorClass] = useState('');
    const [error, setError] = useState({});
    const [openSendSuccess, setOpenSendSuccess] = useState(false);
    const [fileButtons, setFileButtons] = useState([]);
    const [txtObservation, setTxtObservation] = useState('');
    const [itemRemove, setItemRemove] = useState({});
    const paramsAuthorizations = useSelector((state) => state.authorizationsReducer?.params);
    const authSelector = useSelector((state) => state.authorizationsReducer?.modelRequest);
    const [modelRequest, setModelRequest] = useState(authSelector);
    const dispatch = useDispatch();
    const [allFiles, setAllFiles] = useState([]);
    const [labelTypeAttachedSelected, setLabelTypeAttachedSelected] = useState('');
    const [attachmentRequired, setAttachmentRequired] = useState({});
    const [openConfirm, setOpenConfirm] = useState(false);
    const [manyFilesModal, setManyFilesModal] = useState(false);
    const [ sameFile, setSameFile ] = useState(false);
    const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);
    const [cancelModal, setCancelModal] = useState(false);
    const [counterLength, setCounterLength] = useState(0);


    const handleAddAttachment = () => {
        if (files.length === 0) {
            if (screen.sWidth > 768) {
                inputFile?.current?.click();
            }
            else {
                setOpenModal(true);
            }
        }
    };

    const handleChangeSelect = () => e => {
        const { name, value } = e.target;
        let auxSelected = listTypeAttached.filter(x => x.value === value);
        setLabelTypeAttachedSelected(auxSelected);
        setTypeAttachedSelected(value);
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    const handleRemove = () => {
        let attachedDeleteItem = listAttached?.filter(x => x.nombre !== itemRemove?.nombre);
        let filesDeleteItem = files?.filter(x => x.archivoNombre !== itemRemove?.nombre);
        setListAttached(attachedDeleteItem);
        setAllFiles(filesDeleteItem);
        setFiles(filesDeleteItem);
        let filesModel = JSON.parse(modelRequest?.adjuntos);
        let newFilesToSend = filesModel?.filter(filesToSend => filesToSend?.archivoNombre !== itemRemove?.nombre);
        setModelRequest(prevState => ({ ...prevState, 'adjuntos': JSON.stringify(newFilesToSend) }));
        setOpenModalRemove(false);
    };

    const handleUpload = (e) => {
        let alertGreatSize = "";
        var filesUpload = e.target.files;
        for (let file of filesUpload) {
            let size = (file.size / 1024);
            if (
                size < parseInt(configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor) * 1000
            ) {
                let adj = {
                    archivoNombre: file.name.substring(0, file.name.lastIndexOf(".")),
                    archivoExtension: file.name.substring(file.name.lastIndexOf(".") + 1),
                    archivoNombreCompleto: file.name,
                    archivoTamanio: parseInt(size),//KB
                    archivo: null,//new Blob([file],{type: file.type}),
                    archivoClasificacion: 0,
                };

                let exists = listAttached?.filter(x => x?.nombre?.trim() == adj?.archivoNombre?.trim());

                if(exists?.length > 0) {
                    setSameFile(true);
                } else {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        adj.archivo = reader.result?.split(',').pop();
                        setFiles([...files, adj]);
                        setHide(true);
                    };
                    reader.readAsDataURL(file);
                }
            } else {
                alertGreatSize = alertGreatSize + " " + file.name;
            }
        }
        if (alertGreatSize != "") {
            setModalTitle('Atención');
            setModalDescription(
                `Los archivos: ${alertGreatSize} superan el tamaño máximo.
                Asegurese de que los archivos no superen los ${configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor}MB`
            );
            setModalIcon(<CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
            setErrorClass(classes.backgroundError);
            setOpenSendSuccess(prevState => !prevState);
        }
        e.target.value = null;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateFiles(files) && validateSelect(typeAttachedSelected)) {
            let dateNow = Date.now();
            setFiles([...files, { archivoClasificacion: typeAttachedSelected, archivoObservacion: txtObservation, archivoTipo: labelTypeAttachedSelected }]);
            let lenFiles = files.length - 1;
            let auxFile = files[lenFiles];
            auxFile.archivoClasificacion = typeAttachedSelected;
            auxFile.archivoObservacion = txtObservation;
            auxFile.archivoTipo = labelTypeAttachedSelected;
            let aux = [...allFiles, auxFile];
            setModelRequest(prevState => ({ ...prevState, 'adjuntos': JSON.stringify(aux) }));
            let type = listTypeAttached.find(x => x.value == typeAttachedSelected);
            let listAux = listAttached;
            let newItemList = {
                nombre: files[0]?.archivoNombre,
                fecha: format(dateNow, "dd/MM/yyyy"),
                tipo: type?.label,
                observacion: txtObservation,
                delete: true
            };
            if (Object.values(attachmentRequired)?.length > 0) {
                let requiredAttachments = attachmentRequired;
                setAttachmentRequired(requiredAttachments);
            }
            listAux.push(newItemList);
            setListAttached(listAux);
            setAllFiles([...allFiles, auxFile]);
            cleanStates();

            listAttached?.length <= 3 ? setOpenConfirm(prevState => !prevState) : setManyFilesModal(prevState => !prevState);
            
        }
    };

    const handleChangeText = (e) => {
        const { value } = e.target;
        if (value.length <= 200) {
            setTxtObservation(value);
            setCounterLength(value.length);
        };
    };

    const handleModalRemove = (attached) => e => {
        setItemRemove(attached);
        setOpenModalRemove(true);
    };

    function validateSelect(select) {
        let aux = [];
        let validation = [];
        let Obj = {};
        Obj.selectAttached = select.toString() ? '' : '*Campo obligatorio';
        aux = Obj;
        if (Object.values(aux).every((x) => x === "")) {
            validation.push(true);
        } else {
            validation.push(false);
        }
        setError(aux);
        return Object.values(validation).every((valid) => valid === true);
    }

    function cleanStates() {
        setFiles([]);
        setTxtObservation('');
        setTypeAttachedSelected('');
        setHide(false);
    }

    const handleNext = () => {
        if (validateNextStep(authSelector)) {
            let active = activeStep + 1;
            setActiveStep(active);
            dispatch(setActualStepAuthorizationsRequest(activeStep));
            dispatch(setNextStepAuthorizationsRequest(active));
        }
    };

    function validateNextStep(model) {
        let validation;
        let attachmentsToSend = model?.adjuntos !== undefined ? JSON.parse(model?.adjuntos) : [];

        if (model?.attachmentRequired?.length > 0) {
            if (model?.attachmentRequired?.length > attachmentsToSend?.length) {
                validation = false;
            } else {
                validation = true;
            }
        } else {
            validation = true;
        }
        return validation;
    }

    useEffect(() => {
        let list = paramsAuthorizations?.tipoAdjuntos?.map((type) => {
            return { value: parseInt(type?.tipoAdjuntosCodigo), label: type?.tipoAdjuntosDescripcion };
        });
        let requiredAttachments = {};
        if (Object.values(authSelector).length > 0 && authSelector?.adjuntos !== undefined) {
            let dateNow = Date.now();
            let filesModel = JSON.parse(authSelector.adjuntos);
            let listAttachedAux = [];
            filesModel.map(item => {
                const type = listTypeAttached.find(x => x.value === item.archivoTipo[0]?.value);
                let aux = {
                    nombre: item.archivoNombre,
                    fecha: format(dateNow, "dd/MM/yyyy"),
                    tipo: type?.label,
                    observacion: item.archivoObservacion,
                    delete: true
                };
                listAttachedAux.push(aux);
            });
            setListAttached(listAttachedAux);
            setAllFiles(filesModel);
        }
        if (Object.values(authSelector?.attachmentRequired)?.length > 0) {
            authSelector?.attachmentRequired?.map(item => {
                requiredAttachments[item] = "*Adjunto obligatorio";
            });
            setAttachmentRequired(requiredAttachments);
        }
        setListTypeAttached(list);
    }, []);

    useEffect(() => {
        dispatch(setModelAuthRequest(modelRequest));
    }, [modelRequest]);

    return (
        <Fragment>
            {screen.sWidth > 768 &&
                <TopButtons handleOpenCancelModalTurn={handleOpenCancelModal} handleBackTurn={handleBack} activeStepTurn={activeStep} handleNextTurn={handleNext} titleCancel={"Cancelar"} titleConfirm={"Siguiente"} />
            }
            <Accordion style={{ boxShadow: 'none', marginTop: '30px' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ borderBottom: '1px solid #e2e3e3' }}
                >
                    <AttachFileOutlinedIcon style={{ marginRight: "5px", backgroundColor: '#0075c9', color: '#ffffff', borderRadius: '15px', fontSize: '30px', padding: '5px' }} className={classes.iconFilter} />
                    <span style={{ paddingLeft: '10px' }}>Adjuntos</span>
                </AccordionSummary>
                <AccordionDetails>
                    {listAttached?.length > 0 ?
                        <div className={classes.authorizationsAccordion}>
                            <Typography className={classes.txtSubtitleCalendar} style={{ fontSize: "16px" }}>
                                NOMBRE
                            </Typography>
                            {listAttached?.map((attached, i) => {
                                return (
                                    <>
                                        <Accordion
                                            key={i}
                                            style={{ width: "100%" }}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                classes={{ content: classes.width100 }}
                                            >
                                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", overflow: "hidden" }}>
                                                    <Typography className={classes.txtEllipsis}>
                                                        {attached?.nombre}
                                                    </Typography>
                                                </div>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "20px" }}>
                                                    <Typography className={classes.txtSubtitleCalendar}>
                                                        {attached?.fecha}
                                                    </Typography>
                                                    {attached?.delete &&
                                                        <div style={{ display: "flex" }}>
                                                            <IconButton
                                                                className={classes.padding0}
                                                                onClick={handleModalRemove(attached)}
                                                            >
                                                                < DeleteOutlineIcon />
                                                            </IconButton>
                                                        </div>
                                                    }
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails >
                                                {<>
                                                    <div className={classes.displayFlex}>
                                                        <Typography className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}>Tipo de archivo: </Typography>
                                                        <Typography style={{ marginLeft: '5px' }} className={`${classes.txtSubtitleGray} ${classes.txtEllipsis}`}>
                                                            {attached?.tipo}
                                                        </Typography>

                                                    </div>
                                                    <div className={classes.displayFlex}>
                                                        <Typography className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}>Observaciones / comentarios: </Typography>
                                                        <Typography className={`${classes.txtSubtitleGray} ${classes.txtEllipsis}`} style={{ marginLeft: '5px' }}>
                                                            {attached?.observacion ? attached?.observacion : "No hay observaciones"}
                                                        </Typography>
                                                    </div>
                                                </>}
                                            </AccordionDetails>
                                        </Accordion>
                                    </>);
                            })}
                        </div>
                        :
                        <Typography style={{ marginTop: "20px", fontSize: "16px", textAlign: "center" }}>
                            NO HAY ADJUNTOS
                        </Typography>
                    }
                </AccordionDetails>
            </Accordion>
            <section style={{ display: 'flex', flexDirection: "column", alignItems: "center", paddingTop: '20px' }}>

                <div style={{ display: 'flex' , alignItems: 'center' }}>
                    <CustomButton title={"Nuevo adjunto"}
                        onClick={handleAddAttachment}
                        style={{ height: "25px", padding: '16px' }}
                        icon={<AddCircleIcon style={{ marginRight: "5px" }} />}
                        customClass={`${classes.txtMobile} ${classes.lightLinearGradient}`}
                    />
                    {
                        screen.sWidth > 768 &&
                        <FrequentQuestionsButton />
                    }
                </div>

                {Object.values(attachmentRequired)?.some(x => x !== '') &&
                    <FormHelperText className={classes.errorText} style={{ marginTop: screen.sWidth < 768 && '20px' }}>
                        Debe adjuntar los siguientes archivos:
                        {Object.entries(attachmentRequired)?.map(item => {
                            return (
                                <div key={item}>
                                    * {item[0]}
                                </div>
                            );
                        })}
                    </FormHelperText>
                }
                <FormControl style={{ paddingTop: '20px', paddingBottom: '20px' }} variant="standard" className={classes.widthMobile}>
                    <Typography className={classes.textTitleTurn} style={{ fontSize: '13px', margin: '5px 7px' }}>Tipo de archivo </Typography>
                    <CustomSelect
                        label=''
                        name={'selectAttached'}
                        className={`${classes.selectAuthorizations}`}
                        list={listTypeAttached === undefined ? listSelect : listTypeAttached}
                        selected={typeAttachedSelected}
                        onChange={handleChangeSelect()}
                    />
                    <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.selectAttached}</FormHelperText>
                </FormControl>
            </section>
            {files.length !== 0 && hide &&
                <>
                    <section
                        style={{ display: 'flex', flexDirection: "column", alignItems: "center", paddingTop: '20px' }}
                    >
                        {files?.map((file, i) => (
                            <div key={i} style={{ display: "flex", justifyContent: "center", width: "100%" }} className={`${classes.txtSubtitleCalendar}`}>
                                <p className={`${classes.txtEllipsis}`}>
                                    {file?.archivoNombre}
                                </p>
                                <IconButton
                                    onClick={deleteFile(fileButtons, setFileButtons, setFiles, files, file?.btnNombre, file?.archivoNombre)}>
                                    <HighlightOffIcon />
                                </IconButton>
                            </div>
                        ))}
                        <FormControl variant="standard" className={classes.widthMobile}>
                            <Typography className={classes.textTitleTurn} style={{ fontSize: '13px', margin: '5px 7px' }}>Observaciones</Typography>
                            <LineBreakInput
                                formControlClass={`${classes.txtObservations}`}
                                inputLabelClass={classes.txtLabelComments}
                                customClass={classes.txtBoxObservation}
                                name="observacion"
                                placeholder='Escribí tu comentario aquí...'
                                onChange={handleChangeText}
                                multiline
                                style={{
                                    borderRadius: 29,
                                    position: 'relative',
                                    border: '1px solid #ced4da', 
                                    fontSize: 16,
                                    minWidth: '224px !important',
                                    margin: '0 0 7px 0',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: "nowrap",
                                  }}
                                rows={5}
                                inputProps={{ maxLength: 200 }}
                            />
                        <Typography variant="caption" style={{ marginLeft: screen.sWidth < 768 ? "240px" : '450px', }}>{counterLength}/200</Typography>
                        </FormControl>
                        <CustomButton title={"ADJUNTAR"} onClick={handleSubmit}
                            style={{
                                height: "25px",
                                fontSize: "12px",
                                fontWeight: "600",
                                margin: "50px",
                            }}
                            customClass={`${classes.customHeaderBtn} ${classes.btnAttached} ${classes.lightLinearGradientReverse} ${classes.txtMobile}`}
                        />
                    </section>
                </>
            }

            <ConfirmModal
                description={"No puede adjuntar dos archivos con el mismo nombre"}
                isOpen={sameFile}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                        </div>
                    </>
                }
                setOpen={setSameFile}
            />

            <ConfirmModal isOpen={openSendSuccess} setOpen={setOpenSendSuccess} errorClass={errorClass}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalIcon}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalTitle}
                        </div>
                    </>
                }
                description={modalDescription}
            />

            <UploadModal
                title='Adjuntar archivo'
                description=''
                observation=''
                open={openModal}
                onClose={setOpenModal}
                setModalTitle={setModalTitle}
                setModalDescription={setModalDescription}
                setModalIcon={setModalIcon}
                setErrorClass={setErrorClass}
                setOpenSendSuccess={setOpenSendSuccess}
                listAttached={listAttached}
                setFiles={setFiles}
                setRepeatFile={setSameFile}
                files={files}
                fileButtons={fileButtons}
                setFileButtons={setFileButtons}
                hide={true}
                setHide={setHide}
            />
            {
                openModalRemove &&
                <ModalActivities
                    title={'Eliminar adjunto'}
                    description={'¿Estás seguro que deseas eliminar el adjunto?'}
                    open={openModalRemove}
                    onClose={setOpenModalRemove}
                    modalconfirm={true}
                    modalLogout={false}
                    buttonType={'submit'}
                    handleClick={handleRemove}
                />
            }
            <ConfirmModal
                isOpen={openConfirm}
                setOpen={setOpenConfirm}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                        </div>
                    </>
                }
                description={"El archivo se ha adjuntado con éxito."}
            />

            <ConfirmModal
                isOpen={manyFilesModal}
                setOpen={setManyFilesModal}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <ErrorOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                        </div>
                    </>
                }
                description={"Se han cargado demasiados archivos, la solicitud podría demorar en completarse."}
            />
            <input ref={inputFile} type="file" hidden id='upFile' onChange={handleUpload} name='upFileName' />
            {screen.sWidth < 768 &&
                <MobileAuthorizationsButtons
                    cancelModal={cancelModal}
                    setCancelModal={setCancelModal}
                    handleNext={handleNext}
                    activeStep={activeStep}
                />
            }
        </Fragment>
    );
};

export default React.memo(Step2, (prevState, nextState) => {
    return prevState.activeStep === nextState.activeStep;
});

const validateFiles = (files) => {
    let auxFiles = [];
    let response = false;
    if (auxFiles.length > 0) {
        let aux = [];
        files?.forEach(item => {
            if (item?.adjuntoObligatorio === 'S') {
                aux.push(auxFiles?.some(x => x === item.btnNombre));
            }
        });
        if (aux.length > 0 && Object.values(aux)?.every(x => x === true)) {
            response = true;
        }
    } else {
        response = true;
    }
    return response;
};