import { useEffect, useState } from 'react';
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import useStyles from "../../../assets/styles/styles";
import {
    Typography,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ClickAwayListener,
    Zoom,
    FormControl,
    TextField,
    IconButton,
    CircularProgress,
} from "@material-ui/core";
import CustomButton from '../../../controls/Button/CustomButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import TuneIcon from '@material-ui/icons/Tune';
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import MobileDatePicker from '@material-ui/lab/MobileDatePicker';
import InputLabel from '@material-ui/core/InputLabel';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { getDataWheightHeight } from './../../../redux/actions/medicalDataAction';
import { useDispatch, useSelector } from "react-redux";
import MovementsCard from "../../Billing/Movements/MovementsCard";
import HeaderCard from '../../Billing/Movements/HeaderCard';
import { es } from "date-fns/locale";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { handleGoToPersonalMedicalData } from "../../../utils/utilsFunctions";

const WeightAndHeigth = (props) => {
    const today = new Date();
    today.setMonth(-6);
    const dispatch = useDispatch();
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const [open, setOpen] = useState(false);
    const [dateStart, setDateStart] = useState(today);
    const [dateEnd, setDateEnd] = useState(new Date());
    const [listRegister, setListRegister] = useState([]);
    const [lastRecord, setLastRecord] = useState(null);
    const [skip, setSkip] = useState(0);
    const [initial, setInitial] = useState(true);
    const medicalData = useSelector(state => state.medicalDataReducer);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (medicalData.weightHeight?.pesoTalla !== undefined && medicalData.weightHeight?.pesoTalla?.length > 0) {

            if (listRegister.length !== 0) {
                const newRegisters = listRegister;
                medicalData.weightHeight.pesoTalla.forEach(element => {
                    newRegisters.push(element);
                });
                setListRegister(newRegisters);
            } else {
                //solo ingresa la primera vez
                if (initial) {
                    setLastRecord(medicalData.weightHeight.pesoTalla[0]);
                    setInitial(false);
                }
                setListRegister(medicalData.weightHeight.pesoTalla);
            }

        }
        setLoading(false);
    }, [medicalData.weightHeight]);

    useEffect(() => {
        setLoading(true);
        callToServices(false, true);
    }, []);

    useEffect(() => {
        filterDate();
    }, [dateEnd]);

    useEffect(() => {
        filterDate();
    }, [dateStart]);

    const filterDate = () => {
        setListRegister([]);
        setSkip(0);
        callToServices(true);
    };

    const handleClickExpanded = (i, item) => (e) => {
        if (e.target.innerText === "VER MAS") {
            e.target.innerText = "VER MENOS";
        }
        else {
            e.target.innerText = "VER MAS";
        }
    };

    const lastRecordCard = () => {
        return (
            <>
            
                <MovementsCard
                    buttonsInHeader={false}
                    headerIcon={
                        <HeaderCard
                            day={lastRecord?.fechaFormateada?.nombreDia}
                            numberDay={lastRecord?.fechaFormateada?.dia}
                            month={parseInt(lastRecord?.registracionFechaHora?.substring(5, 7))}
                            year={lastRecord?.registracionFechaHora?.substr(0, 4)}
                        />
                    }
                    description={
                        <>
                            <Typography
                                style={{ fontWeight: "600", color: '#0075c9' }} className={`${classes.txtCardDescription} `}
                            >
                                <span style={{ fontSize: '1rem' }}>
                                    {`Hora: ${lastRecord?.registracionFechaHora?.substr(lastRecord?.registracionFechaHora?.indexOf("T") + 1, 5)} hs`}
                                </span>
                            </Typography>

                            <Typography
                                style={{ fontWeight: "600" }}
                                className={`${classes.subTitleMedicalData}`}
                            >
                                <span style={{ fontSize: '1rem' }}>
                                    {"PESO Y TALLA"}
                                </span>
                            </Typography>

                            <div style={{ lineHeight: ".5" }}>
                                <Typography
                                    style={{ fontSize: "10px", display: "inline", marginBottom: "0", paddingBottom: "0" }}
                                    className={`${classes.txtSubtitleCalendar}`}
                                >
                                    {"PESO: "}
                                </Typography>
                                <Typography
                                    style={{ fontSize: "10px", display: "inline", marginBottom: "0", paddingBottom: "0" }}
                                    className={`${classes.txtCardDescription}`}
                                >
                                    {lastRecord?.peso + " KG"}
                                </Typography>
                            </div>
                            <div style={{ lineHeight: ".5" }}>
                                <Typography
                                    style={{ fontSize: "10px", display: "inline", marginBottom: "0", paddingBottom: "0" }}
                                    className={`${classes.txtSubtitleCalendar}`}
                                >
                                    {"TALLA METROS: "}
                                </Typography>
                                <Typography
                                    style={{ fontSize: "10px", display: "inline", marginBottom: "0", paddingBottom: "0" }}
                                    className={`${classes.txtCardDescription}`}
                                >
                                    {lastRecord?.tallaMetros + " mts"}
                                </Typography>
                            </div>
                            <div style={{ lineHeight: ".5" }}>
                                <Typography
                                    style={{ fontSize: "9.5px", display: "inline", marginBottom: "0", paddingBottom: "0" }}
                                    className={`${classes.txtSubtitleCalendar}`}
                                >
                                    {"TALLA CENTIMETROS: "}
                                </Typography>
                                <Typography
                                    style={{ fontSize: "10px", display: "inline", marginBottom: "0", paddingBottom: "0" }}
                                    className={`${classes.txtCardDescription}`}
                                >
                                    {lastRecord?.tallaCentimetros + "cms"}
                                </Typography>
                            </div>
                            <div style={{ lineHeight: ".5" }}>
                                <Typography
                                    style={{ fontSize: "10px", display: "inline", marginBottom: "0", paddingBottom: "0" }}
                                    className={`${classes.txtSubtitleCalendar}`}
                                >
                                    {"IMC: "}
                                </Typography>
                                <Typography
                                    style={{ fontSize: "10px", display: "inline", marginBottom: "0", paddingBottom: "0" }}
                                    className={`${classes.txtCardDescription}`}
                                >
                                    {lastRecord?.imc + " KG/M2"}
                                </Typography>
                            </div>
                        </>
                    }
                />
            </>
        );
    };

    const addAccordion = (i, item) => {
        return (
            <Accordion key={i} style={{ margin: "10px", padding: "15px" }}>
                <AccordionSummary
                    expandIcon={<CustomButton
                                    customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                                    style={{ padding: '5px 20px 5px 20px', fontWeight: "bold", fontSize: "10px", marginLeft: "10px" }}
                                    title='ver mas'
                                    onClick={handleClickExpanded(i, item)}
                                />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <Typography className={classes.txtSubtitleCalendar}>
                            {"PESO Y TALLA"}
                        </Typography>
                        <div id={i} style={{ display: "flex" }}>
                            <Typography className={classes.txtSubtitleGray} style={{ marginLeft: "7px" }}>
                                {`${item?.fechaFormateada?.dia} de ${item?.fechaFormateada?.mes} de ${item?.registracionFechaHora?.substr(0, 4)}`}
                            </Typography>
                            <IconButton className={classes.padding0} onClick={() => { handleDelete(item); }} style={{ marginRight: "10px" }}>
                                <DeleteOutlineIcon />
                            </IconButton>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {<>
                        <div className={classes.displayFlex}>
                            <Typography className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}>{"PESO:  "}</Typography>
                            <Typography className={`${classes.txtSubtitleGray} ${classes.txtEllipsis}`} style={{marginLeft: "5px"}}>{item.peso + "kg"}</Typography>

                        </div>
                        <div className={classes.displayFlex}>
                            <Typography className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}>{"TALLA METROS:  "}</Typography>
                            <Typography className={`${classes.txtSubtitleGray} ${classes.txtEllipsis}`} style={{marginLeft: "5px"}}>
                                {`${item.tallaMetros}.${item.tallaCentimetros} mts`}
                            </Typography>
                        </div>
                        <div className={classes.displayFlex}>
                            <Typography className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}>{"IMC: "}</Typography>
                            <Typography className={`${classes.txtSubtitleGray} ${classes.txtEllipsis}`} style={{marginLeft: "5px"}}>{item.imc + "KG/M2"}</Typography>
                        </div>
                        <div className={classes.displayFlex}>
                            <Typography className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}>{"FECHA:  "}</Typography>
                            <Typography className={`${classes.txtSubtitleGray} ${classes.txtEllipsis}`} style={{marginLeft: "5px"}}>
                                {`${item?.fechaFormateada?.dia} de ${item?.fechaFormateada?.mes} de ${item?.registracionFechaHora?.substr(0, 4)}
                                 ${item?.registracionFechaHora?.substr(item?.registracionFechaHora?.indexOf("T") + 1, 5)}`}
                            </Typography>
                        </div>
                    </>}
                </AccordionDetails>
            </Accordion>);
    };

    const filter = () => {
        return (
            <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway} >
                <div style={{ position: "relative", marginRight: "10px" }}>
                    <CustomButton
                        title={""}
                        onClick={handleClick}
                        icon={<TuneIcon style={{ marginRight: "5px" }} className={classes.iconFilter} />}
                        customClass={`${classes.customHeaderBtn} ${classes.btnFilterAppointment}`}
                    />
                    {open ? (
                        <Zoom in={open}>
                        <div className={classes.dropdown}>
                            <Typography className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>{"PERIODO"}</Typography>
                            <Divider classes={{ root: classes.dividerFilter }} />
                            {<div className={`${classes.headerFiltro} ${classes.marginTop}`}>
                                <FormControl style={{ width: "150px", paddingTop: "10px" }} className={classes.datePicker}>
                                    <InputLabel className={`${classes.txtSubtitleCalendar} ${classes.fontSize15}`} shrink  >{"DESDE"}</InputLabel>
                                    <div className={classes.top22} style={{ display: "flex", justifyContent: "space-between" }}>
                                        <LocalizationProvider locale={es} style={{ width: "100px" }} dateAdapter={AdapterDateFns}>
                                            <MobileDatePicker
                                                cancelText="Cancelar"
                                                toolbarTitle=""
                                                format="dd/MM/YYYY"
                                                value={dateStart}
                                                onChange={(newValue) => { setDateStart(newValue); }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                        <CalendarTodayIcon style={{ alignSelf: "center" }} />
                                    </div>
                                </FormControl>
                                <FormControl style={{ width: "150px", marginLeft: "10px", paddingTop: "10px" }} className={classes.datePicker} >
                                    <InputLabel className={`${classes.txtSubtitleCalendar} ${classes.fontSize15}`} shrink  >{"HASTA"}</InputLabel>
                                    <div className={classes.top22} style={{ display: "flex", justifyContent: "space-between" }}>
                                        <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}  >
                                            <MobileDatePicker
                                                cancelText="Cancelar"
                                                toolbarTitle=""
                                                format="dd/MM/YYYY"
                                                value={dateEnd}
                                                onChange={(newValue) => { setDateEnd(newValue); }}
                                                variant='outlined'
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                        <CalendarTodayIcon style={{ alignSelf: "center" }} />
                                    </div>
                                </FormControl>
                            </div>}
                        </div>
                        </Zoom>)
                        : ""}
                </div>
            </ClickAwayListener>);
    };



    const handleClickAway = () => {
        setOpen(false);
    };
    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleDelete = (item) => {
        var param = {};
        param.numeroPersona = parseInt(window.localStorage.getItem("numeroPersona"));
        param.peso = item.peso;
        param.tallaMetros = item.tallaMetros;
        param.tallaCentimetros = item.tallaCentimetros;
        param.registracionFechaHora = item.registracionFechaHora;
        param.modo = "B";
        props.setAction("BAJA");
        props.setForm(param);
        props.setOpenConfirm(prevState => !prevState);
    };
    const scrollDiv = () => {
        return (screen.sWidth < 800 ? classes.heightDivRegisters : "");
    };

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            callToServices();
        }
    };

    const callToServices = (tipoFecha, first) => {
        let auxSkip = skip;
        if (dateStart !== null && dateEnd !== null) {
            if (tipoFecha || first) {
                auxSkip = 0;
            } else {
                auxSkip += 10;
            }
            const paramGet = {
                numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                count: 10,
                skip: parseInt(auxSkip),
                fechaDesde: dateStart,
                fechaHasta: dateEnd
            };
            setSkip(parseInt(auxSkip));
            dispatch(getDataWheightHeight(paramGet));
        }
    };

    const btnIcon = () => {
        return (
            <CustomButton
                title={"NUEVO "}
                onClick={() => { props.setAdd(prevState => !prevState); }}
                customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                style={{margin: "0 10px"}}
            />
        );
    }

    function showLastRecord(condition) {
        if (condition !== null) {
            var response = (lastRecordCard());
        } else {
            response = (
                <Typography
                    className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}
                    style={{ marginLeft: "20px", marginTop: "15px" }}>{"No se encontraron resultados"}
                </Typography>
            );
        }
        return response;
    }

    function btnVerMas(condition) {
        if (condition > 800) {
            var response = (
                <div style={{ width: "100%" }} className={classes.BtnMobileWeight}>
                    <CustomButton
                        customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                        style={{ padding: '5px 20px 5px 20px', fontWeight: "bold", fontSize: "10px", marginBottom: "10px" }}
                        title='ver mas'
                        onClick={() => { callToServices(); }}
                    />
                </div>
            );
        }
        return response;
    }

    function showAccordion(condition) {
        let response;
        if (condition > 0) {
            response = (
                <> {
                    listRegister?.map((item, i) => (
                        <div
                            className={`${classAccordion(screen.sWidth, classes)} ${classes.notRotateIcon}`}
                            style={{ maxWidth: "400px" ,flexShrink: "1", alignItems: "center" }}>
                            {addAccordion(i, item)}
                        </div>
                    ))}
                    {
                        listRegister?.length >= 10 &&
                        btnVerMas(screen.sWidth)
                    }
                </>
            );
        } else {
            response = (
                <Typography
                    className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}
                    style={{ marginLeft: "20px", marginTop: "15px" }}>
                        {"No se encontraron resultados"}
                </Typography>
            );
        }
        return response;
    }

    function showLastCardRecord(condition) {
        var response;
        if(condition > 768) {
            response = (
                <div
                    className={widthClass(screen.sWidth, classes)}
                    style={{marginLeft: "28px"}}
                >
                    {showLastRecord(lastRecord)}
                </div>
            );
        } else {
            response = (
                <div
                    className={widthClass(screen.sWidth, classes)}
                    style={{display: "flex", justifyContent: (lastRecord !== null ? "center" : "start")}}
                >
                    {showLastRecord(lastRecord)}
                </div>
            );
        }
        return response;
    }

    return (
        <>
            {loading ?
                <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
                :
                <>
                        {
                            screen.sWidth <= 768 &&
                            <div
                                className={`${setBackgroundMobile(classes, screen.sWidth)}`}
                                style={{ paddingTop: '50px', paddingBottom: "40px", display: "flex", alignItems: "center" }}>
                                <IconButton onClick={handleGoToPersonalMedicalData}>
                                    <ArrowBackIcon className={classes.ShowMoreArrowIcon} style={{ fontSize: "25px" }} />
                                </IconButton>
                                <div>
                                    <Typography className={`${classes.titleTurn} ${classes.titleBox}`} style={{ textTransform: 'uppercase' }}>
                                        MIS DATOS MEDICOS
                                    </Typography>
                                    <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.subtitleBox}`} style={{ marginRight: "20px"}}>
                                        Información sin carácter sanitario conforme ley Nº 26529
                                    </Typography>
                                </div>
                            </div>
                        }
                    <div className={classes.headerSecundary} style={{ marginBottom: "20px", display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                        <Typography className={`${classes.titleTurn} ${classes.titleBox}`} style={{ textTransform: 'uppercase'}}>
                            PESO Y TALLA
                        </Typography>
                        <Typography
                            className={`${classes.titleTurn} ${classes.subtitleBox}`}
                            style={{
                                marginLeft: "20px",
                                gridRow: 2, marginTop: screen.sWidth <= 768 ? "20px" : "",
                                fontSize: "11px"
                            }}>
                            Información sin carácter sanitario conforme ley Nº 26529
                        </Typography>
                        <div style={{ gridColumn: "span 1", justifySelf: "right" }} className={classes.headerFiltro}>
                            {btnIcon()}
                            {filter()}
                        </div>
                    </div>
                    <div style={{ marginBottom: "30px" }}>
                        <Typography className={`${classes.titleTurn} ${classes.titleBox}`} >
                            MI ULTIMO REGISTRO
                        </Typography>
                        <Divider className={classes.formDivider} style={{marginBottom: "20px"}}/>
                        {
                            showLastCardRecord(screen.sWidth)
                        }
                    </div>
                    <Typography className={`${classes.titleTurn} ${classes.titleBox}`} style={{ textTransform: 'uppercase' }}>
                        MIS REGISTROS ANTERIORES
                    </Typography>
                    <Divider className={classes.formDivider} style={{marginBottom: "10px"}} />
                    <div
                        className={`${classes.flexRowAcordion} ${scrollDiv()}`}
                        onScroll={(e) => { handleScroll(e); }}
                        style={{ justifyContent: screen.sWidth <= 768 && 'center', marginLeft: screen.sWidth > 768 && '12px' }} >
                        { showAccordion(listRegister?.length) }
                    </div>
                </>
            }
        </>);
};

export default WeightAndHeigth;

function widthClass(width, classes) {
    if (width <= 800) {
        return classes.width100;
    }
    else if (width > 800 && width < 1250) {
        return classes.width50;
    }
    else {
        return classes.width25;
    }
}

function classAccordion(swidth, classes) {
    if (swidth > 800) {
        return classes.AccordionWeightWeb;
    }
    else {
        return classes.AccordionWeightMobile;
    }
}

function setBackgroundMobile(classes, width) {
    let cssClass = classes.displayNone;
    if (width <= 768) {
        cssClass = classes.titlePersonalDataForm;
    }
    return cssClass;
}
