import React, { useEffect, useState } from "react";
import useStyles from "../../assets/styles/styles";
import Menu from "../../components/Menu/Menu";
import { ClickAwayListener, Divider, FormControl, IconButton, Zoom, InputLabel, TextField, Typography } from "@material-ui/core";
import { getDataFilterAppointment, getAfilliatedAppointments, getAfilliatedAppointmentsNext, AppointmentCanceled } from "../../redux/actions/appointmentsActions";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../controls/Select/CustomSelect";
import NotFound from "../../components/HomeLogged/NotFound";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { checkNewAppointments, returnHomeAppointmentHome, setModelAppointmentToRepeatOrModify } from "../../utils/utilsFunctions";
import TuneIcon from '@material-ui/icons/Tune';
import { useHistory } from "react-router";
import { LocalizationProvider, MobileDatePicker } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { format } from "date-fns";
import ModalActivities from "../../components/DialogActivities/ModalActivities";
import useTodayAppointment from "../../hooks/useTodayAppointment";
import NextAppointmentCard from "../../components/Appointments/Cards/NextAppointmentCard";
import  useTrackPageView  from "../../utils/useTrackPageView";
import CustomButton from "../../controls/Button/CustomButton";

const userCode = parseInt(window.localStorage.getItem("numeroPersona"));

const persona = {
    numeroPersona: userCode,
    parametroLlamado: "FILTRO HISTORICO TURNO"
};

const paramAll = {
    numeroPersona: userCode,
    solicitudTurnos: "TODOS",
    count: 1000,
    skip: 0,
};

const AppointmentNext = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const trackPageView = useTrackPageView("Mis próximos turnos");
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [openPicker, setOpenPicker] = useState(false);
    const [openModalRepeatAppointment, setOpenModalRepeatAppointment] = useState(false);
    const appointments = useSelector((state) => state.appointmentsReducer);
    const [next, setNext] = useState([]);
    const [groupFamily, setGroupFamily] = useState([]);
    const [selectAffilliate, setSelectAffilliate] = useState({ value: 0, label: "" });
    const [modelAppointment, setModelAppointment] = useState();
    const { today, setTodayAppointment } = useTodayAppointment({ appointments });
    const [cancelItem, setCancelItem] = useState(
        {
            numeroPersona: 0,
            numeroTurno: 0,
            enviarMail: "",
            mail: ""
        });
    const [openModal, setOpenModal] = useState(false);

    const handleChangeWEB = () => e => {
        var nameAffilliated = groupFamily.filter((x) => x.value == e.target.value);
        setSelectAffilliate({ value: nameAffilliated[0].value, label: nameAffilliated[0].label });
    };

    const handleClickAway = () => {
        setOpenPicker(false);
    };

    useEffect(() => {
        getNextAppointments();
    }, [selectAffilliate?.value])

    const handleClick = () => {
        setOpenPicker((prev) => !prev);
    };

    const handleClickCancelAppointment = () => {
        dispatch(AppointmentCanceled(cancelItem));
        var newNext = next?.filter((x) => x.turnoNumero != cancelItem?.numeroTurno);
        setNext(newNext);
        setOpenModal(false)
    };

    const handleClickOpenRepeatModal = (item) => (e) => {
        e.preventDefault();
        setOpenModalRepeatAppointment(prevState => !prevState);
        setModelAppointment(item);
    };

    const handleClickModifyRepeatAppointment = () => {
        setModelAppointmentToRepeatOrModify(modelAppointment, dispatch, userCode, appointments, history);
    };


    const getNextAppointments = () => {
        dispatch(getAfilliatedAppointmentsNext({
            numeroPersona: userCode,
            solicitudTurnos: "PROXIMOS",
            count: 99999,
            skip: 0
        }));
    }

    useEffect(() => {
        trackPageView();
        getNextAppointments();
        dispatch(getDataFilterAppointment(persona));
        dispatch(getAfilliatedAppointments(paramAll));
    }, []);

    useEffect(() => {
        let listGroupFamily = appointments?.filtro?.map((val) => {
            return {
                value: parseInt(val?.numeroPersona), label: val?.apellidoNombre
            };
        });
        if (listGroupFamily?.length > 1) {
            listGroupFamily?.push({
                value: 0, label: "TODOS"
            });
        }
        setGroupFamily(listGroupFamily);
    }, [appointments?.filtro]);

    useEffect(() => {
        groupFamily?.forEach((item) => {
            if (item.value == userCode) {
                setSelectAffilliate({ value: item.value, label: item.label });
            }
        });
    }, [groupFamily]);

    useEffect(() => {
        let list = [];
        if (selectAffilliate?.value == 0 || !selectAffilliate?.value == undefined) {
            list = appointments?.next?.map(element => {
                return element;
            });
        }
        else {
            list = appointments?.next?.filter((x) => selectAffilliate?.value == x.pacienteNumero).map((element) => {
                return element;
            });
        }
        setNext(list);
    }, [selectAffilliate, appointments?.next]);

    useEffect(() => {
        let start = format(dateStart, "yyyy-MM-dd");
        let end = format(dateEnd, "yyyy-MM-dd");
        let list = appointments?.next?.filter((x) => selectAffilliate?.value == x.pacienteNumero).map((element) => {
            return element;
        });
        let nextFilter = list?.filter(x => x.turnoFechHora.split('T')[0] >= start && x.turnoFechHora.split('T')[0] <= end);
        setNext(nextFilter);
    }, [dateStart, dateEnd]);

    return (
        <>
            <Menu />
            <div className={`${classes.divMyHistoryTurn}`}>
                <div className={`${classes.divAffilliate} ${classes.divAffilliateMyHistory}`}>
                    <Typography className={classes.textTitleTurn}>Afiliado: </Typography>
                    <CustomSelect
                        className={`${classes.afilliateSelect}`}
                        list={groupFamily}
                        selected={selectAffilliate?.value}
                        customClassList={`${classes.deploymentSelect} ${classes.deploymentSelectMyHistory}`}
                        onChange={handleChangeWEB()}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <IconButton onClick={returnHomeAppointmentHome(history)}>
                            <ArrowBackIcon className={classes.iconFilter} />
                        </IconButton>
                        <Typography className={`${classes.textTitleTurn}`} style={{ textTransform: 'uppercase' }}>
                            Mis próximos turnos
                        </Typography>
                    </div>
                    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                        <div style={{ position: "relative", marginRight: "12%"  }}>
                            <CustomButton
                                onClick={handleClick}
                                style={{ fontSize: '13px'}}
                                customClass={`${classes.customHeaderBtn} ${classes.btnFilterAppointment}`}
                                icon={<TuneIcon style={{ marginRight: "5px" }} className={classes.iconFilter}/>}
                            />
                            {openPicker && (
                                <Zoom
                                    in={openPicker}
                                >
                                    <div className={`${classes.dropDownMyHistory} ${classes.dropdown}`}>
                                        <Typography className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>{"PERIODO"}</Typography>
                                        <Divider classes={{ root: classes.dividerFilter }} />
                                        <div className={`${classes.headerFiltro} ${classes.marginTop}`}>
                                            <FormControl style={{ width: "150px", paddingTop: "10px" }} className={classes.datePicker}>
                                                <InputLabel className={`${classes.txtSubtitleCalendar} ${classes.fontSize15}`} shrink  >{"DESDE"}</InputLabel>
                                                <div className={classes.top22} style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <LocalizationProvider style={{ width: "100px" }} dateAdapter={AdapterDateFns}  >
                                                        <MobileDatePicker
                                                            cancelText="Cancelar"
                                                            toolbarTitle=""
                                                            inputFormat="dd/MM/yyyy"
                                                            value={dateStart}
                                                            onChange={(newValue) => { setDateStart(newValue); }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                    <CalendarTodayIcon style={{ alignSelf: "center" }} />
                                                </div>
                                            </FormControl>

                                            <FormControl style={{ width: "150px", marginLeft: "10px", paddingTop: "10px" }} className={classes.datePicker} >
                                                <InputLabel className={`${classes.txtSubtitleCalendar} ${classes.fontSize15}`} shrink  >{"HASTA"}</InputLabel>
                                                <div className={classes.top22} style={{ display: "flex", justifyContent: "space-between", }}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                                        <MobileDatePicker
                                                            cancelText="Cancelar"
                                                            toolbarTitle=""
                                                            inputFormat="dd/MM/yyyy"
                                                            value={dateEnd}
                                                            onChange={(newValue) => { setDateEnd(newValue); }}
                                                            variant='outlined'
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                    <CalendarTodayIcon style={{ alignSelf: "center" }} />
                                                </div>
                                            </FormControl>
                                        </div>
                                    </div>
                                </Zoom>
                            )}
                        </div>
                    </ClickAwayListener>
                </div>
                <Divider className={`${classes.formDivider} ${classes.dividerAppointment}`} style={{margin: "10px 0"}} />
                <div className={`${classes.listCardMyHistory}`} style={{display: "flex", justifyContent: "center"}}>
                    {next?.length > 0 ?
                        next?.map((item, key) => (
                            <NextAppointmentCard item={item} i={key} handleClickOpenRepeatModal={handleClickOpenRepeatModal} setCancelItem={setCancelItem} setOpenModal={setOpenModal} />
                        ))
                        :
                        <NotFound customMsg={
                            <div className={classes.contentNotFound} style={{ marginTop: "10%" }}>
                                <span>No tenés próximos turnos</span>
                            </div>}
                            wsItem={next?.filter(nextAppointmentsToFilter => nextAppointmentsToFilter?.pacienteNumero === selectAffilliate?.value)}
                            fullItem={next} />
                    }
                </div>
                {
                    openModalRepeatAppointment &&
                    <ModalActivities
                        title={'Modificar Turno'}
                        description={'¿Estás seguro que deseas modificar el turno seleccionado?'}
                        open={openModalRepeatAppointment}
                        onClose={setOpenModalRepeatAppointment}
                        modalconfirm={true}
                        modalLogout={false}
                        buttonType={'submit'}
                        handleClick={handleClickModifyRepeatAppointment}
                    />
                }
                {
                    openModal &&
                    <ModalActivities
                        title={"Cancelar turno"}
                        description={"¿Estás seguro que deseas cancelar el turno?"}
                        open={openModal}
                        onClose={setOpenModal}
                        modalconfirm={true}
                        modalLogout={false}
                        buttonType={'submit'}
                        handleClick={handleClickCancelAppointment} />
                }
            </div>
        </>
    );
};
export default AppointmentNext;