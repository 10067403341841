import React, { Fragment, useEffect, useState } from "react";
import useStyles from "../../assets/styles/styles";
import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from '@material-ui/core/Button';
import Moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { setModelToSaveAppointment, getFreeDoctorAppointmentsList, getNextAvailableAppointments, getDoctorsList } from '../../redux/actions/appointmentsActions';
import FooterDrawer from "./FooterDrawer";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import NotFound from "../HomeLogged/NotFound";
import CustomSelect from "../../controls/Select/CustomSelect";
import ModalProfile from "../DialogProfile/Modal";
import { format } from "date-fns";
import ButtonPhones from "../ButtonPhones/ButtonPhones";
import { CircularProgress } from "@mui/material";
import { getFullData } from '../../redux/actions/personalDataActions';

const AppointmentsList = (props) => {
    const { activeStep, setActiveStep, handleOpenCancelModal, medicalCenters } = props;
    const classes = useStyles();
    const date = new Date();
    const dispatch = useDispatch();
    const appointments = useSelector((state) => state.appointmentsReducer);
    const [openModal, setOpenModal] = useState(false);
    const modelNextAppointment = useSelector(state => state.appointmentsReducer);
    const [modelAppointment, setModelAppointment] = useState(modelNextAppointment?.modelAppointment);
    const countItems = 1000;
    const [skipItems, setSkipItems] = useState(0);
    const { screen } = useResizeScreen();
    const selectedSpecialty = [{ value: modelAppointment?.especialidadCodigo, label: modelAppointment?.especialidadDescripcion }];
    const selectedDoctor = [{ value: modelAppointment?.medicoCodigo, label: modelAppointment?.medicoDescripcion }];
    const freeDoctorAppointmentsList = useSelector(state => state.appointmentsReducer?.freeDoctorAppointmentsList.turnosLibres);
    const [modalObservations, setModalObservations] = useState({});
    const flow = modelNextAppointment?.modelAppointment?.appointmentFlow;
    const isDoctorFlow = flow === 'medico';
    const isLoading = useSelector(state => state.appointmentsReducer?.isLoading);
    const doctorsList = useSelector(state => state.appointmentsReducer?.doctorsList?.medicos);
    const [doctorListToCompare, setDoctorListToCompare] = useState(doctorsList);
    const [selectedAppointment, setSelectedAppointment] = useState();
    const personalDataReducer = useSelector((state) => state.personalDataReducer);
    const phones = useSelector((state) => state.phonesReducer.phoneShift);
    const [visualization, setVisualization] = useState('');
    const [phonesToShow, setPhonesToShow] = useState([]);
    const [spontaneousInformation, setSpontaneousInformation] = useState({});
    const isSpontaneous = modelAppointment?.especialidadDescripcion?.toUpperCase()?.includes('ESPONTANEA');

    const optionsToSelect = [
        {
            key: 'doctorSelection',
            label: 'Profesional',
            component: (
                <div style={{ display: modelAppointment?.medicoCodigo === '' && 'none' }}>
                    <Typography style={{ fontWeight: '400', fontSize: '14px', marginLeft: '25px', marginTop: '20px' }}>
                        Profesional
                    </Typography>
                    <CustomSelect
                        className={`${classes.afilliateSelectTurn}`}
                        list={selectedDoctor}
                        selected={modelAppointment?.medicoCodigo}
                        disabled={true}
                    />
                </div>
            ),
        },
        {
            key: 'specialtySelection',
            label: 'Especialidad',
            component: (
                <div>
                    <Typography style={{ fontWeight: '400', fontSize: '14px', marginLeft: '25px', marginTop: '20px' }}>
                        Especialidad
                    </Typography>
                    <CustomSelect
                        className={`${classes.afilliateSelectTurn}`}
                        list={selectedSpecialty}
                        selected={modelAppointment?.especialidadCodigo}
                        disabled={true}
                    />
                </div>
            ),
        },
    ];

    useEffect(() => {
        setPhonesToShow(
            phones?.filter((phone) => phone?.planCode?.includes(personalDataReducer?.fullData?.afiliado?.planDescripcion))
        );
    }, [personalDataReducer]);

    const getFreeAppointmentsParameters = {
        numeroPersona: modelNextAppointment?.modelAppointment?.afiliadoCodigo,
        proveedorNumero: modelNextAppointment?.modelAppointment?.proveedorNumero,
        count: countItems,
        skip: skipItems,
        fechaDesde: date?.toISOString()?.split('T')[0],
        fechaHasta: format(date?.setMonth(date?.getMonth() + 6), 'yyyy-MM-dd'),
        tipoAtencion: 'PRESENCIAL'
    };

    const getAvailableAppointmentsBySpecialty = {
        especialidadCodigo: modelNextAppointment?.modelAppointment?.especialidadCodigo,
        centroMedico: 0,
        count: countItems,
        skip: skipItems,
        tipoAtencion: 'PRESENCIAL'
      };

    useEffect(() => {
        const visualizationType = modelNextAppointment?.modelAppointment?.observations?.[0]?.centrosMedicos?.filter(
            doctorVisualization => doctorVisualization?.especialidadCodigo == modelNextAppointment?.modelAppointment?.especialidadCodigo
        );

        setVisualization(visualizationType?.[0]?.visualizacion?.trim());

        setSpontaneousInformation({
            edadDesde: visualizationType?.[0]?.edadDesde,
            edadHasta: visualizationType?.[0]?.edadHasta,
            horarios: visualizationType?.[0]?.horarios,
            atiendeDiscapacitados: visualizationType?.[0]?.atiendeDiscapacitados,
            observaciones: visualizationType?.[0]?.observaciones,
            centroMedico: getMedicalCenterDescription(visualizationType?.[0]?.centroMedicoCodigo)
        });

        if (visualizationType?.[0]?.observaciones != '' || visualizationType?.[0]?.observaciones != undefined) {
            dispatch(setModelToSaveAppointment({ ...modelAppointment, observaciones: visualizationType?.[0]?.observaciones }));
        }

        setSkipItems(0);
        dispatch(getFullData({ numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")) }));
    }, [])

    const getMedicalCenterDescription = (medicalCenterCode) => {
        let medicalCenterDescription = medicalCenters?.filter(center => center?.centroMedicoCodigo == medicalCenterCode);
        
        return medicalCenterDescription?.[0]?.centroMedicoNombre ?? '';
    }

    useEffect(() => {
        if (isDoctorFlow) {
            dispatch(getFreeDoctorAppointmentsList(getFreeAppointmentsParameters));
        } else {
            dispatch(getNextAvailableAppointments(getAvailableAppointmentsBySpecialty));
        }
    }, [skipItems]);

    useEffect(() => {
        setModelAppointment(modelNextAppointment?.modelAppointment)
    }, [modelNextAppointment?.modelAppointment]);

    useEffect(() => {
        if ((flow == 'especialidad') && (modelAppointment?.centroMedicoCodigo !== undefined) && (doctorsList != doctorListToCompare)) {
            let observations = doctorsList?.filter(selectedDoctor => selectedDoctor?.medicoNombre == modelAppointment?.medicoDescripcion?.trim());
    
            setModalObservations(observations?.[0]?.centrosMedicos?.filter(
                center =>
                    center?.centroMedicoCodigo == modelAppointment?.centroMedicoCodigo &&
                    center?.especialidadCodigo == modelNextAppointment?.modelAppointment?.especialidadCodigo)?.[0]
            );
            setOpenModal(prevState => !prevState);
        }
        setDoctorListToCompare(doctorsList);
    }, [doctorsList])

    useEffect(() => {
        dispatch(setModelToSaveAppointment({ ...modelAppointment, observaciones: modalObservations?.observaciones }));
    }, [modalObservations])

    const handleClick = (item) => e => {
        e.preventDefault();

        if (flow == 'especialidad') {
            dispatch(getDoctorsList({
                numeroPersona: modelAppointment?.afiliadoCodigo,
                medicoNombre: item?.medicoDescripcion?.trim()
            }));
        }

        setSelectedAppointment(item);

        let observations = modelNextAppointment?.modelAppointment?.observations?.[0]?.centrosMedicos;
        let medicalCenterCode = (isDoctorFlow ? item?.centroMedicoCodigo : item?.sucursalCodigo);
        let medicalCenter = medicalCenters?.filter(center => center?.centroMedicoCodigo == medicalCenterCode);

        let modelToSend = {
            ...modelAppointment,
            selectedAppointment: item?.turnoFechHora,
            hourSelected: Moment(item?.turnoFechHora).format('HH:mm'),
            turnoNumero: item?.turnoNumero,
            nombreCentroMedico: medicalCenter?.[0]?.centroMedicoNombre,
            direccion: medicalCenter?.[0]?.direccion,
            medicoCodigo: isDoctorFlow ? modelAppointment?.medicoCodigo : item?.medicoCodigo,
            medicoDescripcion: isDoctorFlow ? modelAppointment?.medicoDescripcion : item?.medicoDescripcion,
            centroMedicoCodigo: medicalCenterCode
        }

        setModalObservations(observations?.filter(center => center?.centroMedicoCodigo == medicalCenterCode && center?.especialidadCodigo == modelNextAppointment?.modelAppointment?.especialidadCodigo)?.[0]);
        setModelAppointment(modelToSend);
        dispatch(setModelToSaveAppointment(modelToSend));
        isDoctorFlow && setOpenModal(prevState => !prevState);
    };

    const handleClickSeeMore = () => setSkipItems(skipItems + 10);

    const handleClickSeeLess = () => setSkipItems(skipItems - 10);

    const handleNext = () => {
        modelAppointment?.turnoNumero != null && setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 2);

    const modalObservationsBody = () => {
        return (
            <div style={{ margin: '0 30px', padding: '0', maxWidth: '250px' }}>
                <Typography style={{ fontSize: "12px", fontWeight: "bold", paddingBottom: "5px" }}>
                    {modelAppointment?.especialidadDescripcion}
                </Typography>
                <Typography style={{ fontSize: "14px", paddingBottom: "15px" }}>
                    {modelAppointment?.medicoDescripcion}
                </Typography>
                <Typography style={{ fontSize: "12px", fontWeight: "bold", paddingBottom: "10px" }}>
                    DATOS DEL TURNO
                </Typography>
                <Typography style={{ fontSize: "12px", paddingBottom: "10px" }}>
                    <span style={{ fontWeight: "bold" }}>EDADES: </span>{modalObservations?.edadDesde} A {modalObservations?.edadHasta} AÑOS
                </Typography>
                <Typography style={{ fontSize: "12px", paddingBottom: "10px" }}>
                    <span style={{ fontWeight: "bold" }}>OBSERVACIONES: </span>
                    {modalObservations?.observaciones === '' || modalObservations?.observaciones === undefined ? "-" : modalObservations?.observaciones}
                </Typography>
                <Typography style={{ fontSize: "12px", paddingBottom: "10px" }}>
                    <span style={{ fontWeight: "bold" }}>PREPARACION: </span>
                    {modalObservations?.preparacion === '' || modalObservations?.preparacion === undefined ? "-" : modalObservations?.preparacion}
                </Typography>
            </div>
        );
    }

    const showAppointmentObservations = () => {
        return (
            <div className={classes.borderWhite} style={{ left: "20px" }}>
                <ModalProfile
                    type='cancel'
                    title={
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                                Observaciones
                            </div>
                        </>
                    }
                    observation={ modalObservationsBody() }
                    open={openModal}
                    onClose={setOpenModal}
                    close={true}
                />
            </div>
        )
    }

    const doctorAppointments = () => {
        return (freeDoctorAppointmentsList?.filter(x => x?.especialidadCodigo == modelAppointment?.especialidadCodigo));
    }

    const listItems = () => {
        const dataList = isDoctorFlow ? doctorAppointments() : appointments?.nextAvailableAppointments?.turnosLibresPorEspecialidad;

        return (
            <List
                component="nav"
                className={classes.root}
                aria-label="mailbox folders"
                style={{ marginBottom: "40px", maxHeight: "496px", overflow: "auto", marginTop: "0", paddingTop: "0" }}>
                {dataList?.slice(0, 10)?.map((item) => (
                    <Fragment key={item}>
                        <ListItem className={classes.listItemTurn}>
                            <Button
                                onClick={handleClick(item)}
                                className={selectedAppointment === item || modelAppointment?.turnoNumero == item?.turnoNumero ? classes.btnHoverNextAppointment : classes.btnNextAppointment}>
                                <div className={`${classes.divDate}`} style={{ justifyContent: 'space-between' }}>
                                    <Typography className={classes.dateNext}>
                                        {Moment(item?.turnoFechHora).format('DD.MM.YYYY')}
                                    </Typography>
                                    <Typography
                                        className={`${classes.descripNext}`}
                                        style={{
                                            whiteSpace: 'nowrap',
                                            width: screen.sWidth < 768 ? '130px' : '200px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontWeight: 700,
                                            fontSize: '.8rem'
                                        }}
                                    >
                                        {isDoctorFlow ? getMedicalCenterDescription(item?.centroMedicoCodigo) : item?.sucursalDescripcion}
                                    </Typography>
                                    <div className={classes.divHour}>
                                        <div>
                                            <Typography className={classes.hourNext} >
                                                {Moment(item?.turnoFechHora)?.format('HH:mm')}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </Button>
                        </ListItem>
                    </Fragment>
                ))}
            </List>
        );
    };

    const showButtons = () => {
        if (skipItems === 0) {
            return (
                <Button size="small" style={{ float: "right" }} onClick={handleClickSeeMore}>
                    VER MAS
                </Button>
            );
        } else if (10 > (flow == 'especialidad' ? appointments?.nextAvailableAppointments?.turnosLibresPorEspecialidad?.length : freeDoctorAppointmentsList?.length)) {
            return (
                <Button size="small" style={{ float: "right" }} onClick={handleClickSeeLess}>
                    VER MENOS
                </Button>
            );
        }
        else {
            return (
                <>
                    <Button size="small" style={{ float: "right", margin: "10px" }} onClick={handleClickSeeMore}>
                        VER MAS
                    </Button>
                    <Button size="small" style={{ float: "right", margin: "10px" }} onClick={handleClickSeeLess}>
                        VER MENOS
                    </Button>
                </>
            );
        }
    };

    const setTitle = () => {
        let title = '';
        if (screen.sWidth < 768) {
            title = (
                <>
                    <Typography
                        className={classes.titleTurn}
                        style={{ color: "#002858", fontWeight: "bold", fontSize: "15px", marginBottom: '-10px' }}
                    >
                        {modelAppointment?.especialidadDescripcion}
                    </Typography>

                    <Typography className={classes.titleTurn} style={{ marginBottom: '20px' }}>
                        {modelAppointment?.medicoDescripcion}
                    </Typography>
                </>
            );
        }
        return title;
    }

    const isNotSpontaneous = () => {
        return (!isSpontaneous && (visualization !== 'DEMANDA ESPONTANEA'));
    }

    const showComponentByVisualization = () => {
        if (visualization == 'TURNO TELEFONICO') {
            return (
                <div style={{ width: screen.sWidth < 768 && '280px', margin: screen.sWidth < 768 ? '0 auto' : '0' }}>
                    <Typography
                        style={{
                            fontFamily: "Arial, regular",
                            fontSize: screen.sWidth < 768 ? "16px" : "20px",
                            color: "#0075c9",
                            fontWeight: 'bold',
                            marginTop: screen.sWidth > 768 && '15px',
                            marginLeft: screen.sWidth > 768 && '10%'
                        }}
                    >
                        Turno telefónico
                    </Typography>
                    <ButtonPhones listItems={phonesToShow} />
                </div>
            );
        }

        return (
            (isNotSpontaneous()) &&
            <div>
                {listItems()}
                {showButtons()}
            </div>
        );
    }

    const componentContainer = (component) => {
        return (
            <div
                style={{
                    justifyContent: "space-between",
                    display: "grid",
                    gridTemplateColumns: screen.sWidth > 1100 ? "1fr 1fr" : "1fr",
                    paddingTop: "5px",
                    paddingBottom: "5px"
                }}
            >
                {optionSelected()}
                {component}
            </div>
        );
    }

    const showSpontaneousInformation = () => {
        return (
            <>
                <div style={{ marginTop: screen.sWidth > 968 && '30px', marginLeft: screen.sWidth < 768 && '20px' }}>
                    <Typography
                        className={`${classes.txtObservaciones}`}
                        style={{ margin: "5px" }}
                    >
                        <span className={`${classes.txtBold500} ${classes.grayTxt}`}>
                            Centro médico:{" "}
                        </span>
                        <span className={classes.grayTxt}>{spontaneousInformation?.centroMedico}</span>
                    </Typography>
                    <Typography
                        className={`${classes.txtObservaciones}`}
                        style={{ margin: "5px" }}
                    >
                        <span className={`${classes.txtBold500} ${classes.grayTxt}`}>
                            Horarios de atención:{" "}
                        </span>
                        <span className={classes.grayTxt}>{spontaneousInformation?.horarios}</span>
                    </Typography>
                    <Typography
                        className={`${classes.txtObservaciones}`}
                        style={{ margin: "5px" }}
                    >
                        <span className={`${classes.txtBold500} ${classes.grayTxt}`}>
                            {`Edades: `}
                        </span>
                        <span className={classes.grayTxt}>
                            de {spontaneousInformation?.edadDesde} a {spontaneousInformation?.edadHasta} años{" "}
                            {spontaneousInformation?.atiendeDiscapacitados === "S" &&
                                <>(atiende capacidades especiales)</>
                            }
                        </span>
                    </Typography>
                    <Typography
                        className={`${classes.txtBold500} ${classes.txtObservaciones} ${classes.grayTxt}`}
                        style={{ margin: "5px" }}
                    >
                        {spontaneousInformation?.observaciones !== '' && (`Observaciones: ${spontaneousInformation?.observaciones}`)}
                    </Typography>

                </div>
            </>
        );
    }

    const optionSelected = () => {
        if (screen.sWidth > 768) {
            return (
                <div style={{ marginLeft: '14px' }}>
                    {flow === 'medico' ?
                        (
                            <>
                                {optionsToSelect?.find((option) => option?.key === 'doctorSelection')?.component}
                                {optionsToSelect?.find((option) => option?.key === 'specialtySelection')?.component}
                            </>
                        )
                    :
                        (
                            <>
                                {optionsToSelect?.find((option) => option?.key === 'specialtySelection')?.component}
                                {optionsToSelect?.find((option) => option?.key === 'doctorSelection')?.component}
                            </>
                        )
                    }
                </div>
            );
        }
        return null;
    };

    const showAppointmentsList = () => {
        return (
            isDoctorFlow
            ? doctorAppointments()?.length
            : appointments?.nextAvailableAppointments?.turnosLibresPorEspecialidad?.length
        );
    }

    const showMessage = () => {
        if (!isDoctorFlow) {
            return (
                componentContainer(
                    <NotFound
                        customMsg={
                            <div className={classes.contentNotFound}>
                                <span style={{ marginTop: "10%" }}>
                                    {appointments?.nextAvailableAppointments?.mensajes?.length > 0
                                        ? appointments?.nextAvailableAppointments?.mensajes?.[0]
                                        : 'No hay turnos disponibles'
                                    }
                                </span>
                            </div>
                        }
                        wsItem={appointments?.nextAvailableAppointments?.turnosLibresPorEspecialidad}
                        fullItem={appointments?.nextAvailableAppointments?.turnosLibresPorEspecialidad}
                        loading={appointments?.isLoading}
                    />
                )
            );
        } else if(isDoctorFlow && isNotSpontaneous()) {
            return (
                componentContainer(
                    <NotFound
                        customMsg={
                            <div className={classes.contentNotFound}>
                                <span style={{ marginTop: "10%" }}>No hay turnos disponibles</span>
                            </div>
                        }
                        wsItem={doctorAppointments() ?? []}
                        fullItem={doctorAppointments() ?? []}
                        loading={appointments?.isLoading}
                    />
                )
            );
        }
    }

    return (
        <>
            <div className={classes.drawerContainerTurn} style={{ backdropFilter: "blur(8px) !important", marginLeft: '20px' }}>
                    <>
                        <Typography className={classes.titleTurn} style={{ marginTop: "20px" }}>
                            {flow === "especialidad"
                                ? "Turno por Especialidad / Presencial"
                                : "Turno por Médico / Presencial"
                            }
                        </Typography>
                        {setTitle()}
                    </>
                {showAppointmentsList() > 0 && !isLoading && isNotSpontaneous() ?
                    <>
                        {componentContainer(showComponentByVisualization())}
                    </>
                    :
                    showMessage()
                }

                {!appointments?.isLoading && ((visualization == 'DEMANDA ESPONTANEA' || isSpontaneous) && flow !== 'especialidad')
                    ? componentContainer(showSpontaneousInformation())
                    :
                    (
                        visualization == 'DEMANDA ESPONTANEA' &&
                        <div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}>
                            <CircularProgress />
                        </div>
                    )
                }

                {openModal && showAppointmentObservations()}

            </div>

            {screen.sWidth < 768 &&
                <FooterDrawer
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    handleOpenCancelModal={handleOpenCancelModal}
                />
            }

        </>
    );
};

export default AppointmentsList;