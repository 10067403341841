import ModalActivities from "../DialogActivities/ModalActivities";

const SaveModal = ({ isOpen, setOpen, type, onClick, titleModal, descriptionModal, modalRegister, escapeKeyDown, backdropClick }) => {

    return <>
        {isOpen ?
            <ModalActivities
                title={titleModal}
                description={descriptionModal}
                open={isOpen}
                onClose={setOpen}
                modalconfirm={true}
                modalLogout={false}
                buttonType={type}
                handleClick={onClick}
                modalRegister={modalRegister}
                disableEscapeKeyDown={escapeKeyDown}
                onBackdropClick={backdropClick}
            />
            : ""}
    </>;
};

export default SaveModal;