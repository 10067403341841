import React, { Suspense, lazy } from "react";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
const waitFor = Tag => props => <Tag {...props} />;
const Home = lazy(() => import('./pages/Home/index'));
import ReactDOM from "react-dom";
import { Switch, Route } from "react-router-dom";
import { Callback } from "./auth/callback";
import { LogoutCallback } from "./auth/logoutCallback";
import { SilentRenew } from "./auth/silentRenew";
import { Logout } from "./auth/logout";
import Login from "./auth/login";
import UsefulPhones from "./pages/UsefulPhones/index";
import CoveragePlans from "./pages/CoveragePlans/index";
import Activities from "./pages/Activities";
import MedicalRecord from "./pages/MedicalRecord/index";
import MapContainer from "./components/MapContainer/MapContainer";
import Headquarters from "./components/Headquarters/Headquarters";
import AppointmentHome from "./pages/Turn/index";
import { PrivateRoute } from "./privateRoute";
import RedirectPageContactAdvisor from "./pages/RedirectPageContactAdvisor";
import PersonalData from "./pages/PersonalData";
import Credentials from "./pages/Credentials";
import LoadingPage from "./components/LoadingPage/LoadingPage";
import loading from './assets/styles/loading.css';
import MedicalData from "./pages/MedicalData";
import PersonalMedicalData from "./pages/MedicalData/PersonalMedicalData";
import ProgramsHome from "./pages/MedicalData/ProgramsHome";
import VirtualDoctor from "./pages/VirtualDoctor";
import Coverage from "./pages/Coverage";
import TravelCoverage from "./pages/Coverage/TravelCoverage";
import AllNotification from "./components/Notification/AllNotification";
import Register from "./auth/Register";
import ContactUs from "./pages/ContactUs";
import Notification from "./pages/Notification";
import Billing from "./pages/Billing";
import Prevention from "./components/MedicalData/Prevention/Prevention";
import Authorizations from "./pages/Authorizations";
import AuthorizationsDetails from "./components/Authorizations/AuthorizationsDetails";
import AppointmentRecord from "./pages/AppointmentRecord";
import AppointmentNext from "./pages/AppointmentNext";
import AppointmentFavorite from "./pages/AppointmentFavorite";
import Questions from "./pages/Turn/Question";
import Refunds from "./pages/Refunds";
import FrequentQuestionsPage from "./pages/FrequentQuestions";
import HeadquartersFrequentQuestions from "./components/Headquarters/HeadquartersFrequentQuestions";
import AuthorizationsFrequentQuestions from "./components/Authorizations/AuthorizationsFrequentQuestions";
import RefundsFrequentQuestions from "./components/Refunds/RefundsFrequentQuestions";
import RefundsDetails from "./components/Refunds/RefundsDetails";
import PrescriptionPage from './components/MedicalData/PrescriptionPage/PrescriptionPage'



export const Routes = (
  <TransitionGroup>
    <CSSTransition timeout={500} classNames={{ ...loading }}>
      <Suspense fallback={ReactDOM.render(<LoadingPage />, document.getElementById("loadingDiv"))}>
        <Switch >
          <Route path="/silentrenew" exact={true} component={SilentRenew} />
          <Route path="/logout" exact={true} component={Logout} />
          <Route path="/logout/callback" exact={true} component={LogoutCallback} />
          <Route path="/callback" exact={true} component={Callback} />
          <Route path="/login" exact={true} component={Login} />
          <Route exact={true} path="/phones" component={UsefulPhones} />
          <Route exact={true} path="/plans" component={CoveragePlans} />
          <Route exact={true} path="/contactadvisor" component={RedirectPageContactAdvisor} />
          <Route
            exact={true}
            path="/consumercomplaint"
            component={() => {
              window.location.href = window.env.URL_ConsumerComplaint;
            }}
          />
          <Route
            exact={true}
            path="/lowservice"
            component={() => {
              window.location.href = window.env.URL_LowService;
            }}
          />
          <Route path="/MedicalRecords" component={MedicalRecord} />
          <Route exact={true} path="/activities" component={Activities} />
          <Route path="/mapContainer" component={MapContainer} />
          <Route path="/headquarters" component={Headquarters} />
          <Route exact={true} path="/" component={waitFor(Home)} />
          <Route exact={true} path="/headquartersFAQ" component={HeadquartersFrequentQuestions} />
          <Route exact={true} path="/AuthorizationsFAQ" component={AuthorizationsFrequentQuestions} />
          <Route exact={true} path="/RefundsFAQ" component={RefundsFrequentQuestions} />

          <PrivateRoute path="/AppointmentHome" component={AppointmentHome} />
          <PrivateRoute exact={true} path="/PersonalData" component={PersonalData} />

          <PrivateRoute path="/Credentials" component={Credentials} />

          <PrivateRoute path="/MedicalData" component={MedicalData} />
          <PrivateRoute path="/PersonalMedicalData" component={PersonalMedicalData} />
          <PrivateRoute path="/ProgramsHome" component={ProgramsHome} />
          <PrivateRoute path='/Prevention' component={Prevention} />
          <PrivateRoute path="/Coverage" component={Coverage} />
          <PrivateRoute path="/TravelCoverage" component={TravelCoverage} />
          <PrivateRoute path="/PrescriptionPage" component={PrescriptionPage} />

          <PrivateRoute path="/VirtualDoctor" component={VirtualDoctor} />
          <PrivateRoute path="/AllNotifications" component={AllNotification} />
          <PrivateRoute path="/Register" component={Register} />
          <PrivateRoute path="/Notification" component={Notification} />
          <PrivateRoute path="/ContactUs" component={ContactUs} />
          <PrivateRoute path='/Billing' component={Billing} />
          <PrivateRoute path='/Authorizations' component={Authorizations} />
          <PrivateRoute path='/AuthorizationsDetails' component={AuthorizationsDetails} />

          <PrivateRoute path='/AppointmentRecord' component={AppointmentRecord} />
          <PrivateRoute path='/NextAppointments' component={AppointmentNext} />
          <PrivateRoute path='/FavoriteAppointments' component={AppointmentFavorite} />
          <PrivateRoute path='/FrequentQuestions' component={Questions} />
          <PrivateRoute path='/Refunds' component={Refunds} />
          <PrivateRoute path='/RefundsDetails' component={RefundsDetails} />
          <PrivateRoute path="/FrequentQuestionsPage" component={FrequentQuestionsPage} />
        </Switch>
      </Suspense>
    </CSSTransition>
  </TransitionGroup >
);
