import * as actionTypes from "../consts/actionTypes";

export function setSelectedFiltersCartilla(selectedFilters) {
  return {
    type: actionTypes.SET_SELECTED_FILTERS_VALUES,
    payload: selectedFilters,
  };
}

export function setModelFiltersCartilla(modelSelected) {
  return {
    type: actionTypes.SET_MODEL_FILTERS_VALUES,
    payload: modelSelected,
  };
}

export function getPrestadoresCartilla(filtersCartilla) {
  return {
    type: actionTypes.GET_PRESTADORES_CARTILLA,
    payload: filtersCartilla,
  };
}

export function getFiltersValues(param) {
  return {
    type: actionTypes.GET_FILTERS_VALUES,
    payload: param,
  };
}

export function getLocalidadesBarrios(codigo) {
  return {
    type: actionTypes.GET_LOCALIDADES_BARRIOS,
    payload: codigo,
  };
}

export function getEspecialidadesEstudios(codigo) {
  return {
    type: actionTypes.GET_ESPECIALIDADES_ESTUDIOS_COMPLEMENTARIOS,
    payload: codigo,
  };
}

export function getPerfilCompleto(providerCode) {
  return {
    type: actionTypes.GET_PERFIL_COMPLETO,
    payload: providerCode,
  };
}

export const getDataFailure = (error) => {
  return {
    type: actionTypes.SEND_REQUEST_FAILURE,
    payload: {},
    error: error,
  };
};

export const cleanModelMedicalRecords = () => {
  return {
    type: actionTypes.CLEAN_MODEL_MEDICAL_RECORDS,
    payload: ''
  };
};

export const setLoadingSearchMedicalRecord = (param) => {
  return {
    type: actionTypes.SET_LOADING_MEDICAL_RECORDS,
    payload: param
  };
};

export const cleanPerfilCompleto = () => {
  return {
    type: actionTypes.CLEAN_PERFIL_COMPLETO,
    payload: ''
  };
};