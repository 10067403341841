import * as actionTypes from '../consts/actionTypes';
import {responseToReturn} from '../../utils/utilsFunctions.js';

const initialState = {
    contactUs: [],
    msgError: ''
};

export default function contactUsReducer(state = initialState, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_POST_CONTACT_US]: responseToReturn('contactUs', state, action),
        [actionTypes.FAILURE_POST_CONTACT_US]: responseToReturn('', state, action),
    };

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}