import {
  ClickAwayListener,
  Divider,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  TextField,
  Typography,
  IconButton,
  Zoom,
} from "@material-ui/core";
import CustomButton from "../../../controls/Button/CustomButton";
import { useEffect, useState } from "react";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import useStyles from "../../../assets/styles/styles";
import TuneIcon from "@material-ui/icons/Tune";

import InputLabel from "@material-ui/core/InputLabel";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import {
  getDetailLaboratoryStudies,
  getLaboratoryStudies,
} from "./../../../redux/actions/medicalDataAction";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import NotFound from "../../HomeLogged/NotFound";
import { es } from "date-fns/locale";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { handleGoToMedicalData } from "../../../utils/utilsFunctions";
import AppointmentsCard from "../../Appointments/Cards/AppointmentsCard";
import HeaderCard from '../../Billing/Movements/HeaderCard';


const LaboratoryStudies = ({ handleBack }) => {
  const dispatch = useDispatch();
  const today = new Date();
  today.setMonth(-6);
  const [open, setOpen] = useState(false);
  const [dateStart, setDateStart] = useState(today);
  const [dateEnd, setDateEnd] = useState(new Date());
  const [skip, setSkip] = useState(0);
  const classes = useStyles();
  const { screen } = useResizeScreen();
  const [studies, setStudies] = useState([]);
  const medicalData = useSelector((state) => state?.medicalDataReducer);
  const [isDetail, setIsDetail] = useState(false);
  const [details, setDetails] = useState([]);
  const paddingStyle = "5px 20px";
  const [loading, setLoading] = useState(true);

 

  useEffect(() => {
    callToService(false, true);
  }, []);

  useEffect(() => {
    if (
      medicalData?.laboratoryStudies?.resultadosLaboratorio !== undefined &&
      medicalData?.laboratoryStudies?.resultadosLaboratorio?.length > 0
    ) {
      //paginado agrega a la lista
      if (studies.length !== 0) {
        let newRegisters = studies;
        medicalData?.laboratoryStudies?.resultadosLaboratorio?.forEach(
          (element) => {
            newRegisters.push(element);
          }
        );
        setStudies(newRegisters);
      }
      //filtro actualiza lista completa
      else {
        setStudies(medicalData?.laboratoryStudies?.resultadosLaboratorio);
      }
    }
  }, [medicalData?.laboratoryStudies]);

  useEffect(() => {
    if (
      medicalData?.detailLaboratory?.resultadosLaboratorio !== undefined &&
      medicalData?.detailLaboratory?.resultadosLaboratorio?.length > 0
    ) {
      setDetails(medicalData?.detailLaboratory?.resultadosLaboratorio);
    }
  }, [medicalData?.detailLaboratory]);

  useEffect(() => {
    filterDate();
  }, [dateEnd]);

  useEffect(() => {
    filterDate();
  }, [dateStart]);

  const filterDate = () => {
    setStudies([]);
    setSkip(0);
    callToService(true);
  };

  const callToService = (tipoFecha, first) => {
    let auxSkip = skip;
    if (dateStart !== null && dateEnd !== null) {
      if (tipoFecha || first) {
        auxSkip = 0;
      } else {
        auxSkip += 10;
      }
      const param = {
        numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
        count: 10,
        skip: parseInt(auxSkip),
        fechaDesde: dateStart,
        fechaHasta: dateEnd
      };
      setSkip(parseInt(auxSkip));
      dispatch(getLaboratoryStudies(param));
      setLoading(false);
    }
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const scrollDiv = () => {
    return screen.sWidth < 800 ? classes.heightDivRegisters : "";
  };

  const getDetail = (item) => {
    let param = {
      numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
      resultadoLaboratorioCodigo: item.codigo,
    };
    dispatch(getDetailLaboratoryStudies(param));
    setIsDetail(true);
  };

  const filter = () => {
    return (
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <div style={{ position: "relative", marginRight: "30px" }}>
          <CustomButton
            title={""}
            onClick={handleClick}
            icon={
              <TuneIcon
                style={{ marginRight: "5px" }}
                className={classes.iconFilter}
              />
            }
            customClass={`${classes.customHeaderBtn} ${classes.btnFilterAppointment}`}
          />
          {open ? (
            <Zoom in={open}>
            <div className={classes.dropdown}>
              <Typography
                className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}
              >
                {"PERIODO"}
              </Typography>
              <Divider classes={{ root: classes.dividerFilter }} />
              {
                <div className={`${classes.headerFiltro} ${classes.marginTop}`}>
                  <FormControl
                    style={{ width: "150px", paddingTop: "10px" }}
                    className={classes.datePicker}
                  >
                    <InputLabel
                      className={`${classes.txtSubtitleCalendar} ${classes.fontSize15}`}
                      shrink
                    >
                      {"DESDE"}
                    </InputLabel>
                    <div
                      className={classes.top22}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <LocalizationProvider
                        style={{ width: "100px" }}
                        locale={es}
                        dateAdapter={AdapterDateFns}
                      >
                        <MobileDatePicker
                          cancelText="Cancelar"
                          toolbarTitle=""
                          format="dd/MM/YYYY"
                          value={dateStart}
                          onChange={(newValue) => {
                            setDateStart(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <CalendarTodayIcon style={{ alignSelf: "center" }} />
                    </div>
                  </FormControl>
                  <FormControl
                    style={{
                      width: "150px",
                      marginLeft: "10px",
                      paddingTop: "10px",
                    }}
                    className={classes.datePicker}
                  >
                    <InputLabel
                      className={`${classes.txtSubtitleCalendar} ${classes.fontSize15}`}
                      shrink
                    >
                      {"HASTA"}
                    </InputLabel>
                    <div
                      className={classes.top22}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          cancelText="Cancelar"
                          toolbarTitle=""
                          format="dd/MM/YYYY"
                          value={dateEnd}
                          onChange={(newValue) => {
                            setDateEnd(newValue);
                          }}
                          variant="outlined"
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <CalendarTodayIcon style={{ alignSelf: "center" }} />
                    </div>
                  </FormControl>
                </div>
              }
            </div>
            </Zoom>
          ) : (
            ""
          )}
        </div>
      </ClickAwayListener>
    );
  };

  const generateCard = (i, item) => {
    return (
      <AppointmentsCard
        key={i}
        index={i}
        buttonsInHeader={true}
        headerIcon={
          <HeaderCard
            day={item?.fechaFormateada?.nombreDia}
            numberDay={item?.fechaFormateada?.dia}
            month={parseInt(item?.fecha?.substring(5, 7))}
            year={parseInt(item?.fecha?.substr(0, 4))}
          />}
        description={
            <>
                <Typography style={{ fontWeight: "600", color: '#0075c9', marginTop: "5px" }} className={`${classes.txtCardDescription}`}>
                    <span style={{ fontSize: '1rem' }}>{"HORA: " + item.fecha.substr(item.fecha.indexOf("T") + 1, 5) + " hs."}</span>
                </Typography>
                    <div style={{ display: 'flex' }}>
                        <Typography style={{ fontWeight: "600", color: '#0075c9', marginBottom: "5px" }} className={`${classes.txtCardDescription} `} >
                            <span style={{ fontSize: '0.7rem', color: "#002858" }}>{"N° DE ORDEN: " + item.orden}</span>
                        </Typography>
                        </div>
                <Typography className={`${classes.txtCardDescription}`}>
                  {item.examen.slice(0, 20) + "..."}
                </Typography>
            </>
        }
        buttonZone={
            <section className={`${classes.btnZoneAppointmentCard}`}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CustomButton
                  customClass={classes.lightLinearGradient}
                  style={{ padding: paddingStyle }}
                  title="VER"
                  onClick={() => {
                    getDetail(item);
                  }}
                />
              </div>
            </section>
        }
    />
    );
  };

  const generateAccordionMobile = (item, i) => {
    return (
      <Accordion key={i}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.txtSubtitleCalendar}>
            {item.examen}
          </Typography>
        </AccordionSummary>
        <div className={classes.heightDivRegisters}>
          {item.examenValores.map((detalle, x) => (
            <AccordionDetails key={x}>
              <div>
                <Typography className={classes.subTitleMedicalData}>
                  {detalle.determinacion}
                </Typography>
              </div>
              <div>
                <Typography
                  className={`${classes.txtDescription} ${classes.txtEllipsis}`}
                >
                  {" "}
                  {"Valor: " + detalle.valor}
                </Typography>
              </div>
              <div>
                <Typography
                  className={`${classes.txtDescription} ${classes.txtEllipsis}`}
                >
                  {"Unidad: " + detalle.unidad}{" "}
                </Typography>
              </div>
              <div>
                <Typography
                  className={`${classes.txtDescription} ${classes.txtEllipsis}`}
                >
                  {" "}
                  {"Valores de Referencia: " + detalle.valorReferencia}{" "}
                </Typography>
              </div>
            </AccordionDetails>
          ))}
        </div>
      </Accordion>
    );
  };

  const generateAccordionWeb = (item, i) => {
    return (
      <Accordion key={i} expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "25%" }}>
              <Typography className={classes.txtSubtitleCalendar}>
                {item.examen}
              </Typography>
            </div>

            <div style={{ width: "25%" }}>
              <Typography
                className={`${classes.txtSubtitleCalendar} ${classes.txtEllipsis}`}
              >
                {" "}
                {"Valor "}
              </Typography>
            </div>
            <div style={{ width: "25%" }}>
              <Typography
                className={`${classes.txtSubtitleCalendar} ${classes.txtEllipsis}`}
              >
                {"Unidad "}{" "}
              </Typography>
            </div>
            <div style={{ width: "25%" }}>
              <Typography
                className={`${classes.txtSubtitleCalendar} ${classes.txtEllipsis}`}
              >
                {" "}
                {"Valores de Referencia: "}{" "}
              </Typography>
            </div>
          </div>
        </AccordionSummary>

        {item.examenValores.map((detalle, x) => (
          <AccordionDetails key={x}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "25%" }}>
                <Typography className={classes.txtDescription}>
                  {detalle.determinacion}
                </Typography>
              </div>
              <div style={{ width: "25%" }}>
                <Typography
                  className={`${classes.txtDescription} ${classes.txtEllipsis}`}
                >
                  {" "}
                  {detalle.valor}
                </Typography>
              </div>
              <div style={{ width: "25%" }}>
                <Typography
                  className={`${classes.txtDescription} ${classes.txtEllipsis}`}
                >
                  {detalle.unidad}{" "}
                </Typography>
              </div>
              <div style={{ width: "25%" }}>
                <Typography
                  className={`${classes.txtDescription} ${classes.txtEllipsis}`}
                >
                  {" "}
                  {detalle.valorReferencia}{" "}
                </Typography>
              </div>
            </div>
          </AccordionDetails>
        ))}
      </Accordion>
    );
  };

  const generateAccordion = (item, i) => {
    return screen.sWidth <= 800
      ? generateAccordionMobile(item, i)
      : generateAccordionWeb(item, i);
  };

  function btnVerMas(condition) {
    if (condition > 800) {
      var response = (
        <div style={{ width: "100%" }} className={classes.BtnMobileWeight}>
          <CustomButton
            customClass={classes.lightLinearGradient}
            title="ver mas"
            onClick={() => {
              callToService();
            }}
          />
        </div>
      );
    } else {
      response = ("");
    }
    return response;
  }

  function showAccordion(condition) {
    if (condition > 0) {
      var response = (
        details.map((item, i) => generateAccordion(item, i))
      );
    } else {
      response = (
        <NotFound
          customMsg={
            <div className={classes.contentNotFound}>
              <span>
                No se encontraron resultados
              </span>
            </div>
          }
          wsItem={medicalData?.detailLaboratory}
          fullItem={medicalData?.detailLaboratory?.resultadosLaboratorio}
        />
      );
    }
    return response;
  }

  function showStudies(condition) {
    if (condition > 0) {
      var response = (
        <div
          className={`${classes.flexRowAcordion} ${scrollDiv()}`}
          onScroll={(e) => {
            handleScroll(e);
          }}
        >
          {studies.map((item, i) => (
            <div className={widthClass(screen.sWidth, classes)} style={{marginLeft: "1%"}}>
              {generateCard(i, item)}
            </div>
          ))}
        </div>
      );
    } else {
      response = (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ?
                <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
                :
          <NotFound
            customMsg={
              <div className={classes.contentNotFound}>
                <span>
                  No se encontraron resultados
                </span>
              </div>
              }
            wsItem={medicalData?.laboratoryStudies?.resultadosLaboratorio}
            fullItem={medicalData?.laboratoryStudies?.resultadosLaboratorio}
          />}
        </div>
      );
    }
    return response;
  }

  return (
    <>
      <div
        className={`${setBackgroundMobile(classes, screen.sWidth)}`}
        style={{ paddingTop: "60px", marginBottom: "20px", display: "flex", alignItems: "center" }}
      >
        {
          screen.sWidth <= 768 &&
          <>
            <IconButton onClick={handleGoToMedicalData}>
              <ArrowBackIcon className={classes.ShowMoreArrowIcon} style={{ fontSize: "25px", marginBottom: "10px" }} />
            </IconButton>
            <Typography
              className={`${classes.titleTurn} ${classes.titleBox}`}
              style={{ textTransform: "uppercase" }}
            >
              RESULTADOS DE LABORATORIOS
            </Typography>
          </>
        }
      </div>

      <div className={classes.headerSecundary} style={{ marginBottom: "20px" }}>
        <Typography
          className={classes.subTitleMedicalData}
          style={{ marginLeft: "20px", padding: paddingStyle }}
        >
          LABORATORIOS REALIZADOS EN CM CONGRESO Y RAMOS
        </Typography>

        <div className={classes.headerFiltro}>
          {isDetail ? (
            <CustomButton
              customClass={classes.lightLinearGradient}
              style={{ padding: paddingStyle }}
              title="ATRAS"
              onClick={() => {
                setIsDetail(false);
              }}
            />
          ) : (
            filter()
          )}
        </div>
      </div>
      {isDetail ? (
        showAccordion(medicalData?.detailLaboratory?.resultadosLaboratorio?.length)
      ) : (
        <>
          {showStudies(studies?.length)}

          {studies?.length >= 10 && btnVerMas(screen.sWidth)}
        </>
      )}
    </>
  );
};

export default LaboratoryStudies;

function setBackgroundMobile(classes, width) {
  let cssClass = classes.displayNone;
  if (width <= 768) { cssClass = classes.titlePersonalDataForm; }
  return cssClass;
}

function widthClass(width, classes) {
  if (width <= 800) { return classes.width100; }
  else if (width > 800 && width < 1250) { return classes.width50; }
  else { return classes.width25; }
}
