import { Button, Divider, CircularProgress } from "@material-ui/core";
import useStyles from '../../../assets/styles/styles';
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import CustomButton from "../../../controls/Button/CustomButton";
import CreateIcon from '@material-ui/icons/Create';
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormHeader from "../FormHeader";
import SportsHobbiesForm from "./SportsHobbiesForm";
import { handleGoToPersonalData, showMessageModal } from "../../../utils/utilsFunctions";


const SportsHobbies = ({ handleBack }) => {
    const fullPersonalData = useSelector(state => state.personalDataReducer);
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const [disabled, setDisabled] = useState(true);
    const [personalData, setPersonalData] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorModal, setErrorModal] = useState(false);   
    const errorMessage = useSelector(state => state.personalDataReducer.msgError);

    const handleEdit = (e) => {
        setDisabled(prevState => !prevState);
    };

    useLayoutEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        if (Object.values(fullPersonalData?.fullData).length > 0) {
            setPersonalData(fullPersonalData?.fullData?.afiliado);
            setLoading(false);
        }
    }, [fullPersonalData?.fullData]);

    useEffect(() => {
        if (errorMessage !== '' && errorMessage !== undefined) {
            setErrorModal(true);
        }
    }, [errorMessage]);

    return <>
        <FormHeader title='Hobbies y Deportes' handleGoTo={handleGoToPersonalData} />
        {
            screen.sWidth > 768 &&
            <Divider className={classes.formDivider} />
        }
        {disabled ?
            <div style={{ position: 'absolute', right: '0' }} className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                {screen.sWidth <= 768 ?
                    <Button onClick={handleEdit}>
                        <CreateIcon className={classes.ShowMoreArrowIcon} />
                    </Button>
                    :
                    (screen.sWidth > 768 && disabled ?
                        (<CustomButton
                            title="EDITAR"
                            customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                            onClick={handleEdit}
                        />) :
                        (<CustomButton
                            title="CANCELAR"
                            customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                            onClick={handleEdit}
                        />)
                    )
                }
            </div> :
            <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm} ${classes.btnCancelarAddress}`}>
                <CustomButton
                    title="CANCELAR"
                    customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                    onClick={handleEdit}
                />
            </div>
        }
        {loading ?
            <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
            :
            <div style={{ display: "flex", justifyContent: "center", width: '100%' }}>
                <SportsHobbiesForm personalData={personalData} setPersonalData={setPersonalData} disabled={disabled} handleEdit={handleEdit} editMode={editMode} setEditMode={setEditMode} />
            </div>
        }
        {
            showMessageModal(
                errorMessage,
                errorModal,
                setErrorModal,
                classes.borderWhite,
                classes.quickAccessIcon,
                classes.contentDialogTitle,
                handleGoToPersonalData
            )
        }
    </>;
};

export default SportsHobbies;
