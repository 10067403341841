import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetCounterSurvey({ payload }) {
    try {
        const response = yield call(API.getCounterSurvey, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_COUNTER_SURVEY, response, message: '' });
        }
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_GET_COUNTER_SURVEY,
            message: error.message,
        });
    }
}

function* asyncSendSurvey({ payload }) {
    try {
        const response = yield call(API.sendSurvey, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_SEND_SURVEY, response, message: '' });
        }
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_SEND_SURVEY,
            message: error.message,
        });
    }
}


// Watchers
export default function* surveySaga() {
    yield takeLatest(actionTypes.GET_COUNTER_SURVEY, asyncGetCounterSurvey);
    yield takeLatest(actionTypes.SEND_SURVEY, asyncSendSurvey);
}
