import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetPracticas() {
    try {
        const response = yield call(API.getPracticas);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_PRACTICAS, response, message: '' });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_GET_PRACTICAS,
            message: error.message,
        });
    }
}


// Watchers
export default function* practicasSaga() {
    yield takeLatest(actionTypes.GET_PRACTICAS, asyncGetPracticas);
}
