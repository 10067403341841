import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Menu from "../../components/Menu/Menu";
import Header from "../../components/GenericHeader/Header";
import Banner from "../../components/Banner/Banner";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import useStyles from "../../assets/styles/styles";
import CardPhoto from '../../components/CardPhoto/CardPhoto';
import { useDispatch, useSelector } from 'react-redux';
import { getActivities } from "../../redux/actions/activitiesActions";
import parse from 'html-react-parser';
import { isAuthenticated } from '../../utils/utilsFunctions';
import  useTrackPageView  from "../../utils/useTrackPageView";

const Activities = () => {
  const classes = useStyles();
  const screenSize = useResizeScreen();
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const activities = useSelector(state => state?.activitiesReducer?.activities?.activity);
  const [card, setCard] = useState([])
  const [banner, setBanner] = useState([])
  const trackPageView = useTrackPageView("Actividades");

  useEffect(() => {
    trackPageView();
    if (isAuthenticated()) {
      dispatch(getActivities('Authorize'));
    } else {
      dispatch(getActivities())
    }
  }, [])

  useEffect(() => {
    let imgBanner = activities?.find((item) => { return item?.panelPosition === 0 })
    let imgResponse = [{ title: imgBanner?.title, img: imgBanner?.image }]
    setBanner(imgResponse);
    let activity = activities?.filter(x => x?.panelPosition === 1).map((item) => {
      return {
        title: item?.title,
        subtitle: "",
        description: parse(item?.description),
        observation: "",
        info: "",
        img: item?.image,
      }
    })
    setCard(activity);
  }, [activities])

  const handleClick = (e) => {
    e.preventDefault();
    if (showMore) {
      setShowMore(false);
    }
    else {
      setShowMore(true);

    }
  };

  const addShowMore = () => {
    if (card !== undefined) {
      return (showMore ?
        <CardPhoto listCard={card} countCard={card?.length}
          hasButton={true} hasDetail={false} />
        :
        <CardPhoto listCard={card} countCard={3} hasButton={false} hasDetail={false} />);
    }
  };

  const addShowMoreButton = () => {
    return (showMore ? (
      <Button className={classes.txtBtnShowMore} onClick={handleClick}>
        {"VER MENOS"}
      </Button>
    ) : (
      <Button className={classes.txtBtnShowMore} onClick={handleClick}>
        {"VER MÁS >"}
      </Button>
    ));
  };

  const mobileScreen = () => {
    if (screenSize.screen.sWidth <= "768") {
      return (<CardPhoto
        listCard={card}
        countCard={card?.length}
        hasButton={true}
        hasDetail={false}
        isNotPlan={true} />)
    }
    else {
      return (addShowMore())
    }
  }
  return (
    <>
      <Menu />
      <Header title="Actividades" colorTitle="#0075C9" heightTitle="140px" />
      {activities !== undefined ?
        <>
          < Banner
            items={banner}>
          </Banner>
          {screenSize.screen.sWidth >= "768" &&
            <Box
              display="flex"
              justifyContent="flex-end"
              paddingLeft="34px"
              paddingRight="56px"
              minHeight="44px"
            >
              {addShowMoreButton()}
            </Box>
          }
          {mobileScreen()}
        </>
        :
        (<div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}>
          <CircularProgress />
        </div>)
      }
    </>
  );
};

export default Activities;
