import { FormControl, FormHelperText, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from '../../../../controls/DatePicker/CustomizedDatePicker';
import Input from '../../../../controls/InputCustom/CustomizedInputs';
import { useResizeScreen } from '../../../../hooks/useResizeScreen';
import TopButtons from '../../../AskTurn/Web/Buttons/TopButtons';
import useStyles from "../../../../assets/styles/styles";
import Select from '../../../../controls/Select/CustomSelect';
import { getParametersRefunds, setModel } from '../../../../redux/actions/refundsActions';
import { validCuitCuil, validatorForms } from '../../../../utils/utilsFunctions';
import Loading from '../../../LoadingPage/Loading';
import FooterDrawer from '../../../AskTurn/FooterDrawer';
import InputNumber from '../../../../controls/InputCustom/InputNumber';

const modelErrors = {
    comprobanteCuit: '',
    comprobanteRazonSocial: '',
    comprobanteTipoCodigo: '',
    comprobanteFecha: '',
    comprobantePuntoVenta: '',
    comprobanteNumero: '',
    comprobanteCantidadSesiones: '',
    comprobanteImporte: ''
};

const AddRefundStep2 = ({ activeStep, handleOpenCancelModal, handleBack, setActiveStep }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { screen } = useResizeScreen();
    const { loading, parametersRefunds, modelRequest } = useSelector(state => state?.refundsReducer);
    const [modelToSend, setModelToSend] = useState({});
    const [error, setError] = useState(modelErrors);
    const [listsInputs, setListInputs] = useState({ comprobanteLetra: [{ value: '', label: 'SELECCIONE TIPO' }], comprobanteTipoCodigo: [] });
    const [listInputsForm, setListInputsForm] = useState([]);

    useEffect(() => {
        let propertiesValidations = [
            {
                name: 'comprobanteNroDocumento',
                type: 'number',
                subType: 'cuitcuil',
                exactLength: 11,
                required: null,
                label: 'CUIT',
                campoItem: 27
            },
            {
                name: 'comprobanteRazonSocial',
                type: 'string',
                required: null,
                maxLength: 100,
                label: 'APELLIDO O RAZON SOCIAL',
                campoItem: 14
            },
            {
                name: 'comprobanteTipoCodigo',
                type: 'list',
                required: null,
                label: 'TIPO',
                campoItem: 15
            },
            {
                name: 'comprobanteLetra',
                type: 'list',
                required: null,
                label: 'LETRA',
                campoItem: 16
            },
            {
                name: 'comprobanteFecha',
                type: 'date',
                required: null,
                label: 'FECHA',
                campoItem: 19
            },
            {
                name: 'comprobantePuntoVenta',
                type: 'number',
                maxLength: 4,
                required: null,
                label: 'PUNTO DE VENTA',
                campoItem: 17
            },
            {
                name: 'comprobanteNumero',
                type: 'number',
                required: null,
                maxLength: 8,
                label: 'NUMERO',
                campoItem: 18
            },
            {
                name: 'comprobanteCantidadSesiones',
                type: 'number',
                required: null,
                maxLength: 6,
                label: 'CANTIDAD DE SESIONES',
                campoItem: 21
            },
            {
                name: 'comprobanteImporte',
                type: 'decimal',
                maxDecimals : 2,
                maxLength: 9,
                required: null,
                label: 'IMPORTE TOTAL',
                campoItem: 20
            }
        ];

        const listFiltered = parametersRefunds?.tipoReintegros?.find(x => x?.tipoReintegroCodigo === modelRequest?.tipoReintegroCodigo);

        for( let i = 0; i < propertiesValidations?.length; i++) {
            let refundFieldsFiltered = listFiltered?.tipoReintegroCampos?.filter(x => x?.campoItem === propertiesValidations?.[i]?.campoItem);
            if(refundFieldsFiltered?.length > 0) {
                propertiesValidations[i].required = refundFieldsFiltered?.[0]?.campoObligatorio;
                propertiesValidations[i].label = refundFieldsFiltered?.[0]?.campoCaption?.toUpperCase();
            }
        }
        setListInputsForm(propertiesValidations);

        let areSessionsRequired = listFiltered?.tipoReintegroCampos?.some(x => x?.campoItem === 21 && x?.campoObligatorio);

        if (!areSessionsRequired) {
            const listWithoutSessions = propertiesValidations?.filter(x => x?.name !== 'comprobanteCantidadSesiones');
            
            setListInputsForm(listWithoutSessions);
        }
    }, [modelRequest, loading]);


    const handleNext = (e) => {
        e.preventDefault();
        if (validateModelStep2() && validateCuitNumber()) {
            dispatch(setModel({ ...modelRequest, ...modelToSend, comprobanteImporte: modelToSend?.comprobanteImporte?.replace(',','.') }));
            setActiveStep(activeStep + 1);
        }
    };

    const handleChange = (e) => {
        e.preventDefault();

        let aux = { ...modelToSend, [e.target.name]: `${e.target.value}` };

        if (e.target.name === 'comprobanteTipoCodigo') {
            aux = { ...aux, comprobanteLetra: null }
        }

        setModelToSend(aux);

        setError({ ...error, [e.target.name]: "" });
    }

    useEffect(() => {
        validateCuitNumber();
    }, [modelToSend?.comprobanteNroDocumento])

    const validateCuitNumber = () => {
        const cuitNumber = modelToSend?.comprobanteNroDocumento;

        if (cuitNumber?.length > 0) {
            const isValidCuit = validCuitCuil(cuitNumber);
            setError({ ...error, comprobanteNroDocumento: isValidCuit ? '' : '*El Cuit/Cuil ingresado no es válido' });

            return isValidCuit;
        }

        return true;
    }

    useEffect(() => {
        dispatch(getParametersRefunds());
        validateInitialValues();
    }, []);

    useEffect(() => {
        const listReceiptType = parametersRefunds?.tipoReintegroComprobantes?.map((item) => {
            return { value: item?.tipoReintegroComprobanteCodigo, label: item?.tipoReintegroComprobanteDescripcion };
        });

        setListInputs({ ...listsInputs, comprobanteTipoCodigo: listReceiptType })

    }, [parametersRefunds]);

    useEffect(() => {

        if (modelToSend?.comprobanteTipoCodigo != null) {
            const listFiltered = parametersRefunds?.tipoReintegroComprobantes?.find(x => x?.tipoReintegroComprobanteCodigo === modelToSend?.comprobanteTipoCodigo);
            const listLetters = listFiltered?.letrasComprobantes?.map((item) => {
                return { value: item?.letraComprobanteCodigo, label: item?.letraComprobanteCodigo };
            });

            setListInputs({ ...listsInputs, comprobanteLetra: listLetters });
        }

    }, [modelToSend?.comprobanteTipoCodigo])

    const setDate = (value) => {
        setModelToSend({ ...modelToSend, comprobanteFecha: value });
        setError({ ...error, comprobanteFecha: "" });
    }

    const validateInitialValues = () => {
        let aux ={};
        if(modelRequest){
            aux = {...modelRequest};
            
            if(modelRequest?.comprobanteFecha == null){
                aux = {...aux, comprobanteFecha: new Date()};
            }
        }

        setModelToSend(aux);
    }

    const validateModelStep2 = () => {
        const { auxErrors, valid } = validatorForms(listInputsForm, modelToSend);

        setError(auxErrors);

        return valid;
    }

    return (
        <>
            {screen.sWidth > 768 &&
                <TopButtons handleOpenCancelModalTurn={handleOpenCancelModal} handleBackTurn={handleBack} activeStepTurn={activeStep} handleNextTurn={handleNext} titleCancel={"Cancelar"} titleConfirm={"Siguiente"} />
            }
            {
                loading ?
                    (<Loading isLoading={loading} />)
                    :
                    (
                        <div style={{ padding: "20px" }}>
                            <div style={{ marginBottom: "30px" }}>
                                <Typography className={classes.titleTurn}>
                                    DATOS DEL COMPROBANTE
                                </Typography>
                            </div>
                            <div>
                                {
                                    listInputsForm?.map(({ name, label, type }) => {
                                        switch (type) {
                                            case 'number':
                                            case 'decimal':
                                                return <FormControl className={`${classes.formControlStep2Refunds} `} variant="standard">
                                                    <InputNumber
                                                        label={label}
                                                        name={name}
                                                        value={modelToSend[name]}
                                                        type={type}
                                                        onChange={handleChange}
                                                    />
                                                    <FormHelperText className={`${classes.errorText} ${classes.errorTextFormMobile}`}>{error[name]}</FormHelperText>
                                                </FormControl>
                                            case 'date':
                                                return <FormControl className={`${classes.formControlDatePickerRefunds} `} variant="standard">
                                                    <DatePicker
                                                        label={label}
                                                        name={name}
                                                        onChange={setDate}
                                                        value={modelToSend[name]}
                                                    />
                                                    <FormHelperText style={{margin:"10px 0 15px 30px"}} className={`${classes.errorText} ${classes.errorTextFormMobile}`}>{error[name]}</FormHelperText>

                                                </FormControl>
                                            case 'list':
                                                return <FormControl className={`${classes.formControlCustomSelectRefunds} `} variant="standard">
                                                    <Select
                                                        label={label}
                                                        name={name}
                                                        list={listsInputs[name]}
                                                        onChange={handleChange}
                                                        selected={modelToSend[name] ?? ''}
                                                    />
                                                    <FormHelperText style={{marginLeft:"30px"}} className={`${classes.errorText} ${classes.errorTextFormMobile}`}>{error[name]}</FormHelperText>
                                                </FormControl>
                                            default:
                                                return <FormControl className={`${classes.formControlStep2Refunds} `} variant="standard">
                                                    <Input
                                                        label={label}
                                                        name={name}
                                                        value={modelToSend[name]}
                                                        onChange={handleChange}
                                                    />
                                                    <FormHelperText className={`${classes.errorText} ${classes.errorTextFormMobile}`}>{error[name]}</FormHelperText>
                                                </FormControl>
                                        }
                                    }
                                    )
                                }

                            </div>
                        </div>
                    )
            }
            {
                screen.sWidth <= 768 &&
                <FooterDrawer activeStep={activeStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} handleNext={handleNext} customClassDiv={classes.footerAuthRequest} confirm={true} />
            }
        </>
    )
}

export default AddRefundStep2