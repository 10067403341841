import { useEffect, useState, Fragment } from 'react';
import { FormControl, FormHelperText, FormControlLabel, Checkbox, InputLabel, TextField, Typography, InputAdornment, IconButton, CircularProgress } from "@material-ui/core";
import CustomSelect from "../../../controls/Select/CustomSelect";
import CustomButton from "../../../controls/Button/CustomButton";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import useStyles from "../../../assets/styles/styles";
import { useSelector, useDispatch } from 'react-redux';
import { getDataAffiliatesBaja, getMotivosBaja, getSolicitudBaja, getFile, postRequestLow, cleanPostProcedureSelector } from '../../../redux/actions/procedureActions';
import { LocalizationProvider, MobileDatePicker } from '@material-ui/lab';
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import CustomizedInputs from '../../../controls/InputCustom/CustomizedInputs';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import UploadModal from "../../../controls/UploadModal/UploadModal";
import { deleteFile, handleChangeMail, isDisabled, validate, validateFiles, validateData, messageModalFunction, showBtn } from "../../../utils/utilsFunctions";
import ConfirmModal from '../ConfirmModal';
import UnsubscribeMember from './UnsubscribeMember';
import { setearMail, existFile, verifyMail, setAttachment, validationDate } from './functionShared';
import { es } from "date-fns/locale";


const Unsubscribe = (props) => {
    const today = new Date();
    const data = useSelector(state => state.procedureReducer);
    const [ProcessSelected, setProcessSelected] = useState('');
    const [listSubTramite, setListSubTramite] = useState([]);
    const [lowMotives, setLowMotives] = useState([]);
    const [motiveSelected, setMotiveSelected] = useState('');
    const [dateLow, setDateLow] = useState(new Date(today.getFullYear(), today.getMonth() + 1, 0));
    const fullPersonalData = useSelector(state => state.personalDataReducer);
    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");
    const [mailError, setMailError] = useState('');
    const dispatch = useDispatch();
    const [disabledPhone, setDisabledPhone] = useState(true);
    const [disabledEmail, setDisabledEmail] = useState(true);
    const [emailChecked, selEmailChecked] = useState(false);
    const [showPermanente, setShowPermanente] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalDescription, setModalDescription] = useState("");
    const [modalIcon, setModalIcon] = useState('');
    const [errorClass, setErrorClass] = useState('');
    const [openSendSuccess, setOpenSendSuccess] = useState(false);
    const [query, setQuery] = useState("");
    const [listOfAttachmentButtons, setistOfAttachmentButtons] = useState([]);
    const [files, setFiles] = useState([]);
    const [fileButtons, setFileButtons] = useState([]);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [nameOfItem, setItemName] = useState('');
    const [itemRequired, setItemRequired] = useState('');
    const postProcedureSelector = useSelector(state => state.procedureReducer.postRequest);
    const [itemClasificacion, setItemClasificacion] = useState(0);
    const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);

    const CODIGO_BAJA_FAMILIAR = 2;
    const classes = useStyles();

    const parameter = { numeroPersona: window.localStorage.getItem("numeroPersona") };
    const Tramites = [
        {
            solicitudBajaCodigo: 1,
            solicitudBajaDescripcion: "Baja del titular y su grupo familiar"
        }, {
            solicitudBajaCodigo: 2,
            solicitudBajaDescripcion: "Baja de familiar"
        }
    ];

    const MotivosBaja = [
        {
            MotivoBajaCodigo: "baj",
            MotivoBajaDescripcion: "Motivo 1",
            MotivoBajaObservacion: "Observacion",
        }, {
            MotivoBajaCodigo: "baj",
            MotivoBajaDescripcion: "Motivo 2",
            MotivoBajaObservacion: "Observacion",
        }, {
            MotivoBajaCodigo: "baj",
            MotivoBajaDescripcion: "Motivo 3",
            MotivoBajaObservacion: "Observacion",
        }, {
            MotivoBajaCodigo: "baj",
            MotivoBajaDescripcion: "Motivo 4",
            MotivoBajaObservacion: "Observacion",
        }
    ];

    const Adjuntos = [
        {
            adjuntoDescripcion: 'DNI FRENTE',
            adjuntoClasificacion: '',
            adjuntoModelo: 'ModeloCodigo',
            adjuntoInstructivo: 'InstructivoCodigo',
            adjuntoObligatorio: 'N'
        }
    ];

    const handleChangeSelectProcedure = () => e => {
        const { name, value } = e.target;
        setProcessSelected(value);
        validate(name, '*Campo obligatorio', value, setError, error);
        getListOfFiles(value);
        setFiles([]);
        setFileButtons([]);
        dispatch(cleanPostProcedureSelector());
    };

    const getListOfFiles = (value) => {
        let newFiles = data?.procedures?.tramites?.filter((x) => x.tramiteCodigo == value)[0];
        if (newFiles !== undefined) {
            setAttachment(newFiles?.subTramites?.filter((x) => x.subTramiteCodigo === value)[0], setistOfAttachmentButtons, Adjuntos);
        }
    };

    const handleChangeSelectMotive = () => e => {
        const { name, value } = e.target;
        setMotiveSelected(value);
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    useEffect(() => {
        dispatch(getDataAffiliatesBaja(parameter));
        dispatch(getMotivosBaja());
        dispatch(getSolicitudBaja());
        let tel = fullPersonalData?.fullData?.afiliado?.telefonos[0]?.telefono;
        setPhone(tel);
        fullPersonalData?.fullData?.afiliado?.mails?.forEach((item) => {
            setearMail(item, setMail);
        });
        setProcessSelected(1);
        return (() => {
            dispatch(cleanPostProcedureSelector());
        });
    }, []);

    useEffect(() => {
        getListOfFiles(1);
    }, [data?.requestLow]);

    useEffect(() => {
        setearLowMotives(data, setLowMotives, MotivosBaja);
    }, [data.lowMotives]);

    useEffect(() => {
        setearRequestLow(data, setListSubTramite, Tramites);
    }, [data?.requestLow]);

    const handleChangePhone = () => e => {
        const { name, value } = e.target;
        setPhone(value);
        validate(name, '*Campo obligatorio', value, setError, error);

    };

    useEffect(() => {
        if (existFile(data)) {
            var a = document.createElement("a"); //Create <a>
            a.href = "data:image/png;base64," + data.file.base64; //Image Base64 Goes here
            a.download = "Image.png"; //File name Here
            a.click(); //Downloaded file
        }
    }, [data.file]);

    const handleCheck = (e) => {
        e.preventDefault();
        if (!emailChecked) {
            setModalTitle('SE HA MODIFICADO EL MAIL CORRECTAMENTE');
            setModalDescription(`Este cambio se realizará en el e-mail particular.`);
            setModalIcon(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
            setErrorClass('');
            setOpenSendSuccess(prevState => !prevState);
        }
        selEmailChecked(prevState => !prevState);
    };

    const handleEditEmail = () => {
        setDisabledEmail(prevState => !prevState);
    };

    const handleEditPhone = () => {
        setDisabledPhone(prevState => !prevState);
    };

    const [error, setError] = useState({
        lowMotive: '',
        subProcedure: '',
        date: '',
        mail: '',
        phone: ''
    });

    const handleOpenModal = (itemName, fileRequired, clasificacion) => (e) => {
        e.preventDefault();
        setOpenUploadModal(prevState => !prevState);
        setItemName(itemName);
        setItemRequired(fileRequired);
        setItemClasificacion(clasificacion);
    };

    const dowloadFile = (codigo) => {
        dispatch(getFile(codigo));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateFiles(listOfAttachmentButtons, files)) {
            let filesToSend = files.map((item) => {
                delete (item['btnNombre']);
                delete (item["adjuntoObligatorio"]);
                return item;
            });
            let parametro = {
                NumeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                SolicitudBajaCodigo: ProcessSelected,
                NumeroPersonaBaja: "",
                SolicitudBajaFecha: dateLow.toISOString(),
                MotivoBajaCodigo: motiveSelected,
                Mail: mail,
                MailModificar: verifyMail(showPermanente),
                Telefono: phone,
                Consulta: query,
                Adjuntos: JSON.stringify(filesToSend)
            };
            let newError = validationSelect(parametro, dateLow, today);
            setError(newError);
            if (validateData(newError)) {
                let circularProgress = (<div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress color="inherit" /></div>);
                setModalTitle('INICIANDO GESTION');
                setModalDescription(circularProgress);
                setModalIcon('');
                setErrorClass('');
                setOpenSendSuccess(prevState => !prevState);
                dispatch(postRequestLow(parametro));
            }
        }
    };

    useEffect(() => {
        messageModalFunction(
            postProcedureSelector, setModalTitle,
            setModalDescription,
            setModalIcon,
            setErrorClass,
            classes,
            setOpenSendSuccess,
            mail
        );

        return (() => {
            if (postProcedureSelector?.confirmacion) {
                setModalTitle('');
                setModalDescription('');
                setModalIcon('');
                setErrorClass('');
                setTimeout(() => {
                    window.location.replace('/PersonalData');
                }, 4000);
            }
        });
    }, [postProcedureSelector]);

    function UnsubscribeForm() {
        return <>
            {ProcessSelected == CODIGO_BAJA_FAMILIAR ?
                <UnsubscribeMember listOfAttachmentButtons={listOfAttachmentButtons} processSelected={ProcessSelected} permission={props.permission} />
                :
                unsubscribeBajaTitular()} </>;

    }

    const unsubscribeBajaTitular = () => {
        return <>
            <FormControl style={{ marginTop: "20px", marginBottom: "20px" }} className={`${classes.formControlsAffiliations} ${classes.divFamily} ${classes.itemGridSpan2}`} variant="standard">
                {data.dataAffiliates?.items?.map((item) => (
                    item.itemCodigo == data.dataAffiliates?.afiliados[0]?.numeroTitular &&
                    <div classsName={`${classes.iconsCards} `}>
                        <Typography className={`${classes.txtLabelTramites} ${classes.txtEllipsis}`}>TITULAR</Typography>
                        <Typography className={`${classes.txtSubtitleCalendar} ${classes.txtEllipsis} ${classes.left10}`}>{item.itemDescripcion}</Typography>
                    </div>
                ))}
            </FormControl>
            <FormControl style={{ marginTop: "20px", marginBottom: "20px" }} className={`${classes.formControlsAffiliations} ${classes.itemGridSpan2} `} variant="standard">
                <div classsName={`${classes.iconsCards} `}>
                    <Typography className={`${classes.txtLabelTramites} ${classes.txtEllipsis}`}>GRUPO FAMILIAR</Typography>

                    {data.dataAffiliates?.items?.map((item) => (
                        item.itemCodigo != data.dataAffiliates?.afiliados[0]?.numeroTitular &&
                        <Typography className={`${classes.txtSubtitleCalendar} ${classes.txtEllipsis} ${classes.left10}`}>{item.itemDescripcion}</Typography>
                    ))
                    }
                </div>
            </FormControl>
            <FormControl className={`${classes.formControlCustomSelect}  ${classes.itemGridSpan1}`} variant="standard">
                <CustomSelect
                    name='lowMotive'
                    label='MOTIVO DE BAJA'
                    list={lowMotives}
                    selected={motiveSelected}
                    onChange={handleChangeSelectMotive()}
                />
                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.lowMotive}</FormHelperText>

            </FormControl>
            <FormControl className={`${classes.formControlDatePicker} ${classes.itemGridSpan1}`} >
                <FormControl className={classes.formControl}>
                    <InputLabel style={{ top: "15px" }} className={`${classes.inputLabelSelectForms} ${props.inputLabelClass}`} shrink htmlFor="bootstrap-input">
                        {"FECHA DE LA BAJA"}
                    </InputLabel>
                    <div style={{ marginTop: "30px" }} className={classes.top22}>
                        <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}  >
                            <MobileDatePicker
                                cancelText="Cancelar"
                                toolbarTitle=""
                                format="dd/MM/YYYY"
                                value={dateLow}
                                shouldDisableDate={(date) => date < new Date(date.getFullYear(), date.getMonth() + 1, 0)}
                                disablePast={true}
                                onChange={(newValue) => { setDateLow(newValue); }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            {<FormHelperText className={classes.errorText}>{"La fecha debe ser cada final de mes"}</FormHelperText>}
                            {<FormHelperText className={classes.errorText}>{error?.date}</FormHelperText>}

                        </LocalizationProvider>

                    </div>
                </FormControl>

            </FormControl>


            <FormControl className={`${classes.formControlAddress} ${classes.itemGridSpan2} `} variant="standard">
                <CustomizedInputs
                    value={mail}
                    name='mail'
                    label='E-MAIL'
                    disabled={disabledEmail}
                    onChange={handleChangeMail(setMail, setMailError, setError, error, setShowPermanente, fullPersonalData?.fullData?.afiliado?.mails?.filter(z => z.mailTipoCodigo == 1)[0]?.mail)}
                    endAdornment={
                        <InputAdornment position="start">
                            <IconButton onClick={handleEditEmail} size='small'>
                                <EditIcon />
                            </IconButton>
                        </InputAdornment>
                    }

                />
                {showPermanente &&
                    <FormControlLabel style={{ paddingLeft: '5%', fontSize: '11px' }} control={<Checkbox checked={emailChecked} onClick={handleCheck} color='primary' />} label="¿E-MAIL PERMANENTE?" />
                }
                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.mail}</FormHelperText>
                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{mailError}</FormHelperText>
            </FormControl>

            <FormControl
                className={`${classes.formControlAddress} ${classes.itemGridSpan2} `}
                variant="standard"
            >
                <CustomizedInputs
                    label='TELEFONO'
                    value={`${phone}`}
                    name='phone'
                    disabled={disabledPhone}
                    onChange={handleChangePhone()}
                    endAdornment={
                        <InputAdornment
                            position="start"
                        >
                            <IconButton
                                onClick={handleEditPhone} size= 'small'
                            >
                                <EditIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                    inputProps={{ maxLength: 20 }}
                />
                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.phone}</FormHelperText>


            </FormControl>
            <FormControl className={classes.commentGrid} variant="standard">
                <CustomizedInputs
                    name="consulta"
                    inputProps={{ maxLength: 2000 }}
                    label='OBSERVACIONES'
                    defaultValue={query}
                    placeholder='Escribe tu texto aqui'
                    multiline
                    rows={5}
                    onChange={(e) => { setQuery(e.target.value); }}
                />
            </FormControl>

            <section className={classes.itemGridAdjuntarArchivos} >
                <div style={{ width: '250px' }}>
                    <Typography style={{ flex: '1 1 100%', justifyContent: 'center' }} className={`${classes.txtObservaciones} ${classes.txtSubtitle}`}>ADJUNTAR ARCHIVOS</Typography>
                    <span style={{ flex: '1 1 100%', fontSize: '10px', display: 'flex' }}>
                        {`(TAMAÑO MAXIMO ${configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor}MB)`}
                    </span>
                    {listOfAttachmentButtons?.map((item, i) => (
                        <Fragment key={i}>
                            <CustomButton
                                name={item.adjuntoDescripcion}
                                style={{ padding: '5px 15px 5px 5px' }}
                                title={`ADJUNTAR ${item.adjuntoDescripcion}`}
                                customClass={`${classes.lightLinearGradientReverse} ${classes.marginLeft}`}
                                icon={<AttachFileIcon />}
                                disabled={isDisabled(fileButtons, item.adjuntoDescripcion)}
                                onClick={handleOpenModal(item.adjuntoDescripcion, item.adjuntoObligatorio, item.adjuntoClasificacion)}
                            />
                            {item.adjuntoModelo != '' &&
                                <IconButton
                                    href={`${configurationReduxState?.filter(x => x?.archivoNombre === item?.adjuntoModelo)?.[0]?.valor}`}
                                    rel={"noopener noreferrer"}
                                    target={"_blank"}>
                                    <Typography className={`${classes.txtSubtitleBlue}`}>
                                        descargar Modelo
                                    </Typography>
                                </IconButton>
                            }
                            {item.adjuntoInstructivo != '' &&
                                <IconButton onClick={() => { dowloadFile(item.adjuntoInstructivo); }}>
                                    <Typography className={`${classes.txtSubtitleBlue}`}>
                                        descargar Instructivo
                                    </Typography>
                                </IconButton>
                            }
                            {item.adjuntoObligatorio === 'S' &&
                                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>*Archivo obligatorio</FormHelperText>
                            }
                        </Fragment>
                    ))}
                    {files?.map((item, i) => (
                        <p
                            key={i}
                            style={{ marginLeft: "5%" }}
                            className={classes.txtSubtitleCalendar}>{item?.archivoNombre} <IconButton onClick={deleteFile(fileButtons, setFileButtons, setFiles, files, item?.btnNombre, item?.archivoNombre)}><HighlightOffIcon /></IconButton>
                        </p>
                    ))}

                    <div className={`${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                        {ProcessSelected != CODIGO_BAJA_FAMILIAR &&
                            <CustomButton
                                customClass={classes.lightLinearGradient}
                                style={{ padding: '5px 20px 5px 20px' }}
                                title='Enviar'
                                type='button'
                                onClick={handleSubmit}
                                disabled={showBtn(listOfAttachmentButtons, files, error, motiveSelected)}
                            />
                        }
                    </div>
                </div>
            </section>

            <ConfirmModal isOpen={openSendSuccess} setOpen={setOpenSendSuccess} errorClass={errorClass}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalIcon}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalTitle}
                        </div>
                    </>
                }
                description={modalDescription} />
            <div className={classes.width100}>
                <UploadModal
                    className={classes.width100}
                    title='Adjuntar archivo'
                    archivoClasificacion={itemClasificacion}
                    description=''
                    observation=''
                    open={openUploadModal}
                    onClose={setOpenUploadModal}
                    setFileButtons={setFileButtons}
                    setModalTitle={setModalTitle}
                    setModalDescription={setModalDescription}
                    setModalIcon={setModalIcon}
                    setFiles={setFiles}
                    setErrorClass={setErrorClass}
                    setOpenSendSuccess={setOpenSendSuccess}
                    files={files}
                    fileButtons={fileButtons}
                    itemName={nameOfItem}
                    isRequired={itemRequired}
                />
            </div>
        </>;
    };


    return (<>
        {props.permission ? <>
            <FormControl className={`${classes.formControlCustomSelect} ${classes.itemGridSpan2} `} variant="standard">
                <CustomSelect
                    name='SubProcedure'
                    label='SUBTRAMITE'
                    list={listSubTramite}
                    selected={ProcessSelected}
                    onChange={handleChangeSelectProcedure()}
                />
                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.subProcedure}</FormHelperText>
            </FormControl>

            {UnsubscribeForm()
            }
        </> : <FormHelperText className={classes.errorText}>{"La facturación del titular es por empresa. por favor consultá con tu empresa"}</FormHelperText>
        } </>);
};

export default Unsubscribe;

function validationSelect(param, dateLow, date) {
    let temp = {};
    temp.lowMotive = param.MotivoBajaCodigo != '' ? '' : '*Campo obligatorio';
    temp.subProcedure = param.SolicitudBajaCodigo != '' ? '' : '*Campo obligatorio';
    temp.date = validationDate(dateLow, date);
    temp.mail = param.Mail != '' ? '' : '*Campo obligatorio';
    temp.phone = param.Telefono != '' ? '' : '*Campo obligatorio';

    return temp;

}


function setearLowMotives(data, setLowMotives, MotivosBaja) {
    if (data.lowMotives != null) {
        let auxReason = [];
        let auxList = [];
        if (data.lowMotives?.motivosBaja.length == 0) {
            auxList = MotivosBaja;
        } else {
            auxList = data?.lowMotives?.motivosBaja;
        }
        auxList?.forEach(item => {
            let auxObj = {
                value: item.motivoBajaCodigo,
                label: item.motivoBajaDescripcion
            };
            auxReason.push(auxObj);
        });
        setLowMotives(auxReason);
    }
}

function setearRequestLow(data, setListSubTramite, Tramites) {
    if (data.requestLow != null) {
        let auxProcedure = [];
        let auxList = [];
        if (data.requestLow?.tramites?.length == 0) {
            auxList = Tramites;
        } else {
            auxList = data?.requestLow?.tramites;
        }
        auxList?.forEach(item => {
            let auxObj = {
                value: item.solicitudBajaCodigo,
                label: item.solicitudBajaDescripcion
            };
            auxProcedure.push(auxObj);
        });
        setListSubTramite(auxProcedure);
    }
}

