

export function setArrayList(allergy, setTypeOfAllergies) {
    let allergiesArray = [];
    if (allergy?.length > 0 || allergy.length > 0) {
        allergy?.forEach(item => {
            let allergyObj = {};
            allergyObj.value = item.allergyCodigo;
            allergyObj.label = item.allergyDescripcion;
            allergiesArray.push(allergyObj);
        });
        setTypeOfAllergies(allergiesArray);
    }
}

export function validate(inputAllergy, selectAllergy, setError, flag) {
    let temp = selectAllergy;
    let aux = [];
    let validation = [];
    Object.values(temp).forEach((item, i) => {
        let allergyObj = {};
        if (flag) {
            allergyObj.alergiaCodigo = item.alergiaCodigo.toString() ? '' : '*Campo obligatorio';
            allergyObj.alergiaDescripcion = item.alergiaDescripcion ? '' : '*Campo obligatorio';
            if (item.alergiaDescripcion === 'OTRA') {
                allergyObj.alergiaDetalle = validateInput(inputAllergy);
            } else {
                allergyObj.alergiaDetalle = '';
            }
            aux.push(allergyObj);
            if (Object.values(aux[i]).every((x) => x === "")) {
                validation.push(true);
            } else {
                validation.push(false);
            }
        } else {
            validation.push(true);
        }
    });
    setError(aux);
    return Object.values(validation).every((valid) => valid === true);
}

export function validateInput(input) {
    let aux = input?.alergiaDetalle?.trimEnd();
    let response = '*Campo obligatorio';
    if (input !== '' && aux !== '')
        response = '';
    return response;
}

export function addAllergy(addSelect, addInput, allergies) {
    let responseAllergy = allergies;
    if (addSelect !== '') {
        responseAllergy = [...allergies, ...addSelect];
        if (addInput !== '' && addInput[0]?.alergiaDetalle !== '') {
            responseAllergy = [...allergies, { ...addInput }];
        }
    }
    return responseAllergy;
}

export function addABMItem(addSelect, addInput) {
    let response = addSelect[0].alergiaDescripcion;
    if (addInput !== '' && addInput[0]?.alergiaDetalle !== '') {
        response = addInput.alergiaDetalle;
    }
    return response;
}

export function removeItem(setAllergy, allergy, allergyItem) {
    let allergyWOItem = allergy;
    if (allergyItem !== '') {
        allergyWOItem = allergy?.filter(x => x !== allergyItem);
    }
    setAllergy(allergyWOItem);
}

export function cleanSelect(setAddSelect, setAddInput) {
    let allergySelected = [{
        alergiaCodigo: 0,
        alergiaDescripcion: '',
        alergiaDetalle: ''
    }];
    setAddSelect(allergySelected);
    setAddInput(allergySelected);
}