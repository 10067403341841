import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "../../assets/styles/styles";
import useTrackPageView from "../../utils/useTrackPageView";
import { getCapAndCopayLinkByPlanCodeAction } from "../../redux/actions/capsAndCopayLinksActions";

const RedirectToCapsAndCopay = () => {
  const classes = useStyles();
  const trackPageView = useTrackPageView("Topes y Copagos");
  const dispatch = useDispatch();

  const fullDataAffiliatedReduxState = useSelector(
    (state) => state.personalDataReducer?.fullData?.afiliado
  );

  const capsAndCopayLinksReduxState = useSelector(
    (state) => state.capsAndCopayLinksReducer
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (fullDataAffiliatedReduxState?.planCodigo > 0) {
      dispatch(
        getCapAndCopayLinkByPlanCodeAction(
          fullDataAffiliatedReduxState?.planCodigo
        )
      );
    }
  }, [fullDataAffiliatedReduxState]);

  useEffect(() => {
    if (capsAndCopayLinksReduxState?.capsAndCopayLink?.link?.length > 0) {
      setLoading(false)
    }
  }, [capsAndCopayLinksReduxState]);

  useEffect(() => {
    trackPageView();
  }, [])

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flex: "0 0 100%",
            paddingTop: "30px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
          <Iframe
            sandbox=""
            scrolling="yes"
            src={capsAndCopayLinksReduxState?.capsAndCopayLink?.link}
            className={classes.iframe}
          />
      )}
    </>
  );
};

export default RedirectToCapsAndCopay;
