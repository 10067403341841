import * as actionTypes from "../consts/actionTypes";

export function getStepCount() {
    return {
        type: actionTypes.GET_STEPS,
        payload: ''
    };
}

export function setActualStep(actualStep) {
    return {
        type: actionTypes.SET_ACTUAL_STEP,
        payload: actualStep
    };
}

export function setNextStep(step) {
    return {
        type: actionTypes.NEXT_STEP,
        payload: step
    };
}

export function setPrevStep(step) {
    return {
        type: actionTypes.PREV_STEP,
        payload: step
    };
}