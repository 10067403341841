import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import Header from "../../components/GenericHeader/Header";
import Menu from "../../components/Menu/Menu";
import useStyles from "../../assets/styles/styles";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import {
    Grid,
    Box,
    Typography,
    ImageList,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CustomButton from "../../controls/Button/CustomButton";
import CustomSelect from '../../controls/Select/CustomSelect';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReplayIcon from "@material-ui/icons/Replay";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AskTurn from "../../components/AskTurn/AskTurn";
import { useDispatch, useSelector } from "react-redux";
import {
    getAppointmentFavorite,
    getDataFilterAppointment,
    getAfilliatedAppointmentsRecord,
    getAfilliatedAppointmentsNext,
    getAnswerQuestion,
    ABAppointmentFavorite,
    AppointmentCanceled,
    getAfilliatedAppointments,
    setModelToSaveAppointment,
    getSpecialties,
    setModifyAppointment,
} from "../../redux/actions/appointmentsActions";
import CustomCalendar from "../../components/Calendar/Calendar";
import ModalActivities from "../../components/DialogActivities/ModalActivities";
import ContainerBox from "../../components/ContainerBox/ContainerBox";
import NotFound from "../../components/HomeLogged/NotFound";
import TodayIcon from '@material-ui/icons/Today';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { useHistory, useLocation } from "react-router";
import { checkNewAppointments, handleExpandAccordion, notAccessToThisModule, verifyPermission } from "../../utils/utilsFunctions";
import FavoriteCard from "../../components/Appointments/Cards/FavoriteCard";
import NextAppointmentCard from "../../components/Appointments/Cards/NextAppointmentCard";
import HistoryAppointmentCard from "../../components/Appointments/Cards/HistoryAppointmentCard";
import { getLimitPlan } from "../../redux/actions/limitPlansAction";
import useTrackPageView from "../../utils/useTrackPageView";
import { format } from "date-fns";

const userCode = parseInt(window.localStorage.getItem("numeroPersona"));

const AppointmentHome = () => {
    const dispatch = useDispatch();
    const [selectedFilter, SetFilter] = useState([]);
    const trackPageView = useTrackPageView("Turnos");
    const [isCalendar, setCalendar] = useState(false);
    const [headerCalendarButton, setTitleButton] = useState("VER CALENDARIO");
    const [switchState, setSwitchState] = useState({});
    const [baja, setFavoriteBaja] = useState({});
    const [canceled, setCanceledAppointment] = useState({});
    const [itemAppointment, setItemAppointment] = useState({});
    const [openAskTurn, setOpenAskTurn] = React.useState(false);
    const [skipRecord, setSkipRecord] = useState(0);
    const [skipNext, setSkipNext] = useState(0);
    const classes = useStyles();
    const screenSize = useResizeScreen();
    const appointments = useSelector((state) => state.appointmentsReducer);
    const [next, setNext] = useState([]);
    const [record, setRecorder] = useState([]);
    const [nextComplet, setNextComplet] = useState([]);
    const [recordComplet, setRecordComplet] = useState([]);
    const [today, setTodayAppointment] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openModalFavorite, setOpenModalFavorite] = useState(false);
    const [modifyStep, setModifyStep] = useState(0);
    const [openModalRepeatAppointment, setOpenModalRepeatAppointment] = useState(false);
    const modelNextAppointment = useSelector(state => state.appointmentsReducer);
    const [modifyRepeatTitle, setModifyRepeatTitle] = useState('');
    const [modifyRepeatDescription, setModifyRepeatDescription] = useState('');
    const [groupFamily, setGroupFamily] = useState([]);
    const [selectAffilliate, setSelectAffilliate] = useState({
        value: 0,
        label: ""
    });
    const affiliatePlan = useSelector((state) => state.personalDataReducer?.fullData?.afiliado?.planDescripcion);
    const history = useHistory();
    const { search } = useLocation();
    const limitPlanReducer = useSelector((state) => state.limitPlanReducer.allLimitPlanData);
    const match = search.match(/type=(.*)/);
    const typeURL = match?.[1];
    const loadingPermission = useSelector((state) => state.limitPlanReducer.requestInProgress);
    const [expanded, setExpanded] = useState(false);
    const [ hasPermission, setHasPermission ] = useState(false);
    const [favorite, setFavorite] = useState([])
    const atThisMoment = new Date();
    const [dateStart, setDateStart] = useState(atThisMoment);
    const dateEnd = new Date();
    const [expandNextShifts, setExpandNextShifts] = useState(true);
    const [expandFavorite, setExpandFavorite] = useState(false);
    const [expandHistory, setExpandHistory] = useState(false);
    const [expandQuestion, setExpandQuestion] = useState(false);

    useLayoutEffect(() => {
        verifyPermission(limitPlanReducer, affiliatePlan, "Turnos", setHasPermission);
    }, [limitPlanReducer, affiliatePlan, hasPermission]);

    /*parameters */

    const generateParameter = (type) => {
        let skip;
        if (type == "HISTORICOS") skip = skipRecord;
        else skip = skipNext;
        return {
            numeroPersona: userCode,
            solicitudTurnos: type,
            count: 999,
            skip: skip,
            fechaDesde: format(dateStart, "yyyy-MM-dd"),
            fechaHasta: format(dateEnd, "yyyy-MM-dd")
        };
    };

    useEffect(() => {
        dispatch(getLimitPlan());
        setDateStart(atThisMoment?.setMonth(atThisMoment?.getMonth() - 6));
    }, []);

    const persona = {
        numeroPersona: userCode,
        parametroLlamado: "FILTRO HISTORICO TURNO"

    };

    const paramFav = {
        numeroPersona: userCode,
        count: 999,
        skip: 0,
        fechaDesde: format(dateStart, "yyyy-MM-dd"),
        fechaHasta: format(dateEnd, "yyyy-MM-dd")
    };

    const paramAll = {
        numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
        solicitudTurnos: "TODOS",
        count: 999,
        skip: 0,
        fechaDesde: format(dateStart, "yyyy-MM-dd"),
        fechaHasta: format(dateEnd, "yyyy-MM-dd")
    };
    /**en parameters */


    useEffect(() => {
        if (Object.values(modelNextAppointment?.modelAppointment).length > 0) {
            setOpenAskTurn(prevState => !prevState);
            history.push('AppointmentHome?type=AskNewAppointment')
        }
        getFullAppointments();
        trackPageView();
    }, []);

    const getFullAppointments = () => {
        dispatch(getDataFilterAppointment(persona));
        dispatch(getAfilliatedAppointmentsRecord({
            numeroPersona: userCode,
            solicitudTurnos: "HISTORICOS",
            count: 9999,
            skip: 0,
            fechaDesde: format(dateStart, "yyyy-MM-dd"),
            fechaHasta: format(dateEnd, "yyyy-MM-dd")
        }));
        dispatch(getAnswerQuestion());
        dispatch(getAppointmentFavorite(paramFav));
        dispatch(getAfilliatedAppointmentsNext({
            numeroPersona: userCode,
            solicitudTurnos: "PROXIMOS",
            count: 9999,
            skip: 0,
            fechaDesde: format(dateStart, "yyyy-MM-dd"),
            fechaHasta: format(dateEnd, "yyyy-MM-dd")
        }));
        dispatch(getAfilliatedAppointments(paramAll));
    }

    useEffect(() => {
        Init(appointments?.filtro);
        let listGroupFamily = appointments?.filtro?.map((val) => {
            return {
                value: parseInt(val.numeroPersona), label: val.apellidoNombre
            };
        });
        if (listGroupFamily?.length > 1) {
            listGroupFamily?.push({
                value: 0, label: "TODOS"
            });
        }
        setGroupFamily(listGroupFamily);
    }, [appointments?.filtro]);

    useEffect(() => {
        /* skip for pagination*/
        setSkipNext(parseInt(skipNext) + 1);

        //*appointment complet for filter */
        appointments?.next?.map((item) => {
            nextComplet?.push(item);
        });

        /**add news appointment from pagination and apply filter  */
        if (next?.length == 0) {
            if (selectedFilter?.length != 0) {
                let listFiltered = appointments?.next?.filter((x) => selectedFilter?.find(y => y.select == x.pacienteNumero) != undefined);
                listFiltered?.forEach(element => {
                    next?.push(element);
                });
            } else {
                appointments?.next?.map((item) => {
                    next?.push(item);
                });
            }

        }
        /**create list of appointment today */
        appointments?.next?.map((item) => {
            var dateItem = new Date(item.turnoFechHora);
            var dateTodate = new Date(Date.now());
            var date = new Date(dateTodate.getFullYear(), dateTodate.getMonth(), dateTodate.getDate());
            dateItem.setHours(0, 0, 0, 0);
            date.setHours(0, 0, 0, 0);
            if (dateItem.getTime() == date.getTime()) {
                today?.push(item);
            }
        });

    }, [appointments?.next]);

    useEffect(() => {
        setSkipRecord(parseInt(skipRecord) + 1);
        setRecordComplet(appointments?.record);

        let listFiltered = appointments?.record?.filter((x) => selectedFilter?.find(y => y.select == x.pacienteNumero) != undefined);
        listFiltered?.forEach(element => {
            record.push(element);
        });
    }, [appointments?.record]);

    useEffect(() => {
        if (baja == undefined || baja == null) {
            let f = appointments?.favorite?.map((item) => {
                return item
            });
            setFavorite(f);
        }
        else {
            let f = appointments.favorite.filter((x) => x.medicoCodigo != baja?.medicoCodigo);
            setFavorite(f);
        }
    }, [appointments?.favorite]);

    useEffect(() => {
        paramFav.numeroPersona = selectAffilliate?.value;
        dispatch(getAppointmentFavorite(paramFav));
    }, [selectAffilliate]);

    useEffect(() => {
        if (isCalendar) setTitleButton("VER LISTADO");
        else setTitleButton("VER CALENDARIO");
    }, [isCalendar]);

    useEffect(() => {
        let record = recordComplet?.filter((x) => selectedFilter?.find(y => y.select == x.pacienteNumero) != undefined);
        let next = nextComplet?.filter((x) => selectedFilter?.find(y => y.select == x.pacienteNumero) != undefined);
        setNext(next);
        setRecorder(record);
    }, [switchState]);

    useEffect(() => {
        Object.entries(baja).length > 0 && dispatch(ABAppointmentFavorite(baja));
        dispatch(getAppointmentFavorite(paramFav));
    }, [baja]);

    useEffect(() => {
        Object.entries(canceled).length > 0 && dispatch(AppointmentCanceled(canceled));
        let newNext = next?.filter((x) => x.turnoNumero != canceled.numeroTurno);
        let newNextComplet = nextComplet?.filter((x) => x.turnoNumero != canceled.numeroTurno);
        setNext(newNext);
        setNextComplet(newNextComplet);
        getFullAppointments();
    }, [canceled]);


    const handleScroll = (e, type) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            if (type == "HISTORICOS") {
                var parameter = generateParameter("HISTORICOS");
                dispatch(getAfilliatedAppointmentsRecord(parameter));
            } else if ("PROXIMOS") {
                parameter = generateParameter("PROXIMOS");
                dispatch(getAfilliatedAppointmentsNext(parameter));
            }
        }
    };

    const AccordionDetailCard = (icon, item, i) => {
        switch (icon) {
            case 1:
                return (<NextAppointmentCard item={item} i={i} handleClickOpenRepeatModal={handleEdit} setCancelItem={setItemAppointment} setOpenModal={setOpenModal} />);
            case 2:
                return (<HistoryAppointmentCard item={item} i={i} handleClickOpenRepeatModal={handleClickOpenRepeatModal} />);
            case 3:
                return <FavoriteCard item={item} i={i} handleClickOpenRepeatModal={handleClickOpenRepeatModal} handleClickOpenDeleteFavModal={handleClickOpenDeleteFavModal} />;
            case 4:
                return (addAccordionQuestion(item, i, classes, expanded, setExpanded));
            default:
                break;
        }
    };


    const AddFilter = (select) => {
        SetFilter((selectedFilter) => [...selectedFilter, { select }]);
    };

    const handleAskTurnClick = () => {
        history.push('/AppointmentHome?type=AskNewAppointment');
        setOpenAskTurn(true);
    };

    const handleEdit = (item) => (e) => {
        e.preventDefault();
        var itemCancel = {
            numeroPersona: userCode,
            numeroTurno: item.turnoNumero,
            enviarMail: "N",
            mail: ""
        };
        setItemAppointment(itemCancel);
        setModelAppointmentToRepeatOrModify(item);
        setModifyRepeatTitle('Modificar Turno');
        setModifyRepeatDescription('¿Estás seguro que deseas modificar el turno seleccionado?');
        setOpenModalRepeatAppointment(true);
    };

    const determineAppointmentModification = (selectedAppointment, dispatch) => {
        if (selectedAppointment && selectedAppointment.turnoNumero) {
            const appointmentDate = new Date(selectedAppointment.turnoFechHora).getTime();
            const actualDate = Date.now();
            const isNextAppointment = appointmentDate > actualDate;
    
            dispatch(setModifyAppointment(isNextAppointment ? "modify" : "repeat"));
        } else {
            dispatch(setModifyAppointment("repeat"));
        }
    }

    const setModelAppointmentToRepeatOrModify = (item) => {
        /* Si se repite un turno de favoritos, el numero de afiliado y el nombre es el del usuario logueado. */
        dispatch(setModelToSaveAppointment({
            ...modelNextAppointment?.modelAppointment,
            especialidadCodigo: item?.especialidadCodigo,
            especialidadDescripcion: item?.especialidadDescripcion,
            medicoCodigo: item?.medicoCodigo,
            medicoDescripcion: item?.medicoDescripcion,
            afiliadoDescripcion: item?.pacienteNombreApellido ?? window.localStorage.getItem("userName")?.replace("_", " "),
            afiliadoCodigo: item?.pacienteNumero ?? userCode,
            centroMedico: item?.tipoAtencion == "R" ? "" : item?.sucursalCodigo,
            nombreCentroMedico: item?.tipoAtencion == "R" ? "" : item?.sucursalDescripcion,
            modalidadTurno: item?.tipoAtencion == "R" ? "Telemedicina" : "Presencial",
            preference: 2,
            turnoAnterior: item?.turnoNumero
        }));
        determineAppointmentModification(item, dispatch);
        const specialtiesList = {
            numeroPersona: item?.pacienteNumero ?? userCode,
            centroMedico: item?.tipoAtencion == "R" ? 0 : item?.sucursalCodigo,
            tipoAtencion: item?.tipoAtencion == "R" ? "TELEMEDICINA" : "WEB",
            parametroLlamado: 0,
        };
        dispatch(getSpecialties(specialtiesList));
    };

    const Init = (list) => {
        list?.map((item) => {
            const cantFilter = selectedFilter?.filter((x) => x?.select == item?.numeroPersona);
            if (cantFilter?.length == 0 && item?.numeroPersona == userCode) {
                AddFilter(item?.numeroPersona);
                setSelectAffilliate({ value: item?.numeroPersona, label: item?.apellidoNombre });
                setSwitchState((switchState) => ({ ...switchState, [item?.numeroPersona]: true }));
            }
        });
    };

    const handleChangeWEB = () => e => {
        SetFilter([]);
        var nameAffilliated = groupFamily.filter((x) => x.value == e.target.value);
        if (nameAffilliated[0].value === 0) {
            const newFilter = groupFamily.map((x) => {
                if (x.value != nameAffilliated[0].value) {
                    return { select: x.value.toString() };
                }
            });
            SetFilter(newFilter);
        }
        else {
            AddFilter(nameAffilliated[0].value);

        }
        setSelectAffilliate({ value: nameAffilliated[0].value, label: nameAffilliated[0].label });
        Object.keys(switchState).forEach((item) => {
            if (parseInt(item) !== nameAffilliated[0].value) {
                setSwitchState({ ...switchState, [parseInt(item)]: false });

            } else {
                setSwitchState({ ...switchState, [nameAffilliated[0].value]: true });
            }
        });
    };

    const handleCloseDrawer = useCallback(() => {
        setOpenAskTurn(false);
    }, [openAskTurn]);

    const handleClickOpenDeleteFavModal = (item) => (e) => {
        e.preventDefault();
        var del = {
            numeroPersona: selectAffilliate?.value,
            medicoCodigo: item?.medicoCodigo,
            tipo: "BAJA"
        };
        setItemAppointment(del);
        setOpenModalFavorite(true);
    };

    const handleClickOpenRepeatModal = (item) => (e) => {
        e.preventDefault();
        setModelAppointmentToRepeatOrModify(item);
        setModifyRepeatTitle('Repetir Turno');
        setModifyRepeatDescription('¿Estás seguro que deseas repetir el turno seleccionado?');
        setOpenModalRepeatAppointment(prevState => !prevState);
    };

    const handleClickModifyRepeatAppointment = (type) => (e) => {
        e.preventDefault();
        setOpenAskTurn(prevState => !prevState);
        setOpenModalRepeatAppointment(prevState => !prevState);
        history.push('AppointmentHome?type=AskNewAppointment')
    };


    const addAccordion = (list, title, icon, request, expand, setExpand) => {
        const message =
            title === "Próximos turnos" ? "No tenés próximos turnos" :
            title === "Historial" ? "No tenés historial de turnos" :
            title === "Favoritos" ? "No tenés turnos favoritos" :
            "No hay preguntas frecuentes";

        let uniqueList = [];

        if (request === "PROXIMOS") {

            uniqueList = list?.reduce((accumulator, currentItem) => {
                const isDuplicate = accumulator?.some(item => item?.turnoNumero === currentItem?.turnoNumero);
                if (!isDuplicate) {
                    accumulator?.push(currentItem);
                }
                return accumulator;
            }, []);
        }

        return (
            <Accordion 
            expanded={expand}
            onChange={() => { setExpand(!expand); }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Grid item xs={1}>
                        { iconsAccordion(icon, classes) }
                    </Grid>
                    <Typography className={classes.txtTitleQuestion}>
                        { title }
                    </Typography>
                </AccordionSummary>
                <AccordionDetails >
                    {
                        list?.length > 0
                        ?
                            <div
                                style={{ overflowY: "scroll", height: "fit-content", maxHeight: "500px" }}
                                onScroll={(e) => { handleScroll(e, request); }}>
                                <ImageList
                                    className={`${classes.imageListCardTurn} ${classes.addListCardMobile}`}
                                    cols={4}>
                                    {request == "PROXIMOS" 
                                    ?
                                        uniqueList?.map((item, i) => (AccordionDetailCard(icon, item, i)))
                                    :
                                        list?.map((item, i) => (AccordionDetailCard(icon, item, i)))
                                    }
                                </ImageList>
                            </div>
                        :
                            <div style={{ display: 'flex', justifyContent: 'center', flex: '0 0 100%'}}>
                                <NotFound
                                    customMsg={
                                        <div className={classes.contentNotFound}>
                                            <span>
                                                { message }
                                            </span>
                                        </div>
                                    }
                                    wsItem={list}
                                    fullItem={list}
                                />
                            </div>
                    }
                </AccordionDetails>
            </Accordion>);
    };

    const handleCalendarClick = (e) => {
        e.preventDefault();
        setCalendar((prevState) => !prevState);
    };

    const responsiveHomeBox = () => {
        if (Responsive(screenSize) === "web") {
            return (
                boxHomeWeb()
            );
        }
        else {
            return (
                boxHomeMobile()
            );
        }
    };

    const boxHomeWeb = () => {
        return (
            <section style={{ display: 'flex', padding: '0px 30px 30px 30px', flexWrap: 'wrap' }}>
                <ContainerBox title='Mis próximos turnos' link='/NextAppointments'>
                    {next?.length > 0 ?
                        next?.slice(0, 2)?.map((item, key) => (
                            <NextAppointmentCard item={item} i={key} handleClickOpenRepeatModal={handleEdit} setCancelItem={setItemAppointment} setOpenModal={setOpenModal} />
                        ))
                        :
                        <NotFound
                            customMsg={
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: "10%",
                                    }}
                                >
                                    <TodayIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                                    <span>No tenés próximos turnos</span>
                                </div>}
                            wsItem={[]}
                            fullItem={[]} />
                    }
                </ContainerBox>

                <ContainerBox title='Mis favoritos' link='/FavoriteAppointments'>
                    {favorite?.length > 0 ?
                        favorite?.slice(0, 2)?.map((item, i) => (
                            <FavoriteCard item={item} i={i} handleClickOpenRepeatModal={handleClickOpenRepeatModal} handleClickOpenDeleteFavModal={handleClickOpenDeleteFavModal} />
                        ))
                        :
                        <NotFound
                            customMsg={
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: "10%" }}>
                                    <FavoriteBorderIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                                    <span>No tenés turnos favoritos</span>
                                </div>}
                            wsItem={appointments?.appointmentFavoriteFullData?.favoritos?.filter((x) => x.pacienteNumero === userCode)}
                            fullItem={appointments?.appointmentFavoriteFullData} />
                    }
                </ContainerBox>

                <ContainerBox title='Mi historial' link='/AppointmentRecord'>
                    {record?.length > 0 ?
                        record?.slice(0, 2)?.map((item, i) => (
                            <HistoryAppointmentCard item={item} i={i} handleClickOpenRepeatModal={handleClickOpenRepeatModal} />
                        ))
                        :
                        <NotFound
                            customMsg={
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: "10%" }}>
                                    <TodayIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                                    <span>No tenés historial de turnos</span>
                                </div>}
                            wsItem={record}
                            fullItem={record} />
                    }
                </ContainerBox>

                <ContainerBox title='Preguntas frecuentes' link='/FrequentQuestions'>
                    {appointments?.questionF?.length > 0 ?
                        appointments?.questionF?.map((item, i) => (
                            <>
                                {i < 5 &&
                                    <Accordion
                                        key={i}
                                        className={classes.questionQFMargin}
                                        style={{ width: "90%", margin: "0px 5%" }}
                                        expanded={expanded === item.question}
                                        onChange={(e, isExpanded) => { handleExpandAccordion(item.question, e, isExpanded, setExpanded); }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.txtTitleQuestion}>
                                                {item.question}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails key={i}>
                                            <Typography className={classes.txtCardQuestionDesc}>
                                                {item.answer}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>}
                            </>
                        ))
                        :
                        <NotFound
                            customMsg={
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <span>No existen actualmente preguntas frecuentes</span>
                                </div>}
                            wsItem={appointments?.appointmentQuestionFullData?.answerQuestion}
                            fullItem={appointments?.appointmentQuestionFullData?.answerQuestion} />
                    }
                </ContainerBox>
            </section>
        );
    };

    const boxHomeMobile = () => {
        return (
            <Grid style={{ marginTop: "40px" }}>
                {addAccordion(next, "Próximos turnos", 1, 'PROXIMOS' , expandNextShifts, setExpandNextShifts)}
                {addAccordion(record, "Historial", 2, 'HISTORICOS', expandHistory, setExpandHistory)}
                {addAccordion(favorite, "Favoritos", 3, '', expandFavorite, setExpandFavorite)}
                {addAccordion(appointments.questionF, "Preguntas frecuentes", 4, '', expandQuestion, setExpandQuestion)}
            </Grid>
        );
    };

    const responsiveCalendar = () => {
        if (Responsive(screenSize) === "web") {
            return (
                <CustomCalendar familyFilter={selectAffilliate} appointments={appointments?.next} withCard={true} handleEdit={handleEdit} setItemAppointment={setItemAppointment} setOpenModal={setOpenModal} handleClickOpenRepeatModal={handleClickOpenRepeatModal} />
            );
        }
    };

    const isCalendarWeb = () => {
        if (isCalendar)
            return (
                responsiveCalendar()
            );
        else {
            return (
                <Box>
                    {responsiveHomeBox()}
                </Box>
            );
        }
    };

    function toShow(condition) {
        var response;
        if(condition === undefined) {
            response = (
                <>
                    {Responsive(screenSize) === "web" &&
                     groupFamily?.length > 1 && (
                        <div style={{ display: 'flex', color: 'red', fontSize: '14px', marginTop: '20px', marginLeft: '45px' }}>
                            RECUERDE SELECCIONAR EL AFILIADO PARA VER LOS TURNOS.
                        </div>
                     )
                    }
                    {Responsive(screenSize) == "mobile" && (
                        <div className={` ${classes.headerFixTurn}`}>
                            <Header title="Mis Turnos" colorTitle="#0075C9" heightTitle="140px" />
                           { groupFamily?.length > 1 && (
                            <div style={{ color: 'red', fontSize: '12px', whiteSpace: 'nowrap', marginTop: '-15px', marginLeft: '25px', display:'flex' }}>
                                RECUERDE SELECCIONAR EL AFILIADO PARA VER LOS TURNOS.
                            </div>
                           )}
                        </div>
                    )}
                <div className={` ${classes.paddingTurnMobile}`}>
                    {
                        groupFamily?.length === 0 ?
                            (
                                <NotFound
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    customMsg={
                                        <div className={classes.contentNotFound}>
                                            <span className={classes.notAppointment}>
                                                No se encontraron datos
                                            </span>
                                        </div>
                                    }
                                    wsItem={groupFamily?.length}
                                    fullItem={groupFamily}
                                />
                            ) :
                            (
                                <div className={`${classes.divAffilliate}`}>
                                    <Typography className={classes.textTitleTurn}>Afiliado: </Typography>
                                    <CustomSelect
                                        className={`${classes.afilliateSelect}`}
                                        customRequired={true}
                                        list={groupFamily}
                                        selected={selectAffilliate?.value}
                                        customClassList={classes.deploymentSelect}
                                        onChange={handleChangeWEB()}
                                    />
                                </div>
                            )
                    }
                    <div className={`${classes.orderBtnNewTurn} ${classes.headerSecundary}`} style={{ marginTop: "0px", paddingLeft: "45px", paddingRight: "45px" }}>
                        {Responsive(screenSize) == "web" &&
                            <>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Typography className={classes.textTitleTurn}>Mis Turnos</Typography>
                                </div>
                                <CustomButton
                                    title={headerCalendarButton}
                                    onClick={handleCalendarClick}
                                    style={{
                                        height: "40px",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                    }}
                                    customClass={`${classes.customHeaderBtn} ${classes.whiteAndBlueBtn}`}
                                />
                            </>
                        }
                        <CustomButton
                            title={"Nuevo Turno"}
                            onClick={handleAskTurnClick}
                            style={{
                                height: "40px",
                                fontSize: "12px",
                                fontWeight: "600",
                            }}
                            icon={<AddCircleIcon style={{ marginRight: "5px" }} />}
                            customClass={` ${classes.lightLinearGradient}`}
                        />
                    </div>
                    {isCalendarWeb()}
                </div>
                {
                    openAskTurn && (
                        <AskTurn open={openAskTurn} setOpen={setOpenAskTurn} handleCloseDrawer={handleCloseDrawer} step={modifyStep} ></AskTurn>
                    )
                }
                {
                    openModal &&
                    <ModalActivities
                        title={"Cancelar turno"}
                        description={"¿Estás seguro que deseas cancelar el turno?"}
                        open={openModal}
                        onClose={setOpenModal}
                        modalconfirm={true}
                        functionConfirm={setCanceledAppointment}
                        itemConfirm={itemAppointment} />
                }
                {
                    openModalFavorite &&
                    <ModalActivities
                        title={"Eliminar favorito"}
                        description={"¿Estás seguro que deseas eliminar el turno de tus favoritos?"}
                        open={openModalFavorite}
                        onClose={setOpenModalFavorite}
                        modalconfirm={true}
                        functionConfirm={setFavoriteBaja}
                        itemConfirm={itemAppointment} />
                }
                {
                    openModalRepeatAppointment &&
                    <ModalActivities
                        title={modifyRepeatTitle}
                        description={modifyRepeatDescription}
                        open={openModalRepeatAppointment}
                        onClose={setOpenModalRepeatAppointment}
                        modalconfirm={true}
                        modalLogout={false}
                        buttonType={'submit'}
                        handleClick={handleClickModifyRepeatAppointment(modifyRepeatTitle)}
                    />
                }
            </>
            );
        } else {
            response = (
                <AskTurn open={openAskTurn} setOpen={setOpenAskTurn} handleCloseDrawer={handleCloseDrawer} step={modifyStep} ></AskTurn>
            );
        }
        return response;
    }

    function hasAccess(condition) {
        var resp;
        if(condition) {
            resp = (
                toShow(typeURL)
            );
        } else {
            resp = (notAccessToThisModule());
        }
        return resp;
    }

    return (
        <>
            <Menu />
            {
                loadingPermission ?
                    <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
                :
                (
                    hasAccess(hasPermission)
                )
            }
        </>
    );
};

export default AppointmentHome;

function Responsive(screenSize) {
    let width = screenSize.screen.sWidth;
    if (width <= 768) return "mobile";
    else return "web";
}

function iconsAccordion(icon, classes) {
    switch (icon) {
        case 1:
            return <AssignmentIcon className={classes.NextAppointmentIconRound} />;
        case 2:
            return <ReplayIcon className={classes.RecordIconRound} />;
        case 3:
            return <FavoriteIcon className={classes.FavoriteIconRound} />;
        case 4:
            return <LiveHelpIcon className={classes.QuestionIconRound} />;
        default:
            break;
    }
}

function HeaderIcon(item, classes) {
    if (item.fechaFormateada != undefined) {
        return (
            <Grid style={{ width: "100%" }}>
                <Typography className={`${classes.txtCalendar} ${classes.txtEllipsis}`}>
                    {item.fechaFormateada.nombreDia}
                </Typography>
                <Typography
                    className={`${classes.txtNumberCalendar} ${classes.txtEllipsis}`}
                >
                    {item.fechaFormateada.dia}
                </Typography>
                <Typography className={`${classes.txtCalendar} ${classes.txtEllipsis}`}>
                    {item.fechaFormateada.mes}
                </Typography>
            </Grid>
        );
    }
}


const addAccordionQuestion = (item, i, classes, expanded, setExpanded) => {
    return (
        <Accordion
            expanded={expanded === item.question}
            onChange={(e, isExpanded) => { handleExpandAccordion(item.question, e, isExpanded, setExpanded); }}
            key={i} className={classes.questionQFMargin}
            style={{ width: '100%' }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.txtTitleQuestion}>
                    {item.question}
                </Typography>
            </AccordionSummary>
            <AccordionDetails key={i}>
                <Typography className={classes.txtCardQuestionDesc}>
                    {item.answer}
                </Typography>
            </AccordionDetails>

        </Accordion>);
};