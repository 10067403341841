
import useStyles from "../../assets/styles/styles";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import ModalActivities from "../DialogActivities/ModalActivities";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
    Typography,
} from "@material-ui/core";
import { useEffect, useRef } from "react";


const OpenModal = (props) => {
    const classes = useStyles();
    const screenSize = useResizeScreen();
    const { modal } = props;
    const descriptionRef  = useRef(null)

    useEffect (() =>{
        if(descriptionRef.current) {
            descriptionRef.current.scrollIntoView({behavior: 'smooth'})
        }

    },[props.open])
 
    const showNewsAndActivitiesCards = () => {
        if (screenSize.screen.sWidth <= "600") {
            return (
                <Card className={classes.cardMobile}>
                    <CardContent>
                        <Typography ref={descriptionRef} style={{paddingTop: '10px' }}>
                            {modal.description}
                        </Typography>
                        <Typography style={{ paddingTop: "10px" }}>
                            {modal.observation}
                        </Typography>
                        <Typography style={{ paddingTop: "10px" }}>
                            {modal.info}
                        </Typography>
                    </CardContent>
                </Card>
            );
        } else {
            return (
                <ModalActivities
                    customBackgroundDialogContent={classes.homeNewsAndActivitiesCard}
                    customBackgroundDialog={classes.homeNewsAndActivitiesCard}
                    title={modal.title}
                    description={modal.description}
                    observation={modal.observation}
                    info={modal.info}
                    open={props.open}
                    onClose={props.setOpen}
                    crossDarkColor={true}
                />
            );
        }
    }

    return (
        <>
            { props?.open ? showNewsAndActivitiesCards() : "" }
        </>
    )
}
export default OpenModal;