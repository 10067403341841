import { CircularProgress, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../../assets/styles/styles";
import { useEffect, useState } from "react";
import { setModelToSaveAppointment, getSpecialtiesList, getDoctorsList } from "../../redux/actions/appointmentsActions";
import { Autocomplete, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../controls/Button/CustomButton";
import TextField from '@mui/material/TextField';
import { useResizeScreen } from "../../hooks/useResizeScreen";

const specialtiesListParameters = {
    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
    especialidadDescripcion: ''
};

const doctorsListParameters = {
    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
    medicoNombre: ''
};

const FlowSpecialtyListDrawer = (props) => {
    const dispatch = useDispatch();
    const { handleBack } = props;
    const classes = useStyles();
    const modelNextAppointment = useSelector(state => state.appointmentsReducer);
    const [modelAppointment, setModelAppointment] = useState(modelNextAppointment?.modelAppointment);
    const [groupSpecialties, setGroupSpecialties] = useState([]);
    const [groupDoctors, setGroupDoctors] = useState([]);
    const [specialtiesByDoctor, setSpecialtiesByDoctor] = useState([]);
    const specialtiesList = useSelector(state => state.appointmentsReducer?.specialtiesList?.especialidades);
    const doctorsList = useSelector(state => state.appointmentsReducer?.doctorsList?.medicos);
    const [hasFocus, setHasFocus] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [shouldSearch, setShouldSearch] = useState(false);
    const error = useSelector(state => state.appointmentsReducer?.msgError);
    const [hasDoctorFocus, setHasDoctorFocus] = useState(false);
    const [searchDoctorValue, setSearchDoctorValue] = useState('');
    const [shouldSearchDoctor, setShouldSearchDoctor] = useState(false);
    const flow = modelNextAppointment?.modelAppointment?.appointmentFlow;
    const isDoctorFlow = flow === 'medico';
    const [isLoading, setIsLoading] = useState(false);
    const { screen } = useResizeScreen();
    const [specialtiesOptions, setSpecialtiesOptions] = useState([]);


    const setPopUp = () => {
        let flag = false;
        if(modelAppointment?.appointmentFlow === 'especialidad') {
            if(modelAppointment?.especialidadCodigo == undefined || modelAppointment?.especialidadCodigo == '') {
                flag = true;
            }
        } else if(modelAppointment?.appointmentFlow === 'medico') {
            if(modelAppointment?.medicoCodigo == undefined || modelAppointment?.medicoCodigo == '') {
                flag = true;
            }
        }
        return flag;
    }

    const [openModal, setOpenModal] = useState(setPopUp());

    const handleFocusChange = (e) => {
        setHasFocus(e.type === 'focus');
    };

    const handleFocusDoctorChange = (e) => {
        setHasDoctorFocus(e.type === 'focus');
    };

    useEffect(() => {
        if (!openModal) {
            if (modelAppointment?.especialidadCodigo == undefined || modelAppointment?.medicoCodigo == undefined) {
                setModelAppointment({
                    ...modelAppointment,
                    especialidadCodigo: '',
                    especialidadDescripcion: '',
                    medicoCodigo: '',
                    medicoDescripcion: ''
                });
            }
        }
    }, [openModal])

    useEffect(() => {
        if (modelAppointment?.medicoDescripcion?.length > 3) {
            setHasDoctorFocus(false);
        }

        if (modelAppointment?.especialidadDescripcion?.length > 3) {
            setHasFocus(false);
        }
    }, [modelAppointment?.medicoDescripcion, modelAppointment?.especialidadDescripcion])

    useEffect(() => {
        let listSpecialties = [];

        listSpecialties = specialtiesList?.map(item => {
            return {
                value: item?.especialidadCodigo?.trim(),
                label: item?.especialidadDescripcion?.trim()
            };
        });

        if (listSpecialties != undefined) {
            listSpecialties?.unshift({ value: "", label: "NOMBRE DE LA ESPECIALIDAD" });
        }

        setGroupSpecialties(listSpecialties);
    }, [specialtiesList]);

    useEffect(() => {
        let listDoctors = [];

        listDoctors = doctorsList?.map(item => {
            return {
                value: item?.centrosMedicos?.[0]?.medicoCodigo,
                label: item?.medicoNombre
            };
        });

        if (doctorsList != undefined) {
            listDoctors?.unshift({ value: "", label: "NOMBRE DEL PROFESIONAL" });
        }

        setGroupDoctors(listDoctors);

    }, [doctorsList])

    useEffect(() => {
        if (modelAppointment?.especialidadCodigo == undefined || modelAppointment?.especialidadCodigo == '') {
            setDefaultSpecialtyValue();
        }

        if (modelAppointment?.medicoCodigo == undefined || modelAppointment?.medicoCodigo == '') {
            setDefaultDoctorValue();
        }

        setModelAppointment({
            ...modelAppointment,
            turnoNumero: undefined
        });
    }, [])

    useEffect(() => {
        if (error == 'No hay especialidades para el parámetro de busqueda seleccionado' ||
            error == 'No hay médicos para el parámetro de busqueda seleccionado') {
            setIsLoading(false);
        }
    }, [error])

    const setDefaultSpecialtyValue = () => {
        setGroupSpecialties([{ value: "", label: "NOMBRE DE LA ESPECIALIDAD" }]);
    }

    const setDefaultDoctorValue = () => {
        setGroupDoctors([{ value: "", label: "NOMBRE DEL PROFESIONAL" }]);
    }

    useEffect(() => {
        if ((searchValue?.length > 2 && searchValue != "NOMBRE DE LA ESPECIALIDAD") && shouldSearch) {
            setIsLoading(true);
            dispatch(getSpecialtiesList({ ...specialtiesListParameters, especialidadDescripcion: searchValue }));
            setShouldSearch(false);
        }
    }, [searchValue, shouldSearch]);

    useEffect(() => {
        if ((searchDoctorValue?.length > 2 && searchDoctorValue!="NOMBRE DEL PROFESIONAL" ) && shouldSearchDoctor) {
            setIsLoading(true);
            dispatch(getDoctorsList({ ...doctorsListParameters, medicoNombre: searchDoctorValue }));
            setShouldSearchDoctor(false);
        }
    }, [searchDoctorValue, shouldSearchDoctor]);

    const handleChangeSpecialties = (e, newValue) => {
        let specialties = groupSpecialties;
        let doctorSpecialties = specialtiesByDoctor;
        

        setSearchValue(newValue);

        setShouldSearch(true);

        if (newValue == null || newValue == "NOMBRE DE LA ESPECIALIDAD") {
            setModelAppointment({
                ...modelAppointment,
                especialidadCodigo: specialties?.[0]?.value?.trim(),
                especialidadDescripcion: specialties?.[0]?.label?.trim()
            });
            return;
        }

        let nameSpecialties = (isDoctorFlow ? doctorSpecialties : specialties)?.filter(x => x?.label?.trim() == newValue?.trim());

        if (parseInt(nameSpecialties?.[0]?.value) !== 0 && nameSpecialties !== null) {
            if (!isDoctorFlow) {
                setModelAppointment({
                    ...modelAppointment,
                    especialidadCodigo: nameSpecialties?.[0]?.value?.trim(),
                    especialidadDescripcion: nameSpecialties?.[0]?.label?.trim(),
                    medicoCodigo: '',
                    medicoDescripcion: ''
                });
            } else {
                setModelAppointment({
                    ...modelAppointment,
                    especialidadCodigo: nameSpecialties?.[0]?.value?.trim(),
                    especialidadDescripcion: nameSpecialties?.[0]?.label?.trim(),
                });
            }
        } else {
            setModelAppointment({
                ...modelAppointment,
                especialidadCodigo: '',
                especialidadDescripcion: ''
            });
        }
        setDefaultSpecialtyValue();
        setIsLoading(false);
    };

    const handleChangeDoctors = (e, newValue) => {
        let listSpecialties = [];

        setSearchDoctorValue(newValue?.trim());

        setShouldSearchDoctor(true);

        let nameDoctor = groupDoctors?.filter(doctorName => doctorName?.label?.trim() == newValue?.trim());
        let selectedProvider = doctorsList?.filter(selectedDoctor => selectedDoctor?.medicoNombre == nameDoctor?.[0]?.label);

        if (nameDoctor?.[0]?.value !== 0 && nameDoctor !== null) {
            setModelAppointment({
                ...modelAppointment,
                medicoCodigo: nameDoctor?.[0]?.value,
                medicoDescripcion: nameDoctor?.[0]?.label,
                proveedorNumero: selectedProvider?.[0]?.proveedorNumero,
                especialidadCodigo: '',
                especialidadDescripcion: '',
                observations: selectedProvider
            });
        } else {
            setModelAppointment({
                ...modelAppointment,
                medicoCodigo: groupDoctors?.[0]?.value,
                medicoDescripcion: groupDoctors?.[0]?.label,
                especialidadCodigo: '',
                especialidadDescripcion: ''
            });
        }

        listSpecialties = selectedProvider?.[0]?.centrosMedicos?.map(item => {
            return {
                value: item?.especialidadCodigo?.trim(),
                label: item?.especialidadDescripcion?.trim()
            };
        });

        const uniqueSpecialties = listSpecialties?.reduce((accumulator, item) => {
            const existingIndex = accumulator?.findIndex(
                specialty => specialty?.value === item?.value && specialty?.label === item?.label
            );

            if (existingIndex === -1) {
                accumulator?.push(item);
            }

            return accumulator;
        }, []);

        setSpecialtiesByDoctor(uniqueSpecialties);
        setDefaultDoctorValue();
        setIsLoading(false);
    };

    useEffect(() => {
        dispatch(setModelToSaveAppointment({ ...modelAppointment }));
    }, [modelAppointment?.medicoCodigo, modelAppointment?.especialidadCodigo]);

    useEffect(() => {
        if (specialtiesByDoctor?.length === 1) {
            setModelAppointment({
                ...modelAppointment,
                especialidadCodigo: specialtiesByDoctor?.[0]?.value,
                especialidadDescripcion: specialtiesByDoctor?.[0]?.label
            });
        }
    }, [specialtiesByDoctor]);
    
    useEffect(() => {
        setSpecialtiesOptions(specialtiesList?.map(item => ({
            value: item?.especialidadCodigo?.trim(),
            label: item?.especialidadDescripcion?.trim()
        })) || []);
    }, [specialtiesList]);

    useEffect(() => {
        if (searchValue?.length === 0) {
            setSpecialtiesOptions([]);
        }
    }, [searchValue]);

    const closeModal = () => {
        setModelAppointment({
            ...modelAppointment,
            especialidadCodigo: '',
            especialidadDescripcion: '',
            centroMedico: '',
            centroMedicoCodigo: '',
            direccion: '',
            nombreCentroMedico: '',
            selectedAppointment: '',
            medicoCodigo: '',
            medicoDescripcion: ''
        });
        setOpenModal(false);
    }

    const appointmentFlowPopUp = () => {
        return (
            <>
                <Dialog
                    onClose={closeModal}
                    aria-labelledby="customized-dialog-title"
                    open={openModal}
                    id="backDropActivities">
                    <div id="modalActivities">
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>

                        <DialogTitle
                            id="customized-dialog-title"
                            className={`${classes.contentDialogTitle}`}
                            style={{ textAlign: "center" }}
                            onClose={closeModal}>
                            <Typography className={classes.titleDialogActivities} style={{ fontWeight: "600" }}>
                                <div style={{ marginTop: "30px" }}>Atención</div>
                            </Typography>
                        </DialogTitle>

                        <DialogContent className={`${classes.contentDialogTitle}`} style={{ paddingTop: "10px" }}>
                            <Typography style={{ paddingBottom: "20px" }}>
                                <div style={{ textAlign: "center" }}>
                                    {modelAppointment?.appointmentFlow === "especialidad"
                                        ? <div>Vas a ver los <b>próximos turnos disponibles</b> de la especialidad en nuestros <b>Centros Médicos</b></div>
                                        : <div>Vas a ver los <b>próximos turnos disponibles</b> del profesional en nuestros <b>Centros Médicos</b></div>
                                    }
                                </div>
                            </Typography>
                            <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomButton
                                    title="ACEPTAR"
                                    onClick={closeModal}
                                    customClass={`${classes.lightLinearGradient}`}
                                    style={{
                                        margin: "10px",
                                        height: "34px",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        width: "100px",
                                        border: "1px solid #0075c9"
                                    }}
                                    sx={{'&:hover': {
                                        color: "#0075c9 !important",
                                        background: "#ffffff !important",
                                    }}}
                                />
                                <CustomButton
                                    title="CANCELAR"
                                    onClick={handleBack}
                                    style={{
                                        margin: "10px",
                                        fontSize: "12px",
                                        boxShadow: "0px 2px 8px 0 #585858",
                                        borderRadius: "20px",
                                        fontWeight: "600",
                                        backgroundColor: "#ffffff",
                                        color: "#0075c9",
                                        border: "1px solid #0075c9",
                                        width: "100px"
                                    }}
                                    sx={{'&:hover': {
                                        color: "#ffffff !important",
                                        background: "linear-gradient(90deg, #54c0e8, #0075c9 100%)",
                                    }}}
                                />
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
            </>
        );
    }

    const specialtiesAutocomplete = (list) => {
        return (
            <Autocomplete
                freeSolo
                clearText={'Borrar'}
                onBlur={handleFocusChange}
                clearOnEscape={false}
                loading={isLoading}
                loadingText={error == '' ? 'Buscando especialidades...' : error}
                noOptionsText={'No hay opciones'}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className={`${classes.afilliateSelectTurn}`}
                        variant="outlined"
                        style={{ textTransform: 'uppercase' }} />
                )}
                options={list}  
                onInputChange={(e, newValue) => { handleChangeSpecialties(e, newValue) }}
                className={`${classes.afilliateSelectTurn}`}
                value={modelAppointment?.especialidadCodigo == "" ? "NOMBRE DE LA ESPECIALIDAD" : modelAppointment?.especialidadDescripcion}
            />
        );
    }

    return (
        <>
            <div className={`${classes.drawerContainerTurn}`} style={{ marginLeft: screen.sWidth > 768 && '20px' }}>
                <Typography className={classes.titleTurn} style={{ marginTop: "20px", marginLeft: 0 }}>
                    {modelAppointment?.appointmentFlow === "especialidad"
                        ? "Turno por Especialidad / Presencial"
                        : "Turno por Médico / Presencial"
                    }
                </Typography>
                {modelAppointment?.appointmentFlow === "especialidad" ?
                    <div className={classes.appointmentsAutocompleteContainer}>
                        {(groupSpecialties?.length > 0 && !openModal) ?
                            <>
                                <Typography className={classes.appointmentsAutocompleteLabels}>
                                    Escribí el nombre de la especialidad
                                </Typography>
                                {specialtiesAutocomplete(groupSpecialties)}
                                {modelAppointment?.especialidadCodigo == "" && <span className={classes.spanError} style={{ width: "auto" }} >*Complete el campo</span>}
                            </>
                            :
                            <div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}>
                                <CircularProgress />
                            </div>
                        }
                    </div>
                    :
                    <div className={classes.appointmentsAutocompleteContainer}>
                        {(groupDoctors?.length > 0 && !openModal) ?
                            <>
                                <Typography className={classes.appointmentsAutocompleteLabels}>
                                    Escribí el nombre del profesional
                                </Typography>
                                <Autocomplete
                                    freeSolo
                                    clearText={'Borrar'}
                                    loading={isLoading}
                                    loadingText={error == '' ? 'Buscando profesionales...' : error}
                                    noOptionsText={'No hay opciones'}
                                    open={hasDoctorFocus}
                                    onFocus={handleFocusDoctorChange}
                                    onBlur={handleFocusDoctorChange}
                                    clearOnEscape={false}
                                    renderInput={(params) => (
                                        <TextField {...params} className={`${classes.afilliateSelectTurn}`} variant="outlined" />
                                    )}
                                    options={groupDoctors}
                                    onInputChange={(e, newValue) => { handleChangeDoctors(e, newValue) }}
                                    className={`${classes.afilliateSelectTurn}`}
                                    value={modelAppointment?.medicoCodigo == "" ? "NOMBRE DEL PROFESIONAL" : modelAppointment?.medicoDescripcion}
                                />
                                {modelAppointment?.medicoCodigo == "" && <span className={classes.spanError} style={{ width: "auto" }}>*Complete el campo</span>}

                                {(modelAppointment?.medicoCodigo !== "" && modelAppointment?.medicoCodigo !== undefined && specialtiesByDoctor?.length > 1) &&
                                    <>
                                        <Typography className={classes.appointmentsAutocompleteLabels}>
                                            Escribí el nombre de la especialidad
                                        </Typography>
                                        {specialtiesAutocomplete(specialtiesByDoctor)}
                                        {modelAppointment?.especialidadCodigo == "" && <span className={classes.spanError} style={{ width: "auto" }} >*Complete el campo</span>}
                                    </>
                                }
                            </>
                            :
                            <div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}>
                                <CircularProgress />
                            </div>
                        }
                    </div>
                }

            </div>

            { openModal && appointmentFlowPopUp() }
        </>
    );
};

export default FlowSpecialtyListDrawer;
