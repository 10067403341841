import { useEffect, useState } from "react";

const useTodayAppointment = ({ appointments }) => {
    const [today, setToday] = useState([]);

    useEffect(() => {
        let todayAppointment = [];
        /**create list of appointment today */
        appointments?.next?.map((item) => {
            var dateItem = new Date(item?.turnoFechHora);
            var dateTodate = new Date(Date.now());
            var date = new Date(dateTodate.getFullYear(), dateTodate.getMonth(), dateTodate.getDate());
            dateItem.setHours(0, 0, 0, 0);
            date.setHours(0, 0, 0, 0);
            if (dateItem.getTime() == date.getTime()) {
                todayAppointment.push(item);
            }
        });
        setToday(todayAppointment);
    }, [appointments?.next]);

    const setTodayAppointment = value => {
        setToday(value);
    };

    return { today, setTodayAppointment };
};

export default useTodayAppointment;