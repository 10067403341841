import { CircularProgress, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../../../assets/styles/styles";
import {
  getNextAvailableAppointmentsDoctor,
  setModelToSaveAppointment,
} from "../../../redux/actions/appointmentsActions";
import CustomCalendar from "../../Calendar/Calendar";
import FooterDrawer from "../FooterDrawer";
import { ValidateSteps } from "../Utils/ValidateSteps";
import "./doctorCalendar.css";
import DoesntFreeAppointmentModal from "./DoesntFreeAppointmentModal";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import { getFullData } from '../../../redux/actions/personalDataActions';


const SelectDateDrawer = (props) => {
  const {
    activeStep,
    setActiveStep,
    handleOpenCancelModal,
    handleBack,
    handleCloseDrawer,
  } = props;
  const modelNextAppointment = useSelector(
    (state) => state.appointmentsReducer
  );
  const [modelAppointment, setModelAppointment] = useState(
    modelNextAppointment?.modelAppointment
  );
  const specialties = useSelector(
    (state) => state.medicalCenterReducer?.specialtyList
  );
  const doctorSchedule = useSelector(
    (state) => state.appointmentsReducer?.nextAvailableAppointmentsDoctor
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const [schedule, setSchedule] = useState([""]);
  const [description, setDescription] = useState([""]);
  const [doctorCodes, setDoctorCodes] = useState([""]);
  const [openModal, setOpenModal] = useState();
  const { screen } = useResizeScreen();
  const loading = useSelector((state) => state.medicalCenterReducer?.isLoading);

  const paramAll = {
    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
    medicoCodigo: "TODOS",
    count: 0,
    skip: 0,
    tipoAtencion: modelNextAppointment?.modelAppointment?.modalidadTurno == 'Telemedicina' ? 'TELEMEDICINA' : 'PRESENCIAL'
  };

  const inlineCalendarStyles = {
    appointmentType: { alignSelf: "center", fontSize: "12px", margin: "5px" },

    appointmentStateIndicator: { display: 'flex', flexDirection: screen.sWidth < 321 ? 'column' : 'row' },

    noAvailableTurnIndicator: { alignSelf: "center", fontSize: "12px", margin: "5px", textAlign: "center"}
}

  useEffect(() => {
    dispatch(getFullData({ numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")) }));
  }, [])

  useEffect(() => {
    setModelAppointment(modelNextAppointment?.modelAppointment);
  }, [modelNextAppointment?.modelAppointment]);

  useEffect(() => {
    dispatch(
      getNextAvailableAppointmentsDoctor({
        ...paramAll,
        medicoCodigo: modelAppointment?.medicoCodigo?.toString(),
      })
    );
    setDoctorCodes({ [modelAppointment?.medicoCodigo]: true });
  }, [modelAppointment?.medicoCodigo, modelAppointment.preference]);

  useEffect(() => {
    validateSpecialties(
      specialties,
      setOpenModal,
      setDescription,
      modelAppointment
    );
    let turnosLibres = [];
    if (modelAppointment?.modalidadTurno === 'Telemedicina') {
      turnosLibres = doctorSchedule?.turnosLibres?.filter(element => element?.tipoAtencion === "R");
    } else {
      turnosLibres = doctorSchedule?.turnosLibres?.filter(element => element?.tipoAtencion === "P");

      doctorSchedule?.turnosLibres?.filter( x => x.estadoTurno === 'LICENCIA' )?.map( item => {
        turnosLibres?.push( item );
      });
    }
    setSchedule(turnosLibres);

  }, [doctorSchedule, specialties]);

  useEffect(() => {
    if (description !== undefined) {
      const descriptionsAppointment = description[0]?.observaciones;
      const preparation = description[0]?.preparacion;
      setModelAppointment({
        ...modelAppointment,
        observaciones: descriptionsAppointment,
        preparacion: preparation,
      });
      dispatch(
        setModelToSaveAppointment({
          ...modelAppointment,
          observaciones: descriptionsAppointment,
          preparacion: preparation,
        })
      );
    }
  }, [description]);

  const handleNext = () => {
    if (
      ValidateSteps(activeStep, modelNextAppointment?.modelAppointment, screen)
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 3);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const showObservations = () => {
    let observaciones = "No hay observaciones"

    if(description !== undefined){
      description[0]?.observaciones !== "" && (observaciones = description[0]?.observaciones)
    }

    return observaciones
  }

  const centeredCircularProgress = () => {
    return (
      <div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}>
        <CircularProgress />
      </div>
    );
  }

  return (
    (specialties?.length != 0 && !modelNextAppointment?.isLoading && !loading ? <>
      <div className={classes.drawerContainerTurn}>
        {screen.sWidth < 768 && (
          <>
            <Typography
              className={classes.textTitleTurn}
              style={{ margin: "0px " }}
            >
              {props.titles.map((titles) => titles.title8)}
            </Typography>
            <div style={{ marginTop: "10px" }}>
              <Typography
                className={`${classes.subtitleTurn} ${classes.txtDarkBlue}`}
                style={{ margin: "0px " }}
              >
                {modelAppointment?.medicoDescripcion}
              </Typography>
              <Typography
                className={`${classes.titleTurn} ${classes.txtBold500}`}
                style={{ margin: "0px " }}
              >
                {modelAppointment?.especialidadDescripcion}
              </Typography>
            </div>
            <div style={{ marginTop: "10px" }}>
              {schedule !== undefined && description !== undefined ? (
                <>
                  <Typography
                    className={` ${classes.txtLinksInCard} ${classes.fontBold} ${classes.txtObservaciones}`}
                    style={{ margin: "0px " }}
                  >
                    Próximo turno más cercano:{" "}
                    {schedule[0]?.turnoFechHora?.split("T")[0]} |{" "}
                    {schedule[0]?.turnoFechHora?.split("T")[1]} hs.
                  </Typography>
                  <Typography
                    className={`${classes.subtitleTurn} ${classes.txtDarkBlue} ${classes.txtObservaciones}`}
                    style={{ margin: "0px " }}
                  >
                    Horario de atención:{" "}
                    <span className={classes.txtBold500}>
                      {description[0]?.horarios}
                    </span>
                  </Typography>
                  <Typography
                    className={`${classes.subtitleTurn} ${classes.txtDarkBlue} ${classes.txtObservaciones}`}
                    style={{ margin: "0px " }}
                  >
                    Edades:{" "}
                    <span className={classes.txtBold500}>
                      de {description[0]?.edadDesde} a{" "}
                      {description[0]?.edadHasta} años{" "}
                      {description[0]?.atiendeDiscapacitados === "S" && <>(atiende capacidades especiales)</>}
                    </span>
                  </Typography>
                  <Typography
                    className={`${classes.subtitleTurn} ${classes.txtDarkBlue} ${classes.txtObservaciones}`}
                    style={{ margin: "0px " }}
                  >
                    Observaciones: {showObservations()}
                    {description[0]?.preparacion}
                  </Typography>
                </>
              ) : (
                <div
                  className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}
                >
                  <CircularProgress />
                </div>
              )}
            </div>
          </>
        )}
        {specialties?.length != 0 ?
        <div className={`${classes.boxPointsDrawer} ${classes.centeredBox}`}>
          <div style={{ display: 'flex', flexDirection: screen.sWidth < 321 ? 'column' : 'row' }}>
            <div
              className={`${classes.boxPointsDrawer_AgendaSusp} ${classes.boxPointsDrawer_Seleccionado} ${classes.box15x15}`}
            />
            <Typography
              className={classes.txtLinksInCard}
              style={ inlineCalendarStyles.appointmentType }
            >
              Seleccionado
            </Typography>
          </div>
          <div style={ inlineCalendarStyles.appointmentStateIndicator }>
            <div
              className={`${classes.boxPointsDrawer_AgendaSusp} ${classes.boxPointsDrawer_Disponible} ${classes.box15x15}`}
            />
            <Typography
              className={classes.txtLinksInCard}
              style={ inlineCalendarStyles.appointmentType }
            >
              Disponible
            </Typography>
          </div>
          <div style={ inlineCalendarStyles.appointmentStateIndicator }>
            <div
              className={`${classes.boxPointsDrawer_AgendaSusp} ${classes.boxPointsDrawer_SinTurno} ${classes.box15x15}`}
              style={{ width: "15px !important" }}
            />
            <Typography
              className={`${classes.txtLinksInCard}`}
              style={ inlineCalendarStyles.noAvailableTurnIndicator }
            >
              Sin turno disponible
            </Typography>
          </div>
          <div style={ inlineCalendarStyles.appointmentStateIndicator }>
            <div
              className={`${classes.boxPointsDrawer_AgendaSusp} ${classes.boxPointsDrawer_Licencia} ${classes.box15x15}`}
            />
            <Typography
              className={classes.txtLinksInCard}
              style={ inlineCalendarStyles.appointmentType }
            >
              Licencia
            </Typography>
          </div>
        </div>
        :
        centeredCircularProgress()
        }
        {(schedule !== undefined && schedule[0] !== "") && (!modelNextAppointment?.isLoading && specialties?.length != 0) ? (
          <CustomCalendar
            familyFilter={doctorCodes}
            appointments={schedule}
            withCard={false}
            isDoctor={true}
          />
        ) : (
          <div
            className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}
          >
            <CircularProgress />
          </div>
        )}
      </div>
      {screen.sWidth < 768 && (
        <FooterDrawer
          activeStep={activeStep}
          handleOpenCancelModal={handleOpenCancelModal}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      )}
      {doctorSchedule?.turnosLibres?.length === 0 &&
        <DoesntFreeAppointmentModal onClose={handleClose} openModal={openModal} />
      }
      {(doctorSchedule?.turnosLibres?.length === undefined || doctorSchedule?.turnosLibres?.length === 0) && (!modelNextAppointment?.isLoading && !loading) && (
        <Typography
          className={`${classes.txtBold500} ${classes.grayTxt}`}
          style={{ marginTop: "10px " }}
        >
          No hay turnos disponibles
        </Typography>
      )}
    </>
    :
    centeredCircularProgress()
    )
  );
};

export default React.memo(SelectDateDrawer, (prevState, nextState) => {
  return (
    prevState.modelNextAppointment?.modelAppointment?.medicoCodigo ===
    nextState.modelNextAppointment?.modelAppointment?.medicoCodigo
  );
});

function validateSpecialties(
  specialties,
  setOpenModal,
  setDescription,
  modelAppointment
) {
  let aux = "";
  if (specialties) {
    aux = specialties?.agenda?.filter(
      (x) => x.medicoCodigo === modelAppointment?.medicoCodigo
    );
    if (aux?.length === 0) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
    setDescription(
      specialties?.agenda?.filter(
        (x) =>
          x.medicoCodigo === modelAppointment?.medicoCodigo &&
          x.especialidadCodigo === modelAppointment?.especialidadCodigo
      )
    );
  }
}
