import * as actionTypes from '../consts/actionTypes';

const initialValues = {
    address: [],
    typesDocuments: [],
    nationalities: [],
    civilStatus: [],
    fullData: [],
    abmData: [],
    credentials: [],
    hobbies: [],
    deportes: [],
    error: [],
    msgError: ''
};

export default function personalDataReducer(state = initialValues, action) {

    const ACTIONS = {
        [actionTypes.FAILURE_GET_TYPE_OF_CONTACT]: responseToReturn(),
        [actionTypes.FAILURE_GET_TYPE_OF_CONTACT_PHONE]: responseToReturn(),
        [actionTypes.FAILURE_GET_TYPE_OF_CONTACT_MAIL]: responseToReturn(),
        [actionTypes.FAILURE_GET_CONTACTDATA_OF_AFFILIATED]: responseToReturn(),
        [actionTypes.FAILURE_GET_TYPES_DOCUMENTS]: responseToReturn(),
        [actionTypes.FAILURE_GET_CIVIL_STATUS]: responseToReturn(),
        [actionTypes.FAILURE_GET_NATIONALITY]: responseToReturn(),
        [actionTypes.FAILURE_GET_FULL_DATA]: responseToReturn(),
        [actionTypes.FAILURE_ABM_FULL_DATA]: responseToReturn(),
        [actionTypes.FAILURE_GET_CREDENTIALS]: responseToReturn(),
        [actionTypes.FAILURE_GET_HOBBIES]: responseToReturn(),
        [actionTypes.FAILURE_GET_DEPORTES]: responseToReturn()
    };

    function responseToReturn() {
        let res = { ...state };
        if (action.message) {
            res = { ...state, msgError: action.message };
        }
        return res;
    }

    if (ACTIONS[action.type] !== undefined) {
        return ACTIONS[action.type];
    }

    switch (action.type) {
        case actionTypes.SUCCESS_GET_TYPE_OF_CONTACT:
            if (action.response)
                return { ...state, address: action.response.data.data };
            else
                return { ...state };
        case actionTypes.SEND_REQUEST_FAILURE:
            if (action.error) {
                return { ...state, error: action.error.response.data.data };
            }
            else {
                return { ...state };
            }
        case actionTypes.SUCCESS_GET_TYPE_OF_CONTACT_PHONE:
            if (action.response)
                return { ...state, phones: action.response.data.data };
            else
                return { ...state };
        case actionTypes.SUCCESS_GET_TYPE_OF_CONTACT_MAIL:
            if (action.response)
                return { ...state, mails: action.response.data.data };
            else
                return { ...state };
        case actionTypes.SUCCESS_GET_CONTACTDATA_OF_AFFILIATED:
            if (action.response)
                return { ...state, contactData: action.response.data };
            else
                return { ...state };
        case actionTypes.SUCCESS_GET_TYPES_DOCUMENTS:
            if (action.response)
                return { ...state, typesDocuments: action.response.data.data.documentoTipo };
            else
                return { ...state };
        case actionTypes.SUCCESS_GET_CIVIL_STATUS:
            if (action.response)
                return { ...state, civilStatus: action.response.data.data.estadoCivil };
            else
                return { ...state };
        case actionTypes.SUCCESS_GET_NATIONALITY:
            if (action.response)
                return { ...state, nationalities: action.response.data.data.nacionalidad };
            else
                return { ...state };
        case actionTypes.SUCCESS_GET_FULL_DATA:
            if (action.response)
                return { ...state, fullData: action.response.data.data };
            else
                return { ...state };
        case actionTypes.SUCCESS_ABM_FULL_DATA:
            if (action.response)
                return { ...state, abmData: action.response.data };
            else
                return { ...state };
        case actionTypes.SUCCESS_GET_CREDENTIALS:
            if (action.response)
                return { ...state, credentials: action.response.data };
            else
                return { ...state };
        case actionTypes.SUCCESS_GET_HOBBIES:
            if (action.response)
                return { ...state, hobbies: action.response.data.data };
            else
                return { ...state };
        case actionTypes.SUCCESS_GET_DEPORTES:
            if (action.response)
                return { ...state, deportes: action.response.data.data };
            else
                return { ...state };
        case actionTypes.CLEAR_ABM_MODEL:
            return { ...state, abmData: [] };
        default:
            return { ...state };
    }
}