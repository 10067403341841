import { CircularProgress, ImageList } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../../assets/styles/styles";

const NotFound = ({ fullItem, wsItem, customMsg = 'No hay contenido para mostrar', loading, customClass }) => {
    const classes = useStyles();
    const [notFound, setNotFound] = useState(
        <div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}>
            <CircularProgress />
        </div>
    );

    useEffect(() => {
        if (wsItem?.length === 0) {
            setNotFound(
                <ImageList className={`${classes.gridList} ${customClass}`} cols={1}>
                    <span className={classes.noContent}>{customMsg}</span>
                </ImageList>
            );
        }
    }, [wsItem]);

    useEffect(() => {
        let notFoundRes = <></>;

        if(loading){
            notFoundRes = <div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}>
            <CircularProgress /></div>;
        }else if(wsItem?.length === 0){
            notFoundRes = <ImageList className={`${classes.gridList} ${customClass}`} cols={1}>
                <span className={classes.noContent}>{customMsg}</span></ImageList>;
        }

        setNotFound(notFoundRes);
    }, [fullItem, loading]);

    return <>{notFound}</>;
};

export default React.memo(NotFound, (prevState, nextState) => {
    return prevState.loading === nextState.loading && prevState.wsItem === nextState.wsItem && prevState.fullItem === nextState.fullItem;
});