import ModalProfile from "../../DialogProfile/Modal";
import { Typography } from "@material-ui/core";
import useStyles from "../../../assets/styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { setModelToSaveAppointment } from "../../../redux/actions/appointmentsActions";
import { useEffect, useState } from "react";

export default function ModalObservations(props) {
    const { medico, openModal, setOpenModal } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const agendaMedico = useSelector((state) => state.medicalCenterReducer.specialtyList.agenda);
    const modelNextAppointment = useSelector(state => state.appointmentsReducer);
    const [modelAppointment, setModelAppointment] = useState(modelNextAppointment?.modelAppointment);

    useEffect(() => {
        const selectedObservation = agendaMedico?.find(x => x.medicoCodigo === medico?.medicoCodigo)?.observaciones;
    
        const updatedModelAppointment = {
            ...modelAppointment,
            observaciones: selectedObservation
        };
    
        setModelAppointment(updatedModelAppointment);
        dispatch(setModelToSaveAppointment(updatedModelAppointment));
    }, [agendaMedico]);
    

    return (
        <div className={classes.borderWhite} style={{ left: "20px" }}>
            {agendaMedico?.filter(x => x.medicoCodigo === medico?.medicoCodigo)?.map((item, index) => (
                <ModalProfile
                    key={index}
                    type='cancel'
                    title={
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                                Observaciones
                            </div>
                        </>
                    }
                    observation={
                        <div style={{ margin: '0 30px', padding: '0', maxWidth: '250px' }}>
                            <Typography style={{ fontSize: "12px", fontWeight: "bold", paddingBottom: "5px" }}>
                                {item.especialidadDescripcion}
                            </Typography>
                            <Typography style={{ fontSize: "14px", paddingBottom: "15px" }}>
                                {item.medicoDescripcion}
                            </Typography>
                            <Typography style={{ fontSize: "12px", fontWeight: "bold", paddingBottom: "10px" }}>
                                DATOS DEL TURNO
                            </Typography>
                            <Typography style={{ fontSize: "12px", paddingBottom: "10px" }}>
                                <span style={{ fontWeight: "bold" }}>EDADES: </span>{item.edadDesde} A {item.edadHasta} AÑOS
                            </Typography>
                            <Typography style={{ fontSize: "12px", paddingBottom: "10px" }}>
                                <span style={{ fontWeight: "bold" }}>OBSERVACIONES: </span>{item.observaciones === '' || item.observaciones === undefined ? "-" : item.observaciones}
                            </Typography>
                            <Typography style={{ fontSize: "12px", paddingBottom: "10px" }}>
                                <span style={{ fontWeight: "bold" }}>PREPARACION: </span>{item.preparacion === '' || item.preparacion === undefined ? "-" : item.preparacion}
                            </Typography>
                        </div>
                    }
                    open={openModal}
                    onClose={setOpenModal}
                    close={true}
                />
            ))}

        </div>
    )
}

