import React, { useState, useEffect } from 'react';
import ButtonPhones from '../../components/ButtonPhones/ButtonPhones';
import Menu from "../../components/Menu/Menu";
import useStyles from "../../assets/styles/styles";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import { getPhones } from "../../redux/actions/phonesUtilsActions";
import { useDispatch, useSelector } from "react-redux";
import Header from '../../components/GenericHeader/Header';
import { Typography, CircularProgress } from "@material-ui/core";
import { getLimitPlan } from "../../redux/actions/limitPlansAction";
import { notAccessToThisModule, verifyPermission } from "../../utils/utilsFunctions";
import useTrackPageView from "../../utils/useTrackPageView";

const UsefulPhones = () => {
  const classes = useStyles();
  const { screen } = useResizeScreen();
  const [ hasPermission, setHasPermission ] = useState(false);
  const dispatch = useDispatch();
  const affiliatePlan = useSelector((state) => state.personalDataReducer?.fullData?.afiliado?.planDescripcion);
  const items = useSelector(state => state.phonesReducer);
  const limitPlanReducer = useSelector((state) => state.limitPlanReducer.allLimitPlanData);
  const [loading, setLoading] = useState(true);
  const loadingPermission = useSelector((state) => state.limitPlanReducer.requestInProgress);
  const trackPageView = useTrackPageView("Teléfonos Útiles");

  useEffect(() => {
    verifyPermission(limitPlanReducer, affiliatePlan, "Teléfonos Útiles", setHasPermission);
  }, [limitPlanReducer, affiliatePlan, hasPermission]);

  useEffect(() => {
    trackPageView();
    setLoading(true);
    dispatch(getPhones());
  }, []);

  useEffect(() => {
    dispatch(getLimitPlan());
  }, []);

  function show(condition) {
    var res;
    if(condition) {
        res = (
          <ButtonPhones listItems={items?.phoneList} />
        );
    } else {
        res = (notAccessToThisModule());
    }
    return res;
  }

  useEffect(() => {
    if (Object.values(items?.phoneList).length > 0) {
      setLoading(false);
    }
  }, [items]);

  return (
    <>
      <Menu />
      {screen.sWidth < 768 ?
        <Header title='Teléfonos útiles' colorTitle="#0075C9" />
        :
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography className={`${classes.titlePhoneFix}`}>
              Teléfonos útiles
            </Typography>
          </div>
        </>
      }
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography className={classes.paragraphUsefulPhones}>
          Si estás desde tu celular, presioná en el número que precises llamar
        </Typography>
      </div>
      {
        loading || loadingPermission && affiliatePlan?
          <div style={{ display: 'flex', justifyContent: 'center', flex: '0 0 100%', paddingTop: '10px' }}>
            <CircularProgress />
          </div>
        :
          (
            show(hasPermission)
          )
      }
    </>
  );
};

export default UsefulPhones;
