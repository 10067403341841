import * as actionTypes from "../consts/actionTypes";

export function deletePersonPlayer(param) {
    return {
        type: actionTypes.DELETE_PERSON_PLAYER,
        payload: param
    };
}
    
export function updateTag(param) {
    return {
        type: actionTypes.UPDATE_TAG,
        payload: param
    };
}