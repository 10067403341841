import useStyles from '../../assets/styles/styles';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

import CustomButton from '../../controls/Button/CustomButton';


export default function Banner(props) {
  const classes = useStyles();
  return (
    <>
      <ImageList
        className={`${classes.imageBanner}`}>
        {props?.items?.map((item, i) => (
          <ImageListItem key={i}
            style={{ height: "320px" }}>
            <img src={item?.img} alt={item?.title} style={{ objectFit: "fill" }}/>
            <ImageListItemBar
              className={`${classes.cardContent} ${classes.titleBanner}`}
              style={{ height: "60px" }}
              title={item?.title}
              key={i}
            />
          </ImageListItem>
        )
        )}
      </ImageList>
      {(props?.hasButton && props?.items !== undefined) && <a href={props?.items[0]?.link} style={{ textDecoration: 'none' }} >
        <CustomButton
          title={"Conocelo"}
          customClass={`${classes.customBtnMaps} ${classes.lightLinearGradient}`}
          style={{
            position: "absolute",
            top: "435px",
            right: "40px",
          }}
        />
      </a>}
    </>
  );
}
