import React, { useCallback, useEffect, useRef, useState } from "react";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import clsx from "clsx";
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import { Divider, Button, Box, Dialog, DialogContent } from "@material-ui/core";
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PhoneInTalkOutlinedIcon from '@material-ui/icons/PhoneInTalkOutlined';
import IconButton from "@material-ui/core/IconButton";
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

/* Icons */
import ArticleOutlinedIcon from '@material-ui/icons/ArticleOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import NotesIcon from "@material-ui/icons/Notes";
import PersonIcon from "@material-ui/icons/Person";
import ViewListIcon from "@material-ui/icons/ViewList";
import SupportAgentOutlinedIcon from '@material-ui/icons/SupportAgentOutlined';
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import CheckIcon from "@material-ui/icons/Check";
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import useStyles from "../../assets/styles/styles";
import { Link, useHistory } from "react-router-dom";
import { AuthConsumer } from "../../auth/providers/authProvider";
import LogoutIcon from '@material-ui/icons/Logout';
import logo from "../../assets/images/logo.png";
import ModalLogOut from "./ModalLogOut";
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import CreditScoreOutlinedIcon from '@material-ui/icons/CreditScoreOutlined';
import WarningAmberOutlinedIcon from '@material-ui/icons/WarningAmberOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import { useDispatch, useSelector } from "react-redux";
import { getAllNotifications } from "../../redux/actions/notificationsActions";
import ItemMenu from "./ItemMenu";
import DropdownNotification from "./DropdownNotification";
import EmergencyModal from "../HomeLogged/EmergencyModal";
import Typography from "@material-ui/core/Typography";
import { getFullData } from "../../redux/actions/personalDataActions";
import { getConfigurations } from "../../redux/actions/configurationAction";
import { getCards } from "../../redux/actions/cardActions";
import NotAccessModal from "../HomeLogged/NotAccessModal";
import { getLimitPlan } from "../../redux/actions/limitPlansAction";
import { verifyPermission } from "../../utils/utilsFunctions";
import CustomButton from "../../controls/Button/CustomButton";
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import { CircularProgress } from "@mui/material";

const Menu = () => {
  const dispatch = useDispatch();
  const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);
  const classes = useStyles();
  const [maintenanceModal, setMaintenanceModal] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState('');
  const { screen } = useResizeScreen();
  const [openLogout, setOpenLogout] = useState();
  const notificacionReducer = useSelector((state) => state.notificationReducer.notificacions);
  const personalDataReducer = useSelector(state => state.personalDataReducer);
  const [openModalEmergency, setOpenModalEmergency] = useState(false);
  const history = useHistory();
  const drawerOpenerRef = useRef();
  const [openNotAccessModal, setOpenNotAccessModal] = useState(false);
  const affiliatePlanDescription = useSelector((state) => state.personalDataReducer?.fullData?.afiliado?.planDescripcion);
  const limitPlanReducer = useSelector((state) => state.limitPlanReducer.allLimitPlanData);
  const [ hasPermission, setHasPermission ] = useState(false);

  useEffect(() => {
    dispatch(getConfigurations());
    if (notificacionReducer?.notification.length != 0) {
      dispatch(getAllNotifications({ email: window.localStorage.getItem('email') }));
    }
    dispatch(getLimitPlan());
    dispatch(getCards());
  }, []);

  useEffect(() => {
    verifyPermission(limitPlanReducer, affiliatePlanDescription, "Reintegros", setHasPermission);
  }, [limitPlanReducer, affiliatePlanDescription, hasPermission]);

  useEffect(() => {
    checkMaintenanceMode();
  }, []);

  const handleLogout = () => {
    history.push('/logout');
  }

  const checkMaintenanceMode = () => {
    const maintenanceMode = configurationReduxState?.filter(configuration => configuration?.id == 'onOffMantenimiento');
    const maintenanceMessage = configurationReduxState?.filter(configuration => configuration?.id == 'mensajeMantenimiento');

    if(maintenanceMode?.[0]?.valor == 'Activo') {
      setMaintenanceMessage(maintenanceMessage?.[0]?.valor);
      setMaintenanceModal(true);
    }
  }

  useEffect(() => {
    checkMaintenanceMode();
  }, [configurationReduxState])

  const showMaintenanceMessage = () => {
    return (
      <div className={classes.borderWhite} style={{ left: '20px' }}>
        <Dialog
          disableEscapeKeyDown={true}
          onBackdropClick={false}
          onClose={setMaintenanceModal}
          aria-labelledby='customized-dialog-title'
          open={maintenanceModal}
          id='maintenanceModal'>
          <Box display="flex" id='cancelModal'>
            <div style={{ alignSelf: 'center' }}>
              <DialogContent className={classes.contentDialogTitle}>
                <section className={classes.maintenanceDialogContentContainer}>
                  <BuildCircleIcon style={{ fontSize: '50px', marginBottom: '20px' }} className={classes.quickAccessIcon} />
                  <div className={classes.maintenanceMessageContentContainer}>
                    <span>{maintenanceMessage}</span>
                  </div>
                  <CustomButton title='ACEPTAR' onClick={handleLogout} customClass={classes.maintenanceButton} />
                </section>
              </DialogContent>
            </div>
          </Box>
        </Dialog>
      </div>
    );
  }

  //State for Drawer Open or Close
  const [open, setOpen] = useState(true);
  const DrawerOpen = useCallback(() => {
    setOpen(prevState => !prevState);
  }, [open]);

  const handleClickAway = () => {
    if (screen.sWidth <= 768 ) {
      setOpen(false);
    }
  };

  //Lists of items in Drawer
  const listItems = (
    <List>
      <AuthConsumer>
        {({ isAuthenticated }) => {
          if (!isAuthenticated()) {
            return (<>
              <ItemMenu Link={Link} Titulo="Inicio" Icon={<HomeOutlinedIcon />} Redirect="/"></ItemMenu>
              <Divider classes={{ root: classes.divider }} />
            </>);
          }
        }
        }
      </AuthConsumer>
      <AuthConsumer>
        {({ isAuthenticated }) => {
          if (isAuthenticated()) {
            return (
              <>
                <ItemMenu emergency={true} setOpenModalEmergency={setOpenModalEmergency} Titulo="Urgencias" Icon={<PhoneInTalkOutlinedIcon />}></ItemMenu>
                <Divider classes={{ root: classes.divider }} />
                <ItemMenu Link={Link} Titulo="Turnos" Icon={<AddBoxOutlinedIcon />} Redirect="/AppointmentHome"></ItemMenu>
                <Divider classes={{ root: classes.divider }} />
                <ItemMenu Link={Link} Titulo="Autorizaciones" Icon={<DescriptionOutlinedIcon />} Redirect="/Authorizations"></ItemMenu>
                <Divider classes={{ root: classes.divider }} />
                {
                  hasPermission ?                   
                  <ItemMenu Link={Link} Titulo="Reintegros" Icon={<MonetizationOnOutlinedIcon />} Redirect="/Refunds"></ItemMenu>
                  :
                  <ItemMenu notAccess={true} setOpenNotAccessModal={setOpenNotAccessModal} Titulo="Reintegros" Icon={<MonetizationOnOutlinedIcon />}></ItemMenu>
                }
                <Divider classes={{ root: classes.divider }} />
                <ItemMenu Link={Link} Titulo="Médico virtual" Icon={<DevicesOutlinedIcon />} Redirect="/VirtualDoctor"></ItemMenu>
                <Divider classes={{ root: classes.divider }} />
                <ItemMenu Link={Link} Titulo="Credenciales" Icon={<ContactMailOutlinedIcon />} Redirect="/Credentials"></ItemMenu>
                <Divider classes={{ root: classes.divider }} />
                <ItemMenu Link={Link} Titulo="Datos médicos" Icon={<FilterNoneOutlinedIcon />} Redirect="/MedicalData"></ItemMenu>
                <Divider classes={{ root: classes.divider }} />
                <ItemMenu Link={Link} Titulo="Datos y afiliaciones" Icon={<AccountCircleOutlinedIcon />} Redirect="/PersonalData"></ItemMenu>
                <Divider classes={{ root: classes.divider }} />
                <ItemMenu Link={Link} Titulo="Cobertura" Icon={<ArticleOutlinedIcon />} Redirect="/Coverage"></ItemMenu>
                <Divider classes={{ root: classes.divider }} />
                <ItemMenu Link={Link} Titulo={"Teléfonos útiles"} Icon={<SupportAgentOutlinedIcon />} Redirect="/phones"></ItemMenu>
                <Divider classes={{ root: classes.divider }} />
              </>
            );
          }
        }}
      </AuthConsumer>
      <ItemMenu Link={Link} Titulo={'Planes de Cobertura'} Icon={<EventAvailableIcon />} Redirect={"/plans"}></ItemMenu>
      <Divider classes={{ root: classes.divider }} />
      <ItemMenu Link={Link} Titulo="Cartilla" Icon={<ViewListIcon />} Redirect="/MedicalRecords"></ItemMenu>
      <Divider classes={{ root: classes.divider }} />
      <AuthConsumer>
        {({ isAuthenticated }) => {
          if (isAuthenticated()) {
            return (
              <>
                <ItemMenu Link={Link} Titulo="Facturación" Icon={<CreditScoreOutlinedIcon />} Redirect="/Billing"></ItemMenu>
                <Divider classes={{ root: classes.divider }} />
                <Button target="_blank" rel="noopener noreferrer" href={`${configurationReduxState?.filter(x => x.id === 'reclamoConLogin')[0]?.valor}`}
                  startIcon={<WarningAmberOutlinedIcon />}
                  className={`${classes.icon} ${classes.listItemsStyles} ${classes.multiline} ${classes.buttonBlankClaims}`}
                  size={"large"}>Reclamos
                </Button>
                <Divider classes={{ root: classes.divider }} />
                <ItemMenu Link={Link} Titulo="Contactanos" Icon={<ContactPhoneOutlinedIcon />} Redirect="/ContactUs"></ItemMenu>
                <Divider classes={{ root: classes.divider }} />
                <ItemMenu Link={Link} Titulo="Preguntas frecuentes" Icon={<QuestionAnswerOutlinedIcon />} Redirect="/FrequentQuestionsPage"></ItemMenu>
              </>
            );
          } else {
            return (<>
              <ItemMenu Link={Link} Titulo={"Teléfonos útiles"} Icon={<PhoneIcon />} Redirect="/phones"></ItemMenu>
              <Divider classes={{ root: classes.divider }} />
              <ItemMenu Link={Link} Titulo="Contactar a un asesor" Icon={<PhoneIphoneIcon />} Redirect="/contactadvisor"></ItemMenu>
              <Divider classes={{ root: classes.divider }} />
              <ItemMenu Link={Link} Titulo="Nuestras sedes" Icon={<LocationCityIcon />} Redirect="/headquarters"></ItemMenu>
              <Divider classes={{ root: classes.divider }} />
              <ItemMenu Link={Link} Titulo="Actividades" Icon={<CheckIcon />} Redirect="/activities"></ItemMenu>
              <Divider classes={{ root: classes.divider }} />
            </>);
          }
        }}
      </AuthConsumer>
      {
        screen.sWidth < 768 ? <>
          <Divider classes={{ root: classes.divider }} />
          <ItemMenu Link={Link} Titulo="Baja del servicio - Arrepentimiento" Icon={<ClearIcon />} Ref={true} Redirect={window.env.URL_LowService}></ItemMenu>
          <Divider classes={{ root: classes.divider }} />
          <ItemMenu Link={Link} Titulo="Defensa de las y los consumidores. Reclamos" Icon={<PeopleAltOutlinedIcon />} Ref={true} Redirect="/consumercomplaint"></ItemMenu>
        </>
          : ''
      }
      <div className={classes.menuSubscription}>
        <Typography className={classes.multiline}>
          {" "}{"Recibe las notificaciones de Luis Pasteur"}{" "}
        </Typography>
        <div className='onesignal-customlink-container'></div>
      </div>
    </List >
  );

  const handleClickLogOut = () => {
    setOpenLogout(prevState => !prevState);
  };

  if (document.getElementById('loadingDiv')) {
    setTimeout(() => {
      document.getElementById('loadingDiv')?.remove();
    }, 120);
  }


  useEffect(() => {
    let numberPerson = window.localStorage.getItem("numeroPersona");
    if (numberPerson) {
      dispatch(getFullData({ numeroPersona: numberPerson }));
    }
  }, []);

  useEffect(() => {
    if (screen.sWidth <= 768) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [screen.sWidth]);

  //Appbar and drawer implementation with icons,lists.
  return (
    <>
      {showMaintenanceMessage()}
      <div className={classes.root}>
        <CssBaseline />
        <ClickAwayListener
      onClickAway={handleClickAway}
    >
        <AppBar
          position="fixed"
          elevation={0}
          className={clsx(classes.appBar, { [classes.appBarShift]: open })}
        >
          <Toolbar className={`${classes.toolbar}`}>
            {screen.sWidth <= 768 &&
              <IconButton
                ref={drawerOpenerRef}
                color="inherit"
                maria-label="open drawer"
                onClick={DrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open)}
              >
                <NotesIcon />
              </IconButton>
            }
            <AuthConsumer>
              {({ isAuthenticated }) => {
                if (isAuthenticated()) {
                  return (
                    <>
                      <div className={classes.divHeaderLogo}>
                        <IconButton onClick={() => { history.push('/'); }}>
                          <img style={{ height: '90px' }} src={logo} alt="logo" />
                        </IconButton>
                      </div>
                      <section className={`${classes.rightSectionMenu}`}>
                        {screen.sWidth > 768 &&
                          <article style={{ display: 'flex', flex: '1 1 100%', flexWrap: 'wrap', paddingRight: '20px' }}>
                            <Typography style={{ color: 'black', width: '100%', justifyContent: 'flex-end', display: 'flex' }}>
                              {window.localStorage.getItem("userName").replace("_", " ")}
                            </Typography>
                            <Typography style={{ color: 'black', fontWeight: 'bold', width: '100%', justifyContent: 'flex-end', display: 'flex' }}>
                              Plan {personalDataReducer?.fullData?.afiliado?.planDescripcion?.trim()}
                            </Typography>
                          </article>
                        }
                        <DropdownNotification />
                        <IconButton
                          edge="start"
                          className={classes.menuButtonRight}
                          color="inherit"
                          onClick={handleClickLogOut}
                        >
                          <LogoutIcon />
                        </IconButton>
                      </section>
                    </>
                  );
                } else {
                  return (
                    <>
                    <div className={classes.divHeaderLogo}>
                    <IconButton onClick={() => { history.push('/'); }}>
                        <img style={{ height: '90px'}} src={logo} alt="logo" />
                      </IconButton>
                    </div>
                      {screen.sWidth > 768 &&
                        <Box className={`${classes.boxHeaderBtn} ${classes.boxHeaderBtnFix}`}>
                          <CustomButton
                            href={`${configurationReduxState?.filter(x => x.id === 'bajaServicio')[0]?.valor}`}
                            target="_blank"
                            rel='noopener noreferer'
                            title="BAJA DEL SERVICIO - ARREPENTIMIENTO"
                            customClass={`${classes.customHeaderBtn} ${classes.lightLinearGradient} ${classes.customHeaderBtnFix}`}
                          />
                          <CustomButton
                            href={`${configurationReduxState?.filter(x => x.id === 'reclamoSinLogin')[0]?.valor}`}
                            target="_blank"
                            rel='noopener noreferer'
                            title="DEFENSA DE LAS Y LOS CONSUMIDORES. RECLAMOS"
                            customClass={`${classes.customHeaderBtn} ${classes.customHeaderBtnFix} ${classes.whiteAndBlueBtn}`}
                          />
                        </Box>}
                      {
                        configurationReduxState !== undefined ?
                          <IconButton
                            edge='start'
                            className={classes.menuButtonRight}
                            color='inherit'
                            component={Link}
                            to={'/login'}>
                            <PersonIcon style={{ flex: '1 1 100%' }} />
                            <span className={classes.txtLogIn}>Iniciar Sesion</span>
                          </IconButton>
                        :
                          <CircularProgress size={40} style ={{marginLeft:"40px"}}sx={{ color: '#fff', marginRight: '30px' }} />
                      }
                    </>);
                }
              }}
            </AuthConsumer>
          </Toolbar>
        </AppBar>
        </ClickAwayListener>
        <Drawer
          className={`${classes.drawerMenu}`}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{ paper: `${classes.drawerPaperMenu} ${classes.customScroll}` }}
        >
          {listItems}
        </Drawer>
      </div>
      <ModalLogOut isOpen={openLogout} setOpen={setOpenLogout} />
      <EmergencyModal isOpen={openModalEmergency} setOpen={setOpenModalEmergency} />
      <NotAccessModal isOpen={openNotAccessModal} setOpen={setOpenNotAccessModal} />
    </>
  );
};

export default Menu;
