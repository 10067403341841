import * as actionTypes from "../consts/actionTypes";

const initialValues = {
  filtersCartillaSelected: {
    plan: "",
    zone: "",
    city: "",
    provider: "",
    department: "",
    alphabeticalSearch: "",
  },
  filters: { plan: [], provider: [], zone: [] },
  localidadesBarrios: [],
  especialidadesEstudios: [],
  cartilla: {},
  filtersCartilla: {
    Titular: "",
    Miembro: "",
    FiltroTipoPrestador: "",
    FiltroEspecialidad: "",
    FiltroEstudioComplementario: "",
    FiltroPlan: "",
    FiltroZona: "",
    FiltroLocalidad: [],
    FiltroRazonSocial: "",
    Count: 0,
    Skip: 0,
  },
  completeProfile: {
    PrestadorNumero: 0,
  },
  loading: false,
  msgError: ''
};
export default function cartillaReducer(state = initialValues, action) {

  const ACTIONS = {
    [actionTypes.FAILURE_GET_FILTERS_VALUES]: responseToReturn(),
    [actionTypes.FAILURE_GET_LOCALIDADES_BARRIOS]: responseToReturn(),
    [actionTypes.FAILURE_GET_ESPECIALIDADES_ESTUDIOS_COMPLEMENTARIOS]: responseToReturn(),
    [actionTypes.FAILURE_GET_PRESTADORES_RESULT]: responseToReturn(),
    [actionTypes.FAILURE_GET_PERFIL_COMPLETO]: responseToReturn()
  };

  function responseToReturn() {
    let res = { ...state };
    if (action.message) {
        res = { ...state, msgError: action.message };
    }
    return res;
  }

  if (ACTIONS[action.type] !== undefined) {
    return ACTIONS[action.type];
  }

  switch (action.type) {
    case actionTypes.SET_SELECTED_FILTERS_VALUES:
      return {
        ...state,
        filtersCartillaSelected: action.payload,
      };
    case actionTypes.SET_MODEL_FILTERS_VALUES:
      return {
        ...state,
        filtersCartilla: action.payload,
      };
    case actionTypes.GET_FILTERS_VALUES:
      return {
        ...state,
      };
    case actionTypes.SUCCESS_GET_FILTERS_VALUES:
      if (action.response) {
        return { ...state, filters: action.response.data };
      } else {
        return { ...state, action };
      }
    case actionTypes.SUCCESS_GET_LOCALIDADES_BARRIOS:
      if (action.response) {
        return { ...state, localidadesBarrios: action.response?.data };
      } else {
        return [...state, action];
      }
    case actionTypes.SUCCESS_GET_ESPECIALIDADES_ESTUDIOS_COMPLEMENTARIOS:
      if (action.response) {
        return { ...state, especialidadesEstudios: action.response?.data };
      } else {
        return [...state, action];
      }
    case actionTypes.SUCCESS_GET_PRESTADORES_RESULT:
      if (action.response) {
        return {
          ...state,
          cartilla: action.response?.data,
          loading: false
        };
      } else {
        return [...state, action];
      }
    case actionTypes.SUCCESS_GET_PERFIL_COMPLETO:
      if (action.response) {
        return {
          ...state,
          completeProfile: action.response?.data,
        };
      } else {
        return [...state, action];
      }
    case actionTypes.CLEAN_MODEL_MEDICAL_RECORDS:
      return {
        ...state, filtersCartillaSelected: {
          plan: "",
          zone: "",
          city: "",
          provider: "",
          department: "",
          alphabeticalSearch: "",
        },
        filters: { plan: [], provider: [], zone: [] },
        localidadesBarrios: [],
        especialidadesEstudios: [],
        cartilla: {},
        filtersCartilla: {
          Titular: "",
          Miembro: "",
          FiltroTipoPrestador: "",
          FiltroEspecialidad: "",
          FiltroEstudioComplementario: "",
          FiltroPlan: "",
          FiltroZona: "",
          FiltroLocalidad: [],
          FiltroRazonSocial: "",
          Count: 0,
          Skip: 0,
        },
        completeProfile: {
          PrestadorNumero: 0,
        },
        loading: false
      };
    case actionTypes.SET_LOADING_MEDICAL_RECORDS:
      return { ...state, loading: true };
    case actionTypes.CLEAN_PERFIL_COMPLETO:
      return { ...state, cartilla: {} };
    default:
      return state;
  }
}
