import * as actionTypes from "../consts/actionTypes";

export function getDataRegisterUser(param) {
    return {
        type: actionTypes.GET_DATA_REGISTER_USER,
        payload: param
    };
}

export function addDataRegisterUser(param) {

    return {
        type: actionTypes.ADD_DATA_REGISTER_USER,
        payload: param
    };
}

export function deleteDataRegisterUser(param) {

    return {
        type: actionTypes.DELETE_DATA_REGISTER_USER,
        payload: param
    };
}

export function modifyDataRegisterUser(param) {

    return {
        type: actionTypes.MODIFY_DATA_REGISTER_USER,
        payload: param
    };
}

export function registerUser(params) {
    return {
        type: actionTypes.REGISTER_USER,
        payload: params
    };
}

export function verifyRegister(param) {
    return {
        type: actionTypes.VERIFY_REGISTER,
        payload: param
    };
}