import { takeLatest, call, put } from "@redux-saga/core/effects";
import * as actionTypes from '../consts/actionTypes';
import * as API from '../api/index';

function* asyncGetTelemedicineAppointments({ payload }) {
    try {
        let response = yield call(API.getTelemedicineAppointments, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_TELEMEDICINE, response, message: '' });
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_TELEMEDICINE, message: error.message });
    }
}

export default function* telemedicineAppointmentsSaga() {
    yield takeLatest(actionTypes.GET_TELEMEDICINE, asyncGetTelemedicineAppointments);
}
