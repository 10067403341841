import React from 'react';
import AddRefundStep1 from './CustomSteps/AddRefundStep1';
import AddRefundStep2 from './CustomSteps/AddRefundStep2';
import AddRefundStep3 from './CustomSteps/AddRefundStep3';
import AddRefundStep4 from './CustomSteps/AddRefundStep4';
import AddRefundStep5 from './CustomSteps/AddRefundStep5';

const DrawerRefundsSteps = ({ isWeb, activeStep, setActiveStep, handleOpenCancelModal, handleBack }) => {
    const ShowStepsFunction = () => {
        const DEFAULT_STEP = <AddRefundStep1 activeStep={activeStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} setActiveStep={setActiveStep} />;
        const ACTIVE_STEPS = {
            0: <AddRefundStep1 activeStep={activeStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} setActiveStep={setActiveStep} />,
            1: <AddRefundStep2 activeStep={activeStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} setActiveStep={setActiveStep} />,
            2: <AddRefundStep3 activeStep={activeStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} setActiveStep={setActiveStep} />,
            3: <AddRefundStep4 activeStep={activeStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} setActiveStep={setActiveStep} />,
            4: <AddRefundStep5 activeStep={activeStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} setActiveStep={setActiveStep} />,
        };

        const ACTIVEWEB_STEPS = {
            0: <AddRefundStep1 activeStep={activeStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} setActiveStep={setActiveStep} />,
            1: ACTIVE_STEPS[activeStep],
            2: ACTIVE_STEPS[activeStep],
            3: ACTIVE_STEPS[activeStep],
            4: ACTIVE_STEPS[activeStep]
        };

        let returnActiveStep = DEFAULT_STEP;

        if (activeStep) {
            if (isWeb) {
                returnActiveStep = ACTIVEWEB_STEPS[activeStep];
            } else {
                returnActiveStep = ACTIVE_STEPS[activeStep];
            }
        }
        return returnActiveStep;
    };

    return <>
        {ShowStepsFunction()}
    </>;
};

export default React.memo(DrawerRefundsSteps, (prevState, nextState) => {
    return prevState.activeStep === nextState.activeStep;
});
