import * as React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { useResizeScreen } from "../../hooks/useResizeScreen";
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import MobileDatePicker from '@material-ui/lab/MobileDatePicker';
import DesktopDatePicker from '@material-ui/lab/DesktopDatePicker';
import { TextField } from "@material-ui/core";
import useStyles from "../../assets/styles/styles";
import { es } from "date-fns/locale";

const CustomDatePicker = (props) => {
  const screenSize = useResizeScreen();
  const classes = useStyles();

  function required() {
    if (props.required) {
      return <span style={{ color: '#ff0000' }}>*</span>;
    }
  }
  return (
    screenSize.screen.sWidth > "900" ?
      <FormControl className={classes.formControl}>
        <InputLabel className={`${classes.inputLabelSelectForms} ${props.inputLabelClass}`} shrink htmlFor="bootstrap-input">

          {props.label}{required()}
        </InputLabel>

        <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}  >
          <DesktopDatePicker disabled={props.disable}
            cancelText="Cancelar"
            toolbarTitle=""
            inputFormat="dd/MM/yyyy"
            value={props.value}
            onChange={(newValue) => {
              props.onChange(newValue);
            }}
            renderInput={(params) => <TextField {...params} helperText={''}/>}
          />
        </LocalizationProvider>
      </FormControl>
      :
      <FormControl variant="standard" className={classes.formControl}>
        <InputLabel shrink style={{ position: "relative", transformOrigin: "top left !important" }} >
          {props.label}{required()}
        </InputLabel>
        <LocalizationProvider locale={es} dateAdapter={AdapterDateFns} >

          <MobileDatePicker disabled={props.disable}
            cancelText="Cancelar"
            toolbarTitle=""
            inputFormat="dd/MM/yyyy"
            value={props.value}
            onChange={(newValue) => {
              props.onChange(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

      </FormControl>
  );
}

export default CustomDatePicker