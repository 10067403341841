import { useEffect, useState, Fragment } from 'react';
import { FormControl, FormHelperText, FormControlLabel, Checkbox, InputLabel, TextField, Typography, InputAdornment, IconButton, CircularProgress } from "@material-ui/core";
import CustomSelect from "../../../controls/Select/CustomSelect";
import CustomButton from "../../../controls/Button/CustomButton";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import useStyles from "../../../assets/styles/styles";
import { useSelector, useDispatch } from 'react-redux';
import { getDataAffiliatesBaja, getMotivosBaja, getSolicitudBaja, getFile, postRequestLow } from '../../../redux/actions/procedureActions';
import { LocalizationProvider, MobileDatePicker } from '@material-ui/lab';
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import CustomizedInputs from '../../../controls/InputCustom/CustomizedInputs';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import UploadModal from "../../../controls/UploadModal/UploadModal";
import { deleteFile, handleChangeMail, isDisabled, validate, validateFiles, validateData, validateDate, messageModalFunction, showBtn } from "../../../utils/utilsFunctions";
import ConfirmModal from '../ConfirmModal';
import SaveModal from '../SaveModal';
import { es } from "date-fns/locale";

const UnsubscribeMember = (props) => {
    const { listOfAttachmentButtons, processSelected, permission } = props;
    const date = new Date();
    const data = useSelector(state => state.procedureReducer);
    const [withdrawalReason, setWithdrawalReason] = useState([]);
    const [reasonSelected, setReasonSelected] = useState('');
    const [dateLow, setDateLow] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    const fullPersonalData = useSelector(state => state.personalDataReducer);
    const [mail, setMail] = useState("");
    const [phone, setPhone] = useState("");
    const [mailError, setMailError] = useState('');
    const dispatch = useDispatch();
    const [disabledEmail, setDisabledEmail] = useState(true);
    const [showPermanente, setShowPermanente] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [emailChecked, selEmailChecked] = useState(false);
    const [modalDescription, setModalDescription] = useState("");
    const [modalIcon, setModalIcon] = useState('');
    const [errorClass, setErrorClass] = useState('');
    const [openSendSuccess, setOpenSendSuccess] = useState(false);
    const [query, setQuery] = useState("");
    const [disabledPhone, setDisabledPhone] = useState(true);
    const [files, setFiles] = useState([]);
    const [fileButtons, setFileButtons] = useState([]);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [nameOfItem, setItemName] = useState('');
    const [itemRequired, setItemRequired] = useState('');
    const [openSendData, setOpenSendData] = useState(false);
    const [familyGroup, setFamilyGroup] = useState([]);
    const [memberSelected, setMemberSelected] = useState([]);
    const postProcedureSelector = useSelector(state => state.procedureReducer.postRequest);
    const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);

    const classes = useStyles();

    const parameter = { numeroPersona: window.localStorage.getItem("numeroPersona") };

    const MotivosBaja = [
        {
            MotivoBajaCodigo: "baj",
            MotivoBajaDescripcion: "Motivo 1",
            MotivoBajaObservacion: "Observacion",
        }, {
            MotivoBajaCodigo: "baj",
            MotivoBajaDescripcion: "Motivo 2",
            MotivoBajaObservacion: "Observacion",
        }, {
            MotivoBajaCodigo: "baj",
            MotivoBajaDescripcion: "Motivo 3",
            MotivoBajaObservacion: "Observacion",
        }, {
            MotivoBajaCodigo: "baj",
            MotivoBajaDescripcion: "Motivo 4",
            MotivoBajaObservacion: "Observacion",
        }
    ];

    const handleChangeSelectMotive = (e) => {
        const { name, value } = e.target;
        setReasonSelected(value);
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    const handleEditEmail = () => {
        setDisabledEmail(prevState => !prevState);
    };

    const handleEditPhone = () => {
        setDisabledPhone(prevState => !prevState);
    };

    const handleCheck = (e) => {
        e.preventDefault();
        if (!emailChecked) {
            setModalTitle('SE HA MODIFICADO EL MAIL CORRECTAMENTE');
            setModalDescription(`Este cambio se realizará en el e-mail particular.`);
            setModalIcon(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
            setErrorClass('');
            setOpenSendSuccess(prevState => !prevState);
        }
        selEmailChecked(prevState => !prevState);
    };
    const [error, setError] = useState({
        reasonSelected: '',
        mail: '',
        phone: ''
    });

    const handleChangePhone = (e) => {
        const { name, value } = e.target;
        setPhone(value);
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    const handleOpenModal = (itemName, fileRequired) => (e) => {
        e.preventDefault();
        setOpenUploadModal(prevState => !prevState);
        setItemName(itemName);
        setItemRequired(fileRequired);
    };

    const dowloadFile = (codigo) => {
        dispatch(getFile(codigo));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validateSubmitFunction({
            listOfAttachmentButtons, files, date, dateLow, error, reasonSelected, setError,
            processSelected, memberSelected, mail, showPermanente, phone, query,
            setModalTitle, setModalDescription, setModalIcon, setErrorClass, setOpenSendSuccess, dispatch, setOpenSendData
        });
    };

    const handleChangeSelectMember = (e) => {
        const { value } = e.target;
        setMemberSelected(value);
    };

    useEffect(() => {
        dispatch(getDataAffiliatesBaja(parameter));
        dispatch(getMotivosBaja());
        dispatch(getSolicitudBaja());
    }, []);

    useEffect(() => {
        let auxFamilyGroup = data?.dataAffiliates?.items?.map(item => {
            return { value: item.itemCodigo, label: item.itemDescripcion };
        });
        if (auxFamilyGroup !== undefined) {
            setFamilyGroup(auxFamilyGroup);
            setMemberSelected(auxFamilyGroup[0]?.value);
        }
    }, [data?.dataAffiliates?.items]);

    useEffect(() => {
        if (Object.values(memberSelected).length > 0) {
            dispatch(getDataAffiliatesBaja(memberSelected));
            let auxMember = data?.dataAffiliates?.afiliados?.filter(x => x.numeroPersona == Number.parseInt(memberSelected));
            setPhone(auxMember[0]?.telefono);
            setMail(auxMember[0]?.mail);
        }
    }, [memberSelected]);

    useEffect(() => {
        if (data.file != null && data.file.result) {
            var a = document.createElement("a"); //Create <a>
            a.href = "data:image/png;base64," + data.file.base64; //Image Base64 Goes here
            a.download = "Image.png"; //File name Here
            a.click(); //Downloaded file
        }
    }, [data.file]);

    useEffect(() => {
        setWithdrawalReasonFunction(data, setWithdrawalReason, MotivosBaja);
    }, [data.withdrawalReason]);

    useEffect(() => {
        messageModalFunction(
            postProcedureSelector, setModalTitle,
            setModalDescription,
            setModalIcon,
            setErrorClass,
            classes,
            setOpenSendSuccess,
            mail
        );

        return (() => {
            if (postProcedureSelector?.confirmacion) {
                setModalTitle('');
                setModalDescription('');
                setModalIcon('');
                setErrorClass('');
                setTimeout(() => {
                    window.location.replace('/PersonalData');
                }, 4000);
            }
        });
    }, [postProcedureSelector]);

    return (
        <>
            {permission ?
                <>
                    <FormControl className={`${classes.formControlCustomSelect} ${classes.itemGridSpan2} `} variant="standard">
                        <CustomSelect
                            name='familyGroup'
                            label='GRUPO FAMILIAR'
                            list={familyGroup}
                            selected={memberSelected}
                            onChange={handleChangeSelectMember}
                        />
                    </FormControl>
                    <FormControl className={`${classes.formControlCustomSelect} ${classes.itemGridSpan1}`} variant="standard">

                        <CustomSelect
                            name='withdrawalReason'
                            label='MOTIVO DE BAJA'
                            list={withdrawalReason}
                            selected={reasonSelected}
                            onChange={handleChangeSelectMotive}
                        />
                        <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.reasonSelected}</FormHelperText>
                    </FormControl>
                    <FormControl className={`${classes.formControlDatePicker} ${classes.itemGridSpan1}`} >
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                style={{ top: "15px" }}
                                className={`${classes.inputLabelSelectForms} ${props.inputLabelClass}`}
                                shrink htmlFor="bootstrap-input"
                            >
                                {"FECHA DE LA BAJA"}
                            </InputLabel>
                            <div style={{ marginTop: "30px" }} className={classes.top22}>
                                <LocalizationProvider
                                    locale={es}
                                    dateAdapter={AdapterDateFns}
                                >
                                    <MobileDatePicker
                                        cancelText="Cancelar"
                                        toolbarTitle=""
                                        format="dd/MM/YYYY"
                                        value={dateLow}
                                        shouldDisableDate={(dateRes) => dateRes < new Date(dateRes.getFullYear(), dateRes.getMonth() + 1, 0)}
                                        disablePast={true}
                                        onChange={(newValue) => { setDateLow(newValue); }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    {<FormHelperText className={classes.errorText}>{"La fecha debe ser cada final de mes"}</FormHelperText>}
                                </LocalizationProvider>
                            </div>
                        </FormControl>
                    </FormControl>
                    <FormControl className={`${classes.formControlAddress} ${classes.itemGridSpan2} `} variant="standard">
                        <CustomizedInputs
                            value={mail?.trimEnd()}
                            name='mail'
                            label='E-MAIL'
                            disabled={disabledEmail}
                            onChange={handleChangeMail(setMail, setMailError, setError, error, setShowPermanente, fullPersonalData?.fullData?.afiliado?.mails?.filter(z => z.mailTipoCodigo == 1)[0]?.mail)}
                            endAdornment={
                                <InputAdornment position="start">
                                    <IconButton onClick={handleEditEmail} size='small'>
                                        <EditIcon />
                                    </IconButton>
                                </InputAdornment>
                            }

                        />
                        {showPermanente &&
                            <FormControlLabel style={{ paddingLeft: '5%', fontSize: '11px' }}
                                control={
                                    <Checkbox checked={emailChecked} onClick={handleCheck} color='primary' />}
                                label="¿E-MAIL PERMANENTE?" />
                        }
                        <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.mail}</FormHelperText>
                        <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{mailError}</FormHelperText>
                    </FormControl>

                    <FormControl className={`${classes.formControlAddress} ${classes.itemGridSpan2} `} variant="standard">
                        <CustomizedInputs
                            value={`${phone}`}
                            label='TELEFONO'
                            name='phone'
                            disabled={disabledPhone}
                            onChange={handleChangePhone}
                            endAdornment={
                                <InputAdornment position="start">
                                    <IconButton onClick={handleEditPhone}>
                                        <EditIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                            inputProps={{ maxLength: 20 }}
                        />
                        <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.phone}</FormHelperText>


                    </FormControl>
                    <FormControl className={classes.commentGrid} variant="standard">
                        <CustomizedInputs
                            name="consulta"
                            label='COMENTARIOS'
                            placeholder='Escribe tu texto aqui'
                            multiline
                            rows={5}
                            inputProps={{ maxLength: 2000 }}
                            onChange={(e) => { setQuery(e.target.value); }}
                            value={query}
                        />
                    </FormControl>

                    <section className={classes.itemGridAdjuntarArchivos}>
                        <Typography style={{ flex: '1 1 100%', justifyContent: 'center' }} className={`${classes.txtObservaciones} ${classes.txtSubtitle}`}>ADJUNTAR ARCHIVOS</Typography>
                        <span style={{ flex: '1 1 100%', fontSize: '10px', display: 'flex' }}>
                            {`(TAMAÑO MAXIMO ${configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor}MB)`}
                        </span>
                        {listOfAttachmentButtons?.map((item, i) => (
                            <Fragment key={i}>
                                <CustomButton
                                    name={item.adjuntoDescripcion}
                                    style={{ padding: '5px 15px 5px 5px' }}
                                    title={`ADJUNTAR ${item.adjuntoDescripcion}`}
                                    icon={<AttachFileIcon />}
                                    customClass={`${classes.lightLinearGradientReverse} ${classes.marginLeft}`}
                                    onClick={handleOpenModal(item.adjuntoDescripcion, item.adjuntoObligatorio)}
                                    disabled={isDisabled(fileButtons, item.adjuntoDescripcion)}
                                />
                                {item.adjuntoModelo != '' &&
                                    <IconButton
                                    href={`${configurationReduxState?.filter(x => x?.archivoNombre === item?.adjuntoModelo)?.[0]?.valor}`}
                                        target={"_blank"}
                                        rel={"noopener noreferrer"}>
                                        <Typography className={`${classes.txtSubtitleBlue}`}>
                                            descargar Modelo
                                        </Typography>
                                    </IconButton>
                                }
                                {item.adjuntoInstructivo != '' &&
                                    <IconButton onClick={() => { dowloadFile(item.adjuntoInstructivo); }}>
                                        <Typography className={`${classes.txtSubtitleBlue}`}>
                                            descargar Instructivo
                                        </Typography>
                                    </IconButton>
                                }
                                {item.adjuntoObligatorio === 'S' &&
                                    <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>*Archivo obligatorio</FormHelperText>
                                }
                            </Fragment>
                        ))}
                        {files?.map((item, i) => (
                            <p key={i} style={{ marginLeft: "5%" }} className={classes.txtSubtitleCalendar}>{item?.archivoNombre} <IconButton onClick={deleteFile(fileButtons, setFileButtons, setFiles, files, item?.btnNombre, item?.archivoNombre)}><HighlightOffIcon /></IconButton></p>
                        ))}
                    </section>


                    <ConfirmModal isOpen={openSendSuccess} setOpen={setOpenSendSuccess} errorClass={errorClass}
                        titleModal={
                            <>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    {modalIcon}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    {modalTitle}
                                </div>
                            </>
                        }
                        description={modalDescription} />
                    <div className={classes.width100}>
                        <UploadModal
                            className={classes.width100}
                            title='Adjuntar archivo'
                            description=''
                            observation=''
                            open={openUploadModal}
                            onClose={setOpenUploadModal}
                            setModalTitle={setModalTitle}
                            setModalDescription={setModalDescription}
                            setModalIcon={setModalIcon}
                            setErrorClass={setErrorClass}
                            setOpenSendSuccess={setOpenSendSuccess}
                            setFiles={setFiles}
                            files={files}
                            fileButtons={fileButtons}
                            setFileButtons={setFileButtons}
                            itemName={nameOfItem}
                            isRequired={itemRequired}
                        />
                        <SaveModal isOpen={openSendData} setOpen={setOpenSendData} onClick={handleSubmit} modalRegister={false} type='submit'
                            titleModal={
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        ¿Desea enviar los datos?
                                    </div>
                                </>
                            }
                            descriptionModal=""
                        />
                    </div>
                    <div className={`${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                        <CustomButton
                            disabled={showBtn(listOfAttachmentButtons, files, error, reasonSelected)}
                            customClass={classes.lightLinearGradient}
                            style={{ padding: '5px 20px 5px 20px' }}
                            title='Enviar'
                            type='button'
                            onClick={() => setOpenSendData(prevState => !prevState)} />
                    </div>
                </>
                :
                <FormHelperText className={classes.errorText}>{"La facturacion del titular es por empresa. por favor consultá con tu empresa"}</FormHelperText>}
        </>
    );
};

export default UnsubscribeMember;


function validateSelect(valueName, setError, error) {
    let response = false;
    if (Object.values(valueName).length > 0) {
        response = true;
    }
    if (!response) {
        setError({ ...error, "reasonSelected": '*Campo obligatorio' });
    }
    return response;
}

function setWithdrawalReasonFunction(data, setWithdrawalReason, MotivosBaja) {
    if (data?.lowMotives != null) {
        setDataOfWS(data?.lowMotives?.motivosBaja, MotivosBaja, setWithdrawalReason, 'motivoBajaCodigo', 'motivoBajaDescripcion');
    }
}


function setDataOfWS(wsList, hardcodeList, setter, nameValue, nameLabel) {
    let auxReason = [];
    let auxList = [];
    if (wsList?.length === 0) {
        auxList = hardcodeList;
    } else {
        auxList = wsList;
    }
    auxList?.forEach(item => {
        let auxObj = {
            value: item[nameValue],
            label: item[nameLabel]
        };
        auxReason.push(auxObj);
    });
    setter(auxReason);
}

function validateSubmitFunction(props) {
    const { listOfAttachmentButtons, files, date, dateLow, error, reasonSelected, setError,
        processSelected, memberSelected, mail, showPermanente, phone, query,
        setModalTitle, setModalDescription, setModalIcon, setErrorClass, setOpenSendSuccess, dispatch, setOpenSendData } = props;
    if (validateFiles(listOfAttachmentButtons, files)) {
        let filesToSend = files.map((item) => {
            delete (item['btnNombre']);
            delete (item["adjuntoObligatorio"]);
            return item;
        });
        if (validateDate(date, dateLow) && validateData(error) && validateSelect(reasonSelected, setError, error)) {
            let parametro = {
                NumeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                SolicitudBajaCodigo: processSelected,
                NumeroPersonaBaja: memberSelected,
                SolicitudBajaFecha: dateLow.toISOString(),
                MotivoBajaCodigo: reasonSelected,
                Mail: mail,
                MailModificar: showPermanente ? "S" : "N",
                Telefono: phone,
                Consulta: query,
                Adjuntos: JSON.stringify(filesToSend)
            };
            let circularProgress = (<div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress color="inherit" /></div>);
            setModalTitle('INICIANDO GESTION');
            setModalDescription(circularProgress);
            setModalIcon('');
            setErrorClass('');
            setOpenSendSuccess(prevState => !prevState);
            setOpenSendData(false);
            dispatch(postRequestLow(parametro));
        }
    }
}