import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../../../assets/styles/styles";
import CustomButton from "../../../controls/Button/CustomButton";
import { setModelToSaveAppointment } from "../../../redux/actions/appointmentsActions";
import { Typography } from "@material-ui/core";

const SelectHour = ({ selectedDate }) => {
  const classes = useStyles();
  const doctorSchedule = useSelector(
    (state) => state.appointmentsReducer.nextAvailableAppointmentsDoctor
  );
  const [modelAppointment, setHour] = useState(selectedDate);
  const dispatch = useDispatch();
  const [freeAppointment, setFreeAppointment] = useState([]);

  const handleClickHour = (hour, turnoNumero) => (e) => {
    e.preventDefault();
    setHour({
      ...modelAppointment,
      hourSelected: hour,
      turnoNumero: turnoNumero,
    });
    dispatch(
      setModelToSaveAppointment({
        ...selectedDate,
        hourSelected: hour,
        turnoNumero: turnoNumero,
      })
    );
  };

  const setActive = (item) => {
    let cssClass = "";
    if (item === modelAppointment?.hourSelected){
      cssClass = classes.hourSelected;
    }
    return cssClass;
  };

  useEffect(() => {
    const fechaHora =
      `${selectedDate.selectedAppointment}T${selectedDate.hourSelected}`;
    const hourAppointment = doctorSchedule?.turnosLibres?.filter(
      (x) => x.turnoFechHora === fechaHora
    );
    if (hourAppointment.length > 0) {
      setHour({
        ...selectedDate,
        hourSelected: hourAppointment[0]?.turnoFechHora.split("T")[1],
        turnoNumero: hourAppointment[0]?.turnoNumero,
      });
      dispatch(
        setModelToSaveAppointment({
          ...selectedDate,
          hourSelected: hourAppointment[0]?.turnoFechHora.split("T")[1],
          turnoNumero: hourAppointment[0]?.turnoNumero,
        })
      );
    } else {
      setHour({ ...selectedDate, hourSelected: "", turnoNumero: 0 });
      dispatch(
        setModelToSaveAppointment({
          ...selectedDate,
          hourSelected: "",
          turnoNumero: 0,
        })
      );
    }
  }, [selectedDate.selectedAppointment]);

  useEffect(() => {
    let freeAppointments = [];
    if (modelAppointment?.modalidadTurno === 'Telemedicina') {
      freeAppointments = doctorSchedule?.turnosLibres?.filter(element => element?.tipoAtencion === "R");
    } else {
      freeAppointments = doctorSchedule?.turnosLibres?.filter(element => element?.tipoAtencion === "P");
    }
    setFreeAppointment(freeAppointments)
  }, [doctorSchedule]);

  return (
    <>
      {doctorSchedule?.turnosLibres?.filter(
        (x) =>
          x.turnoFechHora.split("T")[0] === selectedDate.selectedAppointment &&
          x.estadoTurno === "DISPONIBLE"
      ).length > 0 && (
        <Typography
          className={`${classes.txtBold500} ${classes.grayTxt}`}
          style={{ marginTop: "10px " }}
        >
          Seleccioná horario
        </Typography>
      )}
      {modelAppointment?.hourSelected === "" && (
        <span className={classes.spanError}>*Complete el campo</span>
      )}
      <div
        className={`${classes.boxPointsDrawer} ${classes.backgroundToLightGray}`}
        style={{ flexWrap: "wrap" }}
      >
        {freeAppointment
          ?.filter(
            (x) =>
              x.turnoFechHora.split("T")[0] ===
                selectedDate.selectedAppointment &&
              x.estadoTurno === "DISPONIBLE"
          )
          .map((item, i) => (
            <CustomButton
              key={i}
              onClick={handleClickHour(
                item.turnoFechHora.split("T")[1],
                item.turnoNumero
              )}
              title={item.turnoFechHora.split("T")[1].substring(0, 5)}
              customClass={`${classes.noneBoxShadow} ${
                classes.hourBtn
              } ${setActive(item.turnoFechHora.split("T")[1])}`}
            />
          ))}
      </div>
    </>
  );
};

export default SelectHour;
