import { FormControl, FormHelperText } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CustomSelect from '../../../../controls/Select/CustomSelect'
import TopButtons from '../../../AskTurn/Web/Buttons/TopButtons'
import useStyles from '../../../../assets/styles/styles'
import { useResizeScreen } from '../../../../hooks/useResizeScreen'
import FooterDrawer from '../../../AskTurn/FooterDrawer'
import { setModel, getParametersRefunds } from '../../../../redux/actions/refundsActions'
import { Typography } from '@mui/material'
import Loading from '../../../LoadingPage/Loading'
import { validatorForms } from '../../../../utils/utilsFunctions';
import CustomButton from '../../../../controls/Button/CustomButton'
import InfoIcon from '@material-ui/icons/Info';
import LineBreakInput from "../../../../controls/InputCustom/LineBreakInput.jsx";

const propertiesValidations = [
    {
        name: 'afiliadoBeneficiario',
        type: 'number',
        required: true
    },
    {
        name: 'tipoReintegroCodigo',
        type: 'number',
        required: true
    }];

const AddRefundStep1 = ({ activeStep, handleOpenCancelModal, handleBack, setActiveStep }) => {

    const { loading, parametersRefunds, modelRequest } = useSelector(state => state.refundsReducer);
    const [modelToSend, setModelToSend] = useState({ ...modelRequest });
    const { groupFamilyTurn } = useSelector((state) => state.appointmentsReducer);
    const { allConfigurations } = useSelector(state => state.configurationReducer);
    const [groupFamilyList, setGroupFamilyList] = useState();
    const [typeAtentionList, setTypeAtentionList] = useState();
    const [error, setError] = useState({ numeroPersona: '', tipoReintegroCodigo: '' });
    const classes = useStyles();
    const dispatch = useDispatch();
    const { screen } = useResizeScreen();
    const [textInfo, setTextInfo] = useState('');
    const [counterLength, setCounterLength] = useState(0);


    useEffect(() => {
        dispatch(getParametersRefunds());
    }, []);

    const handleNext = () => {

        if (validateModelStep1()) {
            dispatch(setModel(modelToSend));
            setActiveStep(activeStep + 1);
        }
    };

    const handleChange = (e) => {
        setModelToSend({ ...modelToSend, [e.target.name]: `${e.target.value}` });
        setError({ ...error, [e.target.name]: "" });
    }

    const handleChangeObservations = (e) => {
        const { value } = e.target;
        if (value.length <= 200) {
            setModelToSend({ ...modelToSend, [e.target.name]: value });
            setCounterLength(value.length);
        }
    };

    useEffect(() => {

        const listGroupFamily = groupFamilyTurn.map((item) => {
            return { value: item?.itemCodigo, label: item?.itemDescripcion };
        });
        setGroupFamilyList(listGroupFamily);
    }, [groupFamilyTurn]);

    useEffect(() => {

        const listTypeAtention = parametersRefunds?.tipoReintegros?.map((item) => {
            return { value: item?.tipoReintegroCodigo, label: item?.tipoReintegroDescripcion };
        });
        setTypeAtentionList(listTypeAtention);
    }, [parametersRefunds]);

    const validateModelStep1 = () => {
        const { auxErrors, valid } = validatorForms(propertiesValidations, modelToSend);

        setError(auxErrors)

        return valid;
    }

    useEffect(() => {
        if (modelToSend?.tipoReintegroCodigo != null && parametersRefunds?.tipoReintegros != null) {
            const typeRefund = parametersRefunds?.tipoReintegros?.find(x => x.tipoReintegroCodigo === modelToSend?.tipoReintegroCodigo);
            setTextInfo(typeRefund?.tipoReintegroTextoDetalle);
        }else{
            setTextInfo('');
        }
    }, [modelToSend?.tipoReintegroCodigo])

    const textInfoMargin = () => {
        let margin = '';
        if(screen.sWidth < 321) {
            margin = '20px';
        } else if(screen.sWidth < 500) {
            margin = '40px';
        } else if(screen.sWidth < 900) {
            margin = '50px';
        }
        return margin;
    }

    const observationsLabel = ({ sWidth }) => (
        <span style={{ marginTop: "-35px", marginRight: sWidth < 768 ? "190px" :  "360px" }}>OBSERVACIONES</span>
    );

    return (
        <Fragment>
            {screen.sWidth > 768 &&
                <TopButtons handleOpenCancelModalTurn={handleOpenCancelModal} handleBackTurn={handleBack} activeStepTurn={activeStep} handleNextTurn={handleNext} titleCancel={"Cancelar"} titleConfirm={"Siguiente"} />
            }
            {
                loading ?
                    (<Loading isLoading={loading} />)
                    :
                    (
                        <section style={{ display: 'flex', padding: '5px', flexWrap: 'wrap', marginTop: "40px" }}>
                            <FormControl className={`${classes.formControlCustomSelect} ${classes.formBiggerSelect} `} variant="standard">
                                <CustomSelect
                                    name='afiliadoBeneficiario'
                                    required={true}
                                    label='AFILIADO'
                                    list={groupFamilyList}
                                    onChange={handleChange}
                                    selected={modelToSend?.afiliadoBeneficiario}
                                />
                                <FormHelperText className={classes.errorText}>{error['afiliadoBeneficiario']}</FormHelperText>
                            </FormControl>
                            <FormControl className={`${classes.formControlCustomSelect} ${classes.formBiggerSelect} `} variant="standard">
                                <CustomSelect
                                    name='tipoReintegroCodigo'
                                    required={true}
                                    label='TIPO DE ATENCION'
                                    list={typeAtentionList}
                                    onChange={handleChange}
                                    selected={modelToSend?.tipoReintegroCodigo}
                                />
                                <FormHelperText className={classes.errorText}>{error['tipoReintegroCodigo']}</FormHelperText>
                                <FormHelperText className={classes.infoText}  style={{ width: 'auto', marginLeft: textInfoMargin() }}>{textInfo}</FormHelperText>
                                <a
                                    href={allConfigurations?.configurations?.filter(x => x.id === 'informacionReintegros')[0]?.valor}
                                    download="informacionReintegros.pdf"
                                    style={{
                                        textDecoration: "none",
                                        textAlign: screen.sWidth < 900 && 'center' 
                                    }}
                                    target="_blank"
                                >
                                    <CustomButton
                                        icon={<InfoIcon style={{marginRight:'5px'}} />}
                                        title={'REQUISITOS DOCUMENTACION PDF'}
                                        customClass={`${classes.lightLinearGradient}`}
                                        style={{
                                            fontSize: "11px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            height: "35px",
                                            width: "250px",
                                            textAlign: 'center',
                                            margin: '15px 0'
                                          }}
                                    />
                                </a>
                            </FormControl>
                            <FormControl variant="standard" className={classes.widthMobile} style={{ width: "100%" ,marginLeft:"10px", marginTop:"30px"}}>
                                <LineBreakInput
                                    formControlClass={`${classes.txtObservations}`}
                                    inputLabelClass={classes.txtLabelComments}
                                    customClass={classes.txtBoxObservation}
                                    name="observacion"
                                    label={observationsLabel(screen)}
                                    placeholder='Escribe tu texto aqui'
                                    value={modelToSend?.observacion}
                                    onChange={handleChangeObservations}
                                    multiline
                                    style={{
                                        left: screen.sWidth < 768 ? "10px" : "-2px",
                                        borderRadius: 29,
                                        position: 'relative',
                                        border: '1px solid #ced4da',
                                        fontSize: 16,
                                        minWidth: '224px !important',
                                        margin: '0 0 7px 0',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: "nowrap",
                                    }}
                                    rows={5}
                                    inputProps={{ maxLength: 200 }}
                                />
                                <Typography variant="caption" style={{ marginLeft: screen.sWidth < 768 ? "250px" : '450px', }}>{counterLength}/200</Typography>
                            </FormControl>
                        </section>
                    )
            }
            {
                screen.sWidth <= 768 &&
                <FooterDrawer activeStep={activeStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} handleNext={handleNext} customClassDiv={classes.footerAuthRequest} confirm={true} />
            }
        </Fragment>
    )
}

export default AddRefundStep1 