import React from "react";
import { clearModelAuthRequest, setModelAuthRequest } from '../../../../redux/actions/authorizationsActions';
import { useHistory } from "react-router";
import CustomButton from '../../../../controls/Button/CustomButton';
import useStyles from '../../../../assets/styles/styles';
import { useDispatch } from "react-redux";
import { Typography } from '@material-ui/core';
import ModalProfile from "../../../../components/DialogProfile/Modal";


const MobileAuthorizationsButtons = ({ openCancelModal, setOpenCancelModal, handleNext, activeStep }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const handleCancelModal = () => {
        setOpenCancelModal(prevState => !prevState);
    };

    const handleClose = () => {
        dispatch(clearModelAuthRequest());
        dispatch(setModelAuthRequest({}))
        history.push('/Authorizations');
    };

    return (
        <>
            <ModalProfile
                type='cancel'
                title={<div style={{ display: 'flex', justifyContent: 'center' }}>Cancelar autorización</div>}
                observation={
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography>¿Está seguro que desea salir?</Typography>
                    </div>
                }
                description={
                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <CustomButton
                            className={`${classes.buttonConfirmModal} ${classes.lightLinearGradient}`}
                            title={"Si"}
                            onClick={handleClose}
                        />
                        <CustomButton
                            customClass={`${classes.buttonNotConfirmModal}`}
                            title={"No"}
                            onClick={handleCancelModal}
                        />
                    </div>
                }
                onClose={setOpenCancelModal}
                open={openCancelModal}
            />

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CustomButton
                    customClass={`${classes.whiteAndBlueBtn} ${classes.fontBold}`}
                    title={'Cancelar'}
                    onClick={handleCancelModal}
                    style={{ minWidth: "100px" }}
                />
                <CustomButton
                    customClass={`${classes.lightLinearGradient} ${classes.fontBold}`}
                    title={activeStep === 2 ? 'Confirmar' : 'Siguiente'}
                    onClick={handleNext}
                    style={{ minWidth: "100px", fontSize: "12px" }}
                />
            </div>
        </>
    );
};

export default MobileAuthorizationsButtons;
