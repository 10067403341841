import React, { useState, Fragment } from 'react';
import { FormControl, Typography, FormHelperText, Divider } from "@material-ui/core";
import useStyles from '../../../assets/styles/styles';
import CustomizedInputs from "../../../controls/InputCustom/CustomizedInputs";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import CustomButton from '../../../controls/Button/CustomButton';
import SaveModal from '../SaveModal';
import ConfirmModal from '../ConfirmModal';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CustomSelect from '../../../controls/Select/CustomSelect';
import NotFound from '../../HomeLogged/NotFound';
import { useSelector } from 'react-redux';

const AutomaticMembershipForm = ({ membershipData, setMembershipData, disabled, handleBack, setDisabled }) => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [error, setError] = useState({
        tarjetaCreditoTipoDescripcion: '',
        debitoBancoDescripcion: '',
        tarjetaCreditoVencimientoAnio: '',
        tarjetaCreditoVencimientoMes: '',
        debitoTipoCodigo: '',
        debitoNumeroCuenta: '',
        debitoCBU: ''
    });
    const data = useSelector(state => state.personalDataReducer?.fullData);
    const tipos = [{ value: 'V', label: "VISA" }, { value: 'C', label: "MASTER CARD" }, { value: 'W', label: 'AMEX' }];

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate(membershipData, setError)) {
            setOpen(prevState => !prevState);
            setOpenConfirm(prevState => !prevState);
        }
    };
    
    const handleChangeInput = (position) => (e) => {
        const { name, value } = e.target;
        const newArray = membershipData.map((item, i) => {
            if (position === i) {
                validateTarjetaVencimiento(name, value);
            } else {
                return item;
            }
        });
        setMembershipData(newArray);
    };

    const handleChangeSelect = (position) => (e) => {
        const { name, value } = e.target;
        const codeName = name.split('-')[0];
        const descriptionName = name.split('-')[1];
        const description = tipos.filter(x => x.value === value);
        const newArray = membershipData.map((item, i) => {
            if (position === i) {
                return { ...item, [codeName]: value, [descriptionName]: description[0].label, };
            } else {
                return item;
            }
        });
        setMembershipData(newArray);
    };

    const handleClickModal = () => {
        if (validate(membershipData, setError)) {
            setOpen(prevState => !prevState);
        }
    };

    const creditCardComponent = (item, i) => {
        return (
            <Fragment>
                <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm} ${classes.divSubtitleEditBtn}`}>
                    <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.subtitleAddressForm}`}>
                        Tarjeta de credito
                    </Typography>
                </div>
                <FormControl className={classes.formControlCustomSelect} variant="standard">
                    <CustomSelect
                        name='tarjetaCreditoTipoCodigo-tarjetaCreditoTipoDescripcion'
                        disable={disabled}
                        list={tipos} selected={item?.tarjetaCreditoTipoCodigo}
                        position={i} onChange={handleChangeSelect(i)}
                        label={"TIPO DE TARJETA"} />
                    <FormHelperText className={classes.errorText}>{error[i]?.tarjetaCreditoTipoDescripcion}</FormHelperText>
                </FormControl>
                <FormControl className={classes.formControlAddress} variant="standard">
                    <CustomizedInputs
                        id=''
                        name='debitoBancoDescripcion'
                        value={item.debitoBancoDescripcion !== '' ? item.debitoBancoDescripcion : 'No especifica'}
                        onChange={handleChangeInput(i)}
                        disabled={disabled}
                        label='BANCO EMISOR' />
                    <FormHelperText className={classes.errorText}>{error[i]?.debitoBancoDescripcion}</FormHelperText>
                </FormControl>
                <FormControl className={classes.formControlAddress} variant="standard">
                    <CustomizedInputs
                        id='tarjetaCreditoVencimientoMes'
                        name='tarjetaCreditoVencimientoMes/tarjetaCreditoVencimientoAnio'
                        value={`${item.tarjetaCreditoVencimientoMes}/${item.tarjetaCreditoVencimientoAnio}`}
                        onChange={handleChangeInput(i)}
                        disabled={disabled}
                        label='VENCIMIENTO' />
                    <FormHelperText className={classes.errorText}>{error[i]?.tarjetaCreditoVencimientoMes}{error[i]?.tarjetaCreditoVencimientoAnio}</FormHelperText>
                </FormControl>
            </Fragment>
        );
    };

    const debitAccountComponent = (item, i) => {
        return (
            <Fragment>
                <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm} ${classes.divSubtitleEditBtn}`}>
                    <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.subtitleAddressForm}`}>
                        CBU DEBITO EN CTA
                    </Typography>
                </div>
                <FormControl className={classes.formControlAddress} variant="standard">
                    <CustomizedInputs
                        id=''
                        name='debitoTipoDescripcion'
                        value={item.debitoTipoDescripcion}
                        onChange={handleChangeInput(i)}
                        disabled={disabled}
                        label='TIPO DE CUENTA' />
                    <FormHelperText className={classes.errorText}>{error[i]?.debitoTipoDescripcion}</FormHelperText>
                </FormControl>
                <FormControl className={classes.formControlAddress} variant="standard">
                    <CustomizedInputs
                        id=''
                        name='debitoBancoDescripcion'
                        value={item.debitoBancoDescripcion !== '' ? item.debitoBancoDescripcion : 'No especifica'}
                        onChange={handleChangeInput(i)}
                        disabled={disabled}
                        label='BANCO EMISOR' />
                    <FormHelperText className={classes.errorText}>{error[i]?.debitoBancoDescripcion}</FormHelperText>
                </FormControl>
                <FormControl className={classes.formControlAddress} variant="standard">
                    <CustomizedInputs
                        id=''
                        name='debitoNumeroCuenta'
                        value={item.debitoNumeroCuenta}
                        onChange={handleChangeInput(i)}
                        disabled={disabled}
                        label='NUMERO DE CUENTA'
                        type='password' />
                    <FormHelperText className={classes.errorText}>{error[i]?.debitoNumeroCuenta}</FormHelperText>
                </FormControl>
                {screen.sWidth < 1300 ?
                    <FormControl className={classes.formControlAddress} variant="standard">
                        <CustomizedInputs
                            id=''
                            name='debitoCBU'
                            value={item.debitoCBU}
                            onChange={handleChangeInput(i)}
                            disabled={disabled}
                            label='CBU'
                            type='password' />
                        <FormHelperText className={classes.errorText}>{error[i]?.debitoCBU}</FormHelperText>
                    </FormControl>
                    :
                    <FormControl className={classes.formControlAddress} variant="standard" style={{ marginLeft: "100%" }}>
                        <CustomizedInputs
                            id=''
                            name='debitoCBU'
                            value={item.debitoCBU}
                            onChange={handleChangeInput(i)}
                            disabled={disabled}
                            label='CBU'
                            type='password' />
                        <FormHelperText className={classes.errorText}>{error[i]?.debitoCBU}</FormHelperText>
                    </FormControl>
                }
            </Fragment>
        );
    };

    const validateDebit = (item, i) => {
        let response = '';
        if (item?.tarjetaCreditoTipoCodigo !== '' && item?.tarjetaCreditoVencimientoMes !== '' && item?.tarjetaCreditoVencimientoAnio !== '') {
            response = creditCardComponent(item, i);
        } else {
            response = debitAccountComponent(item, i);
        }
        return response;
    };

    const validateAutomaticDebit = (debit) => {
        return debit.some(function(item) {
            return item.debitoTipoDescripcion !== '' && item.debitoNumeroCuenta !== '' ;
        });
    }

    return <>
        <form onSubmit={handleSubmit} className={classes.addressForm}  style={{ marginLeft: screen.sWidth <= 768 ? '0' : '300px', padding: '20px', display: 'inline-block',  }}>
            {data?.afiliado?.debitosAutomaticos?.length > 0 && (validateAutomaticDebit(data?.afiliado?.debitosAutomaticos)) ?
                (membershipData.map((item, i) => (
                    <Fragment key={i}>
                        {validateDebit(item, i)}
                    </Fragment>
                ))) : (
                    <Fragment>
                        <NotFound
                        customMsg={
                            <div className={classes.contentNotFound}>
                                <span>No tenés adhesión al débito automático</span>
                            </div>}
                        customClass={classes.flexStartJustifyContent}
                        wsItem={[]}
                        fullItem={[]}
                    />
                    </Fragment>
                )}
            <div className={`${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                {screen.sWidth <= 768 ?
                    <Divider style={{marginBottom: "80px", color: "#fff", border: "0"}} />
                    : ''
                }
                {!disabled &&
                    <div>
                        <CustomButton customClass={classes.lightLinearGradient} style={{ padding: '5px 20px 5px 20px' }} title='Guardar' onClick={handleClickModal} />
                    </div>
                }
            </div>
            <SaveModal isOpen={open} setOpen={setOpen} type='submit' onClick={handleSubmit}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            Enviado correctamente
                        </div>
                    </>
                }
                descriptionModal=
                "Si modificaste tus datos personales en breve recibirás la confirmación del cambio, luego de validarse la misma por parte del sector de Afiliaciones."
            />
        </form>
        <ConfirmModal isOpen={openConfirm} setOpen={setOpenConfirm}
            titleModal={
                <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        Enviado correctamente
                    </div>
                </>
            }
            description=
            {"Los cambios han sido realizados, una vez validados por nuestro sector correspondiente observarás los cambios en el sistema."} />
    </>;
};

export default React.memo(AutomaticMembershipForm);


function validate(membership, setError) {
    const temp = membership;
    const aux = [];
    const validation = [];
    Object.values(temp).forEach((item, i) => {
        let membershipObj = {};
        membershipObj.tarjetaCreditoTipoDescripcion = item.tarjetaCreditoTipoDescripcion ? '' : '*Campo obligatorio';
        membershipObj.debitoBancoDescripcion = item.debitoBancoDescripcion ? '' : '*Campo obligatorio';
        membershipObj.tarjetaCreditoVencimientoAnio = item.tarjetaCreditoVencimientoAnio ? '' : '*Año obligatorio';
        membershipObj.tarjetaCreditoVencimientoMes = item.tarjetaCreditoVencimientoMes ? '' : '*Mes obligatorio';
        membershipObj.debitoTipoCodigo = item.debitoTipoCodigo ? '' : '*Campo obligatorio';
        membershipObj.debitoNumeroCuenta = item.debitoNumeroCuenta ? '' : '*Campo obligatorio';
        membershipObj.debitoCBU = item.debitoCBU ? '' : '*Campo obligatorio';
        aux.push(membershipObj);
        if (Object.values(aux[i]).every((x) => x === "")) {
            validation.push(true);
        } else {
            validation.push(false);
        }
    });
    setError(aux);
    return Object.values(validation).every((valid) => valid === true);
}

function validateTarjetaVencimiento(propName, propValue) {
    let aux = '';
    if (propName === 'tarjetaCreditoVencimientoMes/tarjetaCreditoVencimientoAnio') {
        aux = { ...item, [propName.split('/')[0]]: propValue.split('/')[0] ?? '', [propName.split('/')[1]]: propValue.split('/')[1] ?? '' };
    }
    else {
        aux = { ...item, [propName]: propValue };
    }
    return aux;
}