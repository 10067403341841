import React from 'react';
import { CircularProgress } from '@material-ui/core';

const SpinnerLoading = () => {
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', flex: '0 0 100%', paddingTop: '10px' }}>
                <CircularProgress />
            </div>
        </>
    )
}

export default SpinnerLoading