import React, { Fragment, useEffect, useState } from 'react';
import CustomButton from '../../../controls/Button/CustomButton';
import ConfirmModal from '../../PersonalData/ConfirmModal';
import SaveModal from '../../PersonalData/SaveModal';
import { FormControl, Button, FormHelperText, Switch, FormGroup, FormControlLabel, Typography } from '@material-ui/core';
import CustomizedInputs from '../../../controls/InputCustom/CustomizedInputs';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';

import useStyles from '../../../assets/styles/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMessageModal from '../../../hooks/useMessageModal';
import CustomSelect from '../../../controls/Select/CustomSelect';
import { addABMItem, addAllergy, cleanSelect, removeItem, validate } from './Utils/utilsFuncions';
import { abmAllergies } from '../../../redux/actions/medicalDataAction';


const AllergiesForm = ({ allergy, setAllergy, addItemScreen, setAddItemScreen }) => {
    const classes = useStyles();
    const errorMessage = useSelector(state => state?.medicalDataReducer?.msgError);
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const [error, setError] = useState(
        {
            alergiaCodigo: '',
            alergiaDescripcion: '',
            alergiaDetalle: ''
        },
    );
    const defaultModal = {
        defaultModalTitle: 'Enviado correctamente',
        defaultModalDescription: 'Los cambios han sido realizados.',
        defaultModalIcon: <CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />,
        defaultModalErrorClass: ''
    };
    const setMessageModal = {
        setModalTitle: 'Ocurrió un error',
        setModalDescription: 'Ocurrio un error al enviar los datos.',
        setModalIcon: <CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />,
        setModalErrorClass: classes.backgroundError
    };
    const { modalTitle, modalDescription, modalIcon, modalErrorClass } = useMessageModal(defaultModal, setMessageModal, errorMessage);
    const allergiesAffiliates = useSelector(state => state.medicalDataReducer.affiliatedAllergies);
    const allergiesParams = useSelector(state => state.medicalDataReducer.allergiesParams);
    const abmAllergiesSelector = useSelector(state => state.medicalDataReducer.abmAllergies);

    const [typeOfAllergies, setTypeOfAllergies] = useState([]);
    const [switchState, setSwitchState] = useState(false);

    const [addInput, setAddInput] = useState('');
    const [addSelect, setAddSelect] = useState([{
        alergiaCodigo: 0,
        alergiaDescripcion: '',
        alergiaDetalle: ''
    }]);
    const [flag, setFlag] = useState(false);
    const [remove, setRemove] = useState('');
    const [swStatus, setSwState] = useState(null);

    useEffect(() => {
        if (abmAllergiesSelector?.confirmacion) {
            setOpenConfirm(false);
            setOpen(false);
        }
    }, [abmAllergiesSelector?.confirmacion])

    useEffect(() => {
        let listParameters = [];
        allergiesParams?.alergia?.map((item, i) => (
            listParameters.push({ value: item.alergiaDescripcion, label: item.alergiaDescripcion })
        ));
        listParameters?.push({ value: "OTRA", label: "OTRA" })
        setTypeOfAllergies(listParameters);
    }, []);

    const handleSwitchChange = (e) => {
        const { checked } = e.target;
        let switchStatus = 'N';
        if (checked) {
            switchStatus = 'S';
        }
        dispatch(abmAllergies({
            numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
            alergiaDescripcion: "",
            shockAnafilactico: switchStatus,
            modo: "M"
        }));
        setSwState(switchStatus);
        setSwitchState(prevState => !prevState);
    };

    const handleChangeInput = (e) => {
        const { value } = e.target;
        let allergyToAdd = {
            alergiaCodigo: 'OTRA',
            alergiaDescripcion: 'OTRA',
            alergiaDetalle: value
        };
        setAddInput(allergyToAdd);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate(addInput, addSelect, setError, flag)) {
            if (flag) {
                let allergies = addAllergy(addSelect, addInput, allergy);
                let item = addABMItem(addSelect, addInput);
                setAllergy(allergies);
                dispatch(abmAllergies({
                    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                    alergiaDescripcion: item,
                    shockAnafilactico: swStatus,
                    modo: "A"
                }));
                setAddItemScreen(prevState => !prevState);
            } else {
                removeItem(setAllergy, allergy, remove);
                let item = remove.alergiaDescripcion;
                if (item.alergiaDescripcion === 'OTRA')
                    item = remove.alergiaDetalle;
                dispatch(abmAllergies({
                    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                    alergiaDescripcion: item,
                    shockAnafilactico: swStatus,
                    modo: "B"
                }));
                setRemove('');
            }
            cleanSelect(setAddSelect, setAddInput);
            setOpen(false);
        }
    };

    const handleClickModal = () => {
        if (validate(addInput, addSelect, setError, true)) {
            setFlag(true);
            setOpen(prevState => !prevState);
        }
    };

    const handleRemoveItem = (allergyItem) => (e) => {
        e.preventDefault();
        setRemove(allergyItem);
        setOpen(prevState => !prevState);
        setFlag(false);
    };

    const handleChangeSelect = (listToFilter) => (e) => {
        const { value } = e.target;
        let description = listToFilter?.filter(x => x.value === value);
        let allergySelected = [{
            alergiaCodigo: value,
            alergiaDescripcion: value,
            alergiaDetalle: ''
        }];
        setAddSelect(allergySelected);
    };

    const handleAddItem = (e) => {
        e.preventDefault();
        if (validate(addInput, addSelect, setError, true)) {
            let allergies = addAllergy(addSelect, addInput, allergy);
            setAllergy(allergies);
            let item = addABMItem(addSelect, addInput);

            dispatch(abmAllergies({
                numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                alergiaDescripcion: item,
                shockAnafilactico: swStatus,
                modo: "A"
            }));
            cleanSelect(setAddSelect, setAddInput);
            setOpenConfirm(prevState => !prevState);
        }
    };

    useEffect(() => {
        setSwitchState(allergiesAffiliates?.shockAnafilactico === 'S');
        setSwState(allergiesAffiliates?.shockAnafilactico);
    }, [allergiesAffiliates?.shockAnafilactico]);

    useEffect(() => {
        let listParameters = [];
        allergiesParams?.alergia?.map((item, i) => (
            listParameters.push({ value: item.alergiaDescripcion, label: item.alergiaDescripcion })
        ));
        listParameters?.push({ value: "OTRA", label: "OTRA" })
        setTypeOfAllergies(listParameters)
    }, [allergiesParams?.alergia]);

    const handleShowAddItemScreen = (e) => {
        e.preventDefault();
        window.location.reload();
    };

    return (
        <>
            <form onSubmit={handleSubmit} className={classes.chronicDiseaseform} style={{ justifyContent: 'flex-start' }}>
                {!addItemScreen ?
                    <>
                        <FormControl required component="fieldset" className={`${classes.formControlsEmergency} ${classes.formControlSelect} ${classes.formControlSwitchAllergies}`}>
                            <FormGroup>
                                <FormControlLabel
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            onChange={handleSwitchChange}
                                            color="primary"
                                            name='shockAnafilactico'
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                            checked={switchState}
                                        />}
                                    label={
                                        <Typography className={`${classes.txtFilter} ${classes.txtEllipsis}`}>ANTECEDENTES DE SHOCK ANAFILACTICO</Typography>
                                    }
                                />
                            </FormGroup>
                        </FormControl>
                        {allergy?.map((item, i) => (
                            <Fragment key={i}>
                                    <div className={`${classes.divEmergencyPhonesForm} ${classes.divCustomSelectHobbies} ${classes.divAllergiesForm}`} >
                                        <div className={classes.divFormControlsEmergency}>
                                            <CustomizedInputs name={`alergiaDetalle-${i}`} value={item?.alergiaDetalle || item?.alergiaDescripcion} disabled={true} />
                                        </div>
                                        <div className={classes.quarterCol} style={{ justifyContent: 'center' }}>
                                            <Button onClick={handleRemoveItem(item)} >
                                                <DeleteOutlineIcon className={classes.ShowMoreArrowIcon} style={{ color: '#002858' }} />
                                            </Button>
                                        </div>
                                    </div>
                            </Fragment>
                        ))}
                    </>
                    :
                    <>
                        <div className={`${classes.divEmergencyPhonesForm} ${classes.divCustomSelectHobbies} ${classes.divAllergiesForm}`} >
                            <div className={classes.divFormControlsEmergency}>
                                <>
                                    <FormControl className={`${classes.formControlsEmergency} ${classes.formControlSelect}`} variant="standard">
                                        <CustomSelect name={`alergiaCodigo-alergiaDescripcion/0`} list={typeOfAllergies} selected={addSelect[0]?.alergiaCodigo} onChange={handleChangeSelect(typeOfAllergies)} />
                                        <FormHelperText className={classes.errorText}>{error[0]?.alergiaDescripcion}</FormHelperText>
                                    </FormControl>
                                    {addSelect[0]?.alergiaDescripcion === 'OTRA' &&
                                        <FormControl className={`${classes.formControlsEmergency}`} variant="standard">
                                            <CustomizedInputs name={`alergiaDetalle-0`} value={addInput?.alergiaDetalle} label='ESPECIFICAR' onChange={handleChangeInput} inputProps={{ maxLength: 30 }} />
                                            <FormHelperText className={classes.errorText}>{error[0]?.alergiaDetalle}</FormHelperText>
                                        </FormControl>
                                    }
                                </>
                            </div>
                        </div>
                        <Button onClick={handleAddItem} >
                            <AddCircleOutlinedIcon className={`${classes.ShowMoreArrowIcon}`} />
                        </Button>

                    </>
                }
                <div className={`${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                    {addItemScreen &&
                        <div style={{ marginBottom: "50px" }}>
                            <CustomButton
                                customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                                title='cancelar'
                                onClick={handleShowAddItemScreen}
                                style={{ marginRight: "10px", marginLeft: "45px" }}
                            />
                            <CustomButton
                                customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                                style={{ padding: '5px 20px 5px 20px', fontWeight: "bold", fontSize: "10px" }}
                                title='Guardar'
                                onClick={handleClickModal}
                            />
                        </div>
                    }
                </div>
                <SaveModal isOpen={open} setOpen={setOpen} type='submit' onClick={handleSubmit}
                    titleModal={
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                Confirmar envío de datos
                            </div>
                        </>
                    }
                    descriptionModal="¿Deseas confirmar los cambios?"
                />
            </form>
            <ConfirmModal isOpen={openConfirm} setOpen={setOpenConfirm} errorClass={modalErrorClass}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalIcon}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalTitle}
                        </div>
                    </>
                }
                description={modalDescription} />
        </>
    );
};

export default React.memo(AllergiesForm);
