import { CircularProgress, FormControl, FormHelperText, IconButton, InputAdornment, Typography } from '@material-ui/core';
import React, { useEffect, useState, useLayoutEffect } from 'react';
import CustomizedInputs from '../../../../controls/InputCustom/CustomizedInputs';
import EditIcon from '@material-ui/icons/Edit';
import useStyles from "../../../../assets/styles/styles";
import { useDispatch, useSelector } from 'react-redux';
import ModalActivities from "../../../DialogActivities/ModalActivities";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { validate } from "../../../../utils/utilsFunctions";

import TopButtons from "../../../AskTurn/Web/Buttons/TopButtons";
import { useResizeScreen } from '../../../../hooks/useResizeScreen';
import { postRequest } from '../../../../redux/actions/authorizationsActions';
import { cleanMsgError } from '../../../../redux/actions/errorActions';
import MobileAuthorizationsButtons from './MobileAuthorizationsButtons';

const Step3 = (props) => {
    const { handleOpenCancelModal, activeStep, handleBack } = props;
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const dispatch = useDispatch();
    const [mail, setMail] = useState('');
    const [phone, setPhone] = useState('');
    const [disabledPhone, setDisabledPhone] = useState(true);
    const [disabledEmail, setDisabledEmail] = useState(true);
    const fullPersonalData = useSelector(state => state.personalDataReducer);
    const authSelectorModel = useSelector((state) => state.authorizationsReducer?.modelRequest);
    const requestSelector = useSelector((state) => state.authorizationsReducer?.postRequest);
    const paramsAuthorizations = useSelector((state) => state.authorizationsReducer?.params);
    const errorReduxState = useSelector((state) => state.authorizationsReducer?.msgError);

    const [openModal, setOpenModal] = useState(false);
    const [openModalError, setOpenModalError] = useState(false);
    const [error, setError] = useState([]);
    const [mailError, setMailError] = useState('');
    const [model, setModel] = useState();
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const today = new Date();
    const [loading, setLoading] = useState(false);
    const [cancelAuthorization, setCancelAuthorization] = useState(false);
    const [filesQuantity, setFilesQuantity] = useState(undefined);

    const handleChangePhone = () => e => {
        const { name, value } = e.target;
        setPhone(value);
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    const handleEditEmail = () => {
        setDisabledEmail(prevState => !prevState);
    };

    const handleEditPhone = () => {
        setDisabledPhone(prevState => !prevState);
    };

    useLayoutEffect(() => {
        dispatch(cleanMsgError());

        if (authSelectorModel?.adjuntos) {
            setFilesQuantity(JSON.parse(authSelectorModel?.adjuntos));
        }
    }, [])

    useEffect(() => {
        let phoneUser = fullPersonalData?.fullData?.afiliado?.telefonos[0]?.telefono;
        setPhone(phoneUser);
        let mailUser = fullPersonalData?.fullData?.afiliado?.mails[0]?.mail.trim();
        setMail(mailUser);
    }, [fullPersonalData?.fullData?.afiliado]);

    const handleNext = () => {
        setterModel(model, setModel, authSelectorModel, phone, mail, today);
        setOpenModalConfirm(true);
    };

    const handleSubmit = () => {
        setLoading(true);
        dispatch(postRequest(model));
    };

    useEffect(() => {
        if (requestSelector?.confirmacion) {
            setOpenModalConfirm(false);
            setOpenModal(true);
            setLoading(false);
        }
        else if (!requestSelector?.confirmacion && requestSelector?.confirmacion != undefined) {
            setOpenModalConfirm(false);
            setOpenModalError(true);
            setLoading(false);
        }
    }, [requestSelector]);

    useEffect(() => {

        if (loading && errorReduxState?.length > 0) {
            setOpenModalConfirm(false);
            setOpenModalError(true);
            setLoading(false);
        }

    }, [errorReduxState]);

    useEffect(() => {
        if (openModal) {
            setTimeout(() => { window.location.replace('/Authorizations'); }, 3000);
        }
    }, [openModal]);

    const getPlazoHoras = () => {
        const plazo = paramsAuthorizations?.tipoAutorizaciones?.filter(x => x.tipoAutorizacionCodigo == authSelectorModel.TipoAutorizacionCodigo)[0]?.tipoAutorizacionPlazo;

        return plazo ? plazo : '48'
    }

    const handleSave = (e) => {
        const isValid = validateEmail(mail)
        if (isValid) {
            setMailError('')
            handleNext(e)
        }
        else {
            setMailError('No cumple con el formato permitido: mail@mail.com');
        }
    }

    const handleChangeMail = (e) => {
        const { value } = e.target;

        setMail(value);
    };

    const validateEmail = email => {
        const regex = /[a-z0-9!#$%&'*{1,5}/=?^_`{|}~-]{1,5}(?:\.[a-z0-9!#$%&'*{1,5}/=?^_`{|}~-]{1,5})*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.){1,5}[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email);

        return regex
    }

    return (
        <>
            {
                screen.sWidth > 768 &&
                <>
                    <TopButtons
                        handleOpenCancelModalTurn={handleOpenCancelModal}
                        handleBackTurn={handleBack}
                        activeStepTurn={activeStep}
                        handleNextTurn={handleSave}
                        step={2}
                        titleCancel={"ANULAR SOLICITUD"}
                        titleConfirm={"CREAR SOLICITUD"} />
                </>
            }

            <div className={classes.divConfirmAuthorization} >
                <Typography className={classes.titleConfirmAuthorization}>Iniciar solicitud de autorización</Typography>
                <Typography className={classes.subtitleConfirmAuthorization}>Los plazos habituales para tu autorización son: </Typography>
                <Typography className={classes.titleConfirmAuthorization} style={{ fontWeight: "400" }}>
                    {getPlazoHoras()}
                </Typography>
                <Typography className={classes.subtitleConfirmAuthorization}>
                    Estos plazos pueden modificarse según la complejidad de la práctica y su aprobación está sujeta a evaluación de Auditoría Médica. De ser necesario, se requerirá documentación adicional
                </Typography>
            </div>
            <Typography className={`${classes.titleTurn} ${classes.titleSecondary}`} >DATOS DE CONTACTO</Typography>
            <div className={classes.formConfirmAuthorization} >
                <FormControl className={`${classes.formControlAddress} ${classes.itemGridSpan2}`} variant="standard">
                    <CustomizedInputs
                        formControlClass={classes.width70}
                        value={phone}
                        label='TELEFONO'
                        name='telefono'
                        disabled={disabledPhone}
                        onChange={handleChangePhone()}
                        endAdornment={
                            <InputAdornment position="start">
                                <IconButton onClick={handleEditPhone}>
                                    <EditIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                        inputProps={{ maxLength: 20 }}
                    />
                    <FormHelperText className={classes.errorText}>{error?.telefono}</FormHelperText>
                </FormControl>
                <FormControl className={`${classes.formControlAddress} ${classes.itemGridSpan2}`}  >
                    <CustomizedInputs
                        formControlClass={classes.width70}
                        label={"E-MAIL"}
                        value={mail}
                        name='mail'
                        disabled={disabledEmail}
                        onChange={handleChangeMail}
                        endAdornment={
                            <InputAdornment position="start">
                                <IconButton onClick={handleEditEmail}>
                                    <EditIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText className={classes.errorText}>{error?.mail}</FormHelperText>
                    <FormHelperText className={classes.errorText}>{mailError}</FormHelperText>
                </FormControl>
            </div>
            {
                openModalConfirm &&
                <ModalActivities
                    title={
                        loading &&
                        <>
                            <span>Espere un momento...</span>
                        </>
                    }
                    description={
                        loading
                        ?
                            <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}>
                                <CircularProgress color={'inherit'} />
                            </div>
                        :
                            <div style={{ textAlign: 'center' }}>
                                {
                                    filesQuantity &&
                                    <Typography>
                                        {`Adjuntaste ${filesQuantity?.length} ${filesQuantity?.length === 1 ? 'archivo' : 'archivos'}`}
                                    </Typography>
                                }
                                
                                <Typography style={{ marginTop: '20px' }}>¿Estás seguro de confirmar el alta de la solicitud?</Typography>
                            </div>
                    }
                    open={openModalConfirm}
                    onClose={setOpenModalConfirm}
                    modalconfirm={loading ? false : true}
                    modalLogout={false}
                    buttonType={'submit'}
                    handleClick={handleSubmit}
                />
            }
            {openModal &&
                <ModalActivities
                    title={
                        <section style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                            <CheckCircleIcon style={{ fontSize: "50px" }} className={classes.quickAccessIcon} />
                            <div style={{ display: 'flex', justifyContent: 'center', flex: '1 1 100%', marginTop: '30px' }}>
                                <span>TU SOLICITUD SE CREO CON EXITO</span>
                            </div>
                        </section>
                    }
                    description={
                        <section style={{ display: 'flex', justifyContent: 'center', flexWrap: 'nowrap' }}>
                            {''}
                        </section>}
                    open={openModal}
                    onClose={setOpenModal}
                    modalconfirm={false}
                    modalLogout={false}
                />
            }
            {openModalError &&
                <ModalActivities
                    title={
                        <section style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                            <CancelIcon style={{ fontSize: "50px" }} className={classes.quickAccessIcon} />
                            <div style={{ display: 'flex', justifyContent: 'center', flex: '1 1 100%' }}>
                                <span>{errorReduxState}</span>
                            </div>
                        </section>
                    }
                    open={openModalError}
                    onClose={setOpenModalError}
                    modalconfirm={false}
                    modalLogout={false}
                />
            }
            {screen.sWidth < 768 &&
                <MobileAuthorizationsButtons
                    cancelAuthorization={cancelAuthorization}
                    setCancelAuthorization={setCancelAuthorization}
                    handleNext={handleNext}
                    activeStep={activeStep}
                />
            }
        </>
    );
};

export default Step3;

function setterModel(model, setModel, authModel, phone, mail, today) {
    const realizationFields = ['FECHA DEL TURNO', 'FECHA ESTIMADA', 'FECHA DE APLICACION'];
    const prescriptionFields = ['FECHA DE ORDEN MEDICA / PRESCRIPCION', 'FECHA DE LA ORDEN / PRESCRIPCION', 'FECHA DE LA ORDEN', 'FECHA ORDEN MEDICA / PRESCRIPCION'];
    const realizationLocationFields = ['LUGAR', 'LUGAR DEL TURNO / PRESTADOR', 'LUGAR DE ENTREGA', 'LUGAR DEL TURNO', 'ORTOPEDIA / DOMICILIO', 'DOMICILIO DE ENTREGA'];
    const prescriptorPhoneFields = ['TELEFONO PROFESIONAL'];
    const prescriptorEmailFields = ['MAIL PROFESIONAL'];
  
    const getFieldValue = (fieldNames) => {
      for (const fieldName of fieldNames) {
        const value = authModel[fieldName];
        if (value) {
          return value;
        }
      }
      return null;
    };
  
    const realizationDate = getFieldValue(realizationFields) ?? today.toISOString();
    const prescriptionDate = getFieldValue(prescriptionFields) ?? today.toISOString();
    const realizationLocation = getFieldValue(realizationLocationFields) ?? '';
    const prescriptorPhone = getFieldValue(prescriptorPhoneFields) ?? '';
    const prescriptorEmail = getFieldValue(prescriptorEmailFields) ?? '';

    setModel({
      ...model,
      numeroPersona: authModel?.numeroPersona,
      afiliadoBeneficiario: authModel?.numeroPersona,
      tipoAutorizacionCodigo: authModel?.TipoAutorizacionCodigo?.toString(),
      observacion: authModel?.observacion ?? '',
      realizacionFecha: realizationDate,
      realizacionLugar: realizationLocation,
      prescripcionFecha: prescriptionDate,
      prescripcionMatricula: authModel['Matricula de Prescriptor'] ?? '',
      prescriptorNombre: authModel['Nombre de Prescriptor'] ?? '',
      prescriptorEmail: prescriptorEmail,
      prescriptorTelefono: prescriptorPhone,
      contactoEmail: mail,
      contactoTelefono: phone,
      adjuntos: authModel?.adjuntos ?? '',
    });
}
