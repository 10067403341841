import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetHeadquaters() {
    try {
        var response = yield call(API.getHeadquaters);
        if (response)
            yield put({ type: actionTypes.SUCCESS_GET_ALL_HEADQUATERS, response, message: '', });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_GET_ALL_HEADQUATERS,
            message: error.message,
        });
    }
}


// Watchers
export default function* sedesSaga() {
    yield takeLatest(actionTypes.GET_ALL_HEADQUATERS, asyncGetHeadquaters);
}
