import * as actionTypes from "../consts/actionTypes";


export function getDataWheightHeight(param) {
  return {
    type: actionTypes.GET_DATA_WHEIGHT_HEIGHT,
    payload: param,
  };
}

export function ABWheightHeight(param) {
  return {
    type: actionTypes.AB_DATA_WHEIGHT_HEIGHT,
    payload: param,
  };
}


export function ABvitalParameters(param) {
  return {
    type: actionTypes.AB_VITAL_PARAMETERS,
    payload: param,
  };
}
export function getVitalParameters(param) {
  return {
    type: actionTypes.GET_VITAL_PARAMETERS,
    payload: param,
  };
}

export function getAffiliateEnrollments(param) {
  return {
    type: actionTypes.GET_PROGRAMS,
    payload: param,
  };
}

export function getAllergiesParams() {
  return {
    type: actionTypes.GET_ALLERGIES_PARAMS,
    payload: ''
  };
}

export function getAffiliatedAllergies(affiliated) {
  return {
    type: actionTypes.GET_AFFILIATED_ALLERGIES,
    payload: affiliated
  };
}

export function abmAllergies(allergy) {
  return {
    type: actionTypes.ABM_ALLERGIES,
    payload: allergy
  };
}

export function getMDPhones(affiliated) {
  return {
    type: actionTypes.GET_AFFILIATED_PHONES,
    payload: affiliated
  };
}

export function abmMDPhones(affiliated) {
  return {
    type: actionTypes.ABM_MD_PHONES,
    payload: affiliated
  };
}


export function getLaboratoryStudies(param) {
  return {
    type: actionTypes.GET_LABORATORY_STUDIES,
    payload: param,
  };
}

export function getDetailLaboratoryStudies(param) {
  return {
    type: actionTypes.GET_DETAIL_LABORATORY_STUDIES,
    payload: param,
  };
}

export function abmFactorGroup(affiliated) {
  return {
    type: actionTypes.ABM_FACTOR_GROUP,
    payload: affiliated
  };
}

export function getFactorGroup(param) {
  return {
    type: actionTypes.GET_FACTOR_GROUP,
    payload: param,
  };
}

export function getGroupFactorParams() {
  return {
    type: actionTypes.GET_FACTOR_GROUP_PARAMS,
    payload: ''
  };
}

export function abmChronicDiseases(affiliated) {
  return {
    type: actionTypes.ABM_CHRONIC_DISEASES,
    payload: affiliated
  };
}

export function getChronicDiseases(param) {
  return {
    type: actionTypes.GET_CHRONIC_DISEASES,
    payload: param,
  };
}

export function getChronicDiseasesParams() {
  return {
    type: actionTypes.GET_CHRONIC_DISEASES_PARAMS,
    payload: ''
  };
}

export function abmChronicMedication(affiliated) {
  return {
    type: actionTypes.ABM_CHRONIC_MEDICATION,
    payload: affiliated
  };
}

export function getChronicMedication(param) {
  return {
    type: actionTypes.GET_CHRONIC_MEDICATION,
    payload: param,
  };
}

export function getVaccine(param) {
  return {
    type: actionTypes.GET_VACCINE,
    payload: param,
  };
}

export function getPharmaceuticalForm() {
  return {
    type: actionTypes.GET_PHARMACEUTICAL_FORM,
    payload: ''
  };
}


export function getRecipe(param) {
  return {
    type: actionTypes.GET_RECIPE,
    payload: param,
  };
}

