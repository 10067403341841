import * as actionTypes from "../consts/actionTypes";

const initialValues = {
  phoneList: [],
  phoneShift: [],
  msgError: ''
};

export default function phonesReducer(state = initialValues, action) {

  const ACTIONS = {
    [actionTypes.FAILURE_PHONES]: responseToReturn(),
    [actionTypes.FAILURE_SHIFT_PHONES]: responseToReturn()
  };

  function responseToReturn() {
    let res = { ...state };
    if (action.message) {
      res = { ...state, msgError: action.message };
    }
    return res;
  }

  if (ACTIONS[action.type] !== undefined) {
    return ACTIONS[action.type];
  }

  switch (action.type) {
    case actionTypes.SUCCES_PHONES:
      if (action.response) {
        return { ...state, phoneList: action.response.data.usefulPhone };
      } else {
        return { ...state };
      }
    case actionTypes.SUCCES_SHIFT_PHONES:
      if (action.response) {
        return { ...state, phoneShift: action.response.data.phoneShift };
      } else {
        return { ...state };
      }
    default:
      return { ...state };
  }
}