import { useEffect, useState } from 'react';
import useStyles from "../../../assets/styles/styles";
import { CircularProgress, FormControl, InputLabel } from "@material-ui/core";
import { useSelector, useDispatch } from 'react-redux';
import CustomSelect from '../../../controls/Select/CustomSelect';
import { getProcedures, getPermisionLow } from '../../../redux/actions/procedureActions';
import GeneralProcedure from './GeneralProcedure';
import Unsubscribe from './Unsubscribe';
import ChangePlan from './ChangePlan';
import { useResizeScreen } from "../../../hooks/useResizeScreen";

const listProcedureGeneral = [
    { value: 0, label: "TRAMITES GENERALES" },
    { value: 1, label: "SOLICITUD DE BAJA" },
    { value: 2, label: "CAMBIO DE PLAN" }
];

const FormAffiliations = ({ handleBack }) => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const dispatch = useDispatch();
    const data = useSelector(state => state.procedureReducer);
    const [procedureGeneral, setProcedureGeneral] = useState(-1);
    const [permission, setPermission] = useState(false);
    const [listOfProcess, setListOfProcess] = useState([{ value: 0, label: '' }]);

    const handleChangeSelectProcedureGeneral = e => {
        const { value } = e.target;
        setProcedureGeneral(value);
    };

    useEffect(() => {
        dispatch(getProcedures('AFILIACIONES'));
        dispatch(getPermisionLow({ NumeroPersona: window.localStorage.getItem("numeroPersona") }));
    }, []);

    useEffect(() => {
        if (data.permissionLow != null) {
            setPermission(data.permissionLow?.habilitado);
        }
    }, [data.permissionLow]);

    useEffect(() => {
        let process = [];
        data?.procedures?.tramites?.map(item => {
            let obj = {
                value: item.tramiteCodigo,
                label: item.tramiteDescripcion
            };
            process.push(obj);
        });
        process?.unshift({ value: "", label: "SELECCIONE UNA OPCION" })
        setListOfProcess(process);
    }, [data?.procedures]);

    function showLabel(condition) {
        var response;
        if (condition > 768) {
            response = (
                <InputLabel
                    id="demo-simple-select-filled-label"
                    style={{ display: "relative", top: "17%", left: "8%" }}>
                    {procedureGeneral === -1 ? listOfProcess[0].label : ""}
                </InputLabel>
            );
        } else {
            response = (
                <InputLabel
                    id="demo-simple-select-filled-label"
                    style={{ display: "relative", top: "8px", left: "50px" }}>
                    {procedureGeneral === -1 ? listOfProcess[0].label : ""}
                </InputLabel>
            );
        }
        return response;
    }

    return (
        <>
            <form className={`${classes.nuevosFormGrid}`} style={{ marginTop: "10px" }} >
                {
                    listOfProcess?.length > 1 ?
                        <FormControl
                            className={`${classes.formControlCustomSelect} ${classes.itemGridSpan2}`}
                            variant="standard"
                            style={{right:'20px'}}
                        >
                            {showLabel(screen.sWidth)}
                            <CustomSelect
                                labelId="demo-simple-select-filled-label"
                                list={listOfProcess}
                                onChange={handleChangeSelectProcedureGeneral}
                                label={"TRAMITE"}
                            />
                        </FormControl>
                        :
                        <div style={{ width: "25%" }} className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}>
                            <CircularProgress />
                        </div>
                }
                {
                    (procedureGeneral > 2 || procedureGeneral === 0) &&
                    <GeneralProcedure handleBack={handleBack} procedureCodeSelected={procedureGeneral} />
                }
                {
                    procedureGeneral === 1 &&
                    <Unsubscribe handleBack={handleBack} permission={permission} />
                }
                {
                    procedureGeneral === 2 &&
                    <ChangePlan handleBack={handleBack} permission={permission} />
                }
            </form>
        </>
    );
};
export default FormAffiliations;

