import * as actionTypes from '../consts/actionTypes';

const initialState = {
    campaign: [],
    msgError: ''
};

export default function campaignReducer(state = initialState, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_GET_CAMPAIGN]: responseToReturn(),
        [actionTypes.FAILURE_GET_CAMPAIGN]: responseToReturn()
    };
    function responseToReturn() {
        let res = { ...state };
        if (action.response) {
            res = { ...state, campaign: action.response.data.campaigns, msgError: action.message };
        }
        if (action.message) {
            res = { ...state, msgError: action.message };
        }
        return res;
    }

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}