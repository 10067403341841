import React, { useState, Fragment, useEffect } from "react";
import {
  Button,
  FormControl,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import useStyles from "../../../assets/styles/styles";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import CustomButton from "../../../controls/Button/CustomButton";
import SaveModal from "../SaveModal";
import ConfirmModal from "../ConfirmModal";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CustomSelect from "../../../controls/Select/CustomSelect";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useDispatch, useSelector } from "react-redux";
import {
  abmFullData,
  getDeportes,
  getFullData,
  getHobbies,
} from "../../../redux/actions/personalDataActions";
import CancelIcon from "@material-ui/icons/Cancel";

const SportsHobbiesForm = ({
  personalData,
  setPersonalData,
  disabled,
  handleBack,
  handleEdit
}) => {
  const classes = useStyles();
  const { screen } = useResizeScreen();
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [hobbieSportsABM, setHobbieSportsABM] = useState({
    hobbies: [],
    deportes: [],
  });
  const dispatch = useDispatch();
  const [modelABM, setModelABM] = useState(personalData);
  const fullPersonalData = useSelector((state) => state.personalDataReducer);

  const [hobbieTypes, setHobbieTypes] = useState([]);
  const [sportsTypes, setSportsTypes] = useState([]);

  const [hobbieSelected, setHobbieSelected] = useState("");
  const [sportSelected, setSportSelected] = useState("");

  const [modalTitle, setModalTitle] = useState("Enviado correctamente");
  const [modalDescription, setModalDescription] = useState(
    "Tus datos han sido modificados en el sistema."
  );
  const [modalIcon, setModalIcon] = useState(
    <CheckCircleOutlineIcon
      className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`}
    />
  );
  const [errorClass, setErrorClass] = useState("");

  const [error, setError] = useState(
    {
      hobbieCodigo: "",
      hobbieDescripcion: "",
    },
    {
      deporteCodigo: "",
      deporteDescripcion: "",
    }
  );

  const handleChangeHobbie = (e) => {
    const { value } = e.target;
    setHobbieSelected(value);
  };

  const handleChangeSport = (e) => {
    const { value } = e.target;
    setSportSelected(value);
  };

  useEffect(() => {
    const listAux = [];
    fullPersonalData?.deportes?.hobbiesDeportes?.forEach(item => {
      let objectAux = {
        value: item.hobbiesDeportesCodigo,
        label: item.hobbiesDeportesDescripcion
      };
      listAux.push(objectAux);
    });
    setSportsTypes(listAux);
  }, [fullPersonalData?.deportes]);

  useEffect(() => {
    const listAux = [];
    fullPersonalData?.hobbies?.hobbiesDeportes?.forEach(item => {
      let objectAux = {
        value: item.hobbiesDeportesCodigo,
        label: item.hobbiesDeportesDescripcion
      };
      listAux.push(objectAux);
    });
    setHobbieTypes(listAux);
  }, [fullPersonalData?.hobbies]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate(personalData, setError)) {
      let form = modelABM;
      form.documentos = [];
      form.domicilios = [];
      form.telefonos = [];
      form.mails = [];
      form.adjuntos = [];
      delete form.cuentasReintegro;
      let modelToSend = {
        informacion: form,
        numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
      };
      dispatch(abmFullData(modelToSend));
      setOpen((prevState) => !prevState);
      setOpenConfirm((prevState) => !prevState);
    }
    handleEdit();
  };

  const handleClickModal = () => {
    if (hobbieSelected !== "") {
      let aux = personalData?.hobbies;
      let auxDescription = hobbieTypes?.filter(x => x.value === hobbieSelected)[0]?.label;
      let newObj = {
        ["hobbieCodigo"]: hobbieSelected,
        ["hobbieDescripcion"]: auxDescription,
      };
      aux?.push(newObj);
      AddToABMModel(
        "hobbieCodigo",
        "hobbieModo",
        hobbieSelected,
        hobbieSportsABM.hobbies,
        "hobbies",
        "A"
      );
      setPersonalData({ ...personalData, ["hobbies"]: aux });
      setSportSelected("");
      setHobbieSelected("");
    }
    if (sportSelected !== "") {
      let aux = personalData?.deportes;
      let auxDescription = sportsTypes?.filter(x => x.value === sportSelected)[0]?.label;
      let newObj = {
        ["deporteCodigo"]: sportSelected,
        ["deporteDescripcion"]: auxDescription,
      };
      aux?.push(newObj);
      AddToABMModel(
        "deporteCodigo",
        "deporteModo",
        sportSelected,
        hobbieSportsABM.deportes,
        "deportes",
        "A"
      );
      setPersonalData({ ...personalData, ["deportes"]: aux });
      setSportSelected("");
      setHobbieSelected("");
    }
    if (validate(personalData, setError)) {
      let deportes = hobbieSportsABM.deportes;
      let hobbies = hobbieSportsABM.hobbies;
      setModelABM({ ...modelABM, hobbies, deportes });
      setOpen((prevState) => !prevState);
    }
  };

  useEffect(() => {
    setArrayList(personalData, setHobbieTypes, setSportsTypes);
    dispatch(getHobbies("HOBBIE"));
    dispatch(getDeportes("DEPORTE"));
    dispatch(getFullData({
      numeroPersona: parseInt(window.localStorage.getItem("numeroPersona"))
    }));
  }, []);

  const handleRemoveItem =
    (
      code,
      collection,
      propName,
      collectionToUse,
      abmCollection,
      propNameDescription,
      modo
    ) =>
      (e) => {
        e.preventDefault();
        let aux = collection?.filter((x) => x[propName] !== code);
        setPersonalData({ ...personalData, [collectionToUse]: aux });
        AddToABMModel(
          propName,
          modo,
          code,
          abmCollection,
          collectionToUse,
          "B"
        );
      };

  const handleAddItem =
    (
      collection,
      propNameCode,
      propNameDescription,
      collectionToUse,
      listItem,
      abmCollection,
      listOfItems,
      modo
    ) =>
      (e) => {
        e.preventDefault();
        if (listItem !== "") {
          let aux = collection;
          let auxDescription = listOfItems?.filter(x => x.value === listItem)[0]?.label;
          let newObj = {
            [propNameCode]: listItem,
            [propNameDescription]: auxDescription,
          };
          aux?.push(newObj);
          AddToABMModel(
            propNameCode,
            modo,
            listItem,
            abmCollection,
            collectionToUse,
            "A"
          );
          setPersonalData({ ...personalData, [collectionToUse]: aux });
          setSportSelected("");
          setHobbieSelected("");
        }
      };


  function AddToABMModel(
    codeName,
    modo,
    value,
    abmCollection,
    collectionToUse,
    modoValue
  ) {
    let abmAux = abmCollection;
    let abmObj = {
      [codeName]: value,
      [modo]: modoValue,
    };
    abmAux?.push(abmObj);
    setHobbieSportsABM({ ...hobbieSportsABM, [collectionToUse]: abmAux });
  }

  useEffect(() => {
    setMessageModal(
      fullPersonalData,
      setModalTitle,
      setModalDescription,
      setModalIcon,
      setErrorClass,
      classes
    );
  }, [fullPersonalData]);


  return (
    <>
      <form onSubmit={handleSubmit} className={classes.addressForm} style={{ margin: screen.sWidth > 768 && "0 20%" }}>
        <div className={classes.divSportsHobbies}>
          <div
            className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm} ${classes.divSubtitleEditBtn}`}
          >
            <Typography
              className={`${classes.titleTurn} ${classes.titleBox} ${classes.subtitleAddressForm}`}
            >
              HOBBIES
            </Typography>
          </div>
          {personalData?.hobbies?.map((item, i) => (
            <Fragment key={i}>
              <FormControl
                style={{ flex: '0 0 100%' }}
                className={`${classes.formControlAddress} ${classes.formControlSportsHobbies}`}
                variant="standard"
              >
                <div className={classes.divCustomSelectHobbies}>
                  <CustomSelect
                    name={`hobbieCodigo-hobbieDescripcion/${i}`}
                    disable={true}
                    list={hobbieTypes}
                    selected={item.hobbieCodigo}
                    position={i}
                  />
                  {!disabled && (
                    <div style={{ width: "10% !important" }}>
                      <Button
                        onClick={handleRemoveItem(
                          item.hobbieCodigo,
                          personalData?.hobbies,
                          "hobbieCodigo",
                          "hobbies",
                          hobbieSportsABM?.hobbies,
                          "hobbieDescripcion",
                          "hobbieModo"
                        )}
                      >
                        <DeleteOutlineIcon
                          className={classes.ShowMoreArrowIcon}
                          style={{ color: "#002858" }}
                        />
                      </Button>
                    </div>
                  )}
                </div>
                <FormHelperText className={classes.errorText}>
                  {error[i]?.hobbieDescripcion}
                </FormHelperText>
              </FormControl>
            </Fragment>
          ))}
          {!disabled && (
            <FormControl
              style={{ flex: '0 0 100%' }}
              className={`${classes.formControlAddress} ${classes.formControlSportsHobbies}`}
              variant="standard"
            >
              <div className={classes.divCustomSelectHobbies}>
                <CustomSelect
                  name={`hobbieCodigo-hobbieDescripcion/`}
                  disable={disabled}
                  list={hobbieTypes}
                  selected={hobbieSelected}
                  onChange={handleChangeHobbie}
                />
                <div style={{ width: "10% !important" }}>
                  <Button
                    onClick={handleAddItem(
                      personalData?.hobbies,
                      "hobbieCodigo",
                      "hobbieDescripcion",
                      "hobbies",
                      hobbieSelected,
                      hobbieSportsABM?.hobbies,
                      hobbieTypes,
                      "hobbieModo"
                    )}
                  >
                    <ControlPointIcon className={classes.ShowMoreArrowIcon} />
                  </Button>
                </div>
              </div>
            </FormControl>
          )}
        </div>

        <div className={classes.divSportsHobbies}>
          <div
            className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm} ${classes.divSubtitleEditBtn}`}
          >
            <Typography
              className={`${classes.titleTurn} ${classes.titleBox} ${classes.subtitleAddressForm}`}
            >
              DEPORTES
            </Typography>
          </div>
          {personalData?.deportes?.map((item, i) => (
            <Fragment key={i}>
              <FormControl
                style={{ flex: '0 0 100%' }}
                className={`${classes.formControlAddress} ${classes.formControlSportsHobbies}`}
                variant="standard"
              >
                <div className={classes.divCustomSelectHobbies}>
                  <CustomSelect
                    name={`deporteCodigo-deporteDescripcion/${i}`}
                    disable={true}
                    list={sportsTypes}
                    selected={item.deporteCodigo}
                    position={i}
                  />
                  {!disabled && (
                    <div style={{ width: "10% !important" }}>
                      <Button
                        onClick={handleRemoveItem(
                          item.deporteCodigo,
                          personalData?.deportes,
                          "deporteCodigo",
                          "deportes",
                          hobbieSportsABM?.deportes,
                          "deporteDescripcion",
                          "deporteModo"
                        )}
                      >
                        <DeleteOutlineIcon
                          className={classes.ShowMoreArrowIcon}
                          style={{ color: "#002858" }}
                        />
                      </Button>
                    </div>
                  )}
                </div>
                <FormHelperText className={classes.errorText}>
                  {error[i]?.deporteDescripcion}
                </FormHelperText>
              </FormControl>
            </Fragment>
          ))}
          {!disabled && (
            <FormControl
              style={{ flex: '0 0 100%' }}
              className={`${classes.formControlAddress} ${classes.formControlSportsHobbies}`}
              variant="standard"
            >
              <div className={classes.divCustomSelectHobbies}>
                <CustomSelect
                  name={`deporteCodigo-deporteDescripcion`}
                  disable={disabled}
                  list={sportsTypes}
                  selected={sportSelected}
                  onChange={handleChangeSport}
                />
                <div style={{ width: "10% !important" }}>
                  <Button
                    onClick={handleAddItem(
                      personalData?.deportes,
                      "deporteCodigo",
                      "deporteDescripcion",
                      "deportes",
                      sportSelected,
                      hobbieSportsABM?.deportes,
                      sportsTypes,
                      "deporteModo"
                    )}
                  >
                    <ControlPointIcon className={classes.ShowMoreArrowIcon} />
                  </Button>
                </div>
              </div>
            </FormControl>
          )}
        </div>
        <div
          className={`${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}
        >
          {screen.sWidth < 768 && !disabled && (
            <div>
              <CustomButton
                customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                style={{ padding: "5px 20px 5px 20px", marginTop: "10px", marginBottom: "30px" }}
                title="Cancelar"
                type="button"
                onClick={handleEdit}
              />
            </div>
          )}
          {!disabled && (
            <div>
              <CustomButton
                customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                style={{ padding: "5px 20px 5px 20px", marginTop: "10px", marginBottom: "30px" }}
                title="Guardar"
                onClick={handleClickModal}
              />
            </div>
          )}
        </div>
        <SaveModal
          isOpen={open}
          setOpen={setOpen}
          type="submit"
          onClick={handleSubmit}
          titleModal={
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CheckCircleOutlineIcon
                  className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                Confirmar envío de datos
              </div>
            </>
          }
          descriptionModal="¿Deseas confirmar los cambios?"
        />
      </form>
      <ConfirmModal
        isOpen={openConfirm}
        setOpen={setOpenConfirm}
        errorClass={errorClass}
        titleModal={
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {modalIcon}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {modalTitle}
            </div>
          </>
        }
        description={modalDescription}
      />
    </>
  );
};

export default React.memo(SportsHobbiesForm);

function validate(hobbiesSportsPersonalData, setError) {
  let temp = hobbiesSportsPersonalData;
  let aux = [];
  let validation = [];
  Object.values(temp.deportes).forEach((item, i) => {
    let sportsObj = {};
    sportsObj.deporteCodigo = item.deporteCodigo ? "" : "*Campo obligatorio";
    sportsObj.deporteDescripcion = item.deporteDescripcion
      ? ""
      : "*Campo obligatorio";
    aux.push(sportsObj);
    if (Object.values(aux[i]).every((x) => x === "")) {
      validation.push(true);
    } else {
      validation.push(false);
    }
  });
  Object.values(temp.hobbies).forEach((item, i) => {
    let hobbiesObj = {};
    hobbiesObj.hobbieCodigo = item.hobbieCodigo ? "" : "*Campo obligatorio";
    hobbiesObj.hobbieDescripcion = item.hobbieDescripcion
      ? ""
      : "*Campo obligatorio";
    aux.push(hobbiesObj);
    if (Object.values(aux[i]).every((x) => x === "")) {
      validation.push(true);
    } else {
      validation.push(false);
    }
  });
  setError(aux);
  return Object.values(validation).every((valid) => valid === true);
}

function setArrayList(personalData, setHobbieTypes, setSportsTypes) {
  let hobbiesArray = [];
  let sportsArray = [];
  if (personalData?.hobbies.length > 0 || personalData?.deportes.length > 0) {
    personalData?.hobbies?.forEach((hobbie) => {
      let hobbieObj = {};
      hobbieObj.value = hobbie.hobbieCodigo;
      hobbieObj.label = hobbie.hobbieDescripcion;
      hobbiesArray.push(hobbieObj);
    });
    personalData?.deportes?.forEach((deporte) => {
      let deporteObj = {};
      deporteObj.value = deporte.deporteCodigo;
      deporteObj.label = deporte.deporteDescripcion;
      sportsArray.push(deporteObj);
    });
    setHobbieTypes(hobbiesArray);
    setSportsTypes(sportsArray);
  }
}

function setMessageModal(
  fullPersonalData,
  setModalTitle,
  setModalDescription,
  setModalIcon,
  setErrorClass,
  classes
) {
  if (
    Object.keys(fullPersonalData?.fullData).length > 0 &&
    !fullPersonalData?.fullData?.confirmacion
  ) {
    setModalTitle("Ocurrió un error");
    setModalDescription(fullPersonalData?.fullData?.mensajes[0]);
    setModalIcon(
      <CancelIcon
        className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`}
      />
    );
    setErrorClass(classes.backgroundError);
  }
}
