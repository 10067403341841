import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetDataRegisterUser({ payload }) {
  try {
    var response = yield call(API.getDataRegisterUser, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCESS_GET_DATA_REGISTER_USER,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_DATA_REGISTER_USER,
      message: error.message,
    });
  }
}

function* asyncAddDataRegisterUser({ payload }) {
  try {
    var response = yield call(API.addDataRegisterUser, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCESS_ADD_DATA_REGISTER_USER,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_ADD_DATA_REGISTER_USER,
      message: error.message,
    });
  }
}

function* asyncDeleteDataRegisterUser({ payload }) {
  try {
    var response = yield call(API.deleteDataRegisterUser, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCESS_DELETE_DATA_REGISTER_USER,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_DELETE_DATA_REGISTER_USER,
      message: error.message,
    });
  }
}

function* asyncRegisterUser({ payload }) {
  try {
    let response = yield call(API.registerAffiliate, payload);
    if (response) {
      yield put({ type: actionTypes.SUCCESS_REGISTER_USER, response, message: '' });
    }
  } catch (error) {
    yield put({ type: actionTypes.FAILURE_REGISTER_USER, message: error.message });
  }
}

function* asyncValidationRegister({ payload }) {
  try {
    let response = yield call(API.validationRegisterUser, payload);
    if (response) {
      yield put({ type: actionTypes.SUCCESS_VERIFY_REGISTER, response });
    }
  } catch (error) {
    yield put({ type: actionTypes.FAILURE_VERIFY_REGISTER, message: error });
  }
}

function* asyncModifyDataRegisterUser({ payload }) {
  try {
    var response = yield call(API.modifyDataRegisterUser, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCESS_MODIFY_DATA_REGISTER_USER,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_MODIFY_DATA_REGISTER_USER,
      message: error.message,
    });
  }
}


// Watchers
export default function* phonesSaga() {
  yield takeLatest(actionTypes.GET_DATA_REGISTER_USER, asyncGetDataRegisterUser);
  yield takeLatest(actionTypes.ADD_DATA_REGISTER_USER, asyncAddDataRegisterUser);
  yield takeLatest(actionTypes.DELETE_DATA_REGISTER_USER, asyncDeleteDataRegisterUser);
  yield takeLatest(actionTypes.MODIFY_DATA_REGISTER_USER, asyncModifyDataRegisterUser);
  yield takeLatest(actionTypes.REGISTER_USER, asyncRegisterUser);
  yield takeLatest(actionTypes.VERIFY_REGISTER, asyncValidationRegister);
}
