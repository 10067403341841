import React from "react";
import { useLocation } from "react-router-dom";
import ChronicDiseases from "../MedicalData/ChronicDiseases/ChronicDiseases";
import FactorGroup from "../MedicalData/FactorGroup/FactorGroup";
import Allergies from "../MedicalData/Allergies/Allergies";
import HomeWeight from "../MedicalData/WeightAndHeight/HomeWeight";
import ChronicMedication from "../MedicalData/ChronicMedication/ChronicMedication";
import Phones from "../MedicalData/Phones/Phones";
import AddressForm from "./Address/AddressForm";
import AutomaticMembership from "./AutomaticMembership/AutomaticMembership";
import FormContactData from './FormContactData';
import FormPersonalData from "./FormPersonalData";
import FormRefund from "./FormRefund";
import SportsHobbies from "./Sports&Hobbies/SportsHobbies";
import HomeVitalParameters from "../MedicalData/VitalParameters/HomeVitalParameters";
import Programs from "../MedicalData/Programs/Programs";
import Vaccine from "../MedicalData/Vaccine/Vaccine";
import RequirementsAndForms from "../MedicalData/Programs/RequirementsAndForms";
import LaboratoryStudies from "../MedicalData/LaboratoryStudies/LaboratoryStudies";
import MyCoveragePDF from "../MyCoverage/TravelCoverage/MyCoveragePDF";
import RedirectToCapsAndCopay from "../../pages/RedirectToCapsAndCopay/index";
import Refunds from "../MyCoverage/Refunds/Refunds";
import BillingAndCollection from "../Billing/BillingAndCollection";
import Affiliations from "./Affiliations/Affiliations";
import Movements from "../Billing/Movements/Movements";
import WayToPay from "../Billing/WayToPay/WayToPay";
import DirectDebit from "../Billing/DirectDebit/DirectDebit";
import AskNewAppointment from "../AskTurn/AskNewAppointment";
import MedicalRecordContainer from '../MedicalRecord/Container/MedicalRecordContainer';
import RequestAuthorization from "../Authorizations/NewRequest/RequestAuthorization";
import RefundsRequest from "../Refunds/RefundsRequest/RefundsRequest";
import BillingFrequentQuestions from "../Billing/BillingFrequentQuestions";
import PersonalDataFrequentQuestions from "./PersonalDataFrequentQuestions";
import RedirectToActivities from "../../pages/RedirectToActivities/index";
import PrescriptionPage from "../MedicalData/PrescriptionPage/PrescriptionPage";


const SelectTypeOfComponent = (props) => {
    const {
        handleBack, activeStepTurn, setActiveStepTurn,
        handleOpenCancelModalTurn, handleBackTurn, handleNextTurn,
        handleCloseDrawerTurn, showMaps, maps, specialistVisualizacion,
        setSpecialistVisualizacion
    } = props;
    const { search } = useLocation();
    const match = search.match(/type=(.*)/);
    const type = match?.[1];

    const responseToReturn = () => {
        const TYPE_OF_PAGE = {
            PersonalData: <FormPersonalData handleBack={handleBack} />,
            Affiliations: <Affiliations handleBack={handleBack} />,
            Address: <AddressForm handleBack={handleBack} />,
            ContactData: <FormContactData handleBack={handleBack} />,
            SportsHobbies: <SportsHobbies handleBack={handleBack} />,
            Refund: <FormRefund handleBack={handleBack} />,
            AutomaticMembership: <AutomaticMembership handleBack={handleBack} />,
            ChronicDiseases: <ChronicDiseases />,
            Programs: <Programs />,
            Vaccine: <Vaccine />,
            Lab: <LaboratoryStudies />,
            FactorGroup: <FactorGroup />,
            WeightHeight: <HomeWeight handleBack={handleBack} />,
            Allergies: <Allergies />,
            EmergencyPhones: <Phones type={'EmergencyPhones'} />,
            ContactPhones: <Phones type={'ContactPhone'} />,
            ChronicMedication: <ChronicMedication />,
            VitalParameters: <HomeVitalParameters handleBack={handleBack} />,
            RequirementsAndForms: <RequirementsAndForms />,
            CapsAndCopay: <RedirectToCapsAndCopay />,
            RefundCoverage: <Refunds />,
            MyPlan: <MyCoveragePDF />,
            FrequentQuestions: <MyCoveragePDF />,
            GeneralConditions: <MyCoveragePDF />,
            Formalities: <BillingAndCollection />,
            WayToPay: <WayToPay />,
            DirectDebit: <DirectDebit />,
            Movements: <Movements />,
            AskNewAppointment: <AskNewAppointment
                activeStepTurn={activeStepTurn}
                setActiveStepTurn={setActiveStepTurn}
                handleOpenCancelModalTurn={handleOpenCancelModalTurn}
                handleBackTurn={handleBackTurn}
                handleNextTurn={handleNextTurn}
                handleCloseDrawerTurn={handleCloseDrawerTurn}
                specialistVisualizacion={specialistVisualizacion}
                setSpecialistVisualizacion={setSpecialistVisualizacion}
            />,
            Records: <MedicalRecordContainer showMaps={showMaps} maps={maps} />,
            NewRequest: <RequestAuthorization
                handleBack={handleBackTurn}
                handleNext={handleNextTurn}
                activeStep={activeStepTurn}
                setActiveStep={setActiveStepTurn}
                handleOpenCancelModal={handleOpenCancelModalTurn}
                handleCloseDrawer={handleCloseDrawerTurn}
            />,
            RefundsRequest: <RefundsRequest
                handleBack={handleBackTurn}
                handleNext={handleNextTurn}
                activeStep={activeStepTurn}
                setActiveStep={setActiveStepTurn}
                handleOpenCancelModal={handleOpenCancelModalTurn}
                handleCloseDrawer={handleCloseDrawerTurn}
            />,
            BillingFrequentQuestions: <BillingFrequentQuestions />,
            PersonalDataFrequentQuestions: <PersonalDataFrequentQuestions />,
            Activities: <RedirectToActivities />,
            PrescriptionPage: <PrescriptionPage />
        };
        return TYPE_OF_PAGE[type];
    };
    return <>
        {responseToReturn()}
    </>;
};


export default React.memo(SelectTypeOfComponent);