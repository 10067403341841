import React, { useState } from 'react';
import { FormControl, InputBase, InputLabel } from "@material-ui/core";
import { usePlacesWidget } from "react-google-autocomplete";
import { alpha, styled } from '@material-ui/core/styles';
import useStyle from '../../assets/styles/styles';
const googleApiKey = window.env.GOOGLE_API_KEY;
const CustomTextFieldInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));
const GMapsAutocomplete = ({ handleChange, value, handlePlaceSelected, placeholder, disabled, label }) => {
    const classes = useStyle();
    const { ref: materialRef } = usePlacesWidget({
        apiKey: googleApiKey,
        onPlaceSelected: handlePlaceSelected,
        inputAutocompleteValue: "country",
        options: {
            types: ["geocode", "establishment"],
            componentRestrictions: 'ar',
        },
        language: 'es',
        onChange: handleChange,
        value: value
    });

    
    function required(){
        if (require) {
            return <span style={{color: '#ff0000' }}>*</span>;
        }
    }

    return <>
        <FormControl className={classes.formControl} variant="standard">
            <inputLabel className={classes.inputLabelForms} htmlFor="customTextFieldInput">
                {label} {required()}
            </inputLabel>
            <CustomTextFieldInput disabled={ disabled ? true : false} placeholder={placeholder} inputRef={materialRef} className={classes.customInput} id="customTextFieldInput" />
        </FormControl>
    </>;
};

export default GMapsAutocomplete;