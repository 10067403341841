import * as actionTypes from '../consts/actionTypes';
import {responseToReturn} from '../../utils/utilsFunctions.js'

const initialState = {
    programRequirementAndForm: [],
    medicalPrograms: [],
    msgError: ''
};

export default function programRequirementAndFormReducer(state = initialState, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_GET_PROGRAMREQUIREMENTANDFORM_BY_PROGRAM]: responseToReturn('programRequirementAndForm', state, action),
        [actionTypes.SUCCESS_GET_MEDICAL_PROGRAMS]: responseToReturn('medicalPrograms', state, action),
        [actionTypes.FAILURE_GET_PROGRAMREQUIREMENTANDFORM_BY_PROGRAM]: responseToReturn('programRequirementAndForm', state, action),
        [actionTypes.FAILURE_GET_MEDICAL_PROGRAMS]: responseToReturn('medicalPrograms', state, action),
    };

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}