import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetLimitPlan() {
	try {
		var response = yield call(API.getLimitPlan);
		if (response) {
			yield put({
				type: actionTypes.SUCCESS_GET_LIMIT_PLAN,
				response,
				message: ''
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.FAILURE_GET_LIMIT_PLAN,
			message: error.message,
		});
	}
}

function* asyncPostLimitPlan({ payload }) {
	try {
		var response = yield call(API.postLimitPlan, payload);
		if (response) {
			yield put({
				type: actionTypes.SUCCESS_POST_LIMIT_PLAN,
				response,
				message: ''
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.FAILURE_POST_LIMIT_PLAN,
			message: error.message,
		});
	}
}

function* asyncPutLimitPlan({ payload }) {
	try {
		var response = yield call(API.putLimitPlan, payload);
		if (response) {
			yield put({
				type: actionTypes.SUCCESS_PUT_LIMIT_PLAN,
				response,
				message: ''
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.FAILURE_PUT_LIMIT_PLAN,
			message: error.message,
		});
	}
}

function* asyncDeleteLimitPlan({ payload }) {
	try {
		var response = yield call(API.deleteLimitPlan, payload);
		if (response) {
			yield put({
				type: actionTypes.SUCCESS_DELETE_LIMIT_PLAN,
				response,
				message: ''
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.FAILURE_DELETE_LIMIT_PLAN,
			message: error.message,
		});
	}
}

export default function* limitPlanSaga() {
	yield takeLatest(actionTypes.GET_LIMIT_PLAN, asyncGetLimitPlan);
	yield takeLatest(actionTypes.POST_LIMIT_PLAN, asyncPostLimitPlan);
	yield takeLatest(actionTypes.PUT_LIMIT_PLAN, asyncPutLimitPlan);
	yield takeLatest(actionTypes.DELETE_LIMIT_PLAN, asyncDeleteLimitPlan);
}
