import { useEffect, useState, Fragment } from 'react';
import { FormControl, FormHelperText, FormControlLabel, Checkbox, InputLabel, TextField, Typography, InputAdornment, IconButton, CircularProgress } from "@material-ui/core";
import CustomSelect from "../../../controls/Select/CustomSelect";
import CustomButton from "../../../controls/Button/CustomButton";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import useStyles from "../../../assets/styles/styles";
import { useSelector, useDispatch } from 'react-redux';
import { getChangePlan, getMotiveLowPlan, getPlans, getDataAffiliatesBaja, getFile, postChangePlan } from '../../../redux/actions/procedureActions';
import { LocalizationProvider, MobileDatePicker } from '@material-ui/lab';
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import CustomizedInputs from '../../../controls/InputCustom/CustomizedInputs';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import UploadModal from "../../../controls/UploadModal/UploadModal";
import { deleteFile, handleChangeMail, isDisabled, validate, validateFiles, validateData, messageModalFunction } from "../../../utils/utilsFunctions";
import ConfirmModal from '../ConfirmModal';
import ChangePlanHeadline from './ChangePlanHeadline';
import { setearMail, existFile, verifyMail, setAttachment, validationDate } from './functionShared';
import { es } from "date-fns/locale";

const ChangePlan = (props) => {
    const date = new Date();
    const data = useSelector(state => state.procedureReducer);
    const dispatch = useDispatch();
    const [ProcessSelected, setProcessSelected] = useState('');
    const [listSubTramite, setListSubTramite] = useState([]);
    const [lowMotives, setLowMotives] = useState([]);
    const [aditionals, SetAditionals] = useState([]);
    const [selectAditional, setSelectAditional] = useState([]);
    const [planAditional, setPlanAditional] = useState([]);
    const [motiveSelected, setMotiveSelected] = useState('');
    const [dateLow, setDateLow] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 1));
    const fullPersonalData = useSelector(state => state.personalDataReducer);
    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");
    const [mailError, setMailError] = useState('');
    const [query, setQuery] = useState("");
    const [disabledPhone, setDisabledPhone] = useState(true);
    const [disabledEmail, setDisabledEmail] = useState(true);
    const [emailChecked, selEmailChecked] = useState(false);
    const [showPermanente, setShowPermanente] = useState(false);
    const [listOfAttachmentButtons, setistOfAttachmentButtons] = useState([]);
    const [modalTitle, setModalTitle] = useState('');
    const [modalDescription, setModalDescription] = useState("");
    const [modalIcon, setModalIcon] = useState('');
    const [errorClass, setErrorClass] = useState('');
    const [openSendSuccess, setOpenSendSuccess] = useState(false);
    const [files, setFiles] = useState([]);
    const [fileButtons, setFileButtons] = useState([]);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [nameOfItem, setItemName] = useState('');
    const [itemRequired, setItemRequired] = useState('');
    const [planes, setListPlanes] = useState([]);
    const [selectedPlanDisp, setSelectedPlanDisp] = useState('');
    const postChangeReducer = useSelector(state => state.procedureReducer.postChangePlan);
    const [itemClasificacion, setItemClasificacion] = useState(0);
    const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);

    const CODIGO_CAMBIOPLAN_GRUPO_PRIMARIO = 1;
    const classes = useStyles();

    const parameter = { numeroPersona: window.localStorage.getItem("numeroPersona"), ParametroLlamado: "" };
    const Tramites = [
        {
            cambioPlanCodigo: 1,
            cambioPlanDescripcion: "Titular y grupo primario"
        }, {
            cambioPlanCodigo: 2,
            cambioPlanDescripcion: "Titular y Adicionales"
        }
    ];

    const MotivosBaja = [
        {
        }
    ];

    const planesDisponibles = [
        {

        },
    ];

    const Adjuntos = [
        {

        }
    ];

    const parametroPlan = { ParametroLlamado: "CAMBIO DE PLAN" };

    const handleChangeSelectProcedure = () => e => {
        const { name, value } = e.target;
        setProcessSelected(value);
        validate(name, '*Campo obligatorio', value, setError, error);
        let newFiles = data.procedures?.tramites?.filter((x) => x.tramiteCodigo == value)[0];
        setAttachment(newFiles?.subTramites?.filter((x) => x.subTramiteCodigo === value)[0], setistOfAttachmentButtons, Adjuntos);
        setFiles([]);
        setFileButtons([]);
    };

    const handleChangeSelectMotive = () => e => {
        const { name, value } = e.target;
        setMotiveSelected(value);
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    const handleChangeSelectAditional = () => e => {
        const { name, value } = e.target;
        if(value !== undefined) {
            setSelectAditional(value);
            validate(name, '*Campo obligatorio', value, setError, error);

            var family = data.dataAffiliates?.afiliados.filter((x) => x.numeroPersona == value)[0];
            setPlanAditional({ cod: family.planCodigo, description: family.planDescripcion });
        }
    };

    const handleChangeSelectPlan = () => e => {
        const { name, value } = e.target;
        setSelectedPlanDisp(value);
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    useEffect(() => {
        dispatch(getChangePlan());
        dispatch(getMotiveLowPlan());
        dispatch(getDataAffiliatesBaja(parameter));
        dispatch(getPlans(parametroPlan));
        let tel = fullPersonalData?.fullData?.afiliado?.telefonos[0]?.telefono;
        setPhone(tel);
        fullPersonalData?.fullData?.afiliado?.mails?.forEach((item) => {
            setearMail(item, setMail);
        });
        setProcessSelected(1);
    }, []);

    useEffect(() => {
        setearMotivosPlan(data, MotivosBaja, setLowMotives);
    }, [data.getMotivosPlan]);

    useEffect(() => {
        setearPlanes(data, setListSubTramite, Tramites);
    }, [data.getPlan]);

    useEffect(() => {
        setearAdicionales(data, SetAditionals, Tramites);
    }, [data.dataAffiliates]);

    useEffect(() => {
        setearPlanesDisponible(data, setListPlanes, planesDisponibles);
    }, [data.getPlanDisponible]);

    const handleEditPhone = () => {
        setDisabledPhone(prevState => !prevState);
    };

    const handleChangePhone = () => e => {
        const { name, value } = e.target;
        setPhone(value);
        validate(name, '*Campo obligatorio', value, setError, error);

    };

    const handleEditEmail = () => {
        setDisabledEmail(prevState => !prevState);
    };

    const handleCheck = (e) => {
        e.preventDefault();
        if (!emailChecked) {
            setModalTitle('SE HA MODIFICADO EL MAIL CORRECTAMENTE');
            setModalDescription(`Este cambio se realizará en el e-mail particular.`);
            setModalIcon(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
            setErrorClass('');
            setOpenSendSuccess(prevState => !prevState);
        }
        selEmailChecked(prevState => !prevState);
    };
    const [error, setError] = useState({
        lowMotive: '',
        subProcedure: '',
        aditionals: '',
        plans: '',
        mail: '',
        phone: '',
        date: ''
    });

    const handleOpenModal = (itemName, fileRequired, archivoClasificacion) => (e) => {
        e.preventDefault();
        setOpenUploadModal(prevState => !prevState);
        setItemName(itemName);
        setItemRequired(fileRequired);
        setItemClasificacion(archivoClasificacion)
    };

    const dowloadFile = (codigo) => {
        dispatch(getFile(codigo));
    };

    useEffect(() => {
        if (existFile(data)) {
            var a = document.createElement("a"); //Create <a>
            a.href = "data:image/png;base64," + data.file.base64; //Image Base64 Goes here
            a.download = "Image.png"; //File name Here
            a.click(); //Downloaded file
        }
    }, [data.file]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateFiles(listOfAttachmentButtons, files)) {
            let filesToSend = files.map((item) => {
                delete (item['btnNombre']);
                delete (item["adjuntoObligatorio"]);
                return item;
            });
            let parametro = {
                NumeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                CambioPlanCodigo: ProcessSelected,
                NumeroPersonaCambio: (ProcessSelected == CODIGO_CAMBIOPLAN_GRUPO_PRIMARIO) ? null : selectAditional,
                PlanCodigoNuevo: selectedPlanDisp,
                CambioPlanFecha: dateLow.toISOString(),
                MotivoCambioPlanCodigo: motiveSelected,
                Mail: mail,
                MailModificar: verifyMail(showPermanente),
                Telefono: phone,
                Consulta: query,
                Adjuntos: JSON.stringify(filesToSend)
            };
            let newError = validationSelect(parametro, dateLow, date);
            setError(newError);
            if (validateData(newError)) {
                let circularProgress = (
                    <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress color="inherit" /></div>
                );
                setModalTitle('INICIANDO GESTION');
                setModalDescription(circularProgress);
                setModalIcon('');
                setErrorClass('');
                setOpenSendSuccess(prevState => !prevState);
                dispatch(postChangePlan(parametro));
            }
        }
    };

    function ChangePlanForm() {
        return <>
            {
                ProcessSelected == CODIGO_CAMBIOPLAN_GRUPO_PRIMARIO ?
                    <ChangePlanHeadline processSelected={ProcessSelected} />
                    :
                    changePlanAditionals()
            }
        </>;

    }

    function enableEnviarBtn(listOfAttachment, file, err, motive, plan, aditional) {
        const isClear = (valor) => valor === "";
        var response = true;
        if(((Object.values(err)).every(isClear)) && validateFiles(listOfAttachment, file) && motive !== "" && plan !== "" && aditional.length !== 0) {
            response = false;
        }
        return response;
    }

    const changePlanAditionals = () => {
        return (<>
            <FormControl className={`${classes.formControlCustomSelect} ${classes.itemGridSpan2}`} variant="standard">
                <CustomSelect
                    name='lowMotive'
                    label='MOTIVO DE BAJA'
                    list={lowMotives}
                    selected={motiveSelected}
                    onChange={handleChangeSelectMotive()}
                />
                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.lowMotive}</FormHelperText>
            </FormControl>
            <FormControl className={`${classes.formControlCustomSelect} ${classes.itemGridSpan2} `} variant="standard">
                <div className={classes.headerFiltro}>
                    {selectAditional != "" ? <Typography className={`${classes.txtSubtitleCalendar}`}>
                        {"PLAN VIGENTE: " + planAditional.description}
                    </Typography> : ""}
                </div>
                {aditionals?.length === 0 ?
                    <>
                    <CustomSelect
                    name='aditionals'
                    label='ADICIONALES'
                    list={aditionals}
                    selected={selectAditional}
                    onChange={handleChangeSelectAditional()}
                    disable={true}
                />
                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>No hay adicionales disponibles</FormHelperText>
                </>
                :
                <>
                <CustomSelect
                    name='aditionals'
                    label='ADICIONALES'
                    list={aditionals}
                    selected={selectAditional}
                    onChange={handleChangeSelectAditional()}
                />
                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.aditionals}</FormHelperText>
                </>
                }
            </FormControl>
            <FormControl className={`${classes.formControlCustomSelect} ${classes.itemGridSpan1} `} variant="standard">
                <CustomSelect
                    name='plans'
                    label='PLANES DISPONIBLES'
                    list={planes}
                    selected={selectedPlanDisp}
                    onChange={handleChangeSelectPlan()}
                />
                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.plans}</FormHelperText>
            </FormControl>
            <FormControl className={`${classes.formControlDatePicker} ${classes.itemGridSpan1}`} >
                <FormControl className={classes.formControl}>
                    <InputLabel style={{ top: "15px" }} className={`${classes.inputLabelSelectForms} `} shrink htmlFor="bootstrap-input">
                        {"FECHA DE LA BAJA"}
                    </InputLabel>
                    <div style={{ marginTop: "30px" }} className={classes.top22}>
                        <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}  >
                            <MobileDatePicker
                                cancelText="Cancelar"
                                toolbarTitle=""
                                format="dd/MM/YYYY"
                                value={dateLow}
                                disablePast={true}
                                shouldDisableDate={(date) => date > new Date(date.getFullYear(), date.getMonth(), 1)}
                                onChange={(newValue) => { setDateLow(newValue); }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            {<FormHelperText className={classes.errorText}>{"El cambio se realiza el primer dia del mes"}</FormHelperText>}
                            {<FormHelperText className={classes.errorText}>{error?.date}</FormHelperText>}

                        </LocalizationProvider>
                    </div>
                </FormControl>
            </FormControl>
            <FormControl className={`${classes.formControlAddress} ${classes.itemGridSpan2}`} variant="standard">
                <CustomizedInputs
                    value={mail}
                    name='mail'
                    label='E-MAIL'
                    disabled={disabledEmail}
                    onChange={
                        handleChangeMail(setMail, setMailError, setError, error, setShowPermanente, fullPersonalData?.fullData?.afiliado?.mails?.filter(z => z.mailTipoCodigo == 1)[0]?.mail)
                    }
                    endAdornment={
                        <InputAdornment position="start">
                            <IconButton onClick={handleEditEmail} size='small'>
                                <EditIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {showPermanente ?
                    <FormControlLabel style={{ paddingLeft: '5%', fontSize: '11px' }} control={<Checkbox checked={emailChecked} onClick={handleCheck} color='primary' />} label="¿E-MAIL PERMANENTE?" /> : ""
                }
                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.mail}</FormHelperText>
                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{mailError}</FormHelperText>
            </FormControl>
            <FormControl className={`${classes.formControlAddress} ${classes.itemGridSpan2}`} variant="standard">
                <CustomizedInputs
                    value={phone}
                    label='TELEFONO'
                    name='phone'
                    disabled={disabledPhone}
                    onChange={handleChangePhone()}
                    endAdornment={
                        <InputAdornment position="start">
                            <IconButton onClick={handleEditPhone}>
                                <EditIcon />
                            </IconButton>
                        </InputAdornment>}
                    onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');
                    }}
                    inputProps={{ maxLength: 20 }}
                />
                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.phone}</FormHelperText>
            </FormControl>
            <FormControl className={classes.commentGrid} variant="standard">
                <CustomizedInputs
                    name="consulta"
                    label='OBSERVACIONES'
                    placeholder='Escribe tu texto aqui'
                    multiline
                    rows={5}
                    inputProps={{ maxLength: 2000 }}
                    onChange={(e) => { setQuery(e.target.value); }}
                    defaultValue={query}
                />
            </FormControl>
            <section className={` ${classes.commentGrid}`}>
                <div style={{ width: '250px' }}>
                    <Typography style={{ flex: '1 1 100%', justifyContent: 'center' }} className={`${classes.txtObservaciones} ${classes.txtSubtitle}`}>ADJUNTAR ARCHIVOS</Typography>
                    <span style={{ flex: '1 1 100%', fontSize: '10px', display: 'flex' }}>{`(TAMAÑO MAXIMO ${configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor}MB)`}</span>
                    {listOfAttachmentButtons?.map((item, i) => (
                        <Fragment key={i}>
                            <CustomButton
                                name={item.adjuntoDescripcion}
                                style={{ padding: '5px 15px 5px 5px' }}
                                title={`ADJUNTAR ${item.adjuntoDescripcion}`}
                                icon={<AttachFileIcon />}
                                customClass={`${classes.lightLinearGradientReverse} ${classes.marginLeft}`}
                                onClick={handleOpenModal(item.adjuntoDescripcion, item.adjuntoObligatorio, item.adjuntoClasificacion)}
                                disabled={isDisabled(fileButtons, item.adjuntoDescripcion)}
                            />
                            {item.adjuntoModelo != '' &&
                                <IconButton href={`${configurationReduxState?.filter(x => x?.archivoNombre === item?.adjuntoModelo)?.[0]?.valor}`} target={"_blank"} rel={"noopener noreferrer"}>
                                    <Typography className={`${classes.txtSubtitleBlue}`}>
                                        descargar Modelo
                                    </Typography></IconButton>}
                            {item.adjuntoInstructivo != '' &&
                                <IconButton onClick={() => { dowloadFile(item.adjuntoInstructivo); }}>
                                    <Typography className={`${classes.txtSubtitleBlue}`}>
                                        descargar Instructivo
                                    </Typography></IconButton>}
                            {item.adjuntoObligatorio === 'S' &&
                                <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>*Archivo obligatorio</FormHelperText>
                            }
                        </Fragment>
                    ))}
                    {files?.map((item, i) => (
                        <p key={i} style={{ marginLeft: "5%" }} className={classes.txtSubtitleCalendar}>{item?.archivoNombre} <IconButton onClick={deleteFile(fileButtons, setFileButtons, setFiles, files, item?.btnNombre, item?.archivoNombre)}><HighlightOffIcon /></IconButton></p>
                    ))}
                </div>
            </section>
            <ConfirmModal isOpen={openSendSuccess} setOpen={setOpenSendSuccess} errorClass={errorClass}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalIcon}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalTitle}
                        </div>
                    </>
                }
                description={modalDescription} />
            <div className={classes.width100}>
                <UploadModal
                    className={classes.width100}
                    title='Adjuntar archivo'
                    description=''
                    observation=''
                    open={openUploadModal}
                    onClose={setOpenUploadModal}
                    setModalTitle={setModalTitle}
                    setModalDescription={setModalDescription}
                    setModalIcon={setModalIcon}
                    setErrorClass={setErrorClass}
                    setOpenSendSuccess={setOpenSendSuccess}
                    setFiles={setFiles}
                    files={files}
                    fileButtons={fileButtons}
                    setFileButtons={setFileButtons}
                    itemName={nameOfItem}
                    isRequired={itemRequired}
                    archivoClasificacion={itemClasificacion}
                />
            </div>
            <div className={`${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                {screen.sWidth <= 768 ?
                    <div>
                        <CustomButton customClass={classes.lightLinearGradient} style={{ padding: '5px 20px 5px 20px' }} title='Volver' type='button' onClick={props.handleBack} />
                    </div>
                    :
                    <CustomButton
                        disabled={enableEnviarBtn(listOfAttachmentButtons, files, error, motiveSelected, selectedPlanDisp, selectAditional)}
                        customClass={classes.lightLinearGradient}
                        style={{ padding: '5px 20px 5px 20px', marginBottom: "20px" }}
                        title='Enviar'
                        type='button'
                        onClick={handleSubmit}
                    />
                }
            </div>
        </>);
    };


    useEffect(() => {
        messageModalFunction(
            postChangeReducer, setModalTitle,
            setModalDescription,
            setModalIcon,
            setErrorClass,
            classes,
            setOpenSendSuccess,
            mail
        );
        return (() => {
            if (postChangeReducer?.confirmacion) {
                setModalTitle('');
                setModalDescription('');
                setModalIcon('');
                setErrorClass('');
            }
        });
    }, [postChangeReducer]);

    return (
        <>
            {
                props.permission ? <>
                    <FormControl className={`${classes.formControlCustomSelect} ${classes.itemGridSpan2} `} variant="standard">
                        <CustomSelect
                            name='subProcedure'
                            label='CAMBIO DE PLAN'
                            list={listSubTramite}
                            selected={ProcessSelected}
                            onChange={handleChangeSelectProcedure()}
                        />
                        <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.subProcedure}</FormHelperText>
                    </FormControl>
                    {
                        ChangePlanForm()
                    }
                </>
                    :
                    <FormHelperText className={classes.errorText}>{"La facturacion del titular es por empresa. por favor consultá con tu empresa"}</FormHelperText>
            }
        </>
    );
};

export default ChangePlan;

function validationSelect(param, dateLow, date) {
    let temp = {};
    temp.lowMotive = param.MotivoCambioPlanCodigo != '' ? '' : '*Campo obligatorio';
    if (param.CambioPlanCodigo == 1) {
        temp.aditionals = "";
    } else {
        temp.aditionals = param.NumeroPersonaCambio != '' ? '' : '*Campo obligatorio';
    }
    temp.subProcedure = param.CambioPlanCodigo != '' ? '' : '*Campo obligatorio';
    temp.plans = param.PlanCodigoNuevo != '' ? '' : '*Campo obligatorio';
    temp.date = validationDate(dateLow, date);
    temp.mail = param.Mail != '' ? '' : '*Campo obligatorio';
    temp.phone = param.Telefono != '' ? '' : '*Campo obligatorio';
    return temp;
}

function setearMotivosPlan(data, MotivosBaja, setLowMotives) {
    if (data.getMotivosPlan != null) {
        useSetter(data?.getMotivosPlan?.motivosCambioPlan, MotivosBaja, setLowMotives, 'motivoCambioPlanCodigo', 'motivoCambioPlanDescripcion');
    }
}

function setearPlanes(data, setListSubTramite, Tramites) {
    if (data.getPlan != null) {
        useSetter(data.getPlan?.tramites, Tramites, setListSubTramite, 'cambioPlanCodigo', 'cambioPlanDescripcion');
    }
}

function setearAdicionales(data, SetAditionals, Tramites) {
    let aditionalsAux = data?.dataAffiliates?.afiliados?.filter((item) => item.parentescoDescripcion == "ADICIONALES");
    if (aditionalsAux !== null && aditionalsAux !== undefined) {
        useSetter(aditionalsAux, Tramites, SetAditionals, 'numeroPersona', 'apellidoNombre');
    }
}

function setearPlanesDisponible(data, setListPlanes, planesDisponibles) {
    if (data.getPlanDisponible != null && data.getPlanDisponible != "") {
        useSetter(data.getPlanDisponible?.planes, planesDisponibles, setListPlanes, 'planCodigo', 'planDescripcion');
    }
}


function useSetter(wsList, hardcodeList, setter, nameValue, nameLabel) {
    let auxReason = [];
    let auxList = [];
    if (wsList?.length === 0) {
        auxList = hardcodeList;
    } else {
        auxList = wsList;
    }
    auxList?.forEach(item => {
        let auxObj = {
            value: item[nameValue],
            label: item[nameLabel]
        };
        if(auxObj.value !== undefined && auxObj.label !== undefined) {
            auxReason.push(auxObj);
        } else {
            return;
        }
    });
    setter(auxReason);
}