import { useEffect, useState } from 'react';
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import useStyles from "../../../assets/styles/styles";
import {
    Typography,
    Divider,
    Accordion,
    AccordionSummary,
    Zoom,
    AccordionDetails,
    ClickAwayListener,
    FormControl,
    TextField,
    IconButton,
    FormControlLabel,
    FormGroup,
    Switch,
} from "@material-ui/core";
import CustomButton from '../../../controls/Button/CustomButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import TuneIcon from '@material-ui/icons/Tune';
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import MobileDatePicker from '@material-ui/lab/MobileDatePicker';
import InputLabel from '@material-ui/core/InputLabel';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { getVitalParameters } from './../../../redux/actions/medicalDataAction';
import { useDispatch, useSelector } from "react-redux";
import NotFound from '../../HomeLogged/NotFound';
import { es } from "date-fns/locale";
import { handleGoToPersonalMedicalData } from "../../../utils/utilsFunctions";
import FormHeader from '../../PersonalData/FormHeader';
import MovementsCard from '../../Billing/Movements/MovementsCard';
import HeaderCard from '../../Billing/Movements/HeaderCard';
import Loading from '../../LoadingPage/Loading';

const VitalParameters = (props) => {
    const today = new Date();
    today.setMonth(-6);
    const dispatch = useDispatch();
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const [open, setOpen] = useState(false);
    const [dateStart, setDateStart] = useState(today);
    const [dateEnd, setDateEnd] = useState(new Date());
    const [skip, setSkip] = useState(0);
    const [vitalParameters, setVitalParameters] = useState([]);
    const [selectedFilter, setFilter] = useState(null);
    const [switchState, setSwitchState] = useState({});
    const [lastRecord, setLastRecord] = useState(null);
    const filterTypes = ["TODOS", "TEMPERATURA", "TENSION ARTERIAL", "GLUCEMIA", "FRECUENCIA CARDIACA"];
    const [initial, setInitial] = useState(true);
    const spaceBetween = "space-between";
    const medicalData = useSelector(state => state.medicalDataReducer);
    const [loadingLastRecord, setLoadingLastRecord] = useState(true);

    useEffect(() => {
        setFilter("TODOS");
        const newSwitch = {
            "TODOS": true,
            "TEMPERATURA": false,
            "TENSION ARTERIAL": false,
            "GLUCEMIA": false,
            "FRECUENCIA CARDIACA": false
        };
        setSwitchState(newSwitch);
    }, []);

    useEffect(() => {
        setLoadingLastRecord(true)
        if (medicalData.vitalParameters.parametrosVitales !== undefined && medicalData.vitalParameters.parametrosVitales.length > 0) {
            if (vitalParameters.length !== 0) {
                const newRegisters = vitalParameters;
                medicalData.vitalParameters.parametrosVitales.forEach(element => {
                    newRegisters.push(element);
                });
                setVitalParameters(newRegisters);
            }
            else {
                setLastRecord(medicalData.vitalParameters.parametrosVitales[0]);
                setVitalParameters(medicalData.vitalParameters.parametrosVitales);
                setLoadingLastRecord(false);
            }
        }
        setLoadingLastRecord(false)
    }, [medicalData.vitalParameters]);

    useEffect(() => {
        filterDate();
    }, [dateEnd]);

    useEffect(() => {
        filterDate();
    }, [dateStart]);

    useEffect(() => {
        filterDate();
    }, [selectedFilter]);

    const filterDate = () => {
        setLastRecord(null);
        setLoadingLastRecord(true);
        setVitalParameters([]);
        callToServices();
    };

    const handleChangeFilter = (event) => {
        if (event.target.name !== "TODOS" || event.target.name == "TODOS" && event.target.checked) {
            const defaultSwitch = {
                "TODOS": !event.target.checked,
                "TEMPERATURA": false,
                "TENSION ARTERIAL": false,
                "GLUCEMIA": false,
                "FRECUENCIA CARDIACA": false
            };
            setFilter(event.target.checked ? event.target.name : "TODOS");
            setSwitchState({ ...defaultSwitch, [event.target.name]: event.target.checked });
        }
    };

    const callToServices = (skipValue) => {
        let aux = skipValue ? parseInt(skipValue) : 0;

        if (dateStart !== null && dateEnd !== null) {
            if (selectedFilter) {
                const paramGet = {
                    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                    count: 10,
                    skip: aux,
                    parametroVitalTipo: selectedFilter,
                    fechaDesde: dateStart,
                    fechaHasta: dateEnd
                };

                setSkip(aux);
                dispatch(getVitalParameters(paramGet));
            }
        }
    };

    const lastRecordCard = () => {
        return <>
            {lastRecord !== null ?
                (
                    <MovementsCard
                        buttonsInHeader={false}
                        headerIcon={
                            <HeaderCard
                                day={lastRecord?.fechaFormateada?.nombreDia}
                                numberDay={lastRecord?.fechaFormateada?.dia}
                                month={parseInt(lastRecord?.parametroVitalFechaRegistracion?.substring(5, 7))}
                                year={lastRecord?.parametroVitalFechaRegistracion?.substr(0, 4)}
                            />
                        }
                        description={<div style={{ marginTop: "10px" }}>
                            <Typography className={`${classes.txtSubtitleCalendar} ${classes.txtEllipsis}`}>
                                {`HORA: ${lastRecord?.parametroVitalFechaRegistracion?.substr(lastRecord?.parametroVitalFechaRegistracion.indexOf("T") + 1)} hs`}
                            </Typography>
                            <Typography className={`${classes.subTitleMedicalData} ${classes.txtEllipsis}`}>{lastRecord?.parametroVitalTipo}</Typography>
                            {lastRecord?.paramentrosVitalValores.map((item, i) => (
                                <div key={i} className={classes.displayFlex}>
                                    <Typography
                                        style={{ fontSize: "10px", marginRight: "5px" }}
                                        className={`${classes.txtSubtitleCalendar} ${classes.txtEllipsis}`}>
                                        {item.valorDescripcion + " "}
                                    </Typography>
                                    <Typography style={{ fontSize: "10px" }} className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>
                                        {`${item.valor} ${units(lastRecord.parametroVitalTipo)}`}
                                    </Typography>
                                </div>
                            ))
                            }
                        </div>}
                    />
                )
                : (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <NotFound customMsg={
                            <div className={classes.contentNotFound}>
                                <span>
                                    No se encontraron resultados
                                </span>
                            </div>
                        }
                            wsItem={medicalData?.vitalParameters?.parametrosVitales}
                            fullItem={medicalData?.vitalParameters}
                        />
                    </div>
                )
            }
        </>;
    };

    const units = (desc) => {
        let response = "";
        switch (desc) {
            case "TENSION ARTERIAL":
                response = "mm de Merc.";
                break;
            case "FRECUENCIA CARDIACA":
                response = "lat/min";
                break;
            case "TEMPERATURA":
                response = "°C";
                break;
            case "GLUCEMIA":
                response = "mg/dl";
                break;
            default:
                break;
        }
        return response;
    };

    const addAccordion = (i, item) => {
        return (
            <Accordion key={i} expanded={true}>
                <AccordionSummary
                    aria-expanded={true}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div style={{ width: "100%", display: "flex", justifyContent: spaceBetween }}>
                        <Typography className={classes.txtSubtitleCalendar}>
                            {item.parametroVitalTipo}
                        </Typography>
                        <IconButton className={classes.padding0} onClick={() => { handleDelete(item); }}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        item.paramentrosVitalValores.map((value, key) => (
                            <div key={key} className={classes.displayFlex}>
                                <Typography className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`} style={{ marginRight: "5px" }}>{value.valorDescripcion}</Typography>
                                <Typography className={`${classes.txtSubtitleGray} ${classes.txtEllipsis}`}>
                                    {`${value.valor} ${units(item.parametroVitalTipo)}`}
                                </Typography>
                            </div>
                        ))
                    }
                    <div className={classes.displayFlex}>
                        <Typography className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`} style={{ marginRight: "5px" }}>{"FECHA Y HORA:"}</Typography>
                        <Typography className={`${classes.txtSubtitleGray} ${classes.txtEllipsis}`}>{item.parametroVitalFechaRegistracion.replace("T", " ")}</Typography>
                    </div>
                </AccordionDetails>
            </Accordion>);
    };

    const filter = () => {
        return (
            <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway} >
                <div style={{ position: "relative", marginRight: "10px" }}>
                    <CustomButton
                        title={""}
                        onClick={handleClick}
                        icon={<TuneIcon style={{ marginRight: "5px" }} className={classes.iconFilter} />}
                        customClass={`${classes.customHeaderBtn} ${classes.btnFilterAppointment}`}
                    />
                    {open && (
                        <Zoom
                            in={open}
                        >
                            <div className={classes.dropdown}>
                                <Typography className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>{"PERIODO"}</Typography>
                                <Divider classes={{ root: classes.dividerFilter }} />
                                {<div className={`${classes.headerFiltro} ${classes.marginTop}`}>
                                    <FormControl style={{ width: "150px", paddingTop: "10px" }} className={classes.datePicker}>
                                        <InputLabel className={`${classes.txtSubtitleCalendar} ${classes.fontSize15}`} shrink  >{"DESDE"}</InputLabel>
                                        <div style={{ display: "flex", justifyContent: spaceBetween }} className={classes.top22}>
                                            <LocalizationProvider locale={es} style={{ width: "100px" }} dateAdapter={AdapterDateFns}  >
                                                <MobileDatePicker
                                                    cancelText="Cancelar"
                                                    toolbarTitle=""
                                                    format="dd/MM/YYYY"
                                                    value={dateStart}
                                                    onChange={(newValue) => { setDateStart(newValue); }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                            <CalendarTodayIcon style={{ alignSelf: "center" }} />
                                        </div>
                                    </FormControl>
                                    <FormControl style={{ width: "150px", marginLeft: "10px", paddingTop: "10px" }} className={classes.datePicker} >
                                        <InputLabel className={`${classes.txtSubtitleCalendar} ${classes.fontSize15}`} shrink  >{"HASTA"}</InputLabel>
                                        <div style={{ display: "flex", justifyContent: spaceBetween }} className={classes.top22}>
                                            <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}  >
                                                <MobileDatePicker
                                                    cancelText="Cancelar"
                                                    toolbarTitle=""
                                                    format="dd/MM/YYYY"
                                                    value={dateEnd}
                                                    onChange={(newValue) => { setDateEnd(newValue); }}
                                                    variant='outlined'
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                            <CalendarTodayIcon style={{ alignSelf: "center" }} />
                                        </div>
                                    </FormControl>
                                </div>}
                                <div style={{ marginTop: "20px" }}>
                                    <Typography className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>
                                        {"PARAMETRO"}
                                    </Typography>
                                    <Divider classes={{ root: classes.dividerFilter }} />
                                    <FormControl
                                        required
                                        component="fieldset"
                                        className={`${classes.formControl} ${classes.formSwitch}`}
                                    >
                                        <FormGroup>
                                            <>
                                                {filterTypes.map((val, index) => (
                                                    <>
                                                        <FormControlLabel
                                                            className={classes.marginRight}
                                                            key={index}
                                                            labelPlacement="start"
                                                            control={
                                                                <Switch
                                                                    onChange={handleChangeFilter}
                                                                    color="primary"
                                                                    name={val}
                                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                    checked={switchState[val]}
                                                                />}
                                                            label={<Typography className={`${classes.txtFilter} ${classes.txtEllipsis}`}>{val}</Typography>}
                                                        />
                                                    </>
                                                ))
                                                }
                                            </>
                                        </FormGroup>
                                    </FormControl>
                                </div>
                            </div>
                        </Zoom>)
                    }
                </div>
            </ClickAwayListener>);
    };

    const scrollDiv = () => {
        return (screen.sWidth < 800 ? classes.heightDivRegisters : "");
    };

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            callToServices();
        }
    };

    const handleClickAway = () => {
        setOpen(false);
    };
    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleDelete = (item) => {
        const param = {
            numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
            parametroVitalTipo: item?.parametroVitalTipo?.trim(),
            parametroVitalValores: JSON.stringify(item.paramentrosVitalValores),
            parametroVitalFechaRegistracion: item.parametroVitalFechaRegistracion,
            modo: "B"
        };
        param.modo = "B";
        props.setAction("BAJA");
        props.setForm(param);
        props.setOpenConfirm(prevState => !prevState);
    };

    function formHeaderText() {
        const response = {
            titleHeader: 'Parametros vitales',
            subtitleHeader: 'Información sin carácter sanitario conforme ley Nº 26529'
        };
        if (screen.sWidth < 768) {
            response.titleHeader = 'Mis datos medicos';
            response.subtitleHeader = 'Información sin carácter sanitario conforme ley Nº 26529';
        }
        return response;
    }

    function showLastCardRecord(condition) {
        var response;
        if (condition > 768) {
            response = (
                <div className={classes.prueba} style={{ marginLeft: "25px" }}>
                    {lastRecordCard()}
                </div>
            );
        } else {
            response = (
                <div className={classes.prueba} style={{ display: "flex", justifyContent: "center" }}>
                    {lastRecordCard()}
                </div>
            );
        }
        return response;
    }

    function myLastRecord() {
        return (
            <div>
                <div style={{ marginBottom: "30px" }}>
                    <Typography className={`${classes.titleTurn} ${classes.titleBox}`}>
                        MI ULTIMO REGISTRO
                    </Typography>
                    <Divider className={classes.formDivider} style={{ marginBottom: "15px" }} />
                    {showLastCardRecord(screen.sWidth)}
                </div>

                <Typography className={`${classes.titleTurn} ${classes.titleBox}`} style={{ textTransform: 'uppercase' }}>
                    MIS REGISTROS ANTERIORES
                </Typography>
                <Divider className={classes.formDivider} style={{ marginBottom: "15px" }} />
                <div
                    className={`${classes.flexRowAcordion} ${scrollDiv()}`}
                    onScroll={(e) => { handleScroll(e); }}
                >
                    {
                        vitalParameters?.length > 0 ? <> {
                            vitalParameters.map((item, i) => (
                                <div key={i} className={`${classAccordion(screen.sWidth, classes)} ${classes.marginBottom}`} style={{ marginLeft: screen.sWidth > 768 && '40px' }}>
                                    {addAccordion(i, item)}
                                </div>
                            ))
                        }
                            {screen.sWidth > 800 && vitalParameters?.length > 10 &&
                                <div style={{ width: "100%" }} className={classes.BtnMobileWeight} >
                                    <CustomButton style={{ marginRight: "5px" }} customClass={classes.lightLinearGradient} title='ver mas' onClick={(event) => {
                                        callToServices(skip + 10);
                                    }} />
                                </div>
                            }
                        </> :
                            (
                                <div style={{ display: 'flex', justifyContent: 'center', flex: '0 0 100%' }}>
                                    <NotFound customMsg={
                                        <div className={classes.contentNotFound}>
                                            <span>
                                                No se encontraron resultados
                                            </span>
                                        </div>
                                    }
                                        wsItem={medicalData?.vitalParameters?.parametrosVitales}
                                        fullItem={medicalData?.vitalParameters}
                                    />
                                </div>
                            )
                    }
                </div>
            </div>
        );
    }

    return (<>
        {
            screen.sWidth <= 768 &&
            <FormHeader title={formHeaderText().titleHeader} subtitle={formHeaderText().subtitleHeader} handleGoTo={handleGoToPersonalMedicalData} />
        }
        <div className={classes.headerSecundary} style={{ marginBottom: "20px", display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <Typography className={`${classes.titleTurn} ${classes.titleBox}`} style={{ textTransform: 'uppercase', marginTop: "10px" }}>
                Parametros vitales
            </Typography>
            <Typography className={`${classes.titleTurn} ${classes.subtitleBox}`} style={{ marginLeft: "20px", gridRow: 2 }}>
                Información sin carácter sanitario conforme ley Nº 26529
            </Typography>
            <div style={{ gridColumn: "span 1", justifySelf: "right" }} className={classes.headerFiltro}>
                <CustomButton
                    title={"NUEVO "}
                    onClick={() => { props.setAdd(prevState => !prevState); }}
                    customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                    style={{ margin: "0 10px" }}
                />
                {filter()}
            </div>
        </div>
        <Loading
            isLoading={loadingLastRecord}
            children={myLastRecord()}
        />
    </>);
};

export default VitalParameters;

function widthClass(width, classes) {
    if (width <= 800) {
        return classes.width100;
    }
    else if (width > 800 && width < 1250) {
        return classes.width50;
    }
    else {
        return classes.width25;
    }
}

function classAccordion(swidth, classes) {
    if (swidth > 800) {
        return classes.AccordionWeightWeb;
    }
    else {
        return classes.AccordionWeightMobile;
    }
}

function setBackgroundMobile(classes, width) {
    let cssClass = classes.displayNone;
    if (width <= 768) {
        cssClass = classes.titlePersonalDataForm;
    }
    return cssClass;
}
