import * as actionTypes from "../consts/actionTypes";

const initialValues = {
    allLimitPlanData: [],
    post: {},
    put: {},
    delete: {},
    requestInProgress: false,
    refreshing: false,
    msgError: ''
};

const limitPlanReducer = (state = initialValues, action) => {

    function responseToReturn() {
        let res = { ...state };
        if (action.message) {
            res = { ...state, msgError: action.message };
        }
        return res;
    }

    switch (action.type) {
        case actionTypes.SUCCESS_GET_LIMIT_PLAN:
            if (action.response) {
                return { ...state, allLimitPlanData: action.response.data.limitPlan, refreshing: false,
                    requestInProgress: false, };
            }
            else {
                return { ...state };
            }

        case actionTypes.SUCCESS_POST_LIMIT_PLAN:
            window.location.reload();
            if (action.response) {
                return { ...state, post: action.response, refreshing: false,
                    requestInProgress: false, };
            }
            else {
                return { ...state };
            }

        case actionTypes.SUCCESS_PUT_LIMIT_PLAN:
            window.location.reload();
            if (action.response) {
                return { ...state, put: action.response, refreshing: false,
                    requestInProgress: false, };
            }

            else {
                return { ...state };
            }

        case actionTypes.SUCCESS_DELETE_LIMIT_PLAN:
            window.location.reload();
            if (action.response) {
                return { ...state, delete: action.response, refreshing: false,
                    requestInProgress: false, };
            }
            else {
                return { ...state };
            }

        case actionTypes.GET_LIMIT_PLAN:
            return {
                ...state,
                refreshing: true,
                requestInProgress: true,
            }
        case actionTypes.FAILURE_GET_LIMIT_PLAN:
        case actionTypes.FAILURE_POST_LIMIT_PLAN:
        case actionTypes.FAILURE_PUT_LIMIT_PLAN:
        case actionTypes.FAILURE_DELETE_LIMIT_PLAN:
            return responseToReturn();
        default:
            return state;
    }
}
export default limitPlanReducer;
