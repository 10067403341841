import { CircularProgress, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../../assets/styles/styles";
import { useCallback, useEffect, useState } from "react";
import { getSpecialties, setModelToSaveAppointment, getNextAvailableAppointmentsDoctor } from "../../redux/actions/appointmentsActions";
import { setNextStep } from "../../redux/actions/stepAppointmentsActions";
import { ValidateSteps } from "./Utils/ValidateSteps";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import CustomSelect from "../../controls/Select/CustomSelect";
import SelectDoctorAccordion from "./SelectDoctorAccordion";
import FooterDrawer from "./FooterDrawer";
import DoctorDetails from "./DoctorDetails"


const SpecialtyList = (props) => {

  const preferenceTurnList = [{ value: "", label: "SELECCIONE UNA OPCION" }, { value: 1, label: "PROXIMO TURNO MAS CERCANO" }, { value: 2, label: "TURNO POR PROFESIONAL" }];
  const [preference, setPreference] = useState({ preferenceCodigo: 0, preferenceDescripcion: '' });
  const { activeStep, setActiveStep, setPrefereceStep3, handleBack, handleOpenCancelModal, setSpecialistVisualizacion } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const specialties = useSelector((state) => state.medicalCenterReducer?.specialtyList);
  const [specialtyState, setSpecialty] = useState({ itemCodigo: 0, itemDescripcion: '' });
  const modelNextAppointment = useSelector(state => state.appointmentsReducer);
  const [modelAppointment, setModelAppointment] = useState(modelNextAppointment?.modelAppointment);
  const { screen } = useResizeScreen();
  const [groupSpecialties, setGroupSpecialties] = useState([]);
  const [disabled, setDisabled] = useState(false)

  const paramAll = {
    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
    medicoCodigo: "TODOS",
    count: 0,
    skip: 0,
    tipoAtencion: modelNextAppointment?.modelAppointment?.modalidadTurno == 'Telemedicina' ? 'TELEMEDICINA' : 'PRESENCIAL'
  };

  const doctorSchedule = useSelector(
    (state) => state.appointmentsReducer?.nextAvailableAppointmentsDoctor
  );

  const [doctor, setDoctor] = useState()

  const [preferenceTurnListState, setPreferenceTurnList] = useState(preferenceTurnList)

  const telefonico = "TURNO TELEFONICO";

  const handleClick = useCallback((medicos, pref) => (e) => {
 
    e.preventDefault();
    const { value } = e.target;
    if (screen.sWidth > 768) {
      setPrefereceStep3(pref.preferenceCodigo);
    }
    if (value != "") {
      let doctor = medicos.filter(x => x?.medicoCodigo == value)[0];
      setDoctor(doctor)
      setModelAppointment({ ...modelAppointment, medicoCodigo: doctor?.medicoCodigo, medicoDescripcion: doctor?.medicoDescripcion, doctorVisualizacion: doctor?.visualizacion });
      dispatch(setModelToSaveAppointment({ ...modelAppointment, medicoCodigo: doctor?.medicoCodigo, medicoDescripcion: doctor?.medicoDescripcion, doctorVisualizacion: doctor?.visualizacion }));
    }
  }, [modelAppointment]);

  const handleNextAppointmentClick = useCallback((specialtyCode, specialtyDescription) => (e) => {
    e.preventDefault();
    setModelAppointment({ ...modelAppointment, especialidadCodigo: specialtyCode, especialidadDescripcion: specialtyDescription });
  }, [modelAppointment]);

  useEffect(() => {
    setSpecialty({ itemCodigo: modelAppointment?.especialidadCodigo, itemDescripcion: modelAppointment?.especialidadDescripcion });
    if (modelAppointment?.especialidadCodigo == undefined || modelAppointment?.preference == undefined) {
      setModelAppointment({ ...modelAppointment, especialidadCodigo: '', especialidadDescripcion: '', preference: '' });
      dispatch(setModelToSaveAppointment({ ...modelAppointment, especialidadCodigo: '', especialidadDescripcion: '', preference: '' }));
    }
    if (modelNextAppointment.modelModifyAppointment != "") {
      setDisabled(true);
    }
    if (modelAppointment?.modalidadTurno == "Telemedicina") {
      dispatch(getSpecialties({
        numeroPersona: modelAppointment?.afiliadoCodigo,
        centroMedico: 0,
        tipoAtencion: "TELEMEDICINA",
        parametroLlamado: 0,
      }));
    }
  }, []);


  useEffect(() => {

    if(modelAppointment?.doctorVisualizacion == "TURNO WEB"){
      dispatch(
        getNextAvailableAppointmentsDoctor({
          ...paramAll,
          medicoCodigo: modelAppointment?.medicoCodigo?.toString(),
        })
      );
    }
    
  }, [modelAppointment?.medicoCodigo, modelAppointment.preference]);

  const getSchedule = () => {
    let turnosLibres = [];

    if(modelAppointment?.doctorVisualizacion == "TURNO WEB"){
      if (modelAppointment?.modalidadTurno === 'Telemedicina') {
        turnosLibres = doctorSchedule?.turnosLibres?.filter(element => element?.tipoAtencion === "R" && element?.medicoCodigo == modelAppointment?.medicoCodigo);
      } else {
        turnosLibres = doctorSchedule?.turnosLibres?.filter(element => element?.tipoAtencion === "P" && element?.medicoCodigo == modelAppointment?.medicoCodigo);
      }
    }
    

    return turnosLibres?.length > 0 ? turnosLibres[0] : undefined;
  }

  useEffect(() => {
    if (!modelNextAppointment?.modelAppointment?.especialidadCodigo) {
      return;
    }
  
    const { agenda } = specialties;
    const itemCode = specialtyState?.value ?? specialtyState?.itemCodigo;
    const specialtiesList = agenda?.filter(x => x?.especialidadCodigo === itemCode);
    const doctorList = specialtiesList?.filter(x => x?.medicoCodigo === modelAppointment?.medicoCodigo);
  
    const isSpontaneous = specialtiesList?.length > 0 && specialtiesList?.every(x => x?.visualizacion?.trim() === "DEMANDA ESPONTANEA")
    const isPhoneAppointment = specialtiesList?.length > 0 && specialtiesList?.every(x => x?.visualizacion === telefonico) || isSpontaneous;
  
    if (isSpontaneous) {
      setModelAppointment({ ...modelAppointment, doctorVisualizacion: "DEMANDA ESPONTANEA" });
      dispatch(setModelToSaveAppointment({ ...modelAppointment, doctorVisualizacion: "DEMANDA ESPONTANEA" }));
      setDoctor(doctorList?.[0]);
    }
    setPreferenceTurnList(isPhoneAppointment ? preferenceTurnList.filter(x => x.value !== 1) : preferenceTurnList);
  }, [specialtyState, specialties]);
  
  const goToNextStep = () => {
    if (modelAppointment?.especialidadCodigo !== "" && modelAppointment?.medicoCodigo !== "") {
      if (modelAppointment?.doctorVisualizacion) {
        setSpecialistVisualizacion(modelAppointment?.doctorVisualizacion)
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
  }
  
  const handleNext = () => {
    
    if(modelAppointment?.doctorVisualizacion == "DEMANDA ESPONTANEA"){
      return false;
    }

    if (screen.sWidth <= 768 && preference.preferenceCodigo == 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
      dispatch(setNextStep(activeStep + 2));
    }
    if (ValidateSteps(activeStep, modelNextAppointment.modelAppointment, screen)) {
      goToNextStep();
    }
  };

  const handleChangePreferenceTurn = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const selected = { preferenceCodigo: value, preferenceDescripcion: name };
    setDoctor(undefined)
    setPreference(selected);
    if (screen.sWidth > 768) {
      setPrefereceStep3(selected?.preferenceCodigo);
    }
    setModelAppointment({ ...modelAppointment, medicoCodigo: '', medicoDescripcion: '', doctorVisualizacion: '', preference: value });
    dispatch(setModelToSaveAppointment({ ...modelAppointment, medicoCodigo: '', medicoDescripcion: '', doctorVisualizacion: '', preference: value }));
  };

  useEffect(() => {
    if (preference.preferenceCodigo == 1) {
      setDoctor(undefined)
      handleNextAppointmentClick(specialtyState?.itemCodigo, specialtyState?.itemDescripcion);
      if (screen.sWidth > 768)
        setPrefereceStep3(preference.preferenceCodigo);
    }
  }, [preference]);


  useEffect(() => {
    let listSpecialties = specialties?.itemsEspecialidades?.map((item) => {
      return { value: item?.itemCodigo, label: item?.itemDescripcion };
    });
    if (listSpecialties != undefined) {
      listSpecialties.unshift({ value: "", label: "SELECCIONE UNA OPCION" })
    }
    setGroupSpecialties(listSpecialties);
  }, [specialties]);

  const handleChangeSpecialties = (e) => {
    let nameSpecialties = groupSpecialties?.filter((x) => x.value == e.target.value);

    const updateModelAppointment = {
      ...modelAppointment,
      especialidadCodigo: nameSpecialties?.[0]?.value,
      especialidadDescripcion: nameSpecialties?.[0]?.label,
      medicoCodigo: '',
      preference: '',
      turnoNumero: undefined,
      hourSelected: '',
      selectedAppointment: ''
    };

    setDoctor(undefined)

    if (nameSpecialties[0].value !== 0 && nameSpecialties !== null && specialtyState.value != nameSpecialties[0].value) {
      setSpecialty({ value: nameSpecialties[0].value, label: nameSpecialties[0].label });
      setModelAppointment(updateModelAppointment);
      dispatch(setModelToSaveAppointment(updateModelAppointment));
    } else {
      setSpecialty({ value: 0, label: '' });
      setModelAppointment({ ...modelAppointment, especialidadCodigo: '', especialidadDescripcion: '' });
    }
  };

  return (
    <>
      <div className={`${classes.drawerContainerTurn}`}>
        <Typography className={classes.textTitleTurn} style={{ marginLeft: "20px" }}>
          {props.titles.map((titles) => titles.title3)}
        </Typography>
        <Typography className={classes.titleTurn} style={{ margin: "0px " }}>
          {modelAppointment?.modalidadTurno}
        </Typography>
        {
          screen.sWidth > 900 && <div className={`${classes.boxPointsDrawer} ${classes.colorsLegends}`}>
            <div className={classes.boxPointsDrawer_AgendaSusp} />
            <Typography className={classes.txtLinksInCard} style={{ alignSelf: 'center', fontSize: '13px', margin: '5px' }}>Agendas suspendidas</Typography>
            <div className={classes.boxPointsDrawer_TurnoTelef} />
            <Typography className={classes.txtLinksInCard} style={{ alignSelf: 'center', fontSize: '13px', margin: '5px' }}>Turnos telefónicos</Typography>
            <div className={classes.boxPointsDrawer_DemandaEspon} />
            <Typography className={classes.txtLinksInCard} style={{ alignSelf: 'center', fontSize: '13px', margin: '5px' }}>Demanda espontánea</Typography>
          </div>
        }

        <div style={{ marginTop: "10px", display: "grid" }}>
          {groupSpecialties?.length > 0 ?
            <>
              <CustomSelect
                className={`${classes.afilliateSelectTurn}`}
                customClassList={classes.deploymentSelectTurn}
                list={groupSpecialties}
                selected={modelAppointment?.especialidadCodigo}
                disabled={disabled}
                onChange={handleChangeSpecialties}
              />
              {modelAppointment?.especialidadCodigo == "" && <span className={classes.spanError} style={{ width: "auto" }} >*Complete el campo</span>}
              {modelAppointment?.especialidadCodigo !== "" &&
                <>
                  <CustomSelect
                    className={`${classes.afilliateSelectTurn}`}
                    customClassList={classes.deploymentSelectTurn}
                    list={preferenceTurnListState}
                    selected={modelAppointment?.preference}
                    onChange={handleChangePreferenceTurn}
                    disabled={disabled}
                  />
                  {modelAppointment?.preference == "" && <span className={classes.spanError} style={{ width: "auto" }}>*Complete el campo</span>}
                </>
              }

              {(preference.preferenceCodigo == 2 || modelAppointment?.preference == 2) &&
                <SelectDoctorAccordion disabled={disabled} specialties={specialties} specialtyState={specialtyState} modelAppointment={modelAppointment} handleClick={handleClick} preference={preference} />
              }
              <div style={{ marginTop: "10px", width: "auto", marginLeft: screen.sWidth > 768 && "20px", marginBottom: "20px" }}>
                  <>
                    {(doctor && ["TURNO WEB", telefonico, "DEMANDA ESPONTANEA"].includes(modelAppointment?.doctorVisualizacion)) &&
                      <DoctorDetails schedule={getSchedule()} doctor={doctor} />
                    }
                    {(doctor && ["AGENDA SUSPENDIDA"].includes(modelAppointment?.doctorVisualizacion)) && screen.sWidth > 768 &&
                      <div style={{ display: "flex ", justifyContent: "center", marginTop: "0" }}>
                          <Typography
                              style={{ margin: "10px", color: "#0075C9" }}
                          >
                              No hay turnos disponibles
                          </Typography>
                      </div>
                    }
                  </>
                
              </div>
            </>
            :
            <div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}>
              <CircularProgress />
            </div>
          }
        </div>
        {screen.sWidth <= 768 && <div className={`${classes.boxPointsDrawer} ${classes.colorsLegends}`}>
          <div className={classes.boxPointsDrawer_AgendaSusp} />
          <Typography className={classes.txtLinksInCard} style={{ alignSelf: 'center', fontSize: '13px', margin: '5px' }}>Agendas suspendidas</Typography>
          <div className={classes.boxPointsDrawer_TurnoTelef} />
          <Typography className={classes.txtLinksInCard} style={{ alignSelf: 'center', fontSize: '13px', margin: '5px' }}>Turnos telefónicos</Typography>
          <div className={classes.boxPointsDrawer_DemandaEspon} />
            <Typography className={classes.txtLinksInCard} style={{ alignSelf: 'center', fontSize: '13px', margin: '5px' }}>Demanda espontánea</Typography>
        </div>
        }
        {screen.sWidth <= 768 &&
          <FooterDrawer activeStep={activeStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} handleNext={handleNext} />
        }

      </div>
    </>
  );
};

export default SpecialtyList;

function setCssClasses(item, classes) {
  let cssClass = '';
  if (item === 'AGENDA SUSPENDIDA') {
    cssClass = classes.boxPointsDrawer_AgendaSusp;
  } else if (item === telefonico) {
    cssClass = classes.boxPointsDrawer_TurnoTelef;
  }
  return cssClass;
}