import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

/**agregar el llamado a las funciones que se comunican con la api */
function* asyncGetUrlDoctorCaller({ payload }) {
    try {
        let response = yield call(API.postCallDoctor, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_POST_CALL_DOCTOR, response });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_POST_CALL_DOCTOR,
            message: error
        });
    }
}

// Watchers
export default function* virtualDoctorSaga() {
    yield takeLatest(actionTypes.POST_CALL_DOCTOR, asyncGetUrlDoctorCaller);
}
