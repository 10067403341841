import React, { useEffect, useState, Fragment } from 'react';
import CustomizedInputs from "../../../controls/InputCustom/CustomizedInputs";
import useStyles from "../../../assets/styles/styles";
import { FormControl, FormHelperText, IconButton, InputAdornment, Typography } from "@material-ui/core";
import CustomButton from '../../../controls/Button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import CustomSelect from '../../../controls/Select/CustomSelect';
import EditIcon from '@material-ui/icons/Edit';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ConfirmModal from '../ConfirmModal';
import UploadModal from "../../../controls/UploadModal/UploadModal";
import { validate, deleteFile, handleSave, showBtn } from '../../../utils/utilsFunctions';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import LineBreakInput from "../../../controls/InputCustom/LineBreakInput"

const GeneralProcedure = ({ handleBack, procedureCodeSelected }) => {
    const classes = useStyles();
    const data = useSelector(state => state.procedureReducer);
    const postProcedureSelector = useSelector(state => state.procedureReducer.postProcedure);
    const fullPersonalData = useSelector(state => state.personalDataReducer);
    const dispatch = useDispatch();
    const [mail, setMail] = useState('');
    const [phone, setPhone] = useState('');
    const [disabledPhone, setDisabledPhone] = useState(true);
    const [disabledEmail, setDisabledEmail] = useState(true);
    const [procedureSelected, setProcedure] = useState(
        {
            procedure: '',
            subProcedure: ''
        });
    const [listOfSubProcess, setListOfSubProcess] = useState([{ value: 0, label: '' }]);
    const [modelToSend, setModelToSend] = useState({
        mailModificar: "N",
        sector: "AFILIACIONES"
    });
    const [files, setFiles] = useState([]);
    const [listOfAttachmentButtons, setListOfAttachmentButtons] = useState([]);
    const [fileButtons, setFileButtons] = useState([]);
    const [nameOfItem, setItemName] = useState('');
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [openSendSuccess, setOpenSendSuccess] = useState(false);
    const [error, setError] = useState([]);
    const [modalTitle, setModalTitle] = useState('');
    const [modalDescription, setModalDescription] = useState("");
    const [modalIcon, setModalIcon] = useState('');
    const [errorClass, setErrorClass] = useState('');
    const [itemRequired, setItemRequired] = useState('');
    const [itemTipoArchivo, setItemTipoArchivo] = useState(0);
    const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);
    const { screen } = useResizeScreen();
    const [counterLength, setCounterLength] = useState(0);


    useEffect(() => {
        if (data?.procedures?.tramites) {
            let subProcess = data?.procedures?.tramites?.filter(x => x.tramiteCodigo == procedureCodeSelected)[0]?.subTramites?.map(item => {
                return { ...item, value: item.subTramiteCodigo, label: item.subTramiteDescripcion };
            });
            setListOfSubProcess(subProcess);
            setProcedure({ ...procedureSelected, 'subProcedure': subProcess[0]?.value });
            setFiles([]);
            setFileButtons([]);
            setModelToSend({ ...modelToSend, 'tramiteCodigo': procedureCodeSelected });
        }
    }, [procedureCodeSelected]);

    useEffect(() => {
        if (procedureSelected?.subProcedure !== '') {
            let auxAttachments = listOfSubProcess?.filter(x => x.subTramiteCodigo === procedureSelected?.subProcedure)[0]?.adjuntos;
            setListOfAttachmentButtons(auxAttachments);
            setFiles([]);
            setFileButtons([]);
            setModelToSend({ ...modelToSend, 'subTramiteCodigo': procedureSelected?.subProcedure });
        }
    }, [procedureSelected]);

    useEffect(() => {
        let mailUser = fullPersonalData?.fullData?.afiliado?.mails?.filter(z => z.mailTipoCodigo === 1)[0]?.mail;
        if (mailUser !== undefined && mailUser !== null) {
            setMail(mailUser);
            setModelToSend({ ...modelToSend, 'mail': mailUser });
        }
        const lengthPhones = fullPersonalData?.fullData?.afiliado?.telefonos?.length;
        const phoneUser = lengthPhones !== 0 ? fullPersonalData?.fullData?.afiliado?.telefonos[0].telefono : "";
        if (phoneUser !== undefined && phoneUser !== null) {
            setPhone(phoneUser);
            setModelToSend({ ...modelToSend, 'telefono': phoneUser });
        }
    }, [fullPersonalData?.fullData]);

    const isDisabled = (typeName) => {
        let response = false;
        if (fileButtons[typeName]) {
            response = true;
        }
        return response;
    };

    const handleChangeSelect = (e) => {
        const { name, value } = e.target;
        setProcedure({ ...procedureSelected, [name]: value });
    };

    const handleEditEmail = () => {
        setDisabledEmail(prevState => !prevState);
    };

    const handleChangeMail = () => (e) => {
        const { name, value } = e.target;
        setModelToSend({ ...modelToSend, [name]: value, 'mailModificar': "S" });
        setMail(value);
    };

    useEffect(() => {
        const obligatoryField = '*Campo obligatorio';
        const emailFormatValidation = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        setError({ ...error, mail: mail ? '' : obligatoryField })

        if (!emailFormatValidation.test(mail) && mail !== '') {
            setError({ ...error, mail: 'Formato requerido: mail@mail.com' })
        }
    }, [mail])

    const handleChangeText = () => e => {
        const { value, name } = e.target;
        if (name !== 'consulta') {
                setPhone(value);
                validate(name, '*Campo obligatorio', value, setError, error);
            }
            setModelToSend({ ...modelToSend, [name]: value });
        
    };

    const handleChangeObservations =() => e => {
        const { value } = e.target;
            if (value.length <= 2000) {
        setCounterLength(value.length);
            }
    }

    const handleEditPhone = () => {
        setDisabledPhone(prevState => !prevState);
    };

    const handleOpenModal = (itemName, fileRequired, clasificacion) => (e) => {
        e.preventDefault();
        setOpenUploadModal(prevState => !prevState);
        setItemName(itemName);
        setItemRequired(fileRequired);
        setItemTipoArchivo(clasificacion);
    };

    const validateFiles = () => {
        let auxFiles = [];
        let response = false;
        listOfAttachmentButtons.forEach(item => {
            if (item.adjuntoObligatorio === 'S') {
                auxFiles.push(item.adjuntoDescripcion);
            }
        });
        if (auxFiles.length > 0) {
            let aux = [];
            files?.forEach(item => {
                if (item?.adjuntoObligatorio === 'S') {
                    aux.push(auxFiles?.some(x => x === item.btnNombre));
                }
            });
            if (aux.length > 0 && Object.values(aux)?.every(x => x === true)) {
                response = true;
            }
        } else {
            response = true;
        }
        return response;
    };

    function deleteParameterAdjunto() {
        files?.forEach((item) => {
            delete (item['btnNombre']);
            delete (item["adjuntoObligatorio"]);
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let modelWS = {
            ...modelToSend,
            mail: mail,
            telefono: phone,
            tramiteCodigo: procedureCodeSelected,
            subTramiteCodigo: procedureSelected?.subProcedure,
            numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
        };
        if (validateFiles() && validateForm(modelWS, setError)) {
            deleteParameterAdjunto();
            let model = {
                ...modelToSend,
                mail: mail,
                telefono: phone,
                tramiteCodigo: procedureCodeSelected,
                subTramiteCodigo: procedureSelected?.subProcedure,
                numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                adjuntos: files?.length > 0 ? JSON.stringify(files) : null
            };
            handleSave({ mail, setModalTitle, setModalDescription, setModalIcon, setErrorClass, setOpenSendSuccess, classes, error, dispatch, model });
        }
    };

    useEffect(() => {
        if (postProcedureSelector?.confirmacion) {
            setModalTitle('TU GESTIÓN SE HA INICIADO');
            setModalDescription(`Será respondida al email: ${mail}`);
            setModalIcon(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
            setErrorClass('');
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else if (!postProcedureSelector?.confirmacion && postProcedureSelector?.confirmacion !== undefined) {
            setOpenSendSuccess(true);
            setModalTitle('Ocurrió un error');
            setModalDescription(postProcedureSelector?.mensajes?.map(item => { return item.mensaje + ' '; }) ?? 'Por favor, intente nuevamente');
            setModalIcon(<CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
            setErrorClass(classes.backgroundError);
        }
        return (() => {
            if (postProcedureSelector?.confirmacion) {
                setModalTitle('');
                setModalDescription('');
                setModalIcon('');
                setErrorClass('');
                setTimeout(() => {
                    window.location.replace('/PersonalData');
                }, 4000);
            }
        });
    }, [postProcedureSelector?.confirmacion]);

    const observationsLabel = ({ sWidth }) => (
        <span style={{ marginLeft: sWidth < 768 ? "-28px" : "1px" }}>OBSERVACIONES</span>
    );

    return (
        <>
            <FormControl className={`${classes.formControlCustomSelect} ${classes.itemGridSpan2}`} style={{right:'20px'}}>
                <CustomSelect
                    label={"SUBTRAMITES"}
                    name='subProcedure'
                    list={listOfSubProcess}
                    selected={procedureSelected?.subProcedure}
                    onChange={handleChangeSelect}
                />
                <FormHelperText className={classes.errorText}>{error?.subProcedure}</FormHelperText>
            </FormControl>
            <FormControl className={`${classes.formControlAddress} ${classes.itemGridSpan2}`} style={{ right:'15px',marginTop: '5px' }} >

                <CustomizedInputs
                    label={"E-MAIL"}
                    value={mail}
                    name='mail'
                    disabled={disabledEmail}
                    onChange={handleChangeMail()}
                    endAdornment={
                        <InputAdornment position="start">
                            <IconButton onClick={handleEditEmail} size='small'>
                                <EditIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <FormHelperText className={classes.errorText}>{error?.mail}</FormHelperText>
            </FormControl>
            <FormControl className={`${classes.formControlAddress} ${classes.itemGridSpan2}`} variant="standard" style={{right: screen.sWidth < 768 ? '15px' :'10px', marginTop: '5px'}}>
                <CustomizedInputs
                    value={phone}
                    label='TELEFONO'
                    name='telefono'
                    disabled={disabledPhone}
                    onChange={handleChangeText()}
                    endAdornment={
                        <InputAdornment position="start">
                            <IconButton onClick={handleEditPhone} size='small'>
                                <EditIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                    inputProps={{ maxLength: 20 }}
                />
                <FormHelperText className={classes.errorText}>{error?.telefono}</FormHelperText>
            </FormControl>
            <FormControl className={`${classes.commentGrid}  ${classes.formControlChronicMedicationForm}`} variant="standard" style={{ right: screen.sWidth < 768 ? '15px' : '10px', marginLeft: screen.sWidth < 768 ? '15px' : '-6px', }}>
                <LineBreakInput
                    name="consulta"
                    style={{ marginTop: "25px", marginLeft: "-1px" }}
                    label={observationsLabel(screen)}
                    placeholder='Escribe tu texto aqui'
                    onChange={handleChangeObservations()}
                    multiline
                    rows={5}
                    inputProps={{ maxLength: 2000 }}
                />
                <Typography variant="caption" style={{ position: 'absolute', bottom: '-10px', right: screen.sWidth < 768 ? '45px' : '110px' }}>{counterLength}/2000</Typography>
            </FormControl>
            <div className={classes.itemGridAdjuntarArchivos}>
                {listOfAttachmentButtons?.length === 0 ? ""
                    :
                    <>
                        <Typography className={`${classes.titleTurn} ${classes.titleBox}`} style={{ textTransform: 'uppercase', fontSize: "14px", margin: "0 auto", padding: screen.sWidth <= 768 ? '5px 5px 5px 15px'  : '5px 5px 5px 5px'  }}>
                            ADJUNTAR ARCHIVOS
                        </Typography>
                        <Typography className={`${classes.titleTurn} ${classes.titleBox}`} style={{ textTransform: 'uppercase', fontSize: "10px", color: "#002858", margin: "0 auto", padding: screen.sWidth <= 768 ? '5px 5px 5px 15px'  : '5px 5px 5px 5px'  }}>
                            {`(TAMAÑO MAXIMO ${configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor}MB)`}
                        </Typography>
                    </>
                }
                {listOfAttachmentButtons.map((item, i) => (
                    <Fragment key={i}>
                        <CustomButton
                            name={item.adjuntoDescripcion}
                            style={{ padding: '5px 5px 5px 5px', marginLeft: screen.sWidth <= 768 ? '15px' : '5px' }}
                            title={`ADJUNTAR ${item.adjuntoDescripcion}`}
                            icon={<AttachFileIcon />}
                            customClass={`${classes.lightLinearGradient} ${classes.marginLeft}`}
                            onClick={handleOpenModal(item.adjuntoDescripcion, item.adjuntoObligatorio, item.adjuntoClasificacion)}
                            disabled={isDisabled(item.adjuntoDescripcion)}
                        />
                        {item.adjuntoObligatorio === 'S' &&
                            <FormHelperText className={classes.errorText}>*Archivo obligatorio</FormHelperText>
                        }
                    </Fragment>
                ))}
                {files?.map((item, i) => (
                    <p key={i} style={{ marginLeft: "5%" }} className={classes.txtSubtitleCalendar}>{item?.archivoNombre} <IconButton onClick={deleteFile(fileButtons, setFileButtons, setFiles, files, item?.btnNombre, item?.archivoNombre)}><HighlightOffIcon /></IconButton></p>
                ))}
            <div className={`${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm} ${classes.gridBtnEnviar}`}>
                {
                    <CustomButton
                        disabled={showBtn(listOfAttachmentButtons, files, error)}
                        customClass={classes.lightLinearGradient}
                        style={{ padding: '5px 20px 5px 20px', marginBottom: "20px" ,marginRight: "100px", marginLeft: "15px"}}
                        title='Enviar'
                        type='button'
                        onClick={handleSubmit}
                    />
                }
            </div>
            </div>

            <ConfirmModal isOpen={openSendSuccess} setOpen={setOpenSendSuccess} errorClass={errorClass}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalIcon}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {modalTitle}
                        </div>
                    </>
                }
                description={modalDescription} />
            <UploadModal
                title='Adjuntar archivo'
                description=''
                observation=''
                open={openUploadModal}
                onClose={setOpenUploadModal}
                setModalTitle={setModalTitle}
                setModalDescription={setModalDescription}
                setModalIcon={setModalIcon}
                setErrorClass={setErrorClass}
                setOpenSendSuccess={setOpenSendSuccess}
                setFiles={setFiles}
                files={files}
                fileButtons={fileButtons}
                setFileButtons={setFileButtons}
                itemName={nameOfItem}
                isRequired={itemRequired}
                archivoClasificacion={itemTipoArchivo}
            />

        </>
    );
};

export default React.memo(GeneralProcedure, (prevProps, nextProps) => {
    return prevProps.procedureCodeSelected === nextProps.procedureCodeSelected;
}
);

function validateForm(object, setError) {
    let temp = object;
    let aux = {};
    let validation = [];
    let Obj = {};
    Obj.tramiteCodigo = temp.tramiteCodigo >= 0 ? '' : '*Campo obligatorio';
    Obj.subTramiteCodigo = temp.subTramiteCodigo >= 0 ? '' : '*Campo obligatorio';
    Obj.mail = temp.mail ? '' : '*Campo obligatorio';
    Obj.telefono = temp.telefono !== "" ? '' : '*Campo obligatorio';
    aux = Obj;
    if (Object.values(aux).every((x) => x === "")) {
        validation.push(true);
    } else {
        validation.push(false);
    }
    setError(aux);
    return Object.values(validation).every((valid) => valid === true);
}