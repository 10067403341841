import { Box, Tab, Tabs, IconButton } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from "react";
import Header from "../GenericHeader/Header";
import Menu from "../Menu/Menu";
import useStyles from '../../assets/styles/styles';
import SwipeableViews from "react-swipeable-views";
import TabPanel from "../../controls/TabPanel/TabPanel";
import Details from "./RefundsTabPanel/Details";
import Comments from "./RefundsTabPanel/Comments";
import { useDispatch, useSelector } from "react-redux";
import { getRefundComments, getRefundDetails } from "../../redux/actions/refundsActions";
import { useLocation } from "react-router-dom";
import NotFound from "../HomeLogged/NotFound";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useResizeScreen } from "../../hooks/useResizeScreen";
import { useHistory } from "react-router";
import { showErrorMessageModal } from "../../utils/utilsFunctions";

const RefundsDetails = () => {
    const dispatch = useDispatch();
    const { screen } = useResizeScreen();
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const theme = useTheme();
    const refundsReduxState = useSelector(state => state.refundsReducer);
    const location = useLocation();
    const history = useHistory();
    const [ detailsParameters, setDetailsParameters ] = useState(location.state.params);
    const [ listDetails, setListDetails ] = useState([]);
    const [openModalError, setOpenModalError] = useState(false);
    const { msgError } = useSelector(state => state.refundsReducer);

    useEffect(() => {
        if(detailsParameters?.[0] != undefined) {
            dispatch(getRefundComments(detailsParameters?.[0]));
            dispatch(getRefundDetails(detailsParameters?.[0]));
        }
    }, [detailsParameters])

    useEffect(() => {
        if(msgError !== "" && msgError !== undefined) {
            setOpenModalError(true);
            setTimeout(() => { window.location.replace('/Refunds'); }, 3000);
        }

    }, [msgError])

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    function axisValue(condition) {
        let response = '';
        if(condition === 'rtl') {
            response = 'x-reverse';
        } else {
            response = 'x';
        }
        return response;
    }

    const goToRefundsHome = () => {
        setDetailsParameters({});
        history.push('/Refunds');
    }

    return (
        <>
            <Menu />
            <Header title='Reintegros' />
            <Box>
            {
                refundsReduxState?.refundDetails?.reintegroDetalle?.length > 0 ?
                    <>
                        <IconButton
                            onClick={goToRefundsHome}
                            style={{ marginLeft: screen.sWidth > 900 ? "60px" : "25px", marginTop: "20px" }}
                        >
                            <ArrowBackIcon className={classes.iconFilter} />
                        </IconButton>
                        <div className={classes.authorizationHeader}>
                            <Tabs className={classes.authorizationTabs} value={value} onChange={handleChange} centered>
                                <Tab label="DETALLE" />
                                <Tab label="COMENTARIOS" />
                            </Tabs>
                        </div>
                        
                        <SwipeableViews
                            style={{ overflow: 'hidden' }}
                            axis={axisValue(theme.direction)}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <Details
                                    detailsParameters={detailsParameters}
                                    listDetails={listDetails}
                                    setListDetails={setListDetails}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <Comments item={refundsReduxState} detailsParameters={detailsParameters[1]}/>
                            </TabPanel>
                        </SwipeableViews>
                    </>
                :
                    <NotFound
                        customMsg={
                            <div className={classes.contentNotFound}>
                                <span>No hay contenido para mostrar</span>
                            </div>
                        }
                        wsItem={refundsReduxState?.refundDetails?.reintegroDetalle}
                        fullItem={refundsReduxState?.refundDetails?.reintegroDetalle?.length}
                        loading={refundsReduxState?.loading}
                    />
            }
            </Box>

            {showErrorMessageModal(msgError, openModalError, setOpenModalError, classes.borderWhite, classes.quickAccessIcon)}
        </>
    );
};

export default RefundsDetails;
