import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetPlanPdfByPlanCode({ payload }) {
    try {
        let response = yield call(API.getPlanPdfByPlanCode, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_PLANPDF_BY_PLANCODE, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_PLANPDF_BY_PLANCODE, message: error.message });
    }
}

export default function* planPdfSaga() {
    yield takeLatest(actionTypes.GET_PLANPDF_BY_PLANCODE, asyncGetPlanPdfByPlanCode);
}
