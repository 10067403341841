import { combineReducers } from "redux";
import demoReducer from "./demoReducer";
import cartillaReducer from "./cartillaReducer";
import appointmentsReducer from "./appointmentsReducer";
import medicalCenterReducer from "./medicalCenterReducer";
import stepAppointmentReducer from "./stepAppointmentReducer";
import emergencyReducer from "./emergencyReducer";
import notificationReducer from "./notificationReducer";
import phonesReducer from "./phonesReducer";
import personalDataReducer from './personalDataReducer';
import errorReducer from "./errorReducer";
import medicalDataReducer from "./medicalDataReducer";
import registerUsersReducer from "./registerUsersReducer";
import coverageReducer from "./coverageReducer";
import virtualDoctorReducer from "./virtualDoctorReducer";
import contactUsReducer from "./contactUsReducer";
import credentialReducer from "./credentialReducer";
import activitiesReducer from './activitiesReducer';
import procedureReducer from './procedureReducer';
import preventionReducer from "./preventionReducer";
import personPlayerReducer from "./personPlayerReducer";
import billingReducer from "./billingReducer";
import authorizationsReducer from "./authorizationsReducer";
import refundsReducer from "./refundsReducer";
import surveyReducer from "./surveyReducer";
import newsReducer from "./newsReducer";
import sedesReducer from "./sedesReducer";
import configurationReducer from "./configurationReducer";
import cardReducer from "./cardReducer";
import planPdfReducer from "./planPdfReducer";
import practicasReducer from "./practicasReducer";
import telemedicineAppointmentsReducer from "./telemedicineAppointmentsReducer";
import capsAndCopayLinksReducer from "./capsAndCopayLinksReducer";
import programRequirementAndFormReducer from "./programRequirementAndFormReducer";
import limitPlanReducer from './limitPlanReducer';
import campaignReducer from './campaignReducer';

const rootReducer = combineReducers({
  demoReducer,
  cartillaReducer: cartillaReducer,
  appointmentsReducer: appointmentsReducer,
  medicalCenterReducer: medicalCenterReducer,
  stepAppointmentReducer: stepAppointmentReducer,
  emergencyReducer: emergencyReducer,
  notificationReducer: notificationReducer,
  phonesReducer: phonesReducer,
  personalDataReducer: personalDataReducer,
  errorReducer: errorReducer,
  registerUsersReducer: registerUsersReducer,
  medicalDataReducer: medicalDataReducer,
  coverageReducer: coverageReducer,
  virtualDoctorReducer: virtualDoctorReducer,
  contactUsReducer: contactUsReducer,
  credentialReducer: credentialReducer,
  activitiesReducer: activitiesReducer,
  procedureReducer: procedureReducer,
  preventionReducer: preventionReducer,
  personPlayerReducer: personPlayerReducer,
  billingReducer: billingReducer,
  authorizationsReducer: authorizationsReducer,
  refundsReducer: refundsReducer,
  surveyReducer: surveyReducer,
  newsReducer: newsReducer,
  sedesReducer: sedesReducer,
  campaignReducer : campaignReducer,
  configurationReducer: configurationReducer,
  cardReducer: cardReducer,
  planPdfReducer: planPdfReducer,
  practicasReducer: practicasReducer,
  telemedicineAppointmentsReducer: telemedicineAppointmentsReducer,
  capsAndCopayLinksReducer: capsAndCopayLinksReducer,
  programRequirementAndFormReducer: programRequirementAndFormReducer,
  limitPlanReducer: limitPlanReducer,
});

export default rootReducer;
