
import MenuBookIcon from '@material-ui/icons/MenuBook';
import TocIcon from '@material-ui/icons/Toc';
import { useResizeScreen } from "../../hooks/useResizeScreen";
import Menu from "../../components/Menu/Menu";
import useStyles from "../../assets/styles/styles";
import { Fragment, useEffect, useState } from 'react';
import QuickAccessBox from '../../components/HomeLogged/QuickAccessBox';
import Header from '../../components/GenericHeader/Header';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SelectTypeOfComponent from '../../components/PersonalData/SelectTypeOfComponent';
import { useDispatch } from "react-redux";
import { getAffiliateEnrollments } from "../../redux/actions/medicalDataAction";
import useTrackPageView from "../../utils/useTrackPageView";

const ProgramsHome = () => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const [type, setType] = useState(-1);
    const [mobileDevice, setMobileDevice] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const trackPageView = useTrackPageView("Programas Médicos");
    const { search } = useLocation();
    const match = search.match(/type=(.*)/);
    const typeUrl = match?.[1];

    const listIconAccess = [
        { title: 'Programas vigentes', icon: <MenuBookIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 0, path: '/ProgramsHome?type=Programs' },
        { title: 'Requisitos y formularios', icon: <TocIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 1, path: '/ProgramsHome?type=RequirementsAndForms' },
    ];

    const paramFav = {
        numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
        count: 10,
        skip: 0,
    };
    const handleClick = (item) => (e) => {
        setType(item.type);
    };

    useEffect(() => {
        if (screen.sWidth <= 768)
            setMobileDevice(true);
        else
            setMobileDevice(false);
    }, [screen.sWidth, type]);


    useEffect(() => {
        dispatch(getAffiliateEnrollments(paramFav));
        trackPageView();
    }, []); 

    useEffect(() => {
        window.onpopstate = (e) => {
            if (screen.sWidth <= 768 && type !== -1)
                window.location.href = '/ProgramsHome';
            else {
                history.push('/MedicalData');
            }
        };
    });

    useEffect(() => {
        if (typeUrl !== undefined) {
            let auxURL = `/ProgramsHome?type=${typeUrl}`;
            let aux = listIconAccess?.filter(x => x.path === auxURL);
            setType(aux[0]?.type);
        }else{
            setType(-1);
        }
    }, [typeUrl]);

    return (
        <>
            <Menu />
            {type === -1 || !mobileDevice ?
                <>
                    <Header title='Datos Médicos' />
                    <div className={`${classes.divQuickAccess} ${classes.customScroll}`} style={{ justifyContent: 'center' }}>
                        {listIconAccess.map((item, i) => (
                            <Fragment key={i}>
                                <QuickAccessBox
                                    component={Link}
                                    to={item.path}
                                    handleClick={handleClick(item)}
                                    i={i}
                                    item={item}
                                    classes={classes}
                                    customClass={classes.programsDataItem}
                                    iconClass={classes.personalDataDivIcons}
                                    txtIcon={classes.personalDataTxtIcon}
                                    style={{marginTop: "-10px"}}
                                />
                            </Fragment>

                        ))}
                    </div>
                </>
                :
                ''
            }
            <SelectTypeOfComponent />
        </>
    );
};

export default ProgramsHome;
