import { Box, Tab, Tabs, IconButton } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from "react";
import Header from "../GenericHeader/Header";
import Menu from "../Menu/Menu";
import useStyles from '../../assets/styles/styles';
import SwipeableViews from "react-swipeable-views";
import TabPanel from "../../controls/TabPanel/TabPanel";
import Details from "./AuthorizationsTabPanel/Details";
import Comments from "./AuthorizationsTabPanel/Comments";
import { useDispatch, useSelector } from "react-redux";
import { getAuthorizationDetails, clearModelDetails } from "../../redux/actions/authorizationsActions";
import { useHistory } from "react-router-dom";
import NotFound from "../HomeLogged/NotFound";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useResizeScreen } from "../../hooks/useResizeScreen";
import { showErrorMessageModal } from "../../utils/utilsFunctions";

const AuthorizationsDetails = () => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const [value, setValue] = useState(0);
    const theme = useTheme();
    const dispatch = useDispatch();
    const authorizationReduxState = useSelector(state => state.authorizationsReducer);
    const history = useHistory();
    const errorMessage = useSelector(state => state.authorizationsReducer.msgError);
    const [openError, setOpenError] = useState();
    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    useEffect( () => () => dispatch(clearModelDetails()), [] );

    useEffect(() => {
        if (Object.values(authorizationReduxState?.authorizationDetails).length === 0) {
            history.push('/Authorizations');
        }
        dispatch(getAuthorizationDetails());
    }, []);

    useEffect(() => {
        if (errorMessage !== "" && errorMessage !== undefined) {
            setOpenError(true);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (openError === false) {
            history.push('/Authorizations');
        }
    }, [openError])

    function axisValue(condition) {
        var response = '';
        if(condition === 'rtl') {
            response = 'x-reverse';
        } else {
            response = 'x';
        }
        return response;
    }

    return (
        <>
            <Menu />
            <Header title='Autorizaciones' />
            <Box>
            {authorizationReduxState?.authorizationDetail?.length !== 0 ?
                <>
                    <IconButton
                        onClick={() => history.push('/Authorizations')}
                        style={{ marginLeft: screen.sWidth > 900 ? "60px" : "25px", marginTop: "20px" }}
                        >
                        <ArrowBackIcon className={classes.iconFilter} />
                    </IconButton>
                    
                    <div className={classes.authorizationHeader}>
                        <Tabs className={classes.authorizationTabs} value={value} onChange={handleChange} centered>
                            <Tab label="DETALLE" />
                            <Tab label="COMENTARIOS" />
                        </Tabs>
                    </div>
                </>
                :
                <NotFound customMsg={
                    <div className={classes.contentNotFound}>
                      <span className={classes.notAppointment}>No hay contenido para mostrar</span>
                    </div>}
                    wsItem={authorizationReduxState?.authorizationDetail?.length}
                    fullItem={authorizationReduxState?.authorizationDetail?.length}
                />
            }
                {authorizationReduxState?.authorizationDetail?.length !== 0 ?
                    <SwipeableViews
                        style={{ overflow: 'hidden' }}
                        axis={axisValue(theme.direction)}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <Details item={authorizationReduxState} />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <Comments item={authorizationReduxState} />
                        </TabPanel>
                    </SwipeableViews> :
                    <NotFound customMsg={
                        <div className={classes.contentNotFound}>
                          <span className={classes.notAppointment}>No hay contenido para mostrar</span>
                        </div>}
                        wsItem={authorizationReduxState?.authorizationDetail?.length}
                        fullItem={authorizationReduxState?.authorizationDetail?.length}
                    />
                }
            </Box>
            {showErrorMessageModal(errorMessage, openError, setOpenError, classes.borderWhite, classes.quickAccessIcon)}
        </>
    );
};

export default AuthorizationsDetails;
