import React from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CustomButton from '../../../../controls/Button/CustomButton';
import { IconButton } from '@material-ui/core';
import { useResizeScreen } from '../../../../hooks/useResizeScreen';
import useStyles from "../../../../assets/styles/styles";
import { useSelector } from 'react-redux';



const TopButtons = (props) => {
    const { activeStepTurn, handleOpenCancelModalTurn, handleBackTurn, handleNextTurn, titleCancel, titleConfirm, disabledNext=false } = props;
    const { screen } = useResizeScreen();
    const classes = useStyles();
    const modelNextAppointment = useSelector(state => state.appointmentsReducer);
    const flow = modelNextAppointment?.modelAppointment?.appointmentFlow;


    function displayButtons() {
        let response = "space-between";
        if (activeStepTurn == 0) {
            response = "flex-end"
        }
        return response;
    }

    const showNextButton = () => {
        return (modelNextAppointment?.modelAppointment?.especialidadDescripcion !== "ATENCION CLINICA ESPONTANEA") || (flow == 'medico' || flow == 'especialidad');
    }

    const nextStep = () => {
        if ((flow == 'medico') && (activeStepTurn == 2) && (modelNextAppointment?.modelAppointment?.turnoNumero == undefined)) {
            return null;
        } else {
            return handleNextTurn;
        }
    }

    return (
        <>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CustomButton
                    title={titleCancel}
                    customClass={`${classes.whiteAndBlueBtn} ${classes.fontBold}`}
                    style={{ minWidth: "100px" }}
                    onClick={handleOpenCancelModalTurn}
                />
            </div>
            <div style={{ display: "flex", justifyContent: displayButtons(), alignItems: "center", paddingTop: "10px" }}>
                <div>
                    {activeStepTurn !== 0 && (modelNextAppointment.modelModifyAppointment == "" || modelNextAppointment.modelModifyAppointment == undefined) &&
                        <IconButton onClick={handleBackTurn}>
                            <ArrowBackIcon className={classes.ShowMoreArrowIcon} style={{ fontSize: "25px" }} />
                        </IconButton>}
                </div>
                {screen.sWidth > 768 && showNextButton() &&
                    <CustomButton
                        title={titleConfirm}
                        customClass={`${classes.lightLinearGradient} ${classes.fontBold}`}
                        style={{ minWidth: "100px", fontSize: "12px" }}
                        disabled={disabledNext}
                        onClick={nextStep()}
                    />
                }
            </div>
        </>
    )
}

export default TopButtons
