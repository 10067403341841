import { CircularProgress } from "@material-ui/core";
import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import AuthorizationsIndex from "../../components/Authorizations/AuthorizationsIndex";
import NewRequest from "../../components/Authorizations/NewRequest/NewRequest";
import Header from "../../components/GenericHeader/Header";
import Menu from "../../components/Menu/Menu";
import { getDataGroupFamily } from "../../redux/actions/appointmentsActions";
import { getParamsAuthRequest, setModelAuthRequest } from "../../redux/actions/authorizationsActions";
import { getLimitPlan } from "../../redux/actions/limitPlansAction";
import { notAccessToThisModule, verifyPermission } from "../../utils/utilsFunctions";
import useTrackPageView from "../../utils/useTrackPageView";

const persona = {
    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona"))
};

const Authorizations = () => {
    const trackPageView = useTrackPageView("Autorizaciones");
    const dispatch = useDispatch();
    const { search } = useLocation();
    const affiliatePlan = useSelector((state) => state.personalDataReducer?.fullData?.afiliado?.planDescripcion);
    const limitPlanReducer = useSelector((state) => state.limitPlanReducer.allLimitPlanData);
    const loadingPermission = useSelector((state) => state.limitPlanReducer.requestInProgress);
    const [ hasPermission, setHasPermission ] = useState(false);
    const match = search.match(/type=(.*)/);
    const typeURL = match?.[1];

    useLayoutEffect(() => {
        verifyPermission(limitPlanReducer, affiliatePlan, "Autorizaciones", setHasPermission);
    }, [limitPlanReducer, affiliatePlan, hasPermission]);

    function isEnabled(condition) {
        var resp;
        if(condition) {
            resp = (notAccessToThisModule());
        } else {
            resp = (<AuthorizationsIndex />);
        }
        return resp;
    }

    useEffect(() => {
        trackPageView();
        dispatch(getLimitPlan());
    }, []);

    useEffect(() => {
        dispatch(getDataGroupFamily(persona));
        dispatch(getParamsAuthRequest());
        return (() => {
            dispatch(setModelAuthRequest([]));
        });
    }, []);

    function showNewRequest(loading) {
        var res;
        if(loading) {
            res = (
                <div style={{ display: 'flex', justifyContent: 'center', flex: '0 0 100%', paddingTop: '10px' }}>
                    <CircularProgress />
                </div>
            );
        } else {
            res = (
                <NewRequest />
            );
        }
        return res;
    }

    return (
        <>
            <Menu />
            {typeURL === undefined && !loadingPermission ?
                <>
                    <Header title='Autorizaciones' />
                    {
                        isEnabled(!hasPermission)
                    }
                </>
                :
                (
                    showNewRequest(loadingPermission)
                )
            }
        </>
    );
};

export default Authorizations;