import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormHelperText, IconButton, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CancelIcon from "@material-ui/icons/Cancel";
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import useStyles from '../../../../assets/styles/styles.js';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useResizeScreen } from "../../../../hooks/useResizeScreen";
import CustomButton from "../../../../controls/Button/CustomButton.jsx";
import ModalActivities from "../../../DialogActivities/ModalActivities.jsx";
import UploadModal from "../../../../controls/UploadModal/UploadModal.jsx";
import ConfirmModal from "../../../PersonalData/ConfirmModal.jsx";
import { validate } from "../../../../utils/utilsFunctions.js";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CustomSelect from "../../../../controls/Select/CustomSelect.jsx";
import { format } from "date-fns";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useDispatch, useSelector } from "react-redux";
import TopButtons from "../../../AskTurn/Web/Buttons/TopButtons.jsx";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FrequentQuestionsButton from "../../../Authorizations/FrequentQuestionsButtons";
import { getRefundTypeAttached, setModel, getParametersRefunds } from "../../../../redux/actions/refundsActions";
import FooterDrawer from "../../../AskTurn/FooterDrawer.jsx";
import LineBreakInput from "../../../../controls/InputCustom/LineBreakInput.jsx";


const AddRefundStep3 = ({ activeStep, handleOpenCancelModal, handleBack, setActiveStep }) => {
    const dispatch = useDispatch();
    const refundsTypeAttached = useSelector(state => state?.refundsReducer?.refundTypeAttached);
    const refundsModelRequestSelector = useSelector(state => state?.refundsReducer?.modelRequest);
    const [itemRemove, setItemRemove] = useState({});
    const [listTypeAttached, setListTypeAttached] = useState([{ value: 0, label: '' }]);
    const [modalDescription, setModalDescription] = useState("");
    const [typeAttachedSelected, setTypeAttachedSelected] = useState('');
    const inputFile = useRef();
    const [listAttached, setListAttached] = useState([]);
    const { screen } = useResizeScreen();
    const [files, setFiles] = useState([]);
    const [openModalRemove, setOpenModalRemove] = useState(false);
    const [hide, setHide] = useState(false);
    const classes = useStyles();
    const [modalTitle, setModalTitle] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [errorClass, setErrorClass] = useState('');
    const [modalIcon, setModalIcon] = useState('');
    const [txtObservation, setTxtObservation] = useState('');
    const [error, setError] = useState({ attachedError: "" });
    const [fileButtons, setFileButtons] = useState([]);
    const [modelRequest, setModelRequest] = useState(refundsModelRequestSelector);
    const [openSendSuccess, setOpenSendSuccess] = useState(false);
    const [allFiles, setAllFiles] = useState([]);
    const [labelTypeAttachedSelected, setLabelTypeAttachedSelected] = useState('');
    const [openConfirm, setOpenConfirm] = useState(false);
    const configurationReduxState = useSelector(state => state?.configurationReducer?.allConfigurations?.configurations);
    const [openExistFile, setOpenExistFile] = useState(false);
    const { parametersRefunds } = useSelector(state => state?.refundsReducer);
    const [totalRequiredAttachments, setTotalRequiredAttachments] = useState([]);
    const [counterLength, setCounterLength] = useState(0);

    useEffect(() => {
        dispatch(getRefundTypeAttached("REINTEGRO"));
        dispatch(getParametersRefunds());
    }, [])

    const handleAddAttachment = () => {
        if (files?.length === 0) {
            if (screen.sWidth > 768) {
                inputFile?.current?.click();
            }
            else {
                setOpenModal(true);
            }
        }
    };

    const handleChangeSelect = () => e => {
        const { name, value } = e.target;
        let auxSelected = listTypeAttached?.filter(x => x?.value === value);
        setLabelTypeAttachedSelected(auxSelected);
        setTypeAttachedSelected(value);
        validate(name, '*Campo obligatorio', value, setError, error);
    };

    const handleRemove = () => {
        let attachedDeleteItem = listAttached?.filter(x => x?.nombre !== itemRemove?.nombre);
        let filesDeleteItem = files?.filter(x => x?.archivoNombre !== itemRemove?.nombre);
        setListAttached(attachedDeleteItem);
        setAllFiles(filesDeleteItem);
        setFiles(filesDeleteItem);
        let modelBeforeRemove = JSON.parse(modelRequest?.adjuntos)
        let modelAfterRemove = modelBeforeRemove?.filter(x => x?.archivoNombre !== itemRemove?.nombre)
        setModelRequest(modelRequest => (
            { ...modelRequest,
                'adjuntos': modelAfterRemove?.length > 0 ? JSON.stringify(modelAfterRemove) : undefined
            }
        ));
        setOpenModalRemove(false);
    };

    const handleUpload = (e) => {
        e.preventDefault();
        let redAlert = "";
        let filesUpload = e.target.files;
        for (let uploadedFile of filesUpload) {
            let size = (uploadedFile.size / 1024);
            if (size < parseInt(configurationReduxState?.filter(x =>
                x?.id === 'medidaArchivosAdjuntos')[0]?.valor) * 1000) {
                let auxAdj = {
                    archivoNombre: uploadedFile.name.substring(0, uploadedFile.name.lastIndexOf(".")),
                    archivoExtension: uploadedFile.name.substring(uploadedFile.name.lastIndexOf(".") + 1),
                    archivoNombreCompleto: uploadedFile.name,
                    archivoTamanio: parseInt(size),
                    archivoClasificacion: 0,
                    archivo: null,
                };

                let existThisFile = listAttached?.filter(x => x?.nombre === auxAdj?.archivoNombre);

                if(existThisFile?.length > 0) {
                    setOpenExistFile(true);
                } else {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        auxAdj.archivo = reader.result?.split(',').pop();
                        setFiles([...files, auxAdj]);
                        setHide(true);
                    };
                    reader.readAsDataURL(uploadedFile);
                }
                
            } else {
                redAlert = redAlert + " " + uploadedFile.name;
            }
        }
        if (redAlert != "") {
            setModalTitle('Atención');
            setModalDescription(
                `El archivo: ${redAlert} supera el tamaño máximo.
                Asegurate de que los archivos no superen los ${configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor}MB`
            );
            setModalIcon(<CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
            setErrorClass(classes.backgroundError);
            setOpenSendSuccess(prevState => !prevState);
        }
        e.target.value = null;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (files?.length > 0 && validateSelect(typeAttachedSelected)) {
            let dateNow = Date.now();
            setFiles([...files, { archivoClasificacion: typeAttachedSelected, archivoObservacion: txtObservation, archivoTipo: labelTypeAttachedSelected }]);
            let lenFiles = files?.length - 1;
            let auxFile = files[lenFiles];
            auxFile.archivoClasificacion = typeAttachedSelected;
            auxFile.archivoObservacion = txtObservation;
            let aux = [...allFiles, auxFile];
            setModelRequest(modelRequest => ({ ...modelRequest, 'adjuntos': JSON.stringify(aux) }));
            let type = listTypeAttached?.find(x => x?.value == typeAttachedSelected);
            let listAux = listAttached;
            let newItemList = {
                nombre: files[0]?.archivoNombre,
                fecha: format(dateNow, "dd/MM/yyyy"),
                tipo: type?.label,
                observacion: txtObservation,
                delete: true
            };
            listAux.push(newItemList);
            setListAttached(listAux);
            setAllFiles([...allFiles, auxFile]);
            cleanStates();
            setOpenConfirm(prevState => !prevState);
        }
    };

    const handleChangeText = e => {
        const { value } = e.target;
        if (value.length <= 200) {
            setTxtObservation(value);
            setCounterLength(value.length);
        }
    };

    const handleModalRemove = attached => e => {
        setItemRemove(attached);
        setOpenModalRemove(true);
    };

    function validateSelect(select) {
        let aux = [];
        let validation = [];
        let Obj = {};

        Obj.selectAttached = select?.toString() ? '' : '*Campo obligatorio';
        aux = Obj;


        if (Object.values(aux).every((x) => x === "")) {
            validation.push(true);
        } else {
            validation.push(false);
        }

        if(select === "") {
            setError(aux);
        }

        return Object.values(validation).every(valid => valid === true);
    }

    function cleanStates() {
        setFiles([]);
        setTxtObservation('');
        setTypeAttachedSelected('');
        setHide(false);
    }

    const handleNext = (e) => {
        e.preventDefault();
        if (validateModelStep3(modelRequest)) {
            dispatch(setModel({ ...modelRequest }));
            setActiveStep(activeStep + 1);
        }
    };

    useEffect(() => {
        let requiredAttachments = [];
        const fields = parametersRefunds?.tipoReintegros?.find(x => x?.tipoReintegroCodigo === modelRequest?.tipoReintegroCodigo);
        const filteredFields = fields?.tipoReintegroCampos;

        filteredFields?.forEach(field => {
            if (field?.campoAdjuntoObligatorio) {
                requiredAttachments?.push(field);
            }
        });

        setTotalRequiredAttachments(requiredAttachments)
    }, [])

    const attachmentsDetails = () => {
        let caption = (
            totalRequiredAttachments?.map(requiredAttachments => (
                <FormHelperText
                    className={classes.errorText}
                    style={{ marginLeft: screen.sWidth > 768 ? "-65px" : "-5px" }}
                    key={requiredAttachments}
                >
                    {` * ${requiredAttachments?.campoAdjuntoDetalle}`}
                </FormHelperText>
            ))
        );
        return caption;
    }

    function validateModelStep3(model) {
        let responseFunction = false;

        let modelToSend = model?.adjuntos !== undefined ? JSON.parse(model?.adjuntos) : [];

        if (totalRequiredAttachments?.length > modelToSend?.length) {
            setError({ ...error, attachedError: `Es obligatorio adjuntar ${totalRequiredAttachments?.length} ` + (totalRequiredAttachments?.length > 1 ? "archivos" : "archivo")});
            return responseFunction;
        } else {
            setError({ ...error, attachedError: "" });
            return true;
        }
    }

    useEffect(() => {
        let list = refundsTypeAttached?.tipoAdjunto?.map(type => {
            return { value: parseInt(type?.tipoAdjuntosCodigo), label: type?.tipoAdjuntosDescripcion };
        });
        setListTypeAttached(list);

        if (modelRequest?.adjuntos !== undefined) {
            let dateNow = Date.now();
            let filesModel = JSON.parse(modelRequest?.adjuntos);
            let listAttachedAux = [];
            filesModel?.map(item => {
                const type = listTypeAttached?.find(x => x?.value === item?.archivoClasificacion);
                let aux = {
                    nombre: item?.archivoNombre,
                    fecha: format(dateNow, "dd/MM/yyyy"),
                    tipo: type?.label,
                    observacion: item?.archivoObservacion,
                    delete: true
                };
                listAttachedAux?.push(aux);
            });
            setListAttached(listAttachedAux);
            setAllFiles(filesModel);
        }
    }, [refundsTypeAttached?.tipoAdjunto, modelRequest?.adjuntos]);

    const deleteFile = (setFiles, files, fileName) => (e) => {
        e.preventDefault();
        let auxFiles = files?.filter(item => {
            return item?.archivoNombre !== fileName;
        });
        setFiles(auxFiles);
    };

    const AttachedFilesAccordion = () => {
        return (
            <Accordion expanded={true} style={{ boxShadow: 'none', marginTop: '30px' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ borderBottom: '1px solid #e2e3e3' }}
                >
                    <AttachFileOutlinedIcon
                        style={{
                            marginRight: "5px",
                            backgroundColor: '#0075c9',
                            color: '#ffffff',
                            borderRadius: '15px',
                            fontSize: '30px',
                            padding: '5px'
                        }}
                        className={classes.iconFilter}
                    />
                    <span style={{ paddingLeft: '10px' }}>Adjuntos</span>
                </AccordionSummary>
                <AccordionDetails>
                    { listAttached?.length > 0 ?
                        <div className={classes.authorizationsAccordion}>
                            <Typography className={classes.txtSubtitleCalendar} style={{ fontSize: "16px", marginBottom: "20px" }}>
                                NOMBRE
                            </Typography>
                            { listAttached?.map((attached, i) => {
                                return (
                                    <>
                                        <Accordion
                                            key={i}
                                            style={{ width: "100%" }}
                                            expanded={true}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                classes={{ content: classes.width100 }}
                                            >
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        overflow: "hidden"
                                                    }}
                                                >
                                                    <Typography
                                                        className={classes.txtEllipsis}
                                                    >
                                                        { attached?.nombre }
                                                    </Typography>
                                                </div>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        marginLeft: "20px"
                                                    }}
                                                >
                                                    <Typography className={classes.txtSubtitleCalendar}>
                                                        { attached?.fecha }
                                                    </Typography>
                                                    { (attached?.delete && attached?.observacion !== 'Comprobante digital') &&
                                                        <div style={{ display: "flex" }}>
                                                            <IconButton className={classes.padding0} onClick={handleModalRemove(attached)}>
                                                                <DeleteOutlineIcon />
                                                            </IconButton>
                                                        </div>
                                                    }
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails >
                                                {
                                                    <>
                                                        <div className={classes.displayFlex}>
                                                            <Typography className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}>
                                                                Tipo de archivo:
                                                            </Typography>
                                                            <Typography className={`${classes.txtSubtitleGray} ${classes.txtEllipsis}`} style={{ marginLeft: "10px" }}>
                                                                { attached?.tipo }
                                                            </Typography>

                                                        </div>
                                                        <div className={classes.displayFlex}>
                                                            <Typography className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}>
                                                                Observaciones / Comentarios:
                                                            </Typography>
                                                            <Typography className={`${classes.txtSubtitleGray} ${classes.txtEllipsis}`} style={{ marginLeft: "10px" }}>
                                                                { attached?.observacion !== "" ? attached?.observacion : "No hay observaciones" }
                                                            </Typography>
                                                        </div>
                                                    </>
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    </>);
                            })}
                        </div>
                        :
                        <Typography style={{ marginTop: "20px", fontSize: "14px", textAlign: "center" }}>
                            No hay archivos adjuntos
                        </Typography>
                    }
                </AccordionDetails>
            </Accordion>
        );
    }

    const AddAttachedButton = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <CustomButton title={"Nuevo adjunto"}
                    onClick={handleAddAttachment}
                    style={{ height: "20px", padding: "16px", fontSize: "12px" }}
                    icon={<AddCircleIcon style={{ marginRight: "8px" }} />}
                    customClass={`${classes.txtMobile} ${classes.lightLinearGradient}`}
                />
                {
                    screen.sWidth > 768 &&
                    <FrequentQuestionsButton />
                }
            </div>
        );
    }

    return (
        <>
            { screen.sWidth > 768 &&
                <TopButtons
                    handleOpenCancelModalTurn={handleOpenCancelModal}
                    handleBackTurn={handleBack}
                    activeStepTurn={activeStep}
                    handleNextTurn={handleNext}
                    titleCancel={"Cancelar"}
                    titleConfirm={"Siguiente"}
                />
            }

            <AttachedFilesAccordion />

            <section style={{ display: 'flex', flexDirection: "column", alignItems: "center", paddingTop: '20px' }}>
                <AddAttachedButton />

                { error?.attachedError !== "" ?
                    <>
                        <FormHelperText
                            className={classes.errorText}
                            style={{ marginLeft: screen.sWidth > 768 ? "-65px" : "-5px", marginTop: screen.sWidth < 768 && "10px"}}
                        >
                            { error?.attachedError }
                        </FormHelperText>
                        {attachmentsDetails()}
                    </>
                : 
                    ""
                }
            </section>

            { files?.length !== 0 && hide &&
                <>
                    <div style={{ paddingTop: '20px', paddingBottom: '20px', display: 'flex', flexDirection: "column", alignItems: "center" }}>
                        <FormControl  variant="standard" className={classes.widthMobile}>
                            <Typography>Tipo de archivo </Typography>
                            <CustomSelect
                                label=''
                                name={'selectAttached'}
                                className={`${classes.selectAuthorizations}`}
                                list={listTypeAttached}
                                selected={typeAttachedSelected}
                                onChange={handleChangeSelect()}
                            />
                            <FormHelperText className={`${classes.errorText}`} style={{ paddingLeft: '5%' }}>{error?.selectAttached}</FormHelperText>
                        </FormControl>
                    </div>
                    
                    <section
                        style={{ display: 'flex', flexDirection: "column", alignItems: "center", paddingTop: '20px' }}
                    >
                        { files?.map((file, i) => (
                            <div key={i} style={{ display: "flex", justifyContent: "center", width: "100%" }} className={`${classes.txtSubtitleCalendar}`}>
                                <p className={`${classes.txtEllipsis}`}>
                                    { file?.archivoNombre }
                                </p>
                                <IconButton
                                    onClick={deleteFile(setFiles, files, file?.archivoNombre)}>
                                    <HighlightOffIcon />
                                </IconButton>
                            </div>
                        ))}

                        <FormControl variant="standard" className={ classes.widthMobile }>
                            <Typography>Observaciones</Typography>
                            <LineBreakInput
                                formControlClass={`${classes.txtObservations}`}
                                inputLabelClass={classes.txtLabelComments}
                                customClass={classes.txtBoxObservation}
                                name="observacion"
                                placeholder='Escribí tu texto aquí'
                                onChange={handleChangeText}
                                multiline
                                style={{
                                    borderRadius: 29,
                                    position: 'relative',
                                    border: '1px solid #ced4da', 
                                    fontSize: 16,
                                    minWidth: '224px !important',
                                    margin: '0 0 7px 0',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: "nowrap",
                                  }}
                                rows={5}
                                inputProps={{ maxLength: 200 }}
                            />
                        <Typography variant="caption" style={{  marginLeft: screen.sWidth < 768 ? "250px" : '450px',}}>{counterLength}/200</Typography>
                    </FormControl>

                        <CustomButton
                            title={"ADJUNTAR"}
                            onClick={handleSubmit}
                            style={{
                                height: "20px",
                                fontSize: "12px",
                                fontWeight: "600",
                                margin: "50px",
                                padding: "16px"
                            }}
                            customClass={`${classes.customHeaderBtn} ${classes.btnAttached} ${classes.lightLinearGradientReverse} ${classes.txtMobile}`}
                        />
                    </section>
                </>
            }

            <ConfirmModal isOpen={openSendSuccess} setOpen={setOpenSendSuccess} errorClass={errorClass}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            { modalIcon }
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            { modalTitle }
                        </div>
                    </>
                }
                description={ modalDescription }
            />

            <UploadModal
                title='Adjuntar archivo'
                description=''
                observation=''
                open={openModal}
                setModalTitle={setModalTitle}
                onClose={setOpenModal}
                setModalIcon={setModalIcon}
                setModalDescription={setModalDescription}
                setOpenSendSuccess={setOpenSendSuccess}
                setErrorClass={setErrorClass}
                files={files}
                setFiles={setFiles}
                setFileButtons={setFileButtons}
                fileButtons={fileButtons}
                setHide={setHide}
                hide={true}
                listAttached={listAttached}
                setRepeatFile={setOpenExistFile}
            />

            { openModalRemove &&
                <ModalActivities
                open={openModalRemove}
                    title={'Eliminar adjunto'}
                    buttonType={'submit'}
                    description={'¿Estás seguro que querés eliminar el adjunto?'}
                    modalconfirm={true}
                    onClose={setOpenModalRemove}
                    handleClick={handleRemove}
                    modalLogout={false}
                />
            }

            <ConfirmModal
                setOpen={setOpenConfirm}
                isOpen={openConfirm}
                description={"El archivo se ha adjuntado con éxito."}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                        </div>
                    </>
                }
            />

            <ConfirmModal
                setOpen={setOpenExistFile}
                isOpen={openExistFile}
                description={"No puede adjuntar dos archivos con el mismo nombre"}
                titleModal={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />
                        </div>
                    </>
                }
            />

            <input ref={inputFile} type="file" hidden id='upFile' onChange={handleUpload} name='upFileName' />

            { screen.sWidth <= 768 &&
                <div style={{ marginTop: "50px" }}>
                    <FooterDrawer
                        activeStep={activeStep}
                        handleOpenCancelModal={handleOpenCancelModal}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        customClassDiv={classes.footerAuthRequest}
                        confirm={true}
                    />
                </div>
            }

        </>
    );
};

export default AddRefundStep3;

