import React, { useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../assets/styles/styles";
import "../../components/DialogActivities/modalactivities.css";
import CustomButton from "../../controls/Button/CustomButton";
import CancelIcon from '@material-ui/icons/Cancel';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useResizeScreen } from "../../hooks/useResizeScreen";
import { useSelector } from "react-redux";
import FrequentQuestionsButton from "../../components/Authorizations/FrequentQuestionsButtons";

const UploadModal = (props) => {
    const classes = useStyles();
    const { title, description, observation, open, onClose, modalconfirm, setModalTitle, setModalDescription, setModalIcon, listAttached, setRepeatFile,
        setErrorClass, setOpenSendSuccess, setFiles, files, customClass, fileButtons, setFileButtons, itemName, isRequired, hide, setHide, archivoClasificacion, ...other } = props;
    const inputFile = useRef();
    const inputPhoto = useRef();
    const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);

    const handleClose = () => {
        onClose(false);
    };

    const handleUpload = (e) => {
        let alertGreatSize = "";
        var filesUpload = e.target.files;
        for (let file of filesUpload) {
            let size = (file.size / 1024);
            if (size < parseInt(configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor) * 1000) {
                let adj = {
                    archivoNombre: file.name.substring(0, file.name.lastIndexOf(".")),
                    archivoExtension: file.name.substring(file.name.lastIndexOf(".") + 1),
                    archivoNombreCompleto: file.name,
                    archivoTamanio: parseInt(size),//KB
                    archivo: null,//new Blob([file],{type: file.type}),
                    archivoClasificacion: archivoClasificacion ?? 0,
                    btnNombre: itemName,
                    adjuntoObligatorio: isRequired
                };

                let repeatFile = listAttached?.filter(x => x?.nombre?.trim() == adj?.archivoNombre?.trim());

                if(repeatFile?.length > 0) {
                    handleClose();
                    setRepeatFile(true);
                } else {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        adj.archivo = reader.result?.split(',').pop();
                        setFiles([...files, adj]);
                        setFileButtons({ ...fileButtons, [itemName]: true });
                        handleClose();
                        if (hide) {
                            setHide(true)
                        }
                    };
                    reader.readAsDataURL(file);
                }
                
            } else {
                alertGreatSize = alertGreatSize + " " + file.name;
            }
        }
        if (alertGreatSize != "") {
            setModalTitle('Atención');
            setModalDescription(`
                Los archivos: ${alertGreatSize} superan el tamaño máximo. 
                Asegurese de que los archivos no superen los ${configurationReduxState?.filter(x => x.id === 'medidaArchivosAdjuntos')[0]?.valor}MB
            `);
            setModalIcon(<CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
            setErrorClass(classes.backgroundError);
            setOpenSendSuccess(prevState => !prevState);
        }
    };

    const { screen } = useResizeScreen();

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                id="backDropActivities"
                {...other}
            >
                <div id="modalActivities" style={{ textAlign: 'center' }}>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogTitle
                        id="customized-dialog-title"
                        className={`${classes.contentDialogTitle} ${customClass}`}
                        style={{ padding: '20px' }}
                        onClose={handleClose}
                    >
                        <Typography
                            className={classes.titleDialogActivities}
                            style={{ fontWeight: "600", display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                            {title}
                            <FrequentQuestionsButton />
                        </Typography>
                    </DialogTitle>

                    <DialogContent className={`${classes.contentDialogTitle} ${customClass}`} style={{ paddingTop: "0px" }}>
                        <Typography >
                            {description}
                        </Typography>
                        <Typography >
                            {observation}
                        </Typography>
                        <div style={{ textAlign: "center" }}>
                            <CustomButton
                                style={{ padding: '5px 15px 5px 5px', width: '200px', marginBottom: '10px' }}
                                title={"ADJUNTAR ARCHIVO"}
                                icon={<AttachFileIcon />}
                                customClass={`${classes.lightLinearGradient} ${classes.marginLeft}`}
                                onClick={() => { inputFile?.current?.click(); }}
                            />
                            {
                                screen.sWidth < 768 &&
                                <CustomButton
                                style={{ padding: '5px 15px 5px 5px', width: '200px', marginBottom: '10px' }}
                                title={"SACAR FOTO"}
                                icon={<AttachFileIcon />}
                                customClass={`${classes.lightLinearGradient} ${classes.marginLeft}`}
                                onClick={() => { inputPhoto?.current?.click(); }}
                                />
                            }
                        </div>
                    </DialogContent>
                    <input ref={inputFile} type="file" hidden id='upFile' onChange={handleUpload} name='upFileName' />
                    <input ref={inputPhoto} type="file" hidden capture="environment" id='upPhoto' onChange={handleUpload} name='upPhotoName' />
                </div>
            </Dialog>
        </>
    );
};
export default UploadModal;
