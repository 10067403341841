import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetComments({ payload }) {
    try {
        let response = yield call(API.getComments, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_COMMENTS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_COMMENTS, message: error });
    }
}

function* asyncPushComments({ payload }) {
    try {
        let response = yield call(API.pushComments, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_PUSH_COMMENTS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_PUSH_COMMENTS, message: error });
    }
}

function* asyncGetReadComments({ payload }) {
    try {
        let response = yield call(API.readComments, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_READ_COMMENTS, response });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_READ_COMMENTS, message: error });
    }
}

function* asyncGetAuthorizationsDetails({ payload }) {
    try {
        let response = yield call(API.getAllAuthorizations, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_AUTHORIZATIONS_DETAILS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_AUTHORIZATIONS_DETAILS, message: error });
    }
}

function* asyncGetDetails({ payload }) {
    try {
        let response = yield call(API.getDetails, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_AUTHORIZATION_DETAIL, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_AUTHORIZATIONS_DETAILS, message: error });
    }
}

function* asyncGetAuthorizationsTypeAttached({ payload }) {
    try {
        let response = yield call(API.getTypeAttached, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_AUTHORIZATIONS_TYPEATTACHED, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_AUTHORIZATIONS_TYPEATTACHED, message: error });
    }
}

function* asyncGetParamsAuthRequest() {
    try {
        let response = yield call(API.getParametersAuthorizationRequest);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_PARAMS_AUTH_REQUEST, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_PARAMS_AUTH_REQUEST, message: error });
    }
}

function* asyncGetAuthorizationsAttached({ payload }) {
    try {
        let response = yield call(API.getAttached, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_AUTHORIZATIONS_ATTACHED, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_AUTHORIZATIONS_ATTACHED, message: error });
    }
}

function* asyncPostAuthorizationsAttached({ payload }) {
    try {
        let response = yield call(API.postAttached, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_POST_AUTHORIZATIONS_ATTACHED, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_POST_AUTHORIZATIONS_ATTACHED, message: error });
    }
}

function* asyncPostAuthorizationsRequest({ payload }) {
    try {
        let response = yield call(API.postRequest, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_POST_AUTHORIZATIONS_REQUEST, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_POST_AUTHORIZATIONS_REQUEST, message: error});
    }
}

function* asyncGetAuthorizationsAffiliate({ payload }) {
    try {
        let response = yield call(API.getAuthorizationsAffiliate, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_AUTHORIZATIONS_AFFILIATE, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_AUTHORIZATIONS_AFFILIATE, message: error });
    }
}

function* asyncDownloadAttachments({ payload }) {
    try {
        let response = yield call(API.downloadAttachments, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_DOWNLOAD_ATTACHMENTS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_DOWNLOAD_ATTACHMENTS, message: error });
    }
}

function* asyncDownloadAuthorization({ payload }) {
    try {
        let response = yield call(API.downloadAuthorizations, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_DOWNLOAD_AUTHORIZATION, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_DOWNLOAD_AUTHORIZATION, message: error });
    }
}

function* asyncCancelAuthorization({ payload }) {
    try {
        const response = yield call(API.cancelAuthorization, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_CANCEL_AUTHORIZATION, response });
    } catch (error) {
        yield put({
            type: actionTypes.FAILURE_CANCEL_AUTHORIZATION,
            message: error
        });
    }
}



export default function* authorizationsSaga() {
    yield takeLatest(actionTypes.GET_COMMENTS, asyncGetComments);
    yield takeLatest(actionTypes.PUSH_COMMENTS, asyncPushComments);
    yield takeLatest(actionTypes.GET_READ_COMMENTS, asyncGetReadComments);
    yield takeLatest(actionTypes.GET_AUTHORIZATIONS_DETAILS, asyncGetAuthorizationsDetails);
    yield takeLatest(actionTypes.GET_AUTHORIZATION_DETAIL, asyncGetDetails);
    yield takeLatest(actionTypes.GET_AUTHORIZATIONS_TYPEATTACHED, asyncGetAuthorizationsTypeAttached);
    yield takeLatest(actionTypes.GET_PARAMS_AUTH_REQUEST, asyncGetParamsAuthRequest);
    yield takeLatest(actionTypes.GET_AUTHORIZATIONS_ATTACHED, asyncGetAuthorizationsAttached);
    yield takeLatest(actionTypes.POST_AUTHORIZATIONS_ATTACHED, asyncPostAuthorizationsAttached);
    yield takeLatest(actionTypes.POST_AUTHORIZATIONS_REQUEST, asyncPostAuthorizationsRequest);
    yield takeLatest(actionTypes.GET_AUTHORIZATIONS_AFFILIATE, asyncGetAuthorizationsAffiliate);
    yield takeLatest(actionTypes.DOWNLOAD_ATTACHMENTS, asyncDownloadAttachments);
    yield takeLatest(actionTypes.DOWNLOAD_AUTHORIZATION, asyncDownloadAuthorization);
    yield takeLatest(actionTypes.CANCEL_AUTHORIZATION, asyncCancelAuthorization);
}
