import * as actionTypes from "../consts/actionTypes";

export function getLimitPlan() {
  return {
    type: actionTypes.GET_LIMIT_PLAN,
    payload: "",
  }
}

export function postLimitPlan(param) {
  return {
    type: actionTypes.POST_LIMIT_PLAN,
    payload: param,
  }
}

export function putLimitPlan(param) {
  return {
    type: actionTypes.PUT_LIMIT_PLAN,
    payload: param,
  }
}

export function deleteLimitPlan(param) {
  return {
    type: actionTypes.DELETE_LIMIT_PLAN,
    payload: param,
  }
}
