import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/GenericHeader/Header";
import Menu from "../../components/Menu/Menu";
import VirtualDoctorCaller from "../../components/VirtualDoctorCaller/VirtualDoctorCaller";
import { getDataGroupFamily } from "../../redux/actions/appointmentsActions";
import { getLimitPlan } from "../../redux/actions/limitPlansAction";
import { notAccessToThisModule, verifyPermission } from "../../utils/utilsFunctions";
import useTrackPageView from "../../utils/useTrackPageView";

const persona = {
    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona"))
};

const VirtualDoctor = () => {
    const dispatch = useDispatch();
    const [ hasPermission, setHasPermission ] = useState(false);
    const limitPlanReducer = useSelector((state) => state.limitPlanReducer.allLimitPlanData);
    const affiliatePlan = useSelector((state) => state.personalDataReducer?.fullData?.afiliado?.planDescripcion);
    const trackPageView = useTrackPageView("Médico Virtual");

    useLayoutEffect(() => {
        verifyPermission(limitPlanReducer, affiliatePlan, "Médico Virtual", setHasPermission);
    }, [limitPlanReducer, affiliatePlan, hasPermission]);

    useEffect(() => {
        dispatch(getDataGroupFamily(persona));
    }, []);

    useEffect(() => {
        dispatch(getLimitPlan());
        trackPageView();
    }, []);

    return (
        <>
        {
            <>
                <Menu />
                <Header title='Médico virtual' />
                {
                    !hasPermission ?
                    notAccessToThisModule()
                :
                <VirtualDoctorCaller />
                }
            </>
        }
        </>
    );
};

export default VirtualDoctor;