import * as actionTypes from '../consts/actionTypes';
import {responseToReturn} from '../../utils/utilsFunctions.js';

const initialState = {
    planPdf: [],
    msgError: ''
};

export default function planPdfReducer(state = initialState, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_GET_PLANPDF_BY_PLANCODE]: responseToReturn('planPdf', state, action),
        [actionTypes.FAILURE_GET_PLANPDF_BY_PLANCODE]: responseToReturn('planPdf', state, action),
    };

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}