import { Typography } from "@material-ui/core";
import CustomButton from "../../../controls/Button/CustomButton";
import HeaderCard from "../../Billing/Movements/HeaderCard";
import AppointmentsCard from "./AppointmentsCard";
import useStyles from "../../../assets/styles/styles";

const HistoryAppointmentCard = ({ item, i, handleClickOpenRepeatModal }) => {
    const classes = useStyles();

    return (
        <>
            <AppointmentsCard
                index={i}
                headerIcon={<HeaderCard day={item?.fechaFormateada?.nombreDia} numberDay={item?.fechaFormateada?.dia} month={parseInt(item?.turnoFechHora?.substring(5, 7))} year={parseInt(item?.turnoFechHora?.substring(0, 4))} />}
                description={
                    <>
                        <Typography style={{ fontWeight: "600", color: '#0075c9' }} className={`${classes.txtCardDescription} `}>
                            <span style={{ color: 'black' }}>{"Hora: " + item?.turnoFechHora?.split('T')[1].substring(0, 5) + " hs"}</span>
                        </Typography>
                        <Typography style={{ fontWeight: "600", color: '#0075c9', paddingBottom: "10px" }} className={`${classes.txtCardDescription} `} >
                            <span style={{ color: 'black' }}>{"CENTRO MEDICO " + item?.sucursalDescripcion}</span>
                        </Typography>
                        <Typography className={`${classes.txtCardDescription}`}>
                            {item?.pacienteNombreApellido}
                        </Typography>
                        <Typography className={`${classes.txtCardDescription}`}>
                            {item?.especialidadDescripcion}
                        </Typography>
                        <Typography className={`${classes.txtCardDescription}`}>
                            {item?.medicoDescripcion}
                        </Typography>
                    </>
                }
                buttonZone={
                    <section style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flex: 1 }}>
                        <div>
                            <CustomButton
                                title="REPETIR TURNO"
                                customClass={classes.darkLinearGradient}
                                style={{ whiteSpace: "nowrap", marginTop: "10px", width: "100%", fontSize: "0.5rem", fontWeight: '600' }}
                                onClick={handleClickOpenRepeatModal(item)}
                            />
                        </div>
                    </section>
                }
                customHeaderColor={classes.darkLinearGradientImportant}
            />
        </>
    );
};

export default HistoryAppointmentCard;