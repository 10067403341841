import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetConfigurations() {
    try {
        let response = yield call(API.getConfigurations);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_CONFIGURATIONS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_CONFIGURATIONS, message: error.message });
    }
}

function* asyncGetConfigurationById({ payload }) {
    try {
        let response = yield call(API.getConfigById, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_CONFIGURATION_BY_ID, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_CONFIGURATION_BY_ID, message: error.message });
    }
}

function* asyncGetConfigurationLowService({payload}){
    try {
        let response = yield call(API.getConfigById, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_CONFIGURATION_LOW_SERVICE_BY_ID, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_CONFIGURATION_LOW_SERVICE_BY_ID, message: error.message });
    }
}

function* asyncGetConfigurationReclamo({payload}){
    try {
        let response = yield call(API.getConfigById, payload);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_CONFIGURATION_RECLAMO_BY_ID, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_CONFIGURATION_RECLAMO_BY_ID, message: error.message });
    }
}

export default function* configurationSaga() {
    yield takeLatest(actionTypes.GET_CONFIGURATIONS, asyncGetConfigurations);
    yield takeLatest(actionTypes.GET_CONFIGURATION_BY_ID, asyncGetConfigurationById);
    yield takeLatest(actionTypes.GET_CONFIGURATION_LOW_SERVICE_BY_ID, asyncGetConfigurationLowService);
    yield takeLatest(actionTypes.GET_CONFIGURATION_RECLAMO_BY_ID, asyncGetConfigurationReclamo);
}
