import FactCheckIcon from '@material-ui/icons/FactCheck';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import StarsIcon from '@material-ui/icons/Stars';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import useTrackPageView from "../../utils/useTrackPageView";

import { useResizeScreen } from "../../hooks/useResizeScreen";
import Menu from "../../components/Menu/Menu";
import useStyles from "../../assets/styles/styles";
import SelectTypeOfComponent from "../../components/PersonalData/SelectTypeOfComponent";
import { Fragment, useEffect, useState } from 'react';
import QuickAccessBox from '../../components/HomeLogged/QuickAccessBox';
import Header from '../../components/GenericHeader/Header';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';


const TravelCoverage = () => {
    const history = useHistory();
    const { screen } = useResizeScreen();
    const trackPageView = useTrackPageView("Cobertura en Viaje");
    const [type, setActiveType] = useState(-1);
    const classes = useStyles();
    const [mobileDevice, setMobileDevice] = useState(false);
    const { search } = useLocation();
    const match = search.match(/type=(.*)/);
    const typeURL = match?.[1];
    const configurationReduxState = useSelector(state => state.configurationReducer?.allConfigurations);

    const listCoverage = [
        { title: 'MI PLAN', icon: <FactCheckIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 0, path: '/TravelCoverage?type=MyPlan' },
        { title: 'PREGUNTAS FRECUENTES', icon: <QuestionAnswerOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 1, path: '/TravelCoverage?type=FrequentQuestions' },
        { title: 'CONDICIONES GENERALES', icon: <FormatListBulletedOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 2, path: '/TravelCoverage?type=GeneralConditions' },
    ];
    const listMoreCoverage = [
        { title: 'COBERTURA PREMIUM', icon: <StarsIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: -1, href: configurationReduxState?.configurations?.filter(x => x.id === 'coberturaViajeLinkLowCost')[0]?.valor, path: '/TravelCoverage' },
        { title: 'PRODUCTOS LOW COST', icon: <LocalOfferIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: -1, href: configurationReduxState?.configurations?.filter(x => x.id === 'coberturaViajeLink')[0]?.valor, path: '/TravelCoverage' },
    ];

    useEffect(() => {
        trackPageView();
    }, [])

    const handleBack = () => {
        setActiveType(-1);
    };

    const handleClick = (item) => (e) => {
        setActiveType(item.type);
    };

    const validateResponsive = () => {
        if (screen.sWidth <= 768)
            setMobileDevice(true);
        else
            setMobileDevice(false);
    }

    useEffect(() => {
        if (typeURL !== undefined) {
            let auxURL = `/TravelCoverage?type=${typeURL}`
            let aux = listCoverage.filter(x => x.path === auxURL);
            setActiveType(aux[0].type);
        }else{
            setActiveType(-1);
        }
    }, [typeURL]);

    useEffect(() => {
        validateResponsive();
    }, [screen.sWidth, type]);
    useEffect(() => {
        window.onpopstate = (e) => {
            if (type !== -1)
                window.location.href = '/TravelCoverage';
            else
                history.push('/Coverage');
        };
    });

    function mapListItem(list) {
        return (
            <div className={`${classes.divTravelCoverageMenu} ${classes.customScroll}`}>
                {list === listMoreCoverage ?
                    (list.map((item, i) => (
                        <Fragment key={i}>
                            <QuickAccessBox target="_blank" href={item.href} handleClick={handleClick} i={i} item={item} classes={classes} customClass={classes.coverageMenuItem} iconClass={classes.personalDataDivIcons} txtIcon={classes.personalDataTxtIcon} />
                        </Fragment>
                    )))
                    :
                    (list.map((item, i) => (
                        <Fragment key={i}>
                            <QuickAccessBox component={Link} to={item.path} handleClick={handleClick} i={i} item={item} classes={classes} customClass={classes.coverageMenuItem} iconClass={classes.personalDataDivIcons} txtIcon={classes.personalDataTxtIcon} />
                        </Fragment>
                    )))
                }

            </div>
        )
    }
    return (
        <>
            <Menu />
            {!mobileDevice || type === -1 ?
                <>
                    <Header title='Mi Cobertura' />
                    {type === -1 &&
                        <div>
                            <Typography className={`${classes.titleTravelCoverage} ${classes.titleTurn}`}>COBERTURA EN VIAJE</Typography>
                            {mapListItem(listCoverage)}
                            <Typography style={{ paddingTop: "0px" }} className={`${classes.titleTravelCoverage} ${classes.titleTurn}`}>AMPLIAR COBERTURA</Typography>
                            {mapListItem(listMoreCoverage)}
                        </div>
                    }
                </>
                :
                ''
            }
            <SelectTypeOfComponent handleBack={handleBack} />
        </>
    );
};

export default TravelCoverage;

