
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CreditScoreIcon from '@material-ui/icons/CreditScore';
import ContactPageOutlinedIcon from '@material-ui/icons/ContactPageOutlined';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import GradingIcon from '@material-ui/icons/Grading';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import { useResizeScreen } from "../../hooks/useResizeScreen";
import Menu from "../../components/Menu/Menu";
import useStyles from "../../assets/styles/styles";
import SelectTypeOfComponent from "../../components/PersonalData/SelectTypeOfComponent";
import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import QuickAccessBox from '../../components/HomeLogged/QuickAccessBox';
import Header from '../../components/GenericHeader/Header';
import { useDispatch, useSelector } from 'react-redux';
import { getFullData, getTypesOfContact, getTypesOfContactMail, getTypesOfContactPhone } from '../../redux/actions/personalDataActions';
import { getProcedures } from '../../redux/actions/procedureActions';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { getLimitPlan } from "../../redux/actions/limitPlansAction";
import { notAccessToThisModule, verifyPermission } from "../../utils/utilsFunctions";
import useTrackPageView from "../../utils/useTrackPageView";

const userCode = parseInt(window.localStorage.getItem("numeroPersona"));
const affiliated = {
    numeroPersona: userCode
};
const contactTypesModel = {
    numeroPersona: userCode,
    clasificacion: "DOMICILIO"
};
const contactTypesPhoneModel = {
    numeroPersona: userCode,
    clasificacion: "TELEFONO"
};
const contactTypesMailModel = {
    numeroPersona: userCode,
    clasificacion: "MAIL"
};

const PersonalData = () => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const trackPageView = useTrackPageView("Datos Personales");
    const [type, setActiveType] = useState(-1);
    const [mobileDevice, setMobileDevice] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const match = search.match(/type=(.*)/);
    const typeURL = match?.[1];
    const [loading, setLoading] = useState(true);
    const personalDataSelector = useSelector(state => state.personalDataReducer);
    const affiliatePlan = useSelector((state) => state.personalDataReducer?.fullData?.afiliado?.planDescripcion);
    const limitPlanReducer = useSelector((state) => state.limitPlanReducer.allLimitPlanData);
    const [ hasPermission, setHasPermission ] = useState(false);

    const listQuickAccess = [
        { title: 'Datos Personales', icon: <AccountCircleOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 0, path: '/PersonalData?type=PersonalData' },
        { title: 'Trámites', icon: <GradingIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 1, path: '/PersonalData?type=Affiliations' },
        { title: 'Domicilio', icon: <RoomOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 2, path: '/PersonalData?type=Address' },
        { title: 'Datos de contacto', icon: <ContactPageOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 3, path: '/PersonalData?type=ContactData' },
        { title: 'Hobbies y deportes', icon: <DirectionsRunIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 4, path: '/PersonalData?type=SportsHobbies' },
        { title: 'Datos para reintegros', icon: <MonetizationOnOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 5, path: '/PersonalData?type=Refund' },
        {
            title: 'Datos de débito automático',
            icon: <CreditScoreIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />,
            type: 6, path: '/PersonalData?type=AutomaticMembership'
        },
        {
            title: 'Preguntas frecuentes',
            icon: <QuestionAnswerOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />,
            type: 7, path: '/PersonalData?type=PersonalDataFrequentQuestions'
        }
    ];

    useEffect(() => {
        dispatch(getLimitPlan());
    }, []);

    const handleClick = (item) => (e) => {
        setLoading(true);
        window.location.href = item.path;
    };

    const handleBack = () => {
        setActiveType(-1);
        history.push('/PersonalData');
    };

    function validateResponsive() {
        if (screen.sWidth <= 768)
            setMobileDevice(true);
        else
            setMobileDevice(false);
    }

    useEffect(() => {
        validateResponsive();
    }, [screen.sWidth, type]);

    useLayoutEffect(() => {
        verifyPermission(limitPlanReducer, affiliatePlan, "Datos y Afiliaciones", setHasPermission);
    }, [limitPlanReducer, affiliatePlan, hasPermission]);

    useEffect(() => {
        // Realiza el dispatch para obtener los datos
        dispatch(getTypesOfContact(contactTypesModel));
        dispatch(getTypesOfContactPhone(contactTypesPhoneModel));
        dispatch(getTypesOfContactMail(contactTypesMailModel));
        dispatch(getFullData(affiliated));
        dispatch(getProcedures("AFILIACIONES"));
        setLoading(true);
        trackPageView();
    }, []);

    useEffect(() => {
        window.onpopstate = (e) => {
            if (screen.sWidth <= 768 && type !== -1)
                window.location.href = '/PersonalData';
            else
                history.go(0);
        };
    });

    useEffect(() => {
        if (typeURL !== undefined) {
            let auxURL = `/PersonalData?type=${typeURL}`;
            let aux = listQuickAccess.filter(x => x.path === auxURL);
            setActiveType(aux[0].type);
        }else{
            setActiveType(-1);
        }
    }, [typeURL]);

    function isEnabledForThisModule(condition) {
        var response;
        if(condition) {
            response = (
                <div className={`${classes.divQuickAccess} ${classes.customScroll}`}>
                    {listQuickAccess.map((item, i) => (
                        <Fragment key={i}>
                            <QuickAccessBox component={Link} to={item.path} handleClick={handleClick(item)} i={i} item={item} classes={classes} customClass={classes.personalDataItem} iconClass={classes.personalDataDivIcons} txtIcon={classes.personalDataTxtIcon} />
                        </Fragment>
                    ))}
                </div>
            );
        } else {
            response = (notAccessToThisModule());
        }
        return response;
    }

    useEffect(() => {
        return (() => {
            if (Object.values(personalDataSelector).some(x => x !== '')) {
                setLoading(false);
            }
        });
    }, [personalDataSelector]);

    return (
        <>
            <Menu />
            {(!mobileDevice || type === -1) &&
                <>
                    <Header title='Datos y Afiliaciones' />
                    {
                        isEnabledForThisModule(hasPermission)
                    }
                </>
            }
            {loading ? <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div> :
                <SelectTypeOfComponent handleBack={handleBack} />
            }
        </>
    );
};

export default React.memo(PersonalData);

