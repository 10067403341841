import { useEffect } from "react";
import Header from "../../components/GenericHeader/Header";
import Menu from "../../components/Menu/Menu";
import NotificationDetail from "../../components/Notification/NotificationDetail";
import useTrackPageView from "../../utils/useTrackPageView";

const Notification = () => {
    const trackPageView = useTrackPageView("Notificaciones");

    useEffect(() => {
        trackPageView();
    },[]);

    return (
        <>
            <Menu />
            <Header title='Notificaciones' />
            <NotificationDetail />
        </>
    );
};

export default Notification;