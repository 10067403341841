import Menu from "../../../components/Menu/Menu";
import useStyles from "../../../assets/styles/styles";
import { useEffect } from 'react';
import Header from '../../../components/GenericHeader/Header';
import { Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { postPreventionsAction } from "../../../redux/actions/preventionActions";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import FormHeader from "../../PersonalData/FormHeader";
import { handleGoToMedicalData } from "../../../utils/utilsFunctions";
import ConstructionIcon from '@mui/icons-material/Construction';

const paramsToPost = {
    numeroPersona: window.localStorage.getItem('numeroPersona'),
    count: 0,
    skip: 0
};

const controlPreventivo = [
    {
        practicas: 'Consulta clínica',
        periodicidad: '24 meses',
        registro: 'Realizada'
    },
    {
        practicas: 'Consulta Odontológica',
        periodicidad: '12 meses',
        registro: 'Sin realizar'
    }
];

const Prevention = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { screen } = useResizeScreen();

    useEffect(() => {
        /* Realiza el dispatch para obtener los datos */
        dispatch(postPreventionsAction(paramsToPost));
    }, []);

    return (
        <>
            <Menu />
            {
                screen.sWidth < 768 ?
                <FormHeader title={'Prevencion'} handleGoTo={handleGoToMedicalData} />
                :
                <Header title='Prevención' />
            }
            <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '2%' }}>
            <Typography className={`${classes.mainTitle}`} style={{ display: 'flex', justifyContent: 'center', marginRight: screen.sWidth < 768 ?  "10px" : "-10px" , marginTop:"50px"}}>En Construccion</Typography>
            <ConstructionIcon style={{ fontSize: '180px', color: "#002858", marginRight: screen.sWidth < 768 ? "5px" : "-15px" }} />
            </section>
            <section>
            </section>
        </>
    );
};

export default Prevention;