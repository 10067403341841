import React from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import useStyles from '../../assets/styles/styles';
import { BootstrapInput } from '../../assets/styles/bootstrapInput';

const InputNumber = (props) => {
    const { type, ...other } = props;
    const classes = useStyles();

    function required() {
        if (props.customRequired) {
            return <span style={{ color: '#ff0000' }}>*</span>;
        }
    }

    const onInputNumber = (e) => {
        e.preventDefault();
        if (type === 'number') {
            e.target.value = e.target.value.replace(/\D/g, '').replace(/(\..*?)\..*/g, '$1');
        }
        if (type === 'decimal') {
            let value = e.target.value = e.target.value.replace(/[^0-9,]/g, '').replace(/(\,.*?)\,.*/g, '$1');

            if (!parseFloat(value)) {
                value = e.target.value = value.substring(0, value.length - 1);
            }

            if (value.includes(',')) {

                let intNumbers = value.split(',')[0];
                let decimalNumbers = value.split(',')[1];
                if (intNumbers.length > 12) {
                    intNumbers = intNumbers.substring(0, intNumbers.length - 1);
                }
                if (decimalNumbers.length > 2) {
                    decimalNumbers = decimalNumbers.substring(0, 2);
                }
                e.target.value = `${intNumbers},${decimalNumbers}`;
            }
        }
    };

    return (
        <FormControl className={`${classes.formControl} ${props.formControlClass}`} variant="standard">
            <InputLabel className={`${classes.inputLabelForms} ${props.inputLabelClass}`} shrink htmlFor="bootstrap-input">
                {props.label}{required()}
            </InputLabel>
            <BootstrapInput
                {...other}
                onInput={onInputNumber}
                disabled={props.disabled}
                className={`${classes.customInput} ${props.customClass}`}
                id="bootstrap-input"
            />
        </FormControl>
    );
}

export default InputNumber