import * as actionTypes from "../consts/actionTypes";

/**acciones que se disparan del componente */
export function getProcedures(param) {
  return {
    type: actionTypes.GET_PROCEDURES,
    payload: param,
  };
}

export function postProcedures(param) {
  return {
    type: actionTypes.POST_PROCEDURE,
    payload: param
  };
}

export function getDataAffiliatesBaja(param) {
  return {
    type: actionTypes.GET_DATOS_RECUPERA,
    payload: param
  };
}

export function getMotivosBaja() {
  return {
    type: actionTypes.GET_MOTIVOS_BAJA,
    payload: ""
  };
}

export function getSolicitudBaja() {
  return {
    type: actionTypes.GET_SOLICITUD_BAJA,
    payload: ""
  };
}

export function getFile(param) {
  return {
    type: actionTypes.GET_FILE,
    payload: param
  };
}

export function postRequestLow(param) {
  return {
    type: actionTypes.POST_REQUEST_LOW,
    payload: param
  };
}

export function getChangePlan() {
  return {
    type: actionTypes.GET_CHANGE_PLAN,
    payload: "",
  };
}

export function getMotiveLowPlan() {
  return {
    type: actionTypes.GET_MOTIVOS_BAJA_PLAN,
    payload: "",
  };
}


export function getPlans(param) {
  return {
    type: actionTypes.GET_PLAN,
    payload: param,
  };
}


export function postChangePlan(param) {
  return {
    type: actionTypes.POST_CHANGE_PLAN,
    payload: param,
  };
}


export function getPermisionLow(param) {
  return {
    type: actionTypes.PERMISSION_LOW,
    payload: param,
  };
}

export function cleanPostProcedureSelector() {
  return {
    type: actionTypes.CLEAN_POST_PROCEDURE_SELECTOR,
    payload: ""
  };
}
