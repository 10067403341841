import useStyles from "../../assets/styles/styles";
import { useEffect, useState } from 'react';
import CustomButton from "../../controls/Button/CustomButton";
import { FormControl, FormHelperText, CircularProgress } from "@material-ui/core";
import CustomSelect from "../../controls/Select/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import ConfirmModal from "../PersonalData/ConfirmModal";
import { postContactUs } from "../../redux/actions/contactUsActions";
import LineBreakInput from "../../controls/InputCustom/LineBreakInput";
import { Typography } from '@mui/material'
import { useResizeScreen } from "../../hooks/useResizeScreen";

const ContactUs = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [error, setError] = useState({});
    const [zoneSelected, setZoneSelected] = useState('');
    const [centerSelected, setCenterSelected] = useState('');
    const [listZone, setListZone] = useState([]);
    const [listCenters, setListCenters] = useState([{ value: '', label: '' }]);
    const sedesSelector = useSelector(state => state.sedesReducer?.sedes);
    const contactUsReducer = useSelector(state => state.contactUsReducer?.contactUs);
    const personalDataReduxState = useSelector(state => state.personalDataReducer);
    const [loading, setLoading] = useState(false);
    const [model, setModel] = useState({
        numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
        mailContacto: window.localStorage.getItem('email')
    });
    const [modalTitle, setModalTitle] = useState('');
    const [modalDescription, setModalDescription] = useState("");
    const [modalIcon, setModalIcon] = useState('');
    const [errorClass, setErrorClass] = useState('');
    const [list, setList] = useState([{ value: '0', label: 'EMAIL' }]);
    const [counterLength, setCounterLength] = useState(0);
    const { screen } = useResizeScreen();

    const handleChangeSelect = () => e => {
        const { value } = e.target;
        setZoneSelected(value);
        setCenterSelected(undefined);
        setModel({ ...model, sector: undefined, zone: undefined });
    };

    const handleChangeSelectCenter = () => e => {
        const { value } = e.target;
        setCenterSelected(value);
        setModel({ ...model, sector: value });
    };

    const handleChangeInput = () => e => {
        const { value } = e.target;
        if (value.length <= 200) {
        setModel({ ...model, consulta: value });
        setCounterLength(value.length);
    };
    };

    const handleChangeSelectZone = () => e =>{
        const { value } = e.target;
        setZoneSelected(value);
        setModel({ ...model, zone: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setOpen(prevState => !prevState);
        if (validateForm(model)) {
            dispatch(postContactUs(model));
        }
    };

    function validateForm(object) {
        let temp = object;
        let aux = {};
        let validation = [];
        let Obj = {};
        Obj.consulta = temp.consulta ? '' : '*Campo obligatorio';
        Obj.zone = temp.zone ? "" : "*Campo obligatorio";
        Obj.sector = temp.sector ? '' : '*Campo obligatorio';
        aux = Obj;
        if (Object.values(aux).every((x) => x === "")) {
            validation.push(true);
        } else {
            validation.push(false);
        }
        setError(aux);
        return Object.values(validation).every((valid) => valid === true);
    }

    useEffect(() => {
        setError({
            ...error,
            consulta: model?.consulta?.length > 0 ? '' : '*Campo obligatorio',
            sector: model?.sector?.length > 0 ? '' : '*Campo obligatorio',
            zone: model?.zone?.length > 0 ? "" : "*Campo obligatorio"
        });
    }, [model?.consulta, model?.sector, model?.zone])

    useEffect(() => {
        if (personalDataReduxState?.fullData?.afiliado !== undefined) {
            let aux = [{ value: 0, label: personalDataReduxState?.fullData?.afiliado?.mails[0]?.mail }];
            setList(aux);
        }
    }, [personalDataReduxState?.fullData]);

    useEffect(() => {
        let aux = sedesSelector?.filter((ele, ind) => ind === sedesSelector?.findIndex(elem => elem.zone === ele.zone)).map((item, i) => {
            return (
                {
                    value: i,
                    label: item?.zone?.toUpperCase()
                }
            );
        });
        setListZone(aux);
    }, [sedesSelector]);

    useEffect(() => {
        let auxCenter = listZone?.filter(x => x.value == zoneSelected);
        let centers = sedesSelector?.filter(x => x.zone?.toUpperCase() == auxCenter?.[0]?.label)?.map((item, i) => {
            return {
                value: i,
                label: item?.name?.toUpperCase()
            };
        });
        var centersList = {};
        centers = centers.filter(function (current) {
            var exists = !centersList[current.label];
            centersList[current.label] = true;
            return exists;
        });
        centers = centers.filter(x => !(x.label.toUpperCase().includes("CENTRAL")));
        if(zoneSelected == 1) {
            centers = centers.filter(x => !(x.label.toUpperCase().includes("RAMOS")));
            centers = centers.filter(x => !(x.label.toUpperCase().includes("LOMAS")));
            centers = centers.filter(x => !(x.label.toUpperCase().includes("MARTINEZ")));
        }

        let excludeDuplicatedCenters = centers?.reduce((accumulator, center) => {
            let duplicated = accumulator?.some(item => item?.label?.toUpperCase() == center?.label?.toUpperCase());

            if (!duplicated) {
              accumulator?.push(center);
            }

            return accumulator;
          }, []);

        setListCenters(excludeDuplicatedCenters);
    }, [zoneSelected]);

    useEffect(() => {
        let auxCenter = listCenters?.filter(x => x.value == centerSelected);
        setModel({ ...model, "sector": auxCenter[0]?.label });
    }, [centerSelected]);

    useEffect(() => {
        let auxZone = listZone?.filter(x => x.value == zoneSelected);
        setModel({ ...model, "zone": auxZone[0]?.label });
    }, [zoneSelected]);


    useEffect(() => {
        if (contactUsReducer?.confirmacion) {
            setModalTitle('SE HA ENVIADO CON EXITO');
            setModalDescription(contactUsReducer?.mensajes?.[0]?.mensaje ?? 'Tendrá un tiempo máximo de 72 horas hábiles.');
            setModalIcon(<CheckCircleOutlineIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
            setErrorClass('');
            setLoading(false);
            setTimeout(() => { window.location.reload(); }, 3000);
        }
        else if (!contactUsReducer?.confirmacion && contactUsReducer?.confirmacion != undefined) {
            setModalTitle('Ocurrió un error');
            setModalDescription(contactUsReducer?.mensajes?.[0]?.mensaje ?? 'Ocurrió un error al enviar los datos.');
            setModalIcon(<CancelIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />);
            setErrorClass(classes.backgroundError);
            setLoading(false);
            setTimeout(() => { window.location.reload(); }, 3000);
        }
    }, [contactUsReducer?.confirmacion]);

    function enableButton() {
        return Object.values(error).every((x) => x === '') ? false : true;
    }

    const consultation = ({ sWidth }) => (
        <span style={{ marginLeft: sWidth < 768 ? "-5px" : "-5px" }}>CONSULTA</span>
    );


    return (
        <>
            <form onSubmit={handleSubmit} className={classes.contactForm} style={{ paddingTop: '30px' }}>
                {listZone.length > 0 ?
                    <>
                        <FormControl className={`${classes.contactFormControl} ${classes.formControlChronicMedicationForm} ${classes.formContactUs}`} variant="standard">
                            <CustomSelect
                                label="ZONA"
                                name="zone"
                                list={listZone}
                                selected={zoneSelected}
                                onChange={handleChangeSelectZone()}
                            />
                            < FormHelperText className={classes.errorText}>{error?.zone}</FormHelperText>

                        </FormControl>
                        <FormControl className={`${classes.contactFormControl} ${classes.formControlChronicMedicationForm} ${classes.formContactUs}`} variant="standard">
                            <CustomSelect
                                label="CENTRO MEDICO / SUCURSAL"
                                name="sector"
                                list={listCenters}
                                selected={centerSelected}
                                onChange={handleChangeSelectCenter()}
                            />
                            < FormHelperText className={classes.errorText}>{error?.sector}</FormHelperText>
                        </FormControl>
                        <FormControl className={`${classes.contactFormControl} ${classes.formControlChronicMedicationForm} ${classes.formContactUs}`} variant="standard">
                            <CustomSelect
                                label="EMAIL"
                                name="mailContacto"
                                placeholder='EMAIL'
                                list={list}
                                selected={list[0]?.value}
                                disabled={true}
                            />
                        </FormControl>
                        <FormControl className={`${classes.contactFormControl} ${classes.formControlChronicMedicationForm} `} variant="standard" style={{marginTop:"10px"}}>
                            <LineBreakInput
                                label={consultation(screen)}
                                name="consulta"
                                placeholder='Escribe tu texto aqui'
                                onChange={handleChangeInput()}
                                multiline
                                rows={6}
                                inputProps={{ maxLength: 200 }}
                            />
                            < FormHelperText className={classes.errorText}>{error?.consulta}</FormHelperText>
                            <Typography variant="caption" style={{position: "absolute", marginTop: screen.sWidth < 768 ? "180px" : "190px", marginLeft: screen.sWidth < 768 ? "250px" : "460px"}}>{counterLength}/200</Typography>
                        </FormControl>
                        <CustomButton
                            customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                            title='Enviar' onClick={handleSubmit}
                            disabled={enableButton()}
                            style={{marginTop: "20px"}}
                        />
                    </>
                    :
                    (<div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}>
                        <CircularProgress />
                    </div>)
                }
                < ConfirmModal isOpen={open} setOpen={setOpen} errorClass={errorClass}
                    titleModal={
                        loading ?
                            <>
                                <span>Espere un momento...</span>
                            </>
                            :
                            <>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    {modalIcon}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    {modalTitle}
                                </div>
                            </>
                    }
                    description={
                        loading ?
                            <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}>
                                <CircularProgress color={'inherit'} />
                            </div>
                            :
                            modalDescription
                    }
                />
            </form>
        </>
    );
};
export default ContactUs;