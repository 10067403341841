import { Box, Divider, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from "../../assets/styles/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Link } from 'react-router-dom';

const ContainerBox = ({ children, title, link }) => {
    const classes = useStyles();
    return <>
        <div className={`${classes.containerBoxWeb} ${classes.customScroll}`}>
            <section className={classes.headerSection}>
                <Typography className={`${classes.titleTurn} ${classes.titleBox}`}>
                    {title}
                </Typography>
                {link &&
                    <Typography component={Link} to={link} className={`${classes.titleTurn} ${classes.titleBox}`}>
                        <ArrowForwardIosIcon />
                    </Typography>
                }
                <Divider className={`${classes.formDivider} ${classes.boxDivider}`} />
            </section>
            <article style={{ flex: '0 0 100%' }}>
                <Box className={`${classes.boxCard} ${classes.boxCardWeb}`}>
                    {children}
                </Box>
            </article>
        </div>
    </>;
};

export default React.memo(ContainerBox, (prevState, nextState) => {
    return prevState.children === nextState.children;
});