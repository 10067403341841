import { Box, Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useStyles from '../../../assets/styles/styles';
import { getRefundDetails } from "../../../redux/actions/refundsActions";
import NotFound from "../../HomeLogged/NotFound";

const Details = ({ detailsParameters, listDetails, setListDetails }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const { refundDetails, loading } = useSelector(state => state.refundsReducer);
    const steps = [
        '',
        'Iniciada',
        'En proceso',
        'Autorizado',
        'Finalizado',
    ];

    const detailStyles = {
        captionTitle: { fontSize: '12px', padding: '5px 0', fontWeight: '800' },
        captionDescription: { color: '#000000', fontWeight: '500', marginLeft: '5px' },
        informationResume: { fontSize: '14px', fontWeight: "600", color: '#0075c9' },
        affiliateName: { fontSize: '14px', padding: '8px 0' },
        status: { color: '#0075c9', marginLeft: '5px' }
    };

    useEffect(() => {
        if(detailsParameters?.length > 0 && detailsParameters?.[0] != undefined) {
            dispatch(getRefundDetails(detailsParameters?.[0]));
        }
    }, [])

    useEffect(() => {
        if (refundDetails?.reintegroDetalle !== undefined) {
            setActiveStep(refundDetails?.reintegroDetalle?.[0]?.reintegroEstadoCodigo);
        }
    }, [refundDetails?.reintegroDetalle]);

    useEffect(() => {
        let detalles = [];
        if (refundDetails?.reintegroDetalle?.length > 0) {
            detalles = refundDetails?.reintegroDetalle?.map(element => {
                return element;
            });
        }
        setListDetails(detalles);
    }, [refundDetails?.reintegroDetalle?.[0]]);

    const stepsComponent = () => {
        return (
            <Box sx={{ width: '100%' }} className={classes.boxSteps}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps?.map(step => (
                        <Step key={step}>
                            <StepLabel className={classes.stepLabelTab}></StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        );
    }

    const addDetailCaption = (title, description) => {
        return (
            <Typography
                style={ detailStyles.captionTitle }
                className={`${classes.txtCardDescription}`}>
                {title}
                <span style={ detailStyles.captionDescription }>
                    {description}
                </span>
            </Typography>
        );
    }

    return (
        <>
            { stepsComponent() }
            { listDetails?.length > 0 && !loading ?
                <section style={{ padding: '5%' }}>
                    <item>
                        <Typography
                            style={ detailStyles.informationResume }
                            className={`${classes.txtCardDescription}`}>
                            RESUMEN DE LA INFORMACION
                        </Typography>
                        <Typography
                            style={ detailStyles.affiliateName }
                            className={`${classes.txtCardDescription}`}>
                            { listDetails?.[0]?.reintegroAfiliadoSolicitante }
                        </Typography>
                        <Typography
                            style={ detailStyles.affiliateName }
                            className={`${classes.txtCardDescription}`}>
                            ESTADO:
                            <span style={ detailStyles.status }>
                                { listDetails?.[0]?.reintegroEstadoDescripcion }
                            </span>
                        </Typography>
                        { addDetailCaption('FECHA DE SOLICITUD:',new Date(listDetails?.[0]?.reintegroGestionFecha?.replace(/\s/, 'T'))?.toLocaleDateString('es-ES')) }
                        { addDetailCaption('DELEGACION:', listDetails?.[0]?.reintegroDelegacionDescripcion) }
                        { addDetailCaption('TIPO DE REINTEGRO:', listDetails?.[0]?.reintegroTipoDescripcion) }
                        { addDetailCaption('AFILIADO BENEFICIARIO:', listDetails?.[0]?.reintegroAfiliadoBeneficiario) }
                        { addDetailCaption('FECHA DE COMPROBANTE:', new Date(listDetails?.[0]?.reintegroComprobanteFecha?.replace(/\s/, 'T'))?.toLocaleDateString('es-ES')) }
                        { addDetailCaption('TELEFONO DE CONTACTO:', listDetails?.[0]?.reintegroContactoTelefono) }
                        { addDetailCaption('E-MAIL DE CONTACTO:', listDetails?.[0]?.reintegroContactoEmail) }
                    </item>
                </section>
            :
                <NotFound
                    customMsg={
                        <div className={classes.contentNotFound}>
                            <span>No hay contenido para mostrar</span>
                        </div>
                    }
                    wsItem={listDetails}
                    fullItem={listDetails}
                    loading={loading}
                />
            }
        </>
    );
};

export default Details;