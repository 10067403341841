import { Divider, Typography, CircularProgress } from "@material-ui/core";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import useStyles from '../../../assets/styles/styles';
import { Fragment, useEffect, useState } from "react";
import MovementsCard from "./MovementsCard";
import CustomButton from "../../../controls/Button/CustomButton";
import ModalActivities from "../../DialogActivities/ModalActivities";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { MONTHS, handleGoToBilling } from "../../../utils/utilsFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliateBillingFiles } from "../../../redux/actions/billingActions";
import Moment from 'moment';
import FormHeader from "../../PersonalData/FormHeader";

const userCode = parseInt(window.localStorage.getItem("numeroPersona"));

const Movements = () => {
    const { screen } = useResizeScreen();
    const classes = useStyles();
    const [isDeudor, setIsDeudor] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const billsReduxState = useSelector(state => state.billingReducer);
    const [bills, setBills] = useState([{}]);
    const [amount, setAmount] = useState(0);
    const dispatch = useDispatch();
    const [flag, setFlag] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleClickModal = (item) => (e) => {
        dispatch(getAffiliateBillingFiles({
            numeroPersona: userCode,
            comprobanteTipo: item?.comprobanteTipo,
            comprobanteNumero: item?.comprobanteNumero
        }));
        setFlag(true);
        setOpenModal(true);
        setTimeout(() => {
            setOpenModal(false);
        }, 2000);
    };

    const validateDebtor = (amountItem) => {
        if (amountItem < 0) {
            setIsDeudor(true);
        } else {
            setIsDeudor(false);
        }
    };

    useEffect(() => {
        validateReduxState(billsReduxState, flag);
    }, [billsReduxState?.billingFiles?.comprobante]);


    useEffect(() => {
        if (Object.values(billsReduxState?.bills).length > 0) {
            setBills(billsReduxState?.bills?.comprobantes);
            let saldoFloat = billsReduxState?.bills?.saldo?.toString().replace(".", ",");
            let saldoDecimal = saldoFloat?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            setAmount(saldoDecimal);
            validateDebtor(billsReduxState?.bills?.saldo);
            setLoading(false);
        }
    }, [billsReduxState?.bills]);

    function amountColor() {
        var response;
        if(amount < 0) {
            response = 'red';
        } else {
            response = '#40cc40';
        }
        if(amount === 0) {
            response = 'grey';
        }
        return response;
    }

    function showDeudor(deudor, saldo) {
        var response;
        if(deudor || saldo < 0) {
            response = (
                <Typography className={`${classes.titleBox} ${classes.titleEmergency}`}
                    style={{
                        textTransform: 'uppercase',
                        fontSize: '20px',
                        color: '#585858',
                        display: 'flex',
                        flex: '1 1 100%',
                        justifyContent: 'center'
                    }}>
                    Deudor
                </Typography>
            );
        } else if(saldo !== 0) {
            response = (
                <Typography className={`${classes.titleBox} ${classes.titleEmergency}`}
                    style={{
                        textTransform: 'uppercase',
                        fontSize: '20px',
                        color: '#585858',
                        display: 'flex',
                        flex: '1 1 100%',
                        justifyContent: 'center'
                    }}>
                    Acreedor 
                </Typography>
            );
        }
        return response;
    }

    return <>
        {screen.sWidth < 768 ?
            <FormHeader title={"Movimientos"} subtitle={""} handleGoTo={handleGoToBilling} />
            :
            <>
                <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`} style={{ justifyContent: 'flex-start', display: 'none' }}>
                    <Typography className={`${classes.titleTurn} ${classes.titleBox}`} style={{ textTransform: 'uppercase', alignItems: 'center', display: 'flex', fontSize: '14px' }}>
                        Movimientos
                    </Typography>
                </div>
                <Divider className={classes.formDivider} />
            </>
        }
        {loading ?
            <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
            :
            <Fragment>
                <div style={{ display: 'none', justifyContent: 'center', paddingTop: screen.sWidth < 768 ? '5%' : '2%', flexWrap: 'wrap' }}>
                    <Typography className={`${classes.titleBox} ${classes.titleEmergency} ${classes.amountTitle}`}>
                        Saldo actual
                    </Typography>
                    <Typography className={`${classes.titleEmergency} ${classes.amountMovements}`} style={{ color: amountColor() }}>
                        ${amount}
                    </Typography>
                    {showDeudor(isDeudor, amount)}
                </div>
                {bills?.length > 0 &&
                    <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`} style={{ justifyContent: 'flex-start' }}>
                        <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.subTitleMovements} `}>
                            Historial de Movimientos
                        </Typography>
                    </div>
                }
                <Divider className={classes.formDivider} style={{ display: screen.sWidth < 768 && 'none'  }} />
                <div className={classes.divSectionMovements} style={{ justifyContent: 'center' }}>
                    {bills?.map((item, i) => (
                        <MovementsCard
                            key={item}
                            headerIcon={
                                <>
                                    <Typography style={{ fontSize: '9px' }} className={`${classes.txtCalendar} ${classes.txtEllipsis}`}>
                                        PERIODO
                                    </Typography>
                                    <Typography style={{ fontSize: '15px' }} className={`${classes.txtNumberCalendar} ${classes.txtEllipsis}`}>
                                        {MONTHS[item.comprobanteMes]}
                                    </Typography>
                                    <Typography className={`${classes.txtCalendar} ${classes.txtEllipsis}`}>
                                        {item?.comprobanteAnio}
                                    </Typography>
                                </>
                            }
                            description={
                                <>
                                    <Typography style={{ fontWeight: "800", color: '#0075c9' }} className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>
                                        FACTURA Nº: <span style={{ color: 'black' }}>{item?.comprobanteNumero}</span>
                                    </Typography>
                                    <Typography style={{ color: '#0075c9' }} className={`${classes.txtCardDescription} ${classes.txtEllipsis}`} >
                                        FECHA DE VENCIMIENTO: <span style={{ color: 'black' }}>{Moment(item.comprobanteFechaVencimiento?.split('T')[0]).format('DD-MM-yyyy')}</span>
                                    </Typography>
                                    <Typography style={{ color: '#0075c9' }} className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>
                                        SALDO: <span style={{ color: 'black' }}>${item?.comprobanteSaldo}</span>
                                    </Typography>
                                    <Typography style={{ color: '#0075c9' }} className={`${classes.txtCardDescription} ${classes.txtEllipsis}`}>
                                        FECHA DE PAGO: <span style={{ color: 'black' }}>{Moment(item.comprobanteFechaPago?.split('T')[0]).format('DD-MM-yyyy')}</span>
                                    </Typography>
                                </>
                            }
                            buttonZone={
                                <section style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flex: 1 }}>
                                    <div>
                                        <CustomButton
                                            title="DESCARGAR"
                                            customClass={classes.lightLinearGradient}
                                            style={{ marginTop: "10px", width: "60%", fontSize: "6px", fontWeight: '800' }}
                                            onClick={handleClickModal(item)}
                                        />
                                    </div>
                                </section>
                            } index={i} />
                    ))}
                </div>
                {
                    bills?.length === 0 &&
                    <div className={`${classes.contentNotFound}`}>
                        <span style={{ color: '#0075C9' }}>No tenés historial de movimientos</span>
                    </div>
                }
            </Fragment>
        }
        {openModal &&
            <ModalActivities
                title={
                    <section style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                        <CheckCircleIcon className={classes.quickAccessIcon} />
                        <div style={{ display: 'flex', justifyContent: 'center', flex: '1 1 100%', marginTop: '20px' }}>
                            <span>La descarga comenzará en breve</span>
                        </div>
                    </section>
                }
                open={openModal}
                onClose={setOpenModal}
                modalconfirm={false}
                modalLogout={false}
            />
        }
    </>;
};

export default Movements;

function validateReduxState(billsReduxState, flag) {
    if (Object.values(billsReduxState?.billingFiles).length > 0) {
        if (Object.values(billsReduxState?.billingFiles?.comprobante).length > 0) {
            if (flag) {
                downloadClick(billsReduxState?.billingFiles?.comprobante[0]);
            }
        }
    }
}

function downloadClick(item) {
    let downloadBtn = document.createElement('A');
    /*Corroborar si es pdf*/
    downloadBtn.href = "data:application/pdf;base64," + item.archivo;
    downloadBtn.download = '';
    document.body.appendChild(downloadBtn);
    downloadBtn.click();
    document.body.removeChild(downloadBtn);
}
