
// In production, we register a service worker to serve assets from local cache.
import axios from "axios";
import { BroadcastChannel } from 'broadcast-channel';
const url = window.env.URL_SERVICE;
const idOneSignal = window.env.AppId;
const Urltag = '/Bus/UpdateTagUserDevice';


// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

export const UpdateTagSubscription = (param) => {
  return axios
    .post(`${url}${Urltag}`, param)
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};


export default function register() {

  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      window.OneSignal = window.OneSignal || [];
      OneSignal.push(function () {
        OneSignal.init({
          appId: idOneSignal,
          promptOptions: {
            customlink: {
              enabled: true, /* Required to use the Custom Link */
              style: "button", /* Has value of 'button' or 'link' */
              size: "medium", /* One of 'small', 'medium', or 'large' */
              color: {
                button: '#077BCB', /* Color of the button background if style = "button" */
                text: '#FFFFFF', /* Color of the prompt's text */
              },
              text: {
                subscribe: "Suscribirme", /* Prompt's text when not subscribed */
                unsubscribe: "Desuscribirme", /* Prompt's text when subscribed */
                explanation: "", /* Optional text appearing before the prompt button */
              },
              unsubscribeEnabled: true, /* Controls whether the prompt is visible after subscription */
            }
          }
        });
        OneSignal.getUserId(function (userId) {
          console.log("userid registrater", userId);
          window.localStorage.setItem('playerId', userId);
        });
        OneSignal.on('subscriptionChange', function (isSubscribed) {
          console.log("The user's subscription state is now: " + isSubscribed);
          if (isSubscribed) {
            var user = window.localStorage.getItem('email');
            var player = window.localStorage.getItem('playerId');
            var data = { User: user, PlayerId: player, Action: "SUBSCRIBTION" };
            UpdateTagSubscription(data);
          }
        });
        OneSignal.on('notificationDisplay', function (event) {
          console.log('OneSignal notification displayed:', event);
          const channel = new BroadcastChannel('displayed-messages');
          channel.postMessage({ msg: 'new notification from SERVICE-WORKER', element: event });
        });
        OneSignal.on('notificationDismiss', function (event) {
          console.log('OneSignal notification dismissed:', event);
        });
        OneSignal.push(["addListenerForNotificationOpened", function (data) {
          window.location.replace(window.env.URL_PUBLIC_MOBILE);
          console.log("Received NotificationOpened:");
          console.log(data);
        }]);
        OneSignal.on('notificationDismiss', function (event) {
          console.log('OneSignal notification dismissed:', event);
        });
        OneSignal.push(["addListenerForNotificationOpened", function (data) {
          window.location.replace(window.env.URL_PUBLIC_MOBILE);
          console.log("Received NotificationOpened:");
          console.log(data);
        }]);
        //subscription
      });
      OneSignal.push(["addListenerForNotificationOpened", function (data) {
        console.log("Received NotificationOpened:");
        console.log(data);
      }]);
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Lets check if a service worker still exists or not.
        checkValidServiceWorker(swUrl);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
            'worker. To learn more, visit https://goo.gl/SC7cgQ'
          );
        });
      } else {
        // Is not local host. Just register service worker
        registerValidSW(swUrl);
      }
    }, { passive: true });
  }
}

function registerValidSW(swUrl) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the old content will have been purged and
              // the fresh content will have been added to the cache.
              // It's the perfect time to display a "New content is
              // available; please refresh." message in your web app.
              console.log('New content is available; please refresh.');
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');
            }
            navigator.serviceWorker.addEventListener('message', function (event) {
              console.log(event.data.message); // Hello World !
            });
          }
        };
      };
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      if (
        response.status === 404 ||
        response.headers.get('content-type').indexOf('javascript') === -1
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}

function messageToClient(client, data) {
  return new Promise(function (resolve, reject) {
    const channel = new MessageChannel();

    channel.port1.onmessage = function (event) {
      if (event.data.error) {
        reject(event.data.error);
      } else {
        resolve(event.data);
      }
    };

    client.postMessage(JSON.stringify(data), [channel.port2]);
  });
}

self.addEventListener('push', function (event) {
  if (event && event.data) {
    self.pushData = event.data.json();
    if (self.pushData) {
      event.waitUntil(self.registration.showNotification(self.pushData.title, {
        body: self.pushData.body,
        icon: self.pushData.data ? self.pushData.data.icon : null
      }).then(function () {
        clients.matchAll({ type: 'window' }).then(function (clientList) {
          if (clientList.length > 0) {
            messageToClient(clientList[0], {
              message: self.pushData.body // suppose it is: "Hello World !"
            });
          }
        });
      }));
    }
  }
});

