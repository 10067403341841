import axios from "axios";
const API = axios.create({ baseURL: window.env.URL_SERVICE });
const url = window.env.URL_SERVICE;

API.interceptors.request.use(
  (config) => {

    config.headers = {};

    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }

    const numeroPersona = localStorage.getItem("numeroPersona");
    if(numeroPersona){
      config.headers.NumeroPersona = numeroPersona
    }

    return config;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => { 
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error.response.data.errors[0]);
  }
);


const cartillaEnvironment = "/api/FilterCartilla/GetNewFilters";
const cartillaLocalidadesBarrios = "/api/FilterCartilla/GetLocalidadesBarrioDB/";
const cartillaEspecialidadesEstudiosComplementarios =
  "/api/FilterCartilla/GetEspecialidadesEstudiosDB/";
const getPrestadoresResultCartilla =
  "/api/FilterCartilla/GetPrestadoresResult/";
const getPerfilPrestador = "/api/FilterCartilla/GetPerfilPrestador/";
const getTurnosAfiliado = "/api/Turnos/GetTurnosAfiliado/";
const getTurnosFavoritos = "/api/Turnos/GetTurnosFavoritos/";
const getDatosRecupera = "/api/Turnos/GetDatosRecupera/";
const cancelarTurno = "/api/Turnos/CancelarTurno/";
const ABTurnoFavorito = "/api/Turnos/ABTurnoFavorito/";
const GetSpecialtiesParameters = "/api/Turnos/GetSpecialtiesParameters/";
const GetDoctorsParameters = "/api/Turnos/GetDoctorsParameters/";
const GetFreeDoctorAppointments = "/api/Turnos/GetFreeDoctorAppointmentsParameters/";
const AnswerQuestion = "/AnswerQuestion/";
const UsefulPhone = "/UsefulPhone/";
const PhoneShift = "/PhoneShift/";
const especialidadesPorCentroMedico =
  "/CentroMedico/EspecialidadesPorCentroMedico";
const centrosMedicos = "/CentroMedico/CentrosMedicos";
const turnosLibresPorEspecialidad = '/CentroMedico/TurnosLibresPorEspecialidad';
const turnosLibresPorMedico = '/CentroMedico/TurnosLibresPorMedico';
const emergencyNumbers = '/Emergency';
const confirmarTurno = '/api/Turnos/ConfirmarTurno';
const personalData = '/Affiliate';
const PersonalDataContact = '/api/PersonalDataContact/';
const typesDocuments = '/Affiliate/DocumentsTypes';
const civilStatus = '/Affiliate/CivilStatus';
const nationalities = '/Affiliate/Countries';
const getDataWeight = '/Affiliate/GetPesoTalla';
const ABWeight = '/Affiliate/PesoTallaABM';
const getVitalParam = '/Affiliate/GetParametrosVitales';
const ABvitalParam = '/Affiliate/ParametrosVitalesABM';
const getEnrollments = '/Affiliate/GetEnrolamientos';
const getAllergiesParams = '/Affiliate/ParametroAlergias';
const getAllergiesAf = '/Affiliate/GetAlergias';
const ABMAllergies = '/Affiliate/AlergiasABM';
const getPhonesMD = '/Affiliate/GetTelefonosContactoEmergencia';
const abmPhones = '/Affiliate/TelefonosContactoEmergenciaABM';
const lab = '/Affiliate/GetResultadoLaboratorioCabecera';
const labDetail = '/Affiliate/GetResultadoLaboratorioDetalle';
const abmFactorGroup = '/Affiliate/GrupoFactorABM';
const factorGroup = '/Affiliate/GetGrupoFactor';
const parameterFactorGroup = '/Affiliate/ParametroGrupoFactor';
const abmChronicDiseases = '/Affiliate/EnfermedadesCronicasABM';
const chronicDiseases = '/Affiliate/GetEnfermedadesCronicas';
const parameterDiseases = '/Affiliate/ParametroEnfermedades';
const abmChronicMedication = '/Affiliate/MedicamentosCronicosABM';
const chronicMedication = '/Affiliate/GetMedicamentosCronicos';
const vaccine = '/Affiliate/GetVacunas';
const getDataUser = '/Affiliate/GetPersonRegister';
const addDataUser = '/Affiliate/AddPersonRegister';
const deleteDataUser = '/Affiliate/DeletePersonRegister';
const modifyDataUser = '/Affiliate/ModifyPersonRegister';
const getTypeRefundCoverageEndpoint = '/Affiliate/ParametroTipoCoberturaReintegro';
const getAreaRefundCoverageEndpoint = '/Affiliate/ParametroRubroCoberturaReintegro';
const refundCoverageEndpoint = '/Affiliate/CoberturaReintegro';
const callDoctorEndpoint = '/Affiliate/LlamandoAlDoctor';
const contactUs = '/Affiliate/Contactenos';
const Notification = '/Notification';
const byUser = '/Notification/GetAllByUser';
const activity = "/Activity";
const registerUser = '/Affiliate/ValidationRegisterUser';
const validationEndpoint = '/Affiliate/ValidationLogin';
const allCoveragePlans = '/CoveragePlan';
const credentialConfig = '/Credential';
const getProcedure = '/api/Tramites';
const postProcedureEndPoint = '/api/Tramites/AfiliadoMisTramites';
const getMotivosB = '/api/Tramites/SolicitudBajaMotivo';
const getSolicitudB = '/api/Tramites/SolicitudBaja';
const getFileUrl = '/Blob/';
const postRequestUrl = '/api/Tramites/AfiliadoSolicitudBaja';
const getChangePlanUrl = '/api/Tramites/CambioPlan';
const getMotivePlanUrl = '/api/Tramites/CambioPlanMotivo';
const getPlansUrl = '/Plan';
const postChangePlansUrl = '/api/Tramites/AfiliadoCambioPlan';
const getPermisionLowUrl = '/api/Tramites/CambioPlanHabilitado';
const postPreventionEndPoint = '/Affiliate/ControlPreventivo';
const personPlayerUrl = '/PersonPlayer/';
const allCoveragePlansEndpoint = '/CoveragePlan/GetAllPlansSecured';
const allActivitiesEndpoint = '/Activity/GetAllActivitiesSecured';
const wayToPay = '/Billing/GetWayToPay';
const readCommentsEndPoint = '/Authorizations/ComentariosLeidos';
const authorizationDetailsEndPoint = '/Authorizations/AuthorizationDetails';
const commentsEndPoint = '/Authorizations/Comentarios';
const pushCommentEndPoint = '/Authorizations/PublicarComentario';
const allAuthorizationsEndPoint = '/Authorizations/AutorizacionesRecupera';
const typeAttached = '/Authorizations/GetTipoAdjunto';
const attachedUrl = '/Authorizations/GetAdjuntos';
const postAttachedUrl = '/Authorizations/SubirAdjuntos';
const postSendRequestUrl = '/Authorizations/EnviarSolicitud';
const wayToPayUrl = '/api/Facturacion/MedioDePago';
const enabledDirectDebit = '/api/Facturacion/DebitoAutomaticoHabilitado';
const getDirectDebitURL = '/api/Facturacion/DebitoAutomaticoRecupera';
const directDebitAB = '/api/Facturacion/DebitoAutomaticoAB';
const Urltag = '/Bus/UpdateTagUserDevice';
const billingEndPoint = '/api/Facturacion/Factura';
const billingFilesEndPoint = '/api/Facturacion/ArchivoFactura';
const getParametersAuthorizationEndPoint = '/Authorizations/GetParametersAuthorization';
const surveyEndPoint = '/api/Poll/AddCounterEntries';
const sendSurveyEndPoint = '/api/Poll/VotePoll';
const newsEndPoint = '/Notify';
const authorizationsAffiliateEndPoint = '/Authorizations/GetAuthorizationsAffiliate';
const sedesEndPoint = '/Sede';
const getConfigurationsEndpoint = '/Configuration';
const getCardsEndpoint = '/Card';
const hobbiesEndPoint = '/Affiliate/HobbiesDeportes';
const getPharmaceuticalFormEndpoint = '/Affiliate/ParametroFormaFarmaceutica';
const getPlanPdfEndpoint = '/PlanPdf';
const capAndCopayLinksEndpoint = '/CapsAndCopayLinks';
const AuthorizationEndPoint = '/Authorization';
const turnosTelemedicina = '/Affiliate/TelemedicinaTurnoRecepcion';
const programRequirementAndFormEndpoint = '/ProgramRequirementsAndForms';
const LimitPlanEndPoint = "/LimitPlansMobile";
const downloadAuthorizationAttachments = '/Authorizations/DescargarAdjunto';

// Reintegros
const ABAccountRefundsEndPoint = '/Reintegros/CuentaReintegroAB';
const getAccountRefundsEndPoint = '/Reintegros/GetCuentasReintegro';
const parametersRefundsEndPoint = '/Reintegros/GetParametrosReintegro';
const postRefundsEndPoint = '/Reintegros/AddReintegro';
const getRefundsEndPoint = '/Reintegros/GetReintegro';
const getRefundDetailsEndPoint = "/Reintegros/GetDetallesReintegro";
const getRefundsAttachmentsEndPoint = "/Reintegros/GetAdjuntosReintegro";
const getRefundsCommentsEndPoint = "/Reintegros/GetComentariosReintegro";
const getDetailsAttachmentsEndPoint = "/Reintegros/DescargarAdjuntoReintegro";
const getRefundTypeAttachedEndPoint = "/Reintegros/GetTipoAdjuntoReintegros";
const AddAdjuntosReintegro = '/Reintegros/AddAdjuntosReintegro';
const cancelRefundEndPoint = '/Reintegros/AnularReintegro';
const AddComentarioReintegroEndPoint = '/Reintegros/AddComentarioReintegro';
const postReadRefundCommentEndPoint = '/Reintegros/ComentarioLeidoReintegro';
const downloadRefundsEndPoint = "/Reintegros/GetReintegroDescarga";

// Autorizaciones
const downloadAuthorizationsEndpoint = "/Authorizations/DescargaAutorizacion";
const cancelAuthorizationEndPoint = '/Authorizations/AnularAutorizacion';

// Campañas
const campaignEndpoint = "/Campaign";

// REcetas
const getRecipeEndPoint = "/Affiliate/GetReceta";



/* MEDICAL RECORDS */
export const fetchFilterCartilla = () => API.get(cartillaEnvironment);

export const getFilterCartilla = (param) => {
  return API
    .post(`${url}${cartillaEnvironment}`, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLocalidadesBarrio = (codigo) => {
  return API
    .get(`${url}${cartillaLocalidadesBarrios}${codigo}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getEspecialidadesEstudiosComplementarios = (codigo) => {
  return API
    .get(`${url}${cartillaEspecialidadesEstudiosComplementarios}${codigo}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPrestadoresResult = (filters) => {
  return API
    .post(`${url}${getPrestadoresResultCartilla}`, filters)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCompleteProfile = (providerCode) => {
  return API
    .post(`${url}${getPerfilPrestador}`, providerCode)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

/*********************************************************************************/

/* AFILLIATED APPOINTMENTS */

export const getAfilliatedAppointments = (afilliatedAppointments) => {
  return API
    .post(`${url}${getTurnosAfiliado}`, afilliatedAppointments)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAfilliatedAppointmentsRecord = (afilliatedAppointments) => {

  return API
    .post(`${url}${getTurnosAfiliado}`, afilliatedAppointments)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAfilliatedAppointmentsNextAPI = async (afilliatedAppointments) => {

  return API
    .post(`${url}${getTurnosAfiliado}`, afilliatedAppointments)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};



export const getAppointmentFavorite = (param) => {
  return API
    .post(`${url}${getTurnosFavoritos}`, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getDataGroupFamily = (param) => {
  return API
    .post(`${url}${getDatosRecupera}`, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};


export const getDataFilterAppointment = (param) => {
  return API
    .post(`${url}${getDatosRecupera}`, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const AppointmentCanceled = (param) => {
  return API
    .post(`${url}${cancelarTurno}`, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const ABAppointmentFavorite = (param) => {
  return API
    .post(`${url}${ABTurnoFavorito}`, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const GetSpecialtiesList = (param) => {
  return API
    .post(`${url}${GetSpecialtiesParameters}`, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const GetDoctorsList = (param) => {
  return API
    .post(`${url}${GetDoctorsParameters}`, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const GetFreeDoctorAppointmentsList = (param) => {
  return API
    .post(`${url}${GetFreeDoctorAppointments}`, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAnswerQuestion = () => {
  return API
    .get(`${url}${AnswerQuestion}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPhonesUtils = () => {
  return API
    .get(`${url}${UsefulPhone}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const GetShiftPhone = () => {
  return API
    .get(`${url}${PhoneShift}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
export const getSpecialties = (param) => {
  return API
    .post(`${url}${especialidadesPorCentroMedico}`, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getMedicalCenters = (param) => {
  return API
    .post(`${url}${centrosMedicos}`, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getNextAvailableAppointments = (params) => {
  return API.post(`${url}${turnosLibresPorEspecialidad}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getNextAvailableAppointmentsDoctor = (params) => {
  return API.post(`${url}${turnosLibresPorMedico}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const AppointmentConfirm = (param) => {
  return API
    .post(`${url}${confirmarTurno}`, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
/*********************************************************************************/

/***************************** EMERGENCY NUMBERS **********************************/
export const getEmergencyNumbers = () => {
  return API.get(`${url}${emergencyNumbers}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/*********************************************************************************/

/***************************** PERSONAL DATA *********************************/
export const getTypeOfContact = (affiliated) => {
  return API.post(`${url}${personalData}/ContactsTypes`, affiliated)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const postAddressAffiliated = (addressModel) => {
  return API.post(`${url}${personalData}`, addressModel)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getContactDataAffiliated = (params) => {
  return API
    .get(`${url}${PersonalDataContact}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getTypesDocuments = (params) => {
  return API
    .post(`${url}${typesDocuments}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getCivilStatus = () => {
  return API
    .get(`${url}${civilStatus}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getNationalities = () => {
  return API
    .get(`${url}${nationalities}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getCompleteData = (affiliated) => {
  return API.post(`${url}${personalData}/CompletedDates`, affiliated)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const abmFullData = (affiliated) => {
  return API.post(`${url}${personalData}/ABM`, affiliated)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getCredentials = (params) => {
  return API.post(`${url}${personalData}/Credentials`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getHobbies = async (params) => {
  return API.post(`${url}${hobbiesEndPoint}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getDeportes = async (params) => {
  return API.post(`${url}${hobbiesEndPoint}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/**WEIGHT AND HEIGHT */
export const getDataWheightHeight = (params) => {
  return API.post(`${url}${getDataWeight}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const ABWheightHeight = (params) => {
  return API.post(`${url}${ABWeight}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/**VITAL PARAMETERS */
export const getVitalParameters = (params) => {
  return API.post(`${url}${getVitalParam}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const ABvitalParameters = (params) => {
  return API.post(`${url}${ABvitalParam}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getAffiliateEnrollments = (params) => {
  return API.post(`${url}${getEnrollments}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getAllergies = async () => {
  return API.get(`${url}${getAllergiesParams}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getAffiliatedAllergies = async (affiliated) => {
  return API.post(`${url}${getAllergiesAf}`, affiliated)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const allergiesABM = async (item) => {
  return API.post(`${url}${ABMAllergies}`, item)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getMDPhones = async (item) => {
  return API.post(`${url}${getPhonesMD}`, item)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const ABMMDPhones = async (item) => {
  return API.post(`${url}${abmPhones}`, item)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const ABMFactorGroup = (item) => {
  return API.post(`${url}${abmFactorGroup}`, item)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getFactorGroup = (params) => {
  return API.post(`${url}${factorGroup}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getParameterFactorGroup = () => {
  return API.get(`${url}${parameterFactorGroup}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const ABMChronicDiseases = (item) => {
  return API.post(`${url}${abmChronicDiseases}`, item)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getChronicDiseases = (params) => {
  return API.post(`${url}${chronicDiseases}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getParameterDiseases = (params) => {
  return API.get(`${url}${parameterDiseases}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const ABMChronicMedication = (item) => {
  return API.post(`${url}${abmChronicMedication}`, item)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getChronicMedication = (params) => {
  return API.post(`${url}${chronicMedication}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getVaccine = (params) => {
  return API.post(`${url}${vaccine}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getLaboratoryStudies = (param) => {
  return API.post(`${url}${lab}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getDetailLaboratoryStudies = (param) => {
  return API.post(`${url}${labDetail}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};


export const getDataRegisterUser = (param) => {
  return API.post(`${url}${getDataUser}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/**Coverage */

export const getTypeRefundCoverage = async () => {
  return API.get(`${url}${getTypeRefundCoverageEndpoint}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const addDataRegisterUser = (param) => {
  return API.post(`${url}${addDataUser}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};


export const getAreaRefundCoverage = (param) => {
  return API.get(`${url}${getAreaRefundCoverageEndpoint}?tipoCodigo=${param}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const deleteDataRegisterUser = (param) => {
  return API.post(`${url}${deleteDataUser}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};


export const registerAffiliate = (param) => {
  return API.post(`${url}${registerUser}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const validationRegisterUser = (param) => {
  return API.post(`${url}${validationEndpoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const modifyDataRegisterUser = (param) => {
  return API.put(`${url}${modifyDataUser}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const postRefundCoverageResult = async (body) => {
  return API.post(`${url}${refundCoverageEndpoint}`, body)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const postContactUs = async (param) => {
  return API.post(`${url}${contactUs}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const postCallDoctor = async (body) => {
  return API.post(`${url}${callDoctorEndpoint}`, body)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getNotification = (id) => {
  return API
    .get(`${url}${Notification}/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};


export const getAllNotifications = (param) => {
  if(window.localStorage.getItem('access_token')){
    return API
      .get(`${url}${byUser}/${param.email}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
  return null;
};

export const modifyNotification = (param) => {

  return API
    .put(`${url}${Notification}`, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getActivities = () => {
  return API
    .get(`${url}${activity}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getActivitiesWithSecurity = () => {
  return API.get(`${url}${allActivitiesEndpoint}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getAllCoveragePlans = () => {
  return API.get(`${url}${allCoveragePlans}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getAllCoveragePlansWithSecurity = (params) => {
  // headerConfig.headers.NumeroPersona = params.numeroPersona;
  return API.get(`${url}${allCoveragePlansEndpoint}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getAllCredentials = (numeroPersona) => {
  // headerConfig.headers.NumeroPersona = numeroPersona;
  return API.get(`${url}${credentialConfig}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/* PROCEDURES */
export const getProcedures = (id) => {
  return API
    .get(`${url}${getProcedure}?sector=${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const postProcedure = async (param) => {
  return API.post(`${url}${postProcedureEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getDataAffiliatesBaja = (param) => {
  return API.post(`${url}${getDatosRecupera}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getMotivosBaja = () => {
  return API.get(`${url}${getMotivosB}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getSolicitudBaja = () => {
  return API.get(`${url}${getSolicitudB}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getFile = (param) => {
  return API.get(`${url}${getFileUrl}${param}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const postRequestLow = (param) => {
  return API.post(`${url}${postRequestUrl}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/**change plan */
export const getChangePlan = () => {
  return API.get(`${url}${getChangePlanUrl}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getMotiveLowPlan = () => {
  return API.get(`${url}${getMotivePlanUrl}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getPlans = (param) => {
  return API.post(`${url}${getPlansUrl}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const postChangePlan = (param) => {
  return API.post(`${url}${postChangePlansUrl}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};


export const getPermisionLow = (param) => {
  return API.post(`${url}${getPermisionLowUrl}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};


/**Prevention control */
export const postPrevention = async (param) => {
  return API.post(`${url}${postPreventionEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/**Prevention control */
export const deletePersonPlayer = async (param) => {
  return API.delete(`${url}${personPlayerUrl}${param.email}/${param.playerId}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/**Way To Pay control */
export const getWayToPay = (param) => {
  return API.post(`${url}${wayToPayUrl}`, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};


/* Direct Debit */
export const getEnabledDirectDebit = (param) => {
  return API.post(`${url}${enabledDirectDebit}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getDirectDebit = async (param) => {
  return API.post(`${url}${getDirectDebitURL}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const abDirectDebit = (param) => {
  return API
    .post(`${url}${directDebitAB}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const UpdateTag = (param) => {
  return API.post(`${url}${Urltag}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};


/**Authorizations**/

export const readComments = async (param) => {
  return API.post(`${url}${readCommentsEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getDetails = async (param) => {
  return API.post(`${url}${authorizationDetailsEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getComments = async (param) => {
  return API.post(`${url}${commentsEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const pushComments = async (param) => {
  return API.post(`${url}${pushCommentEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getAllAuthorizations = async (param) => {
  return API.post(`${url}${allAuthorizationsEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getAllBillsAffiliate = async (param) => {
  return API.post(`${url}${billingEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getBillingFiles = async (param) => {
  return API.post(`${url}${billingFilesEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getTypeAttached = async (param) => {
  return API.get(`${url}${typeAttached}/${param}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getParametersAuthorizationRequest = async () => {
  return API.get(`${url}${getParametersAuthorizationEndPoint}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getAttached = async (param) => {
  return API.post(`${url}${attachedUrl}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const downloadAttachments = async (param) => {
  return API.get(`${url}${downloadAuthorizationAttachments}/${param.numeroPersona}/${param.autorizacionAdjuntoCodigo}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const postAttached = async (param) => {
  return API.post(`${url}${postAttachedUrl}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const postRequest = async (param) => {
  return API.post(`${url}${postSendRequestUrl}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const cancelAuthorization = async (param) => {
  return API.get(`${url}${cancelAuthorizationEndPoint}/${param.numeroPersona}/${param.gestionCodigo}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/*Recetas  */
export const getRecipe = async (param) => {
  return API.post(`${url}${getRecipeEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};




/*Reintegros*/
export const getRefunds = async (param) => {
  return API.post(`${url}${getRefundsEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const ABAccountRefund = async (param) => {
  return API.post(`${url}${ABAccountRefundsEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getAccountRefunds = async (param) => {
  return API.get(`${url}${getAccountRefundsEndPoint}/${param}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getParametersRefunds = async () => {
  return API.get(`${url}${parametersRefundsEndPoint}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const postRefunds = async (param) => {
  return API.post(`${url}${postRefundsEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getRefundDetails = async (param) => {
  return API.post(`${url}${getRefundDetailsEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getRefundAttachments = async (param) => {
  return API.post(`${url}${getRefundsAttachmentsEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getRefundComments = async (param) => {
  return API.post(`${url}${getRefundsCommentsEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getDetailsAttachments = async (param) => {
  return API.get(`${url}${getDetailsAttachmentsEndPoint}/${param.numeroPersona}/${param.reintegroAdjuntoCodigo}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getRefundTypeAttached = async (param) => {
  return API.get(`${url}${getRefundTypeAttachedEndPoint}/${param}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const postRefundAttached = async (param) => {
  return API.post(`${url}${AddAdjuntosReintegro}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const cancelRefund = async (param) => {
  return API.get(`${url}${cancelRefundEndPoint}/${param.numeroPersona}/${param.gestionCodigo}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const postRefundAComment = async (param) => {
  return API.post(`${url}${AddComentarioReintegroEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const postReadRefundComments = async (param) => {
  return API.post(`${url}${postReadRefundCommentEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const downloadRefunds = async (param) => {
  return API.post(`${url}${downloadRefundsEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const downloadAuthorizations = async (param) => {
  return API.post(`${url}${downloadAuthorizationsEndpoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/*Encuesta*/
export const getCounterSurvey = async (param) => {
  if(window.localStorage.getItem('access_token')){
    return API.post(`${url}${surveyEndPoint}`, param)
      .then(response => {
        return response;
      })
      .catch(error => {
        throw error;
      });
  }
  return null;

};

export const sendSurvey = async (param) => {
  return API.post(`${url}${sendSurveyEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/* Noticias */
export const getNews = async () => {
  return API.get(`${url}${newsEndPoint}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getAuthorizationsAffiliate = async (param) => {
  return API.post(`${url}${authorizationsAffiliateEndPoint}`, param)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/* Sedes */
export const getHeadquaters = async () => {
  return API.get(`${url}${sedesEndPoint}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};


/**Configurations */

export const getConfigurations = async () => {
  return API.get(`${url}${getConfigurationsEndpoint}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getConfigById = async (id) => {
  return API.get(`${url}${getConfigurationsEndpoint}/${id}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/** Cards */

export const getCardsAPI = async () => {
  return API.get(`${url}${getCardsEndpoint}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/** PharmaceuticalForm **/

export const getPharmaceuticalForm = async () => {
  return API.get(`${url}${getPharmaceuticalFormEndpoint}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
}

/** PlanPdf **/

export const getPlanPdfByPlanCode = async (planCode) => {
  return API.get(`${url}${getPlanPdfEndpoint}/Plans/${planCode}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/** Practicas **/
export const getPracticas = async () => {
  return API.get(`${url}${AuthorizationEndPoint}/Practices`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getTelemedicineAppointments = (affiliated) => {
  return API.post(`${url}${turnosTelemedicina}`, affiliated)
  .then(response => {
    return response;
  })
  .catch(error => {
    throw error;
  });
};

/** Cap and Copay Links **/
export const getCapAndCopayLinkByPlanCode = async (planCode) => {
  return API.get(`${url}${capAndCopayLinksEndpoint}/Plans/${planCode}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/** Program Requirement And Form **/

export const getProgramRequirementAndFormByProgram = async (program) => {
  return API.get(`${url}${programRequirementAndFormEndpoint}/Programs/${program}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export const getMedicalPrograms = async() => {
  return API.get(`${url}${programRequirementAndFormEndpoint}/Programs`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/* LimitarPlanes */
export const getLimitPlan = () => {
  if(window.localStorage.getItem('access_token')){
    return API
    .get(`${url}${LimitPlanEndPoint}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
  }

  return null;

};

export const postLimitPlan = (param) => {
  return API
    .post(`${url}${LimitPlanEndPoint}`, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const putLimitPlan = (param) => {
  return API
    .put(`${url}${LimitPlanEndPoint}`, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteLimitPlan = (id) => {
  return API
    .delete(`${url}${LimitPlanEndPoint}/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};


/* Campañas */

export const getCampaign = async () => {
  return API.get(`${url}${campaignEndpoint}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};
