import ModalProfile from "../../DialogProfile/Modal";
import { CircularProgress, Typography } from "@material-ui/core";
import CustomButton from "../../../controls/Button/CustomButton";
import useStyles from "../../../assets/styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AppointmentCanceled, AppointmentConfirm, clearAffilliate, clearModelAppointment, clearMsgError, setModifyAppointment } from "../../../redux/actions/appointmentsActions";
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from "@material-ui/icons/Cancel";
import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { getConfigurations } from "../../../redux/actions/configurationAction";

export default function ModalConfirm(props) {
    const { model, openModal, setOpenModal, handleCloseDrawer } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const confirmTurnReducer = useSelector(state => state.appointmentsReducer?.postAppointment);
    const configurations = useSelector(state => state.configurationReducer?.allConfigurations?.configurations);
    const [loading, setLoading] = useState(false)
    const [openModalConfirm, setOpenModalConfirm] = useState(openModal)
    const [openModalError, setOpenModalError] = useState(false);
    const modelNextAppointment = useSelector(state => state.appointmentsReducer);
    const errorMessage = useSelector(state => state.appointmentsReducer.msgError);
    const [openModalNewTurn, setOpenModalNewTurn] = useState(false);
    const isNotProfessionalAndSpecialtyFlow = modelNextAppointment?.modelAppointment?.appointmentFlow !== 'medico' && modelNextAppointment?.modelAppointment?.appointmentFlow !== 'especialidad';
    const [message, setMessage] = useState('');

    const handleCancel = () => {
        setOpenModal(prevState => !prevState)
        dispatch(setModifyAppointment(""));
    }

    useEffect(() => {
        dispatch(getConfigurations());
    }, [])

    const handleAccept = () => {
        setLoading(true);
        if(modelNextAppointment?.modelModifyAppointment == "modify") {
            let auxModel = {
                numeroPersona: model?.numeroPersona,
                numeroTurno: model?.turnoAnterior,
                enviarMail: model?.enviarMail,
                mail: model?.mail
            };
            dispatch(AppointmentCanceled(auxModel));
            deleteModel();
            dispatch(AppointmentConfirm(model));
        } else {
            deleteModel();
            dispatch(AppointmentConfirm(model));
        }
        
    }

    const setConfigurationMessage = () => {
        const message = configurations?.filter(configuration => configuration?.id == 'mensajePopUpConfirmacionTurnos');

        message?.[0]?.valor && setMessage(message?.[0]?.valor);
    }

    useEffect(() => {
        setConfigurationMessage();
    }, [configurations])

    const deleteModel = () => {
        delete model.turnoAnterior;
    };

    useEffect(() => {
        if (confirmTurnReducer?.succeeded) {
            setOpenModalConfirm(false)
            setLoading(false)
            setOpen(true);
        }
        else if (!confirmTurnReducer?.succeeded && (confirmTurnReducer?.succeeded != undefined)) {
            setOpenModalConfirm(false)
            setOpenModalError(true)
        }
    }, [confirmTurnReducer])

    useEffect(() => {
        if(errorMessage?.length > 0) {
            setOpenModalConfirm(false);
            setOpenModalError(true);
        }
    }, [errorMessage])

    const handleNewTurn = () => {
        window.location.replace('/AppointmentHome?type=AskNewAppointment');
    }

    const handleCancelNewTurn = () => {
        window.location.replace('/');
    }

    useEffect(() => {
        if (open) {
            if (isNotProfessionalAndSpecialtyFlow) {
                dispatch(clearModelAppointment())
                dispatch(clearAffilliate())
                handleCloseDrawer();
            }
            setTimeout(() => {
                setOpen(prevState => !prevState)
            }, 1500);
            setTimeout(() => {
                setOpenModalNewTurn(true)
            }, 1500);
        }
    }, [open])
    
    const handleCancelErrorModal = () => {
        setOpenModalError(false);
        setOpenModal(prevState => !prevState);
        dispatch(clearMsgError());
    }

    const showMessageModal = () => {
        return (
            <div className={classes.borderWhite} style={{ left: "20px" }}>
                <Dialog
                    onClose={handleCancelErrorModal}
                    aria-labelledby="customized-dialog-title"
                    open={openModalError}
                    id="modalTurn">
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={() => {handleCancelErrorModal()}}
                        ><CloseIcon />
                        </IconButton>
                    <Box display="flex" id="cancelModal">
                        <div style={{ alignSelf: 'center' }}>
                            <DialogContent className={classes.contentDialogTitle}>
                                <section
                                    style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                                    <CancelIcon
                                        style={{ fontSize: "50px", marginBottom: "20px" }}
                                        className={classes.quickAccessIcon} />
                                    <div style={{ display: 'flex', justifyContent: 'center', flex: '1 1 100%', textAlign: "center" }}>
                                        <span>{errorMessage}</span>
                                    </div>
                                    </section>
                            </DialogContent>
                        </div>
                    </Box>
                </Dialog>
            </div>
        );
    }


    return (
        <div className={classes.borderWhite} style={{ left: "20px" }}>
            <ModalProfile
                type='cancel'
                title={
                    loading ?
                        <>
                            <div className={classes.popUpNewTurn}>Por favor esperá un momento...</div>

                        </>
                        :
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                Confirmar turno
                            </div>
                        </>
                }
                observation={
                    loading ?
                        <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}>
                            <CircularProgress color={'inherit'} />
                        </div>
                        :
                        <div>
                            <Typography style={{ fontSize: "14px" }}  className={classes.popUpNewTurn}>
                                ¿Estás seguro que querés confirmar tu turno?
                            </Typography>
                            <Typography style={{ fontSize: "14px", marginTop: '20px' }}  className={classes.popUpNewTurn}>
                                {message}
                            </Typography>
                        </div>
                }
                description={
                    !loading &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ marginRight: "0" }}>
                                <CustomButton
                                    title={"SI"}
                                    customClass={`${classes.buttonConfirmModal} ${classes.lightLinearGradient}`}
                                    onClick={handleAccept}
                                />
                            </div>
                            <div style={{ marginLeft: "0" }}>
                                <CustomButton
                                    title={"NO"}
                                    customClass={classes.buttonNotConfirmModal}
                                    onClick={handleCancel}
                                />
                            </div>
                        </div>
                    </>
                }
                open={openModalConfirm}
                onClose={() => {handleCancel()}}
            />
            <div className={classes.borderWhite} style={{ left: "20px" }}>
                <ModalProfile
                    type='cancel'
                    observation={
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: "20px" }}>
                                <div className={classes.iconConfirmTurn} >
                                    <DoneIcon></DoneIcon>
                                </div>
                            </div>
                            <div >
                                <Typography style={{ fontSize: "16px" }}>
                                    Tu turno fue confirmado con éxito
                                </Typography>
                            </div>
                        </>
                    }
                    open={open}
                    onClose={setOpen}
                />
            </div>

            <div className={classes.borderWhite} style={{ left: "20px" }}>
                {showMessageModal()}
            </div>

            <ModalProfile
                type='cancel'
                title={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            Sacar nuevo turno
                        </div>
                    </>
                }
                observation={
                    <div >
                        <Typography style={{ fontSize: "14px" }}>
                            ¿Necesitás sacar otro turno?
                        </Typography>
                    </div>
                }
                description={
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ marginRight: "0" }}>
                                <CustomButton
                                    title={"SI"}
                                    customClass={`${classes.buttonConfirmModal} ${classes.lightLinearGradient}`}
                                    onClick={handleNewTurn}
                                />
                            </div>
                            <div style={{ marginLeft: "0" }}>
                                <CustomButton
                                    title={"NO"}
                                    customClass={classes.buttonNotConfirmModal}
                                    onClick={handleCancelNewTurn}
                                />
                            </div>
                        </div>
                    </>
                }
                open={openModalNewTurn}
                onClose={setOpenModalNewTurn}
                style={{ textAlign: 'center' }}
            />
        </div>
    )
}

