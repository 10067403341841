import * as actionTypes from '../consts/actionTypes';
import {responseToReturn} from '../../utils/utilsFunctions.js';

const initialValues = {
    deleteStatus: [],
    updateTag:[],
    msgError: ''
    
};


export default function registerUsersReducer(state = initialValues, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_DELETE_PERSON_PLAYER]: responseToReturn('delete', state, action),
        [actionTypes.SUCCESS_UPDATE_TAG]: responseToReturn('updateTag', state, action),
        [actionTypes.FAILURE_DELETE_PERSON_PLAYER]: responseToReturn('delete', state, action),
        [actionTypes.FAILURE_UPDATE_TAG]: responseToReturn('updateTag', state, action),

    };

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }
    return receiveAction;
}