import * as actionTypes from '../consts/actionTypes';

const initialValues = {
    practices: [],
    msgError: ''
};

export default function practicasReducer(state = initialValues, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_GET_PRACTICAS]: responseToReturn('practices'),
        [actionTypes.FAILURE_GET_PRACTICAS]: responseToReturn('practices'),
    };
    function responseToReturn(typeState) {
        let res = { ...state };
        if (action.response) {
            res = { ...state, [typeState]: action.response.data.authorization, msgError: action.message };
        }
        if (action.message) {
            res = { ...state, msgError: action.message };
        }
        return res;
    }

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}