import * as actionTypes from '../consts/actionTypes';

const initialState = {
    procedures: [],
    postProcedure: [],
    dataAffiliates: [],
    lowMotives: null,
    requestLow: null,
    file: null,
    postRequest: null,
    getPlan: null,
    getMotivosPlan: null,
    getPlanDisponible: null,
    postChangePlan: null,
    permissionLow: null,
    msgError: ''

};

export default function procedureReducer(state = initialState, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SUCCESS_GET_PROCEDURES]: responseToReturn('procedures'),
        [actionTypes.SUCCESS_POST_PROCEDURE]: responseToReturn('postProcedure'),
        [actionTypes.SUCCESS_GET_DATOS_RECUPERA]: responseToReturn('dataAffiliates'),
        [actionTypes.SUCCESS_GET_MOTIVOS_BAJA]: responseToReturn('lowMotives'),
        [actionTypes.SUCCESS_GET_SOLICITUD_BAJA]: responseToReturn('requestLow'),
        [actionTypes.SUCCESS_GET_FILE]: responseToReturn('file'),
        [actionTypes.SUCCESS_POST_REQUEST_LOW]: responseToReturn('postRequest'),
        [actionTypes.SUCCESS_GET_CHANGE_PLAN]: responseToReturn('getPlan'),
        [actionTypes.SUCCESS_GET_MOTIVOS_BAJA_PLAN]: responseToReturn('getMotivosPlan'),
        [actionTypes.SUCCESS_GET_PLAN]: responseToReturn('getPlanDisponible'),
        [actionTypes.SUCCESS_POST_CHANGE_PLAN]: responseToReturn('postChangePlan'),
        [actionTypes.SUCCESS_PERMISSION_LOW]: responseToReturn('permissionLow'),
        [actionTypes.FAILURE_GET_PROCEDURES]: responseToReturn('procedures'),
        [actionTypes.FAILURE_POST_PROCEDURE]: responseToReturn('postProcedure'),
        [actionTypes.FAILURE_GET_DATOS_RECUPERA]: responseToReturn('dataAffiliates'),
        [actionTypes.FAILURE_GET_MOTIVOS_BAJA]: responseToReturn('lowMotives'),
        [actionTypes.FAILURE_GET_SOLICITUD_BAJA]: responseToReturn('requestLow'),
        [actionTypes.FAILURE_GET_FILE]: responseToReturn('file'),
        [actionTypes.FAILURE_POST_REQUEST_LOW]: responseToReturn('postRequest'),
        [actionTypes.FAILURE_GET_CHANGE_PLAN]: responseToReturn('getPlan'),
        [actionTypes.FAILURE_GET_MOTIVOS_BAJA_PLAN]: responseToReturn('getMotivosPlan'),
        [actionTypes.FAILURE_GET_PLAN]: responseToReturn('getPlanDisponible'),
        [actionTypes.FAILURE_POST_CHANGE_PLAN]: responseToReturn('postChangePlan'),
        [actionTypes.FAILURE_PERMISSION_LOW]: responseToReturn('permissionLow'),
        [actionTypes.CLEAN_POST_PROCEDURE_SELECTOR]: cleanPostProcedureSelector(),
    };
    function responseToReturn(type) {
        let res = { ...state };
        if (action.response) {
            res = { ...state, [type]: action.response.data, msgError: action.message };
        }
        if (action.message) {
            res = { ...state, msgError: action.message };
        }
        return res;
    }

    function cleanPostProcedureSelector() {
        return { ...state, postRequest: [] };
    }

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}