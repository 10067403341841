import { Divider, Typography, CircularProgress } from "@material-ui/core";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import FormHeader from "../../PersonalData/FormHeader";
import CustomButton from "../../../controls/Button/CustomButton";
import PhonesForm from "./PhonesForm";
import { useEffect, useState } from "react";
import useStyles from '../../../assets/styles/styles';
import { useFormHeaderText } from "../../../hooks/useFormHeaderText";
import { useDispatch, useSelector } from "react-redux";
import { getMDPhones } from "../../../redux/actions/medicalDataAction";
import { handleGoToPersonalMedicalData, showMessageModal } from "../../../utils/utilsFunctions";

const Phones = ({ type }) => {
    const { screen } = useResizeScreen();
    const classes = useStyles();
    const dispatch = useDispatch();
    const affiliatedPhones = useSelector(state => state.medicalDataReducer.affiliatedPhones);
    const [disabled, setDisabled] = useState(true);
    const [phone, setPhone] = useState([]);
    const [title, setTitle] = useState('');
    const { titleHeader, subtitleHeader } = useFormHeaderText(title,
        'Información sin carácter sanitario conforme ley Nº 26529',
        'Mis datos medicos',
        'Información sin carácter sanitario conforme ley Nº 26529',
        screen.sWidth);
    const [addItemScreen, setAddItemScreen] = useState(false);
    const [phoneType, setPhoneType] = useState('');
    const errorMessage = useSelector(state => state.medicalDataReducer.msgError);
    const [errorMessageModal, setErrorMessageModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleEdit = (e) => {
        e.preventDefault();
        setDisabled(prevState => !prevState);
    };

    const handleShowAddItemScreen = (e) => {
        e.preventDefault();
        setAddItemScreen(prevState => !prevState);
    };

    useEffect(() => {
        if (affiliatedPhones?.telefonos !== undefined) {
            setPhone(affiliatedPhones?.telefonos)
        }
    }, [affiliatedPhones?.telefonos])

    useEffect(() =>{
        setLoading(false);
    }, [affiliatedPhones]);

    useEffect(() => {
        return (() => {
            setPhone([]);
        })
    }, []);

    useEffect(() => {
        if (type === 'EmergencyPhones') {
        setLoading(true);
            dispatch(getMDPhones({
                numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                telefonoTipo: 'EMERGENCIA',
                count: 10,
                skip: 0
            }));
            setTitle('Mis telefonos de emergencia');
            setPhoneType('EMERGENCIA');
            const emergencia = affiliatedPhones?.telefonos?.filter(x => x.telefonoTipo === 'EMERGENCIA');
            if (affiliatedPhones?.telefonos !== undefined) {
                setPhone(emergencia);
            }
            else {
                setPhone([]);
            }
        }
        else {
        setLoading(true);
            dispatch(getMDPhones({
                numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
                telefonoTipo: 'CONTACTO',
                count: 10,
                skip: 0
            }));
            setTitle('Mis telefonos de contacto');
            setPhoneType('CONTACTO');
            const contacto = affiliatedPhones?.telefonos?.filter(x => x.telefonoTipo === 'CONTACTO');
            if (affiliatedPhones?.telefonos !== undefined) {
                setPhone(contacto);
            }
            else {
                setPhone([]);
            }
        }
        return (() => {
            setPhone([]);
        })
    }, [type]);

    useEffect(() => {
        if (errorMessage?.length > 0) {
            setErrorMessageModal(true);
        }
    }, [errorMessage]);

    return (
        <>
            <FormHeader title={title} subtitle={subtitleHeader} handleGoTo={handleGoToPersonalMedicalData} />
            {
                screen.sWidth > 768 &&
                <Divider className={classes.formDivider} />
            }
            <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                {(disabled && !addItemScreen) && (affiliatedPhones?.telefonos?.length > 0) &&
                    (
                        screen.sWidth <= 768 ?

                            <>
                                <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency}`} style={{ textTransform: 'uppercase' }}>
                                    {title}
                                </Typography>
                                <CustomButton
                                    title="EDITAR"
                                    customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                                    onClick={handleEdit}
                                    style={{margin: "0 10px"}}
                                />
                            </>


                            :
                            <>
                                <CustomButton
                                    title="EDITAR"
                                    customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                                    onClick={handleEdit}
                                    style={{margin: "0 10px"}}
                                />
                            </>
                    )
                }
                {!addItemScreen && disabled &&
                    <CustomButton
                    onClick={handleShowAddItemScreen}
                    title={"NUEVO "}
                    customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                />
                }
            </div>
            {loading ?
                <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
                :
            <PhonesForm
                phone={phone}
                setPhone={setPhone}
                disabled={disabled}
                setDisabled={setDisabled}
                addItemScreen={addItemScreen}
                setAddItemScreen={setAddItemScreen}
                phoneType={phoneType} />
            }
            {showMessageModal(errorMessage, errorMessageModal, setErrorMessageModal, classes.borderWhite, classes.quickAccessIcon, classes.contentDialogTitle, handleGoToPersonalMedicalData)}
        </>
    );
};

export default Phones;
