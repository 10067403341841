import { takeLatest, call, put } from "@redux-saga/core/effects";
import * as actionTypes from '../consts/actionTypes';
import * as API from '../api/index';

function* asyncDeletePersonPlayer({ payload }) {
    try {
        let response = yield call(API.deletePersonPlayer, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_DELETE_PERSON_PLAYER, response, message: '' });
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_DELETE_PERSON_PLAYER, message: error.message });
    }
}

function* asyncUpdateTag({ payload }) {
    try {
        let response = yield call(API.UpdateTag, payload);
        if (response)
            yield put({ type: actionTypes.SUCCESS_UPDATE_TAG, response, message: '' });
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_UPDATE_TAG, message: error.message });
    }
}

export default function* personPlayerSaga () {
    yield takeLatest(actionTypes.DELETE_PERSON_PLAYER, asyncDeletePersonPlayer);
    yield takeLatest(actionTypes.UPDATE_TAG, asyncUpdateTag);

  }
  
