import React, { useEffect, useState } from 'react';
import { Divider, Typography, CircularProgress } from "@material-ui/core";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import FormHeader from "../../PersonalData/FormHeader";
import useStyles from '../../../assets/styles/styles';
import { useFormHeaderText } from "../../../hooks/useFormHeaderText";
import Accordion from "../../../controls/Accordion/Accordion";
import { useSelector } from "react-redux";
import { handleGoToProgramsHome } from "../../../utils/utilsFunctions";

const Programs = () => {
    const { screen } = useResizeScreen();
    const classes = useStyles();
    const { titleHeader, subtitleHeader } = useFormHeaderText('Programas', '', '', screen.sWidth);
    const enrollments = useSelector(state => state.medicalDataReducer.programs);
    const [loading, setLoading] = useState(true);




    useEffect(() => {
        if (enrollments) {
            setLoading(false);
        }
    }, [enrollments]);


    const styles = {
        formTitles: {
            textTransform: 'uppercase',
            alignItems: 'center',
            display: 'flex'
        },

        container: {
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap'
        }
    }

    const spanishDateFormat = date => {
        let auxDate = date?.substr(0, date?.indexOf("T"));
        return (`${auxDate?.substr(8, 2)}/${auxDate?.substr(5, 2)}/${auxDate?.substr(0, 4)}`);
    }

    return (
        <>
            <FormHeader title={titleHeader} subtitle={subtitleHeader} handleGoTo={handleGoToProgramsHome} />
            {
                screen.sWidth > 768 &&
                <Divider className={classes.formDivider} />
            }
            {loading ?
                <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
                :
                <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`} style={{ justifyContent: 'flex-start' }}>
                    {screen.sWidth <= 768 ?
                        <>
                            <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency}`} style={styles.formTitles}>
                                No hay progrmas vigentes
                            </Typography>
                        </>
                        :
                        <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency}`} style={{ marginLeft: '300px' }}>
                            No hay progrmas vigentes actualmente
                        </Typography>

                    }
                </div>
            }
            <div style={ styles.container }>
                {enrollments?.enrolamientos?.map((item, i) => (
                    <div className={classes.divAccordionPrograms} >
                        <Accordion
                            title={
                                <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency}`} style={ styles.formTitles }>
                                    {item?.enrolamientoDescripcion}
                                </Typography>
                            }
                            details={
                                <>
                                  <Typography className={` ${classes.txtDescription}`} >
                                  <span className={`${classes.txtBold500} ${classes.grayTxt}`}>{"Fecha de vigencia:  "}</span><span className={classes.grayTxt}>{ spanishDateFormat(item?.fechaVigencia) }</span>
                                </Typography>
                                <Typography className={`${classes.txtDescription}`} >
                                    <span className={`${classes.txtBold500} ${classes.grayTxt}`}>{"Fecha de BAJA:  "}</span> <span className={classes.grayTxt}>{ spanishDateFormat(item?.fechaBaja) }</span>
                                </Typography>
                                <Typography className={`${classes.txtDescription}`} >
                                    <span className={`${classes.txtBold500} ${classes.grayTxt}`}>{"Diagnóstico:  "}</span><span className={classes.grayTxt}>{item?.diagnosticoDescripcion}</span>
                                </Typography>
                                <Typography className={`${classes.txtDescription}`} >
                                    <span className={`${classes.txtBold500} ${classes.grayTxt}`}>{"Característica:  "}</span> <span className={classes.grayTxt}>{item?.caracteristicaDescripcion}</span>
                                </Typography>
                                <Typography className={`${classes.txtDescription}`} >
                                    <span className={`${classes.txtBold500} ${classes.grayTxt}`}>{"Subcaracterística:  "}</span>  <span className={classes.grayTxt}>{item?.subCaracteristicaDescripcion}</span>
                                </Typography>
                                <Typography className={`${classes.txtDescription}`} >
                                    <span className={`${classes.txtBold500} ${classes.grayTxt}`}>{"Médico Responsable:  "}</span> <span className={classes.grayTxt}>{item?.medicoResponsable !== '' ? item?.medicoResponsable : 'No hay observaciones'}</span>
                                </Typography>
                                </>
                            }
                            customClass={classes.accordionConfirmTurn}
                        />
                    </div>
                ))}
            </div>
        </>
    );
};

export default Programs;