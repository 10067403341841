
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import { useResizeScreen } from "../../hooks/useResizeScreen";
import Menu from "../../components/Menu/Menu";
import useStyles from "../../assets/styles/styles";
import SelectTypeOfComponent from "../../components/PersonalData/SelectTypeOfComponent";
import { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import QuickAccessBox from '../../components/HomeLogged/QuickAccessBox';
import Header from '../../components/GenericHeader/Header';
import { useDispatch, useSelector } from 'react-redux';
import { getFullData, getTypesOfContact, getTypesOfContactMail, getTypesOfContactPhone } from '../../redux/actions/personalDataActions';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getAllBills, getWayToPay } from '../../redux/actions/billingActions';
import { getLimitPlan } from "../../redux/actions/limitPlansAction";
import { notAccessToThisModule, verifyPermission, redirectHrefTo } from "../../utils/utilsFunctions";
import useTrackPageView from "../../utils/useTrackPageView";
import Loading from '../../components/LoadingPage/Loading';

const userCode = parseInt(window.localStorage.getItem("numeroPersona"));

const affiliated = {
    numeroPersona: userCode
};
const contactTypesModel = {
    numeroPersona: userCode,
    clasificacion: "DOMICILIO"
};
const contactTypesPhoneModel = {
    numeroPersona: userCode,
    clasificacion: "TELEFONO"
};
const contactTypesMailModel = {
    numeroPersona: userCode,
    clasificacion: "MAIL"
};

const Billing = () => {
    const trackPageView = useTrackPageView("Facturación");
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const [type, setActiveType] = useState(-1);
    const [mobileDevice, setMobileDevice] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const match = search.match(/type=(.*)/);
    const typeURL = match?.[1];
    const spaceBetween = "space-between";
    const affiliatePlan = useSelector((state) => state.personalDataReducer?.fullData?.afiliado?.planDescripcion);
    const [ hasPermission, setHasPermission ] = useState(false);
    const loadingPermission = useSelector((state) => state.limitPlanReducer.requestInProgress);
    const limitPlanReducer = useSelector((state) => state.limitPlanReducer.allLimitPlanData);

    const quickAccessBilling = [
        { title: 'MOVIMIENTOS', icon: <HistoryOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 0, path: '/Billing?type=Movements' },
        { title: 'ADHESION / BAJA DEBITO AUTOMATICO', icon: <PaymentOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 0, path: '/Billing?type=DirectDebit' },
        { title: 'FORMAS DE PAGO DIGITALES', icon: <MonetizationOnOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 0, path: '/Billing?type=WayToPay' },
        { title: 'CONSULTAS / TRAMITES', icon: <FormatListBulletedOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 0, path: '/Billing?type=Formalities' },
        {
            title: 'PREGUNTAS FRECUENTES',
            icon: <QuestionAnswerOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />,
            type: 0, path: '/Billing?type=BillingFrequentQuestions'
        },
    ];

    useEffect(() => {
        dispatch(getLimitPlan());
        trackPageView();
      }, []);

    const handleClick = (item) => (e) => {
        setActiveType(item.type);
    };

    const handleRedirect = (href) => (e) => {
        e.preventDefault();
        redirectHrefTo(href)
    }

    const handleBack = () => {
        setActiveType(-1);
    };

    useLayoutEffect(() => {
        verifyPermission(limitPlanReducer, affiliatePlan, "Facturación", setHasPermission);
    }, [limitPlanReducer, affiliatePlan, hasPermission]);

    const setWidthIcons = (i) => {
        let response = classes.midIcon;
        if (i === 0 || i === 3) {
            response = classes.firstIcon;
        }
        return response;
    };

    useEffect(() => {
        validateResponsive();
    }, [screen.sWidth, type]);

    function validateResponsive() {
        if (screen.sWidth <= 768)
            setMobileDevice(true);
        else
            setMobileDevice(false);
    }

    useEffect(() => {
        // Realiza el dispatch para obtener los datos
        dispatch(getTypesOfContact(contactTypesModel));
        dispatch(getTypesOfContactPhone(contactTypesPhoneModel));
        dispatch(getTypesOfContactMail(contactTypesMailModel));
        dispatch(getFullData(affiliated));
        dispatch(getAllBills(affiliated));
        dispatch(getWayToPay(affiliated));
    }, []);

    useEffect(() => {
        window.onpopstate = (e) => {
            if (screen.sWidth <= 768 && type !== -1)
                window.location.href = '/Billing';
            else
                history.push('/');
        };
    });

    useEffect(() => {
        if (typeURL !== undefined) {
            let auxURL = `/Billing?type=${typeURL}`;
            let aux = quickAccessBilling.filter(x => x.path === auxURL);
            setActiveType(aux[0].type);
        }else{
            setActiveType(-1);
        }
    }, [typeURL]);

    function componentToRender(condition) {
        var show;
        if(condition) {
            show = (
                <>
                    {
                        screen.sWidth >= 768 &&
                        <>
                            <div className={`${classes.divQuickAccess} ${classes.customScroll}`} style={{ justifyContent: 'center' }}>
                                {quickAccessBilling.map((item, i) => (
                                    <Fragment key={i}>
                                        <QuickAccessBox
                                            component={Link} to={item.path}
                                            handleClick={item.href? handleRedirect(item.href) : handleClick}
                                            i={i} item={item} classes={classes}
                                            customClass={`${classes.personalDataItem} ${classes.billingIcon} ${setWidthIcons(i)}`}
                                            iconClass={classes.personalDataDivIcons}
                                            txtIcon={classes.billingTxtIcon}
                                        />
                                    </Fragment>
                                ))}
                            </div>
                        </>
                    }
                    {
                        screen.sWidth < 768 &&
                        <>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: spaceBetween, marginTop: "10%" }}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: spaceBetween, marginBottom: "-10%" }}>
                                    <QuickAccessBox
                                        component={Link} to={quickAccessBilling[0].path}
                                        handleClick={handleClick}
                                        i={0} item={quickAccessBilling[0]} classes={classes}
                                        customClass={`${classes.personalDataItem} ${classes.billingIcon} ${setWidthIcons(1)}`}
                                        iconClass={classes.personalDataDivIcons}
                                        txtIcon={classes.billingTxtIcon}
                                    />
                                    <QuickAccessBox
                                        component={Link} to={quickAccessBilling[1].path}
                                        handleClick={handleClick}
                                        i={1} item={quickAccessBilling[1]} classes={classes}
                                        customClass={`${classes.personalDataItem} ${classes.billingIcon} ${setWidthIcons(1)}`}
                                        iconClass={classes.personalDataDivIcons}
                                        txtIcon={classes.billingTxtIcon}
                                    />
                                </div>
                                <div style={{ alignSelf: 'center' }}>
                                    <QuickAccessBox
                                        component={Link} to={quickAccessBilling[2].path}
                                        handleClick={handleClick}
                                        i={2} item={quickAccessBilling[2]} classes={classes}
                                        customClass={`${classes.personalDataItem} ${classes.billingIcon} ${setWidthIcons(1)} ${classes.wayToPay}`}
                                        iconClass={classes.personalDataDivIcons}
                                        txtIcon={classes.billingTxtIcon}
                                    />
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: spaceBetween, marginTop: "-10%" }}>
                                    <QuickAccessBox
                                        component={Link} to={quickAccessBilling[3].path}
                                        handleClick={handleClick}
                                        i={3} item={quickAccessBilling[3]} classes={classes}
                                        customClass={`${classes.personalDataItem} ${classes.billingIcon} ${setWidthIcons(1)}`}
                                        iconClass={classes.personalDataDivIcons}
                                        txtIcon={classes.billingTxtIcon}
                                    />
                                    <QuickAccessBox
                                        component={Link} to={quickAccessBilling[4].path}
                                        handleClick={handleClick}
                                        i={4} item={quickAccessBilling[4]} classes={classes}
                                        customClass={`${classes.personalDataItem} ${classes.billingIcon} ${setWidthIcons(1)}`}
                                        iconClass={classes.personalDataDivIcons}
                                        txtIcon={classes.billingTxtIcon}
                                    />
                                </div>
                            </div>
                        </>
                    }
                </>
            );
        } else {
            show = (
                <>
                    {notAccessToThisModule()}
                </>
            );
        }
        return show;
    }

    return (
        <>
            <Menu />
            {(!mobileDevice || type === -1) &&
                <>
                    <Header title='Facturación' />
                    <Loading
                        isLoading={loadingPermission}
                        children={componentToRender(hasPermission)}
                    />
                </>
            }
            <SelectTypeOfComponent handleBack={handleBack} />
        </>
    );
};

export default Billing;

