import { Divider, Typography } from '@material-ui/core';
import React from 'react'
import DrawerAppointmentsSteps from './DrawerAppointmentsSteps';
import WizardLine from './WizardLine';
import useStyles from "../../assets/styles/styles";
import TopButtons from './Web/Buttons/TopButtons';
import { useResizeScreen } from '../../hooks/useResizeScreen';

const list = [
    {
        step0: ""
    },
    {
        step1: ""
    },
    {
        step2: ""
    },
    {
        step3: ""
    },
];

const titles = [
    {
        title1: "Elegir Afiliado",
    },
    {
        title2: "Elegir modalidad de atención",
    },
    {
        title3: "Elegir especialidad/profesional",
    },
    {
        title4: "Centro Médico",
    },
    {
        title5: "Elegir Centro Médico",
    },
    {
        title6: "Turno telefónico",
    },
    {
        title7: "Próximos turnos",
    },
    {
        title8: "Próximo turno",
    },
    {
        title9: "Confirmación",
    }
];

const AskNewAppointment = (props) => {
    const {
        activeStepTurn, setActiveStepTurn, handleOpenCancelModalTurn,
        handleBackTurn, handleNextTurn, handleCloseDrawerTurn,
        setSpecialistVisualizacion, specialistVisualizacion
    } = props;
    const classes = useStyles();
    const { screen } = useResizeScreen();

    return (
        <>
            {screen.sWidth > 768 && activeStepTurn === 3 ?
                <div className={classes.containerNewAppointment} >
                    <DrawerAppointmentsSteps
                        isWeb={true} open={true} titles={titles}
                        activeStep={activeStepTurn} setActiveStep={setActiveStepTurn}
                        handleOpenCancelModal={handleOpenCancelModalTurn}
                        handleBack={handleBackTurn} handleNext={handleNextTurn}
                        handleCloseDrawer={handleCloseDrawerTurn}
                        setSpecialistVisualizacion={setSpecialistVisualizacion}
                        specialistVisualizacion={specialistVisualizacion}
                    />
                </div>
                :
                <div className={classes.containerNewAppointment} >
                    <TopButtons
                        handleOpenCancelModalTurn={handleOpenCancelModalTurn}
                        handleBackTurn={handleBackTurn}
                        activeStepTurn={activeStepTurn}
                        handleNextTurn={handleNextTurn}
                        titleCancel={"Cancelar"}
                        titleConfirm={"Siguiente"} />
                    <WizardLine list={list} activeStep={activeStepTurn} />
                    <div style={{ paddingTop: '10px' }}>
                        <Typography className={`${classes.titleTurn} ${classes.titleBox}`} style={{ fontWeight: "600" }}>
                            Nuevo turno
                        </Typography>
                    </div>
                    <Divider className={classes.formDivider} />
                    <DrawerAppointmentsSteps
                        isWeb={true} open={true} titles={titles}
                        activeStep={activeStepTurn} setActiveStep={setActiveStepTurn}
                        handleOpenCancelModal={handleOpenCancelModalTurn} handleBack={handleBackTurn}
                        handleNext={handleNextTurn} handleCloseDrawer={handleCloseDrawerTurn}
                        setSpecialistVisualizacion={setSpecialistVisualizacion}
                        specialistVisualizacion={specialistVisualizacion}
                    />
                </div>
            }
        </>
    )
}

export default AskNewAppointment
