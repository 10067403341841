import { Button, Divider, CircularProgress } from "@material-ui/core";
import useStyles from '../../../assets/styles/styles';
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import CustomButton from "../../../controls/Button/CustomButton";
import CreateIcon from '@material-ui/icons/Create';
import React, { useState, useEffect, useLayoutEffect } from "react";
import FormHeader from "../FormHeader";
import { useSelector } from "react-redux";
import Form from "./Form";
import { handleGoToPersonalData } from "../../../utils/utilsFunctions";

const AddressForm = () => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const [disabled, setDisabled] = useState(true);
    const fullPersonalData = useSelector(state => state.personalDataReducer);
    const [affiliateAddress, setAffiliateAddress] = useState([]);
    const typeOfAddress = useSelector(state => state.personalDataReducer);
    const [editMode, setEditMode] = useState({});
    const [loading, setLoading] = useState(true);

    const handleEdit = (e) => {
        setDisabled(prevState => !prevState);
    };

    const handleCancel = (e) => {
        window.location.reload()
    };

    function setDataOfWS() {
        if (Object.values(fullPersonalData?.fullData)?.length > 0) {
            let aux = fullPersonalData?.fullData?.afiliado;
            aux?.domicilios?.forEach((item, i) => {
                item.domicilioModo = "M";
            });
            setAffiliateAddress(aux);
            setLoading(false);
        }
    }

    useEffect(() => {
        let aux = {};
        typeOfAddress?.address?.tipoContacto?.forEach((item) => {
            aux[item.tipoContactoDescripcion?.trimEnd()] = false;
        });
        setEditMode(aux);
    }, []);

    useLayoutEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        setDataOfWS();

        return (() => {
            setDataOfWS();
        });
    }, [fullPersonalData?.fullData?.afiliado]);

    return <>
        <FormHeader title='Particular' handleGoTo={handleGoToPersonalData} />
        {
            screen.sWidth > 768 &&
            <Divider className={classes.formDivider} />
        }
        {disabled ?
            <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                {screen.sWidth <= 768 ?
                <>
                    <Button onClick={handleEdit}>
                        <CreateIcon className={classes.ShowMoreArrowIcon} style={{ fontSize: "25px", marginLeft: "600%" }} />
                    </Button>
                </>
                    :
                    (screen.sWidth > 768 && disabled ?
                        (<CustomButton
                            title="EDITAR"
                            customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                            onClick={handleEdit}
                        />) :
                        ""
                    )
                }
            </div> :
            (screen.sWidth > 768 &&
            <CustomButton
                title="CANCELAR"
                customClass={`${classes.btnNewWeigth} ${classes.btnFilterAppointment}`}
                onClick={handleCancel}
                style={{ marginLeft: '80%', marginTop: "10px", fontSize: "10px" }}
            />)
        }

        {loading ?
            <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
            :
            <Form
                affiliateAddress={affiliateAddress}
                setAffiliateAddress={setAffiliateAddress}
                typeOfAddress={typeOfAddress?.address?.tipoContacto}
                disabled={disabled}
                handleCancel={handleCancel}
                editMode={editMode}
                setEditMode={setEditMode}
            />
        }
    </>;
};

export default React.memo(AddressForm);