import * as actionTypes from "../consts/actionTypes";

/**acciones que se disparan del componente */
export function postCallDoctor(params) {
    return {
        type: actionTypes.POST_CALL_DOCTOR,
        payload: params,
    };
}

export function cleanVirtualDoctorModel() {
    return {
        type: actionTypes.CLEAN_VIRTUAL_DOCTOR_MODEL,
        payload: ''
    };
}