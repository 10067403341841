import useStyles from "../../assets/styles/styles";
import Drawer from "../../controls/Drawer/Drawer";
import { useDispatch, useSelector } from "react-redux";
import WizardLine from "./WizardLine";
import React, { useCallback, useEffect, useState } from "react";
import { clearModelAppointment, getDataGroupFamily, getFullGroupFamilyAppointment, getMedicalCenters, getModelToSaveAppointment, setModelToSaveAppointment, setModifyAppointment } from "../../redux/actions/appointmentsActions";
import CustomButton from "../../controls/Button/CustomButton";
import DrawerAppointmentsSteps from "./DrawerAppointmentsSteps";
import ModalProfile from "../../components/DialogProfile/Modal";
import { IconButton, Typography } from "@material-ui/core";
import { getStepCount, setActualStep, setNextStep, setPrevStep } from "../../redux/actions/stepAppointmentsActions";
import FooterDrawer from "./FooterDrawer";
import { ValidateSteps } from "./Utils/ValidateSteps";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import SelectTypeOfComponent from "../PersonalData/SelectTypeOfComponent";
import Header from "../GenericHeader/Header";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router";
import { useCurrentPosition } from "../../hooks/useCurrentPosition";
import { checkSpecialistVisualizacion, getSpecialtyAndDoctorCodes } from "./Utils/CheckAppointmentVisualization";


const userCode = parseInt(window.localStorage.getItem("numeroPersona"));

const specialtiesList = {
  numeroPersona: userCode,
  centroMedico: 72,
  tipoAtencion: "TODOS",
  parametroLlamado: 0,
};

const persona = {
  codigo: parseInt(window.localStorage.getItem("numeroPersona"))
};

const person = {
  numeroPersona: userCode,
};

const AskTurn = (props) => {
  const titles = [
    {
      title1: "Elegir Afiliado",
    },
    {
      title2: "Elegir modalidad de atención",
    },
    {
      title3: "Elegir especialidad/profesional",
    },
    {
      title4: "Centro Médico",
    },
    {
      title5: "Elegir Centro Médico",
    },
    {
      title6: "Turno telefónico",
    },
    {
      title7: "Próximos turnos",
    },
    {
      title8: "Próximo turno",
    },
    {
      title9: "Confirmación",
    }
  ];

  const list = [
    {
      step0: ""
    },
    {
      step1: ""
    },
    {
      step2: ""
    },
    {
      step3: ""
    },
    {
      step4: ""
    },

  ];

  const classes = useStyles();
  const dispatch = useDispatch();
  const { screen } = useResizeScreen();
  const [activeStep, setActiveStep] = useState(props.step ?? 0);
  const [specialistVisualizacion, setSpecialistVisualizacion] = useState();//"TURNO WEB" | "TURNO TELEFONICO"
  const { handleCloseDrawer, setOpen } = props;
  const [openModal, setOpenModal] = useState(false);
  const modelNextAppointment = useSelector(state => state.appointmentsReducer);
  const [modelAppointment, setModelAppointment] = useState(modelNextAppointment?.modelAppointment);
  const history = useHistory();
  const { currentPosition } = useCurrentPosition();
  const [position, setPosition] = useState({ codigo: parseInt(window.localStorage.getItem("numeroPersona")), lat: "", long: "" });
  const specialties = useSelector((state) => state.medicalCenterReducer?.specialtyList);

  const handleNext = () => {
    let auxStepCount = validateTelemedicina('next');
    if (ValidateSteps(activeStep, modelAppointment, screen)) {
      setActiveStep((prevActiveStep) => prevActiveStep + auxStepCount);
      dispatch(setActualStep(activeStep));
      dispatch(setNextStep(activeStep + auxStepCount));
    }
  };

  const handleBack = useCallback(() => {
    let auxStepCount = validateTelemedicina('back');
    if (screen.sWidth <= 768) {
      if (activeStep == 6 && modelAppointment?.preference == 2) {
        auxStepCount = 3;
      }
      if ((activeStep == 6 && modelAppointment?.preference == 1) || activeStep == 4) {
        auxStepCount = 2;
      }
    }
    let CLEAN_MODEL = {
      2: cleanModelSpecialty,
    };
    if (activeStep === 2) {
      CLEAN_MODEL[activeStep]();
    }
    setActiveStep((prevActiveStep) => prevActiveStep - auxStepCount);
    dispatch(setActualStep(activeStep));
    dispatch(setPrevStep(activeStep - auxStepCount));
  }, [activeStep, modelAppointment]);

  const cleanModelSpecialty = () => {
    let aux = modelAppointment;
    delete aux.preference;
    delete aux.observaciones;
    delete aux.preparacion;
    delete aux.doctorVisualizacion;
    setModelAppointment(aux);
    dispatch(setModelToSaveAppointment(aux));
  };

  useEffect(() => {
    if(modelAppointment?.modalidadTurno){
      modelAppointment?.modalidadTurno === 'Telemedicina'? setSpecialistVisualizacion('TURNO TELEFONICO')
      : setSpecialistVisualizacion('TURNO WEB')
    }
  },[modelAppointment?.modalidadTurno]);

  useEffect(() => {
    if (currentPosition.lat !== 0 && currentPosition.lat !== undefined && currentPosition.lng !== 0) {
        setPosition({ ...position, lat: currentPosition.lat.toString(), long: currentPosition.lng.toString() });
    }
}, [currentPosition]);

  const validateTelemedicina = (param) => {
    let stepCount = 1;
    if (modelNextAppointment.modelAppointment.modalidadTurno === 'Telemedicina') {
      if ((param === 'next' && activeStep === 0) || (param === 'back' && activeStep === 2)) {
        stepCount = 2;
      }
    }
    return stepCount;
  };

  const handleOpenCancelModal = useCallback(() => {
    setOpenModal(prevState => !prevState);
    dispatch(setModifyAppointment(""));
  }, [openModal]);

  useEffect(() => {
    window.onpopstate = (e) => {
      setOpen(false);
    };
  });

  useEffect(() => {
    if (position.lat !== "" && position.long !== ""){
      dispatch(getMedicalCenters(position));
    }
  }, [position]);

  useEffect(() => {
    dispatch(getDataGroupFamily(person));
    dispatch(setActualStep(activeStep));
    dispatch(getStepCount());
    if (modelNextAppointment?.modelAppointment?.length > 0) {
      specialtiesList.centroMedico = modelNextAppointment?.modelAppointment?.centroMedico;
    }
    dispatch(getModelToSaveAppointment());
    dispatch(getFullGroupFamilyAppointment(person));
    if (screen.sWidth <= 768) {
      setOpen(true);
    }
    if (modelNextAppointment?.modelModifyAppointment == "modify" || modelNextAppointment?.modelModifyAppointment == "repeat") {
      if (screen.sWidth <= 768) {
        setActiveStep(3);
      }
      else {
        setActiveStep(2);
      }
    }
    else {
      setActiveStep(0);
    }
    /*Return -> verificar cuando pedis un turno y volves a modificar un turno"*/
    return () => {
      dispatch(clearModelAppointment());
      dispatch(setModifyAppointment(""));
    };
  }, []);

  useEffect(() => {
    setModelAppointment(modelNextAppointment?.modelAppointment);
  }, [modelNextAppointment?.modelAppointment]);

  useEffect(() => {
    if (activeStep === list?.length)
      setActiveStep(0);
  }, [activeStep]);

  const handleClose = () => {
    handleCloseDrawer();
    dispatch(clearModelAppointment());
    dispatch(setModifyAppointment(""));
    history.push('/AppointmentHome');
  };

  useEffect(() => {
    setOpen(true);
  }, [screen.sWidth <= 768]);

  useEffect(() => {
    if (!modelNextAppointment?.modelAppointment?.especialidadCodigo) {
      return;
    }

    const { agenda, specialtyCode, doctorCode } = getSpecialtyAndDoctorCodes(specialties, modelNextAppointment);

    checkSpecialistVisualizacion(agenda, specialtyCode, doctorCode, setSpecialistVisualizacion);

  }, [specialties]);

  return (
    <>
      {
        screen.sWidth <= 768 ?
          <>
            <Header title={"Mis Turnos"} zIndexTitle={10} positionTitle={"relative"} />
            {activeStep !== 0 && (modelNextAppointment.modelModifyAppointment == "" || modelNextAppointment.modelModifyAppointment == undefined) &&
              <>
                <div style={{ position: "absolute", zIndex: "20", top: "105px", left: "10px" }}>
                  <IconButton onClick={handleBack}>
                    <ArrowBackIcon className={classes.ShowMoreArrowIcon} style={{ fontSize: "30px" }} />
                  </IconButton>
                </div>
              </>
            }
            <Drawer open={props.open}>
              <WizardLine list={list} activeStep={activeStep} />
              <DrawerAppointmentsSteps isWeb={false} open={props.open} titles={titles} activeStep={activeStep} setActiveStep={setActiveStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} handleNext={handleNext} handleCloseDrawer={handleCloseDrawer} specialistVisualizacion={specialistVisualizacion} setSpecialistVisualizacion={setSpecialistVisualizacion}/>
              {!(activeStep === 5 || activeStep === 4 || activeStep === 2 || activeStep === 3 || activeStep === 6) &&
                <FooterDrawer activeStep={activeStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} handleNext={handleNext} />
              }
            </Drawer>
          </>
          :
          (<>
            <SelectTypeOfComponent
              activeStepTurn={activeStep}
              setActiveStepTurn={setActiveStep}
              handleOpenCancelModalTurn={handleOpenCancelModal}
              handleBackTurn={handleBack}
              handleNextTurn={handleNext}
              handleCloseDrawerTurn={handleCloseDrawer}
              setSpecialistVisualizacion={setSpecialistVisualizacion}
              specialistVisualizacion={specialistVisualizacion}
            />
          </>)
      }

      {
        openModal &&
        <ModalProfile
          type='cancel'
          title={
            <>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                Cancelar turno
              </div>
            </>
          }
          observation={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography>
                ¿Estás seguro que querés cancelar tu turno?
              </Typography>
            </div>
          }
          description={
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              < CustomButton
                title={"Si"}
                customClass={`${classes.buttonConfirmModal} ${classes.lightLinearGradient}`}
                onClick={handleClose}
              />
              < CustomButton
                title={"No"}
                customClass={classes.buttonNotConfirmModal}
                onClick={handleOpenCancelModal}
              />
            </div>
          }
          open={openModal}
          onClose={setOpenModal}
        />
      }
    </>
  );
};

export default React.memo(AskTurn, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open;
});