import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import useStyles from "../../assets/styles/styles";

const FrequentQuestionsButton = () => {
    const classes = useStyles();

	const handleGoToFrequentQuestions = () => {
		window.open(`https://www.oslpasteur.com.ar/PortalHTML5/accesos/PreguntasFrecuentes/general.html?adjuntos`, '_blank');
	}

	return (
		<>
            <button
                className={ `${classes.lightLinearGradient} ${classes.frequentQuestionsButton}` }
                onClick={ handleGoToFrequentQuestions }
				type={'button'}>

                <HelpOutlinedIcon className={`${classes.iconFrequentQuestionsButton}`} />
            </button>
		</>
	);
}

export default FrequentQuestionsButton;
