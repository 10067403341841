import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DateTimePicker from '@material-ui/lab/DateTimePicker';
import "./DateTimerPickerStyle.css";
import useStyles from "../../assets/styles/styles";
import { es } from "date-fns/locale";


const CustomizedDateTimerPicker=(props)=>{
  const classes = useStyles();

  return (
    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns} >
      <DateTimePicker className={classes.dateTimerPicker}
      id={props.id}
        renderInput={(props) => <TextField {...props} />}
        label=""
        toolbarPlaceholder=""
        value={props.value}
        onChange={(newValue) => {
          props.setValue(newValue);
        }}
      />
    </LocalizationProvider>
  );
}

export default CustomizedDateTimerPicker
