import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
// import { createBrowserHistory } from "history";

// const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: window.env.APPINSIGHTS_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    // extensionConfig: {
    //   [reactPlugin.identifier]: { history: browserHistory },
    // },
  },
});

appInsights.loadAppInsights();

export default (Component) => withAITracking(reactPlugin, Component);
export { appInsights, reactPlugin };
