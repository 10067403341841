import React from 'react';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';

const DrawerRequestSteps = ({ isWeb, open, activeStep, setActiveStep, handleOpenCancelModal, handleBack, handleNext, handleCloseDrawer }) => {
    const ShowStepsFunction = () => {
        const DEFAULT_STEP = <Step1 activeStep={activeStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} setActiveStep={setActiveStep} />;
        const ACTIVE_STEPS = {
            0: <Step1 activeStep={activeStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} setActiveStep={setActiveStep} />,
            1: <Step2 activeStep={activeStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} setActiveStep={setActiveStep} />,
            2: <Step3 activeStep={activeStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} />,
        };

        const ACTIVEWEB_STEPS = {
            0: <Step1 activeStep={activeStep} handleOpenCancelModal={handleOpenCancelModal} handleBack={handleBack} setActiveStep={setActiveStep} />,
            1: ACTIVE_STEPS[activeStep],
            2: ACTIVE_STEPS[activeStep],
        };

        let returnActiveStep = DEFAULT_STEP;

        if (activeStep) {
            if (isWeb) {
                returnActiveStep = ACTIVEWEB_STEPS[activeStep];
            } else {
                returnActiveStep = ACTIVE_STEPS[activeStep];
            }
        }
        return returnActiveStep;
    };

    return <>
        {ShowStepsFunction()}
    </>;
};

export default React.memo(DrawerRequestSteps, (prevState, nextState) => {
    return prevState.activeStep === nextState.activeStep;
});
