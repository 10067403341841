import React from 'react';
import { useResizeScreen } from "../../hooks/useResizeScreen";
import useStyles from "../../assets/styles/styles";

import { InputLabel, MenuItem, FormControl, Select, IconButton } from "@material-ui/core";

const CustomSelect = (props) => {
    const { customClassList, btn, handleClickLabel, ...other } = props;
    const screenSize = useResizeScreen();
    const classes = useStyles();

    function required() {
        if (props.required) {
            return <span style={{ color: '#ff0000' }}>*</span>;
        }
    }

    function isBtn() {
        let response = (<InputLabel className={`${classes.inputLabelSelectForms} ${props.inputLabelClass}`} shrink htmlFor="bootstrap-input">
            {props.label} {required()}
        </InputLabel>);
        if (btn) {
            response = (<IconButton onClick={handleClickLabel} style={{ padding: '3px' }}>
                <InputLabel className={`${classes.inputLabelSelectForms} ${props.inputLabelClass}`} shrink htmlFor="bootstrap-input">
                    {props.label} {required()}
                </InputLabel>
            </IconButton>);
        }
        return response;
    }

    return (screenSize.screen.sWidth > "900" ?
        <FormControl {...other} sx={{ m: 1, minWidth: "250px" }} style={{ width: "250px" }}>
            {isBtn()}
            <Select
                label=''
                placeholder={props.placeholder}
                className= {`${props.formControlClass}`}
                name={props.name}
                disabled={props.disable}
                value={props.selected}
                onChange={props.onChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{ classes: { paper: customClassList } }}

            >
                {props.list?.map((item, i) => (
                    <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                ))}

            </Select>
        </FormControl> :
        <FormControl {...other} >
            {isBtn()}
            <Select disabled={props.disable} style={{ marginTop: "15px", width: "90% ", marginLeft: "5%" }}
                label=''
                className= {`${props.formControlClass}`}
                name={props.name}
                value={props.selected}
                onChange={props.onChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                classes={{ root: props.customClass }}
                MenuProps={{ classes: { paper: customClassList } }}

            >
                {props.list?.map((item, i) => (
                    <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                ))}

            </Select>
        </FormControl >
    );
};

export default CustomSelect