import { Divider } from "@material-ui/core";
import useStyles from '../../../assets/styles/styles';
import React, { useState, useEffect } from "react";
import FormHeader from "../FormHeader";
import AutomaticMembershipForm from "./AutomaticMembershipForm";
import { useSelector } from "react-redux";
import { handleGoToPersonalData } from "../../../utils/utilsFunctions";
import { useResizeScreen } from "../../../hooks/useResizeScreen";

const AutomaticMembership = ({ handleBack }) => {
    const classes = useStyles();
    const [disabled, setDisabled] = useState(true);
    const [membershipData, setMembershipData] = useState([]);
    const data = useSelector(state => state.personalDataReducer?.fullData);
    const { screen } = useResizeScreen();

    useEffect(() => {
        if (data?.afiliado !== undefined) {
            let aux = data?.afiliado?.debitosAutomaticos;
            setMembershipData(aux);
        }
    }, [data]);

    return <>
        <FormHeader title='Datos de debito automatico' handleGoTo={handleGoToPersonalData} />
        {
            screen.sWidth > 768 &&
            <Divider className={classes.formDivider} />
        }
        <AutomaticMembershipForm membershipData={membershipData} setMembershipData={setMembershipData} disabled={disabled} setDisabled={setDisabled} handleBack={handleBack} />
    </>;
};

export default AutomaticMembership;