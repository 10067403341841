import * as actionTypes from "../consts/actionTypes";

const initialState = {
    errorMsg: ''
};

export default function errorReducer(state = initialState, action) {
    const DEFAULT = { ...state };
    const ACTIONS = {
        [actionTypes.SEND_REQUEST_FAILURE]: responseToReturn(),
        [actionTypes.RESET_SEND_REQUEST_FAILURE]: clearModel()
    };

    function responseToReturn() {
        let res = { ...state };
        if (action.message) {
            res = { ...state, errorMsg: action.message };
        }
        return res;
    }

    function clearModel() {
        return { ...state, errorMsg: [] };
      }

    let receiveAction = DEFAULT;

    if (ACTIONS[action.type] !== undefined) {
        receiveAction = ACTIONS[action.type];
    }

    return receiveAction;
}