import { IconButton, Typography } from '@material-ui/core';
import React from 'react';
import QuickAccess from './QuickAccess';

const QuickAccessBox = (props) => {
    const { hasNotification, i, item, classes, customClass, iconClass, txtIcon, handleClick, ...other } = props;
    return <>
        <IconButton className={`${classes.divItemQuickAccess} ${classes.correctionBlockIcon} ${customClass}`} onClick={handleClick} {...other}>
            <QuickAccess hasNotification={hasNotification} index={i} customClass={iconClass}>
                {item.icon}
            </QuickAccess>
            <Typography className={`${classes.txtObservaciones} ${classes.subtitleTurn} ${classes.txtDarkBlue} ${classes.txtQuickAccess} ${classes.txtEllipsis} ${classes.correctionSentence} ${txtIcon}`} style={{ marginTop: "5px " }}>
                {item.title}
            </Typography>
        </IconButton>
    </>;
};

export default React.memo(QuickAccessBox, (prevState, nextState) => {
    return prevState.item === nextState.item;
});