import React, { useEffect, useState } from "react";
import useStyles from "../../assets/styles/styles";
import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import RoomIcon from '@material-ui/icons/Room';
import MapContainer from "../MapContainer/MapContainer";
import Accordion from "../../controls/Accordion/Accordion";

import { useDispatch, useSelector } from "react-redux";
import { getMedicalCenters, getSpecialties, setModelToSaveAppointment } from '../../redux/actions/appointmentsActions';
import { useCurrentPosition } from "../../hooks/useCurrentPosition";
import { useResizeScreen } from "../../hooks/useResizeScreen";

export default function MedicalCenterDrawer(props) {
  const userCode = parseInt(window.localStorage.getItem("numeroPersona"));
  const classes = useStyles();
  const { screen } = useResizeScreen();
  const [maps, setMaps] = useState(true);
  const dispatch = useDispatch();
  const ubication = useSelector((state) => state.medicalCenterReducer.medicalCenters);
  const { currentPosition } = useCurrentPosition();
  const [switchState, setSwitchState] = useState({});
  const [position, setPosition] = useState({ codigo: userCode, lat: "", long: "" });
  const [medicalCenterMap, setmedicalCenterMap] = useState({ lat: 0, lng: 0 });
  const modelAppointmentReducer = useSelector(state => state.appointmentsReducer?.modelAppointment);
  const [modelAppointment, setModelAppointment] = useState(modelAppointmentReducer);
  const medicalCenter = useSelector(state => state.medicalCenterReducer.medicalCenters.centrosMedicos);
  const centers = medicalCenter?.filter((ele, ind) => ind === medicalCenter?.findIndex(elem => elem.provinciaCodigo === ele.provinciaCodigo && elem.provinciaDescripcion === ele.provinciaDescripcion));

  const specialtiesList = {
    numeroPersona: modelAppointment?.afiliadoCodigo,
    centroMedico: 0,
    tipoAtencion: "WEB",
    parametroLlamado: 0,
  };

  useEffect(() => {
    if (modelAppointment.centroMedico === undefined) {
      initializeSwitch();
      backSwitch();
      setModelAppointment({ ...modelAppointment, nombreCentroMedico: "", centroMedico: "", centroMedicoCodigo: 0, direccion: "" });
    } else {
      initializeUbication();
    }
  }, []);

  useEffect(() => {
    dispatch(setModelToSaveAppointment({ ...modelAppointment }))
  }, [modelAppointment])

  useEffect(() => {
    if (currentPosition.lat !== 0 && currentPosition.lat !== undefined && currentPosition.lng !== 0) {
      setPosition({ ...position, lat: currentPosition.lat.toString(), long: currentPosition.lng.toString() });
    }
  }, [currentPosition]);

  useEffect(() => {
    if (ubication?.length > 0 || ubication?.centroMedicoCercano) {
      setmedicalCenterMap({ ...medicalCenterMap, lat: ubication.centroMedicoCercano.latitud, lng: ubication.centroMedicoCercano.longitud });
    }
    initializeUbication();
  }, [ubication]);

  useEffect(() => {
      dispatch(getMedicalCenters(position));
  }, [position]);


  function showMaps() {
    if (maps) {
      setMaps(false);
    } else {
      setMaps(true);
    }
  }

  const handleSwitch = (item) => (e) => {
    initializeSwitch();
    const { name, value } = e.target;
    setSwitchState((prevState) => ({
      ...prevState,
      [name]: e.target.checked,
    }));
    if (e.target.checked) {
      setModelAppointment({ ...modelAppointment, nombreCentroMedico: item?.centroMedicoNombre, centroMedico: name, centroMedicoCodigo: item.centroMedicoCodigo, direccion: value });
      specialtiesList.centroMedico = item.centroMedicoCodigo;
      dispatch(getSpecialties(specialtiesList));
      setmedicalCenterMap({ ...medicalCenterMap, lat: item?.latitud, lng: item?.longitud });
    }
    else {
      setModelAppointment({ ...modelAppointment, nombreCentroMedico: "", centroMedico: "", direccion: "" });
    }
  };

  function backSwitch() {
    if (modelAppointment?.centroMedico) {
      setSwitchState((prevState) => ({
        ...prevState,
        [modelAppointment.centroMedico]: true,
      }));
    }
  }

  function initializeSwitch() {
    medicalCenter?.map((item) => {
      setSwitchState((prevState) => ({
        ...prevState,
        [item?.centroMedicoNombreCompleto]: false,
      }));
    });
  }

  function initializeUbication() {
    if (modelAppointment?.centroMedico === undefined || modelAppointment?.centroMedico === null || modelAppointment?.centroMedico === "") {
      Object.entries(switchState).forEach(([key, value]) => {
        if (ubication.centroMedicoCercano?.centroMedicoNombreCompleto === key && switchState[ubication.centroMedicoCercano?.centroMedicoNombreCompleto] === false) {
          setSwitchState((prevState) => ({
            ...prevState,
            [ubication.centroMedicoCercano?.centroMedicoNombreCompleto]: true}));
          setModelAppointment({
            ...modelAppointment,
            centroMedico: ubication.centroMedicoCercano?.centroMedicoNombreCompleto,
            nombreCentroMedico: ubication.centroMedicoCercano?.centroMedicoNombre,
            centroMedicoCodigo: ubication.centroMedicoCercano?.centroMedicoCodigo,
            direccion: ubication.centroMedicoCercano?.direccion,
            especialidadCodigo: '',
            especialidadDescripcion: '',
            hourSelected: '',
            medicoCodigo: undefined,
            medicoDescripcion: '',
            selectedAppointment: ''
          });

          specialtiesList.centroMedico = ubication?.centroMedicoCercano?.centroMedicoCodigo;
          dispatch(getSpecialties(specialtiesList));
        }
      });
    }else{
      setSwitchState((prevState) => ({
        ...prevState,
        [modelAppointment?.centroMedico]: true,
      }));
      setModelAppointment({
        ...modelAppointment,
        especialidadCodigo: '',
        especialidadDescripcion: '',
        hourSelected: '',
        medicoCodigo: undefined,
        medicoDescripcion: '',
        selectedAppointment: ''
      });
    }
  }

  const mapResponsive = () => {
    let height = '40vh';
    if (screen.sWidth < 768) {
      height = '30vh';
    }
    return height;
  };

  return (
    <>
      <div className={`${classes.drawerContainerTurn}`}>
        <div className={classes.divBoxFirstTurn} style={{ width: "100%" }}>
          <Typography
            className={classes.textTitleTurn}
            style={{ marginTop: "0px" }}
          >
            {props.titles.map((titles) => titles.title5)}
          </Typography>
          <Typography
            style={{ color: "#54c0e8", fontWeight: "400" }}
          >
            Más cercano
          </Typography>
          <div className={classes.ubicationText}>
            {ubication !== undefined && ubication.centroMedicoCercano !== null && ubication.centroMedicoCercano !== undefined ?
              (<>
                <RoomIcon className={classes.locationIcon} style={{ heigth: "20px", width: "20px" }} />
                {ubication.centroMedicoCercano?.centroMedicoNombre}
                {" | "}
                {ubication.centroMedicoCercano?.direccion}
              </>) : ("")
            }
          </div>
          <MapContainer
            showMaps={showMaps}
            father={"medicalCenter"}
            heightMap={mapResponsive()}
            viewList={false}
            medicalCenterUbication={medicalCenterMap} />
        </div>
        {modelAppointment?.centroMedico === "" ? <span className={classes.spanError}>*Complete el campo</span> : ""}
        <div
          className={classes.accordionMedicalCenter}
        >
          {centers?.map((item, index) => (
            <Accordion
              key={index}
              classes={{ expanded: (screen.sWidth > 1200 && classes.margin0) }}
              title={item.provinciaDescripcion}
              style={{ height: "fit-content" }}
              defaultExpanded={screen.sWidth > 1200}
              details={
                <List
                  component="nav"
                  className={classes.root}
                  aria-label="mailbox folders"
                >
                  <Divider classes={{ root: classes.dividerMedicalCenter }} />
                  {medicalCenter.filter(x => x.provinciaCodigo === item.provinciaCodigo)
                    .map((val, i) => (
                      <ListItem key={i} className={classes.listItemTurn}>
                        <Typography
                          style={{ fontSize: "16px" }}
                        >
                          {val.centroMedicoNombreCompleto}
                        </Typography>
                        <Switch
                          classes={{ root: classes.switchColor }}
                          id={val.centroMedicoNombreCompleto}
                          name={val.centroMedicoNombreCompleto}
                          onChange={handleSwitch(val)}
                          value={val.direccion}
                          checked={!!switchState[val.centroMedicoNombreCompleto]}
                        ></Switch>
                      </ListItem>
                    ))}
                </List>
              }>
            </Accordion>
          ))}
        </div>
      </div>
    </>
  );
}