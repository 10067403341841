import React from 'react';
import { Typography } from "@material-ui/core";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import { MONTHS } from "../../../utils/utilsFunctions";
import useStyles from '../../../assets/styles/styles';

const HeaderCard = ({ day, numberDay, month, year }) => {
    const { screen } = useResizeScreen();
    const classes = useStyles();

    return (
        <>
            {screen.sWidth > 321 ?
                <>
                    <Typography style={{ fontSize: '9px' }} className={`${classes.txtCalendar} ${classes.txtEllipsis}`}>
                        {day}
                    </Typography>
                    <Typography style={{ fontSize: '2rem' }} className={`${classes.txtNumberCalendar} ${classes.txtEllipsis}`}>
                        {numberDay}
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography style={{ paddingRight: '2px' }} className={`${classes.txtCalendar} ${classes.txtEllipsis}`}>
                            {MONTHS[month]}
                        </Typography>
                        <Typography className={`${classes.txtCalendar} ${classes.txtEllipsis}`}>
                            {year}
                        </Typography>
                    </div>
                </>
                :
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography style={{ fontSize: '40px' }} className={`${classes.txtNumberCalendar} ${classes.txtEllipsis}`}>
                            {numberDay}
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '10px' }}>
                        <Typography style={{ fontSize: '15px' }} className={`${classes.txtCalendar} ${classes.txtEllipsis}`}>
                            {day}
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Typography style={{ paddingRight: '2px', fontSize: '12px' }} className={`${classes.txtCalendar} ${classes.txtEllipsis}`}>
                                {MONTHS[month]}
                            </Typography>
                            <Typography style={{ fontSize: '12px' }} className={`${classes.txtCalendar} ${classes.txtEllipsis}`}>
                                {year}
                            </Typography>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default React.memo(HeaderCard);