import {
  Tabs,
  Paper,
  Box,
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ItemTab from "../../components/Tabs/ItemTab";
import { useState, useEffect } from "react";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import useStyles from "../../assets/styles/styles";
import Header from "../GenericHeader/Header";
import Menu from "../Menu/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import CardPhoto from "../CardPhoto/CardPhoto";
import { getHeadquaters } from "../../redux/actions/sedesActions";
import parse from 'html-react-parser';
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../controls/Button/CustomButton";
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import HeadquartersFrequentQuestions from "./HeadquartersFrequentQuestions";
import { useHistory } from "react-router";
import useTrackPageView from '../../utils/useTrackPageView';

const tab = [
  {
    label: "Centros médicos",
  },
  {
    label: "Sucursal",
  },
  {
    label: "Preguntas frecuentes",
  },
];

const Headquarters = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const size = useResizeScreen();
  const [isCenterSelected, setIsCenter] = useState(true);
  const dispatch = useDispatch();
  const sedesSelector = useSelector(state => state.sedesReducer?.sedes);
  const [centers, setCenters] = useState([]);
  const [sucursal, setSucursal] = useState([]);
  const zonas = sucursal.map(x => x.zone);
  const distinctZonas = [...new Set(zonas)];
  const [ isFrequentQuestion, setIsFrequentQuestion ] = useState(false);
  const [ isBranch, setIsBranch ] = useState(false);
  const history = useHistory();
  const trackPageView = useTrackPageView("Nuestras Sedes");

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
    if (newValue === 0) {
      setIsCenter(true);
      setIsFrequentQuestion(false);
      setIsBranch(false);
    }
    if (newValue === 1) {
      setIsCenter(false);
      setIsFrequentQuestion(false);
      setIsBranch(true);
    }
    if (newValue === 2) {
      setIsCenter(false);
      setIsBranch(false);
      setIsFrequentQuestion(true);
    }
  };

  const centered = () => {
    return size.screen.sWidth <= 425 ? false : true;
  };

  const variant = () => {
    return size.screen.sWidth <= 425 ? "scrollable" : "standard";
  };

  const addCardPhoto = () => {
    return (
      <CardPhoto
        listCard={centers}
        countCard={centers.length}
        hasButton={false}
        alternateColor={true}
        hasDetail={true}
        isNotPlan={true}
      ></CardPhoto>
    );
  };

  const addAccordion = (item, i) => {
    return (
      <Accordion key={i}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item xs={1}>
            <LocationCityIcon className={classes.LocationCity} />
          </Grid>
          <Typography className={classes.txtTitlebranchOffice} style={{marginLeft: "15px"}}>
            {distinctZonas[i]}
          </Typography>
        </AccordionSummary>

        {sucursal?.filter(x => x.zone === distinctZonas[i]).map((items, x) => (
          <AccordionDetails key={x}>
            <div>
              <Typography className={classes.headingBPhones}>
                {" "}
                {items.name}
              </Typography>
            </div>
            <div>
              <Typography
                style={{ whiteSpace: "initial" }}
                className={`${classes.txtDescription} ${classes.txtEllipsis}`}
              >
                {" "}
                {items.address}
              </Typography>
            </div>
            <div>
              <a href={`tel:${items.numbers}`} noopener noreferrer
                style={{ whiteSpace: "initial" }}
                className={`${classes.txtDescription} ${classes.txtEllipsis}`}
              >
                {"Tel: " + items.numbers}
              </a>
            </div>
            <div>
              <Typography
                style={{ whiteSpace: "initial" }}
                className={`${classes.txtDescription} ${classes.txtEllipsis}`}
              >
                {" "}
                {parse(items.description)}
              </Typography>
            </div>
            <div>
              <a className={`${classes.txtDescription} ${classes.txtEllipsis}`} noopener noreferrer href={`mailto:${items.email}`}>
                {items.email}
              </a>
            </div>
          </AccordionDetails>
        ))}
      </Accordion>
    );
  };

  function addScreenmin() {
    var componentToShow;
    if(isCenterSelected) {
      componentToShow = (
        addCardPhoto()
      );
    }
    if(isBranch) {
      componentToShow = (
        distinctZonas?.map((item, i) => addAccordion(item, i))
      );
    }
    if(isFrequentQuestion) {
      componentToShow = (
        <HeadquartersFrequentQuestions />
      );
    }
    if(!isCenterSelected && !isBranch && !isFrequentQuestion) {
      componentToShow = (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }} >
            <div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}>
              <CircularProgress />
            </div>
          </div>
      );
    }
    return componentToShow;
  }

  function strip(html) {
    var tmp = document.implementation.createHTMLDocument("New").body;
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  const goToFrequentQuestions = () => {
    history.push("/headquartersFAQ");
  };

  useEffect(() => {
    trackPageView();
    dispatch(getHeadquaters());
  }, []);

  useEffect(() => {
    let aux = sedesSelector?.filter(x => x.category == "Centro Médico").map((item) => {
      return {
        title: item.name,
        location: item.address,
        phone: item.numbers,
        hours: strip(item.description),
        mail: item.email,
        latitud: "",
        longitud: "",
        img: item.image64,
        urlMapa: item.urlMapa
      };
    });
    setCenters(aux);
    let auxSucursal = sedesSelector?.filter(x => x.category == "Sucursal").map((item) => {
      return item;
    });
    setSucursal(auxSucursal);
  }, [sedesSelector]);

  return (
    <>
      <Menu className={classes.noneMenu} />
      <Header title="Nuestras Sedes" />
      {size.screen.sWidth <= "600" &&
        (
          <Paper className={classes.newsTabs}>
            <Tabs
              indicatorColor="primary"
              value={value}
              onChange={handleChange}
              centered={centered()}
              className={classes.navTabs}
              variant={variant()}
            >
              {tab.map((item, i) => (
                <ItemTab
                  className={`${value === i ? classes.selected : classes.colorTab
                    } ${size.screen.sWidth <= 425 ? classes.tabMobile : ""
                    }`}
                  key={i}
                  label={item.label}
                  style={{
                    color: "#0075C9",
                    fontWeight: 700,
                    fontFamily: "sans-serif",
                    minWidth: "9.5% !important",
                    borderRadius: "40px",
                    marginBottom: "10px",
                    textTransform: "none",
                    marginLeft: "1px",
                    marginRight: "1px",
                  }}
                />
              ))}
            </Tabs>
          </Paper>
        )
      }
      <Container
        className={`${size.screen.sWidth <= "600"
          ? classes.paddingCardMobile
          : classes.paddingCard
          } ${classes.cardStyle}`}
      >
        <Box justify="space-between" alignItems="stretch">
          {size.screen.sWidth <= "600" ? (
            addScreenmin()
          ) : (
            <>
              {" "}
              {centers?.length > 0 ?
                addCardPhoto()
                :
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }} >
                  <div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}>
                    <CircularProgress />
                  </div>
                </div>
              }
              {
                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: "30px" }}>
                  <Typography
                    variant="h5"
                    style={{
                      // paddingBottom: "23px",
                      // position: "absolute",
                      color: "#0075c9",
                      marginLeft: "30px",
                    }}
                    className={classes.mainTitle}
                  >
                    {"Sucursales de Atención & Servicios"}
                  </Typography>
                  <CustomButton title={"Preguntas frecuentes"} onClick={goToFrequentQuestions}
                    style={{
                        height: "40px",
                        fontSize: "12px",
                        fontWeight: "600",
                    }}
                    icon={<QuestionAnswerOutlinedIcon style={{ marginRight: "5px" }} />}
                    customClass={`${classes.btnFilterAppointment}`}
                />
                </div>
              }
              {
                <div
                  style={{
                    width: "100%",
                    marginTop: "90px",
                    display: "flex",
                    marginLeft: "30px",
                  }}
                >
                  {sucursal?.length > 0 ?
                    (distinctZonas?.map((item, i) => (
                      <div key={i} style={{ width: "20%", marginRight: "30px" }}>
                        {addAccordion(item, i)}
                      </div>
                    )))
                    :
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", marginLeft: "0px !important" }} >
                      <div className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}>
                        <CircularProgress />
                      </div>
                    </div>
                  }
                  {" "}
                </div>
              }{" "}
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default Headquarters;
