export const getSpecialtyAndDoctorCodes = (specialties, model) => {
    const { agenda } = specialties;
    const specialtyCode = model?.modelAppointment?.especialidadCodigo;
    const doctorCode = model?.modelAppointment?.medicoCodigo;
    return { agenda, specialtyCode, doctorCode };
};

export const checkSpecialistVisualizacion = (agenda, specialtyCode, doctorCode, setVisualization) => {
    const specialtiesList = agenda?.filter(x => x?.especialidadCodigo === specialtyCode);
    const doctorList = specialtiesList?.filter(x => x?.medicoCodigo === doctorCode);

    const doctorListToFilter = (search) => {
        return doctorList?.length > 0 && doctorList?.every(x => x?.visualizacion?.trim() === `${search}`)
    }
    
    const isSpontaneous = doctorListToFilter("DEMANDA ESPONTANEA");
    const isPhoneAppointment = doctorListToFilter("TURNO TELEFONICO");
    const isShiftScheduleSuspended = doctorListToFilter("AGENDA SUSPENDIDA");

    (isSpontaneous) && setVisualization("DEMANDA ESPONTANEA");
    (isPhoneAppointment) && setVisualization("TURNO TELEFONICO");
    (isShiftScheduleSuspended) && setVisualization("AGENDA SUSPENDIDA");

    const suspended = isShiftScheduleSuspended || isPhoneAppointment || isSpontaneous;

    return suspended;
};