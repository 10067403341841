import useStyles from "../../../assets/styles/styles";
import { Typography } from "@material-ui/core";

import NotFound from "../../HomeLogged/NotFound";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { cleanPerfilCompleto, getPerfilCompleto } from "../../../redux/actions/medicalRecords";
import ModalProfile from "../../DialogProfile/Modal";
import MedicalCard from "../Card/MedicalCard";
import { formatPhoneNumber } from "../../../utils/utilsFunctions";

const MedicalRecordContainer = ({ showMaps, maps }) => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const dispatch = useDispatch();
    const {loading, cartilla, completeProfile} = useSelector((state) => state.cartillaReducer);
    const [profileSelected, setProfileSelected] = useState(undefined);
    const [open, setOpen] = useState(false);

    const handleClick = (item, i) => (e) => {
        dispatch(getPerfilCompleto({ PrestadorNumero: item.prestadorNumero }));
        setOpen(true);
    };

    function cardStyles() {
        let response = `${classes.newsRoot} ${classes.addListCard}`;
        if (screen.sWidth <= '800') {
            response = `${classes.imageListCard} ${classes.addListCardMobile}`;
        }
        return response;
    }

    function validFilter(isOpen) {
        return isOpen ? "blur(2px)" : " blur(0px)";
    }

    useEffect(() => {
        setProfileSelected(completeProfile);
    }, [completeProfile]);

    useEffect(() => {
        !open && setProfileSelected(undefined);
    },[open]);

    useEffect(() => {
        return (() => {
            dispatch(cleanPerfilCompleto());
        });
    }, []);

    return (
        <>
            {!maps &&
                ((!loading && cartilla?.cartilla?.length > 0) ?
                    <section>
                        <div
                            className={`${cardStyles()} ${classes.imageListChildren}`}
                            style={{
                                filter: validFilter(open),
                                gap: '0px',
                                display: 'grid',
                                justifyItems: "center"
                            }}

                        >
                            {cartilla?.cartilla?.map((item, i) => (<>
                                <MedicalCard item={item} i={i} handleClick={handleClick} showMaps={showMaps} />
                            </>
                            ))}
                        </div>
                    </section>
                    :
                    <NotFound wsItem={cartilla?.cartilla} fullItem={cartilla} loading={loading} />
                )
            }
            {open && (
                profileSelected ?
                    (<ModalProfile
                        title={
                            <>
                                <Typography className={classes.titleDialogTitle}>
                                    {completeProfile?.prestador?.prestadorRazonSocial}
                                </Typography>
                                <Typography className={classes.subTitleDialogTitle}>
                                    {completeProfile?.prestador?.especialidad[0]?.especialidadDescripcion}
                                </Typography>
                            </>
                        }
                        description={
                            <>
                                <Typography paragraph className={classes.subParagraphDialog}>
                                    DATOS DEL PRESTADOR {completeProfile?.prestador?.prestadorRazonSocial}
                                </Typography>
                                <Typography className={classes.titleDialogContent}>
                                    TELEFONO:
                                </Typography>
                                { completeProfile?.prestador?.telefono?.length > 0 ?
                                    completeProfile?.prestador?.telefono?.map((phone, i) => (
                                        <Typography
                                            key={i}
                                            paragraph
                                            className={classes.paragraphDialog}
                                        >
                                            <a
                                                className={classes.linkDialog}
                                                href={`tel:${phone?.telefonoNumero}`}
                                            >
                                                {formatPhoneNumber(phone?.telefonoNumero, true)}
                                            </a>
                                            <br />
                                        </Typography>
                                    ))
                                    :
                                    <Typography className={classes.paragraphDialog} style={{ marginBottom: '10px' }}>
                                        NO HAY TELEFONO
                                    </Typography>
                                }
                                <Typography className={classes.titleDialogContent}>
                                    DIRECCION:
                                </Typography>
                                {completeProfile?.prestador?.direccion.map((locationValue, key) => (
                                    <Typography
                                        key={key}
                                        paragraph
                                        className={classes.paragraphDialog}
                                    >
                                        {locationValue?.domicilio} | {locationValue?.localidad} | {locationValue?.barrio}
                                        <br />
                                    </Typography>
                                ))}
                                <Typography className={classes.titleDialogContent}>
                                    CORREO:
                                </Typography>
                                {
                                    !completeProfile?.prestador?.mail.length > 0 &&
                                    (
                                        <Typography
                                        paragraph
                                        className={classes.paragraphDialog}>
                                            NO HAY CORREO
                                        <br />
                                    </Typography>
                                    )
                                }
                                {completeProfile?.prestador?.mail.map((mailValue, index) => (
                                    <Typography
                                        key={index}
                                        paragraph
                                        className={classes.paragraphDialog}
                                    >
                                        <a className={classes.linkDialog} href={`mailto:${mailValue?.mailDireccion}`}>
                                            {mailValue?.mailDireccion}
                                        </a>
                                        <br />
                                    </Typography>
                                ))}
                            </>
                        }
                        observation={
                            <>
                                <Typography className={classes.titleDialogContent}>
                                    OBSERVACIONES:
                                </Typography>
                                {
                                    !completeProfile?.prestador?.datoAdicional.length > 0 &&
                                    (
                                        <Typography
                                        paragraph
                                        className={classes.paragraphDialog}>
                                            NO HAY OBSERVACIONES
                                        <br />
                                    </Typography>
                                    )
                                }
                                {completeProfile?.prestador?.datoAdicional.map((observations, y) => (
                                    <>
                                        {observations?.titulo === "OBSERVACIONES" && (
                                            <Typography
                                                key={y}
                                                paragraph
                                                className={classes.paragraphDialog}
                                            >
                                                {observations?.detalle}
                                                <br />
                                            </Typography>
                                        )}
                                    </>
                                ))}
                            </>
                        }
                        specialties={
                            <>
                                <Typography className={classes.titleDialogContent}>
                                    ESPECIALIDADES:
                                </Typography>
                                {completeProfile?.prestador?.especialidad.map((especialidadValue, z) => (
                                    <>
                                        <Typography key={z} className={classes.paragraphDialog}>
                                            {especialidadValue?.especialidadDescripcion}
                                        </Typography>
                                    </>
                                ))}
                            </>
                        }
                        open={open}
                        onClose={setOpen}
                    />)
                :
                <NotFound wsItem={completeProfile?.prestador} fullItem={completeProfile} />
            )
            }
        </>
    );
};

export default MedicalRecordContainer;