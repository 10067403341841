import logo from '../../assets/images/whiteLogo.png';
import useStyles from '../../assets/styles/styles';

const LoadingPage = () => {
    const classes = useStyles();
    return <>
        <div className={classes.divLoading}>
            <img style={{ width: '250px' }} alt="logo" src={logo} />
        </div>
    </>;
};

export default LoadingPage;