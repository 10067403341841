import * as React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import useStyles from '../../assets/styles/styles';
import {BootstrapInput} from '../../assets/styles/bootstrapInput';


const CustomizedInputs = (props) => {
  const { ...other } = props;
  const classes = useStyles();

  function required() {
    if (props.customRequired) {
      return <span style={{ color: '#ff0000' }}>*</span>;
    }
  }

  return (
    <FormControl className={`${classes.formControl} ${props.formControlClass}`} variant="standard">
      <InputLabel className={`${classes.inputLabelForms} ${props.inputLabelClass}`} shrink htmlFor="bootstrap-input">
        {props.label}{required()}
      </InputLabel>
      <BootstrapInput {...other} type={props.type} disabled={props.disabled} className={`${classes.customInput} ${props.customClass}`} id="bootstrap-input" />
    </FormControl>
  );
};


export default CustomizedInputs;