import React from 'react';
import { CircularProgress } from '@material-ui/core';

const Loading = ({isLoading, children}) => {
  return (
    <>
        {
            isLoading ?
            <div style={{ display: 'flex', justifyContent: 'center', flex: '0 0 100%', paddingTop: '10px' }}>
                <CircularProgress />
            </div>
            :
            children
        }
    </>
  )
}

export default Loading