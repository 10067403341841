import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

/**agregar el llamao a las funciones que se comunican con la api */
function* asyncGetAfilliatedAppointments({ payload }) {
  try {

    if(payload !== undefined){
      var response = yield call(API.getAfilliatedAppointments, payload);
    }
    
    if (response)
      yield put({
        type: actionTypes.SUCCESS_GET_AFILLIATED_APPOINTMENTS,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_AFILLIATED_APPOINTMENTS,
      message: error
    });
  }
}

function* asyncGetAfilliatedAppointmentsRecord({ payload }) {
  try {

    if(payload !== undefined){
      var response = yield call(API.getAfilliatedAppointmentsRecord, payload);
    }

    if (response)
      yield put({
        type: actionTypes.SUCCESS_GET_AFILLIATED_APPOINTMENTS_RECORD,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_AFILLIATED_APPOINTMENTS_RECORD,
      message: error
    });
  }
}

function* asyncGetAfilliatedAppointmentsNext({ payload }) {
  try {
    if (payload !== undefined) {
      var response = yield call(API.getAfilliatedAppointmentsNextAPI, payload);
    }
    if (response)
      yield put({
        type: actionTypes.SUCCESS_GET_AFILLIATED_APPOINTMENTS_NEXT,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_AFILLIATED_APPOINTMENTS_NEXT,
      message: error
    });
  }
}


function* asyncGetAppointmentFavorite({ payload }) {
  try {
    const response = yield call(API.getAppointmentFavorite, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCES_GET_APPOINTMENTS_FAVORITE,
        response
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_APPOINTMENTS_FAVORITE,
      message: error
    });
  }
}

function* asyncGetDataGroupFamily({ payload }) {
  try {
    var response = yield call(API.getDataGroupFamily, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCES_GET_DATA_GROUP_FAMILY,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_DATA_GROUP_FAMILY,
      message: error.message,
    });
  }
}



function* asyncGetDataFilterAppointment({ payload }) {
  try {
    const response = yield call(API.getDataFilterAppointment, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCES_GET_DATA_FILTER_APPOINTMENT,
        response
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_DATA_FILTER_APPOINTMENT,
      message: error
    });
  }
}


function* asyncAppointmentCanceled({ payload }) {
  try {
    const response = yield call(API.AppointmentCanceled, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCES_GET_APPOINTMENTS_CANCELED,
        response
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_APPOINTMENTS_CANCELED,
      message: error
    });
  }
}

function* asyncABAppointmentFavorite({ payload }) {
  try {
    const response = yield call(API.ABAppointmentFavorite, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCES_GET_APPOINTMENTS_AB,
        response
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_APPOINTMENTS_AB,
      message: error
    });
  }
}

function* asyncGetAnswerQuestion() {
  try {
    var response = yield call(API.getAnswerQuestion);
    if (response)
      yield put({
        type: actionTypes.SUCCES_ANSWER_QUESTION,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_ANSWER_QUESTION,
      message: error.message,
    });
  }
}

function* asyncGetSpecialties({ payload }) {
  try {
    var response = yield call(API.getSpecialties, payload);
    if (response){
      yield put({ type: actionTypes.SUCCESS_GET_SPECIALTY_LIST, response, message: '' });
    }
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_SPECIALTY_LIST,
      message: error.message,
    });
  }
}

function* asyncGetMedicalCenters({ payload }) {
  try {
    var response = yield call(API.getMedicalCenters, payload);
    if (response){
      yield put({ type: actionTypes.SUCCESS_GET_MEDICAL_CENTERS, response, message: '' });
    }
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_MEDICAL_CENTERS,
      message: error.message,
    });
  }
}

function* asyncGetNextAvailableAppointments({ payload }) {
  try {
    var response = yield call(API.getNextAvailableAppointments, payload);
    if (response){
      yield put({ type: actionTypes.SUCCESS_GET_NEXT_AVAILABLE_APPOINTMENTS, response, message: ''});
    }
  } catch (error) {
    yield put({ type: actionTypes.FAILURE_GET_NEXT_AVAILABLE_APPOINTMENTS, message: error.message });
  }
}

function* asyncGetNextAvailableAppointmentsDoctor({ payload }) {
  try {
    var response = yield call(API.getNextAvailableAppointmentsDoctor, payload);
    if (response) {
      yield put({ type: actionTypes.SUCCESS_GET_NEXT_AVAILABLE_APPOINTMENTS_DOCTOR, response, message: ''});
    }
  } catch (error) {
    yield put({ type: actionTypes.FAILURE_GET_NEXT_AVAILABLE_APPOINTMENTS_DOCTOR, message: error.message });
  }
}

function* asyncSaveAppointment({ payload }) {
  try {
    // var response = yield call(API.saveAppointment, payload);
    if (response)
      yield put({ type: actionTypes.SEND_REQUEST_SUCCESS, message: response });
  } catch (error) {
    yield put({ type: actionTypes.SEND_REQUEST_FAILURE, message: error.message });
  }
}

function* asyncAppointmentConfirmed({ payload }) {
  try {
    const response = yield call(API.AppointmentConfirm, payload);
    if (response) {
      yield put({ type: actionTypes.SUCCESS_GET_APPOINTMENTS_CONFIRMED, response, message: ""});
    }
  } catch (error) {
    yield put({ type: actionTypes.FAILURE_GET_APPOINTMENTS_CONFIRMED, message: error });
  }
}

function* asyncGetFullGroupFamily({ payload }) {
  try {
    var response = yield call(API.getDataFilterAppointment, payload);
    if (response) {
      yield put({ type: actionTypes.SUCCESS_GET_FULL_GROUP_FAMILY_APPOINTMENT, response, message: '' });
    }
  } catch (error) {
    yield put({ type: actionTypes.FAILURE_GET_FULL_GROUP_FAMILY_APPOINTMENT, message: error.message });
  }
}

function* asyncGetSpecialtiesList({ payload }) {
  try {
    const response = yield call(API.GetSpecialtiesList, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCESS_GET_SPECIALTIES_LIST,
        response
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_SPECIALTIES_LIST,
      message: error
    });
  }
}

function* asyncGetDoctorsList({ payload }) {
  try {
    const response = yield call(API.GetDoctorsList, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCESS_GET_DOCTORS_LIST,
        response
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_DOCTORS_LIST,
      message: error
    });
  }
}

function* asyncGetFreeDoctorAppointmentsList({ payload }) {
  try {
    const response = yield call(API.GetFreeDoctorAppointmentsList, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCESS_GET_FREE_DOCTOR_APPOINTMENTS_LIST,
        response
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_FREE_DOCTOR_APPOINTMENTS_LIST,
      message: error
    });
  }
}

// Watchers
export default function* appointmentsSaga() {
  yield takeLatest(
    actionTypes.GET_AFILLIATED_APPOINTMENTS,
    asyncGetAfilliatedAppointments
  );
  yield takeLatest(
    actionTypes.GET_AFILLIATED_APPOINTMENTS_RECORD,
    asyncGetAfilliatedAppointmentsRecord
  );
  yield takeLatest(
    actionTypes.GET_AFILLIATED_APPOINTMENTS_NEXT,
    asyncGetAfilliatedAppointmentsNext
  );
  yield takeLatest(
    actionTypes.GET_APPOINTMENTS_FAVORITE,
    asyncGetAppointmentFavorite
  );

  yield takeLatest(actionTypes.GET_DATA_GROUP_FAMILY, asyncGetDataGroupFamily);

  yield takeLatest(actionTypes.GET_DATA_FILTER_APPOINTMENT, asyncGetDataFilterAppointment);

  yield takeLatest(
    actionTypes.GET_APPOINTMENTS_CANCELED,
    asyncAppointmentCanceled
  );

  yield takeLatest(
    actionTypes.GET_APPOINTMENTS_AB,
    asyncABAppointmentFavorite
  );

  yield takeLatest(actionTypes.GET_ANSWER_QUESTION, asyncGetAnswerQuestion);

  yield takeLatest(actionTypes.GET_SPECIALTY_LIST, asyncGetSpecialties);
  yield takeLatest(actionTypes.GET_MEDICAL_CENTERS, asyncGetMedicalCenters);
  yield takeLatest(actionTypes.GET_NEXT_AVAILABLE_APPOINTMENTS, asyncGetNextAvailableAppointments);
  yield takeLatest(actionTypes.SAVE_APPOINTMENT, asyncSaveAppointment);
  yield takeLatest(actionTypes.GET_NEXT_AVAILABLE_APPOINTMENTS_DOCTOR, asyncGetNextAvailableAppointmentsDoctor);
  yield takeLatest(actionTypes.GET_APPOINTMENTS_CONFIRMED, asyncAppointmentConfirmed);
  yield takeLatest(actionTypes.GET_FULL_GROUP_FAMILY_APPOINTMENT, asyncGetFullGroupFamily);
  yield takeLatest(actionTypes.GET_SPECIALTIES_LIST, asyncGetSpecialtiesList);
  yield takeLatest(actionTypes.GET_DOCTORS_LIST, asyncGetDoctorsList);
  yield takeLatest(actionTypes.GET_FREE_DOCTOR_APPOINTMENTS_LIST, asyncGetFreeDoctorAppointmentsList);
}
