import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetCards() {
    try {
        let response = yield call(API.getCardsAPI);
        if (response) {
            yield put({ type: actionTypes.SUCCESS_GET_CARDS, response, message: '' });
        }
    } catch (error) {
        yield put({ type: actionTypes.FAILURE_GET_CARDS, message: error.message });
    }
}

export default function* cardSaga() {
    yield takeLatest(actionTypes.GET_CARDS, asyncGetCards);
}
