import * as actionTypes from "../consts/actionTypes";

export function getPhones() {
    return {
        type: actionTypes.GET_PHONES,
        payload: ''
    };
}

export function getShiftPhone() {
    
    return {
        type: actionTypes.GET_SHIFT_PHONES,
        payload: ''
    };
}