import Menu from "../../components/Menu/Menu";
import useStyles from "../../assets/styles/styles";
import { Grid, Typography, Divider, IconButton, CircularProgress } from "@material-ui/core";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import CustomButton from "../../controls/Button/CustomButton";
import { useEffect, useLayoutEffect, useState } from "react";
import MedicalFilters from "../../components/MedicalFilters/MedicalFilters";
import MapContainer from "../../components/MapContainer/MapContainer";
import { useDispatch, useSelector } from "react-redux";
import { cleanModelMedicalRecords, getFiltersValues, getPerfilCompleto } from "../../redux/actions/medicalRecords";
import { getFullData } from "../../redux/actions/personalDataActions";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SelectTypeOfComponent from "../../components/PersonalData/SelectTypeOfComponent";
import { useHistory, useLocation } from "react-router";
import { getLimitPlan } from "../../redux/actions/limitPlansAction";
import { verifyPermission, notAccessToThisModule } from "../../utils/utilsFunctions";
import useTrackPageView from "../../utils/useTrackPageView";

const param = {
  parametroLlamado: ''
};

const MedicalRecord = () => {
  const classes = useStyles();
  const { screen } = useResizeScreen();
  const [notFound, setNotFound] = useState(false);
  const dispatch = useDispatch();
  const medicalRecord = useSelector((state) => state.cartillaReducer.cartilla);
  const completeProfile = useSelector((state) => state.cartillaReducer.completeProfile);
  const [profileSelected, setProfileSelected] = useState(completeProfile);
  const [maps, setMaps] = useState(false);
  const history = useHistory();
  const { search } = useLocation();
  const match = search.match(/type=(.*)/);
  const trackPageView = useTrackPageView("Cartilla");
  const typeURL = match?.[1];
  const [type, setActiveType] = useState(-1);
  const affiliatePlan = useSelector((state) => state.personalDataReducer?.fullData?.afiliado?.planDescripcion);
  const [ hasPermission, setHasPermission ] = useState(false);
  const loadingPermission = useSelector((state) => state.limitPlanReducer.requestInProgress);
  const limitPlanReducer = useSelector((state) => state.limitPlanReducer.allLimitPlanData);

  const showMaps = (item, i) => (e) => {
    setProfileFunction(item, setProfileSelected);
    if (maps) {
      setMaps(false);
    } else {
      setMaps(true);
    }
  };

  useEffect(() => {
    dispatch(getLimitPlan());
    trackPageView();
  }, []);

  useEffect(() => {
    if (profileSelected !== undefined)
      dispatch(getPerfilCompleto(profileSelected));
  }, [profileSelected]);

  useLayoutEffect(() => {
    verifyPermission(limitPlanReducer, affiliatePlan, "Cartilla", setHasPermission);
  }, [limitPlanReducer, affiliatePlan, hasPermission]);

  useEffect(() => {
    dispatch(getFiltersValues(param));
    let numberPerson = window.localStorage.getItem("numeroPersona");
    if (numberPerson) {
      dispatch(getFullData({ numeroPersona: numberPerson }));
    }

    return (() => {
      window.localStorage.removeItem("modelCartilla");
      window.localStorage.removeItem("selectedValues");
      window.localStorage.removeItem("values");
    });
  }, []);


  useEffect(() => {
    window.onpopstate = (e) => {
      dispatch(cleanModelMedicalRecords());
      if (screen.sWidth <= 768 && type !== -1)
        window.location.href = '/MedicalRecords';
      else
        history.push('/');
    };
  });

  useEffect(() => {
    if (typeURL !== undefined) {
      setActiveType(1);
    }
  }, [typeURL]);

  function permissionToEnter(condition) {
    var resp;
    if(condition) {
        resp = (MobileFilters({ medicalRecord, classes, showMaps, screen, maps, typeURL, setNotFound, history }));
    } else {
        resp = (notAccessToThisModule());
    }
    return resp;
  }

  return (
    <>
      <Menu />
      {!maps &&
          (
            loadingPermission  && affiliatePlan?
              <div style={{ display: 'flex', justifyContent: 'center', flex: '0 0 100%', paddingTop: '10px' }}>
                <CircularProgress />
              </div>
            :
              (
                permissionToEnter(hasPermission)
              )
          )
        }

      <SelectTypeOfComponent showMaps={showMaps} maps={maps} />
      {medicalRecord?.cartilla && medicalRecord?.cartilla?.length > 0 &&
        (
          maps && (
            <>
              <section className={classes.sectionHeaderCartilla}>
                <IconButton onClick={() => { setMaps(prevState => !prevState); }}>
                  <ArrowBackIcon className={classes.iconFilter} />
                </IconButton>
                <Typography className={classes.titleTurn} style={{ fontSize: '1.3rem', fontWeight: '500', marginTop: '0px' }}>Cartilla </Typography>
                <div className={classes.divBtnHeaderCartilla}>
                  <CustomButton
                    typeButton="header"
                    type="submit"
                    title="VER LISTADO"
                    customClass={`${classes.sendButton} ${classes.lightLinearGradient}`}
                    style={{ boxShadow: 'none' }}
                    onClick={() => { setMaps(prevState => !prevState); }}
                  />
                </div>
              </section>
              <Divider className={`${classes.formDivider} ${classes.boxDivider}`} style={{ marginBottom: '20px' }} />
              <MapContainer showMaps={showMaps} viewList={true} profileSelected={profileSelected} elevation={0.007}/>
            </>
          )
        )
      }
    </>
  );
};

export default MedicalRecord;

function titleButton(isMaps) {
  return isMaps ? "ver listado" : "ver mapa";
}

function MobileFilters({ medicalRecord, classes, showMaps, screen, isMap, typeURL, setNotFound, history }) {
  return (
    <>
      {screen.sWidth < 768 &&
        setHeaderInMobile({ history, classes, typeURL })
      }
      {((typeURL === undefined && screen.sWidth < 768) || (screen.sWidth > 768)) && <MedicalFilters isMobile={false} setNotFound={setNotFound} />}
      {medicalRecord.cartilla && !isMap &&
        (
          <Grid
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
            item
          >
            {(medicalRecord.cartilla?.length > 0 && typeURL !== undefined) &&
              <CustomButton
                title={titleButton(isMap)}
                onClick={showMaps(null, 0)}
                customClass={`${classes.sendButton} ${classes.lightLinearGradient}`}
              />
            }
          </Grid>
        )
      }
    </>
  );
}

function setHeaderInMobile({ history, classes, typeURL }) {
  let response = (
    <section style={{ padding: '4%' }}>
      <Typography className={classes.txtTitleAppointment} style={{ textAlign: 'center' }}>Cartilla</Typography>
      <Divider className={`${classes.formDivider} ${classes.boxDivider}`} />
    </section>);
  if (typeURL !== undefined) {
    response = (
      <section style={{ padding: '4%', display: 'flex', flex: '0 0 100%', flexWrap: 'wrap' }}>
        <IconButton onClick={returnHomeAppointmentHome(history)}>
          <ArrowBackIcon className={classes.iconFilter} />
        </IconButton>
        <Typography className={classes.txtTitleAppointment} style={{ textAlign: 'left', display: 'flex', alignItems: 'center', marginTop: '0px' }}>Cartilla</Typography>
        <Divider className={`${classes.formDivider} ${classes.boxDivider}`} />
      </section>);
  }

  return response;
}

const returnHomeAppointmentHome = (history) => (e) => {
  history.push('/MedicalRecords');
};

function setProfileFunction(item, setProfileSelected) {
  item !== null ? setProfileSelected({ PrestadorNumero: item.prestadorNumero }) : setProfileSelected({});
}
