import { CircularProgress, Typography } from "@material-ui/core";
import useStyles from "../../assets/styles/styles";

const DoctorDetails = ({ doctor, schedule }) => {
  const classes = useStyles();
  const bottomMargin = { marginBottom: "5px" };

  const showObservations = () => {
    return doctor?.observaciones !== "" ? doctor?.observaciones : "No hay observaciones";
  }

  return (
    <>
      {doctor? (
        <>
          {schedule && (
            <Typography
              className={` ${classes.txtLinksInCard} ${classes.fontBold} ${classes.txtObservaciones}`}
              style={bottomMargin}
            >
              Próximo turno más cercano:{" "}
              {schedule?.turnoFechHora?.split("T")[0]} |{" "}
              {schedule?.turnoFechHora?.split("T")[1]} hs.
            </Typography>
          )}

          <Typography
            className={` ${classes.txtObservaciones}`}
            style={bottomMargin}
          >
            <span className={`${classes.txtBold500} ${classes.grayTxt}`}>
              Horario de atención:{" "}
            </span>
            <span className={classes.grayTxt}>{doctor?.horarios}</span>
          </Typography>
          <Typography
            className={`${classes.txtObservaciones}`}
            style={bottomMargin}
          >
            <span className={`${classes.txtBold500} ${classes.grayTxt}`}>
              {`Edades: `}
            </span>
            <span className={classes.grayTxt}>
              de {doctor?.edadDesde} a {doctor?.edadHasta} años{" "}
              {doctor?.atiendeDiscapacitados === "S" && <>(atiende capacidades especiales)</>}
            </span>
          </Typography>
          <Typography
            className={`${classes.txtBold500}  ${classes.txtObservaciones} ${classes.grayTxt}`}
            style={bottomMargin}
          >
          {`Observaciones: `} {showObservations()}  {doctor?.preparacion}
          </Typography>
        </>
      ) : (
        <div
          className={`${classes.boxPointsDrawer} ${classes.centeredBox} ${classes.circularProgress}`}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default DoctorDetails;
