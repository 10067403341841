import { Box, Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import useStyles from "../../assets/styles/styles";
import { useEffect, useState } from "react";
import Iframe from "react-iframe";

const PdfReader = ({ pdf, customClass, isBlob }) => {
  const classes = useStyles();
  const sizeScreen = useResizeScreen();
  const location = window.location.host;
  const [filePath, setFilePath] = useState(`https://${location}/${pdf}`);
  const [pdfFile, setPdfFile] = useState(pdf);

  useEffect(() => {
    if (isBlob) {
      setFilePath(pdf);
    }
  }, []);

  return (
    <Box display="flex" height="100vh">
      {sizeScreen.screen.sWidth <= 1024 ? (
        <div style={{ width: "100%", marginTop: "50px" }} className={customClass}>
          <a className={classes.anchorDownload} href={pdfFile} download={filePath} target="_blank" rel='noopener noreferer'>
            <Button
              className={`${classes.customButton} ${classes.btnDownload} ${classes.lightLinearGradient}`}
            >
              <GetAppIcon />
            </Button>
          </a>
          <object
            data={pdfFile}
            type="application/pdf"
            width="100%"
            style={{ height: "100%", paddingTop: "58px", overflowScrolling: "touch", overflowY: "scroll" }}
          >
            <embed src={pdfFile} width="100%" height="100%" scrollbar="1" />
          </object>
        </div>
      ) : (
        <object
          data={pdfFile}
          type="application/pdf"
          width="100%"
          style={{ height: "100%", paddingTop: "58px" }}
        />
      )}
    </Box>
  );
};

export default PdfReader;
