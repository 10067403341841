import BloodtypeOutlinedIcon from '@material-ui/icons/BloodtypeOutlined';
import MonitorWeightOutlinedIcon from '@material-ui/icons/MonitorWeightOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import ReplayIcon from '@material-ui/icons/Replay';
import PhoneInTalkOutlinedIcon from '@material-ui/icons/PhoneInTalkOutlined';
import RingVolumeOutlinedIcon from '@material-ui/icons/RingVolumeOutlined';
import MedicationIcon from '@material-ui/icons/Medication';
import FavoriteIcon from '@material-ui/icons/Favorite';
import useTrackPageView from "../../utils/useTrackPageView";
import { useResizeScreen } from "../../hooks/useResizeScreen";
import Menu from "../../components/Menu/Menu";
import useStyles from "../../assets/styles/styles";
import { Fragment, useEffect, useState } from 'react';
import QuickAccessBox from '../../components/HomeLogged/QuickAccessBox';
import Header from '../../components/GenericHeader/Header';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SelectTypeOfComponent from '../../components/PersonalData/SelectTypeOfComponent';

const PersonalMedicalData = () => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const [type, setActiveType] = useState(-1);
    const [mobileScreen, setMobileScreen] = useState(false);
    const history = useHistory();
    const { search } = useLocation();
    const match = search.match(/type=(.*)/);
    const typeURL = match?.[1];
    const trackPageView = useTrackPageView("Mis Datos Médicos");

    const quickAccessPersonalMD = [
        { title: 'Grupo y factor', icon: <BloodtypeOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 0, path: '/PersonalMedicalData?type=FactorGroup' },
        { title: 'Peso y talla', icon: <MonitorWeightOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 1, path: '/PersonalMedicalData?type=WeightHeight' },
        { title: 'Alergias', icon: <WarningIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 2, path: '/PersonalMedicalData?type=Allergies' },
        { title: 'Enfermedades crónicas', icon: <ReplayIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 3, path: '/PersonalMedicalData?type=ChronicDiseases' },
        { title: 'Teléfonos de contacto', icon: <PhoneInTalkOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 4, path: '/PersonalMedicalData?type=ContactPhones' },
        { title: 'Teléfonos de emergencia', icon: <RingVolumeOutlinedIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 5, path: '/PersonalMedicalData?type=EmergencyPhones' },
        { title: 'Medicamentos crónicos', icon: <MedicationIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 6, path: '/PersonalMedicalData?type=ChronicMedication' },
        { title: 'Parámetros vitales', icon: <FavoriteIcon className={`${classes.quickAccessIcon} ${classes.personalDataIcon}`} />, type: 7, path: '/PersonalMedicalData?type=VitalParameters' }
    ];

    const handleClickType = (item) => (e) => {
        setActiveType(item.type);
    };

    useEffect(() => {
        mobileScreenFunction();
    }, [screen.sWidth, type]);


    useEffect(() => {
        // Realiza el dispatch para obtener los datos
    }, []);


    useEffect(() => {
        windowOnPopState();
    });

    function windowOnPopState() {
        window.onpopstate = (e) => {
            if (screen.sWidth <= 768 && type !== -1)
                window.location.href = '/PersonalMedicalData';
            else
                history.push('/MedicalData');
        };
    }

    function mobileScreenFunction() {
        if (screen.sWidth <= 768)
            setMobileScreen(true);
        else
            setMobileScreen(false);
    }

    useEffect(() => {
        trackPageView();
    },[])

    useEffect(() => {
        if (typeURL !== undefined) {
            let auxURL = `/PersonalMedicalData?type=${typeURL}`;
            let aux = quickAccessPersonalMD.filter(x => x.path === auxURL);
            setActiveType(aux[0].type);
        }
    }, [typeURL]);

    return (
        <>
            <Menu />
            {!mobileScreen || type === -1 ?
                <>
                    <Header title='Mis Datos Médicos' />
                    <div className={`${classes.divQuickAccess} ${classes.customScroll}`}>
                        {quickAccessPersonalMD.map((item, i) => (
                            <Fragment key={i}>
                                <QuickAccessBox component={Link} to={item.path} handleClick={handleClickType} i={i} item={item} classes={classes} customClass={classes.personalDataItem} iconClass={classes.personalDataDivIcons} txtIcon={classes.personalDataTxtIcon} />
                            </Fragment>
                        ))}
                    </div>
                </>
                :
                ''
            }
            <SelectTypeOfComponent />
        </>
    );
};

export default PersonalMedicalData;
