import * as React from "react";

import { AuthConsumer } from "./providers/authProvider";

export const Callback = () => (
   
    <AuthConsumer>
        
        {({ signinRedirectCallback }) => {
            signinRedirectCallback();
            return <div style={{display:"flex", justifyContent:"center"}}></div>;
        }}
    </AuthConsumer>
);



export default Callback;