import React, { useState, useEffect } from "react";
import useStyles from "../../assets/styles/styles";
import PointSelect from "../../controls/Select/PointSelect";
import "./SelectDoctorAccordion.css";

const SelectDoctorAccordion = ({
  specialties,
  specialtyState,
  modelAppointment,
  handleClick,
  preference,
  disabled,
}) => {
  const classes = useStyles();
  const [medicos, setMedicos] = useState([]);
  const [listMedicos, setListMedicos] = useState([]);

  useEffect(() => {
    let list1 = [];
    if (modelAppointment?.especialidadCodigo != undefined) {
      list1 = specialties?.agenda?.filter(
        (x, i) =>
          x.especialidadCodigo === modelAppointment?.especialidadCodigo &&
          i ===
            specialties?.agenda?.findIndex(
              (elem) =>
                elem.medicoCodigo === x.medicoCodigo &&
                elem.medicoDescripcion === x.medicoDescripcion
            )
      );
    } else {
      list1 = specialties?.agenda?.filter(
        (x, i) =>
          x.especialidadCodigo === specialtyState?.value &&
          i ===
            specialties?.agenda?.findIndex(
              (elem) =>
                elem.medicoCodigo === x.medicoCodigo &&
                elem.medicoDescripcion === x.medicoDescripcion
            )
      );
    }

    let list = [];
    if (list1 != undefined) {
      list = list1.map((item) => {
        let label = item?.medicoDescripcion;
        return {
          value: item?.medicoCodigo,
          label: label,
          className: getPointColorClassCss(item),
        };
      });
    }

    function getPointColorClassCss(medico) {
      if (medico?.visualizacion === "AGENDA SUSPENDIDA")
        return "select-doctor-accordion-menu-item-orange";
      else if (medico?.visualizacion === "TURNO TELEFONICO")
        return "select-doctor-accordion-menu-item-green";
      else if (medico?.visualizacion === "DEMANDA ESPONTANEA")
        return "select-doctor-accordion-menu-item-red";
      else return undefined;
    }


    if (list != undefined) {
      list.unshift({ value: "", label: "SELECCIONE UNA OPCION" });
    }
    setListMedicos(list);
    setMedicos(list1);
  }, [specialtyState]);

  return (
    <>
      <PointSelect
        className={`${classes.afilliateSelectTurn}`}
        menuListClass={classes.deploymentSelectTurn}
        list={listMedicos}
        selected={modelAppointment?.medicoCodigo}
        onChange={handleClick(medicos, preference)}
        disabled={disabled}
      />
      {modelAppointment?.medicoCodigo == "" && (
        <span className={classes.spanError} style={{ width: "auto" }}>
          *Complete el campo
        </span>
      )}
    </>
  );
};

export default React.memo(SelectDoctorAccordion, (prevState, nextState) => {
  return (
    prevState.specialtyState === nextState.specialtyState &&
    prevState.modelAppointment === nextState.modelAppointment
  );
});
