import { Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText, IconButton, InputAdornment, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../../assets/styles/styles';
import CustomizedInputs from '../../../../controls/InputCustom/CustomizedInputs';
import { useResizeScreen } from '../../../../hooks/useResizeScreen';
import FooterDrawer from '../../../AskTurn/FooterDrawer';
import TopButtons from '../../../AskTurn/Web/Buttons/TopButtons';
import ModalActivities from '../../../DialogActivities/ModalActivities';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import InputNumber from '../../../../controls/InputCustom/InputNumber';
import { validatorForms } from '../../../../utils/utilsFunctions';
import { setModel, postRefundRequest, clearPostRefundsRequest, clearMsgError } from '../../../../redux/actions/refundsActions';
import Loading from '../../../LoadingPage/Loading';
import CustomButton from '../../../../controls/Button/CustomButton';

const userCode = parseInt(window.localStorage.getItem("numeroPersona"));

const modelErrors = {
    phone: '',
    email: '',
};

const propertiesValidations = [
    {
        name: 'contactoTelefono',
        type: 'number',
        required: true,
        label: 'TELEFONO',
        maxLength: 40
    },
    {
        name: 'contactoEmail',
        type: 'string',
        required: true,
        label: 'E-MAIL',
        maxLength: 100
    }
]

const AddRefundStep5 = ({ handleOpenCancelModal, activeStep, handleBack }) => {
    const classes = useStyles();
    const { screen } = useResizeScreen();
    const dispatch = useDispatch();
    const [modelToSend, setModelToSend] = useState({});
    const [disabledInputs, setDisabledInputs] = useState({ contactoEmail: true, contactoTelefono: true });
    const [error, setError] = useState(modelErrors);
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalError, setOpenModalError] = useState(false);
    const { modelRequest, loading, postRefund, msgError } = useSelector(state => state.refundsReducer);
    const fullPersonalData = useSelector(state => state.personalDataReducer);
    const { allConfigurations } = useSelector(state => state.configurationReducer);

    const handleNext = () => {
        if (validateModelStep5()) {
            dispatch(setModel({ ...modelRequest, ...modelToSend, numeroPersona: userCode }));
            setOpenModalConfirm(true);
        }
    }

    const handleSubmit = () => {
        dispatch(postRefundRequest(modelRequest));
    }

    const handleChange = (e) => {
        setModelToSend({ ...modelToSend, [e.target.name]: `${e.target.value}` });
        setError({ ...error, [e.target.name]: "" });
    }

    const handleDisable = (name) => {
        setDisabledInputs({ ...disabledInputs, [name]: !disabledInputs[name] })
    }

    const handleChangeCheck = () => {
        setTermsAndConditions(!termsAndConditions);
    }

    const validateModelStep5 = () => {

        const { auxErrors, valid } = validatorForms(propertiesValidations, modelToSend);

        setError(auxErrors);

        return valid;
    }

    useEffect(() => {
      if(!openModalError){
        dispatch(clearMsgError());
      }
    }, [openModalError])
    

    useEffect(() => {
        const phone = fullPersonalData?.fullData?.afiliado?.telefonos[0]?.telefono;
        const email = fullPersonalData?.fullData?.afiliado?.mails[0]?.mail?.trim();

        setModelToSend({ ...modelToSend, contactoTelefono: phone, contactoEmail: email });

    }, [fullPersonalData?.fullData?.afiliado]);

    useEffect(() => {
        if (postRefund?.confirmacion) {
            setOpenModalConfirm(false);
            setOpenModal(true);
        }
        else if (postRefund?.confirmacion !== undefined && postRefund?.confirmacion === false) {
            setOpenModalConfirm(false);
            setOpenModalError(true);
        }
    }, [postRefund]);

    useEffect(() => {
        if (msgError?.length > 0) {
            setOpenModalConfirm(false);
            setOpenModal(false);
            setOpenModalError(true);
        }
    }, [msgError]);

    useEffect(() => {
        if (openModal) {
            setTimeout(() => { window.location.replace('/Refunds'); }, 3000);
        }
    }, [openModal]);

    useEffect(() => {
        return () => {
            dispatch(clearPostRefundsRequest());
        }
    }, [])

    return (
        <>
            {
                screen.sWidth > 768 &&
                <>
                    <TopButtons
                        handleOpenCancelModalTurn={handleOpenCancelModal}
                        handleBackTurn={handleBack}
                        activeStepTurn={activeStep}
                        handleNextTurn={handleNext}
                        disabledNext={!termsAndConditions}
                        step={2}
                        titleCancel={"CANCELAR"}
                        titleConfirm={"ENVIAR REINTEGRO"} />
                </>
            }
            {
                loading ?
                    (<Loading isLoading={loading} />)
                    :
                    (
                        <div>
                            <div style={{ marginBottom: "30px" }}>
                                <Typography className={`${classes.titleTurn}`} >DATOS DE CONTACTO</Typography>
                            </div>
                            <div className={classes.formConfirmAuthorization} >
                                {
                                    propertiesValidations?.map(({ name, label, type }) => {
                                        if (type === 'number') {
                                            return (
                                                <FormControl key={name} className={`${classes.formControlAddress} ${classes.itemGridSpan2}`} variant="standard">
                                                    <InputNumber
                                                        formControlClass={classes.width70}
                                                        value={modelToSend[name]}
                                                        label={label}
                                                        name={name}
                                                        type={type}
                                                        disabled={disabledInputs[name]}
                                                        onChange={handleChange}
                                                        endAdornment={
                                                            <InputAdornment position="start">
                                                                <IconButton onClick={() => handleDisable(name)}>
                                                                    {
                                                                        disabledInputs[name] ? (
                                                                            <EditIcon />
                                                                        ) : (
                                                                            <BlockIcon />
                                                                        )
                                                                    }
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                    <FormHelperText className={classes.errorText}>{error[name]}</FormHelperText>
                                                </FormControl>)
                                        } else {
                                            return (
                                                <FormControl key={name} className={`${classes.formControlAddress} ${classes.itemGridSpan2}`}  >
                                                    <CustomizedInputs
                                                        formControlClass={classes.width70}
                                                        label={label}
                                                        value={modelToSend[name]}
                                                        disabled={disabledInputs[name]}
                                                        onChange={handleChange}
                                                        name={name}
                                                        endAdornment={
                                                            <InputAdornment position="start">
                                                                <IconButton onClick={() => handleDisable(name)}>
                                                                    {
                                                                        disabledInputs[name] ? (
                                                                            <EditIcon />
                                                                        ) : (
                                                                            <BlockIcon />
                                                                        )
                                                                    }
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                    <FormHelperText className={classes.errorText}>{error[name]}</FormHelperText>
                                                </FormControl>)
                                        }
                                    })
                                }

                            </div>
                            <div className={classes.divConfirmRefund}>
                                <FormGroup >
                                    <FormControlLabel
                                        control={<Checkbox color='primary'
                                            checked={termsAndConditions}
                                            onChange={handleChangeCheck} />}
                                        label="Acepto términos y condiciones"
                                    />
                                </FormGroup>
                                <a
                                    href={allConfigurations?.configurations?.filter(x => x.id === 'terminosCondicionesReintegros')[0]?.valor}
                                    download="terminosycondiciones.pdf"
                                    style={{ textDecoration: "none" }}
                                    target="_blank"
                                >
                                    <CustomButton
                                        title={'DESCARGAR PDF'}
                                        customClass={`${classes.downloadTermsAndConditionsBtn} ${classes.lightLinearGradient}`}
                                    />
                                </a>
                            </div>

                        </div>

                    )
            }


            {
                openModalConfirm &&
                <ModalActivities
                    title={
                        <div style={{ display: "flex", justifyContent: "center", paddingTop: '10px' }}>
                            <span>¿Confirma el alta de la solicitud?</span>
                        </div>
                    }
                    description={
                        loading &&
                        <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}>
                            <CircularProgress color={'inherit'} />
                        </div>
                    }
                    open={openModalConfirm}
                    onClose={setOpenModalConfirm}
                    modalconfirm={!loading}
                    modalLogout={false}
                    buttonType={'submit'}
                    handleClick={handleSubmit}
                />
            }
            {
                openModal &&
                <ModalActivities
                    title={
                        <section style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                            <CheckCircleIcon style={{ fontSize: "50px" }} className={classes.quickAccessIcon} />
                            <div style={{ display: 'flex', justifyContent: 'center', flex: '1 1 100%' }}>
                                <span>TU SOLICITUD SE CREO CON EXITO</span>
                            </div>
                        </section>
                    }
                    open={openModal}
                    onClose={setOpenModal}
                    modalconfirm={false}
                    modalLogout={false}
                />
            }
            {
                openModalError &&
                <ModalActivities
                    title={
                        <section style={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap' }}>
                            <CancelIcon style={{ fontSize: "50px", marginBottom: "20px" }} className={classes.quickAccessIcon} />
                            <div style={{ justifyContent: 'center', display: 'flex', flex: '1 1 100%' }}>
                                <span>{msgError}</span>
                            </div>
                        </section>
                    }
                    onClose={setOpenModalError}
                    modalLogout={false}
                    modalconfirm={false}
                    open={openModalError}
                />
            }
            {
                screen.sWidth <= 768 &&
                <FooterDrawer
                    activeStep={activeStep}
                    handleOpenCancelModal={handleOpenCancelModal}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    disabledNext={!termsAndConditions}
                    customClassDiv={classes.footerAuthRequest}
                    confirm={true}
                />
            }
        </>
    );
}

export default AddRefundStep5