import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../consts/actionTypes";
import * as API from "../api";

function* asyncGetWayToPay({ payload }) {
  try {
    var response = yield call(API.getWayToPay, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCESS_GET_WAYTOPAY,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_WAYTOPAY,
      message: error.message,
    });
  }
}

function* asyncGetEnabledDirectDebit({ payload }) {
  try {
    var response = yield call(API.getEnabledDirectDebit, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCESS_GET_ENABLED_DIRECTDEBIT,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_ENABLED_DIRECTDEBIT,
      message: error.message,
    });
  }
}

function* asyncGetDirectDebit({ payload }) {
  try {
    var response = yield call(API.getDirectDebit, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCESS_GET_DIRECTDEBIT,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_GET_DIRECTDEBIT,
      message: error.message,
    });
  }
}

function* asyncABDirectDebit({ payload }) {
  try {
    var response = yield call(API.abDirectDebit, payload);
    if (response)
      yield put({
        type: actionTypes.SUCCESS_AB_DIRECTDEBIT,
        response,
        message: ''
      });
  } catch (error) {
    yield put({
      type: actionTypes.FAILURE_AB_DIRECTDEBIT,
      message: error.message,
    });
  }
}

function* asyncGetAllBills({ payload }) {
  try {
    let response = yield call(API.getAllBillsAffiliate, payload);
    if (response) {
      yield put({ type: actionTypes.SUCCESS_GET_BILLINGS, response, message: '' });
    }
  } catch (error) {
    yield put({ type: actionTypes.FAILURE_GET_BILLINGS, message: error.message });
  }
}

function* asyncGetBillingFiles({ payload }) {
  try {
    let response = yield call(API.getBillingFiles, payload);
    if (response) {
      yield put({ type: actionTypes.SUCCESS_GET_BILLINGS_FILES, response, message: '' });
    }
  } catch (error) {
    yield put({ type: actionTypes.FAILURE_GET_BILLINGS_FILES, message: error.message });
  }
}

// Watchers
export default function* billingSaga() {
  yield takeLatest(actionTypes.GET_WAYTOPAY, asyncGetWayToPay);
  yield takeLatest(actionTypes.GET_ENABLED_DIRECTDEBIT, asyncGetEnabledDirectDebit);
  yield takeLatest(actionTypes.GET_DIRECTDEBIT, asyncGetDirectDebit);
  yield takeLatest(actionTypes.AB_DIRECTDEBIT, asyncABDirectDebit);
  yield takeLatest(actionTypes.GET_BILLINGS, asyncGetAllBills);
  yield takeLatest(actionTypes.GET_BILLINGS_FILES, asyncGetBillingFiles);
}
