import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "../../assets/styles/styles";

const Accordion = (props) => {
  const { title, details, error, customClass, ...others } = props;
  const classes = useStyles();
  return (
    <MuiAccordion {...others}>
      <AccordionSummary
        className={`${customClass}`}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={`${error ? classes.errorText : ""}`}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
      {error ? (
        <FormHelperText className={classes.errorText}>
          *Campo obligatorio
        </FormHelperText>
      ) : (
        ""
      )}
    </MuiAccordion>
  );
};

export default Accordion;
