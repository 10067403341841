import Iframe from "react-iframe";
import Menu from "../../components/Menu/Menu";
import useStyles from "../../assets/styles/styles";
import { getLimitPlan } from "../../redux/actions/limitPlansAction";
import { notAccessToThisModule, verifyPermission } from "../../utils/utilsFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useState } from "react";
import Header from '../../components/GenericHeader/Header';
import useTrackPageView from "../../utils/useTrackPageView";
import Loading from "../../components/LoadingPage/Loading";

const FrequentQuestionsPage = () => {
    const dispatch = useDispatch();
    const loadingPermission = useSelector((state) => state.limitPlanReducer.requestInProgress);
    const limitPlanReducer = useSelector((state) => state.limitPlanReducer.allLimitPlanData);
    const trackPageView = useTrackPageView("Preguntas Frecuentes");
    const classes = useStyles();
    const affiliatePlan = useSelector((state) => state.personalDataReducer?.fullData?.afiliado?.planDescripcion);
    const [ hasPermission, setHasPermission ] = useState(false);

    useLayoutEffect(() => {
        verifyPermission(limitPlanReducer, affiliatePlan, "Preguntas Frecuentes", setHasPermission);
    }, [limitPlanReducer, affiliatePlan, hasPermission]);

    useEffect(() => {
        trackPageView();
        dispatch(getLimitPlan());
    }, []);

    return (
        <>
        {
            <>
                {
                    !hasPermission ?
                    <>
                        <Menu />
                        <Header title="Preguntas Frecuentes" colorTitle="#0075C9" />
                        <Loading
                            isLoading={loadingPermission}
                            children={notAccessToThisModule()}
                        />
                    </>
                :
                    <>
                        <Menu />
                        <Header title="Preguntas Frecuentes" colorTitle="#0075C9" />
                        <Loading
                            isLoading={loadingPermission}
                            children={<Iframe sandbox='' scrolling="yes" src='https://www.oslpasteur.com.ar/PortalHTML5/accesos/PreguntasFrecuentes/index.html?'
                            className={classes.iframe} />}
                        />
                    </>
                }
            </>
        }
    </>
    );
};

export default FrequentQuestionsPage;
