import { Divider, Typography, CircularProgress  } from "@material-ui/core";
import { useResizeScreen } from "../../../hooks/useResizeScreen";
import FormHeader from "../../PersonalData/FormHeader";
import CustomButton from "../../../controls/Button/CustomButton";
import { useLayoutEffect, useState, useEffect } from "react";
import useStyles from '../../../assets/styles/styles';
import ChronicMedicationForm from "./ChronicMedicationForm";
import { getChronicMedication, getPharmaceuticalForm } from "../../../redux/actions/medicalDataAction";
import { useDispatch, useSelector } from "react-redux";
import { handleGoToPersonalMedicalData, showMessageModal } from "../../../utils/utilsFunctions";

const param = {
    numeroPersona: parseInt(window.localStorage.getItem("numeroPersona")),
    count: 10,
    skip: 0
};

const ChronicMedication = () => {
    const { screen } = useResizeScreen();
    const classes = useStyles();
    const [disabled, setDisabled] = useState(true);
    const dispatch = useDispatch();
    const chronicMedication = useSelector((state) => state.medicalDataReducer?.chronicMedication);
    const errorMessage = useSelector(state => state.medicalDataReducer.msgError);
    const [errorMessageModal, setErrorMessageModal] = useState(false);
    const [loading, setLoading] = useState(true);


    useLayoutEffect(() => {
        dispatch(getPharmaceuticalForm());
        dispatch(getChronicMedication(param));
    }, []);

    useEffect(() =>{
        setLoading(false);
    },[chronicMedication])

    function formHeaderText() {
        let response = {
            titleHeader: 'Medicamentos Cronicos',
            subtitleHeader: 'Información sin carácter sanitario conforme ley Nº 26529'
        };
        if (screen.sWidth < 768) {
            response.titleHeader = 'Mis datos medicos';
            response.subtitleHeader = 'Información sin carácter sanitario conforme ley Nº 26529';
        }
        return response;
    }

    const handleNew = (e) => {
        e.preventDefault();
        setDisabled(prevState => !prevState);
    };

    const customsIcons = () => {
        if (disabled) {
            return (
                <CustomButton
                    title="NUEVO"
                    customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                    style={{ marginBottom: "20px", marginLeft: "20px" }}
                    onClick={handleNew}
                />
            );
        }

    };

    useEffect(() => {
        if (errorMessage?.length > 0) {
            setErrorMessageModal(true);
        }
        setLoading(true);
    }, [errorMessage]);

    return (
        <>
            <FormHeader title={formHeaderText().titleHeader} subtitle={formHeaderText().subtitleHeader} handleGoTo={handleGoToPersonalMedicalData} />
            {
                screen.sWidth > 768 &&
                <Divider className={classes.formDivider} />
            }
            {disabled &&
                <div className={`${classes.divSubtitleAddresForm} ${classes.divSubtitleAddresForm} ${classes.divBtnsAddressForm}`}>
                    {screen.sWidth <= 768 ?
                        <>
                            <Typography className={`${classes.titleTurn} ${classes.titleBox} ${classes.titleEmergency}`} style={{ textTransform: 'uppercase', marginLeft: '5px' }}>
                                MEDICAMENTOS CRONICOS
                            </Typography>
                            {customsIcons()}
                        </>
                        :
                        <>
                            <CustomButton
                                title="NUEVO"
                                customClass={`${classes.btnNewWeigth} ${classes.lightLinearGradient} `}
                                style={{ marginBottom: "20px", marginLeft: "20px" }}
                                onClick={handleNew}
                            />
                        </>
                    }
                </div>
            }
            {loading ?
                <div style={{ display: "flex", justifyContent: "center", paddingTop: '30px' }}><CircularProgress /></div>
                :
            <ChronicMedicationForm disabled={disabled} setDisabled={setDisabled} />
            }
            {chronicMedication?.medicamentos?.length === 0 && disabled && chronicMedication !== undefined &&
                <Typography
                    className={`${classes.txtSubtitleBlue} ${classes.txtEllipsis}`}
                    style={{ marginLeft: "30px", marginTop: "15px" }}>
                    {"No se encontraron resultados"}
                </Typography>}

            {showMessageModal(errorMessage, errorMessageModal, setErrorMessageModal, classes.borderWhite, classes.quickAccessIcon, classes.contentDialogTitle, handleGoToPersonalMedicalData)}
        </>
    );
};

export default ChronicMedication;
